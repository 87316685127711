import React, {Component} from 'react';
import InputMask from "react-input-mask";

export default class ImageRadio extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            DATA: props.data,
            FIELD: props.field,
            SELECTED: false
        }
    }

    recordValue = (value) => 
    {
        this.setState({SELECTED: value});
        this.props.setField(this.state.FIELD,value);
    }

    checkSelected = (VALUE) =>
    {
        if (this.state.SELECTED == VALUE) {
            return 'anketa_images_input selected';
        }

        return 'anketa_images_input';
    }

    render()
    {
        let OPTIONS = [];
        for (let i = 0; i < this.state.DATA.length; i++) {
            let CLASS = this.checkSelected(this.state.DATA[i]['VALUE']);
            OPTIONS.push(
                <a key={i} className={CLASS} onClick={() => this.recordValue(this.state.DATA[i]['VALUE'])}>
                    <img src={this.state.DATA[i]['IMAGE']} />
                </a>
            );
        }

    	return (
            <div className="anketa_images_radio">
                {OPTIONS}
            </div>
        );
    }
}
