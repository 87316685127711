import React, {Component} from 'react';
import InputMask from "react-input-mask";

export default class WhatsappInput extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            WHATSAPP: props.WHATSAPP
        }
    }

    handleChange = (event) => {
        const { target: { checked } } = event;
        this.props.setField('WHATSAPP',checked)
    };

    render()
    {
    	return <input type="checkbox" onChange={this.handleChange} defaultValue={this.state.WHATSAPP} />
    }
}
