import React, {Component, useEffect, useState} from 'react';
import {Switch, Route, Link, NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SText from "../../HELPERS/SText";
import styles from "./Bonuses.module.css";
import {useWindowDimensions} from "../../HELPERS/hooks/hooks";

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const Email = ({USER, SECURITY, ORDER}) => {

    const [email, setEmail] = useState(USER.EMAIL)
    const [noForm, setNoForm] = useState(false)

    let SHOW = false;
    if (!USER) {
        SHOW = true;
    } else {
        if (USER.EMAIL.length < 1) {
            SHOW = true;
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const sendToEmail = async () => {
        if (validateEmail(email)) {
            try {
                let TOKEN = 'UNREGISTERED';
                if (USER) {
                    TOKEN = USER.TOKEN;
                }

                let SEND_DATA = {
                    action: 'sendOrderLetter',
                    id: ORDER,
                    email,
                    apikey: TOKEN,
                    security: SECURITY,
                };
                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    setNoForm(true)
                }
            } catch (error) {
                console.log('BAD_ORDER_DATA');
            }
        }
    }

    if (SHOW) {
        if (!noForm) {
            return <div style={{borderTop: '1px solid #EDEDED', paddingTop: 15}}>
                <div style={{marginBottom: 10}}><SText fontWeight={400} fontSize={16} lineHeight={18} color={'#383838'}>Отправить
                    информацию о заказе <br/>
                    на электронную почту</SText></div>
                <div className={styles.inputWrapper}>
                    <div className={styles.input}>
                        <input type="email" placeholder="mail@mail.ru" value={email}
                               onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <div style={{background: validateEmail(email) ? '#43B02A' : '#979797'}} className={styles.btn}>
                        <SText onClick={sendToEmail} fontWeight={500} fontSize={16} lineHeight={20}
                               color={'#FFF'}>Отправить</SText>
                    </div>
                </div>
            </div>
        }
    } else return null
}

const Anketa = ({openLK}) => {
    const {windowDimensions: {width}} = useWindowDimensions()
    return (
        <div className={styles.ecoPerMinute_block_order}>
            <div className="bonuses_anketa_way_intro">
                1200 экобаллов за 1 минуту!
            </div>
            <div className="bonuses_anketa_way_flex">
                <div style={{
                    width: width <= 550 ? '100%' : 239,
                    marginBottom: width <= 550 ? 15 : 0,
                    fontSize: width <= 550 ? 16 : 12
                }} className={styles.bonuses_anketa_way_flex_left}>
                    Это не шутки! Переходите по ссылке, заполните анкету о себе, а мы начислим вам 1200 экобаллов!
                    Это займет 1 минуту
                </div>
                <div className={styles.bonuses_anketa_way_flex_right}>
                    <Link style={{color: '#fff'}} to="/closer" onClick={() => openLK(false)}>
                        Скорее к анкете
                    </Link>
                </div>
            </div>
        </div>
    );
}

const ShareCode = ({USER, CODE}) => {
    return (
        <div className="form-pop-append">
            <div className="share-actions">
                <div className="sharer">
                    <a target="_blank" className="shareicon"
                       href={'http://api.whatsapp.com/send?text=' + CODE}>
                        <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'}/>
                    </a>
                    <a target="_blank" className="shareicon"
                       href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + USER.CODE_MESSAGE}>
                        <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'}/>
                    </a>
                    <a target="_blank" className="shareicon"
                       href={'https://twitter.com/intent/tweet?text=' + USER.CODE_MESSAGE}>
                        <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'}/>
                    </a>
                    <a target="_blank" className="shareicon"
                       href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + USER.CODE_MESSAGE + '&description=' + USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                        <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'}/>
                    </a>
                    <a target="_blank" className="shareicon"
                       href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + USER.CODE_MESSAGE + '&description=' + USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                        <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'}/>
                    </a>
                    <a target="_blank" className="shareicon"
                       href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + USER.CODE_MESSAGE}>
                        <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'}/>
                    </a>
                    <a target="_blank" className="shareicon"
                       href={'viber://forward?text=' + USER.CODE_MESSAGE}>
                        <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'}/>
                    </a>
                </div>
            </div>
        </div>
    );
}

const PromoBlock = ({USER}) => {
    const [share, setShare] = useState(false)
    const [CODE, setCODE] = useState(USER.CODE)
    const [codeError, setCodeError] = useState(false)

    const listener = (e) => {
        if (document.querySelector('.promocode_block_share_button')?.contains(e.target)
        ) {
        } else {
            setTimeout(() => {
                setShare(false)
            }, 200)
        }
    }

    useEffect(() => {
        window.addEventListener('click', listener)
        return () => {
            window.removeEventListener('click', listener)
        }
    }, [])

    const changeCodeNowActive = async (CODE) => {
        if (CODE.length > 5) {
            try {
                let SEND_DATA = {
                    action: 'changeCode',
                    code: CODE,
                    apikey: USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

                if (data !== false) {
                    this.props.doAuth(data.user);
                    setCODE(CODE)
                    setCodeError(false)
                } else {
                    setCodeError(true)
                }
            } catch (error) {
                console.log('BAD PRODUCTS TIMER');
            }
        } else {
            setCodeError(true)
        }
    }

    const {windowDimensions: {width}} = useWindowDimensions()

    return (
        <div className={styles.promocodeBlock}>
            <div className={styles.promocode_inner_block}>
                <input className={codeError ? styles.with_error : null} defaultValue={CODE}
                       onKeyUp={e => changeCodeNowActive(e.target.value)}/>
                <div style={{maxWidth: width <= 550 ? 230 : '100%', marginBottom: width <= 550 ? 20 : 0}}
                     className="promocode_inner_block_flex">
                    <a className="copy_btn" onClick={() => navigator.clipboard.writeText(CODE)}>
                        Копировать
                    </a>
                    <a className="promocode_block_share_button" onClick={() => setShare(true)}>
                        <img src="/system_images/share_icon.png"/>
                    </a>
                    {share && <ShareCode CODE={CODE} USER={USER}/>}
                </div>
            </div>
            <div className={styles.promocode_inner_block}>
                <div className="promocode_inner_block_header">
                    А это ваш промокод!
                </div>
                <div className="promocode_inner_block_text">
                    Подарите другу {USER.PROMO_DISCOUNT} экобаллов на первый заказ и
                    получите {USER.PROMO_DISCOUNT} экобаллов на свой счёт. Для этого поделитесь промокодом со своим
                    другом и получайте бонусы!
                </div>
            </div>
        </div>
    );
}

const Review = () => {

    return <div className={styles.reviewWrapper}>
        <div className={styles.reviewText}>Поделитесь с нами впечатлениями о&nbsp;вашем заказе!</div>
        <div className={styles.browsers}>
            <div className={styles.yandex}>
                <div className={styles.image}><img src="/system_images/yandexDone.png"/></div>
                <a className={styles.reviewBtn} target={'_blank'} href='https://yandex.ru/maps/org/ekomarket/105368001192/reviews/?indoorLevel=1&ll=37.593030%2C55.707720&z=18'>Оставить отзыв</a>
            </div>
            <div className={styles.google}>
                <div className={styles.image}><img src="/system_images/googleDone.png"/></div>
                <a className={styles.reviewBtn} target={'_blank'} href="https://www.google.com/maps/place/ООО+Экомаркет/@55.7087064,37.5955863,17z/data=!4m7!3m6!1s0x46b54b8a2d5767ff:0xb9736997b3268544!8m2!3d55.7087064!4d37.597775!9m1!1b1?hl=ru-RU">Оставить отзыв</a>
            </div>
        </div>
    </div>
}

const DoneNew = ({USER, openLK, ...props}) => {

    return <div className={styles.orderWrapper}>
        <div>
            <div className="done_head">
                <img src="/system_images/green_check.png"/> Спасибо, Ваш заказ принят!
            </div>
            <div>
                <div className={styles.orderNumber}>
                    Номер заказа
                </div>
                <div className={styles.orderDigit}>
                    {props.NUMBER}
                </div>
            </div>
            <div style={{
                textAlign: 'center',
                marginBottom: 25,
                fontSize: 11,
                fontWeight: 400,
                lineHeight: '12px',
                color: '#515151'
            }}>
                Итоговая
                сумма вашего заказа может варьироваться <br/>
                в пределах 10%, если в заказе есть весовой товар. <br/>
                В таком случае будет осуществлен воврат части денежных средств или дополнительное списание, как только
                заказ будет собран.
            </div>
            <Review/>
            <PromoBlock USER={USER}/>
            {parseInt(USER.polled) !== 1 ? <Anketa USER={USER} openLK={openLK}/> : null}
            <Email USER={USER} SECURITY={props.SECURITY} ORDER={props.ORDER}/>
        </div>
        <div className={styles.mainBtnWrapper}>
            <div onClick={() => props.openOrder(false)} className={styles.onMainBtn}>
                <SText fontWeight={500} fontSize={16} lineHeight={20} color={'#ffffff'}
                >Перейти на главную страницу</SText>
            </div>
        </div>
    </div>
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DoneNew);