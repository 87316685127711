import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        REGION: state.REGION,
        SHOW_REGION_HELPER: state.SHOW_REGION_HELPER,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class RegionHelper extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            SHOW_REGION_HELPER: props.SHOW_REGION_HELPER,
        	REGION: props.REGION,
            SHOW: false
        }

        this.setSuggestionsRef = this.setSuggestionsRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount = () =>
    {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setSuggestionsRef(node) {
        this.sug = node;
    }

    handleClickOutside(event) {
        if (this.sug && !this.sug.contains(event.target)) {
            if (this.state.SHOW) {
                this.setState({SHOW: false});
            }
        }
    }

    renderPhone = () =>
    {
        let PHONE = '8(495)4450505';

        switch (parseInt(this.state.REGION)) {
            case 77:
                PHONE = '8(495)4450505';
                break;
            case 78:
                PHONE = '8(812)5661006';
                break;
            case 16:
                PHONE = '8(495)4450505';
                break;
            case 52:
                PHONE = '8(495)4450505';
                break;
            case 61:
                PHONE = '8(495)4450505';
                break;

        }

        return (
            <a style={{color: '#515151'}}  className="header_phone" target="_blank" href={'tel:74951599009'}>
                {'8 (495)-159-90-09'}
            </a>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.SHOW_REGION_HELPER !== prevProps.SHOW_REGION_HELPER) {
            this.setState({SHOW_REGION_HELPER: this.props.SHOW_REGION_HELPER});
        }
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    getTitle = (REGION) =>
    {
        let REGIONS = this.getRegions();

        let TITLE = '';
        for (let i = 0; i < REGIONS.length; i++) {
            if (REGION == REGIONS[i].code) {
                TITLE = REGIONS[i].title;
            }
        }

        return TITLE;
    }

    changeLocation = (CODE) =>
    {
        if (this.state.REGION !== CODE) {
            this.setState({REGION: CODE, SHOW: false});
            ///this.props.changeRegion(CODE);
            this.props.resetProducts();
        } else {
            this.setState({SHOW: false});
        }
    }

    checkSelectedAndReturnClass = (CODE) =>
    {
        if (CODE == this.state.REGION) {
            return 'active';
        } else {
            return '';
        }
    }

    getRegions = () =>
    {
        let REGIONS = [
            {code:77,title:'Москва и Московская область',link:'https://ecomarket.ru'},
            {code:78,title:'Санкт-Петербург и Ленинградская область',link:'https://spb.ecomarket.ru'},
            {code:16,title:'Казань и Республика Татарстан',link:'https://kaz.ecomarket.ru'},
            {code:52,title:'Нижний Новгород и Нижегородская область',link:'https://nnov.ecomarket.ru'},
            {code:61,title:'Ростов-на-Дону и Ростовская область',link:'https://rnd.ecomarket.ru'}
        ];

        return REGIONS;
    }

    renderAngleClass = () =>
    {
        if (!this.state.SHOW) {
            return 'angle_location';
        } else {
            return 'angle_location rotated';
        }
    }

    getActiveLink = (link) =>
    {
        return link + window.location.pathname + window.location.search;
    }

    renderLocations()
    {
        let CLASS = 'locations_select';
        if (this.state.SHOW) {
            CLASS = 'locations_select shown';
        }

        let REGIONS = this.getRegions();

        let RENDERED_REGIONS = [];
        for (let i = 0; i < REGIONS.length; i++) {
            if (parseInt(this.state.REGION) !== parseInt(REGIONS[i].code)) {
                RENDERED_REGIONS.push(
                    <a key={i} href={this.getActiveLink(REGIONS[i].link)}>
                        {REGIONS[i].title}
                    </a>
                );
            } else {
                RENDERED_REGIONS.push(
                    <a key={i}>
                        {REGIONS[i].title}
                    </a>
                );
            }
        }

        return (
            <div className={CLASS}>
                {RENDERED_REGIONS}
            </div>
        );
    }

    toggleLocations = () =>
    {
        if (this.state.SHOW) {
            this.setState({SHOW: false});
        } else {
            this.setState({SHOW: true});
        }
    }

    renderPlacemark = () =>
    {
        if (WIDTH > 980) {
            return <img className="filled_location" src="/system_images/dark_location.png" alt="location" />;
        } else {
            return <img className="filled_location" src="/system_images/location_filled_white.png" alt="location" />
        }
    }

    renderSelecter = () =>
    {
        return (
            <div>
                <div className="chosen_location"  ref={this.setSuggestionsRef}>
                    <span className="region_location" onClick={() => this.toggleLocations()}>
                        {this.renderPlacemark()}
                        <span className="location_chosen_title">
                            {this.getTitle(this.state.REGION)}
                        </span>
                        <img className={this.renderAngleClass()} src="/system_images/angle_down.png" alt="angle" />
                    </span>
                    {this.renderLocations()}
                </div>
            </div>
        );
    }

    renderHelper = () =>
    {
        if (WIDTH > 980) {
            return (
                <div className="region_row_helper">
                    <ul>
                        <li>
                            <Link to="/company/save-nature">
                                Бережём природу
                            </Link>
                        </li>
                        <li>
                            <Link to="/company/about">
                                О нас
                            </Link>
                        </li>
                        <li>
                            <Link to="/company/oplata">
                                Оплата
                            </Link>
                        </li>
                        <li>
                            <Link to="/company/dostavka">
                                Доставка
                            </Link>
                        </li>
                    </ul>
                    {this.renderPhone()}
                </div>
            );
        }
    }

    get(name){
       if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
          return decodeURIComponent(name[1]);
    }

    getStyle = () =>
    {
        if (this.get('hide_all_navs')) {
            return ' hidden_true';
        }

        if ((this.props.history.location.pathname == "/company/bars") || (this.props.history.location.pathname == "/company/zoo") || (this.props.history.location.pathname == "/company/spbzoo") || (this.props.history.location.pathname == "/company/save-nature")) {
            return ' hidden_true';
        }

        return '';
    }

	render() {
        if (window.innerWidth < 1200) {
            return false;
        }
        if (!this.state.SHOW_REGION_HELPER) {
            return false;
        }

		return (
            <div className={"region_row" + this.getStyle()}>
                {this.renderHelper()}
            </div>
		);
	}

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RegionHelper))
