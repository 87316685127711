import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'
import {CSSTransitionGroup} from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {

        CATS: state.CATS,
        TOP_NAVS: state.TOP_NAVS,
        SHOW_REGION_HELPER: state.SHOW_REGION_HELPER
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class TopNavs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ACTIVE: false,
            TOP_NAVS: props.TOP_NAVS,
            SHOW_REGION_HELPER: props.SHOW_REGION_HELPER,
        }

        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.TOP_NAVS !== prevProps.TOP_NAVS) {
            if (!this.props.TOP_NAVS) {
                document.body.classList.remove("modal-open");
                document.documentElement.scrollTop = this.OFFSET;
            } else {
                this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                document.body.classList.add("modal-open");
            }
        }
        if (this.props.SHOW_REGION_HELPER !== prevProps.SHOW_REGION_HELPER) {
            this.setState({SHOW_REGION_HELPER: this.props.SHOW_REGION_HELPER});
        }
    }

    // checkActiveArrow = (id) => {
    //     if (this.state.ACTIVE == id) {
    //         return 'nav-load-more selected';
    //     } else {
    //         return 'nav-load-more';
    //     }
    // }

    // renderSubCatsArrow = (CAT) => {
    //     let CLASSES = this.checkActiveArrow(CAT.id);
    //
    //     return (
    //         <div className={CLASSES}>
    //             <i className="fa fa-angle-right"></i>
    //         </div>
    //     );
    // }

    renderBackgroundImage = (CAT) => {
        // let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN +  '/imgs/groups/' + CAT.image + ')'};
        let IMAGE = {};

        if (CAT.image2 !== '') {
            IMAGE = 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups2/' + CAT.image2 + ')'
        }

        return IMAGE;
    }

    // checkActive = (id) => {
    //     if (this.state.ACTIVE == id) {
    //         return 'foldable selected';
    //     } else {
    //         return 'foldable';
    //     }
    // }

    checkSubActive = (id) => {
        if (this.state.ACTIVE == id) {
            return true;
        } else {
            return false;
        }
    }

    goTo(LINK) {
        this.props.toggleNav();
        this.props.history.push(LINK);
    }

    renderSubCats = (CAT) => {

        let SUB_ITEMS = false;
        let TITLE = CAT.title
        if (this.checkSubActive(CAT.id)) {
            SUB_ITEMS = [];
            for (let i = 0; i < CAT.children.length; i++) {
                let SUB_CAT = CAT.children[i];
                SUB_ITEMS.push(this.renderSubLi(SUB_CAT, i));
            }
            SUB_ITEMS = (
                <div className="in-sub-menu" key="1">
                    <div className="cats_image_and_text_holder" onClick={() => this.toggleSubs(false)}>
                        <img className="trenge_image" src="/system_images/trengle_icon.png"/>
                        <div className="sub_cats_title">{TITLE}</div>
                    </div>
                    {SUB_ITEMS}
                </div>
            );
            return (
                <CSSTransitionGroup
                    transitionName="slide"
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={300}
                >
                    {SUB_ITEMS}
                </CSSTransitionGroup>
            );
        } else return false


    }

    renderSubLi = (CAT, i) => {
        return (
            <div className="cats_children_tile"
                 key={i}
            >
                <div onClick={() => this.goTo('/' + CAT.url)}
                     title={CAT.title}
                >
                    {CAT.title}
                </div>
            </div>
        );
    }

    toggleSubs = (id) => {
        let SET = id;

        if (this.state.ACTIVE == id) {
            SET = false;
        }
        this.setState({ACTIVE: SET});
    }

    renderLi = (CAT, i) => {
        let IMAGE = this.renderBackgroundImage(CAT);

        if (CAT.children.length) {
            // let CLASSES = this.checkActive(CAT.id);
            // let SUB_CATS_ARROW = this.renderSubCatsArrow(CAT);
            let SUB_CATS = this.renderSubCats(CAT);

            return (
                <div className="cats_tile"
                     style={{backgroundImage: IMAGE}}
                     key={i}
                     onClick={() => this.goTo('/' + CAT.url)}
                >
                    <div
                        className="text_in_tile"
                        style={{width: parseInt(CAT.id) == 59 ? "90px" : "80px"}}
                        // title={CAT.title}
                    >
                        {/*{IMAGE}*/}
                        {CAT.image2 === '' && CAT.title}
                        {/*{SUB_CATS_ARROW}*/}
                    </div>
                    {SUB_CATS}

                </div>
            );
        } else {
            return (
                <div className="cats_tile"
                     style={{backgroundImage: IMAGE}}
                     onClick={() => this.goTo('/' + CAT.url)}
                     key={i}
                >
                    <div className="text_in_tile"
                         title={CAT.title}
                    >
                        {/*{IMAGE}*/}
                        {CAT.image2 === '' && CAT.title}
                    </div>
                </div>
            );
        }
    }

    render() {
        if (!this.props.TOP_NAVS) {
            return false;
        }
        let CATS = this.props.CATS;

        let CLASS = '';
        if (this.state.SHOW_REGION_HELPER) {
            CLASS = ' regional'
        }

        if (CATS) {
            let ITEMS = [];

            for (let i = 0; i < CATS.length; i++) {
                let CAT = CATS[i];
                console.log(CAT)
                ITEMS.push(this.renderLi(CAT, i));
            }

            return (
                <div className="cats_tile_holder">
                    {ITEMS}
                </div>
                // <div className={'top_navs' + CLASS}>
                //     {/*<div className="main-nav">*/}
                //
                //     {/*</div>*/}
                // </div>
            );
        } else {
            return false;
        }
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TopNavs))
