import React, {Component} from 'react';

import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {

    return {
        USER: state.USER
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class TagEditor extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            editing: false,
        	TAG: props.tag,
            NAME: props.tag.name,
            DEF_NAME: props.tag.name,
            ORDER: props.tag.order,
            DEF_ORDER: props.tag.order,
        };
    }

    saveValues = async () =>
    {
        try {
            let SEND_DATA = {
                action: 'admin_editGroupTag',
                id: this.state.TAG.id,
                name: this.state.NAME,
                order: this.state.ORDER,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.setState({DEF_NAME: this.state.NAME, editing: false})
            } else {
            }
        } catch (error) {
        }
    }

	render()
    {
    	if (this.state.editing) {
            return (
                <div className="tag_header">
                    <input onChange={(text) => this.setState({NAME: text.target.value})} className="inline_input" type="text" value={this.state.NAME} />
                    <br/>
                    <input onChange={(text) => this.setState({ORDER: text.target.value})} className="inline_input" type="number" value={this.state.ORDER} />
                    <br/>
                    <a className="inline_edit confirm" onClick={() => this.saveValues()}><i className="fa fa-check"></i></a>
                    <a className="inline_edit end" onClick={() => this.setState({editing: false, NAME: this.state.DEF_NAME, ORDER: this.state.DEF_ORDER})}><i className="fa fa-remove"></i></a>
                </div>
            );
        }
    	
        return (
            <div className="tag_header">
				<a onClick={() => this.state.TAG.id !== 'all' ? this.props.setSelected(this.state.TAG.id) : console.log()}>{this.state.NAME}</a> {this.state.TAG.id !== 'all' ? <a className="inline_edit confirm" onClick={() => this.setState({editing: true})}><i className="fa fa-pencil"></i></a> : null}
			</div>
        );
    }
}
				
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagEditor)