import React, {Component} from 'react';
import {Switch, Route, Link, NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import Bonuses from "./../../../APP/COMPONENTS/ContentParts/Bonuses"
import History from "./../../../APP/COMPONENTS/ContentParts/History"
import Personal from "./../../../APP/COMPONENTS/ContentParts/Personal"
import MobileLatestOrders from "./../../../APP/COMPONENTS/ContentParts/MobileLatestOrders"
import Footer from "./../../../APP/COMPONENTS/Footer"

import OrderCard from './../../../APP/COMPONENTS/ContentParts/OrderCard';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Auth extends Component {

	constructor(props)
    {
        super(props);

        let VIEW = 'NAVS';
        if (WIDTH > 760) {
            VIEW = 'BONUSES';
        }

        if ((props.VIEW !== true) && (props.VIEW !== false)) {
            VIEW = props.VIEW;
        }

        this.state = {
        	PHONE: '+7-999-999-99-99',
        	VIEW: VIEW,
        	SENT: false,
        	TOKEN: this.props.TOKEN,
        	USER: this.props.USER,
            ACTIVE_STARS:   0,
            LEVEL:          0,
            LEFT:           0,
            UNPAID:         0,
            BLOGGERS_TEXT:  ''
        }
    }

    componentDidMount()
    {
        this.updateUserInfo();
    }

    updateUserInfo = async() =>
    {
        try {
            let SEND_DATA = {
                action:     'updateUserInfo',
                apikey:      this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.props.doAuth(data.user);
                this.getTheImageAndTitle();
                this.setState({BLOGGERS_TEXT: data.bloggers_text})
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    getTheImageAndTitle()
    {
        let SUM = this.props.USER.SUM;
        let STAR_EQUAL = 1000;
        let LEVEL_EQUAL = 10;
        
        let LEVEL = Math.floor(SUM / (STAR_EQUAL * LEVEL_EQUAL)) + 1;
        let ACTIVE_STARS = Math.floor(SUM / LEVEL_EQUAL / 100) * 100;
        let NEXT_LEVEL_STARS = LEVEL * STAR_EQUAL;
        let LEFT = NEXT_LEVEL_STARS - ACTIVE_STARS;

        this.setState({LEVEL: LEVEL, ACTIVE_STARS: ACTIVE_STARS, LEFT: LEFT})
    }

    renderBonuses()
    {
    	return <Bonuses />;
    }

    renderHistory()
    {
    	return <History changeView={this.changeView} />;
    }

    renderPersonal()
    {
    	return <Personal />;
    }

    renderAvatar(USER)
    {
        let SOURCE = '/system_images/no_user_pic.png';

        if (USER.IMAGE !== "") {
            SOURCE = CONFIG.API_C_DOMAIN + USER.IMAGE;
        }

        return SOURCE;
    }

    getStatusTitle(STATUS)
    {
        switch (STATUS)
        {
            case 1:
                return 'Новичок';
                break;
            case 2:
                return 'Малосольный огурец';
                break;
            case 3:
                return 'Любитель';
                break;
            case 4:
                return 'Грибоедов';
                break;
            case 5:
                return 'Гастрономический обозреватель';
                break;
            case 6:
                return 'Рататуй';
                break;
            case 7:
                return 'Помощник повара';
                break;
            case 8:
                return 'Повар';
                break;
            case 9:
                return 'Кук';
                break;
            case 10:
                return 'Шеф повар';
                break;
            case 11:
                return 'Executive Chef';
                break;
            case 12:
                return 'Владелец фуд корта';
                break;
            case 13:
                return 'Хозяин ресторана';
                break;
            case 14:
                return 'Ресторатор года';
                break;
            case 15:
                return 'Новиков';
                break;
            case 16:
                return 'Фудблогер';
                break;
            case 17:
                return 'Советник короля';
                break;
            case 18:
                return 'Серый кардинал';
                break;
            case 19:
                return 'Волшебник';
                break;
            case 20:
                return 'Автор кулинарной книги';
                break;
            case 21:
                return 'Рокфеллер';
                break;
            case 22:
                return 'Министр продуктовой промышленности';
                break;
            case 23:
                return 'Звёздный воин';
                break;
            case 24:
                return 'Султан';
                break;
            case 25:
                return 'Император';
                break;
        }
    }

    renderCurrentStatus()
    {
        return false;
        
        if (this.state.LEVEL > 0) {
            let IMAGE = '/system_images/statuses/' + this.state.LEVEL + '.png';
            
            return (
                <div className="user-lk-current-holder">
                    <div className="user-lk-current-image-holder">
                        <img src={IMAGE} className="status_image" />
                    </div>
                    
                </div>
            );
        }
    }

    renderUserData()
    {
        let USER = this.props.USER;
        let NAME = '';

        if (USER.NAME !== '') {
            NAME = USER.NAME;
        }

        let IMAGE = this.renderAvatar(USER);

        return (
            <div className="user-lk-head-holder">
                <div className="user-lk-the-data">
                    <div className="user-avatar" style={{'background': 'transparent url(' + IMAGE + ')'}} >
                    </div>
                    <div className="user-lk-name-holder">
                        {NAME}
                    </div>
                    {this.renderCurrentStatus()}
                </div>
            </div>
        );
    }

    renderDeskBloggerNav1 = () =>
    {
        if (this.props.USER.STATUS == 4) {
            return <a className={(this.state.VIEW == 'ZADANIE') ? 'modal-inside-link active' : 'modal-inside-link'} onClick={() => this.setState({VIEW: 'ZADANIE'})}>Тех задание</a>
        }
    }

    renderDeskBloggerNav2 = () =>
    {
        if (this.props.USER.STATUS == 4) {
            return <a className={(this.state.VIEW == 'HELP') ? 'modal-inside-link active' : 'modal-inside-link'} onClick={() => this.setState({VIEW: 'HELP'})}>Помощь</a>
        }
    }

    renderFullSCreenNav()
    {
    	return (
            <div className="modal-lk-head">
                {this.renderUserData()}
        		<div className="modal-nav">
                    <a className={(this.state.VIEW == 'HISTORY') ? 'modal-inside-link active' : 'modal-inside-link'} onClick={() => this.setState({VIEW: 'HISTORY'})}>История заказов</a>

                    <a className={(this.state.VIEW == 'BONUSES') ? 'modal-inside-link active' : 'modal-inside-link'} onClick={() => this.setState({VIEW: 'BONUSES'})}>Бонусы</a>
                    {this.renderDeskBloggerNav1()}

                    <a className={(this.state.VIEW == 'PERSONAL') ? 'modal-inside-link active' : 'modal-inside-link'} onClick={() => this.setState({VIEW: 'PERSONAL'})}>Настройки аккаунта</a>
                    {this.renderDeskBloggerNav2()}
        		</div>
            </div>
		);
    }

    /// <a className="modal-inside-link red"  onClick={() => this.props.logOut()}>Выйти</a>

    renderTop()
    {
        if (WIDTH > 760) {
            return (
                <div className="modal_top">
                    <a className="close-modal" onClick={() => this.props.openLK(false)}>
                        <img src={'/system_images/close.png'} />
                    </a>
                    <h4>Личный кабинет</h4>
                    {this.renderFullSCreenNav()}
                </div>
            );
        } else {
            let HEADER = false;
            if (this.state.VIEW == 'BONUSES') {
                HEADER = (
                    <div className="modal_top modal_mobile_top">
                        <a className="close-modal-mobile" onClick={() => this.setState({VIEW: 'NAVS'})}>
                            <img src={'/system_images/angle_left.png'} />
                        </a>
                        <h4>Мои экобаллы</h4>
                    </div>
                );
            } else if (this.state.VIEW == 'HISTORY') {
                HEADER = (
                    <div className="modal_top modal_mobile_top">
                        <a className="close-modal-mobile" onClick={() => this.setState({VIEW: 'NAVS'})}>
                            <img src={'/system_images/angle_left.png'} />
                        </a>
                        <h4>История заказов</h4>
                    </div>
                );
            } else if (this.state.VIEW == 'PERSONAL') {
                HEADER = (
                    <div className="modal_top modal_mobile_top">
                        <a className="close-modal-mobile" onClick={() => this.setState({VIEW: 'NAVS'})}>
                            <img src={'/system_images/angle_left.png'} />
                        </a>
                        <h4>Настройки аккаунта</h4>
                    </div>
                );
            } else if (this.state.VIEW == 'ZADANIE') {
                HEADER = (
                    <div className="modal_top modal_mobile_top">
                        <a className="close-modal-mobile" onClick={() => this.setState({VIEW: 'NAVS'})}>
                            <img src={'/system_images/angle_left.png'} />
                        </a>
                        <h4>Техническое задание</h4>
                    </div>
                );
            } else if (this.state.VIEW == 'HELP') {
                HEADER = (
                    <div className="modal_top modal_mobile_top">
                        <a className="close-modal-mobile" onClick={() => this.setState({VIEW: 'NAVS'})}>
                            <img src={'/system_images/angle_left.png'} />
                        </a>
                        <h4>Помощь</h4>
                    </div>
                );
            } else if (this.state.VIEW == 'NAVS') {
                HEADER = (
                    <div className="modal_top modal_mobile_top">
                        <a className="close-modal-mobile" onClick={() => this.props.openLK(false)}>
                            <img src={'/system_images/angle_left.png'} />
                        </a>
                        <h4>Личный кабинет</h4>
                    </div>
                );
            } else if (this.state.VIEW == 'ORDER') {
                HEADER = (
                    <div className="modal_top modal_mobile_top">
                        <a className="close-modal-mobile" onClick={() => this.setState({VIEW: 'HISTORY'})}>
                            <img src={'/system_images/angle_left.png'} />
                        </a>
                        <h4>Информация о заказе</h4>
                    </div>
                );
            }
            return HEADER;
        }
    }

    renderUnpaidBadge = () =>
    {
        if (this.state.UNPAID > 0) {
            return (
                <span className="mobile_nav_badge red">
                    {this.state.UNPAID}
                </span>
            );
        }
    }

    renderAnketaLink = () =>
    {
        if (parseInt(this.props.USER.polled) !== 1) {
            return (
                <Link to="/closer" onClick={() => this.props.openLK(false)} className="mobile_nav_badge orange">
                    +1000
                </Link>
            );
        }
    }

    renderBonusesBadge = () =>
    {
        if (this.props.USER.BONUSES > 0) {
            return (
                <span className="mobile_nav_badge green" onClick={() => this.setState({VIEW: 'BONUSES'})}>
                    {this.props.USER.BONUSES}
                </span>
            );
        }
    }

    renderMobileBloggerNav1 = () =>
    {
        if (this.props.USER.STATUS == 4) {
            return <div><a onClick={() => this.setState({VIEW: 'ZADANIE'})}>Техническое задание</a></div>
        }
    }

    renderMobileBloggerNav2 = () =>
    {
        if (this.props.USER.STATUS == 4) {
            return <div><a onClick={() => this.setState({VIEW: 'HELP'})}>Помощь</a></div>
        }
    }


    renderMobileNavs()
    {
        return (
            <div className="mobile_holder">
                <div className="mobile_user_navs">
                    <div><a onClick={() => this.setState({VIEW: 'HISTORY'})}>История заказов{this.renderUnpaidBadge()}</a></div>
                    {this.renderMobileBloggerNav1()}
                    <div><a onClick={() => this.setState({VIEW: 'BONUSES'})}>Бонусы</a>{this.renderBonusesBadge()}{this.renderAnketaLink()}</div>
                    <NavLink to={'/company/save-nature'}><div><a>Бережём природу</a></div></NavLink>
                    <div><a onClick={() => this.setState({VIEW: 'PERSONAL'})}>Настройки аккаунта</a></div>
                    {this.renderMobileBloggerNav2()}
                </div>
                <div className="mobile_user_navs_footer">
                    <div><Link onClick={() => this.props.openLK(false)} to="/company/polzovatelskoe-soglashenie">Оферта</Link></div>
                    <div><a className="logout_button on_auth" onClick={() => this.props.logOut()}>Выход из аккаунта</a></div>
                </div>
                <div className="mobile_footer_holder" onClick={() => this.props.openLK(false)}>
                    <Footer/>
                </div>
            </div>
        );
    }

    renderMobileUserHead()
    {
        if (this.state.VIEW == 'NAVS') {
            return this.renderUserData();
        }
    }

    setUnpaid = (unpaid) =>
    {
        this.setState({UNPAID: unpaid})
    }

    renderMobileLatest()
    {
        if (window.innerWidth < 1200) {
            if (this.state.VIEW == 'NAVS') {
                return <MobileLatestOrders changeView={this.changeView} setUnpaid={this.setUnpaid} />
            }
        }
    }

    renderHelp = () =>
    {
        return (
            <div id="help" className="inside active help_content">
                <div className="bloggers_help_block">
                    <div className="bloggers_help_block_head">
                        Менеджер по работе с блогерами
                    </div>
                    <div className="bh_list">
                        <div className="bh_item">
                            <div className="bh_name">
                                Мадина
                            </div>
                            <div className="bh_phone">
                                <a href="tel: +7 963 584 13 69">+7 963 584 13 69</a>
                            </div>
                            <div className="bh_wa">
                                <a href="https://api.whatsapp.com/send?phone=+7 963 584 13 69">
                                    <img src="/system_images/blogger/wa.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderZadanie = () =>
    {
        return (
            <div id="zadanie" className="inside active zadanie_content" dangerouslySetInnerHTML={{__html: this.state.BLOGGERS_TEXT}} />
        )
    }

    changeView = (VIEW,ORDER) =>
    {
        this.setState({VIEW: VIEW, ORDER: ORDER});
    }

    renderOrder = () =>
    {
        return (
            <div className="full_screen_on_mobile_order">
                <OrderCard key={0} order={this.state.ORDER} fetchData={true} />
            </div>
        )
    }

    renderContent()
    {
        let VIEW = false;
        if (this.state.VIEW == 'BONUSES') {
            VIEW = this.renderBonuses();
        } else if (this.state.VIEW == 'HISTORY') {
            VIEW = this.renderHistory();
        } else if (this.state.VIEW == 'PERSONAL') {
            VIEW = this.renderPersonal();
        } else if (this.state.VIEW == 'ORDER') {
            VIEW = this.renderOrder();
        } else if (this.state.VIEW == 'NAVS') {
            VIEW = this.renderMobileNavs();
        } else if (this.state.VIEW == 'ZADANIE') {
            VIEW = this.renderZadanie();
        } else if (this.state.VIEW == 'HELP') {
            VIEW = this.renderHelp();
        }

        let CLASSES = 'modal_content';
        if (WIDTH <= 760) {
            CLASSES = 'modal_content modal_mobile_content';
        }

        return (
            <div className={CLASSES}>
                {this.renderMobileUserHead()}
                {this.renderMobileLatest()}
                {VIEW}
            </div>
        );
    }

	render() {

		return (
			<div>
				<div className="modal-box lk-modal">
                    <div className="relativity">
                    {this.renderTop()}
                    {this.renderContent()}
                    </div>
				 </div>
			</div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Auth)
