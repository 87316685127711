import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'
import Slider from "react-slick";

import { isActive, sendPostRequest } from "./../../../APP/HELPERS/requests"


/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductCard from './../../../APP/COMPONENTS/ContentParts/ProductCard';
import { isThisHour } from 'date-fns';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        SHOW_INACTIVE: state.SHOW_INACTIVE,
        MANAGER: state.MANAGER,
        USER: state.USER,
        SUPPLIER: state.SUPPLIER,
        SPEC_FILTER: state.SPEC_FILTER,
        SHOP: state.SHOP,
        MARGE_FILTER: state.MARGE_FILTER,
        WRITE_FILTER: state.WRITE_FILTER,
        REPACK: state.REPACK
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class ProductsScrollPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CAT: props.CAT,
            IDS: props.IDS,
            CAROUSEL: false,
            LEFT: 0,
            SIZE: 43,
            LIMITED: props.LIMITED,
            DO_EDIT: false,
            TITLE: props.CAT.title
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.items = this.getItemsPerScreen();

        this.handleScroll = this.handleScroll.bind(this);
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    getItemsPerScreen() {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 1;
        } else if (WIDTH < 769) {
            return 2;
        } else if (WIDTH < 1050) {
            return 3;
        } else if (WIDTH < 1200) {
            return 4;
        } else if (WIDTH < 9900) {
            return 5;
        }
    }

    getItemsPerScreenScroll() {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 1;
        } else if (WIDTH < 769) {
            return 1;
        } else if (WIDTH < 1050) {
            return 1;
        } else if (WIDTH < 1200) {
            return 2;
        } else if (WIDTH < 9900) {
            return 3;
        }
    }

    renderFinalArrow() {
        if (this.state.CAT.id > 0) {
            return (
                <div className="item" key={ 'arrow' }>
                    <div className="product-col">
                        <Link key={ 'final' } to={ '/' + this.state.CAT.url } className="final_arrow">
                            <div
                                className="image centered"
                                style={ { background: '#F3F3F3' } }
                            >
                                <div className="more_mesage_card">
                                    Посмотреть все товары раздела >
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.IDS !== this.props.IDS) {
            this.setState({ IDS: this.props.IDS })
        }
        if (prevProps.CAT !== this.props.CAT) {
            this.setState({ CAT: this.props.CAT })
        }
        if (prevProps.LIMITED !== this.props.LIMITED) {
            this.setState({ IDS: this.props.LIMITED })
        }
    }

    renderFinalCatArrow() {
        if (this.state.CAT.id > 0) {
            return (
                <div className="item" key={ 'arrow' }>
                    <div className="product-col margin-bottom-minus">
                        <Link key={ 'final' } to={ '/' + this.state.CAT.url } className="final_arrow">
                            <div
                                className="image no_back"
                            >
                                <div className="more_mesage_cat_card">
                                    Посмотреть все товары раздела >
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {

    }

    renderCarousel() {
        let PRODUCT_CARDS = [];
        let TOTAL_NORMALS = 0;

        for (let i = 0; i < this.state.IDS.length; i++) {
            if (typeof this.state.IDS[i] !== "undefined") {
                if (typeof this.props.PRODUCTS[this.state.IDS[i]] !== "undefined") {

                    if (isActive(this.props.PRODUCTS[this.state.IDS[i]], this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER, this.props.WRITE_FILTER, this.props.REPACK)) {
                        TOTAL_NORMALS++;
                        if (PRODUCT_CARDS.length < 11) {
                            PRODUCT_CARDS.push(
                                <div className="item" key={ i }>
                                    <ProductCard ID={ this.state.IDS[i] } />
                                </div>
                            );
                        }
                    }
                }
            }
        }

        let arrows = false;
        if (this.items > 2) {
            arrows = true;

            if (TOTAL_NORMALS > 11) {
                PRODUCT_CARDS.push(this.renderFinalCatArrow());
            }
        } else {
            if (PRODUCT_CARDS.length > this.items + 2) {
                PRODUCT_CARDS.push(this.renderFinalArrow());
            }
        }

        let SETTINGS = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: this.items,
            slidesToScroll: 1,
            className: 'owl-product',
            arrows: arrows,
            swipeToSlide: true
        };

        let CAROUSEL = false;
        if (this.items > 2) {
            CAROUSEL = (
                <Slider ref={ c => (this.slider = c) } { ...SETTINGS }>
                    { PRODUCT_CARDS }
                </Slider>
            );
        } else {
            CAROUSEL = (
                <div onScroll={ this.handleScroll } className="rm_carousel" ref={ c => (this.slider = c) }>
                    { PRODUCT_CARDS }
                </div>
            );
        }

        return CAROUSEL;
    }

    handleScroll(event) {

    }

    renderMoreButton() {
        let TOTAL = this.state.IDS.length;
        let LEFT = 0;

        if (TOTAL > this.items) {
            LEFT = TOTAL - this.items;
        }

        if (LEFT > 0) {
            return (
                <Link className="category-carousel-more" to={ '/' + this.state.CAT.url }>
                    Посмотреть +{ LEFT } ещё >
                </Link>
            );
        }
    }

    updateTitle = async () => {
        try {
            let SEND_DATA = {
                action: 'admin_retitleCat',
                id: this.state.CAT.id,
                title: this.state.TITLE,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ DO_EDIT: false });
            }
        } catch (error) {
        }

    }

    recordTitle = (input) => {
        this.setState({ TITLE: input.target.value });
    }

    renderTitle = () => {
        if (this.state.TITLE !== 'Популярное') {
            if (this.state.DO_EDIT) {
                return (
                    <h3>
                        <input onChange={ (text) => this.recordTitle(text) } className="inline_input" type="text" value={ this.state.TITLE } />
                        <a className="inline_edit confirm" onClick={ () => this.updateTitle() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT: false }) }><i className="fa fa-remove"></i></a>
                    </h3>
                )
            } else {
                return (
                    <h3>
                        { this.state.CAT.title } <a className="inline_edit" onClick={ () => this.setState({ DO_EDIT: true }) }><i className="fa fa-pencil"></i></a>
                    </h3>
                )
            }
        } else {
            return (
                <h3>
                    { this.state.CAT.title }
                </h3>
            )
        }
    }

    render() {
        if (typeof this.state.LIMITED !== "undefined") {
            return (
                <div className="category-carousel">
                    <div className="category-carousel-top">
                        { this.renderTitle() }
                        { this.props.noMore ? null : this.renderMoreButton() }
                    </div>

                    { this.renderCarousel() }
                </div>
            );
        } else {
            return (
                <div className="container-fluid">
                    <div className="category-carousel">
                        <div className="category-carousel-top">
                            { this.renderTitle() }
                            { this.props.noMore ? null : this.renderMoreButton() }
                        </div>

                        { this.renderCarousel() }
                    </div>
                </div>
            );
        }
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsScrollPreview))
