import React, {Component} from 'react';

export default class BirthdayInput extends Component {

    constructor(props) {
        super(props);
        this.state = {day: '', month: '', year: ''}
    }

    dayValue = (day) => {
        const d = Number(day.target.value)
        if ((d < 1 || d > 31 || d % 1 !== 0) && day.target.value !== '')
            return

        this.setState({day: day.target.value})
    }

    monthValue = (month) => {
        const m = Number(month.target.value)
        if ((m < 1 || m > 12 || m % 1 !== 0) && month.target.value !== '')
            return

        this.setState({month: month.target.value})
    }

    yearValue = (year) => {
        const y = Number(year.target.value)
        if ((y < 1 || y > 2021 || y % 1 !== 0) && year.target.value !== '')
            return

        this.setState({year: year.target.value})
    }


    /*    recordValue = (text) => {
            this.validate(text.target.value);
        }

        validateString = (string) => {
            var datePat = '[0-9]{4}-[0-9]{2}-[0-9]{2}';

            var matchArray = string.match(datePat);


            return matchArray != null;
        }*/

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.state) !== JSON.stringify(prevState))
            this.validate()
    }

    validate = () => {
        if (!!this.state.day && !!this.state.month && !!this.state.year && this.state.year.length === 4) {
            const date = `${this.state.day}-${this.state.month}-${this.state.year}`
            this.props.setField('BIRTHDAY', date)
        } else {
            this.props.resetField('BIRTHDAY');
        }
        /*if (this.validateString(string)) {
            this.props.setField('BIRTHDAY',string)
        } else {
            this.props.resetField('BIRTHDAY');
        }*/
    }

    render() {
        return (
            <div>
                <input type="number" placeholder={'ДД'}
                       style={{marginRight: '9px', padding: '0 14px', width: '75px', height: '55px'}}
                       onChange={day => this.dayValue(day)} value={this.state.day}/>
                <input type="number" maxLength='2' placeholder={'ММ'}
                       style={{marginRight: '9px', padding: '0 14px', width: '75px', height: '55px'}}
                       onChange={month => this.monthValue(month)} value={this.state.month}/>
                <input type="number" maxLength='4' placeholder={'ГГГГ'}
                       style={{padding: '0 14px', width: '114px', height: '55px'}}
                       onChange={year => this.yearValue(year)} value={this.state.year}/>
            </div>
        );
    }
}
