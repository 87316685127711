import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as ReduxActions from "../../../REDUX/functions";
import CONFIG from "../../../SYSTEM/Config";
import {OutsideAlerter, useWindowDimensions} from "../../HELPERS/hooks/hooks";
import {sendPostRequest} from "../../HELPERS/requests";
import styles from "./SubmitBonuses.module.css"
import cn from "classnames";
import {NavLink} from "react-router-dom";

function mapStateToProps(state) {
    return {
        USER: state.USER,
        PRODUCTS: state.PRODUCTS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


const ShareCode = ({USER, SHARE_CODE}) => {


    if (SHARE_CODE) {
        return (
            <div className="form-pop-append">
                <div className="share-actions">
                    <div className="sharer">
                        <a target="_blank" className="shareicon"
                           href={'http://api.whatsapp.com/send?text=' + USER.CODE}>
                            <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'}/>
                        </a>
                        <a target="_blank" className="shareicon"
                           href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + USER.CODE_MESSAGE}>
                            <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'}/>
                        </a>
                        <a target="_blank" className="shareicon"
                           href={'https://twitter.com/intent/tweet?text=' + USER.CODE_MESSAGE}>
                            <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'}/>
                        </a>
                        <a target="_blank" className="shareicon"
                           href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + USER.CODE_MESSAGE + '&description=' + USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                            <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'}/>
                        </a>
                        <a target="_blank" className="shareicon"
                           href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + USER.CODE_MESSAGE + '&description=' + USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                            <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'}/>
                        </a>
                        <a target="_blank" className="shareicon"
                           href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + USER.CODE_MESSAGE}>
                            <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'}/>
                        </a>
                        <a target="_blank" className="shareicon" href={'viber://forward?text=' + USER.CODE_MESSAGE}>
                            <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'}/>
                        </a>
                    </div>
                </div>
            </div>
        );
    } else return null
}

const Promo = ({USER, SHARE_CODE, setShareCode, doAuth}) => {

    const [CODE_ERROR, setCodeError] = useState(false)

    const getClassInput = () => {
        if (CODE_ERROR) {
            return 'with_error';
        }

        return '';
    }

    const {windowDimensions: {width}} = useWindowDimensions()

    const changeCodeNowActive = async (CODE) => {
        if (CODE.length > 5) {
            try {
                let SEND_DATA = {
                    action: 'changeCode',
                    code: CODE,
                    apikey: USER.TOKEN
                };
                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    doAuth(data.user);
                    setCodeError(false)
                    USER.CODE = CODE
                } else {
                    setCodeError(true);
                }
            } catch (error) {
                console.log('BAD PRODUCTS TIMER');
            }
        } else {
            setCodeError(true);
        }
    }

    const recordNewCodeNow = (code) => {
        changeCodeNowActive(code.target.value);
    }

    return <div className={styles.wrapperPromo}>
        <div className={styles.titlePromo}>А это ваш промокод!</div>
        <div className={styles.handPromo}>
            <img src="/system_images/promoHand.svg"/>
            <div className={styles.textPromo}>Поделитесь вашим промокодом с другом и получите <span
                style={{fontWeight: '700'}}>550 баллов!</span></div>
        </div>
        <div style={width > 650 ? {} : {paddingRight: '0'}} className="promocode_inner_block">
            <input className={cn(getClassInput(), styles.promo)} defaultValue={USER.CODE}
                   onKeyUp={(text) => recordNewCodeNow(text)}/>
            <div className="promocode_inner_block_flex">
                <a style={width > 650 ? {} : {maxWidth: '179px'}} className="copy_btn"
                   onClick={() => navigator.clipboard.writeText(USER.CODE)}>
                    Копировать
                </a>
                <OutsideAlerter callback={() => setShareCode(false)}>
                    <a className="promocode_block_share_button"
                       onClick={() => setShareCode(true)}>
                        <img src="/system_images/share_icon.png"/>
                    </a>
                </OutsideAlerter>
                {<ShareCode SHARE_CODE={SHARE_CODE} USER={USER}/>}
            </div>
        </div>
    </div>
}

const FiveHours = () => {

    const {windowDimensions: {width}} = useWindowDimensions()

    if (width > 650) {
        return <div className={styles.wrapperFive}>
            <img style={{marginBottom: '20px'}} src="/system_images/fiveHours.svg"/>
            <div className={styles.textFive}>
                Оформите заказ в течение 5&nbsp;часов и получите кэшбэк 500 экобаллов!
            </div>
            <div className={styles.buttonStart}>
                <NavLink to={'/'}>
                    <button>Начать покупки >></button>
                </NavLink>
            </div>
        </div>
    } else return <div className={styles.wrapperFive}>
        <div className={styles.fiveMobile}>
            <img style={{marginRight: '8px'}} src="/system_images/fiveHours.svg"/>
            <div className={styles.textFive}>
                Оформите заказ в течение 5 часов и получите кэшбэк 500 экобаллов!
            </div>
        </div>
        <div className={styles.buttonStart}>
            <NavLink to={'/'}>
                <button>Начать покупки >></button>
            </NavLink>
        </div>
    </div>


}

const OurApp = () => {

    const {windowDimensions: {width}} = useWindowDimensions()

    if (width > 650) {
        return <div className={styles.wrapperQR}>
            <div className={styles.titleQR}>Скачайте наше приложение</div>
            <div className={styles.iphoneBlock}>
                <img src="/system_images/phoneBonuses.svg"/>
                <div>и получите <span style={{fontWeight: '700'}}>300 баллов</span> на один заказа через приложение!
                </div>
            </div>
            <div className={styles.qrBlock}>
                <img src="/system_images/QRapp.svg"/>
                <div>наведите камеру смартфона на QR-код для перехода
                    на скачивание приложения
                </div>
            </div>
        </div>
    } else return <div className={styles.wrapperQR}>
        <div className={styles.iphoneBlock}>
            <img src="/system_images/phoneBonuses.svg"/>
            <div>Скачайте наше приложение и получите 300 баллов на один заказа через приложение!</div>
        </div>
        <div>
            <div className={styles.buttonStart}>
                <a href="http://onelink.to/9jdasv" target='_blank'>
                    <button>Скачать >></button>
                </a>
            </div>
        </div>
    </div>
}

const SubmitBonuses = ({USER, doAuth, bonuses = 1200}) => {

    const [shareCode, setShareCode] = useState(false)

    return <div>
        <div className={styles.mainWrapper}>
            <div className={styles.mainCards}>
                <Promo doAuth={doAuth} setShareCode={setShareCode} SHARE_CODE={shareCode} CODE={USER.CODE} USER={USER}/>
                <FiveHours/>
                <OurApp/>
            </div>
            <div className={styles.mainButton}>
                <NavLink to={'/'}>
                    <button>Перейти на главную страницу</button>
                </NavLink>
            </div>
        </div>
    </div>
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(SubmitBonuses);