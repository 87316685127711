import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Done extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            ORDER_ID: props.ORDER,
            NUMBER: props.NUMBER,
            EMAIL: '',
            NO_FORM: false,
            SECURITY: props.SECURITY,
            JOURNAL: 1,
            JOURNAL_SENT: false,
            PHONE: props.PHONE,
            MISSED: props.MISSED
        }
    }

    componentDidMount()
    {

    }

    openLK()
    {
        this.props.openOrder(false);
        this.props.openLK(true);
    }

    recordEmail = (text) => 
    {
        this.setState({EMAIL: text.target.value});
    }

    renderJournalButton = () =>
    {
        if (this.state.JOURNAL_SENT) {
            return (
                <div className="journal_button">
                    <span>Журнал будет доставлен Вам с заказом!</span>
                </div>
            );
        } else {
            return (
                <div className="journal_button">
                    <a onClick={() => this.appendJournal()}>Хочу получить выбранный журнал</a>
                </div>
            );
        }
    }

    journalCheck = (ID) =>
    {
        if (this.state.JOURNAL == ID) {
            return 'journal_radio_checked';
        } else {
            return 'journal_radio';
        }
    }

    getFlocktory =() =>
    {
        return <div className="i-flocktory" data-fl-action="exchange" data-fl-spot="some_spot" data-fl-user-name={this.props.USER.NAME} data-fl-user-email={this.state.PHONE.replace(/\D/g, "") + '@unknown.email'}></div>;
    }

    renderLowerBlock()
    {
        return (
            <div className="done_anketa_block">
                <div className="done_anketa_intro">
                    Заполните анкету<br/>и получите
                </div>
                <div className="done_anketa_intro_2">
                    1200 экобаллов
                </div>
                {this.getFlocktory()}
                <Link to="/closer" onClick={() => this.props.openOrder(false)} className="done_anketa_link"><img src="/system_images/anketa_icon.png"/> Перейти к анкете</Link>
            </div>
        );
        
        return (
            <div>
                <div className="journal_intro">
                    Если вы хотите вместе с заказом получить бесплатный журнал от наших партнёров, то просим вас ниже выбрать понравившийся журнал и подтвердить свой выбор
                </div>
                <div className="journal_radio_holder">
                    <div onClick={() => this.setState({JOURNAL: 1})}>
                        <img src="/system_images/journal_1.jpg" />
                        <span className={this.journalCheck(1)}></span>
                    </div>
                    <div onClick={() => this.setState({JOURNAL: 2})}>
                        <img src="/system_images/journal_2.jpg" />
                        <span className={this.journalCheck(2)}></span>
                    </div>
                </div>
                {this.renderJournalButton()}
            </div>
        );

        return (
            <div>
                <div className="insta_coins">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/coins.png'} />
                </div>
                <div className="insta_text">
                    За каждую вашу покупку в ECOMARKET.RU мы начисляем вам экобаллы на ваш <a onClick={() => this.openLK()}>счет в личном кабинете</a>
                </div>
                <div className="insta_green">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/insta_green.png'} />
                </div>
                <a target="_blank" href="https://www.instagram.com/ecomarket.ru/" className="insta_link">
                    Подписывайтесь на наш Instagram,
                </a>
                <div className="insta_after">
                    следите за новостями, участвуйте в конкурсах и выигрывайте!
                </div>
            </div>
        );
        
    }

    appendJournal = async () =>
    {
        try {
            let TOKEN = 'UNREGISTERED';
            if (this.props.USER) {
                TOKEN = this.props.USER.TOKEN;
            }

            let SEND_DATA = {
                action:     'appendJournal',
                id:         this.state.ORDER_ID,
                journal:    this.state.JOURNAL,
                apikey:     TOKEN,
                security:   this.state.SECURITY,

            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({JOURNAL_SENT: true})
            }
        } catch (error) {
            console.log('BAD_ORDER_DATA');
        }
    }

    sendToEmail = async () =>
    {
        try {
            let TOKEN = 'UNREGISTERED';
            if (this.props.USER) {
                TOKEN = this.props.USER.TOKEN;
            }

            let SEND_DATA = {
                action:     'sendOrderLetter',
                id:         this.state.ORDER_ID,
                email:      this.state.EMAIL,
                apikey:     TOKEN,
                security:   this.state.SECURITY,

            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({NO_FORM: true})
            }
        } catch (error) {
            console.log('BAD_ORDER_DATA');
        }
    }

    renderEmailForm()
    {
        let SHOW = false;
        if (!this.props.USER) {
            SHOW = true;
        } else {
            if (this.props.USER.EMAIL.length < 1) {
                SHOW = true;
            }
        }

        if (SHOW) {
            if (!this.state.NO_FORM) {
                return (
                    <div>
                        <div className="flex">
                            <div className="order_form_input_holder email_holder">
                                <input type="email" placeholder="mail@mail.ru" value={this.state.EMAIL}  onChange={(text) => this.recordEmail(text)} />
                            </div>
                            <a className="send_letter_to_mail" onClick={() => this.sendToEmail()}>
                                <span>Отправить</span>
                                <img src="/system_images/white_forward.png" />
                            </a>
                        </div>
                        <div className="form_letter_message">
                            Укажите email и мы пришлём информацию о вашем заказе
                        </div>
                    </div>
                );
            }
        }
    }

    appendJournal

	render()
    {
        return (
            <div className="order_form_holder">
                <div className="modal_content">
                    <div className="order_finals_block flex_block">
                        <div className="order_done_first_block">
                            <div className="done_head">
                                <img src="/system_images/green_check.png" /> Спасибо, Ваш заказ принят!
                            </div>
                            <div className="after_order_head">
                                Номер заказа: {this.state.NUMBER}
                            </div>
                            <div className="done_message">
                                Итоговая сумма вашего заказа может варьироваться в пределах 10%, если в заказе есть весовой товар.
                            </div>
                            {this.renderEmailForm()}
                        </div>

                        <div className="order_done_second_block">
                        </div>

                        {this.renderLowerBlock()}
                    </div>
                </div>
                <div className="modal_bottom">
                    <a className="green_done" onClick={() => this.props.openOrder(false)}>
                        На главную
                    </a>
                </div>
            </div>
        )         
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Done);