import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as ReduxActions from './../../../REDUX/functions.js';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MapHeaderWithAddress extends Component{

    render() {
        return(
            <img className='header-with-address-image' src='/system_images/AddressBar.png'></img>
        )
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(MapHeaderWithAddress);