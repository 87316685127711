import React, { Component } from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import LazyLoad from 'react-lazyload';
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';
import { sendPostRequest } from "./../../APP/HELPERS/requests"

import { isActive } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import MobileLocationData from "../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        DO_SEARCH: state.DO_SEARCH,
        SPEC_FILTER: state.SPEC_FILTER,
        SHOP: state.SHOP,
        MARGE_FILTER: state.MARGE_FILTER,
        WRITE_FILTER: state.WRITE_FILTER,
        REPACK: state.REPACK
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            STRING: this.props.match.params.string,
            FOUND: false,
            SEARCH_STRING: ''
        }
    }

    componentDidMount() {
        this.props.closeAllModals();
        if (this.props.match.params.string !== 'on') {
            let COMPONENT = this;
            setTimeout(function () {
                COMPONENT.filterProducts(COMPONENT.props.match.params.string);
            }, 1500);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.string !== prevProps.match.params.string) {
            this.props.closeAllModals();

            this.setState({ FOUND: false, STRING: this.props.match.params.string });

            if (this.props.match.params.string !== 'on') {
                this.filterProducts(this.props.match.params.string);
                this.forceUpdate();
            }
        }

        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
                this.forceUpdate();
            }
        }
    }

    filterProducts = async (text) => {
        if (text == 'on') {
            this.setState({ FOUND: false });
        } else {
            if (this.props.PRODUCTS) {
                let IDS = [];

                let PRODUCTS = this.props.PRODUCTS;

                if (this.props.PRODUCTS) {
                    Object.keys(PRODUCTS).forEach(key => {
                        let PRODUCT = PRODUCTS[key];

                        const itemData = PRODUCT.title.toUpperCase();
                        const textData = text.toUpperCase();
                        if (itemData.indexOf(textData) > -1) {
                            IDS.push(PRODUCT.id);
                        }
                    });
                }


                if (IDS.length) {
                    this.setSearchResults(IDS, text);
                    this.forceUpdate();
                } else {
                    this.setState({ FOUND: false });
                }
            } else {
                let COMPONENT = this;
                setTimeout(function () {
                    COMPONENT.filterProducts(COMPONENT.props.match.params.string);
                }, 500);
            }
            /*
            if (this.props.PRODUCTS) {
                try {
                    let SEND_DATA = {
                        action:     'searchString',
                        string:     text
                    };

                    let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                    if (data !== false) {
                        if (data.results.length) {
                            this.setSearchResults(data.results, text);
                            this.forceUpdate();
                        } else {
                            this.setState({FOUND: false});
                            this.forceUpdate();
                        }
                    } else {
                        this.setState({FOUND: false});
                    }
                } catch (error) {
                    this.setState({FOUND: false});
                }
            
            } else {
                let COMPONENT = this;
                setTimeout(function(){
                    COMPONENT.filterProducts(COMPONENT.props.match.params.string);
                }, 500);
            }
            */
        }
    }

    setSearchResults(IDS, text) {
        let PRODUCTS = this.props.PRODUCTS;
        let FOUND = [];
        let COMPONENT = this;

        let MAX = 10;
        for (let i = 0; i < IDS.length; i++) {
            let ID = IDS[i];
            if (typeof PRODUCTS[ID] !== "undefined") {
                if (isActive(PRODUCTS[ID], this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER, this.props.WRITE_FILTER, this.props.REPACK)) {
                    FOUND.push(ID);
                }
            }
        }


        this.setState({ FOUND: FOUND });
    }

    filterProducts_OLD = (text) => {
        if (text == 'on') {
            this.setState({ FOUND: false });
        } else {
            let PRODUCTS = this.props.PRODUCTS;
            let FOUND = [];
            let COMPONENT = this;

            if (this.props.PRODUCTS) {
                Object.keys(PRODUCTS).forEach(key => {
                    let PRODUCT = PRODUCTS[key];
                    if (isActive(PRODUCT, this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER, this.props.WRITE_FILTER, this.props.REPACK)) {
                        const itemData = PRODUCT.title.toUpperCase();
                        const textData = text.toUpperCase();
                        if (itemData.indexOf(textData) > -1) {
                            FOUND.push(PRODUCT.id);
                        }
                    }
                });
            }

            this.setState({ FOUND: FOUND });
            if (this.props.PRODUCTS) {
                if (this.interval) {
                    clearInterval(this.interval);
                }
            }
        }
    }

    getItemsClass() {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 'col-12';
        } else if (WIDTH < 769) {
            return 'col-6';
        } else if (WIDTH < 1050) {
            return 'col-4';
        } else if (WIDTH < 1200) {
            return 'col-3';
        } else if (WIDTH < 9900) {
            return 'el-5';
        }
    }

    renderH1() {
        if (this.state.STRING !== 'on') {
            return (
                <div className="row">
                    <div className="col-12">
                        <h1 className="catalog__title">
                            Поиск: { this.state.STRING }
                        </h1>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-12">
                        <h1 className="catalog__title">
                            Поиск
                        </h1>
                    </div>
                </div>
            );
        }

    }

    renderBreadCrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Главная'
        });
        DATA.push({
            'to': '/',
            'title': 'Поиск'
        });

        return <BreadCrumbs DATA={ DATA } />
    }

    renderRetailOne = () => {
        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return <div className="col-12"><div className="found_search" data-retailrocket-markup-block="5f7d817297a5251aec3fcc54" data-search-phrase={ this.state.STRING }></div></div>
        } else {
            return <div className="col-12"><div className="found_search" data-retailrocket-markup-block="5f7da36297a5282bb875d3be" data-search-phrase={ this.state.STRING }></div></div>
        }
    }


    renderRetailTwo = () => {
        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return <div className="col-12"><div className="not_found_search" data-retailrocket-markup-block="5f7d817997a5251aec3fcc55" data-search-phrase={ this.state.STRING }></div></div>
        } else {
            return <div className="col-12"><div className="not_found_search" data-retailrocket-markup-block="5f7da40297a5282bb875d3cc" data-search-phrase={ this.state.STRING }></div></div>
        }
    }

    renderProducts() {
        let CLASS = 'product_card ' + this.getItemsClass();
        let PRODUCTS = this.props.PRODUCTS;
        if (this.state.FOUND) {
            return (
                <div>
                    <div className="row products-row">
                        { this.state.FOUND.map(id => {
                            if (isActive(PRODUCTS[id], this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER, this.props.WRITE_FILTER, this.props.REPACK)) {
                                return <div className={ CLASS } key={ id }><LazyLoad height={ 300 }><ProductCard ID={ id } /></LazyLoad></div>
                            } else {
                                return null
                            }
                        }
                        ) }
                    </div>
                    { this.renderRetailOne() }
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-12">
                    </div>
                    { this.renderRetailTwo() }
                </div>
            );
        }
    }

    checkRerender() {
        if (this.state.STRING !== this.props.match.params.string) {
            if (this.props.match.params.string !== 'on') {
                this.filterProducts();
            }
        }
    }

    startSearch() {
        if (this.state.SEARCH_STRING.length > 2) {
            if (typeof window.rrApi !== "undefined") {
                try {
                    window.rrApi.search(this.state.STRING);
                } catch (e) { }
            }
            this.props.doSearch(this.state.SEARCH_STRING);
        }
    }

    somethingInputted = (string) => {
        let STRING = string.target.value;
        this.setState({ SEARCH_STRING: STRING });
    }

    handleSearch = (e) => {
        if (e.key === 'Enter') {
            this.startSearch()
        }
    }

    renderSearchForm() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="search_page_form">
                        <div className="searching">
                            <input
                                onKeyDown={ this.handleSearch }
                                className="searchinput"
                                type="text"
                                placeholder="Поиск"
                                onChange={ (text) => this.somethingInputted(text) }
                                value={ this.state.SEARCH_STRING }
                            />
                            <a className="search-btn" onClick={ () => this.startSearch() }><i className="fa fa-search"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        ///this.checkRerender();
        return (
            <CSSTransitionGroup
                transitionAppear={ true }
                transitionAppearTimeout={ 600 }
                transitionEnterTimeout={ 600 }
                transitionLeaveTimeout={ 200 }
                transitionName={ 'SlideIn' }
            >
                <div className="right-part">
                    <Helmet>
                        <title>Поиск</title>
                    </Helmet>

                    { this.renderBreadCrumbs() }
                    <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid">
                            { this.renderH1() }

                            { this.renderProducts() }
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
                <FooterBlock />
                <MobileMessage />
            </CSSTransitionGroup>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Search))