import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CartProducts from './../../../APP/COMPONENTS/ContentParts/CartProducts';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        REC: true,
        REGION: state.REGION,
        KM: state.KM,
        ADRESS: state.ADRESS

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CartContent extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            SUM: 0,
            CART: props.CART,
            PRODUCTS: props.PRODUCTS,
            REGION: props.REGION,
            KM: props.KM,
            ADRESS: props.ADRESS
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {

        let UPDATE = 0;
        if ((typeof this.props.CART !== "undefined") && (typeof this.props.PRODUCTS !== "undefined")) {
            let NEW_KEYS = Object.keys(prevProps.CART);
            let CURRENT_KEYS = Object.keys(this.props.CART);


            if (JSON.stringify(NEW_KEYS) !== JSON.stringify(CURRENT_KEYS)) {
                UPDATE = 1;
            }
        } else {
            UPDATE = 1;
        }

        if (UPDATE == 1) {
            this.setState({SUM: 0, CART: this.props.CART, PRODUCTS: this.props.PRODUCTS});
        }

        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }

        if (this.props.KM !== prevProps.KM) {
            this.setState({KM: this.props.KM});
        }

        if (this.props.ADRESS !== prevProps.ADRESS) {
            this.setState({ADRESS: this.props.ADRESS});
        }
    }

    emptyCart()
    {
        this.props.emptyCartModal(true);
    }

    countCart()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        return this.rmFixed2(SUM);
    }

    rmFixed2(numb)
    {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(parseFloat(numb).toFixed(2)).toFixed(2);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    getCityName = () =>
    {
        if (this.state.REGION == 78) {
            return 'Санкт-Петербургу';
        } else if (this.state.REGION == 77) {
            return 'Москве';
        } else {
            return 'городу';
        }
    }

    getOutsideTheCityName = () =>
    {
        if (this.state.REGION == 78) {
            return 'КАД';
        } else if (this.state.REGION == 77) {
            return 'МКАД';
        } else {
            return 'города';
        }
    }

    getSumMessageCity(SUM)
    {
        return 'Бесплатная доставка по ' + this.getCityName() + ' от 3500 р';
        if (parseInt(SUM) < 2000) {
            return 'Минимальный заказ на ' + CONFIG.MINIMAL + ' р';
        } else {
            return 'Бесплатная доставка по ' + this.getCityName() + ' от 3500 р';
        }
    }

    getOutsideTheCitySumMessage(SUM)
    {
        return 'Бесплатная доставка за пределами ' + this.getOutsideTheCityName() + ' от 8500 р';
        if (parseInt(SUM) < 2000) {
            return 'Минимальный заказ на ' + CONFIG.MINIMAL + ' р';
        } else {
            return 'Бесплатная доставка по ' + this.getOutsideTheCityName() + ' от 8500 р';
        }
    }
    getSumMessage = (SUM) => {
        if(this.state.ADRESS){
            if(parseInt(this.props.KM) == 0){
                return 'Бесплатная доставка при заказе от 3500 р';
                if (parseInt(SUM) < 2000) {
                    return 'Минимальный заказ на ' + CONFIG.MINIMAL + ' р';
                } else {
                    return 'Бесплатная доставка  при заказе от 3500 р';
                }
            }else{
                return 'Бесплатная доставка  при заказе от 8500 р';
                if (parseInt(SUM) < 2000) {
                    return 'Минимальный заказ на ' + CONFIG.MINIMAL + ' р';
                } else {
                    return 'Бесплатная доставка  при заказе от 8500 р';
                }
            }
        }else{
            let MESSAGE = this.getSumMessageCity(SUM);
            let MESSAGE_TWO = this.getOutsideTheCitySumMessage(SUM)
            return (
                <div>
                    {MESSAGE} <br/>
                    {MESSAGE_TWO}
                </div>
            )

        }

    }

    renderMessage()
    {
        let SUM = this.countCart();
        let MESSAGE = this.getSumMessage(SUM);
        let MESSAGE_TWO = this.getOutsideTheCitySumMessage(SUM)
        return (
            <div className="cart-message">
                <div className="flex_top">
{/*                    <div className="text-cart-part">
                        {this.getSumMessage()}
                    </div>*/}
                    <div className="top_cart_sum sum-cart-part">
                        Итог: <span className="top_cart_sum_numbers">{SUM} р</span>
                    </div>
                </div>
            </div>
        );
    }

    renderProducts()
    {
        return <CartProducts />
    }

    goToOrder()
    {
        if (this.props.USER) {
            window.location.href = CONFIG.API_C_DOMAIN + '/cart';
        } else {
            this.props.openLK(true);
        }
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }
    
    checkMaximum()
    {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    checkCartContents()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let RESULT = 0;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;
                        RESULT = 1;
                    }
                }
            }
        }

        this.SUM = SUM;

        return RESULT;
    }

    renderRetailOne = () => 
    {
        return false;

        const WIDTH = window.innerWidth;
        let IDS = [];
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        IDS.push(ID);
                    }
                }
            }
        }

        IDS = IDS.join(',');

        if (WIDTH > 980) {
            return (
                <div>
                    <div data-retailrocket-markup-block="5f7d818897a5251aec3fcc56" data-products={IDS}></div>
                </div>
            );
        } else {
            return (
                <div>
                    <div data-retailrocket-markup-block="5f7da41397a5282bb875d3cd" data-products={IDS}></div>
                </div>
            );
        }
    }

    renderRetailTwo = () => 
    {
        return false;

        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return (
                <div>
                    <div data-retailrocket-markup-block="5f7d819697a5251aec3fcc57"></div>
                </div>
            );
        } else {
            return (
                <div>
                    <div data-retailrocket-markup-block="5f7da42497a5282bb875d3d3"></div>
                    <script>retailrocket.markup.render();</script>
                </div>
            );
        }
    }

    renderCart()
    {
        let CART_OK = 0;

        let count = 0;
        for (let k in this.props.CART) {
            ++count;
        }

        if (count > 0) {
            CART_OK = this.checkCartContents();
        }

        if (CART_OK == 1) {
            return this.renderCartContent();
        } else {
            return this.renderCartEmpty();
        }
    }

    renderCartEmpty()
    {
        return (
            <div>
                <div className="form-fields text-center">
                    <div className="empty_cart_block">
                        <img src="/system_images/empty_basket.png" />
                    </div>
                </div>
                {this.renderRetailTwo()}
            </div>
        );
    }

    renderCartContent()
    {
        return (
            <div className="true_cart_content">
                {this.renderMessage()}
                {this.renderProducts()}
                {this.renderRetailOne()}
            </div>
        );
    }

	render()
    {
        return (
            <div className="modal_content">
                {this.renderCart()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CartContent);