import React, {Component} from 'react';
import {Switch, Route, withRouter, Redirect, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import MobileMessage from './../../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import InnerLoader from './../../../APP/COMPONENTS/ContentParts/InnerLoader';

import PhoneInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/PhoneInput.js';
import NameInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/NameInput.js';
import ProductInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/ProductInput.js';
import EmailInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/EmailInput.js';
import CityInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/CityInput.js';
import TextInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/TextInput.js';
import BirthdayInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/BirthdayInput.js';
import WhatsappInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/WhatsappInput.js';
import ImageRadio from './../../../APP/COMPONENTS/ContentParts/AnketaParts/ImageRadio.js';
import ImageRadioColumnMultiple from './../../../APP/COMPONENTS/ContentParts/AnketaParts/ImageRadioColumnMultiple.js';
import TextedRadio from './../../../APP/COMPONENTS/ContentParts/AnketaParts/TextedRadio.js';
import TextedRadioMultiple from './../../../APP/COMPONENTS/ContentParts/AnketaParts/TextedRadioMultiple.js';
import CountryInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/CountryInput.js';
import StarsInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/StarsInput.js';
import GroupSearchInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/GroupSearchInput.js';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CATS: state.CATS,
        TOKEN: state.TOKEN
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Poll extends Component {

    constructor(props)
    {
        super(props);

        let SHOW_ONE = true;
        let STEP = 'SHOW_ONE';
        let NUMBER = props.number;

        /*
        if (parseInt(NUMBER) > 5) {
            STEP = '0';
            SHOW_ONE = false;
        }
        */

        this.state = {
            CODE: props.CODE,
            POLL: props.POLL,
            PRODUCTS: props.PRODUCTS,
            STEP: STEP,
            ANSWERS: {
                COURRIER: 0,
                COURRIER_FALSE: [],
                COURRIER_OTHER: '',
                PRODUCTS: 0,
                PRODUCTS_FALSE: [],
                PRODUCTS_OTHER: '',
                WHERE_KNOW: [],
                WHERE_KNOW_OTHER: '',
                COMMENT: '',
                SHOW_ONE: false,
                SHOW_TWO: false,
                SHOW_THREE: false,
                SHOW_FOUR: false,
                PAYMENT: '',
                WORK: ''
            },
            SENT: false,
            PRODUCT: '',
            SHOWN_PRODUCTS: {},
            ANSWERS_PRODUCTS: {},
            ANSWERS_COURRIERS: {},
            NUMBER: NUMBER,
            SHOW_ONE: SHOW_ONE
        }

        this.elements = [];
        this.elements['SHOW_ZERO'] = React.createRef();
        this.elements['SHOW_ONE'] = React.createRef();
        this.elements['SHOW_TWO'] = React.createRef();
        this.elements['SHOW_THREE'] = React.createRef();
        this.elements['SHOW_FOUR'] = React.createRef();
        this.elements['SHOW_FIVE'] = React.createRef();

        this.props.limitNavs(true);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.CODE !== this.state.CODE) {
            this.setState({CODE: this.props.CODE, STEP: 0, SENT: false});
        }
    }

    setField = (TITLE, VALUE) =>
    {
        this.setState({[TITLE] : VALUE});
    }

    resetField = (TITLE) =>
    {
        this.setState({[TITLE] : ''});
    }

    scrollToPoll = (i) =>
    {
        window.scrollTo({top: this.elements[i].current.offsetTop - 150, behavior: "smooth"});
    }

    renderSubmit = () =>
    {
        return <button className="anketa-button" onClick={() => this.submit()}>Отправить ответы</button>
    }

    submit = async () =>
    {
        if (!this.SENDING) {
            this.SENDING = true;
            let SEND_DATA = {
                action:         'savePollData',
                token:          this.props.TOKEN,
                code:           this.state.CODE,
                answers:        this.state.ANSWERS,
                product:        this.state.PRODUCT,
                courriers_data: this.state.ANSWERS_COURRIERS,
                products_data:  this.state.ANSWERS_PRODUCTS,
                work:           this.state.ANSWERS.WORK
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SENT: true});
                this.SENDING = false;
            } else {
                this.SENDING = false;
            }
        }
    }

    renderStars = (ITEM) =>
    {
        return (
            <div>
                <div className="rating poll_rating">
                    <input name={ITEM.id} id={ITEM.id + '_star_5'} type="radio" value="5" onChange={() => this.select(ITEM.id, 5)} /><label className="full" htmlFor={ITEM.id + '_star_5'} title="5"><span className="star"></span><span className="number">5</span></label>
                    <input name={ITEM.id} id={ITEM.id + '_star_4'} type="radio" value="4" onChange={() => this.select(ITEM.id, 4)} /><label className="full" htmlFor={ITEM.id + '_star_4'} title="4"><span className="star"></span><span className="number">4</span></label>
                    <input name={ITEM.id} id={ITEM.id + '_star_3'} type="radio" value="3" onChange={() => this.select(ITEM.id, 3)} /><label className="full" htmlFor={ITEM.id + '_star_3'} title="3"><span className="star"></span><span className="number">3</span></label>
                    <input name={ITEM.id} id={ITEM.id + '_star_2'} type="radio" value="2" onChange={() => this.select(ITEM.id, 2)} /><label className="full" htmlFor={ITEM.id + '_star_2'} title="2"><span className="star"></span><span className="number">2</span></label>
                    <input name={ITEM.id} id={ITEM.id + '_star_1'} type="radio" value="1" onChange={() => this.select(ITEM.id, 1)} /><label className="full" htmlFor={ITEM.id + '_star_1'} title="1"><span className="star"></span><span className="number">1</span></label>
                </div>
            </div>
        );
    }

    select = (ID, ANSWER) =>
    {
        let ANSWERS = Object.assign({}, this.state.ANSWERS);
        ANSWERS[ID] = ANSWER;

        if ((parseInt(ID) == 1247) && (parseInt(ANSWER) < 4)) {
            this.setState({ANSWERS: ANSWERS});
        } else {
            let NEXT_STEP = parseInt(this.state.STEP) + 1;

            if ((this.state.STEP == Object.keys(ANSWERS).length - 1) &&(NEXT_STEP <= this.state.POLL.length - 1)) {
                this.setState({STEP: NEXT_STEP, ANSWERS: ANSWERS});
                this.scrollToPoll(NEXT_STEP);
            } else {
                this.setState({ANSWERS: ANSWERS});
            }
        }
    }

    selectCheck = (ID, ANSWER) =>
    {
        let ANSWERS = Object.assign({}, this.state.ANSWERS);
        if(ANSWERS[ID].indexOf(ANSWER) != -1)
        {
            for (let i = 0; i < ANSWERS[ID].length; i++){
                if (ANSWERS[ID][i] === ANSWER) {
                    ANSWERS[ID].splice(i, 1);
                }
            }
        } else {
            ANSWERS[ID].push(ANSWER);
        }

        this.setState({ANSWERS: ANSWERS});
    }

    selectRadio = (ID, ANSWER) =>
    {
        let ANSWERS = Object.assign({}, this.state.ANSWERS);
        ANSWERS[ID] = ANSWER;
        this.setState({ANSWERS: ANSWERS});
    }

    selectCourCheck = (ID, ANSWER) =>
    {
        let ANSWERS_COURRIERS = Object.assign({}, this.state.ANSWERS_COURRIERS);

        if (typeof ANSWERS_COURRIERS[ID] == "undefined") {
            ANSWERS_COURRIERS[ID] = [];
        }

        if(ANSWERS_COURRIERS[ID].indexOf(ANSWER) != -1)
        {
            for (let i = 0; i < ANSWERS_COURRIERS[ID].length; i++){
                if (ANSWERS_COURRIERS[ID][i] === ANSWER) {
                    ANSWERS_COURRIERS[ID].splice(i, 1);
                }
            }
        } else {
            ANSWERS_COURRIERS[ID].push(ANSWER);
        }

        this.setState({ANSWERS_COURRIERS: ANSWERS_COURRIERS});
    }

    selectProductCheck = (ID, ANSWER) =>
    {
        let ANSWERS_PRODUCTS = Object.assign({}, this.state.ANSWERS_PRODUCTS);

        if (typeof ANSWERS_PRODUCTS[ID] == "undefined") {
            ANSWERS_PRODUCTS[ID] = [];
        }

        if(ANSWERS_PRODUCTS[ID].indexOf(ANSWER) != -1)
        {
            for (let i = 0; i < ANSWERS_PRODUCTS[ID].length; i++){
                if (ANSWERS_PRODUCTS[ID][i] === ANSWER) {
                    ANSWERS_PRODUCTS[ID].splice(i, 1);
                }
            }
        } else {
            ANSWERS_PRODUCTS[ID].push(ANSWER);
        }

        this.setState({ANSWERS_PRODUCTS: ANSWERS_PRODUCTS});
    }

    recordText = (text, ID) =>
    {
        let VALUE = text.target.value;
        let ANSWERS = Object.assign({}, this.state.ANSWERS);
        ANSWERS[ID] = VALUE;
        this.setState({ANSWERS: ANSWERS});
    }

    renderOptions = (ITEM) =>
    {
        let OPTIONS = [];
        for (let i = 0; i < ITEM.answers.length; i++) {
            let ID = ITEM.id + '_' + i;
            let ANSWER = ITEM.answers[i];
            OPTIONS.push(
                <div className="poll_radio" key={ID}>
                    <input name={ITEM.id} id={ID} type="radio" value={ANSWER} required onChange={() => this.select(ITEM.id, ANSWER)} />
                    <label htmlFor={ID}>{ANSWER}</label>
                </div>
            );
        }

        return (
            <div>
                {OPTIONS}
            </div>
        );
    }

    renderProductCheckboxes = (ITEM) =>
    {
        let OPTIONS = [];
        for (let i = 0; i < ITEM.answers.length; i++) {
            let ID = ITEM.id + '_' + i;
            let ANSWER = ITEM.answers[i];
            OPTIONS.push(
                <div className="poll_radio" key={ID}>
                    <input name={ITEM.id} id={ID} type="checkbox" value={ANSWER} required onChange={() => this.selectProductCheck(ITEM.id, ANSWER)} />
                    <label htmlFor={ID}>{ANSWER}</label>
                </div>
            );
        }

        return (
            <div>
                {OPTIONS}
            </div>
        );
    }

    renderCourCheckboxes = (ITEM) =>
    {
        let OPTIONS = [];
        for (let i = 0; i < ITEM.answers.length; i++) {
            let ID = ITEM.id + '_' + i;
            let ANSWER = ITEM.answers[i];
            OPTIONS.push(
                <div className="poll_radio" key={ID}>
                    <input name={ITEM.id} id={ID} type="checkbox" value={ANSWER} required onChange={() => this.selectCourCheck(ITEM.id, ANSWER)} />
                    <label htmlFor={ID}>{ANSWER}</label>
                </div>
            );
        }

        return (
            <div>
                {OPTIONS}
            </div>
        );
    }

    renderSubCheckboxes = (ANSWER) =>
    {
        let INSIDE = '';
        if (this.state.ANSWERS['COURRIER_FALSE'].indexOf(ANSWER) != -1) {
            switch (ANSWER) {
                case 'Был невежлив':
                    INSIDE = this.renderCourCheckboxes({
                        id: ANSWER,
                        answers: ['По телефону','При встрече','Не помог занести пакеты']
                    });
                    return (
                        <div className="product_poll opened">
                            <div className="product_poll_content courrier_poll_content">
                                {INSIDE}
                            </div>
                        </div>
                    );
                    break;
                case 'Опоздал':
                    INSIDE = this.renderCourCheckboxes({
                        id: ANSWER,
                        answers: ['от 1 до 30 минут','от 30 минут до 1 часа','на 1 час и более','предупредил об опоздании','не предупредил об опоздании']
                    });
                    return (
                        <div className="product_poll opened">
                            <div className="product_poll_content courrier_poll_content">
                                {INSIDE}
                            </div>
                        </div>
                    );
                    break;
            }
        }
    }

    renderCourrierCheckboxes = (ITEM) =>
    {
        let OPTIONS = [];
        for (let i = 0; i < ITEM.answers.length; i++) {
            let ID = ITEM.id + '_' + i;
            let ANSWER = ITEM.answers[i];
            OPTIONS.push(
                <div className="poll_radio" key={ID}>
                    <input name={ITEM.id} id={ID} type="checkbox" value={ANSWER} required onChange={() => this.selectCheck(ITEM.id, ANSWER)} />
                    <label htmlFor={ID}>{ANSWER}</label>
                    {this.renderSubCheckboxes(ANSWER)}
                </div>
            );
        }

        return (
            <div>
                {OPTIONS}
            </div>
        );
    }

    renderCheckboxes = (ITEM) =>
    {
        let OPTIONS = [];
        for (let i = 0; i < ITEM.answers.length; i++) {
            let ID = ITEM.id + '_' + i;
            let ANSWER = ITEM.answers[i];
            OPTIONS.push(
                <div className="poll_radio" key={ID}>
                    <input name={ITEM.id} id={ID} type="checkbox" value={ANSWER} required onChange={() => this.selectCheck(ITEM.id, ANSWER)} />
                    <label htmlFor={ID}>{ANSWER}</label>
                </div>
            );
        }

        return (
            <div>
                {OPTIONS}
            </div>
        );
    }

    renderRadios = (ITEM) =>
    {
        let OPTIONS = [];
        for (let i = 0; i < ITEM.answers.length; i++) {
            let ID = ITEM.id + '_' + i;
            let ANSWER = ITEM.answers[i];
            OPTIONS.push(
                <div className="poll_radio real_radio" key={ID}>
                    <input name={ITEM.id} id={ID} type="radio" value={ANSWER} required onChange={() => this.selectRadio(ITEM.id, ANSWER)} />
                    <label htmlFor={ID}>{ANSWER}</label>
                </div>
            );
        }

        return (
            <div className="poll_radios_holder">
                {OPTIONS}
            </div>
        );
    }

    getClass = (i) =>
    {
        if (this.state.STEP >= i) {
            return 'poller';
        } else {
            return 'poller inactive';
        }
    }

    nextStep = () =>
    {
        let NEXT_STEP = parseInt(this.state.STEP) + 1;
        if (this.state.STEP == Object.keys(this.state.ANSWERS).length - 1) {
            if (NEXT_STEP <= this.state.POLL.length - 1) {
                this.setState({STEP: NEXT_STEP});
                this.scrollToPoll(NEXT_STEP);
            }
        }
    }

    renderNextButton = (i) =>
    {
        if (i !== this.state.POLL.length - 1) {
            return (
                <div className="text-center">
                    <a className="poll_next_question" onClick={() => this.nextStep()}>
                        <span className="poll_next_question_circle">
                            <img src="/system_images/white_angle_down.png"/>
                            <img src="/system_images/white_angle_down.png"/>
                        </span>
                        <span>Далее</span>
                    </a>
                </div>
            );
        }
    }

    showNext = (NEXT) =>
    {
        this.setState({[NEXT] : true});
        window.scrollTo({top: this.elements[NEXT].current.offsetTop - 300, behavior: "smooth"});
    }

    getClassName = (VARIABLE) =>
    {
        if (this.state[VARIABLE]) {
            return 'poller';
        } else {
            return 'poller hidden'
        }
    }

    toggleProductPoll = (id) =>
    {
        let SHOWN_PRODUCTS = this.state.SHOWN_PRODUCTS;

        if (typeof SHOWN_PRODUCTS[id] == "undefined") {
            SHOWN_PRODUCTS[id] = true;
        } else {
            delete(SHOWN_PRODUCTS[id]);
        }

        this.setState({SHOWN_PRODUCTS: SHOWN_PRODUCTS});
    }

    getProductPollClass = (id) =>
    {
        if (typeof this.state.SHOWN_PRODUCTS[id] !== "undefined") {
            return 'product_poll opened';
        }

        return 'product_poll';
    }

    renderAngleButton = (id) =>
    {
        let CLASS = 'product_poll_grey_angle';
        let IMAGE = '/system_images/angle_down.png';
        if ((typeof this.state.ANSWERS_PRODUCTS[id] !== "undefined") && (this.state.ANSWERS_PRODUCTS[id].length > 0)) {
            CLASS = 'product_poll_grey_angle red_angle';
            IMAGE = '/system_images/white_angle_down.png';
        }

        if (typeof this.state.SHOWN_PRODUCTS[id] !== "undefined") {
            IMAGE = '/system_images/angle_up.png';
            if ((typeof this.state.ANSWERS_PRODUCTS[id] !== "undefined") && (this.state.ANSWERS_PRODUCTS[id].length > 0)) {
                IMAGE = '/system_images/white_angle_up.png';
            }
        }

        return (
            <div className="product_poll_button">
                <div className={CLASS}>
                    <img src={IMAGE} />
                </div>
            </div>
        );
    }

    renderProducts()
    {
        let PRODUCTS = [];
        for (let i = 0 ; i < this.state.PRODUCTS.length; i++) {
            let PRODUCT = this.state.PRODUCTS[i];
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + PRODUCT.image + ')'};

            let INSIDE = this.renderProductCheckboxes({
                id: PRODUCT.id,
                answers: ['не спелый','переспелый','гнилой','помятый','просроченый','другое']
            });

            PRODUCTS.push(
                <div key={i} className="poll_product_holder">
                    <div className="button-holder row-card poll_product_item" onClick={() => this.toggleProductPoll(PRODUCT.id)}>
                        <div
                            className="image"
                            style={STYLE}
                        >
                        </div>
                        <div className="caption">
                            <a>
                                {PRODUCT.title}
                            </a>
                            <div className="product-amount">
                                {PRODUCT.amount} {PRODUCT.ed_izm}
                            </div>
                        </div>
                        {this.renderAngleButton(PRODUCT.id)}
                    </div>
                    <div className={this.getProductPollClass(PRODUCT.id)}>
                        <div className="product_poll_content">
                            <div className="question small_question">
                                Каким оказался этот товар:
                            </div>
                            {INSIDE}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div key={1 + '_1'} className="poller">
                <div className="question small_question">
                    Выберите, пожалуйста, товары, по которым у вас есть комментарии
                </div>
                <div className="answertextarea">
                    {PRODUCTS}
                </div>
            </div>
        );
    }

    renderContent = () =>
    {
        let INSIDE = false;
        let QUESTIONS = [];


        /// COURRIERS ///
        INSIDE = this.renderStars({id: 'COURRIER'});
        QUESTIONS.push(
            <div ref={this.elements['SHOW_ONE']} key={1} className={this.getClassName('SHOW_ONE')}>
                <div className="question">
                    <span><img src="/system_images/poll_c.png"/></span>Оцените работу курьера:
                </div>
                <div className="answertextarea">
                    {INSIDE}
                </div>
            </div>
        );
        if ((this.state.ANSWERS.COURRIER > 0) && (this.state.ANSWERS.COURRIER < 4)) {
            INSIDE = this.renderCourrierCheckboxes({
                id: 'COURRIER_FALSE',
                answers: ['Был невежлив','Был неопрятен','Был не в фирменной одежде','Опоздал','Невнятно говорил']
            });
            QUESTIONS.push(
                <div key={0 + '_1'} className="poller">
                    <div className="question small_question">
                        Скажите, пожалуйста, что вам не понравилось, и на что нам обратить внимание:
                    </div>
                    <div className="answertextarea">
                        {INSIDE}
                    </div>
                </div>
            );
        }
        if ((this.state.ANSWERS.COURRIER > 0) && (this.state.ANSWERS.COURRIER < 4)) {
            if(this.state.ANSWERS['COURRIER_FALSE'].indexOf('Другое') != -1)
            {
                QUESTIONS.push(
                    <div key={0 + '_2'} className="poller">
                        <div className="answertextarea">
                            <input type="text" placeholder="Уточните (необязательно)" required onChange={(text) => this.recordText(text, 'COURRIER_OTHER')} />
                        </div>
                    </div>
                );
            }
        }

        if (this.state.ANSWERS.COURRIER > 0) {
            if (!this.state.SHOW_TWO) {
                QUESTIONS.push(
                    <div className="text-center" key="que1">
                        <a className="poll_next_question no_hover" onClick={() => this.showNext('SHOW_TWO')}>
                                <span className="poll_next_question_circle">
                                    <img src="/system_images/white_angle_down.png"/>
                                    <img src="/system_images/white_angle_down.png"/>
                                </span>
                            <span>Далее</span>
                        </a>
                    </div>
                );
            }
        }


        /// PRODUCTS ///
        INSIDE = this.renderStars({id: 'PRODUCTS'});
        QUESTIONS.push(
            <div ref={this.elements['SHOW_TWO']} key={0} className={this.getClassName('SHOW_TWO')}>
                <div className="question">
                    <span><img src="/system_images/poll_p.png"/></span>Оцените качество продуктов:
                </div>
                <div className="answertextarea">
                    {INSIDE}
                </div>
            </div>
        );
        if ((this.state.ANSWERS.PRODUCTS > 0) && (this.state.ANSWERS.PRODUCTS < 4)) {
            QUESTIONS.push(this.renderProducts());
        }
        if (this.state.ANSWERS.PRODUCTS > 0) {
            if (!this.state.SHOW_THREE) {
                QUESTIONS.push(
                    <div className="text-center" key="que0">
                        <a className="poll_next_question no_hover" onClick={() => this.showNext('SHOW_THREE')}>
                                <span className="poll_next_question_circle">
                                    <img src="/system_images/white_angle_down.png"/>
                                    <img src="/system_images/white_angle_down.png"/>
                                </span>
                            <span>Далее</span>
                        </a>
                    </div>
                );
            }
        }



        /// WORK ///
        INSIDE = this.renderStars({id: 'WORK'});
        QUESTIONS.push(
            <div ref={this.elements['SHOW_THREE']} key="work"   className={this.getClassName('SHOW_THREE')}>
                <div className="question">
                    <span><img src="/system_images/poll_c.png"/></span>Оцените скорость доставки
                </div>
                <div className="answertextarea">
                    {INSIDE}
                </div>
            </div>
        );

        if (parseInt(this.props.POLL_ID) == 24) {
            if (this.state.ANSWERS.WORK > 0) {
                if (!this.state.SHOW_FOUR) {
                    QUESTIONS.push(
                        <div className="text-center" key="que0">
                            <a className="poll_next_question no_hover" onClick={() => this.showNext('SHOW_FOUR')}>
                                    <span className="poll_next_question_circle">
                                        <img src="/system_images/white_angle_down.png"/>
                                        <img src="/system_images/white_angle_down.png"/>
                                    </span>
                                <span>Далее</span>
                            </a>
                        </div>
                    );
                }
            }
        }




        if (parseInt(this.props.POLL_ID) == 24) {
            INSIDE = this.renderCheckboxes({
                id: 'WHERE_KNOW',
                answers: ['Посоветовал знакомый','Реклама на ТВ и Радио','Блогеры в Instagram','Реклама в Instagram','Реклама в Vk','Реклама в YouTube','Увидели на улице','Поисковики Яндекс, Google','Другое']
            });
            QUESTIONS.push(
                <div ref={this.elements['SHOW_FOUR']} key={2}  className={this.getClassName('SHOW_FOUR')}>
                    <div className="question">
                        <span><img src="/system_images/poll_w.png"/></span>Откуда вы узнали о нас?
                    </div>
                    <div className="answertextarea">
                        {INSIDE}
                    </div>
                </div>
            );

            if ((this.state.ANSWERS.COURRIER > 0) && (this.state.ANSWERS.PRODUCTS > 0)) {

                if (this.state.ANSWERS.WHERE_KNOW.length > 0) {
                    if(this.state.ANSWERS['WHERE_KNOW'].indexOf('Другое') != -1)
                    {
                        QUESTIONS.push(
                            <div key={2 + '_1'} className="poller">
                                <div className="answertextarea">
                                    <input type="text" placeholder="Уточните (необязательно)" required onChange={(text) => this.recordText(text, 'WHERE_KNOW_OTHER')} />
                                </div>
                            </div>
                        );
                    }
                }

            }
        }



        /*
                }
        */

        return (
            <div>
                {QUESTIONS}
                {this.renderSubmit()}
            </div>
        );
    }

    renderStatus = () =>
    {
        let ITEMS = [];
        let LENGTH = 4;
        if (this.state.NUMBER > 5) {
            LENGTH = 5;
        }
        for (let i = 0; i < LENGTH; i++) {
            let ITEM = this.state.POLL[i];
            ITEMS.push(<span key={i} className={this.getNavClass(i)}></span>)
        }

        return (
            <div className="poll_float_nav">
                {ITEMS}
            </div>
        );
    }

    getNavClass = (i) =>
    {
        let NORMAL = 'poll_circle';
        let ACTIVE = 'poll_circle active';

        if (i == 0) {
            if (this.state.ANSWERS.COURRIER > 0) {
                return ACTIVE;
            }
        }

        if (i == 1) {
            if (this.state.ANSWERS.PRODUCTS > 0) {
                return ACTIVE;
            }
        }

        if (i == 2) {
            if (this.state.ANSWERS.WHERE_KNOW.length > 0) {
                return ACTIVE;
            }
        }


        return NORMAL;
    }

    renderSubmit = () =>
    {
        if ((
            (this.state.ANSWERS.WHERE_KNOW.length > 0) && (this.state.ANSWERS.WORK > 0)
            ||
            ((parseInt(this.props.POLL_ID) == 26) && (this.state.ANSWERS.WORK > 0))
        )) {
            return (
                <div className="text-center">
                    <button type="button" className="poll_submit" onClick={() => this.submit()}>Отправить</button>
                </div>
            );
        }
    }

    renderRate = () =>
    {
        if ((this.state.ANSWERS.PRODUCTS > 4) && (this.state.ANSWERS.COURRIER > 4)) {
            return (
                <div className="rate_our_app">
                    <img src="/system_images/app_logo.png" />
                    <div className="rate_app_intro">
                        Нравится приложение<br/>
                        "Ecomarket.ru"?
                    </div>
                    <div className="rate_app_text">
                        Не могли бы вы поставить оценку<br/>
                        нашему приложению?
                    </div>
                    <a href="http://onelink.to/9jdasv" target="_blank">Перейти к оценке</a>
                </div>
            );
        }
    }

    renderInner = () =>
    {

        if (this.state.SENT) {
            window.postMessage('ANKETA_FILLED');
            if (typeof window.ReactNativeWebView !== "undefined") {
                window.ReactNativeWebView.postMessage('ANKETA_FILLED');
            }
            return (
                <div className="anketa">
                    <div className="poll_result">
                        <div className="poll_result_big">Большое спасибо за ваши ответы!</div>
                        {this.renderRate()}
                        <Link to="/" className="poll_return">Завершить</Link>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="anketa">
                    <h1 className="main-heading poll-heading">Помогите нам стать лучше</h1>
                    {this.renderContent()}
                </div>
            );
        }
    }

    render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            >
                <div id="products" className="page-content"  ref={this.myRef}>
                    {this.renderInner()}
                </div>
            </CSSTransitionGroup>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Poll)