import React, {Component, useState} from 'react';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"
import Cookies from 'universal-cookie';
import moment from "moment";

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


import MapHelper from "./../../../APP/HELPERS/MapHelper"

import DoPay from "./../../../APP/COMPONENTS/ContentParts/DoPay"
import Done from "./../../../APP/COMPONENTS/ContentParts/Done"
import RegionMap from "./../../../APP/COMPONENTS/ContentParts/RegionMap"
import PostalOffices from "./../../../APP/COMPONENTS/ContentParts/PostalOffices"
import FiveMinutes from "./../../../APP/COMPONENTS/ContentParts/FiveMinutes"
import {setAddressWithAddition} from "./../../../REDUX/functions.js";
import DoneNew from "./DoneNew";
import {store} from "../../../SYSTEM/Router";
import { daysInWeek } from 'date-fns';

const cookies = new Cookies();

function mapStateToProps(state) {
    return {
        REGION: state.REGION,
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        TOKEN: state.TOKEN,
        REF_CODE: state.REF_CODE,
        CLICK_ID: state.CLICK_ID,
        KH_ID: state.KH_ID,
        ADDING_TO_EXISTING: state.ADDING_TO_EXISTING,
        ADRESS: state.ADRESS,
        KM: state.KM,
        GPS: state.GPS,
        USEABLE_PERIODS: state.USEABLE_PERIODS,
        SHOWADRESS: state.SHOWADRESS,
        TEMP_ADRESS: state.TEMP_ADRESS,
        ADDRESS_WITH_ADDITION: state.ADDRESS_WITH_ADDITION,
        PolygonsMinutes: state.PolygonsMinutes,
        PolygonsDeliveryPrice: state.PolygonsDeliveryPrice,
        PolygonsFreeDeliveryWhenSum: state.PolygonsFreeDeliveryWhenSum,

        POST_DATA: state.POST_DATA
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {

        super(props);
        this.emptyInputRef = React.createRef()
        let PHONE = '';
        let NAME = '';
        let LAST_NAME = '';
        let MIDDLE_NAME = '';
        let ENTRANCE = '';
        let APPARTMENT = '';
        let FLOOR = '';
        let ADRESS = '';
        let EMAIL = '';
        let TEMP_ADRESS = '';
        let COMMENT = '';
        let ROUTE = 'FORM';

        if (this.props.USER) {
            PHONE = this.props.USER.PHONE;
            NAME = this.props.USER.NAME;
            LAST_NAME = this.props.USER.LAST_NAME;
            MIDDLE_NAME = this.props.USER.MIDDLE_NAME;
            ENTRANCE = props.USER.ENTRANCE;
            APPARTMENT = props.USER.APPARTMENT;
            FLOOR = props.USER.FLOOR;
            ADRESS = props.USER.ADRESS;
            EMAIL = props.USER.EMAIL;
            TEMP_ADRESS = props.USER.ADRESS;
            COMMENT = props.USER.DEL_COMMENT;
        }

        if (this.props.ADDRESS_WITH_ADDITION) {
            ENTRANCE = this.props.ADDRESS_WITH_ADDITION.entrance;
            APPARTMENT = this.props.ADDRESS_WITH_ADDITION.appartment;
            FLOOR = this.props.ADDRESS_WITH_ADDITION.floor;
            ADRESS = this.props.ADDRESS_WITH_ADDITION.adress;
            TEMP_ADRESS = this.props.ADDRESS_WITH_ADDITION.adress;
            COMMENT = this.props.ADDRESS_WITH_ADDITION.comment;
        }

        let PAYMENT = 1;
        let ORDER = 0;
        let SECURITY = '';
        let NUMBER = '';
        let MISSED = false;

        if (this.props.ADDING_TO_EXISTING) {
            PHONE = this.props.ADDING_TO_EXISTING.PHONE;
            PAYMENT = this.props.ADDING_TO_EXISTING.PAYMENT;
            ORDER = this.props.ADDING_TO_EXISTING.ID;
            SECURITY = this.props.ADDING_TO_EXISTING.SECURITY;
            NUMBER = this.props.ADDING_TO_EXISTING.NUMBER;
            ROUTE = this.props.ADDING_TO_EXISTING.ROUTE;
            MISSED = this.props.ADDING_TO_EXISTING.MISSED;
            let ROUTE_HEADING = this.props.ADDING_TO_EXISTING.ROUTE_HEADING;
            this.props.changeHead(ROUTE_HEADING, false);
            this.props.startAddingProductsToExistingOrder(false);
        }

        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            PAYMENT = 3;
        }

        ADRESS = props.ADRESS;

        let NEW_COUPON = '';
        if (!this.props.USER) {
            ///NEW_COUPON = 'eco_welcome';
        }

        this.state = {
            OK: false,
            SUGGESTIONS: false,
            PAYMENTS_DROP: false,
            BONUSES_DROP: false,
            COUPON_DROP: false,
            NEW_COUPON: false,
            ORDERING: false,


            NEW_BONUSES: 0,
            BONUSES_AMOUNT: 0,
            COUPON_DISCOUNT: false,
            COUPON_NAME: '',
            COUPON_TYPE: false,
            COUPON_ID: false,
            COUPON_AMOUNT: false,
            COUPON_DATA: {},
            NEW_COUPON: NEW_COUPON,

            PHONE: PHONE,
            NAME: NAME,
            ENTRANCE: ENTRANCE,
            APPARTMENT: APPARTMENT,
            FLOOR: FLOOR,
            MKAD: props.KM,
            GPS: [],
            COMMENT: COMMENT,
            ADRESS: ADRESS,
            EMAIL: EMAIL,
            TEMP_ADRESS: ADRESS,
            BONUSES: 0,
            DATES: [],
            DELIVERY_PRICE: 0,
            DEL_ADDON: 0,
            FREE_DELIVERY: 9999999999999999,
            PAYMENT: PAYMENT,
            TOTAL_SUM: 0,
            TIME: false,
            DATE: false,
            DELIVERY: 2,
            SECURITY: SECURITY,
            Update: 0,

            NAME_ERROR: false,
            PHONE_ERROR: false,
            ADRESS_ERROR: false,
            COUPON_ERROR: false,
            ADRESS_CONFIRMED: true,
            ROUTE: ROUTE,

            COUPON_ERROR: false,

            ORDER: ORDER,
            NUMBER: NUMBER,
            REGION: props.REGION,


            ORDER_ERROR: false,
            FREE_DEL: 0,
            MISSED: MISSED,
            MOVING_CART: props.CART,
            ACTIVE_CARD: [],
            FROM_MAP: true,
            USEABLE_PERIODS: props.USEABLE_PERIODS,
            ADDRESS_WITH_ADDITION: false,
            ORDERS_COUNT: 9999,

            POST_DATA: props.POST_DATA,
            DELIVERY_TYPE: false,
            DELIVERY_OFFICE_ADRESS: false,
            DELIVERY_OFFICE_ID: false,
            DELIVERY_DAYS: 0,
            SHOW_POSTAL_OFFICES: true

        };

        this.setMapHelper = this.setMapHelper.bind(this);
        this.setSugRef = this.setSugRef.bind(this);
        this.setPayRef = this.setPayRef.bind(this);
        this.setBonRef = this.setBonRef.bind(this);
        this.setCouRef = this.setCouRef.bind(this);
        this.setCityRef = this.setCityRef.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    adressConfirmed = (ADRESS, GPS, KM) => {

        this.setState({
            ROUTE: 'FORM',
            MKAD: KM,
            ADRESS: ADRESS,
            GPS: GPS,
            FROM_MAP: true
        });
        if (KM > 0) {
            this.updateTimes(KM);
        }
        this.countAppendDelivery(KM);
    }

    componentDidMount = () => {
        document.addEventListener('mousedown', this.handleClickOutside);

        this.fetchOrdersCount(this.state.PHONE)

        this.formStartUp();
        if (!this.props.USER) {
            this.useCoupon();
        }
    }

    fetchOrdersCount = async (phone) => {
        try {
            let SEND_DATA = {
                action: 'getOrdersCountByPhone',
                phone: phone

            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ORDERS_COUNT: data.orders_count});
            }
        } catch (error) {
            return false;
        }
    }

    formStartUp = async () => {
        /*
        this.validatePhone(this.state.PHONE);
        this.validateName(this.state.NAME);
        this.validateLastName(this.state.LAST_NAME);
        this.validateMiddleName(this.state.MIDDLE_NAME);
        */

        let sum_data = await this.countCart();
        let sum = sum_data.SUM;
        let free_del = sum_data.FREE_DEL;
        let bonuses = await this.getBonuses();

        let PAYMENT = 1;
        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            PAYMENT = 3;
        }

        this.setState({
            DATE: false,
            TIME: false,
            BONUSES: bonuses.bonuses,
            DATES: this.props.USEABLE_PERIODS,
            DELIVERY_PRICE: 299,
            FREE_DELIVERY: 3500,
            PAYMENT: PAYMENT,
            SUM: sum,
            TOTAL_SUM: sum,
            free_del: free_del,
            ACTIVE_CARD: bonuses.CARD
        });

        this.countAppendDelivery(this.props.KM);

        if (typeof bonuses.CARD.id !== "undefined") {
            this.setState({PAYMENT: 'ACTIVE_CARD'});
        }

        if (this.props.REF_CODE) {
            this.setState({NEW_COUPON: this.props.REF_CODE});
            this.useCoupon();
        }
    }

    getTitle = (REGION) => {
        let REGIONS = this.getRegions();

        let TITLE = '';
        for (let i = 0; i < REGIONS.length; i++) {
            if (REGION == REGIONS[i].code) {
                TITLE = REGIONS[i].title;
            }
        }

        return TITLE;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.ADRESS !== prevProps.ADRESS) {
            this.props.setAddressWithAddition(false);
            this.setState({
                ENTRANCE: '',
                FLOOR: '',
                APPARTMENT: '',
                COMMENT: ''
            });
        }

        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION, ADRESS: this.props.ADRESS});
            this.formStartUp();
        }

        if (this.props.USEABLE_PERIODS !== prevProps.USEABLE_PERIODS) {
            this.formStartUp();
        }
        if (this.props.ADRESS !== prevProps.ADRESS) {
            this.setState({ADRESS: this.props.ADRESS});
        }
        if (this.props.USEABLE_PERIODS !== prevProps.USEABLE_PERIODS) {
            this.setState({USEABLE_PERIODS: this.props.USEABLE_PERIODS})
        }

        if (this.props.KM !== this.state.MKAD) {
            this.setState({MKAD: this.props.KM});
        }
    }

    mapLoaded = (data) => {
        if (this.state.ADRESS.length > 0) {
            this.selectAdress(this.state.ADRESS);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    countDeliverySum = (SUM, FREE_DEL) => {
        let DELIVERY_SUM = this.state.DELIVERY_PRICE;

        if ((SUM > this.state.FREE_DELIVERY) || (FREE_DEL == 1)) {
            DELIVERY_SUM = 0;
        }

        if (this.props.USER.DELIVERY > 0) {
            DELIVERY_SUM += parseInt(this.props.USER.DELIVERY);
        }

        if (this.state.COUPON_NAME !== '') {
            if (this.state.COUPON_TYPE == 2) {
                DELIVERY_SUM = 0;
            }
            if (this.state.COUPON_TYPE == 5) {
                if (parseInt(this.state.COUPON_DATA.free_del) == 1) {
                    DELIVERY_SUM = 0;
                }
            }
            if (this.state.COUPON_TYPE == 6) {
                if (parseInt(this.state.COUPON_DATA.free_del) == 1) {
                    DELIVERY_SUM = 0;
                }
            }
        }

        if (this.state.DEL_ADDON) {
            DELIVERY_SUM += parseInt(this.state.DEL_ADDON);
        }

        if (this.props.PolygonsDeliveryPrice !== '-1') {
            DELIVERY_SUM = this.props.PolygonsDeliveryPrice
        }


        return DELIVERY_SUM;
    }

    recordCommerceData(ORDER_NUMBER, PRODUCTS, SUM, FREE_DEL) {
        /*
        if (typeof window.gtmdata !== "undefined") {
            let G_COMMERCE_PRODUCTS = [];
            let G_KEYS = Object.keys(PRODUCTS);

            if (G_KEYS.length) {
                for (let ID of G_KEYS) {
                    if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                        let PRODUCT = this.props.PRODUCTS[ID];
                        if (PRODUCT.availableones >= PRODUCTS[ID].amount) {       
                            G_COMMERCE_PRODUCTS.push({
                                "id": PRODUCT.id,
                                "name": PRODUCT.title,
                                "price": PRODUCT.price,
                                "quantity": PRODUCTS[ID].amount
                            });

                        }
                    }
                }
            }

            window.gtmdata.push({
                'ecommerce': {
                    'purchase': {
                        'actionField': {
                            'id': ORDER_NUMBER,
                            'revenue': SUM,
                            'shipping': this.countDeliverySum(SUM,FREE_DEL),
                            'coupon': this.state.COUPON_NAME
                        },
                        'products': G_COMMERCE_PRODUCTS
                    }
                }
            });

        }
        */

        if (typeof window.rrApi !== "undefined") {
            try {
                let RR_COMMERCE_PRODUCTS = [];
                let RR_KEYS = Object.keys(PRODUCTS);

                if (RR_KEYS.length) {
                    for (let ID of RR_KEYS) {
                        if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                            let PRODUCT = this.props.PRODUCTS[ID];
                            if (PRODUCT.availableones >= PRODUCTS[ID].amount) {
                                RR_COMMERCE_PRODUCTS.push({
                                    "id": PRODUCT.id,
                                    "price": PRODUCT.price,
                                    "qnt": PRODUCTS[ID].amount
                                });

                            }
                        }
                    }
                }

                window.rrApi.order({
                    transaction: ORDER_NUMBER,
                    items: RR_COMMERCE_PRODUCTS
                });
            } catch (e) {
            }
        }

        if (typeof window.gtag !== "undefined") {
            let G_COMMERCE_PRODUCTS = [];
            let G_KEYS = Object.keys(PRODUCTS);

            if (G_KEYS.length) {
                for (let ID of G_KEYS) {
                    if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                        let PRODUCT = this.props.PRODUCTS[ID];
                        if (PRODUCT.availableones >= PRODUCTS[ID].amount) {
                            G_COMMERCE_PRODUCTS.push({
                                "id": PRODUCT.id,
                                "name": PRODUCT.title,
                                "price": PRODUCT.price,
                                "quantity": PRODUCTS[ID].amount
                            });

                        }
                    }
                }
            }

            window.gtag('event', 'purchase', {
                "transaction_id": ORDER_NUMBER,
                "value": SUM,
                "currency": "RUB",
                "shipping": this.countDeliverySum(SUM, FREE_DEL),
                "items": G_COMMERCE_PRODUCTS
            });
        }

        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('MADE_A_PURCHASE');
        }


        if (typeof window.dataLayer !== "undefined") {
            let COMMERCE_PRODUCTS = [];
            let KEYS = Object.keys(PRODUCTS);

            if (KEYS.length) {
                for (let ID of KEYS) {
                    if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                        let PRODUCT = this.props.PRODUCTS[ID];
                        if (PRODUCT.availableones >= PRODUCTS[ID].amount) {
                            COMMERCE_PRODUCTS.push({
                                "id": PRODUCT.id,
                                "name": PRODUCT.title,
                                "price": PRODUCT.price,
                                "quantity": PRODUCTS[ID].amount
                            });

                        }
                    }
                }
            }

            window.dataLayer.push({
                "ecommerce": {
                    "purchase": {
                        "actionField": {
                            "id": ORDER_NUMBER
                        },
                        "products": COMMERCE_PRODUCTS
                    }
                }
            });
        }
    }

    prepareProducts = () => {
        let CART = this.props.CART;
        let NEW_CART = [];
        for (let ID in CART) {
            let PRODUCT_ITEM = CART[ID];

            if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                NEW_CART[ID] = PRODUCT_ITEM.amount;
            }
        }

        return NEW_CART;
    }

    getGA = () =>
    {
        let ga = cookies.get('_ga');
        if (ga !== null) {
            return ga;
        }

        return 'неизвестно';
    }

    submitOrder = async () => {
        this.setState({ORDERING: true});

        let TOKEN = 'UNREGISTERED';
        if (this.props.USER) {
            TOKEN = this.props.USER.TOKEN;
        }

        let PAYMENT = 3;
        let CARD = 0;
        if (this.state.PAYMENT == 'ACTIVE_CARD') {
            PAYMENT = 3;
            CARD = this.state.ACTIVE_CARD.id;
        } else {
            PAYMENT = this.state.PAYMENT;
        }


        let polygon = this.props.PolygonsMinutes !== '-1'

        try {
            let SEND_DATA = {
                action: 'submitOrderFromApp_v2',
                apikey: TOKEN,
                gps: this.props.GPS,
                products: this.prepareProducts(),
                name: this.state.NAME,
                email: this.state.EMAIL,
                adress: this.state.ADRESS,
                phone: this.state.PHONE,
                coupon: this.state.COUPON_NAME,
                bonuses: this.state.BONUSES_AMOUNT,
                date: this.state.DATE,
                time: this.state.TIME,
                delivery: this.state.DELIVERY,
                payment: PAYMENT,
                comment: this.state.COMMENT, //this.state.COMMENT,
                appartment: this.state.APPARTMENT, //this.state.APPARTMENT,
                floor: this.state.FLOOR, //this.state.FLOOR,
                entrance: this.state.ENTRANCE, //this.state.ENTRANCE,
                mkad: this.state.MKAD,
                delivery_addon: this.state.DEL_ADDON,
                point: 0,
                source: 0,
                region: this.state.REGION,
                clickid: this.props.CLICK_ID,
                card: CARD,
                kh_id: this.props.KH_ID,
                ga:this.getGA(),
                polygon,

                delivery_id: this.state.DELIVERY,
                delivery_tarif_id: this.state.DELIVERY_TYPE,
                delivery_tarif_title: this.state.DELIVERY_TYPE_TITLE,
                delivery_office_id: this.state.DELIVERY_OFFICE_ID,
                delivery_office_adress: this.state.DELIVERY_OFFICE_ADRESS,
                delivery_price: this.state.DELIVERY_PRICE,
                middle_name: this.state.MIDDLE_NAME,
                last_name: this.state.LAST_NAME
            };



            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                let sum_data = this.countCart();
                let SUM = sum_data.SUM;
                let FREE_DEL = sum_data.FREE_DEL;
                let c = this.state.COUPON_DATA

                let ids = Object.keys(this.props.CART)

                const getCategory = (id) => {
                    let CAT = null

                    store.getState().CATS.forEach(cat => {
                        if (id) {
                            if (cat.id == id) {
                                CAT = cat
                            } else {
                                cat.children.forEach(subcat => {
                                    if (subcat.id == id)
                                        CAT = subcat
                                })
                            }
                        } else CAT = null
                    })

                    return CAT
                }

                let products = ids.map(id => {
                    const prod = this.props.PRODUCTS[parseInt(id)]
                    if (prod) {
                        return {
                            'id': prod.id,
                            'name': prod.title,
                            'price': prod.price,
                            'brand': prod.brand || null,
                            'category': getCategory(prod.all_groups[0])?.title || null,
                            'quantity': this.props.CART[parseInt(id)].amount
                        }
                    } else return {}
                })

                let items = ids.map(id => {
                    const prod = this.props.PRODUCTS[parseInt(id)]
                    if (prod) {
                        return {
                            'item_id': prod.id,
                            'item_name': prod.title,
                            'price': prod.price,
                            'item_brand': prod.brand || null,
                            'item_category': getCategory(prod.all_groups[0])?.title || null,
                            'item_category2': getCategory(prod.all_groups[1])?.title || null,
                            'item_category3': getCategory(prod.all_groups[2])?.title || null,
                            'item_category4': getCategory(prod.all_groups[3])?.title || null,
                            'item_category5': getCategory(prod.all_groups[4])?.title || null,
                            'quantity': this.props.CART[parseInt(id)].amount,
                            'discount': prod.old_price ? (prod.old_price - prod.price) : null
                        }
                    } else return {}
                })

                if (typeof data.error == "undefined") {

                    this.recordCommerceData(data.number, this.props.CART, SUM, FREE_DEL);

                    if (data.missed.length) {
                        try {
                            window.dataLayer.push({ecommerce: null});
                            window.dataLayer.push({
                                'ecommerce': {
                                    'currencyCode': 'RUB',
                                    'purchase': {
                                        'actionField': {
                                            'id': data.order,
                                            'revenue': `${SUM + this.state.DELIVERY_PRICE}`,
                                            'shipping': `${this.state.DELIVERY_PRICE}`,
                                            'coupon': c.name || null
                                        },
                                        'products': products,
                                    },
                                    'items': items,
                                },
                                'event': 'gtm-ee-event',
                                'event-GA4': 'purchase',
                                'gtm-ee-event-category': 'Enhanced Ecommerce',
                                'gtm-ee-event-action': 'Purchase',
                                'gtm-ee-event-non-interaction': 'False',
                                'payment_type': parseInt(this.state.PAYMENT) === 1 ? 'cash' : parseInt(this.state.PAYMENT) === 3 ? 'card' : null,
                                'order_type': parseInt(this.props.USER.orders_count) > 0 ? 'repeat' : 'new',
                            });

                        } catch {
                        }

                        this.setState({
                            ROUTE: 'FIVE_MINUTES',
                            ORDER: data.order,
                            NUMBER: data.number,
                            SECURITY: data.security,
                            PHONE: this.state.PHONE,
                            ORDERING: false,
                            MISSED: data.missed
                        });

                        this.props.changeHead('Заказ принят', false);

                    } else {
                        if ((parseInt(this.state.PAYMENT) !== 3) && (parseInt(this.state.PAYMENT) !== 5)) {
                            window.dataLayer.push({ecommerce: null});
                            window.dataLayer.push({
                                'ecommerce': {
                                    'currencyCode': 'RUB',
                                    'purchase': {
                                        'actionField': {
                                            'id': data.order,
                                            'revenue': `${SUM + this.state.DELIVERY_PRICE}`,
                                            'shipping': `${this.state.DELIVERY_PRICE}`,
                                            'coupon': c.name || null
                                        },
                                        'products': products,
                                    },
                                    'items': items,
                                },
                                'event': 'gtm-ee-event',
                                'event-GA4': 'purchase',
                                'gtm-ee-event-category': 'Enhanced Ecommerce',
                                'gtm-ee-event-action': 'Purchase',
                                'gtm-ee-event-non-interaction': 'False',
                                'payment_type': parseInt(this.state.PAYMENT) === 1 ? 'cash' : null,
                                'order_type': parseInt(this.props.USER.orders_count) > 0 ? 'repeat' : 'new',
                            });

                            this.setState({
                                ROUTE: 'DONE',
                                ORDER: data.order,
                                NUMBER: data.number,
                                SECURITY: data.security,
                                PHONE: this.state.PHONE,
                                ORDERING: false,
                                MISSED: data.missed
                            });
                            this.props.changeHead('Заказ принят', false);
                        } else {
                            window.dataLayer.push({ecommerce: null});
                            window.dataLayer.push({
                                'ecommerce': {
                                    'currencyCode': 'RUB',
                                    'purchase': {
                                        'actionField': {
                                            'id': data.order,
                                            'revenue': `${SUM + this.state.DELIVERY_PRICE}`,
                                            'shipping': `${this.state.DELIVERY_PRICE}`,
                                            'coupon': c.name || null
                                        },
                                        'products': products,
                                    },
                                    'items': items,
                                },
                                'event': 'gtm-ee-event',
                                'event-GA4': 'purchase',
                                'gtm-ee-event-category': 'Enhanced Ecommerce',
                                'gtm-ee-event-action': 'Purchase',
                                'gtm-ee-event-non-interaction': 'False',
                                'payment_type': parseInt(this.state.PAYMENT) === 3 ? 'card' : null,
                                'order_type': parseInt(this.props.USER.orders_count) > 0 ? 'repeat' : 'new',
                            });

                            this.setState({
                                ROUTE: 'DO_PAY',
                                ORDER: data.order,
                                NUMBER: data.number,
                                SECURITY: data.security,
                                PHONE: this.state.PHONE,
                                ORDERING: false,
                                MISSED: data.missed
                            });
                            this.props.changeHead('Оплата', false);
                        }
                    }

                    this.props.emptyCart();
                } else {
                    this.setState({ORDERING: false, ORDER_ERROR: data.error});
                }
            } else {
                this.setState({ORDERING: false});
            }

        } catch (error) {
            this.setState({ORDERING: false});
        }
    }

    countCart() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        let FREE_DEL = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;
                        if (PRODUCTS[ID].fd > 0) {
                            FREE_DEL = 1;
                        }
                    }
                }
            }
        }

        this.SUM = SUM;

        return {
            FREE_DEL,
            SUM
        };
    }

    getAllowedOrderTimes = async (mkad) => {
        try {
            let SEND_DATA = {
                action: 'getAllowedOrderTimes_v2',
                region: this.state.REGION,
                apikey: this.props.TOKEN,
                products: this.props.CART,
                mkad: mkad
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                return data;
            }
        } catch (error) {
            console.log('BAD PRODUCTS TIMER');
        }
    }

    updateTimes = async (KM) => {
        let datetime = await this.getAllowedOrderTimes(KM);
        this.setState({DATES: datetime.DATES});
    }

    getClosestPostalDeliveryTime = async () =>
    {
        try {
            let SEND_DATA = {
                action:    'getPostalDeliveryTimeAndFees',
                adress:     this.props.ADRESS,
                products: this.preparePostalProducts()
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                this.setState({POST_DATA: data});
            }
        } catch (error) {
            console.log('BAD PRODUCTS TIMER');
        }
    }

    getBonuses = async () => {
        if (this.props.USER) {
            try {
                let SEND_DATA = {
                    action: 'getBonusesAndShares',
                    apikey: this.props.USER.TOKEN,
                };
                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.props.setBonusesAmount(data.bonuses);
                    return data;
                } else {
                    return {bonuses: 0, CARD: {}}
                }
            } catch (error) {
                return {bonuses: 0, CARD: {}}
            }
        } else {
            return {bonuses: 0, CARD: {}}
        }
    }

    handleClickOutside(event) {
        if (this.sug && !this.sug.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
        if (this.pay && !this.pay.contains(event.target)) {
            if (this.state.PAYMENTS_DROP) {
                this.setState({PAYMENTS_DROP: false});
            }
        }
        if (this.bon && !this.bon.contains(event.target)) {
            if (this.state.BONUSES_DROP) {
                this.setState({BONUSES_DROP: false});
            }
        }
        if (this.cou && !this.cou.contains(event.target)) {
            if (this.state.COUPON_DROP) {
                this.setState({COUPON_DROP: false, COUPON_ERROR: false});
            }
        }
        if (this.cit && !this.cit.contains(event.target)) {
            if (this.state.SHOW_CITIES) {
                this.setState({SHOW_CITIES: false});
            }
        }

    }

    setCityRef(node) {
        this.cit = node;
    }

    setSugRef(node) {
        this.sug = node;
    }

    setPayRef(node) {
        this.pay = node;
    }

    setBonRef(node) {
        this.bon = node;
    }

    setCouRef(node) {
        this.cou = node;
    }

    setMapHelper = (node) => {
        this.mapHelper = node;
    }

    renderSuggestions(suggestions) {
        let RESULTS = [];

        for (let i = 0; i < suggestions.length; i++) {
            RESULTS.push(
                <a
                    key={i}
                    onClick={() => this.selectAdress(suggestions[i].TITLE)}
                >
                    {suggestions[i].TITLE}
                </a>
            )
        }

        let SUGGESTIONS = (
            <div className="suggestions_block" ref={this.setSugRef}>
                {RESULTS}
            </div>
        );

        this.setState({SUGGESTIONS: SUGGESTIONS, ADRESS_ERROR: false, ADRESS_CONFIRMED: false});
    }

    setSuggestions = (suggestions) => {
        if (suggestions.length) {
            this.renderSuggestions(suggestions);
        } else {
            this.setState({SUGGESTIONS: false, ADRESS_ERROR: true, ADRESS_CONFIRMED: false});
        }
    }

    setCheckedAdress = (result, adress, gps, mkad) => {
        if (result) {
            this.setState({
                TEMP_ADRESS: adress,
                ADRESS: adress,
                ADRESS_ERROR: false,
                ADRESS_CONFIRMED: true,
                GPS: gps,
                MKAD: mkad
            });
            this.countAppendDelivery()
        } else {
            this.adressInput.focus();
            this.setState({TEMP_ADRESS: adress, ADRESS: adress, ADRESS_CONFIRMED: false, GPS: [], MKAD: 0});
            this.mapHelper.getSuggestions(adress);
            this.countAppendDelivery()
        }
    }

    countAppendDelivery(KM) {
        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            this.getClosestPostalDeliveryTime();
        } else {
            if (typeof KM == "undefined") {
                KM = this.state.MKAD;
            }

            if (KM > 0) {
                let sum_data = this.countCart();
                let sum_default = sum_data.SUM;
                let FREE_DEL = sum_data.FREE_DEL;

                let LEFT_KM = 0;
                let km = KM;

                let default_delivery_price = this.state.DELIVERY_SUM;

                let SET_SUM = 0;

                if (sum_default < 8500) {
                    if (km < 30) {
                        SET_SUM = km * 30;
                    } else {
                        LEFT_KM = km - 30;
                        SET_SUM = 30 * 30 + (LEFT_KM * 50);
                    }
                } else {
                    if (km > 30) {
                        var use_km = km - 30;
                        SET_SUM = use_km * 50;
                    }
                }

                let mkad = km;
                let delivery_add = 0;

                if (SET_SUM > 0) {
                    delivery_add = SET_SUM;
                }

                this.setState({DEL_ADDON: delivery_add});
            }
        }
    }

    selectAdress = (adress) => {
        this.setState({SUGGESTIONS: false, TEMP_ADRESS: adress, ADRESS: adress, ADRESS_ERROR: true});
        this.mapHelper.checkAdress(adress);
    }

    recordPhone = (text) => {
        this.setState({PHONE: text.target.value});
        this.validatePhone(text.target.value);
    }

    recordName = (text) => {
        this.setState({NAME: text.target.value});
        this.validateName(text.target.value);
    }

    recordLastName = (text) => {
        this.setState({LAST_NAME: text.target.value});
        this.validateLastName(text.target.value);
    }

    recordMiddleName = (text) => {
        this.setState({MIDDLE_NAME: text.target.value});
        this.validateMiddleName(text.target.value);
    }

    recordAdress = (text) => {
        let ADRESS = text.target.value;
        if (ADRESS.length > 5) {
            this.setState({ADRESS: ADRESS, ADRESS_CONFIRMED: true, GPS: [], MKAD: 0});
        } else {
            this.setState({ADRESS: ADRESS, ADRESS_CONFIRMED: false, GPS: [], MKAD: 0});
        }
        ///this.mapHelper.getSuggestions(text.target.value);
    }

    recordEntrance = (text) => {
        this.setState({ENTRANCE: text.target.value,});
        this.setTempAddressOgj(text.target.value, 'ENTRANCE')

    }

    recordAppartment = (text) => {
        this.setState({APPARTMENT: text.target.value});
        this.setTempAddressOgj(text.target.value, 'APPARTMENT')
    }

    recordFloor = (text) => {
        this.setState({FLOOR: text.target.value});
        this.setTempAddressOgj(text.target.value, 'FLOOR')
    }

    recordComment = (text) => {
        this.setState({COMMENT: text.target.value});
        this.setTempAddressOgj(text.target.value, 'COMMENT')
    }

    setTempAddressOgj = (data, type) => {
        let tempFullAddress = []

        let ADDRESS_WITH_ADDITION = this.state.ADDRESS_WITH_ADDITION;

        if (!ADDRESS_WITH_ADDITION) {
            ADDRESS_WITH_ADDITION = {
                entrance: this.state.ENTRANCE,
                floor: this.state.FLOOR,
                comment: this.state.COMMENT,
                appartment: this.state.APPARTMENT
            };
        }

        switch (type) {
            case "COMMENT":
                ADDRESS_WITH_ADDITION.comment = data;
                break

            case "FLOOR":
                ADDRESS_WITH_ADDITION.floor = data;
                break

            case "APPARTMENT":
                ADDRESS_WITH_ADDITION.appartment = data;
                break

            case "ENTRANCE":
                ADDRESS_WITH_ADDITION.entrance = data;
                break

        }


        this.props.setAddressWithAddition(ADDRESS_WITH_ADDITION);
        this.setState({ADDRESS_WITH_ADDITION: ADDRESS_WITH_ADDITION});
    }

    validateName = (name) => {
        if (name.length > 1) {
            if (this.state.NAME_ERROR) {
                this.setState({NAME_ERROR: false});

            }
        } else {
            if (!this.state.NAME_ERROR) {
                this.setState({NAME_ERROR: true});
            }
        }
    }

    validateMiddleName = (name) => {
        if (typeof name == "undefined") {
            if (!this.state.MIDDLE_NAME_ERROR) {
                this.setState({MIDDLE_NAME_ERROR: true});
            }
            return;
        }
        if (name.length > 1) {
            if (this.state.MIDDLE_NAME_ERROR) {
                this.setState({MIDDLE_NAME_ERROR: false});

            }
        } else {
            if (!this.state.MIDDLE_NAME_ERROR) {
                this.setState({MIDDLE_NAME_ERROR: true});
            }
        }
    }

    validateLastName = (name) => {
        if (typeof name == "undefined") {
            if (!this.state.LAST_NAME_ERROR) {
                this.setState({LAST_NAME_ERROR: true});
            }
            return;
        }

        if (name.length > 1) {
            if (this.state.LAST_NAME_ERROR) {
                this.setState({LAST_NAME_ERROR: false});

            }
        } else {
            if (!this.state.LAST_NAME_ERROR) {
                this.setState({LAST_NAME_ERROR: true});
            }
        }
    }

    validatePhone = (phone) => {
        let phoneNumber = phone.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
            if (this.state.PHONE_ERROR) {
                this.setState({PHONE_ERROR: false});
                this.fetchOrdersCount(phone);
            }
        } else {
            if (!this.state.PHONE_ERROR) {
                this.setState({PHONE_ERROR: true, ORDERS_COUNT: 9999});
            }
        }
    }

    getRegions = () => {
        let REGIONS = [
            {code: 77, title: 'Москва и Московская область', link: 'https://ecomarket.ru'},
            {code: 78, title: 'Санкт-Петербург и Ленинградская область', link: 'https://spb.ecomarket.ru'},
            {code: 16, title: 'Казань и Республика Татарстан', link: 'https://kaz.ecomarket.ru'},
            {code: 52, title: 'Нижний Новгород и Нижегородская область', link: 'https://nnov.ecomarket.ru'},
            {code: 61, title: 'Ростов-на-Дону и Ростовская область', link: 'https://rdn.ecomarket.ru'}
        ];

        return REGIONS;
    }


    changeThisRegion = (REGION) => {
        if (parseInt(REGION) > 0) {
            this.props.changeRegion(REGION);
            this.setState({SHOW_CITIES: false, DATE: false, TIME: false, ROUTE: 'MAP'});
        } else {
            this.props.changeRegion(REGION);
            this.setState({SHOW_CITIES: false, DATE: false, TIME: false, FROM_MAP: false});
        }
    }


    renderCities() {
        if (this.state.SHOW_CITIES) {
            let REGIONS = this.getRegions();

            let RENDERED_REGIONS = [];
            for (let i = 0; i < REGIONS.length; i++) {
                RENDERED_REGIONS.push(
                    <a key={i} onClick={() => this.changeThisRegion(REGIONS[i].code)}>
                        {REGIONS[i].title}
                    </a>
                );
            }

            return (
                <div className="suggestions_block" ref={this.setCityRef}>
                    {RENDERED_REGIONS}
                </div>
            );
        }
    }

    changeCity = () => {
        if (this.state.SHOW_CITIES) {
            this.setState({SHOW_CITIES: false});
        } else {
            this.setState({SHOW_CITIES: true});
        }
    }

    renderCitySelect() {
        return (
            <div className="order_form_input_holder payment_holder">
                <a className="payment_selected" onClick={() => this.changeCity()}>
                    {this.getTitle(this.state.REGION)}
                </a>
                {this.renderCities()}
            </div>
        );
    }

    renderAdressHolder = () => {
        if (this.state.FROM_MAP) {
            return (
                <div className="order_form_input_holder adress_holder">
                    <div className="adress_form_line">{this.state.ADRESS}</div>
                </div>
            );
        } else {
            return (
                <div className="order_form_input_holder adress_holder">
                    <label>Адрес доставки</label>
                    <textarea className="input" placeholder="Ваш адрес" onChange={(text) => this.recordAdress(text)}
                              value={this.state.ADRESS} ref={(input) => {
                        this.adressInput = input;
                    }}></textarea>
                </div>
            );
        }
    }

    renderAppartmentAndOther = () => {
        if (this.state.FROM_MAP) {
            return (
                <div className="flex">
                    <div className="order_form_input_holder appartment_holder">
                        <label>Кв/оф</label>
                        <input type="text" name="appartment" value={this.state.APPARTMENT}
                               onChange={(text) => this.recordAppartment(text)}/>
                    </div>
                    <div className="order_form_input_holder floor_holder">
                        <label>Этаж</label>
                        <input type="text" name="floor" value={this.state.FLOOR}
                               onChange={(text) => this.recordFloor(text)}/>
                    </div>
                    <div className="order_form_input_holder entrance_holder">
                        <label>Подъезд</label>
                        <input type="text" name="entrance" value={this.state.ENTRANCE}
                               onChange={(text) => this.recordEntrance(text)}/>
                    </div>
                </div>
            );
        }
    }

    getPhoneClass = () => {
        if (this.state.PHONE_ERROR) {
            return ' error';
        }

        return '';
    }

    getMiddleNameClass = () => {
        if (this.state.MIDDLE_NAME_ERROR) {
            return ' error';
        }

        return '';
    }

    getLastNameClass = () => {
        if (this.state.LAST_NAME_ERROR) {
            return ' error';
        }

        return '';
    }

    getNameClass = () => {
        if (this.state.NAME_ERROR) {
            return ' error';
        }

        return '';
    }
    openModalmap = () => {
        if (window.innerWidth < 1200) {
            this.props.openDeliveryAddressModal(true)
        } else {
            this.props.openDeliveryAddressMixedModal('adress')
        }
    }

    renderInputError = () => {
        if ((this.state.PHONE_ERROR) || (this.state.NAME_ERROR)) {
            return 'Пожалуйста, заполните поля выделенные красным';
        }
    }

    renderUserInfoRow = () =>
    {
        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            return (
                <>
                <div className="flex">
                    <div className="order_form_input_holder name_holder">
                        <input className={this.getNameClass()} type="text" name="name" placeholder="Ваше имя"
                               value={this.state.NAME} onChange={(text) => this.recordName(text)}/>
                    </div>
                    <div className="order_form_input_holder phone_holder">
                        <input className={this.getMiddleNameClass()} type="text" name="middle_name" placeholder="Ваше отчество"
                               value={this.state.MIDDLE_NAME} onChange={(text) => this.recordMiddleName(text)}/>
                    </div>
                </div>
                <div className="flex">
                    <div className="order_form_input_holder name_holder">
                        <input className={this.getLastNameClass()} type="text" name="last_name" placeholder="Ваша фамилия"
                               value={this.state.LAST_NAME} onChange={(text) => this.recordLastName(text)}/>
                    </div>
                    <div className="order_form_input_holder phone_holder">
                        <InputMask placeholder="Ваш телефон" type="tel" className={'phone ' + this.getPhoneClass()}
                                   id="share_phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)}
                                   value={this.state.PHONE}/>
                    </div>
                </div>

                </>
            )
        } else {
            return (
                <div className="flex">
                    <div className="order_form_input_holder name_holder">
                        <input className={this.getNameClass()} type="text" name="name" placeholder="Ваше имя"
                               value={this.state.NAME} onChange={(text) => this.recordName(text)}/>
                    </div>
                    <div className="order_form_input_holder phone_holder">
                        <InputMask placeholder="Ваш телефон" type="tel" className={'phone ' + this.getPhoneClass()}
                                   id="share_phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)}
                                   value={this.state.PHONE}/>
                    </div>
                </div>
            )
        }
    }

    setPostalDelivery = (id, sub_id, price, offices, title, days) =>
    {
        this.setState({SHOW_POSTAL_OFFICES: true, DELIVERY: id, DELIVERY_TYPE: sub_id, DELIVERY_PRICE: price, POSTAL_OFFICES: offices, DELIVERY_TYPE_TITLE: title, DELIVERY_DAYS: days, DATE: this.addDays(days)});
    }

    renderOfficeAdress = (id, sub_id) =>
    {
        if ((id == this.state.DELIVERY) && (this.state.DELIVERY_TYPE == sub_id)) {
            if (this.state.POSTAL_OFFICES) {
                if (this.state.DELIVERY_OFFICE_ADRESS.length) {
                    return (
                        <div className="delivery_office_selected">
                            {this.state.DELIVERY_OFFICE_ADRESS}
                        </div>
                    );
                } else {
                    return (
                        <div className="delivery_office_selected errored">
                            Выберите пункт выдачи
                        </div>
                    );
                }
            }
        }
    }

    renderPostalCheckbox = (title, id, sub_id, price, offices, days) =>
    {
        let CLASS = '';
        if ((this.state.DELIVERY == id) && (this.state.DELIVERY_TYPE == sub_id)) {
            CLASS = ' selected';
        }
        let SHOW_DAYS = '';
        if (days > 0) {
            SHOW_DAYS ='от ' + days + ' ' + this.num_title(days, ['дня', 'дней', 'дней']);
        }
        return (
            <label className={"delivery_checkbox" + CLASS} key={id + '_' + sub_id} onClick={() => this.setPostalDelivery(id, sub_id, price, offices, title, days)}>
                <div><span></span><div>{title}{this.renderOfficeAdress(id, sub_id)}</div></div>
                <div>{SHOW_DAYS}</div>
            </label>
        )
    }

    getPostRows = () =>
    {
        let POSTAL_ROWS = [];
        if (this.state.POST_DATA.length) {
            for (let i = 0; i<this.state.POST_DATA.length; i++) {
                let service = this.state.POST_DATA[i];
                let title = service.title;
                
                if (service.options.length > 1) {
                    for (let j = 0; j < service.options.length; j++) {
                        let this_title = title + ' (' + service.options[j].name + ')';
                        let price = 0;
                        let days = 0;
                        if (j == 0) {
                            price = service.options[j].price;
                            days = service.options[j].days_min;
                        } else {
                            days = service.options[0].days_min;
                        }

                        POSTAL_ROWS.push(this.renderPostalCheckbox(this_title, service.id, service.options[j].id, price, service.options[j].offices, days));
                    }
                } else {
                    if (typeof service.options[0] !== "undefined") {
                        POSTAL_ROWS.push(this.renderPostalCheckbox(title, service.id, 1, service.options[0].price, service.options[0].offices, service.options[0].days_min));
                    }
                }
            }
        } else {
            //POSTAL_ROWS.push(<div class="no_delivery_error">К сожалению на ваш адрес доставка транспортными компаниями не осуществляется!</div>);
        }

        return POSTAL_ROWS;
    }

    closeOfficesSelect = () =>
    {
        this.setState({SHOW_POSTAL_OFFICES: false});
    }

    renderDeliveryExtraData = () =>
    {
        if (this.state.DELIVERY_TYPE !== false) {
            if (!this.state.POSTAL_OFFICES) {
                return this.renderAdressAndAppartmentData();
            } else {
                if (this.state.SHOW_POSTAL_OFFICES) {
                    let icon = '';
                    let title = '';
                    let placemark_icon = 'pickpoint_icon.png';
                    switch (this.state.DELIVERY) {
                        case 4:
                            icon = 'cdek.png';
                            placemark_icon = 'pickpoint_cdek.png';
                            title = 'СДЭК';
                            break;
                        case 7:
                            icon = 'boxberry.png';
                            placemark_icon = 'pickpoint_boxberry.png';
                            title = 'Boxberry';
                            break;
                    }
                    return (
                        <div className="order_adress_holder">
                            <PostalOffices 
                                OFFICES={this.state.POSTAL_OFFICES} 
                                CENTER={this.props.GPS} 
                                ICON={icon}
                                TITLE={title}
                                PLACEMARK_ICON={placemark_icon}
                                pickPointSelected={this.pickPointSelected} 
                                closeOfficesSelect={this.closeOfficesSelect}
                            />
                        </div>
                    );
                }
            }
        }
    }

    preparePostalProducts = () =>
    {
        let PRODUCTS = [];
        for (let ID in this.props.CART) {
            let PRODUCT_ITEM = this.props.CART[ID];

            if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                PRODUCTS.push({id: ID, amount: PRODUCT_ITEM.amount});
            }
        }

        return PRODUCTS;
    }

    addDays = (days) =>
    {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() + days);

        if (!Date.prototype.toISODate) {
            Date.prototype.toISODate = function() {
              return this.getFullYear() + '-' +
                     ('0'+ (this.getMonth()+1)).slice(-2) + '-' +
                     ('0'+ this.getDate()).slice(-2);
            }
          }
          
        return newDate.toISODate();
    }

    pickPointSelected = async (placemark) =>
    {
        try {
            let SEND_DATA = {
                action: 'getPostalDeliveryPickpointFee',
                products: this.preparePostalProducts(),
                delivery_id: this.state.DELIVERY,
                pickpoint_id: placemark.id,
                pickpoint_adress: placemark.adress
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.setState({DELIVERY_OFFICE_ADRESS: placemark.adress, DELIVERY_OFFICE_ID: placemark.id, DELIVERY_PRICE: data.price, DELIVERY_DAYS: data.days_min, DATE: this.addDays(data.days_min)})
            }
        } catch (error) {
            console.log('BAD PRODUCTS TIMER');
        }
    }

    renderDeliveryBlock = () =>
    {
        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            

            return (
                <>
                    <div className="order_adress_holder">
                        <div className="delivery_post_header">
                            Выберите транспортную компанию:
                        </div>
                        {this.getPostRows()}
                    </div>
                    {this.renderDeliveryExtraData()}
                </>
            );

        } else {
            return this.renderAdressAndAppartmentData();
            
        }
    }

    renderAdressAndAppartmentData = () =>
    {
        let CLASS = 'input';
        if (this.state.ADRESS_ERROR) {
            CLASS = 'error_input';
        }

        return (
            <>
                <div className="order_adress_holder">
                    <div className="order_block_head">
                        Адрес доставки
                    </div>

                    <span className="order_form_adress_changer" onClick={() => this.openModalmap()}>
                        {this.props.ADRESS}<img src="/system_images/grey_pencil.png"/>
                    </span>
                </div>

                {this.renderAppartmentAndOther()}
            </>
        );
    }

    renderForm() {
        

        return (
            <div className="order_first_block">
                <div className='input_error_text'>{this.renderInputError()}</div>

                {this.renderUserInfoRow()}
                
                {this.renderDeliveryBlock()}

                <div className="flex">
                    <div className="order_form_input_holder ocomment_holder">
                        {this.renderComment()}
                    </div>
                </div>
            </div>
        );
    }

    renderComment = () => {
        return (
            <textarea placeholder="Комментарий для курьера" onChange={(text) => this.recordComment(text)}
                      value={this.state.COMMENT}></textarea>
        );
    }


    timeChanged(event) {
        this.setState({TIME: event.target.value});
    }

    dateChanged(event) {
        this.setState({DATE: event.target.value, TIME: false});
    }

    checkCurrentTime = () => {
        let datetime = new Date;
        let HOURS = parseInt(datetime.getHours());

        if ((HOURS >= 23) || (HOURS <= 5)) {
            return false;
        }

        return true;
    }

    getTimesOptions = (TIMES) => {

        let TIMES_ARRAY = [];

        let j = 0;
        for (let KEY in TIMES) {
            let VALUE = TIMES[KEY];

            if (!this.state.TIME) {
                if (j === 0) {
                    if (this.checkCurrentTime()) {
                        this.setState({TIME: VALUE});
                    } else {
                        TIMES_ARRAY.push(
                            <option
                                key={'time_zero'}
                            >
                            </option>
                        );
                    }
                }
            }

            TIMES_ARRAY.push(
                <option
                    key={'time_' + KEY}
                    value={VALUE}
                >
                    {VALUE}
                </option>
            );

            j++;
        }

        return TIMES_ARRAY;
    }

    amountCart() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].weight * CART[ID].amount;
                        SUM += ROW_SUM;


                    }
                }
            }
        }


        return {
            SUM
        };
    }

    getTheTimes() {
        let DATES = [];
        let BUSY = [];
        let LIMIT = [];
        let TIMES = [];
        let DATE_KEY = 0;
        let cartWeight = this.amountCart()
        let j = 0;
        for (let KEY in this.state.DATES) {


            let DATE_HOLDER = this.state.DATES[KEY];

            let times = DATE_HOLDER.times

            if (this.props.PolygonsMinutes !== '-1' && cartWeight.SUM < 15) {
                times = [...DATE_HOLDER.times]
                let deliveryTime = Number(this.props.PolygonsMinutes) + 15
                if (DATE_HOLDER.title === 'Сегодня' && times != (moment().add(deliveryTime, 'm').format('HH:mm'))) {
                    times.unshift(moment().add(Number(this.props.PolygonsMinutes), 'm').format('HH:mm') + '-' + moment().add(deliveryTime, 'm').format('HH:mm'))
                }
            }
            if (!this.state.DATE) {
                if (j === 0) {
                    if (this.checkCurrentTime()) {
                        this.setState({DATE: KEY, TIME: false});
                    } else {
                        DATES.push(
                            <option
                                key={'date_zero'}
                            >
                            </option>
                        );
                    }
                    TIMES = this.getTimesOptions(times);
                }
            } else {
                if (KEY === this.state.DATE) {
                    TIMES = this.getTimesOptions(times);
                }
            }

            DATES.push(
                <option
                    key={'date_' + KEY}
                    value={KEY}
                >
                    {this.state.DATES[KEY].title}
                </option>
            );

            j++;
        }


        return {
            DATES: DATES,
            TIMES: TIMES
        }
    }

    renderPayment(id) {
        switch (id) {
            case 1:
                return (<div className="the_payment"><img src="/system_images/green_cash.png"/> Оплата наличными</div>);
            case 3:
                return (<div className="the_payment"><img src="/system_images/green_card.png"/> Оплата банковской картой
                </div>);
            case 5:
                return (<div className="the_payment"><img src="/system_images/green_card.png"/> СБП
                </div>);
            case 'ACTIVE_CARD':
                return (<div className="the_payment"><img src="/system_images/green_card.png"/> Оплата картой
                    ****{this.state.ACTIVE_CARD.card_last}</div>);
                break;
        }
    }

    renderActiveCardPayment() {
        if (typeof this.state.ACTIVE_CARD !== "undefined") {
            if (typeof this.state.ACTIVE_CARD.id !== "undefined") {
                return (<a onClick={() => this.selectPayment('ACTIVE_CARD')}>
                    <div className="the_payment"><img src="/system_images/green_card.png"/> Оплата картой
                        ****{this.state.ACTIVE_CARD.card_last}</div>
                </a>);
            }
        }
    }

    selectPayment(id) {
        this.setState({PAYMENT: id, PAYMENTS_DROP: false})
    }

    renderCashPayment = () =>
    {
        if ((typeof CONFIG.USE_REGION_DELIVERY == "undefined") || !CONFIG.USE_REGION_DELIVERY || this.props.REGION !== 'OTHER') {
            return (
                <a onClick={() => this.selectPayment(1)}>
                    {this.renderPayment(1)}
                </a>
            )
        }
    }

    changePayment() {
        let PAYMENTS_DROP = (
            <div className="suggestions_block" ref={this.setPayRef}>
                {this.renderActiveCardPayment()}
                {this.renderCashPayment()}
                
                <a onClick={() => this.selectPayment(3)}>
                    {this.renderPayment(3)}
                </a>
                <a onClick={() => this.selectPayment(5)}>
                    {this.renderPayment(5)}
                </a>
            </div>
        );

        this.setState({PAYMENTS_DROP: PAYMENTS_DROP});
    }

    renderPayments() {
        return (
            <div className="order_form_input_holder payment_holder">
                <a className="payment_selected" onClick={() => this.changePayment()}>
                    {this.renderPayment(this.state.PAYMENT)}
                </a>
                {this.state.PAYMENTS_DROP}
            </div>
        );
    }

    getRuMonth = (month) => {
        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

        return months[month];
    }

    getPostDate = () => {
        let someDate = new Date();
        let numberOfDaysToAdd = 5;
        someDate.setDate(someDate.getDate() + numberOfDaysToAdd);

        let dd = someDate.getDate();
        if (dd < 10) {
            dd = '0' + dd;
        }
        let mm = someDate.getMonth();
        let y = someDate.getFullYear();

        return dd + ' ' + this.getRuMonth(mm) + ' ' + y;
    }

    renderTimeAndPayment() {
        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            return (
                <div className="order_first_block">
                    <div className="flex">
                        {this.renderPayments()}
                    </div>
                </div>
            );
        } else {
            if (this.state.FROM_MAP) {
                let DATA = this.getTheTimes();

                return (
                    <div className="order_first_block">
                        <div className="order_block_head">
                            Время доставки
                        </div>
                        <div className="flex">
                            <div className="order_form_input_holder date_holder">
                                <select onChange={(event) => this.dateChanged(event)}>
                                    {DATA.DATES}
                                </select>
                            </div>
                            <div className="order_form_input_holder time_holder">
                                <select value={this.state.TIME} onChange={(event) => this.timeChanged(event)}>
                                    {DATA.TIMES}
                                </select>
                            </div>
                        </div>
                        <div className="flex">
                            {this.renderPayments()}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="order_first_block">
                        <div className="order_block_head">
                            Детали доставки
                        </div>
                        <div className="flex flex-wrap">
                            <div className="region_delivery_row">
                                <img src="/system_images/square_post.png"/> Доставка почтой
                            </div>
                            <div className="region_delivery_row">
                                <img src="/system_images/square_calendar.png"/> Ближайшая дата доставки <span
                                className="green">{this.getPostDate()}</span>
                            </div>
                            <div className="region_delivery_row green">
                                После оформления заказа с ваши свяжется наш специалист для уточнения деталей заказа
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    useCoupon = async () => {
        if (this.state.NEW_COUPON !== '') {
            let sum_data = await this.countCart();
            let sum = sum_data.SUM;

            let APIKEY = 'UNREGISTERED';
            if (this.props.USER) {
                APIKEY = this.props.USER.TOKEN;
            }
            let polygon = this.props.PolygonsMinutes !== '-1'
            try {
                let SEND_DATA = {
                    action: 'checkCoupon_v2',
                    coupon: this.state.NEW_COUPON,
                    apikey: APIKEY,
                    sum: sum,
                    region: this.props.REGION,
                    polygon,
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

                if (data !== false) {
                    if (data.promocode) {
                        this.yesCoupon(data.promocode);
                    } else {
                        this.noCouponText(data.error);
                    }
                } else {
                    this.noCoupon();
                }
            } catch (error) {
                this.noCoupon();
            }
        } else {
            this.noCoupon();
        }
    }

    countPercentDiscount() {

        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);

        let DISCOUNTED_SUM = 0;
        let DISCOUNT_SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let PURE_ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;

                        let DISCOUNT = PRODUCTS[ID].price / 100 * this.state.COUPON_DISCOUNT;
                        let PRICE = PRODUCTS[ID].price - DISCOUNT;
                        let ROW_SUM = PRICE * CART[ID].amount;
                        DISCOUNT_SUM += PURE_ROW_SUM - ROW_SUM;
                        DISCOUNTED_SUM += ROW_SUM;
                    }
                }
            }
        }

        DISCOUNT_SUM = Math.floor(DISCOUNT_SUM);

        this.setState({
            COUPON_AMOUNT: DISCOUNT_SUM,
            DISCOUNTED_SUM: DISCOUNTED_SUM
        });
    }

    countDiscounts() {
        if (this.state.COUPON_NAME !== "") {
            if (this.state.COUPON_TYPE == 0) {
                let SUM = this.SUM - this.state.COUPON_DISCOUNT;
                this.setState({
                    COUPON_AMOUNT: this.state.COUPON_DISCOUNT,
                    DISCOUNTED_SUM: SUM
                });
            } else if (this.state.COUPON_TYPE == 1) {
                this.countPercentDiscount();
            } else if (this.state.COUPON_TYPE == 5) {
                if (parseFloat(this.state.COUPON_DATA.sum) > 0) {
                    if (parseFloat(this.state.COUPON_DATA.sum) < this.SUM) {
                        let SUM = this.SUM - this.state.COUPON_DISCOUNT;
                        this.setState({
                            COUPON_AMOUNT: this.state.COUPON_DISCOUNT,
                            DISCOUNTED_SUM: SUM
                        });
                    }
                } else {
                    let SUM = this.SUM - this.state.COUPON_DISCOUNT;
                    this.setState({
                        COUPON_AMOUNT: this.state.COUPON_DISCOUNT,
                        DISCOUNTED_SUM: SUM
                    });
                }
            } else if (this.state.COUPON_TYPE == 6) {
                if (parseFloat(this.state.COUPON_DATA.sum) > 0) {
                    if (parseFloat(this.state.COUPON_DATA.sum) < this.SUM) {
                        this.countPercentDiscount();
                    }
                } else {
                    this.countPercentDiscount();
                }
            } else {
                this.setState({
                    COUPON_AMOUNT: 0,
                    DISCOUNTED_SUM: 0
                });
            }
        }

        this.countCart();
        this.countAppendDelivery();
    }

    yesCoupon = (promocode) => {
        this.setState({
            COUPON_DISCOUNT: promocode.discount,
            COUPON_NAME: promocode.name,
            COUPON_TYPE: promocode.type,
            COUPON_ID: promocode.id,
            COUPON_DROP: false,
            COUPON_ERROR: false,
            COUPON_DATA: promocode
        });

        this.countDiscounts();
    }

    noCouponText = (text) => {
        this.setState({
            COUPON_DISCOUNT: false,
            COUPON_NAME: '',
            COUPON_TYPE: false,
            COUPON_ID: false,
            COUPON_AMOUNT: false,
            COUPON_DATA: {},
            DISCOUNTED_SUM: 0,
            COUPON_ERROR: text
        });
    }

    noCoupon = () => {
        this.setState({
            COUPON_DISCOUNT: false,
            COUPON_NAME: '',
            COUPON_TYPE: false,
            COUPON_ID: false,
            COUPON_AMOUNT: false,
            DISCOUNTED_SUM: 0,
            COUPON_ERROR: true
        });
    }

    changeCoupon = (code) => {
        this.setState({NEW_COUPON: code.target.value});
    }

    renderCouponError() {
        if (this.state.COUPON_ERROR) {
            if (this.state.COUPON_ERROR !== true) {
                return (
                    <div className="col-12">
                        <div className="coupon_error">
                            {this.state.COUPON_ERROR}
                        </div>
                    </div>
                );
            }
        }
    }

    enterCoupon() {
        if (this.state.COUPON_DROP) {
            let CLASS = 'bonuses';
            if (this.state.COUPON_ERROR) {
                CLASS = 'bonuses error_input';
            }

            return (
                <div className="form-pop-append" id="code-pop" ref={this.setCouRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <input type="text" className={CLASS} onChange={(text) => this.changeCoupon(text)}
                                   value={this.state.NEW_COUPON}/>
                        </div>
                        <div className="col-4 npl">
                            <a className="orange-button" onClick={() => this.useCoupon()}>Применить</a>
                        </div>
                        {this.renderCouponError()}
                    </div>
                </div>
            );
        }
    }

    useBonuses = () => {
        let MAX_BONUSES = this.countMaxBonuses();
        let BONUSES = this.state.NEW_BONUSES;

        if (BONUSES > MAX_BONUSES) {
            BONUSES = MAX_BONUSES;
        }
        if (BONUSES > this.state.BONUSES) {
            BONUSES = this.state.BONUSES;
        }

        this.countCart();

        this.setState({BONUSES_AMOUNT: BONUSES, NEW_BONUSES: BONUSES, BONUSES_DROP: false});

        this.countAppendDelivery();
    }

    countMaxBonuses() {
        let MAX = 9999999999999999999999999999;
        if ((parseInt(this.props.USER.STATUS) !== 4) && (parseInt(this.props.USER.STATUS) !== 6) && parseInt(this.props.USER.STATUS) !== 5) {
            if (window.BibaAndBoba)
                MAX = Math.ceil(this.SUM / 100 * 100);
            else MAX = Math.ceil(this.SUM / 100 * 30);
        }
        if (MAX > this.SUM) {
            MAX = parseInt(this.SUM);
            if (this.SUM - MAX > 0) {
                MAX += 1;
            }
        }
        return MAX;
    }

    changeBonuses = (code) => {
        let SET_BONUSES = code.target.value;
        if (SET_BONUSES > this.state.BONUSES) {
            SET_BONUSES = this.state.BONUSES;
        } else if (SET_BONUSES < 0) {
            SET_BONUSES = 0;
        }
        this.setState({NEW_BONUSES: code.target.value});
    }

    enterBonuses() {
        if (this.state.BONUSES_DROP) {
            return (
                <div className="form-pop-append" id="code-pop" ref={this.setCouRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <input type="number" className="bonuses" onChange={(text) => this.changeBonuses(text)}
                                   value={this.state.NEW_BONUSES}/>
                        </div>
                        <div className="col-4 npl">
                            <a className="orange-button" onClick={() => this.useBonuses()}>Применить</a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    num_title = (n, titles) => {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderUseBonus() {
        if (this.state.BONUSES > 0) {
            let LEFT_BONUSES = this.state.BONUSES - this.state.BONUSES_AMOUNT;

            return (
                <div className="use_something_row">
                    <a onClick={() => this.setState({BONUSES_DROP: true})}>
                        Использовать экобаллы
                    </a>
                    <div className="order_current_bonuses">
                        Остаток: {LEFT_BONUSES} {this.num_title(LEFT_BONUSES, ['экобалл', 'экобалла', 'экобаллов'])}
                    </div>
                </div>
            );
        }
    }

    renderAllDiscounts() {
        return (
            <div className="order_white_block">
                <div className="use_something_row">
                    <a onClick={() => this.setState({COUPON_DROP: true})}>
                        У меня есть промокод
                    </a>
                </div>
                {this.renderUseBonus()}
                {this.enterBonuses()}
                {this.enterCoupon()}
            </div>
        );
    }

    renderBonusesUsed() {
        let LEFT_BONUSES = this.state.BONUSES - this.state.BONUSES_AMOUNT;

        return (
            <div className="order_white_block">
                <div className="use_something_row">
                    <a onClick={() => this.setState({BONUSES_DROP: true})} className="appended">
                        Вы использовали экобаллы <img src="/system_images/green_check.png"/>
                    </a>
                    <div className="order_current_bonuses">
                        Остаток: {LEFT_BONUSES} {this.num_title(LEFT_BONUSES, ['экобалл', 'экобалла', 'экобаллов'])}
                    </div>
                </div>

                {this.enterBonuses()}
                {this.enterCoupon()}
            </div>
        );
    }

    renderCouponUsed() {
        return (
            <div className="order_white_block">
                <div className="use_something_row">
                    <a onClick={() => this.setState({COUPON_DROP: true})} className="appended">
                        Вы применили промокод <img src="/system_images/green_check.png"/>
                    </a>
                </div>

                {this.enterBonuses()}
                {this.enterCoupon()}
            </div>
        );
    }

    renderBonusesBlock() {
        if ((this.state.BONUSES_AMOUNT == 0) && (this.state.COUPON_NAME == '')) {
            return this.renderAllDiscounts();
        } else if (this.state.BONUSES_AMOUNT > 0) {
            return this.renderBonusesUsed();
        } else {
            return this.renderCouponUsed();
        }
    }

    renderProductsSum() {
        if (this.SUM) {
            return (
                <div className="finals_row">
                    Стоимость товаров: {this.rmFixed2(this.SUM)}
                </div>
            );
        }
    }

    getRegionKadTitle() {
        switch (parseInt(this.state.REGION)) {
            case 77:
                return 'за МКАД';
                break;
            case 78:
                return 'за КАД';
                break;
            default:
                return 'за город';
                break;
        }
    }

    renderDeliverySum() {
        
        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            if ((this.state.DELIVERY_PRICE > 0) && (this.state.DELIVERY_TYPE)) {
                let DEL_TITLE = 'Стоимость доставки: ';
                let DELIVERY_SUM = this.state.DELIVERY_PRICE + 'р';

                return (
                    <div className="finals_row">
                        {DEL_TITLE}{DELIVERY_SUM}
                    </div>
                );
            } else {
                return false;
            }
            
        } else {
            let sum_data = this.countCart();
            let FREE_DEL = sum_data.FREE_DEL;
            let SUM = sum_data.SUM;
            let DELIVERY_SUM = this.state.DELIVERY_PRICE;

            if ((SUM > this.state.FREE_DELIVERY) || (FREE_DEL == 1)) {
                DELIVERY_SUM = 0;
            }


            if (this.props.USER.DELIVERY > 0) {
                DELIVERY_SUM += parseInt(this.props.USER.DELIVERY);
            }

            if (this.state.COUPON_NAME !== '') {
                if (this.state.COUPON_TYPE == 2) {
                    DELIVERY_SUM = 0;
                }
                if (this.state.COUPON_TYPE == 5) {
                    if (parseInt(this.state.COUPON_DATA.free_del) == 1) {
                        DELIVERY_SUM = 0;
                    }
                }
            }

            if (this.state.DEL_ADDON) {
                DELIVERY_SUM += parseInt(this.state.DEL_ADDON);
            }

            if (this.props.USER) {
                if (parseInt(this.props.USER.STATUS) == 4) {
                    DELIVERY_SUM = 0;
                }
            }

            if ((parseInt(this.state.ORDERS_COUNT) < 4) && (parseInt(this.state.MKAD) <= 10)) {
                DELIVERY_SUM = 0;
            }

            if (DELIVERY_SUM > 0) {
                DELIVERY_SUM = this.rmFixed2(DELIVERY_SUM);
            } else {
                DELIVERY_SUM = 'Бесплатно!';
            }

            let DEL_TITLE = 'Стоимость доставки: ';
            if (this.state.MKAD) {
                DEL_TITLE = 'Стоимость доставки (' + this.state.MKAD + ' ' + this.getRegionKadTitle() + '): ';
            }

            if (this.props.PolygonsDeliveryPrice !== '-1') {
                DELIVERY_SUM = this.props.PolygonsDeliveryPrice
                this.state.DELIVERY_PRICE = Number(this.props.PolygonsDeliveryPrice)
                let sum = this.countCart()
                if (Number(this.props.PolygonsFreeDeliveryWhenSum) <= sum.SUM) {
                    DELIVERY_SUM = 'Бесплатно!'
                    this.state.DELIVERY_PRICE = 0
                }
            }

            return (
                <div className="finals_row">
                    {DEL_TITLE}{DELIVERY_SUM}
                </div>
            );
        }
    }

    rmFixed2(numb) {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(parseFloat(numb).toFixed(2)).toFixed(2);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderDiscountsSum() {
        if (this.state.BONUSES_AMOUNT > 0) {
            return (
                <div className="finals_row red">
                    Экобаллы: - {this.state.BONUSES_AMOUNT}
                </div>
            );
        } else if (this.state.COUPON_AMOUNT > 0) {
            return (
                <div className="finals_row red">
                    Промокод: - {this.state.COUPON_AMOUNT}
                </div>
            );
        }
    }

    countTotalsSum() {
        let sum_data = this.countCart();
        let SUM = sum_data.SUM;
        let FREE_DEL = sum_data.FREE_DEL;

        let DELIVERY_SUM = this.state.DELIVERY_PRICE;
        if ((SUM > this.state.FREE_DELIVERY) || (FREE_DEL == 1)) {
            DELIVERY_SUM = 0;
        }

        if (this.props.USER.DELIVERY > 0) {
            DELIVERY_SUM += parseInt(this.props.USER.DELIVERY);
        }

        if (this.state.BONUSES_AMOUNT > 0) {
            SUM -= parseInt(this.state.BONUSES_AMOUNT);
        }

        if (this.state.COUPON_AMOUNT > 0) {
            SUM -= parseInt(this.state.COUPON_AMOUNT);
        }

        if (this.state.COUPON_NAME !== '') {
            if (this.state.COUPON_TYPE == 2) {
                DELIVERY_SUM = 0;
            }
            if (this.state.COUPON_TYPE == 5) {
                if (parseInt(this.state.COUPON_DATA.free_del) == 1) {
                    DELIVERY_SUM = 0;
                }
            }
        }

        if (this.props.USER) {
            if (parseInt(this.props.USER.STATUS) == 4) {
                DELIVERY_SUM = 0;
            }
        }

        if (DELIVERY_SUM > 0) {
            DELIVERY_SUM = DELIVERY_SUM;
        } else {
            DELIVERY_SUM = 0;
        }

        if (this.state.DEL_ADDON) {
            DELIVERY_SUM += parseInt(this.state.DEL_ADDON);
        }

        if (this.props.USER) {
            if (parseInt(this.props.USER.STATUS) == 4) {
                DELIVERY_SUM = 0;
            }
        }

        if (parseInt(this.state.ORDERS_COUNT) < 4 && parseInt(this.state.MKAD) <= 10) {
            DELIVERY_SUM = 0;
        }

        this.TOTAL_SUM = SUM;

        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            DELIVERY_SUM = 0;
            if ((this.state.DELIVERY_PRICE > 0) && (this.state.DELIVERY_TYPE)) {
                DELIVERY_SUM = this.state.DELIVERY_PRICE;
            }
        }

        SUM += DELIVERY_SUM;

        if (SUM < 0) {
            SUM = 0;
        }

        return this.rmFixed2(SUM);
    }

    renderTotals() {
        return (
            <div className="finals_row total">
                Итог: {this.countTotalsSum()}
            </div>
        )
    }

    change() {

    }

    renderCheck() {
        return (
            <div className="p_check agree">
                <input name="agree" type="checkbox" id="agree" value="1" checked="checked"
                       onChange={() => this.change()}/>
                <label htmlFor="agree">
                    <span>Принимаю условия <a target="_blank"
                                              href="/company/polzovatelskoe-soglashenie/">договора-оферты</a></span>
                </label>
            </div>
        );
    }

    renderFinalsBlock() {
        return (
            <div className="order_finals_block">
                {this.renderProductsSum()}
                {this.renderDeliverySum()}
                {this.renderDiscountsSum()}
                {this.renderTotals()}
                {this.renderTheDiscountBlock()}
                {this.renderCheck()}
            </div>
        );
    }

    renderTheDiscountBlock = () => {
        if (this.state.COUPON_NAME == 'eco_welcome') {
            if (this.state.COUPON_AMOUNT > 0) {
                return (
                    <div className="the_big_discount_in_cart">
                        Ваша выгода 1000 рублей
                    </div>
                );
            }
        }
    }

    renderOrderError() {
        if (this.state.ORDER_ERROR) {
            return (
                <div className="order_submit_error">
                    {this.state.ORDER_ERROR}
                </div>
            );
        }
    }

    isValid = () => {
        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            if 
            ((!this.state.PHONE_ERROR)
            &&
            (!this.state.NAME_ERROR)
            &&
            (!this.state.MIDDLE_NAME_ERROR)
            &&
            (!this.state.LAST_NAME_ERROR)) {
                if (this.state.DELIVERY !== 2) {

                    if (this.state.OFFICES) {
                        if ((this.state.DELIVERY_PRICE > 0) && (this.state.DELIVERY_OFFICE_ID)) {
                            return true;
                        }
                    } else {
                        if ((this.state.ADRESS.length > 0) && (this.state.DELIVERY_PRICE > 0)) {
                            return true;
                        }
                    }

                }
            }
        } else {
            if (
                (this.state.DATE)
                &&
                (this.state.TIME)
                &&
                (!this.state.PHONE_ERROR)
                &&
                (!this.state.NAME_ERROR)
                &&
                (this.state.ADRESS.length > 0)
            ) {
                return true;
            }
        }


        return false;
    }

    scrollToEmptyInputs = () => {
        this.emptyInputRef.current.scrollIntoView({behavior: 'smooth'});
    }

    renderSubmit() {
        if (!this.state.ORDERING) {
            if (this.isValid()) {
                return (
                    <div>
                        {this.renderOrderError()}
                        <a className="orange-button hoverable" onClick={() => this.submitOrder()}>
                            Оформить покупки
                        </a>
                    </div>
                )
            } else {
                return (
                    <div>
                        {this.renderOrderError()}
                        <a className="orange-button inactive-btn" onClick={this.scrollToEmptyInputs}>
                            Оформить покупки
                        </a>
                    </div>
                );
            }
        } else {
            return (
                <div>
                    {this.renderOrderError()}
                    <a className="orange-button hoverable">
                        Заказ оформляется ...
                    </a>
                </div>
            )
        }
    }

    payDone = () => {
        this.props.changeHead('Заказ оформлен', false);
        this.setState({ROUTE: 'DONE'});
    }

    goNextStep = (pay = true) => {
        if (this.state.PAYMENT == 1 || !pay) {
            this.setState({ROUTE: 'DONE'});
            this.props.changeHead('Заказ принят', false);
        } else {
            this.setState({ROUTE: 'DO_PAY'});
            this.props.changeHead('Оплата', false);
        }
    }

    render() {
        if ((!this.state.USEABLE_PERIODS) && ((typeof CONFIG.USE_REGION_DELIVERY == "undefined") || CONFIG.USE_REGION_DELIVERY == false || this.props.REGION !== 'OTHER')) {
            return (
                <div/>
            );
        }

        if (this.state.ROUTE == 'FORM') {
            return (
                <div ref={this.emptyInputRef} className="order_form_holder">
                    <div className="modal_content">
                        {this.renderForm()}
                        {this.renderTimeAndPayment()}
                        <div className="order_spacer"></div>
                        {this.renderBonusesBlock()}
                        {this.renderFinalsBlock()}
                    </div>

                    <div className="modal_bottom">
                        {this.renderSubmit()}
                    </div>


                </div>
            );
        } else if (this.state.ROUTE == 'DO_PAY') {
            return <DoPay
                ORDER={this.state.ORDER}
                NUMBER={this.state.NUMBER}
                payDone={this.payDone}
                SECURITY={this.state.SECURITY}
                MISSED={this.state.MISSED}
            />;
        } else if (this.state.ROUTE == 'MAP') {
            return <RegionMap adressConfirmed={this.adressConfirmed}/>;
        } else if (this.state.ROUTE == 'FIVE_MINUTES') {
            let ROUTE = '';
            let ROUTE_HEADING = '';
            if (this.state.PAYMENT == 1) {
                ROUTE = 'DONE';
                ROUTE_HEADING = 'Заказ принят';
            } else {
                ROUTE = 'DO_PAY';
                ROUTE_HEADING = 'Оплата';
            }
            return <FiveMinutes PHONE={this.state.PHONE} PAYMENT={this.state.PAYMENT} ROUTE_HEADING={ROUTE_HEADING}
                                ROUTE={ROUTE} MOVING_CART={this.state.MOVING_CART}
                                goNextStep={this.goNextStep} ORDER={this.state.ORDER} NUMBER={this.state.NUMBER}
                                payDone={this.payDone} SECURITY={this.state.SECURITY} MISSED={this.state.MISSED}/>;
        } else {
            return <DoneNew PAYMENT={this.state.PAYMENT} ORDER={this.state.ORDER} NUMBER={this.state.NUMBER}
                            SECURITY={this.state.SECURITY} PHONE={this.state.PHONE} MISSED={this.state.MISSED}/>;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderForm);