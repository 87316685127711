import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import InnerModalProduct from './../../APP/COMPONENTS/ContentParts/InnerModalProduct';

function mapStateToProps(state) {
    return {
        PRODUCT_MODAL: state.PRODUCT_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Product extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: false,
            CURRENT_CLASS: "modal-box product-card"
        };

        this.OFFSET = 0;
    }

    componentDidMount() {
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.PRODUCT_MODAL !== prevProps.PRODUCT_MODAL) {
            if (window.innerWidth < 1200) {
                if(!this.props.PRODUCT_MODAL){
                    this.setState({CURRENT_CLASS: "modal-box product-card"})
                }
                if(this.props.PRODUCT_MODAL){
                    let COMPONENT = this;
                    setTimeout(function () {
                        COMPONENT.setState({CURRENT_CLASS: "modal-box product-card-no-animation"})
                    }, 1000);
                }
                if (!this.props.PRODUCT_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    closeModal = () => {
        if (window.innerWidth < 1200){
            document.getElementById("movebleDiv").style.animationName = "closeModal";
            document.getElementById("modalOverlay").style.animationName = "closeModalOverlay";
        }
        let COMPONENT = this;
        setTimeout(function () {
            COMPONENT.props.openProductModal(false)
        }, 500);
    }

    mobileScreenChaker = (IdName) => {
        if (window.innerWidth <= 1200){
            return IdName
        }
        else return ''
    }

    mobileScreenChakerOverlay = () => {
        if (window.innerWidth <= 1200){
            return "modal-overlay-white"
        }
        else return "modal-overlay"
    }


    // onClick={() => this.props.openProductModal(false)}
	render()
    {
        if (this.props.PRODUCT_MODAL) {
            return (
                <div>
                    <div className={this.mobileScreenChakerOverlay()} id={this.mobileScreenChaker("modalOverlay")} onClick={() => this.props.openProductModal(false)}>
                    </div>
                    <div className = {this.state.CURRENT_CLASS}
                         id = {this.mobileScreenChaker("movebleDiv")}
                    >  
                        <div className="mobile_fixed_closer">
                            <a className="close-modal-holder" onClick={() => this.closeModal()}>
                                {
                                    window.innerWidth <= 1200
                                    ? <div className="collapse_text">Свернуть</div>
                                    : false
                                }
                                <img  style={{height: '19px', width: '19px' }} src="/system_images/green_cross_modal.png" />
                            </a>
                        </div>
                        <InnerModalProduct ID={this.props.PRODUCT_MODAL} />
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Product);