import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import MobileLocationData from "../../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

function mapStateToProps(state, myProps) {
    return {
        REGION: state.REGION
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class About extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            REGION: props.REGION
        }

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.items = this.getItemsPerScreen();

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount()
    {
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    handleScroll(event) {

    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    getItemsPerScreen()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 1;
        } else if (WIDTH < 769) {
            return 1;
        } else if (WIDTH < 1050) {
            return 2;
        } else if (WIDTH < 1200) {
            return 3;
        } else if (WIDTH < 9900) {
            return 3;
        }
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });

        DATA.push({
            'to' : '/company/about',
            'title' : 'О нас'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    getTitle = () =>
    {
    	switch (this.state.REGION) {
			default:
    			return 'О нас';
    			break;
    	}
    }

    getKeywords = () =>
    {
    	switch (this.state.REGION) {
    		default:
    			return 'О нас';
    			break;
    	}
    }

    getDescription = () =>
    {
    	switch (this.state.REGION) {
    		default:
    			return 'О нас';
    			break;
    	}
    }

    renderGallery()
    {
    	let SETTINGS = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: this.items,
            slidesToScroll: 1,
            className: 'static-gallery',
            arrows: true,
            swipeToSlide: true
        };

        let IMAGES = []
    	for (let i = 1; i <= 6; i++) {
    		IMAGES.push(
    			<div className="static_gallery_item" key={i}>
    				<img src={'/system_images/static_pages/about' + i + '.jpg'} alt={this.getTitle()} />
				</div>
			)
    	}

        return (
        	<Slider ref={c => (this.slider = c)} {...SETTINGS}>
                {IMAGES}
            </Slider>
    	);
    }

    renderContent()
    {
    	return (
    		<div className="static_page_body">
    			<h2>Немного об Экомаркете.</h2>

    			<p>Не надо больше тратить своё время в поисках натуральных продуктов. Экомаркет доставит их вам всего за 90 минут.</p>

    			<ul className="m_70_percent">
    				<li>Выберите время доставки</li>
    				<li>Получите свой заказ с доставкой до порога</li>
    				<li>Команда Экомаркет сэкономит вам время и силы, которое вы сможете потратить на более полезное дело, чем поиск продуктов.</li>
				</ul>

				<div className="green_head_block m_70_percent">
    				<div className="green_head_block_head">
    					Ecomarket.ru - продукты, какими они должны быть!
    				</div>
    			</div>

    			<div className="black_head_block">
    				<div className="black_head_block_head m_center">
    					Кто наши поставщики?
    				</div>
    				<div className="black_head_block_body">
    					Наши поставщики это фермеры и небольшие производства, которые выращивают и производят натуральные продукты. Многие имеют био сертификаты.
					</div>
    			</div>

    			<div className="black_head_block">
    				<div className="black_head_block_head m_center">
    					Мы разбираемся в продуктах
    				</div>
    				<div className="black_head_block_body">
    					У нас 3 уровня контроля качества и мини лаборатория по проверке содержания вредных нитратов и добавок принимаемой продукции. Наши комплектовщики аккуратно собирают заказ, вручную только лучшее для вас.
					</div>
    			</div>

    			<div className="black_head_block">
    				<div className="black_head_block_head m_center">
    					Сроки годности
    				</div>
    				<div className="black_head_block_body">
    					В супермаркетах некоторые покупатели часто покупают продукты с истёкшим сроком годности. Мы не допускаем такого и отправляем клиенту только свежее. У нас два раза в день поставка молочной продукции, фруктов, овощей, зелени, мяса и рыбы. Категорически запрещается отправка на последнем дне срока годности.
					</div>
    			</div>

    			<div className="black_head_block">
    				<div className="black_head_block_head m_center">
    					Температурный режим
    				</div>
    				<div className="black_head_block_body">
    					Замороженные продукты , которые требуют температурного режима при транспортировке, упаковываются в специальные термосумки с хладоэлементами. Так мы доставляем продукты в необходимом температурном режиме.
					</div>
    			</div>

    		</div>
		);
    }

	render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            >
                <div className="right-part">
                    <Helmet>
                        <title>{this.getTitle()}</title>
                        <meta name="description" content={this.getDescription()} />
                        <meta name="keywords" content={this.getKeywords()} />
                    </Helmet>
                    <MobileLocationData />
                        <DesktopDeliveryInformation />
                    {this.renderBreadCrumbs()}
                    <div id="products" className="page-content">
                        <div className="static_page_content">
                            <h1>{this.getTitle()}</h1>
                            {this.renderContent()}
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
                <FooterBlock />
            </CSSTransitionGroup>
        );
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(About))
