import React, {Component} from 'react';
/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DeliveryRules extends Component {
    render() {
        return (
            <div>
                <div className='delivery-truck-image'>
                    <img className='truck-image' src='/system_images/Truck.png'/>
                    <div className='truck-image-description'>Информация о доставке</div>
                </div>
                <div className='delivery-text-small'>
                    Ecomarket предлагает круглосуточную доставку продуктов на дом, в офис, на дачу или в любое другое
                    удобное для вас место.
                    Наши курьеры доставят заказ всего лишь за 90 минут!
                    Минимальная сумма заказа – 2000 руб.
                    Вы можете оформить доставку заказа на любой удобный день и даже на неделю вперёд.
                </div>
                <div className='delivery-text-big'>
                    Сохранность свежести продуктов
                </div>
                <div className='delivery-text-small'>
                    Доставка осуществляется с соблюдением всех рекомендаций по хранению и транспортировке продуктов
                    питания. Мы учитываем принцип соседства, температурный режим, особенности условий хранения. Чтобы
                    продукты не помялись, мы бережно их упаковываем и укладываем.
                </div>
                <div className='delivery-text-big'>
                    Получение заказа
                </div>
                <div className='delivery-text-small'>
                    Если вы не сможете принять заказ в обозначенное время и хотите выбрать другое время доставки, либо у вас есть дополнения к заказу, то пожалуйста, свяжитесь с контакт-центром.
                    Если у вас есть пожелания по работе сервиса, пожалуйста, также обратитесь в контакт-центр.
                </div>

            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DeliveryRules);