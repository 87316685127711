import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        REGION: state.REGION,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class footer extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	REGION: props.REGION
        }
    }

	componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    ios = () =>
    {
		return [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod'
		].includes(navigator.platform)
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    renderMobileButton = () =>
    {
    	if (typeof window.hide_mobile_activity !== "undefined") {
			if (window.hide_mobile_activity == true) {
				return '';
			}
		}
    	if (this.ios()) {
    		return (
    			<a className="footer_store_icon" href="itms-apps://itunes.apple.com/app/apple-store/id1486148531"><img src="/system_images/footer_ios_green.png"/></a>
			);
    	} else {
    		return (
    			<a className="footer_store_icon" href="https://play.google.com/store/apps/details?id=com.ecomarket_ooo"><img src="/system_images/footer_android_green.png"/></a>
			);
    	}
    }

    renderPhone = () =>
    {
    	let PHONE = '8(495)4450505';

    	switch (parseInt(this.state.REGION)) {
            case 77:
                PHONE = '8(495)4450505';
                break;
            case 78:
                PHONE = '8(812)5661006';
                break;
            case 16:
                PHONE = '8(495)4450505';
                break;
            case 52:
                PHONE = '8(495)4450505';
                break;

        }

    	return '8 (495)-159-90-09' //PHONE;
    }

    get(name){
	   if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
	      return decodeURIComponent(name[1]);
	}

	getStyle = () =>
	{
		if (this.get('hide_all_navs')) {
			return ' hidden_true';
		}

		if ((this.props.history.location.pathname == "/company/zoo") || (this.props.history.location.pathname == "/company/spbzoo")) {
        	return ' hidden_true';
        }

		return '';
	}

	getHiddenOneClass = () =>
	{
		if (typeof window.hide_mobile_activity !== "undefined") {
			if (window.hide_mobile_activity == true) {
				return ' hidden_mobile_identity';
			}
		}

		return '';
	}

	render() {
		return (
			<section id="footer" className={this.getStyle()}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 on_mobile">
							<div className="footer_mobile_promo_text">
								300 рублей в подарок на первый заказ через приложение
							</div>
							{this.renderMobileButton()}
							<div className="footer_hot_line">
								Горячая линия
							</div>
							<a target="_blank" href={"tel:74951599009"} className="footer_mobile_phone">
								{this.renderPhone()}
							</a>
							<div className="footer_mail">
								<img src="/system_images/envelope_grey.png" />
								<a href="mailto: info@ecomarket.ru">
									info@ecomarket.ru
								</a>
							</div>
						</div>
						<div className="col-lg-5">
							<ul className="footer-nav">
								<li>
									<Link to="/company/about">
										О нас
									</Link>
								</li>
								<li>
									<a target="_blank" href="https://opt.ecomarket.ru">
										Покупки для юр лиц
									</a>
								</li>
								<li>
									<Link to="/company/suppliers">
										Наши поставщики
									</Link>
								</li>
								<li>
									<Link to="/company/dostavka">
										Доставка
									</Link>
								</li>
								<li>
									<Link to="/company/contacts">
										Контакты
									</Link>
								</li>
								<li>
									<a href="https://зелёнаяработа.рф" target="_blank">
										Вакансии
									</a>
								</li>
								<li>
									<Link to="/company/oplata">
										Оплата
									</Link>
								</li>
								<li>
									<Link to="/company/polzovatelskoe-soglashenie">
										Оферта
									</Link>
								</li>
								<li>
									<Link to="/company/save-nature">
										Бережём природу
									</Link>
								</li>
							</ul>
						</div>
						<div className={"col-md-7 text-right no_mobile" + this.getHiddenOneClass()}>
							<div className="flex flex-end footer_phone_icos_holder">
								<div>
									<div className="footer_promo_text">
										300 рублей<br/>в подарок<br/>на первый заказ<br/>через приложение
									</div>
									<div className="footer_apps_links">
										<a href="https://play.google.com/store/apps/details?id=com.ecomarket_ooo">
											<img src="/system_images/play_footer_ico.png" />
										</a>
										<a href="itms-apps://itunes.apple.com/app/apple-store/id1486148531">
											<img src="/system_images/store_footer_ico.png" />
										</a>
									</div>
								</div>
								<div>
									<img className="footer_phone" src="/system_images/footer_phone.jpg" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(footer))
