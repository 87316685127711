import React, {Component} from 'react';
import { YMaps, Map, Polygon, Rectangle, SearchControl, Placemark } from 'react-yandex-maps';
import regionsJson from '../../../src/Jsons/Delivery.json'

export default class MapHelperOrder extends Component {

    constructor(props)
    {
        super(props);

        let ZOOM = 18;
        if (props.CENTER == '') {
            ZOOM = 12;
            this.START_UP_RESET = true;
        }

        this.state = {
            REGION_COORDINATES: props.REGION_COORDINATES,
            CONFIRM: props.CONFIRM,
            TYPE: props.TYPE,
            CLASS: props.CLASS,
            CENTER: this.getCenter(props.CENTER, parseInt(props.REGION)),
            REGION: parseInt(props.REGION),
            ZOOM: ZOOM,
            showButtons: props.showButtons,
            GPS: props.GPS
        }

        this.MAP = false;
        this.MAP_REF = false;
        this.setDrRef = this.setDrRef.bind(this);
        this.setMkadRef = this.setMkadRef.bind(this);
    }

    setDrRef(node) {
        this.DR = node;
    }

    setMkadRef(node) {
        this.MKAD = node;
    }

    explode = ( delimiter, string ) =>
    {
        var emptyArray = { 0: '' };

        if ( delimiter === ''
            || delimiter === false
            || delimiter === null )
        {
            return false;
        }

        if ( typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object' )
        {
            return emptyArray;
        }

        if ( delimiter === true ) {
            delimiter = '1';
        }

        return string.toString().split ( delimiter.toString() );
    }


    getCenter = (GPS, REGION) =>
    {
        if (this.props.GPS){
            return this.props.GPS
        }else
        if (GPS !== '') {
            return this.explode(',', GPS);
        } else {
                if (typeof regionsJson[REGION.toString()] !== "undefined") {
                    return regionsJson[REGION.toString()].CENTER;
                }
                return [55.754638, 37.621633];
        }
    }


    setMap = (MAP) =>
    {
        this.MAP = MAP;
    }

    setMapRef = (MAP) =>
    {
        this.MAP_REF = MAP;
    }

    getYaKey()
    {
        let items = [
            '24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
            '486bc32f-60f4-4320-9d61-c8d14935e017',
            'c0e4e3d2-913b-4873-a81a-59ea899446f8',
            '64f61949-4219-4c76-9c63-a76af46cb444',
            'a45fc414-5133-407b-a440-91d028095f30',
            'ddb478df-6120-46a8-8331-ea67cefec68c'
        ];

        var item = items[Math.floor(Math.random()*items.length)];

        return item;
    }

    renderData()
    {
        return (
            <div className="map_crosshair_pin">
                <img src="/system_images/pin_location.png" alt="focus" />
            </div>
        );
    }

    onBoundsChange = () => {
        this.props.resetGps();
        let COMPONENT = this;
        this.MAP.load(() => {
            if (this.MAP_REF) {
                if(this.MAP_REF.getCenter)
                {
                    let GPS = this.MAP_REF.getCenter();
                    this.MAP.geocode(GPS).then(function (res) {
                        let obj = res.geoObjects.get(0);
                        if (obj) {
                            let SHOWADRESS = obj.properties.get('name');
                            let ADRESS = obj.properties.get('metaDataProperty.GeocoderMetaData.text');

                            COMPONENT.props.setAdressAndGps(ADRESS,GPS,SHOWADRESS,true);
                        }
                    });
                }
            }
        });
    };

    setSuggestedAdress = (adress) =>
    {
        let COMPONENT = this;
        this.MAP.load(() => {
            this.MAP.geocode(adress).then(function (res) {
                let obj = res.geoObjects.get(0);
                let GPS = obj.geometry._coordinates;
                let ADRESS = obj.properties.get('metaDataProperty.GeocoderMetaData.text');
                COMPONENT.MAP_REF.setCenter(GPS);
                COMPONENT.getTheMkad(obj,ADRESS,COMPONENT,GPS);
            });
        });
    }

    mapLoaded = (ymaps) =>
    {
        this.setMap(ymaps);
        this.onBoundsChange();
        this.props.mapLoaded();
    }

    getSuggestions = (STRING) =>
    {
        if (STRING.length > 3) {
            let COMPONENT = this;

            this.MAP.load(() => {
                this.MAP.suggest(STRING, {results: 10, strictBounds : true})

                .then(function (items) {
                    let VARIANTS = [];
                    if (items.length) {
                        for (let KEY in items) {
                            VARIANTS.push({TITLE: items[KEY].displayName});
                        }
                    }

                    COMPONENT.props.setSuggestions(VARIANTS);   
                });
            });
        }
    }


    zoomIn = () =>
    {
        let ZOOM = this.MAP_REF.getZoom();
        this.MAP_REF.setZoom(ZOOM + 1);
    }

    zoomOut = () =>
    {
        let ZOOM = this.MAP_REF.getZoom();
        this.MAP_REF.setZoom(ZOOM - 1);
    }

    locateMe = () =>
    {
        let COMPONENT = this;
        navigator.geolocation.getCurrentPosition(
            function (position) {
                COMPONENT.MAP_REF.setCenter([ position.coords.latitude, position.coords.longitude]);
            }, function (error) {
                console.log(error);
            }, 
            {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 1000
            }
        );
    }

    renderMapButtons = () =>
    {
        if (typeof this.state.showButtons !== "undefined") {
            if (this.state.showButtons == "false") {
                return false;
            }
        }
        let BUTTON = '';
        if (navigator.geolocation) {
            BUTTON = (
                <a className="map_locate" onClick={() => this.locateMe()}><img src="/system_images/airplane.png" /></a>
            )
        }

        return (
            <div className="map_buttons">
                <div className="map_pm_btns">
                    <a className="map_plus" onClick={() => this.zoomIn()}>+</a>
                    <a className="map_minus" onClick={() => this.zoomOut()}>-</a>
                </div>
                {BUTTON}
            </div>
        );
    }

    render() {
        return (
            <YMaps 
                query={{
                    apikey: this.getYaKey(),
                    load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,control.SearchControl'
                }}
            >
                <Map 
                    className={this.state.CLASS}
                    instanceRef={map => this.setMapRef(map)}
                    defaultState={{
                        center: this.state.CENTER,
                        zoom: this.state.ZOOM,
                        controls: []
                    }}
                    onLoad={(ymaps) => this.mapLoaded(ymaps)}
                    onBoundsChange={this.onBoundsChange}
                >
                    {this.renderData()}
                    {this.renderMapButtons()}
                </Map>
            </YMaps>
        );
    }

}


