import React, {Component} from 'react';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


function mapStateToProps(state) {
    return {
        FORCED_ADDRESS_SELECT_MODAL: state.FORCED_ADDRESS_SELECT_MODAL,
        ADRESS: state.ADRESS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DeliveryInformation extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.FORCED_ADDRESS_SELECT_MODAL !== prevProps.FORCED_ADDRESS_SELECT_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.FORCED_ADDRESS_SELECT_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    callMap = () =>
    {
        this.props.openForcedAddressSelectModal(false)
        if(window.innerWidth < 1200){
            this.props.openDeliveryAddressModal(true)
        }
        else{
            this.props.openDeliveryAddressMixedModal('adress')
        }
    }

    render() {
        if (this.props.FORCED_ADDRESS_SELECT_MODAL) {
            return (
                <div>
                    <div className="modal-overlay" onClick={() => this.props.openForcedAddressSelectModal(false)}>
                    </div>
                    <div className="modal-box address-choose-alert">

                        <div className="deliverer-holder">
                            <div>
                                <img className='deliverier-image' src="/system_images/Deliverer.png" />

                                <div className='delivery-text-information'>
                                    Укажите ваш адрес, пожалуйста,<br/> так как нам важно убедиться, что мы сможем<br/> доставить товары по вашему адресу</div>
                                <a className="save-button-deliverer" onClick={this.callMap}>Указать адрес</a>
                                <div className= 'skip-address-choiсe' onClick={() => this.props.openForcedAddressSelectModal(false)}>пропустить</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}
    export default connect(
    mapStateToProps, mapDispatchToProps
)(DeliveryInformation);