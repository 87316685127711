import regionsJson from '../../../Jsons/Delivery.json'
import {checkIdPointInsideCity} from './CheckIdPointInsideCity.js'

export function checkRegionEntry(gps) {
    for (let region_key in regionsJson) {
        if (typeof regionsJson[region_key].REGION !== "undefined") {
            if (EntryToRegionPoint(gps, regionsJson[region_key].REGION)) {
                return checkIdPointInsideCity(gps, regionsJson[region_key]);
            }
        }
    }
    return false;
}

function EntryToRegionPoint(gps, region) {
    let CHECK = false;

    if ((gps[0] < region[0][0]) && (gps[0] > region[1][0]) && (gps[1] > region[0][1]) && (gps[1] < region[1][1])) {
        CHECK = true;
    }

    return CHECK;
}

export function checkIfLocationIsInRussia(adress)
{
    if (typeof adress == "string") {
        let adress_parts = adress.split(',');
        if (adress_parts[0] == 'Россия') {
            return {
                REGION_KEY: 'OTHER',
                TITLE: adress_parts[0] + ', ' + adress_parts[1]
            }
        }
    }

    return false;
}