import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        TOKEN: state.TOKEN,
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CommentForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            STARS: false,
            ID: props.ID,
            COMMENT: '',
            NAME: props.USER.NAME,
            SUBMITTED: false,
            COMMENTED: false,
            CHECKED: false,
            BOUGHT: false
        };
    }

    componentDidMount()
    {
        if (this.props.USER) {
            this.checkCommented();
        } else {
            this.setState({CHECKED: true});
        }
    }

    checkCommented = async () =>
    {
        if (this.props.USER) {
            let SEND_DATA = {
                action:         'checkCommented',
                apikey:         this.props.USER.TOKEN,
                id:             this.state.ID
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                if (data.commented == 1) {
                    this.setState({COMMENTED: true, CHECKED: true, BOUGHT: data.bought});
                } else {
                    this.setState({CHECKED: true, BOUGHT: data.bought});
                }
            } else {
                this.setState({CHECKED: true, BOUGHT: false});
            }
        }
    }

    leaveComment = async () =>
    {
        if (this.state.COMMENT.length > 5) {
            let SEND_DATA = {
                action:         'leaveComment',
                apikey:         this.props.USER.TOKEN,
                name:           this.state.NAME,
                comment:        this.state.COMMENT,
                id:             this.state.ID,
                stars:          this.state.STARS
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({COMMENT: '',NAME: '',STARS: 0, SUBMITTED: true});
            }
        }
    }

    updateStars = (e) => {
        if(e.target.checked) {
           this.setState({STARS: e.target.value});
        }
    }

    recordComment = (text) => 
    {
        this.setState({COMMENT: text.target.value});
    }

    recordName = (text) => 
    {
        this.setState({NAME: text.target.value});
    }

    renderStar(integer)
    {
        if (parseInt(this.state.STARS) >= parseInt(integer)) {
            return <i className="fa fa-star"></i>;
        } else {
            return <i className="fa fa-star-o"></i>;
        }
    }

    renderSubmitButton = () =>
    {
        if (this.state.COMMENT.length > 5) {
            return (
                <a onClick={() => this.leaveComment()} className="comment_submit_button">
                    Отправить
                </a>
            );
        } else {
            return (
                <a className="comment_submit_button inactive_btn">
                    Отправить
                </a>
            );
        }
    }

    renderForm()
    {
        return (
            <div id="comment">
                <div className="form-between">
                    <div className="stars_big_holder">
                        <div className="stars_label">
                            Оценить товар
                        </div>
                        <div className="stars">
                            <input className="star star-5" id="star-5" type="radio" name="star" value="5" onChange={this.updateStars}/ >
                            <label className="star star-5" htmlFor="star-5">
                                {this.renderStar(5)}
                            </label>

                            <input className="star star-4" id="star-4" type="radio" name="star" value="4" onChange={this.updateStars}/ >
                            <label className="star star-4" htmlFor="star-4">
                                {this.renderStar(4)}
                            </label>

                            <input className="star star-3" id="star-3" type="radio" name="star" value="3" onChange={this.updateStars}/ >
                            <label className="star star-3" htmlFor="star-3">
                                {this.renderStar(3)}
                            </label>

                            <input className="star star-2" id="star-2" type="radio" name="star" value="2" onChange={this.updateStars}/ >
                            <label className="star star-2" htmlFor="star-2">
                                {this.renderStar(2)}
                            </label>

                            <input className="star star-1" id="star-1" type="radio" name="star" value="1" onChange={this.updateStars}/ >
                            <label className="star star-1" htmlFor="star-1">
                                {this.renderStar(1)}
                            </label>
                        </div>
                    </div>

                </div>
                    <textarea placeholder="Оставьте ваш отзыв" rows="1" className="ui_input requiredinput comment_textarea" type="text" name="comment" value={this.state.COMMENT} onChange={(text) => this.recordComment(text)}></textarea>

                    {this.renderSubmitButton()}
                    

                
            </div>
        );
    }

	render()
    {
        if (!this.state.CHECKED) {
            return false;
        }

        
        
        if (!this.props.USER) {
            return (
                <div className="comment_form_message">
                    Зарегистрируйтесь, чтобы оставить отзыв
                </div>
            );
        }

        if (!this.state.BOUGHT) {
            return (
                <div className="comment_form_message">
                    Чтобы оставить отзыв, вам надо сначала приобрести товар
                </div>
            );
        }

        if (this.state.COMMENTED) {
            return (
                <div className="comment_form_message">
                    Спасибо, ваш отзыв на этот товар уже здесь есть
                </div>
            );
        }
        
        if (this.state.SUBMITTED) {
            return (
                <div className="comment_form_success">
                    Спасибо за ваш отзыв!
                </div>
            );
        }

        return (
            <div className="comment_form">
                {this.renderForm()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CommentForm);