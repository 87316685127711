import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"
import {CSSTransition, TransitionGroup, CSSTransitionGroup} from 'react-transition-group';
import CostWithImage from "./../../../APP/MODALS/DeliveryInformation/CostWithImage.js";
import CostGrid from "./../../../APP/MODALS/DeliveryInformation/CostGrid.js"
import {YMaps} from 'react-yandex-maps';

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';
import MapDesktop from "../../MODALS/YandexMaps/MapDesktop";
import {checkRegionEntry} from "../../HELPERS/CoordinatesHelders/DefinitionOfTheRegion";
import MapHelperOrder from "../../HELPERS/MapHelperOrder";

import MobileLocationData from "../../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

function mapStateToProps(state, myProps) {
    return {
        REGION: state.REGION,
        ADRESS: state.ADRESS,
        GPS: state.GPS,
        SHOWADRESS: state.SHOWADRESS,
        THREE_THOUSAND: state.THREE_THOUSAND,
        FOUR_THOUSAND: state.FOUR_THOUSAND,
        NINE_THOUSAND: state.NINE_THOUSAND,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Delivery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            REGION: props.REGION,
            ERROR: false,
            SUCCESS: false,
            TEMP_ADRESS: '',
            SUGGESTIONS: [],
            ADRESS: props.ADRESS,
            KM: 0
        }

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.items = this.getItemsPerScreen();

        this.setSuggestionRef = this.setSuggestionRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.handleScroll = this.handleScroll.bind(this);

        this.justChanged = false;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
        if (this.props.ADRESS !== prevProps.ADRESS) {
            if (!this.justChanged) {
                this.setState({ADRESS: this.props.ADRESS});
            } else {
                this.justChanged = false;
            }

        }
        if (this.props.USER) {
            if (this.props.USER.ADRESS !== '') {
                let ADRESS = this.props.USER.ADRESS;
                if (this.props.ADRESS) {
                    ADRESS = this.props.ADRESS;
                }

                let COMPONENT = this;
                this.interval = setInterval(function () {
                    if (COMPONENT.state.MAP_LOADED) {
                        clearInterval(COMPONENT.interval);
                        COMPONENT.mapHelper.setSuggestedAdress(ADRESS);
                    }
                }, 10);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setSuggestionRef(node) {
        this.suggestion = node;
    }

    handleClickOutside(event) {
        if (this.suggestion && !this.suggestion.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
    }


    handleScroll(event) {

    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    getItemsPerScreen() {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 1;
        } else if (WIDTH < 769) {
            return 1;
        } else if (WIDTH < 1050) {
            return 2;
        } else if (WIDTH < 1200) {
            return 3;
        } else if (WIDTH < 9900) {
            return 3;
        }
    }

    renderBreadCrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Главная'
        });

        DATA.push({
            'to': '/company/dostavka',
            'title': 'Доставка'
        });

        return <BreadCrumbs DATA={DATA}/>
    }

    getTitle = () => {
        switch (this.state.REGION) {
            case 78:
                return 'Доставка продуктов по Санкт-Петербургу';
                break;
            default:
                return 'Доставка';
                break;
        }
    }

    getKeywords = () => {
        switch (this.state.REGION) {
            case 78:
                return 'Доставка продуктов по Санкт-Петербургу';
                break;
            default:
                return 'Доставка';
                break;
        }
    }

    getDescription = () => {
        switch (this.state.REGION) {
            case 78:
                return 'Доставка продуктов по Санкт-Петербургу';
                break;
            default:
                return 'Доставка';
                break;
        }
    }

    renderGallery() {
        let SETTINGS = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: this.items,
            slidesToScroll: 1,
            className: 'static-gallery',
            arrows: true,
            swipeToSlide: true
        };

        let IMAGES = []
        for (let i = 1; i <= 6; i++) {
            IMAGES.push(
                <div className="static_gallery_item" key={i}>
                    <img src={'/system_images/static_pages/del' + i + '.jpg'} alt={this.getTitle()}/>
                </div>
            )
        }

        return (
            <Slider ref={c => (this.slider = c)} {...SETTINGS}>
                {IMAGES}
            </Slider>
        );
    }

    renderFirstPart() {
        if (this.state.REGION == 78) {
            return (
                <div className="static_text_block">
                    <div className="static_text_body">
                        Ecomarket предлагает круглосуточную доставку продуктов на дом, в офис, на дачу или в любое
                        другое удобное для вас место.
                        <br/>
                        Минимальная сумма заказа – 2000 руб.
                        <br/>
                        Вы можете оформить доставку заказа на любой удобный день в течение 1 месяца.
                    </div>
                </div>
            );
        } else if (this.state.REGION == 16) {
            return (
                <div className="static_text_block">
                    <div className="static_text_body">
                        Ecomarket предлагает круглосуточную доставку продуктов на дом, в офис, на дачу или в любое
                        другое удобное для вас место.
                        <br/>
                        Минимальная сумма заказа – 2000 руб.
                        <br/>
                        Вы можете оформить доставку заказа на любой удобный день в течение 1 месяца.
                    </div>
                </div>
            );
        } else if (this.state.REGION == 52) {
            return (
                <div className="static_text_block">
                    <div className="static_text_body">
                        Ecomarket предлагает круглосуточную доставку продуктов на дом, в офис, на дачу или в любое
                        другое удобное для вас место.
                        <br/>
                        Минимальная сумма заказа – 2000 руб.
                        <br/>
                        Вы можете оформить доставку заказа на любой удобный день в течение 1 месяца.
                    </div>
                </div>
            );
        } else {
            return (
                <div className="static_text_block">
                    <div className="static_text_body">
                        Ecomarket предлагает круглосуточную доставку продуктов на дом, в офис, на дачу или в любое
                        другое удобное для вас место.
                        <br/>
                        Наши курьеры доставят заказ всего лишь за 90 минут!
                        <br/>
                        Минимальная сумма заказа – 2000 руб.
                        <br/>
                        Вы можете оформить доставку заказа на любой удобный день в течение 1 месяца.
                    </div>
                </div>
            );
        }
    }

    addressTextShow() {
        if (this.props.ADRESS) {
            return this.props.ADRESS
        } else {
            return ''
        }
    }

    renderSearchBox() {
        return (
            <div className="green_blocks_flex">
                <div className='green-blocks_small_text'>Стоимость доставки</div>
                <div className='green-blocks_big_text'>Выберите ваш адрес, пожалуйста</div>
                <div>
                    <input className={this.errorDetected()} value={this.state.ADRESS}
                           placeholder="Город, улица, дом, корпус..."
                           onChange={(text) => this.recordTempAdress(text)}></input>
                    <div className="modal_suggestions_holder">{this.renderSuggestions()}</div>
                </div>
            </div>
        )
    }


    setSuggestions = (data) => {
        if (data.length) {
            this.setState({SUGGESTIONS: data, SHOW_SUGGESTIONS: false});
        } else {
            this.setState({SUGGESTIONS: [], SHOW_SUGGESTIONS: false});
        }
    }
    renderSuggestions = () => {
        if ((this.state.SUGGESTIONS) && (this.state.SUGGESTIONS.length)) {
            let SUGGESTIONS = [];

            if (this.state.SUGGESTIONS.length) {
                for (let i = 0; i < this.state.SUGGESTIONS.length; i++) {
                    let SEG = this.state.SUGGESTIONS[i];
                    SUGGESTIONS.push(
                        <a
                            key={i}
                            onClick={() => this.setSuggestedInChild(SEG.TITLE)}
                        >
                            {SEG.TITLE}
                        </a>
                    );
                }
            }

            if (SUGGESTIONS.length) {
                return (
                    <div className="modal_suggestions_list" ref={this.setSuggestionRef}>
                        {SUGGESTIONS}
                    </div>
                );
            }

            return false;
        }

        return false;
    }


    recordTempAdress = (text) => {
        this.callable(text);
        this.setState({ADRESS: text.target.value});

    }

    setSuggestedInChild = (adress) => {
        this.justChanged = true;
        this.setState({ADRESS: adress, SUGGESTIONS: false});
        this.callable2(adress)
    }

    errorDetected = () => {
        console.log(this.state.ERROR)
        if (!this.state.ERROR) {
            return 'green_blocks_input'
        } else {
            return 'green_blocks_input_alert'
        }
    }

    changeAdress = (adress, showadress, gps, km) => {
        this.setState({ERROR: false})
        let REGION_DATA = checkRegionEntry(gps);
        if (!REGION_DATA) {
            this.setState({ERROR: true})
        }
        let SUCCESS = false;
        let ERROR = true;
        if (REGION_DATA) {
            ERROR = false;
            SUCCESS = true;
        }

        if (SUCCESS) {
            let DATA = {
                KM: km,
                ADRESS: adress,
                GPS: gps,
                SHOWADRESS: showadress
            };

            this.props.setAdressAndGpsAndRegion(DATA);
        }
    }


    renderSecondPart() {
        if (this.props.ADRESS) {
            if (!this.state.ERROR) {
                return (
                    <div className='delivery-page-information'>
                        <div className='delivery-page-information-text-block'>
                            <div className='text-line'>
                                <div className='text-block-small-text'>Сумма заказа:</div>
                                <div className='text-block-big-text'>2000 - 3499 р</div>
                                <div className='text-block-big-text'>от 3500 р</div>
                                {/*<div className='text-block-big-text'>от 8500 р</div>*/}
                            </div>
                            <div>
                                <div className='text-block-small-text'>Цена доставки:</div>
                                <div className='text-block-green-text'>{299 + ' р'}</div>
                                <div className='text-block-green-text'>{'Бесплатно'}</div>
{/*                                <div className='text-block-green-text'>{this.props.THREE_THOUSAND}</div>
                                <div className='text-block-green-text'>{this.props.FOUR_THOUSAND}</div>
                                <div className='text-block-green-text'>{this.props.NINE_THOUSAND}</div>*/}
                            </div>
                        </div>
                        <div className='delivery-page-information-days-block'>

                            <div className='delivery-page-information-days-block small-text'>Стоимость и интервалы по
                                дням
                                недели:
                            </div>
                            <div className='line-grid'>
                                <CostGrid CLASS_ADDON='delivery-position'/>
                            </div>

                        </div>
                    </div>
                )
            }
        }
    }


    renderContent() {
        return (
            <div className="static_page_body">
                {this.renderFirstPart()}
                {this.renderSearchBox()}
                {this.renderSecondPart()}

                <div className="static_text_block">
                    <div className="static_text_head">
                        Сохранность свежести продуктов
                    </div>
                    <div className="static_text_body">
                        Доставка осуществляется с соблюдением всех рекомендаций по хранению и транспортировке продуктов
                        питания. Мы учитываем принцип соседства, температурный режим, особенности условий хранения.
                        Чтобы продукты не помялись, мы бережно их упаковываем и укладываем.
                    </div>
                </div>


                <div className="static_text_block">
                    <div className="static_text_head">
                        Получение заказа
                    </div>
                    <div className="static_text_body">
                        Если вы не сможете принять заказ в обозначенное время и хотите выбрать другое время доставки,
                        либо у вас есть дополнения в заказе, то пожалуйста, свяжитесь с <Link
                        to="/company/contacts">контакт-центром</Link>.
                        <br/>
                        Если вас не устроило качество товара, пожалуйста, обратитесь в контакт-центр и оставьте заявку
                        на возврат.
                    </div>
                </div>

                <div className="delivery_gallery">
                    <img src="/system_images/delivery/1.jpg" className="big_image"/>
                    <img src="/system_images/delivery/2.jpg" className="small_image"/>
                    <img src="/system_images/delivery/3.jpg" className="small_image"/>
                    <img src="/system_images/delivery/4.jpg" className="small_image"/>
                    <img src="/system_images/delivery/5.jpg" className="small_image"/>
                </div>
            </div>
        );
    }

    resetGps = () => {
        this.setState({gps: false});
    }
    mapLoaded = () => {
        let COMPONENT = this;
        setTimeout(function () {
            COMPONENT.setState({MAP_LOADED: true});
        }, 500);
    }

    render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            >
                <div className="right-part">
                    <Helmet>
                        <title>{this.getTitle()}</title>
                        <meta name="description" content={this.getDescription()}/>
                        <meta name="keywords" content={this.getKeywords()}/>
                    </Helmet>
                    <MobileLocationData/>
                    <DesktopDeliveryInformation/>
                    {this.renderBreadCrumbs()}
                    <div id="products" className="page-content">
                        <div className="static_page_content">
                            <h1>{this.getTitle()}</h1>

                            {this.renderContent()}
                        </div>
                    </div>

                    <ModalsContainer/>
                </div>
                <FooterBlock/>
                <MapDesktop
                    className='transparent-map'
                    updateData={this.updateData}
                    confirmThisAdress={this.confirmThisAdress}
                    CONFIRM={true}
                    TYPE="GET_CENTER"
                    setAdressAndGps={this.setAdressAndGps}
                    REGION='78'
                    changeAdress={this.changeAdress}
                    GPS=''
                    setCallable={callable => this.callable = callable}
                    setCallable2={callable2 => this.callable2 = callable2}
                    setSuggestions={this.setSuggestions}
                />

            </CSSTransitionGroup>

        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Delivery))