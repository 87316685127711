import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import LazyLoad from 'react-lazyload';
import {CSSTransitionGroup} from 'react-transition-group';
import CONFIG from './../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import TextedRadio from './../../APP/COMPONENTS/ContentParts/AnketaParts/TextedRadio.js';

function mapStateToProps(state) {
    return {
        TOKEN: state.TOKEN,
        USER: state.USER,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Unsubscribe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            WHY: false,
            UN: false,
            TOKEN: this.props.USER.TOKEN
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.USER.TOKEN) {
            this.props.openLK(true)
        }
        if (prevProps.USER.TOKEN !== this.props.USER.TOKEN) this.setState({TOKEN:this.props.USER.TOKEN})
    }

    async componentDidMount() {
        let USER = JSON.parse(await localStorage.getItem('USER'))
        if (USER === null) {
            // this.props.openLK(true)
        } else {
            // this.setState({TOKEN: this.props.USER.TOKEN})
        }
    }

    setField = (TITLE, VALUE) => {
        this.setState({[TITLE]: VALUE});
    }

    resetField = (TITLE) => {
        this.setState({[TITLE]: ''});
    }

    doWhy = async () => {
        let SEND_DATA = {
            action: 'unsubscribeUser',
            reason: this.state.WHY,
            apikey: this.state.TOKEN,
        };

        let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
        if (data !== false) {
            this.setState({UN: true});
        }
        this.setState({UN:true})
    }

    renderButton = () => {
        if ((this.state.WHY) && (this.state.TOKEN)) {
            return (
                <button className="orange-button" onClick={() => this.doWhy()}>
                    Отправить
                </button>
            );
        } else {
            return (
                <button className="unsubscribeBtn">
                    Отправить
                </button>
            );
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    renderContent = () => {
        if (this.state.UN) {
            return (
                <div>
                    <div className="unsubscribe_small">
                        Спасибо! Ваш ответ поможет нам стать лучше!
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="unsubscribe_small">
                        Расскажите нам, почему вы решили отписаться от нашей рассылки?
                    </div>
                    <div className="unsubscribe_radios">
                        <TextedRadio
                            setField={this.setField}
                            resetField={this.resetField}
                            field="WHY"
                            data={[
                                {VALUE: 'Я не люблю рассылки', TEXT: 'Я не люблю рассылки'},
                                {
                                    VALUE: 'Ваши рассылки приходят слишком часто',
                                    TEXT: 'Ваши рассылки приходят слишком часто'
                                },
                                {VALUE: 'Неинтересная информация', TEXT: 'Неинтересная информация'},
                                {VALUE: 'Неинтересные товары в рассылке', TEXT: 'Неинтересные товары в рассылке'},
                                {VALUE: 'Я уже не являюсь вашим клиентом', TEXT: 'Я уже не являюсь вашим клиентом'},
                                {VALUE: 'Иная причина', TEXT: 'Иная причина'},
                            ]}
                        />
                    </div>
                    <div className="unform">
                        {this.renderButton()}
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh'}}>
                <div className="right-part">
                    <Helmet>
                        <title>Вы отписались</title>
                        <meta name="description" content="Вы отписались"/>
                        <meta name="keywords" content="Вы отписались"/>
                    </Helmet>
                    <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid unsubscribe_container">
                            <div className="unsubscribe_head">
                                Мы отписали вас от нашей рассылки
                            </div>
                            {this.renderContent()}
                        </div>
                    </div>

                    <ModalsContainer/>
                </div>
                <FooterBlock/>
                </div>

                <MobileMessage/>

            </CSSTransitionGroup>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Unsubscribe))