import React, {Component} from 'react';
import InputMask from "react-input-mask";

import CONFIG from './../../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class ProductInput extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: props.PRODUCT,
            FIELD: props.field,
            SUGGESTIONS: false,
            RESULTS: false,
            PLACEHOLDER: props.PLACEHOLDER
        }

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setHistoryRef(node) {
        this.history = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
    }

    recordPhone = (text) => 
    {
        this.validate(text.target.value);
    }

    validate = (string) =>
    {
        if (string.length > 1) {
            this.props.setField(this.state.FIELD,string)
        } else {
            this.props.resetField(this.state.FIELD);
        }
    }

    setSearchResults(IDS, text)
    {
        let PRODUCTS = this.props.PRODUCTS;
        let FOUND = [];
        let COMPONENT = this;

        let MAX = 10;
        for (let i =0; i < IDS.length; i++) {
            let ID = IDS[i];
            if (typeof PRODUCTS[ID] !== "undefined") {
                let PRODUCT = PRODUCTS[ID];
                if (FOUND.length <= MAX) {
                    FOUND.push(
                        <span key={PRODUCT.id} className="search-result-row" onClick={() => this.select(PRODUCT.id, PRODUCT.title)}>
                            {PRODUCT.title}
                        </span>
                    );
                }
            }
        }


        this.setState({RESULTS: FOUND, SUGGESTIONS: true});
    }

    select = (id, title) =>
    {
        this.props.setField(this.state.FIELD,id);
        this.setState({STRING: title, RESULTS: [], SUGGESTIONS: false})
    }

    filterProducts = async (text) => 
    {
        try {
            let SEND_DATA = {
                action:     'searchString',
                string:     text
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                if (data.results.length) {
                    this.setSearchResults(data.results, text);
                } else {
                    this.setState({RESULTS: [], SUGGESTIONS: false});
                }
            } else {
                this.setState({RESULTS: [], SUGGESTIONS: false});
            }
        } catch (error) {
            this.setState({RESULTS: [], SUGGESTIONS: false});
        }
        
    }

    somethingInputted = (string) =>
    {
        let STRING = string.target.value;
        this.setState({STRING: STRING});

        if (STRING.length > 1) {
            this.props.setField(this.state.FIELD,STRING);
            this.filterProducts(STRING);
        } else {
            this.setState({SUGGESTIONS: false, RESULTS: false})
        }
    }

    renderResults()
    {
        if (this.state.SUGGESTIONS) {
            if (this.state.RESULTS) {
                return (
                    <div className="search" ref={this.setHistoryRef}>
                        {this.state.RESULTS}
                    </div>
                );
            }
        }
    }

    render()
    {
        let PLACEHOLDER = '';
        if (typeof this.state.PLACEHOLDER !== "undefined") {
            PLACEHOLDER = this.state.PLACEHOLDER;
        }
    	return (
            <div className="product_searcher">
                <input placeholder={'Выберите товар'} type="text" onChange={(text) => this.somethingInputted(text)} value={this.state.STRING}  />
                {this.renderResults()}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductInput)
