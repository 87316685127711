import React, {Component} from 'react';
import InputMask from "react-input-mask";
export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default class EmailInput extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            NAME: props.NAME
        }
    }

    recordValue = (text) => 
    {
        this.validate(text.target.value);
    }

    validateEmail = (email) =>
    {
        return emailRegex.test(email);
    }

    validate = (string) =>
    {
        if (this.validateEmail(string)) {
            this.props.setField('EMAIL',string)
        } else {
            this.props.resetField('EMAIL');
        }
    }

    render()
    {
    	return <input placeholder={this.props.placeholder} type="email" onChange={(text) => this.recordValue(text)} defaultValue={this.state.NAME} />
    }
}
