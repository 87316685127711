import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';

const STEP = 1;

const Slider = ({ MIN, MAX, MIN_VAL, MAX_VAL, recordValues, noSlider = false }) => {
  const [values, setValues] = React.useState([MIN_VAL, MAX_VAL]);
  const recordLocalValues = (values) => {
    setValues(values)
    recordValues(values)
  }

  const recordValueFromInput = (index, values, inputValue) => {
    let new_values = [...values]
    new_values[index] = inputValue
    recordLocalValues(new_values)
  }

  return (
    <div
      className="range_slider_holder"
      style={ {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
      } }
    >
      <div className="inputs">
        <input type="number" value={ parseInt(values[0]) } onChange={ input => recordValueFromInput(0, values, input.target.value) } />
        <input type="number" value={ parseInt(values[1]) } onChange={ input => recordValueFromInput(1, values, input.target.value) } />
      </div>
      { !noSlider ? <Range
        values={ values }
        step={ STEP }
        min={ MIN }
        max={ MAX }
        onChange={ (values) => recordLocalValues(values) }
        renderTrack={ ({ props, children }) => (
          <div
            onMouseDown={ props.onMouseDown }
            onTouchStart={ props.onTouchStart }
            style={ {
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            } }
          >
            <div
              ref={ props.ref }
              style={ {
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', '#548BF4', '#ccc'],
                  min: MIN,
                  max: MAX
                }),
                alignSelf: 'center'
              } }
            >
              { children }
            </div>
          </div>
        ) }
        renderThumb={ ({ index, props, isDragged }) => (
          <div
            { ...props }
            style={ {
              ...props.style,
              height: '20px',
              width: '20px',
              borderRadius: '50%',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            } }
          >
            <div
              style={ {
                position: 'absolute',
                top: '-28px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                padding: '4px',
                borderRadius: '4px',
                backgroundColor: '#548BF4'
              } }
            >
              { parseInt(values[index]) }
            </div>
            <div
              style={ {
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC'
              } }
            />
          </div>
        ) }
      /> : null }
    </div>
  );
};

export default Slider;