import React, {Component} from 'react';
/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {sendPostRequest} from "../../HELPERS/requests";
import CONFIG from "../../../SYSTEM/Config";
import moment from "moment";

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION,
        ADRESS: state.ADRESS,
        SHOWADRESS: state.SHOWADRESS,
        PRODUCTS: state.PRODUCTS,
        CART: state.CART,
        KM: state.KM,
        INSIDE_MKAD: state.INSIDE_MKAD,
        USEABLE_PERIODS: state.USEABLE_PERIODS,
        PolygonsMinutes: state.PolygonsMinutes,
        PolygonsDeliveryPrice: state.PolygonsDeliveryPrice,
        PolygonsFreeDeliveryWhenSum: state.PolygonsFreeDeliveryWhenSum,

        POSTAL_DAYS: state.POSTAL_DAYS,
        POSTAL_PRICE: state.POSTAL_PRICE
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const Price = (switcher) => {
    if (switcher) {
        return '599 р'
    } else {
        return '299 р'
    }
}

class CostGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ORDERS_COUNT: 9999,
            MKAD: props.KM,
        }
    }

    componentDidMount() {
        if (this.props.USER) {
            this.fetchOrdersCount(this.props.USER.PHONE)
        }
        let three = this.countDeliverPerPrice(3000)
        let four = this.countDeliverPerPrice(4000)
        let nine = this.countDeliverPerPrice(9000)
        this.props.setPriceOfDeliveryPerPrice({three,four,nine})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.KM !== this.state.MKAD) {
            this.setState({MKAD: this.props.KM});
        }
    }

    fetchOrdersCount = async (phone) => {
        try {
            let SEND_DATA = {
                action: 'getOrdersCountByPhone',
                phone: phone

            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ORDERS_COUNT: data.orders_count});
            }
        } catch (error) {
            return false;
        }
    }

    countCart() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        let FREE_DEL = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;

                        if (PRODUCTS[ID].fd > 0) {
                            FREE_DEL = 1;
                        }
                    }
                }
            }
        }

        this.SUM = SUM;

        return {
            FREE_DEL: FREE_DEL,
            SUM: SUM
        };
    }

    num_title = (n, titles) => {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    countDeliverPerPrice = (sum) => {
        let SUM = sum

        if ((parseInt(this.state.ORDERS_COUNT) < 4) && (parseInt(this.state.MKAD) <= 10)) {
            return 'Бесплатно'
        }

        if (this.props.INSIDE_MKAD) {
            if (this.props.PolygonsDeliveryPrice !== '-1') {
                if (Number(this.props.PolygonsFreeDeliveryWhenSum) <= SUM) {
                    this.state.DELIVERY_PRICE = 0
                    return 'Бесплатно'
                } else {
                    this.state.DELIVERY_PRICE = Number(this.props.PolygonsDeliveryPrice)
                    return this.props.PolygonsDeliveryPrice + ' р'
                }
            }
            if (SUM > 3500) {
                return 'Бесплатно';

            } else {
                return '299 р';
            }
        } else {
            let KM = this.props.KM;
            let SET_SUM = 0;
            let LEFT_KM = 0;

            if (SUM < 8500) {
                if (KM < 30) {
                    SET_SUM = KM * 30;
                } else {
                    LEFT_KM = KM - 30;
                    SET_SUM = 30 * 30 + (LEFT_KM * 50);
                }
            } else {
                if (KM > 30) {
                    var use_km = KM - 30;
                    SET_SUM = use_km * 50;
                }
            }
            let CITY_ADDON = 299;
            if (SUM > 3500) {
                CITY_ADDON = 0;
            }
            SET_SUM += CITY_ADDON;
            if (SET_SUM == 0) {
                return 'Бесплатно';
            }
            return SET_SUM + 'р';
        }
    }

    countDeliverySum = (little_rub) => {


        let SUM_DATA = this.countCart();
        let SUM = SUM_DATA.SUM;

        if ((parseInt(this.state.ORDERS_COUNT) < 4) && (parseInt(this.state.MKAD) <= 10)) {
            return (
                <span className="delivery_price_green">
                            Бесплатно
                </span>
            );
        }

        if (this.props.INSIDE_MKAD) {


            if (this.props.PolygonsDeliveryPrice !== '-1') {
                if (Number(this.props.PolygonsFreeDeliveryWhenSum) <= SUM) {
                    this.state.DELIVERY_PRICE = 0
                    return (
                        <span className="delivery_price_green">
                            Бесплатно
                        </span>
                    )
                } else {
                    this.state.DELIVERY_PRICE = Number(this.props.PolygonsDeliveryPrice)
                    return this.props.PolygonsDeliveryPrice + ' р'

                }
            }

            if (SUM > 3500) {
                if (typeof little_rub !== "undefined") {
                    return (
                        <span className="delivery_price_green">
                            Бесплатно
                        </span>
                    );
                } else {
                    return 'Бесплатно';
                }
            } else {
                if (typeof little_rub !== "undefined") {
                    return '299 р';
                } else {
                    return '299 рублей';
                }

            }
        } else {
            let KM = this.props.KM;
            let SET_SUM = 0;
            let LEFT_KM = 0;

            if (SUM < 8500) {
                if (KM < 30) {
                    SET_SUM = KM * 30;
                } else {
                    LEFT_KM = KM - 30;
                    SET_SUM = 30 * 30 + (LEFT_KM * 50);
                }
            } else {
                if (KM > 30) {
                    var use_km = KM - 30;
                    SET_SUM = use_km * 50;
                }
            }

            let CITY_ADDON = 299;
            if (SUM > 3500) {
                CITY_ADDON = 0;
            }

            SET_SUM += CITY_ADDON;

            if (SET_SUM == 0) {
                if (typeof little_rub !== "undefined") {
                    return (
                        <span className="delivery_price_green">
                            Бесплатно
                        </span>
                    );
                } else {
                    return 'Бесплатно';
                }
            }

            //SET_SUM=100
            if (typeof little_rub !== "undefined") {
                return SET_SUM + 'р';
            } else {
                return SET_SUM + ' ' + this.num_title(SET_SUM, ['рубль', 'рубля', 'рублей']);
            }

        }
    }

    amountCart() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].weight * CART[ID].amount;
                        SUM += ROW_SUM;


                    }
                }
            }
        }

        this.SUM = SUM;

        return {
            SUM
        };
    }

    renderDates = () => {
        let DAYS = [];

        for (let i in this.props.USEABLE_PERIODS) {
            let DATE = this.props.USEABLE_PERIODS[i];
            let START_TIME = false;
            let END_TIME = false;
            let cartWeight = this.amountCart()
            for (let j = 0; j < DATE.times.length; j++) {
                if (j == 0) {
                    let START_PARTS = DATE.times[j].split('-');
                    START_TIME = START_PARTS[0];
                    if (this.props.PolygonsMinutes !== '-1' && i === moment().format('YYYY-MM-DD') && cartWeight.SUM < 15) {
                        let deliveryTime = Number(this.props.PolygonsMinutes) + 15
                        START_TIME = moment().add(Number(this.props.PolygonsMinutes), 'm').format('HH:mm')
                    }
                }
                if (j == DATE.times.length - 1) {
                    let END_PARTS = DATE.times[j].split('-');
                    let END_PARTS_HOUR = END_PARTS[0].split(':')
                    END_TIME = END_PARTS_HOUR[0] + ':59';
                }
            }
            if (END_TIME && START_TIME) {
                DAYS.push(
                    <div className="delivery_modal_day" key={i}>
                        <div>
                            {DATE.title}
                        </div>
                        <div>
                            {this.countDeliverySum(true)}
                        </div>
                        <div>
                            {START_TIME + '-' + END_TIME}
                        </div>
                    </div>
                );
            }
        }

        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY) {
            if ((this.props.REGION == 'OTHER') && (DAYS.length == 0)) {
                DAYS.push(
                    <div className="delivery_modal_day" key={1}>
                        <div>
                            с {this.getPostalDate()}
                        </div>
                        <div>
                            от {this.props.POSTAL_PRICE} р
                        </div>
                        <div>
                            -
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className={"cost-grid-main-days" + this.getClass()}>
                {DAYS}
            </div>
        );
    }

    getPostalDate = () =>
    {
        if (this.props.POSTAL_DAYS) {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() + this.props.POSTAL_DAYS);
        return newDate.toLocaleDateString();
        }
    }

    getClass = () => {
        if (typeof this.props.CLASS_ADDON !== "undefined") {
            return ' ' + this.props.CLASS_ADDON;
        }
        return '';
    }

    render() {
        
        return (
            <div className="cost-days">
                <div>
                    {this.renderDates()}
                </div>
            </div>

        );
    }
}


export default connect(
    mapStateToProps, mapDispatchToProps
)(CostGrid);