import React, {Component} from 'react';
/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MapBlock from './../../HELPERS/MapHelperShown';
import RegionSelector from "./RegionSelector.js";
import moment from "moment";

import CONFIG from './../../../SYSTEM/Config.js'

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION,
        ADRESS: state.ADRESS,
        SHOWADRESS: state.SHOWADRESS,
        USEABLE_PERIODS: state.USEABLE_PERIODS,
        PolygonsMinutes: state.PolygonsMinutes,
        POSTAL_DAYS: state.POSTAL_DAYS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MobileLocationData extends Component {
    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {};
    }

    showAdress = () => {
        if (this.props.ADRESS.length > 0) {
            return <div className='selected-address'>{this.props.SHOWADRESS}</div>
        }

        return <div className='address-indicate'>указать на карте</div>
    }

    getClosestTime = () => {
        if (this.props.USEABLE_PERIODS) {
            let DATE = Object.keys(this.props.USEABLE_PERIODS)[0];

            if (typeof this.props.USEABLE_PERIODS[DATE] !== "undefined") {
                let TIME = this.props.USEABLE_PERIODS[DATE].times[0];
                let TIME_PARTS = TIME.split('-');
                let SHOW_TIME = TIME_PARTS[0];
                if (this.props.PolygonsMinutes !== '-1') {
                    let deliveryTime = Number(this.props.PolygonsMinutes) + 15
                    SHOW_TIME =  moment().add(Number(this.props.PolygonsMinutes), 'm').format('HH:mm')
                }
                let SHOW_DATE = this.props.USEABLE_PERIODS[DATE].title;
                let STRING = SHOW_DATE.toLowerCase() + ' с ' + SHOW_TIME;
                return STRING;
            }

            return ' подсчитываем...';
        }
    }

    getPostalDate = () =>
    {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() + this.props.POSTAL_DAYS);
        return newDate.toLocaleDateString();
    }

    getClosestDeliveryTime = () => {
        if (this.props.ADRESS) {
            if (!this.props.USEABLE_PERIODS) {
                if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.POSTAL_DAYS) {
                    return (
                        <div>
                            <div className='main-delivery-text'>Ближайшая доставка: {this.getPostalDate()}</div>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <div className='main-delivery-text'>Ближайшая доставка: подсчитываем...</div>
                        </div>
                    );
                }
            } else {
                return (
                    <div>
                        <div className='main-delivery-text'>
                            Ближайшая доставка: <span className="next_del_time"
                                                      onClick={() => this.props.openDeliveryPaymentModal(true)}>{this.getClosestTime()}</span>
                        </div>
                    </div>
                );
            }

        }

        return (
            <div className='address-not-select-text'>Чтобы сказать время ближайшей <br/> доставки, нам надо знать ваш
                адрес
            </div>
        );
    }

    render() {
        if (window.innerWidth >= 1200) {
            return false;
        }
        return (

            <div className="mobile_adress_home_helper">
                <RegionSelector/>
                <div className='delivery-address-side-bar'>
                    <div className="mobile_location_image_holder">
                        <img className='location-image' src='/system_images/LocationMarker.png' alt=""/>
                    </div>
                    <div className='main-delivery-text'>
                        Адрес доставки:
                    </div>
                    <div className='right-side-text'
                         onClick={() => this.props.openDeliveryAddressModal(true)}>{this.showAdress()}</div>
                </div>

                <div className='delivery-time-side-bar'>
                    <div className="mobile_location_image_holder">
                        <img className='time-image' src='/system_images/time_icon.png' alt=""/>
                    </div>
                    {this.getClosestDeliveryTime()}
                    <span className="delivery_info_button" onClick={() => this.props.openDeliveryPaymentModal(true)}>
                        <img src="/system_images/del_info.png"/>
                    </span>
                </div>

            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(MobileLocationData);