import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import MobileLocationData from "../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        DO_SEARCH: state.DO_SEARCH,
        POPULAR: state.POPULAR,
        SELECTED_TAG: state.SELECTED_TAG,
        PRODUCTS: state.PRODUCTS,
        TAGS: state.TAGS

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class TagCatalogue extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            URL: props.url,
            TAG: false,
            ERROR: false,
            PRODUCTS: false
        }

        this.interval = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.url !== prevProps.url) {
            this.props.closeAllModals();

            this.setState({TAG: false, PRODUCTS: false, URL: this.props.url, ERROR: false});
            let COMPONENT = this;
            this.interval = setInterval(function(){
                clearInterval(COMPONENT.interval);
                COMPONENT.startRendering();
            }, 50);
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
    }

    getSelectedTag = () =>
    {
        let TAG = false;
        for (let i = 0; i < this.props.TAGS.length; i++){
            if (this.state.URL == this.props.TAGS[i].url) {
                TAG = this.props.TAGS[i];
            }
        }

        return TAG;
    }


    componentDidMount()
    {
        let COMPONENT = this;
        this.taglistener = setInterval(function(){
            if (COMPONENT.props.TAGS)  {
                clearInterval(COMPONENT.taglistener);
                COMPONENT.startRendering();
            }
        },50);

        this.props.closeAllModals();
    }

    startRendering = () =>
    {
        let TAG = this.getSelectedTag();
        if (TAG) {
            this.setState({TAG: TAG});
            this.getData(TAG);
        } else {
            this.setState({ERROR: true});
        }
    }

    getData = async(TAG) =>
    {
        if (!this.state.PRODUCTS) {
            let PRODUCTS = this.getProducts(TAG);
            this.setState({PRODUCTS: PRODUCTS});
            clearInterval(this.interval);
        }
    }

    getProducts(TAG)
    {

        let USE_PRODUCTS = [];

        let CLASS = 'product_card ' + this.getItemsClass();
        for (let key in this.props.PRODUCTS) {
            if (this.props.PRODUCTS[key].tags.indexOf(TAG.id) >= 0) {
                USE_PRODUCTS.push(this.props.PRODUCTS[key].id);
            }
        }


        if (USE_PRODUCTS) {
            return (
                <div className="row products-row">
                    {USE_PRODUCTS.map(id => <div className={CLASS}  key={id}><LazyLoad height={300}><ProductCard ID={id} /></LazyLoad></div>)}

                    <div className={CLASS}><LazyLoad height={300}>
                        <div className="product-col not_found_col">
                            <div className="relative_image_holder">
                                <div
                                    className="image image_not_found"
                                    onClick={() => this.props.openNotFounModal(TAG.title)}
                                >
                                    <div className="not_found_absolute">
                                        <img src="/system_images/green_search.png" />
                                        <div className="not_found_text">
                                            Не нашли товар, который искали?
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="button-holder"
                            >
                                <a onClick={() => this.props.openNotFounModal(TAG.title)} className="button_not_found">
                                    Предложить
                                </a>
                            </div>
                            <div className="spacer"></div>
                        </div>
                    </LazyLoad></div>
                </div>
            );
        }
    }

    getItemsClass()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 'col-12';
        } else if (WIDTH < 769) {
            return 'col-6';
        } else if (WIDTH < 1050) {
            return 'col-4';
        } else if (WIDTH < 1200) {
            return 'col-3';
        } else if (WIDTH < 9900) {
            return 'el-5';
        }
    }

    renderH1()
    {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        {this.state.TAG.title}
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });
        DATA.push({
            'to' : '/tag_' + this.state.TAG.url,
            'title' : this.state.TAG.title
        });

        return <BreadCrumbs DATA={DATA} />
    }

    render() {
        if ((!this.props.TAGS) && (!this.state.PRODUCTS)) {
            return <InnerLoader />
        }

        if ((this.state.ERROR) && (!this.state.TAG)) {
            return <E404 />;
        }

        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            >
                <div className="right-part">
                    <Helmet>
                        <title>{this.state.TAG.title}</title>
                        <meta name="description" content={this.state.TAG.title} />
                        <meta name="keywords" content={this.state.TAG.title} />
                    </Helmet>

                    {this.renderBreadCrumbs()}
                    <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid">
                            {this.renderH1()}
                            {this.state.PRODUCTS}
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
                <FooterBlock />
                <MobileMessage />
            </CSSTransitionGroup>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TagCatalogue))