import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import MapHelper from "./../../../APP/HELPERS/MapHelper"
import CardForm from './../../../APP/COMPONENTS/ContentParts/CardForm';

function mapStateToProps(state) {
    return {
        REGION: state.REGION,
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL,
        ADRESS: state.ADRESS,
        SHOWADRESS: state.SHOWADRESS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Personal extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);

        let ADRESS_CONFIRMED = false;
        if (props.USER.ADRESS.length > 4) {
            ADRESS_CONFIRMED = true;
        }

        this.state = {
            REGION: props.REGION,
            USER: props.USER,
            PAYTYPE: props.USER.paytype,
            PHONE: props.USER.PHONE,
            NAME: props.USER.NAME,
            EMAIL: props.USER.EMAIL,
            ENTRANCE: props.USER.ENTRANCE,
            APPARTMENT: props.USER.APPARTMENT,
            FLOOR: props.USER.FLOOR,
            ADRESS: props.USER.ADRESS,
            SUGGESTIONS: false,
            NEW_PASSWORD: false,
            NEW_PASSWORD2: false,
            OLD_PASSWORD: false,
            DEL_COMMENT: props.USER.DEL_COMMENT,
            CARDS: false,
            ADRESS_CONFIRMED: ADRESS_CONFIRMED,
            TEMP_ADRESS: props.USER.ADRESS,
            ADRESS_ERROR: false,
            PHONE_ERROR: true,
            ADD_CARD: false,
            SAVED: false
        };

        this.setMapHelper = this.setMapHelper.bind(this);
        this.setSugRef = this.setSugRef.bind(this);
        this.setAddRef = this.setAddRef.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    mapLoaded = (data) =>
    {
    }

    setUser(props)
    {
        this.setState({
            USER: props.USER,
            PAYTYPE: props.USER.paytype,
            PHONE: props.USER.PHONE,
            NAME: props.USER.NAME,
            EMAIL: props.USER.EMAIL,
            ENTRANCE: props.USER.ENTRANCE,
            APPARTMENT: props.USER.APPARTMENT,
            FLOOR: props.USER.FLOOR,
            ADRESS: props.USER.ADRESS,
            DEL_COMMENT: props.USER.DEL_COMMENT,
            TEMP_ADRESS: props.USER.ADRESS
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.CARD_MODAL !== prevProps.CARD_MODAL) {
            this.getPaymentCards();
        }

        if (this.props.USER !== prevProps.USER) {
            this.setUser(this.props);
        }

        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    componentDidMount()
    {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.validatePhone(this.state.PHONE);
        this.getPaymentCards();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setSugRef(node) {
        this.sug = node;
    }

    setAddRef(node) {
        this.ac = node;
    }

    handleClickOutside(event) {
        if (this.sug && !this.sug.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
        if (this.ac && !this.ac.contains(event.target)) {
            if (this.state.ADD_CARD) {
                this.setState({ADD_CARD: false});
            }
        }
    }

    validatePhone = (phone) =>
    {
        let phoneNumber = phone.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
            this.setState({PHONE_ERROR: false});
        } else {
            this.setState({PHONE_ERROR: true});
        }
    }

    setMapHelper = (node) =>
    {
        this.mapHelper = node;
    }

    removeCard = async (ID) =>
    {
        try {
            let SEND_DATA = {
                action:     'removeCard',
                card:       ID,
                apikey:     this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.getPaymentCards();
            }
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }

    getPaymentCards = async () =>
    {
        try {
            let SEND_DATA = {
                action:    'paymentCards',
                apikey:    this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({CARDS: data.cards});
            }
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }

    saveData = async () =>
    {
        if (this.state.ADRESS_CONFIRMED) {
            let SEND_DATA = {
                action:         'doSaveUserData',
                phone:          this.state.PHONE,
                name:           this.state.NAME,
                adress:         this.props.ADRESS,
                floor:          this.state.FLOOR,
                entrance:       this.state.ENTRANCE,
                del_comment:    this.state.DEL_COMMENT,
                appartment:     this.state.APPARTMENT,
                email:          this.state.EMAIL,
                new_password:   this.state.NEW_PASSWORD,
                new_password2:  this.state.NEW_PASSWORD2,
                old_password:   this.state.OLD_PASSWORD,
                paytype:        this.state.PAYTYPE,
                apikey:         this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.props.doAuth(data.user);
                this.setState({SAVED: true});
            }
        }
    }

    getCardsSource(type)
    {
        switch (type) {
            case 'Visa':
                return CONFIG.API_C_DOMAIN + '/imgs/banks/visa.png';
                break;
            case 'Maestro':
                return CONFIG.API_C_DOMAIN + '/imgs/banks/maestro.png';
                break;
            case 'Mir':
                return CONFIG.API_C_DOMAIN + 'imgs/banks/mir.png';
                break;
            case 'Mastercard':
                return CONFIG.API_C_DOMAIN + 'imgs/banks/mastercard.png';
                break;
            default:
                return '/system_images/green_card.png';
                break;
        }
    }

    addCard()
    {
        this.props.addCardModal('USER');
    }

    renderFavCheck(FAV)
    {
        if (FAV == 1) {
            return (
                <div className="card_check">
                    <img src="/system_images/green_check.png" />
                </div>
            );
        }
    }

    selectFav = async (id) =>
    {
        try {
            let SEND_DATA = {
                action:     'setFavCard',
                apikey:     this.props.USER.TOKEN,
                id:         id
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({CARDS: data.cards});
            }
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }


    recordPhone = (text) => 
    {
        this.setState({PHONE: text.target.value,SAVED: false});
        this.validatePhone(text.target.value);
    }

    recordName = (text) => 
    {
        this.setState({NAME: text.target.value,SAVED: false});
    }

    recordEmail = (text) => 
    {
        this.setState({EMAIL: text.target.value,SAVED: false});
    }

    recordAdress = (text) => 
    {

        this.setState({TEMP_ADRESS: text.target.value, ADRESS_CONFIRMED: false,SAVED: false});
        this.mapHelper.getSuggestions(text.target.value);
    }

    setSuggestions = (suggestions) =>
    {
        if (suggestions.length) {
            this.renderSuggestions(suggestions);
        } else {
            this.setState({SUGGESTIONS: false, ADRESS_ERROR: true, ADRESS_CONFIRMED: false,SAVED: false});
        }
    }

    setCheckedAdress = (result, adress) =>
    {
        if (result) {
            this.setState({TEMP_ADRESS: adress, ADRESS: adress, ADRESS_ERROR: false, ADRESS_CONFIRMED: true,SAVED: false});
        } else {
            this.adressInput.focus();
            this.setState({TEMP_ADRESS: adress, ADRESS: adress, ADRESS_CONFIRMED: false,SAVED: false});
            this.mapHelper.getSuggestions(adress);
        }
    }

    selectAdress = (adress) =>
    {
        this.setState({SUGGESTIONS: false, TEMP_ADRESS: adress, ADRESS: adress, ADRESS_ERROR: true,SAVED: false});
        this.mapHelper.checkAdress(adress);
    }

    renderSuggestions(suggestions)
    {
        let RESULTS = [];

        for (let i = 0; i < suggestions.length; i++) {
            RESULTS.push(
                <a 
                    key={i}
                    onClick={() => this.selectAdress(suggestions[i].TITLE)}
                >
                    {suggestions[i].TITLE}
                </a>
            )
        }

        let SUGGESTIONS = (
            <div className="suggestions_block" ref={this.setSugRef}>
                {RESULTS}
            </div>
        );

        this.setState({SUGGESTIONS: SUGGESTIONS, ADRESS_ERROR: false, ADRESS_CONFIRMED: false});
    }

    recordEntrance = (text) => 
    {
        this.setState({ENTRANCE: text.target.value,SAVED: false});
    }

    recordAppartment = (text) => 
    {
        this.setState({APPARTMENT: text.target.value,SAVED: false});
    }

    recordFloor = (text) => 
    {
        this.setState({FLOOR: text.target.value,SAVED: false});
    }

    recordOld = (text) => 
    {
        this.setState({OLD_PASSWORD: text.target.value,SAVED: false});
    }

    recordNew = (text) => 
    {
        this.setState({NEW_PASSWORD: text.target.value,SAVED: false});
    }

    recordNew2 = (text) => 
    {
        this.setState({NEW_PASSWORD2: text.target.value,SAVED: false});
    }

    renderAvatar(USER)
    {
        let SOURCE = '/system_images/no_user_pic.png';

        if (USER.IMAGE !== "") {
            SOURCE = CONFIG.API_C_DOMAIN + USER.IMAGE;
        }

        return SOURCE;
    }

    avatarChosen = async (e) =>
    {
        let file = e.target.files[0];

        if (typeof file !== "undefined") {
            if (file !== null) {
                let SEND_DATA = new FormData();

                SEND_DATA.append('action', 'uploadAvatar');
                SEND_DATA.append('apikey', this.props.USER.TOKEN);
                SEND_DATA.append('PICTURE', file);

                fetch( 
                    CONFIG.API_GATE, 
                    {
                        credentials: 'include',
                        method: "POST",
                        body: SEND_DATA
                    }
                )
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status.result == 'SUCCESS') {
                        this.props.updateAvatar(responseJson.data.image);
                        let NEW_AVA_USER = Object.assign({}, this.state.USER);
                        NEW_AVA_USER.IMAGE = responseJson.data.image;
                        this.setState({USER: NEW_AVA_USER});
                    }
                })
                .catch(err => {
                    console.log(err)
                    //alert(JSON.stringify(err))
                })
            }
        }
    }

    renderPersonalDataBlock()
    {
        let IMAGE = this.renderAvatar(this.state.USER);

        return (
            <div className="lk-editable-block">

                <div className="lk_edit_head">
                    <div>
                        <img src="/system_images/lk_user.png" />
                    </div>
                    <div>
                        Персональная информация
                    </div>
                </div>

                <div>
                    <label>
                        <div className="user-avatar pointer" style={{'background': 'transparent url(' + IMAGE + ')'}} >
                            <div className="editable-pencil"><img src="/system_images/green_pencil.png" /></div>
                        </div>
                        <input style={{'display':'none'}} type="file" onChange={(e)=>this.avatarChosen(e)} />
                    </label>
                </div>

                <div className="personal-form-row">
                    <label>Имя</label>
                    <div>
                        <input type="text" name="name" placeholder="Ваше имя" value={this.state.NAME}  onChange={(text) => this.recordName(text)} />
                    </div>
                </div>

                <div className="personal-form-row">
                    <label>Почта</label>
                    <div>
                        <input className="email requiredinput" type="email" name="email" placeholder="Ваш email" value={this.state.EMAIL} onChange={(text) => this.recordEmail(text)} />
                    </div>
                </div>

                <div className="personal-form-row">
                    <label>Телефон</label>
                    <div>
                        <InputMask placeholder="Ваш телефон" type="tel" className="phone" id="share_phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} value={this.state.PHONE} />
                    </div>
                </div>

            </div>
        );
    }

    selectRegion(id)
    {
        if (this.state.REGION == id) {
            return true;
        } else {
            return false;
        }
    }

    renderRegionCheckboxes()
    {
        return (
            <div className="flex_checks">
                <input type="radio" name="region_type" id="region_77" value="77" checked={this.selectRegion(77)} onChange={() => this.props.changeRegion(77)} />
                <label htmlFor="region_77">Москва и МО</label>
                <input type="radio" name="region_type" id="region_78" value="78" checked={this.selectRegion(78)} onChange={() => this.props.changeRegion(78)} />
                <label htmlFor="region_78">Санкт-Петербург и ЛО</label>
                <input type="radio" name="region_type" id="region_16" value="16" checked={this.selectRegion(16)} onChange={() => this.props.changeRegion(16)} />
                <label htmlFor="region_16">Казань и Республика Татарстан</label>
                <input type="radio" name="region_type" id="region_52" value="52" checked={this.selectRegion(52)} onChange={() => this.props.changeRegion(52)} />
                <label htmlFor="region_52">Нижний Новгород и Нижегородская область</label>
            </div>
        );
    }

    openModalmap = () => {
        if(window.innerWidth < 1200){
            this.props.openDeliveryAddressModal(true)
        }
        else{
            this.props.openDeliveryAddressMixedModal('adress')
        }
    }

    showAdress = () =>
    {
        if (this.props.ADRESS.length > 0) {
            return this.props.SHOWADRESS;
        }

        return <div className = 'address-indicate'>указать на карте</div>
    }

    renderDeliveryDataBlock()
    {
        let CLASS = 'input';
        if (this.state.ADRESS_ERROR) {
            CLASS = 'error_input';
        }

        return (
            <div className="lk-editable-block">
                <div className="lk_edit_head">
                    <div>
                        <img src="/system_images/lk_adress.png" />
                    </div>
                    <div>
                        Адрес доставки
                    </div>
                </div>

                <div className="personal-form-row">
                    <label>Адрес</label>
                    <span className="order_form_adress_changer" onClick={() => {this.openModalmap()}}>
                        {this.showAdress()}<img src="/system_images/grey_pencil.png" />
                    </span>
                </div>

                <div className="personal-form-row">
                    <label>Квартира</label>
                    <div className="input_relative">
                        <input type="text" name="appartment" placeholder="Кв/оф" value={this.state.APPARTMENT}  onChange={(text) => this.recordAppartment(text)} />
                    </div>
                </div>

                <div className="personal-form-row">
                    <label>Подъезд</label>
                    <div className="input_relative">
                        <input type="text" name="entrance" placeholder="Подъезд" value={this.state.ENTRANCE}  onChange={(text) => this.recordEntrance(text)} />
                    </div>
                </div>

                <div className="personal-form-row">
                    <label>Этаж</label>
                    <div className="input_relative">
                        <input type="text" name="floor" placeholder="Этаж" value={this.state.FLOOR}  onChange={(text) => this.recordFloor(text)} />
                    </div>
                </div>

            </div>
        );
    }

    renderRemoveCardButton(id)
    {
        if (this.state.CARDS_EDITING) {
            return (
                <a onClick={() => this.removeCard(id)} className="remove_card_btn">
                    <img src="/system_images/red_bin.png" />
                </a>
            );
        }
    }

    renderCards()
    {
        if (this.state.CARDS) {
            let RENDERED = [];

            for (let i = 0; i < this.state.CARDS.length; i++) {
                let CARD = this.state.CARDS[i];
                let TITLE = CARD.FIRST + '*****' + CARD.LAST;
                let IMAGE = this.getCardsSource(CARD.TYPE);

                RENDERED.push(
                    <div className="relative" key={i} >
                        <a className="user_card" onClick={() => this.selectFav(CARD.ID)}>
                            <img src="/system_images/green_card.png" />
                            <span>
                                {CARD.FIRST} ●●●● {CARD.LAST}
                            </span>
                            {this.renderFavCheck(CARD.FAV)}
                        </a>
                        {this.renderRemoveCardButton(CARD.ID)}
                    </div>
                );
            }

            return (
                <div>
                    {RENDERED}
                </div>
            );
        }
    }

    removeCard = async (ID) => 
    {
        try {
            let SEND_DATA = {
                action:     'removeCard',
                card:       ID,
                apikey:     this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            this.getPaymentCards();
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }

    renderEditCardsButton()
    {

        if (this.state.CARDS) {
            if (this.state.CARDS.length) {
                if (this.state.CARDS_EDITING) {
                    return (
                        <div className="cards_edit_button">
                            <a onClick={() => this.setState({CARDS_EDITING: false})}>
                                Готово
                            </a>
                        </div>
                    );
                } else {
                    return (
                        <div className="cards_edit_button">
                            <a onClick={() => this.setState({CARDS_EDITING: true})}>
                                Изменить
                            </a>
                        </div>
                    );
                }
            }          
        }
    }

    selectPayType(id)
    {
        if (this.state.PAYTYPE == id) {
            return true;
        } else {
            return false;
        }
    }

    renderPayTypeCheckboxes()
    {
        return (
            <div className="flex_checks">
                <input type="radio" name="pay_type" id="pay_1" value="1" checked={this.selectPayType(1)} onChange={() => this.setState({PAYTYPE: 1})} />
                <label htmlFor="pay_1">Оплата наличными курьеру</label>
                <input type="radio" name="pay_type" id="pay_3" value="3" checked={this.selectPayType(3)} onChange={() => this.setState({PAYTYPE: 3})} />
                <label htmlFor="pay_3">Оплата банковской картой</label>
            </div>
        );
    }

    renderPaymentDataBlock()
    {
        return (
            <div className="lk-editable-block">
                <div className="lk_edit_head">
                    <div>
                        <img src="/system_images/lk_payment.png" />
                    </div>
                    <div>
                        Способы оплаты
                    </div>
                </div>

                {this.renderPayTypeCheckboxes()}

                <div className="cards_place">
                    {this.renderEditCardsButton()}

                    {this.renderCards()}

                    <div className="add_card_holder">
                        <a onClick={() => this.setState({ADD_CARD: true})}>
                            <span>+</span>Добавить новую карту
                        </a>
                    </div>
                </div>

                {this.renderAddCardForm()}
            </div>
        );
    }

    renderAddCardHead()
    {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({ADD_CARD: false})}>
                        <img src={'/system_images/angle_left.png'} />
                    </a>
                    <h4>Добавление карты</h4>
                </div>
            );
        } else {
            return (
                <div>
                    <a className="close-modal" onClick={() => this.setState({ADD_CARD: false})}>
                        <img src={'/system_images/close.png'} />
                    </a>

                    <div className="how_bonuses_head">Добавление карты</div>
                </div>
            );
        }
    }

    renderAddCardForm()
    {
        if (this.state.ADD_CARD) {
            let CLASSES = 'form-pop-append card_add_pop';
            if (WIDTH <= 760) {
                CLASSES = 'form-pop-append card_add_pop fixed-full-screen';
            }

            return (
                <div className={CLASSES} ref={this.setAddRef}>
                    {this.renderAddCardHead()}
                    

                    <CardForm cardAdded={this.cardAdded} />
                </div>
            );
        }
    }

    renderSecurityDataBlock()
    {
        return (
            <div className="lk-editable-block security_one">
                <div className="lk_edit_head">
                    <div>
                        <img src="/system_images/lk_key.png" />
                    </div>
                    <div>
                        Смена пароля
                    </div>
                </div>

                <div className="personal-form-row">
                    <label>Старый пароль</label>
                    <div className="input_relative">
                        <input type="password" name="old_password" placeholder="Старый пароль"  onChange={(text) => this.recordOld(text)} />
                    </div>
                </div>

                <div className="personal-form-row">
                    <label>Новый пароль</label>
                    <div className="input_relative">
                        <input type="password" name="new_password" placeholder="Новый пароль"  onChange={(text) => this.recordNew(text)} />
                    </div>
                </div>

                <div className="personal-form-row">
                    <label>Повторите пароль</label>
                    <div className="input_relative">
                        <input type="password" name="new_password2" placeholder="Повторите пароль"  onChange={(text) => this.recordNew2(text)} />
                    </div>
                </div>
            </div>
        );
    }

    cardAdded = () =>
    {
        this.setState({ADD_CARD: false});
        this.getPaymentCards();
    }

    passwordsValidated()
    {
        if ((this.state.NEW_PASSWORD) || (this.state.NEW_PASSWORD2)) {
            if ((this.state.NEW_PASSWORD.length > 4) && (this.state.NEW_PASSWORD2.length > 4)) {
                if (this.state.NEW_PASSWORD == this.state.NEW_PASSWORD2) {
                    return true;
                }
            }

            return false;
        }

        return true;
    }

    renderSaveButton()
    {
        if ((this.state.ADRESS_CONFIRMED) && (!this.state.PHONE_ERROR) && this.passwordsValidated()) {

            if (this.state.SAVED) {
                return <button className="orange-button" onClick={() => this.saveData()}>Изменения сохранены</button>;
            } else {
                return <button className="orange-button" onClick={() => this.saveData()}>Сохранить изменения</button>;
            }

            
        } else {
            return <button className="orange-button inactive-btn">Сохранить изменения</button>
        }
    }

    render()
    {
        return (
            <div id="personal" className="inside active">

                {this.renderPersonalDataBlock()}

                {this.renderDeliveryDataBlock()}

                {this.renderPaymentDataBlock()}

                {this.renderSecurityDataBlock()}

                <div className="unform text-center personal_block">
                    {this.renderSaveButton()}
                </div>

                <a className="logout_button" onClick={() => this.props.logOut()}>Выход из аккаунта</a>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Personal);