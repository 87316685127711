import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class UserBlock extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        }
    }

    enterLK = () => {}

    renderNameOrMessage = () =>
    {
    	if (this.props.USER) {
    		return (
                    <span>
                        {this.props.USER.NAME}
                    </span>
            );
    	} else {
    		return 'Войти';
    	}
    }

    renderFirstLine()
    {
    	return (
	    	<a 
	    		className="auth" 
	    		onClick={() => this.props.openLK(true)}
                style={{display: 'flex', alignItems: 'center'}}
	    	>
                {this.renderSubLine()}
	    		{this.renderNameOrMessage()}
		    </a>
	    );
    }

    num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderSubLine = () =>
    {
    	if (this.props.USER) {
    		return (
				<div style={{marginRight: 8}}>
					<span className="header_bonuses_absolute">{this.props.USER.BONUSES}</span>
                    <div className="i-flocktory" data-fl-user-name={this.props.USER.NAME} data-fl-user-email={this.props.USER.PHONE.replace(/\D/g, "") + '@unknown.email'}></div>
				</div>
			);
    		 
		}
    }

    renderBlogerBadge = () =>
    {
        if (this.props.USER) {
            if ((parseInt(this.props.USER.STATUS) == 4) || (parseInt(this.props.USER.STATUS) == 6)) {
                return <img src="/system_images/blogger/bloger.png" className="bloger_badge" />
            }
        }
    }

	render() {
    	return (
    		<div style={{marginLeft: 20, paddingRight: 50}} className="head_user">
                <a className="head_user_two" onClick={() => this.props.logOut(true)} >
                    Выйти
                </a>
    		</div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserBlock)
