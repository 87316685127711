import React, {Component} from 'react';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import AddressBlock from './DeliveryInformation/AddressBlock.js';
import CostWithImage from "./DeliveryInformation/CostWithImage.js";
import CostGrid from "./DeliveryInformation/CostGrid.js"
import DeliveryRules from "./DeliveryInformation/DeliveryRules.js"

function mapStateToProps(state) {
    return {
        DELIVERY_PAYMENT_MODAL: state.DELIVERY_PAYMENT_MODAL,
        ADRESS: state.ADRESS,
        SHOWADRESS: state.SHOWADRESS,
        PRODUCTS: state.PRODUCTS,
        CART: state.CART
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DeliveryInformation extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.DELIVERY_PAYMENT_MODAL !== prevProps.DELIVERY_PAYMENT_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.DELIVERY_PAYMENT_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    // showAdress = () =>
    // {
    //     if (this.props.ADRESS) {
    //         return this.props.ADRESS;
    //     }
    //
    //     return 'Адрес';
    // }

    

    render() {
        if (window.innerWidth >= 1200) {
            return false;
        }

        if (this.props.DELIVERY_PAYMENT_MODAL) {
            return (
                <div>
                    <div className='modal-overlay' onClick={() => this.props.openDeliveryPaymentModal(false)} />
                    <div className="modal-box">
                        <div className="modal_content mapped">
                            <div className="modal_payment_data">
                                <div className='address-time-buttons-area'>
                                    <img className='map-back-button-size' src='/system_images/MapBackButton.png' onClick={() => this.props.openDeliveryPaymentModal(false)}></img>
                                    {/*<div className='address-time-buttons' onClick={() => {this.props.openDeliveryPaymentModal(false);this.props.openDeliveryAddressModal(true)}}>Адрес</div>*/}
                                    {/*<div className='address-time-buttons active' onClick={() => {this.props.openDeliveryPaymentModal(false);}}>Время и стоимость</div>*/}
                                </div>
                                <div className='payment-information'>
                                    <AddressBlock/>
                                </div>
                                <div className="del_modal_line"></div>
                                <div className='delivery-cost'>
                                    <CostWithImage/>
                                    <CostGrid/>
                                </div>
                                <div className="del_modal_line"></div>
                                <div className='delivery-information'>
                                    <DeliveryRules/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DeliveryInformation);