import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class MobileNavToggle extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        }
    }

	render() {
		return (
			<div className="m-nav-block">
				<a className="nav-opener">
					<span></span>
					<span></span>
					<span></span>
				</a>
			</div>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(MobileNavToggle)
