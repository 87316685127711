import React, { Component } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import Tree from 'rc-tree';
import "rc-tree/assets/index.css"

import SelectSearch, { fuzzySearch } from 'react-select-search';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CommentForm from './../../../APP/COMPONENTS/ContentParts/CommentForm.js'
import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs.js'
import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview.js'

import TheLine from './../../../APP/COMPONENTS/ContentParts/Line.js'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Slider from "react-slick";
import { store } from "../../../SYSTEM/Router";
import { logOut } from "./../../../REDUX/functions.js";

import ToggleButton from "./../../../APP/COMPONENTS/ContentParts/ToggleButton";

function mapStateToProps(state, myProps) {

    let AMOUNT = 0;
    let id = parseInt(myProps.product.id);
    if (typeof state.CART[id] !== "undefined") {
        AMOUNT = state.CART[id].amount;
    }

    let AMOUNT_ALTERNATIVE = 0;
    let product = state.PRODUCTS[id]
    if (product) {
        let idAlternative = parseInt(product?.opt.id);
        if (typeof state.CART[idAlternative] !== "undefined") {
            AMOUNT_ALTERNATIVE = state.CART[idAlternative].amount;
        }
    }

    return {
        AMOUNT,
        AMOUNT_ALTERNATIVE,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        POPULAR: state.POPULAR,
        REGION: state.REGION,
        FAVS: state.FAVS,
        USEABLE_PERIODS: state.USEABLE_PERIODS,
        USER: state.USER,
        QUEST_PRODUCTS: state.QUEST_PRODUCTS,
        PRODUCTS: state.PRODUCTS,
        TOKEN: state.TOKEN,
        CATS: state.CATS,
        CART: state.CART,
        urlToProductModal: state.urlToProductModal,
        isFromBasketModal: state.isFromBasketModal,
        CART_MODAL: state.CART_MODAL,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class InnerProduct extends Component {

    constructor(props) {
        super(props);

        let MODAL = false;
        if (typeof props.MODAL !== "undefined") {
            MODAL = props.MODAL;
        }

        this.state = {
            /*AMOUNT: false,*/
            ID: props.product.id,
            PRODUCT: props.product,
            MANIPULATE: false,
            VIEW: 'TEXT',
            HOVER: false,
            REGION: props.REGION,
            MODAL: MODAL,
            alternativeProd: {},
            wholesale: false,

            TITLE: props.product.title,
            SHORT_DESCRIPTION: props.product.short_description,
            WEIGHT: props.product.weight,
            PERAMOUNT: props.product.peramount,
            PRICE: props.product.price,
            STORAGE: props.product.storage,
            SOSTAV: props.product.sostav,
            ALL_GROUPS: props.product.all_groups,

            SM_PRICE: props.product.sm_price,
            HALAL_PRICE: props.product.halal_price,

            calories_amount: props.product.calories_amount,
            calories_percent: props.product.calories_percent,
            fats_amount: props.product.fats_amount,
            fats_percent: props.product.fats_percent,
            ugl_amount: props.product.ugl_amount,
            ugl_percent: props.product.ugl_percent,
            bel_amount: props.product.bel_amount,
            bel_percent: props.product.bel_percent,
            klet_amount: props.product.klet_amount,
            klet_percent: props.product.klet_percent,
            autoorder: props.product.autoorder,
            sugar: props.product.sugar,
            SHOW_REL_SELECT: false,

            show_sm: props.product.show_sm == 1 ? true : false,
            show_hf: props.product.show_hf == 1 ? true : false,

            IMPORTANT: parseInt(props.product.important) == 1 ? true : false,

            STATS: false,
            SHOW_STATS: false,
            related: props.product.related
        }

        this.myRef = React.createRef();
    }

    forceUpdateHandler() {
        this.forceUpdate();
    };

    componentWillMount() {
        ///this.fetchAllData(this.getCurrentProduct().id);
    }

    getBuyStats = () => {
        if (!this.state.STATS) {
            this.fetchStats();
        }

        this.setState({ SHOW_STATS: true })
    }

    toggleShop = (value, type) => {
        this.setState({ [type]: value }, () => this.changeShops())
    }

    changeShops = async () => {
        try {
            let SEND_DATA = {
                action: 'admin_setShops',
                id: this.state.ID,
                apikey: this.props.USER.TOKEN,
                show_sm: this.state.show_sm,
                show_hf: this.state.show_hf
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
        } catch (error) {
            this.setState({ STATS: [] });
        }
    }

    renderBadges1() {
        let LEFT_ONES = [];
        const width = this.wrapperRef?.clientWidth
        if (this.getCurrentProduct().ro == 1) {

            let BADGE = (
                <div style={ { height: this.state.cardWidth > 195 ? 18 : 16 } } className="cardBadge2" key="0">
                    <img src={ CONFIG.API_C_DOMAIN + '/imgs/icos/organicRu.svg' } />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (this.getCurrentProduct().weekly == 1) {
            let BADGE = (
                <div style={ { height: this.state.cardWidth > 195 ? 18 : 16 } } className="cardBadge2" key="1">
                    <img src={ CONFIG.API_C_DOMAIN + '/imgs/icos/topWeekend.svg' } />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        /*if (this.getCurrentProduct().top == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge2" key="2">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/top.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }*/
        if (this.getCurrentProduct().eco == 1) {
            let BADGE = (
                <div style={ { height: this.state.cardWidth > 195 ? 18 : 16 } } className="cardBadge2" key="3">
                    <img src={ CONFIG.API_C_DOMAIN + '/imgs/icos/organicEu.svg' } />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        /* if (this.getCurrentProduct().knife == 1) {
             let BADGE = (
                 <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge2" key="4">
                     <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/knife.svg'}/>
                 </div>
             );
             LEFT_ONES.push(BADGE);
         }*/
        if (this.getCurrentProduct().freeze == 1) {
            let BADGE = (
                <div style={ { height: this.state.cardWidth > 195 ? 18 : 16 } } className="cardBadge2" key="5">
                    <img src={ CONFIG.API_C_DOMAIN + '/imgs/icos/freeze.svg' } />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        /*if (this.getCurrentProduct().no_lactose == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge2" key="6">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/noLactose.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (this.getCurrentProduct().veganHundred == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge2" key="7">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/veganHundred.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (this.getCurrentProduct().furnace == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge2" key="8">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/furnace.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }*/
        if (this.getCurrentProduct().halal == 1) {
            let BADGE = (
                <div style={ { height: this.state.cardWidth > 195 ? 18 : 16 } } className="cardBadge2" key="9">
                    <img src={ CONFIG.API_C_DOMAIN + '/imgs/icos/halal.svg' } />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        /*if (this.getCurrentProduct().vegan == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge" key="10">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/vegan.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (this.getCurrentProduct().no_sugar == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge" key="11">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/noSugar.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (this.getCurrentProduct().no_gluten == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge" key="12">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/noGluten.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (this.getCurrentProduct().fresh == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge" key="13">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/fresh.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (this.getCurrentProduct().new_year == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge" key="14">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/new_year.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (this.getCurrentProduct().living_fish == 1) {
            let BADGE = (
                <div style={{height: this.state.cardWidth > 195 ? 25 : 18}} className="cardBadge" key="15">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/living_fish.svg'}/>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }*/
        if (this.getCurrentProduct().living_crab == 1) {
            let BADGE = (
                <div style={ { height: this.state.cardWidth > 195 ? 18 : 16 } } className="cardBadge2" key="16">
                    <img src={ CONFIG.API_C_DOMAIN + '/imgs/icos/living_crab.svg' } />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }
        if (LEFT_ONES.length) {
            return (
                <div className="badgesCard2">
                    { LEFT_ONES.map(badge => {
                        return badge
                    }) }
                </div>
            );
        } else {
            return false;
        }
    }

    fetchStats = async () => {
        try {
            let SEND_DATA = {
                action: 'admin_getProductStats',
                id: this.state.ID,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.setState({ STATS: data });
            } else {
                this.setState({ STATS: [] });
            }
        } catch (error) {
            this.setState({ STATS: [] });
        }
    }

    prepareProductOptions = () => {
        let options = [{ value: 0, name: '1', key: 'def' }];
        let added = []

        for (let i in this.props.PRODUCTS) {
            if (parseInt(this.props.PRODUCTS[i].topack) == 0 && parseInt(this.props.PRODUCTS[i].archive) == 0) {
                let option = {
                    key: this.props.PRODUCTS[i].id,
                    name: this.props.PRODUCTS[i].title + ' |' + this.props.PRODUCTS[i].id,
                    value: this.props.PRODUCTS[i].id,
                };
                if (!added.includes(this.props.PRODUCTS[i].id)) {
                    options.push(option)
                    added.push(this.props.PRODUCTS[i].id);
                }
            }
        }

        this.setState({ PRODUCT_OPTIONS: options })
    }

    async componentDidMount() {

        this.prepareProductOptions();

        const mainProdId = parseInt(this.state.PRODUCT.id)
        const prodFromProducts = this.props.PRODUCTS[mainProdId]
        const alternativeId = parseInt(prodFromProducts?.opt.id)
        if (alternativeId !== 0)
            await this.getWebProduct(alternativeId)

        let urlArray = this.props.urlToProductModal.split('/')
        let item_list_id
        let url
        if (this.props.urlToProductModal !== 'modalCard') {
            if (this.props.urlToProductModal.indexOf('search') !== -1) {
                url = 'search'
                item_list_id = '7'
            } else if (this.props.urlToProductModal.indexOf('popular') !== -1) {
                url = 'popular'
                item_list_id = '6'
            } else if (this.props.urlToProductModal.indexOf('myproducts') !== -1) {
                url = 'myproducts'
                item_list_id = '5'
            } else if (urlArray[urlArray.length - 1] === '') {
                url = 'main'
                item_list_id = '4'
            } else if (urlArray[urlArray.length - 1] !== '') {
                url = 'catalog'
                item_list_id = '3'
            }
        }

        if (this.props.CART_MODAL) {
            url = 'cart'
            item_list_id = '1'
        }

        const getCategory = (id) => {
            let CAT = null

            store.getState().CATS.forEach(cat => {
                if (id) {
                    if (cat.id == id) {
                        CAT = cat
                    } else {
                        cat.children.forEach(subcat => {
                            if (subcat.id == id)
                                CAT = subcat
                        })
                    }
                } else CAT = null
            })

            return CAT
        }


    }

    getWebProduct = async (ID) => {
        try {
            let SEND_DATA = {
                action: 'admin_getProductById',
                id: ID,
                region: this.props.REGION,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                let PRODUCT = data;
                PRODUCT.CAT = this.getParentCat(PRODUCT.group);

                this.setState({
                    related: PRODUCT.related,
                    alternativeProd: {
                        PRODUCT, ERROR: false, ID
                    }
                });
            } else {
                this.setState({ alternativeProd: {} });
            }
        } catch (error) {
            this.setState({ alternativeProd: {} });
        }
    }

    getParentCat(id) {
        id = parseInt(id);
        let CAT = false;
        for (let i = 0; i < this.props.CATS.length; i++) {
            if (!CAT) {

                let PARENT_CAT = this.props.CATS[i];
                if (parseInt(PARENT_CAT.id) === id) {
                    CAT = PARENT_CAT;
                }

                if (!CAT) {
                    if (PARENT_CAT.children.length) {
                        for (let j = 0; j < PARENT_CAT.children.length; j++) {
                            let CHILD_CAT = PARENT_CAT.children[j];
                            if (parseInt(CHILD_CAT.id) === id) {
                                CAT = CHILD_CAT;
                            }
                        }
                    }
                }

            }
        }

        return CAT;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.product.id !== this.state.ID) {
            /*this.props.push(this.getCurrentProduct().url)*/

            this.state.PRODUCT = this.props.product;
            this.setState({ ID: this.props.product.id, PRODUCT: this.props.product, VIEW: 'TEXT' });
            ////this.fetchAllData(this.props.product.id);
            if (this.state.MODAL) {
                this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            this.resetRetail();

        }

        if (this.props.REGION !== prevProps.REGION) {
            this.setState({ REGION: this.props.REGION });
        }
    }

    resetRetail() {
        let parents = document.getElementsByClassName('product-card');
        for (let i = 0; i < parents.length; i++) {
            let divs = parents[i].querySelectorAll('[data-retailrocket-markup-block]');
            for (let j = 0; j < divs.length; j++) {
                if (divs[j].classList.contains('launched')) {

                    divs[j].classList.remove("launched");
                    if (typeof divs[j].getAttribute("initialized") !== "undefined") {
                        divs[j].removeAttribute("initialized");
                    }
                }
            }
        }
    }

    cancelClose = async () => {
        try {
            let SEND_DATA = {
                action: 'admin_cancelClose',
                id: this.state.PRODUCT.id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                alert('Товар убран из списка к зжакрытию')
            }
        } catch (error) {
        }
    }

    renderLeftBadgesNew() {
        let LEFT_ONES = [];

        if (parseInt(this.state.PRODUCT.archive) == 1) {
            let BADGE = (
                <div className="cardBadge archived" key="-1">
                    Закрыт
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.topack == 1) {
            let BADGE = (
                <div className="cardBadge topack_card" key="0">
                    Фасовочный
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.toclose == 1) {
            let BADGE = (
                <div className="cardBadge toclose_card" key="0">
                    К закрытию <a className="cancel_close" onClick={ () => this.cancelClose() }><img src="/system_images/close.png" /></a>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.seasonstart !== this.state.PRODUCT.seasonend) {
            if (parseInt(this.state.PRODUCT.today_season) == 0 && this.state.PRODUCT.availableones == 0) {
                let BADGE = (
                    <div className="cardBadge season_card" key="0">
                        Сезонный
                    </div>
                );
                LEFT_ONES.push(BADGE);
            }
        }

        if (LEFT_ONES.length) {
            return (
                <div className="badgesCard">
                    { LEFT_ONES.map(badge => {
                        return badge
                    }) }
                </div>
            );
        } else {
            return false;
        }
    }

    renderMinusButton() {
        return (
            <div className={ "cardMinus" } onClick={ () => this.minusProduct() }>
                { this.state.wholesale
                    ? <img style={ this.props.AMOUNT_ALTERNATIVE == 1 ? { width: 18, height: 18 } : {} }
                        src={ this.props.AMOUNT_ALTERNATIVE == 1 ? '/system_images/green_bin.png' : '/system_images/greenMinus.png' } />
                    : <img style={ this.props.AMOUNT == 1 ? { width: 18, height: 18 } : {} }
                        src={ this.props.AMOUNT == 1 ? '/system_images/green_bin.png' : '/system_images/greenMinus.png' } /> }
            </div>
        )
        return (
            <a className="am-minus" onClick={ () => this.minusProduct() } tabIndex="0">
                -
            </a>
        );
    }

    minusProduct() {
        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE - 1 < 1)
                this.setState({ MANIPULATE: false });
        } else {
            if (this.props.AMOUNT - 1 < 1)
                this.setState({ MANIPULATE: false });
        }
        this.props.minusCart(this.getCurrentProduct(), 'modalCard');

    }

    checkMaximum() {
        let PRODUCT = this.getCurrentProduct();
        if (this.state.wholesale) {
            return this.props.AMOUNT_ALTERNATIVE + 1 <= PRODUCT.availableones;
        } else {
            return this.props.AMOUNT + 1 <= PRODUCT.availableones;
        }
    }

    plusProduct() {
        let check = this.checkMaximum();

        if (check) {
            if (this.state.wholesale) {
                if (this.props.AMOUNT_ALTERNATIVE > 0) {
                    this.props.plusCart(this.getCurrentProduct(), 'modalCard');
                } else {
                    this.addToCart();
                }
            } else {
                if (this.props.AMOUNT > 0) {
                    this.props.plusCart(this.getCurrentProduct(), 'modalCard');
                } else {
                    this.addToCart();
                }
            }
        }
    }

    renderCartedPrice() {
        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE > 0) {
                return this.getCurrentProduct().price * this.props.AMOUNT_ALTERNATIVE;
            } else {
                return this.getCurrentProduct().price;
            }
        } else {
            if (this.props.AMOUNT > 0) {
                return this.state.PRICE * this.props.AMOUNT;
            } else {
                return this.state.PRICE;
            }
        }
    }


    rmFixed2(numb) {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(numb).toFixed(2);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    getInCartAmountOrOne = () => {
        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE > 0) {
                return this.props.AMOUNT_ALTERNATIVE;
            }
        } else {
            if (this.props.AMOUNT > 0) {
                return this.props.AMOUNT;
            }
        }
        return 1;
    }

    renderProductOldPrice() {
        if ((parseInt(this.getCurrentProduct().sale) == 1) && (this.getCurrentProduct().old_price > 0)) {
            let PRICE = this.rmFixed2(parseFloat(this.getCurrentProduct().old_price) * this.getInCartAmountOrOne());
            let PRICE_PARTS = PRICE.toString().split('.');
            if (typeof PRICE_PARTS[1] !== "undefined") {
                if (PRICE_PARTS[1].length == 1) {
                    PRICE_PARTS[1] = PRICE_PARTS[1] + '0';
                }
            } else {
                PRICE_PARTS[1] = '00';
            }
            return (
                <div className="old_price_row">
                    <div className="old_price_line"></div>
                    <span className="old_price_text">
                        { PRICE_PARTS[0] }
                    </span>
                    <span className="old_price_text2">
                        { PRICE_PARTS[1] }
                    </span>
                </div>
            );
        }
    }

    getOldPriceClass = () => {
        if ((parseInt(this.getCurrentProduct().sale) == 1) && (this.getCurrentProduct().old_price > 0)) {
            return ' prices_padded';
        }

        return '';
    }
    getDiscountProcent = () => {
        if ((parseInt(this.getCurrentProduct().sale) == 1) && (this.getCurrentProduct().old_price > 0)) {
            let PERCENT = (100 - (this.getCurrentProduct().price / (this.getCurrentProduct().old_price / 100))).toFixed()
            return (
                <div className="price_procent">
                    - { PERCENT }%
                </div>

            );
        }
    }

    renderAmountMini = () => {
        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE > 0) {
                return (this.props.AMOUNT_ALTERNATIVE * this.getCurrentProduct()?.peramount)?.toFixed(2)
            } else {
                return this.getCurrentProduct()?.peramount
            }
        } else {
            if (this.props.AMOUNT > 0) {
                return (this.props.AMOUNT * this.getCurrentProduct()?.peramount)?.toFixed(2)
            } else {
                return this.getCurrentProduct()?.peramount
            }
        }
    }

    renderEcoBonuses = () => {
        return (
            Math.floor(Math.floor(parseInt(this.getCurrentProduct().price) * this.getInCartAmountOrOne()) / 100 * 8)
        )
    }

    getClosestTime = () => {
        if (this.props.USEABLE_PERIODS) {
            let DATE = Object.keys(this.props.USEABLE_PERIODS)[0];
            if (typeof this.props.USEABLE_PERIODS[DATE] !== "undefined") {
                let SHOW_DATE = this.props.USEABLE_PERIODS[DATE].title;
                let STRING = "Доставим " + SHOW_DATE.toLowerCase()
                return STRING;
            }

            return ' подсчитываем...';
        }
    }

    ecobalsText = (num) => {
        if (num == 1) {
            return "экобалл"
        }
        if (num == 2 || num == 3 || num == 4) {
            return "экобалла"
        } else {
            return "экобаллов"
        }
    }

    renderAnketaLink = () => {
        let temp = this.renderEcoBonuses().toString();
        let lastNum = parseInt(temp[temp.length - 1]);

        return (
            <div className="car_and_text">
                <div className="car_and_text_green_text">
                    +{ this.renderEcoBonuses() }
                </div>
                <div className="delivery_date_text">
                    экобаллов
                    {/*на личный счёт*/ }
                </div>
            </div>
        )
    }

    updateInlineValues = async () => {
        try {
            this.setState({
                DO_EDIT_TITLE: false,
                DO_EDIT_SHORT_DESCRIPTION: false,
                DO_EDIT_STORAGE: false,
                DO_EDIT_SOSTAV: false,
                DO_EDIT_PERAMOUNT: false,
                DO_EDIT_WEIGHT: false,
                DO_EDIT_FOOD: false,
                DO_EDIT_PRICE: false,
                DO_EDIT_GROUPS: false,
                DO_EDIT_SM_PRICE: false,
                DO_EDIT_HF_PRICE: false
            });

            let SEND_DATA = {
                action: 'admin_updateProductValues',
                id: this.state.PRODUCT.id,
                apikey: this.props.USER.TOKEN,
                title: this.state.TITLE,
                short_description: this.state.SHORT_DESCRIPTION,
                sostav: this.state.SOSTAV,
                weight: this.state.WEIGHT,
                coef: this.state.PERAMOUNT,
                price: this.state.PRICE,
                sm_price: this.state.SM_PRICE,
                halal_price: this.state.HALAL_PRICE,
                storage: this.state.STORAGE,
                calories_amount: this.state.calories_amount,
                calories_percent: this.state.calories_percent,
                fats_amount: this.state.fats_amount,
                fats_percent: this.state.fats_percent,
                ugl_amount: this.state.ugl_amount,
                ugl_percent: this.state.ugl_percent,
                autoorder: this.state.autoorder,
                sugar: this.state.sugar,
                bel_amount: this.state.bel_amount,
                bel_percent: this.state.bel_percent,
                klet_amount: this.state.klet_amount,
                klet_percent: this.state.klet_percent,
                all_groups: this.state.ALL_GROUPS
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {

            }
        } catch (error) {
            console.log(error)
        }
    }


    renderPerAmountBlock = () => {

        if ((this.state.DO_EDIT_PERAMOUNT) && (typeof this.state.DO_EDIT_PERAMOUNT !== "undefined")) {
            return (
                <div className="current_amount_small">
                    <input onChange={ (text) => this.recordField('PERAMOUNT', text) } className="inline_input" type="text" value={ this.state.PERAMOUNT } />
                    <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                    <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_PERAMOUNT: false, PERAMOUNT: this.state.PRODUCT.peramount }) }><i className="fa fa-remove"></i></a>
                </div>
            )
        } else {
            return (
                <div className="current_amount_small">
                    за { this.state.PERAMOUNT } { this.getCurrentProduct().ed_izm }<a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_PERAMOUNT: true }) }><i className="fa fa-pencil"></i></a>
                </div>
            )
        }
    }

    renderPriceBlock = () => {
        if ((this.state.DO_EDIT_PRICE) && (typeof this.state.DO_EDIT_PRICE !== "undefined")) {

            return (
                <div>
                    <input onChange={ (text) => this.recordField('PRICE', text) } className="inline_input" type="text" value={ this.state.PRICE } />
                    <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                    <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_PRICE: false, PRICE: this.state.PRODUCT.price }) }><i className="fa fa-remove"></i></a>
                </div>
            );

        } else {
            let PRICE = this.renderCartedPrice();
            PRICE = this.rmFixed(PRICE)
            let PRICE_PARTS = PRICE.toString().split('.');

            if (typeof PRICE_PARTS[1] !== "undefined") {
                if (PRICE_PARTS[1].length == 1) {
                    PRICE_PARTS[1] = PRICE_PARTS[1] + '0';
                }
            } else {
                PRICE_PARTS[1] = '00';
            }

            let PERCENT = (100 - (this.state.alternativeProd?.PRODUCT?.price / (this.state.alternativeProd?.PRODUCT?.old_price / 100))).toFixed() + '%'
            if (!parseInt(PERCENT)) PERCENT = false
            let ed_izm = this.props.PRODUCTS[parseInt(this.state.PRODUCT.id)]?.opt.ed_izm

            return (
                <>
                    <span className="price" itemProp="price">{ PRICE_PARTS[0] }</span>
                    <span className="price_kop">{ PRICE_PARTS[1] }</span>
                    <a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_PRICE: true }) }><i className="fa fa-pencil"></i></a>
                </>
            )
        }

    }

    renderButtons = () => {
        const width = window.innerWidth;

        let PRICE = this.renderCartedPrice();
        PRICE = this.rmFixed(PRICE)
        let PRICE_PARTS = PRICE.toString().split('.');

        if (typeof PRICE_PARTS[1] !== "undefined") {
            if (PRICE_PARTS[1].length == 1) {
                PRICE_PARTS[1] = PRICE_PARTS[1] + '0';
            }
        } else {
            PRICE_PARTS[1] = '00';
        }

        let PERCENT = (100 - (this.state.alternativeProd?.PRODUCT?.price / (this.state.alternativeProd?.PRODUCT?.old_price / 100))).toFixed() + '%'
        if (!parseInt(PERCENT)) PERCENT = false
        let ed_izm = this.props.PRODUCTS[parseInt(this.state.PRODUCT.id)]?.opt.ed_izm

        return (
            <div className="btn_place">
                <div className="product-adder button-holder">
                    <div className="selecter" itemProp="offers" itemScope itemType="http://schema.org/Offer">
                        {/*{this.getCurrentProduct().peramount}*/ }
                        <div className="sup_prices">
                            Закуп: { this.state.PRODUCT.sup_price } руб (мрж: { this.state.PRODUCT.marge }%) <a className="read_more" onClick={ () => this.getBuyStats() }>Подробнее</a>
                        </div>
                        <div className="price_button_discount_holder">
                            <div className="price_and_discount_holder">
                                <div className={ 'prices' + this.getOldPriceClass() }>
                                    <div>
                                        { this.renderProductOldPrice() }
                                        { this.getDiscountProcent() }
                                    </div>
                                    { this.renderPriceBlock() }
                                    { this.renderPerAmountBlock() }

                                    <meta itemProp="priceCurrency" content="RUB" />
                                </div>
                            </div>
                            <div className="btn-cart-row">
                                {/*{this.renderManipulator()}*/ }
                                { (width >= 1200 && Object.keys(this.state.alternativeProd).length !== 0) &&
                                    <div className="wholesaleToggle">
                                        <div style={ {
                                            color: !this.state.wholesale ? '#5a5a5a' : '#bfbfbf',
                                            background: !this.state.wholesale ? '#fff' : 'rgb(229, 229, 229)',
                                            borderRadius: 60
                                        } } onClick={ () => this.setState({ wholesale: false }) } className="firstToggle">1
                                            шт
                                        </div>
                                        <div style={ {
                                            color: this.state.wholesale ? '#5a5a5a' : '#bfbfbf',
                                            background: this.state.wholesale ? '#fff' : 'rgb(229, 229, 229)',
                                            borderRadius: 60
                                        } } onClick={ () => this.setState({ wholesale: true }) } className="secondToggle">
                                            <span>
                                                { ed_izm }
                                            </span>
                                            <span className="percents">
                                                -{ PERCENT }
                                            </span>
                                        </div>
                                    </div> }
                            </div>
                        </div>
                        <div style={ { marginTop: 30 } } className="all_bonuses_and_deiveri_text">

                            <div className="car_and_text">
                                { (width < 1200 && Object.keys(this.state.alternativeProd).length !== 0) &&
                                    <div className="wholesaleToggle">
                                        <div style={ {
                                            color: !this.state.wholesale ? '#5a5a5a' : '#bfbfbf',
                                            background: !this.state.wholesale ? '#fff' : 'rgb(229, 229, 229)',
                                            borderRadius: 60
                                        } } onClick={ () => this.setState({ wholesale: false }) } className="firstToggle">
                                            1 шт
                                        </div>
                                        <div style={ {
                                            color: this.state.wholesale ? '#5a5a5a' : '#bfbfbf',
                                            background: this.state.wholesale ? '#fff' : 'rgb(229, 229, 229)',
                                            borderRadius: 60
                                        } } onClick={ () => this.setState({ wholesale: true }) } className="secondToggle">
                                            <span>
                                                { ed_izm }
                                            </span>
                                            <span className="percents">
                                                -{ PERCENT }
                                            </span>
                                        </div>
                                    </div> }
                                <img className="green_car" src="/system_images/green_car.png" />
                                <div className="delivery_date_text">
                                    { this.getClosestTime() }
                                </div>
                            </div>
                            <div className="car_and_text">
                                {/*<div className="car_and_text_green_text">*/ }
                                {/*    +{this.renderEcoBonuses()}*/ }
                                {/*</div>*/ }
                                {/*<div className="delivery_date_text">*/ }
                                {/*    экобаллов на{this.renderAnketaLink()}*/ }
                                {/*</div>*/ }
                                { this.renderAnketaLink() }
                            </div>
                        </div>

                    </div>
                </div>
                { this.renderOtherShopsInfo() }
            </div>
        );

    }

    renderOtherShopsInfo = () => {
        let SHOPS = [];


        SHOPS.push(
            <div className="other_shop_row" key="sm">
                <img src="/system_images/sm_logo.png" />
                { this.state.DO_EDIT_SM_PRICE ?
                    <>
                        <span>
                            <input onChange={ (text) => this.recordField('SM_PRICE', text) } className="inline_input" type="text" value={ this.state.SM_PRICE } />
                        </span>
                        <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_SM_PRICE: false, SM_PRICE: this.state.PRODUCT.sm_price }) }><i className="fa fa-remove"></i></a>
                    </>
                    :
                    <>
                        <span>
                            { this.state.SM_PRICE } руб
                        </span>
                        <a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_SM_PRICE: true }) }><i className="fa fa-pencil"></i></a>
                    </>
                }
                <div style={ { display: 'flex', alignItems: 'center' } } className="p_check show_sm">
                    <label style={ { marginRight: '20px' } } htmlFor="show_sm">
                        <span>Показывать в магазине</span>
                    </label>
                    <ToggleButton isOn={ this.state.show_sm } onToggle={ () => {
                        this.toggleShop(!this.state.show_sm, 'show_sm')
                    } } />
                </div>
            </div>
        )



        SHOPS.push(
            <div className="other_shop_row" key="hal">
                <img src="/system_images/hal_logo.png" />
                { this.state.DO_EDIT_HF_PRICE ?
                    <>
                        <span>
                            <input onChange={ (text) => this.recordField('HALAL_PRICE', text) } className="inline_input" type="text" value={ this.state.HALAL_PRICE } />
                        </span>
                        <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_HF_PRICE: false, HALAL_PRICE: this.state.PRODUCT.halal_price }) }><i className="fa fa-remove"></i></a>
                    </>
                    :
                    <>
                        <span>
                            { this.state.HALAL_PRICE } руб
                        </span>
                        <a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_HF_PRICE: true }) }><i className="fa fa-pencil"></i></a>
                    </>
                }
                <div style={ { display: 'flex', alignItems: 'center' } } className="p_check show_hf">
                    <label style={ { marginRight: '20px' } } htmlFor="show_hf">
                        <span>Показывать в магазине</span>
                    </label>
                    <ToggleButton isOn={ this.state.show_hf } onToggle={ () => {
                        this.toggleShop(!this.state.show_hf, 'show_hf')
                    } } />
                </div>
            </div>
        )


        return SHOPS;
    }

    renderPlusButton() {
        return (
            <a className="am-plus" onClick={ () => this.plusProduct() } tabIndex="0">
                +
            </a>
        );
    }

    rmFixed(numb) {
        numb = parseFloat(numb);
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount() {
        let PRODUCT = this.getCurrentProduct();
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;
        if (this.state.wholesale) {
            SHOW_AMOUNT = this.props.AMOUNT_ALTERNATIVE * PRODUCT.peramount;
        }

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderCurShowAmount() {
        let PRODUCT = this.getCurrentProduct();
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (this.state.wholesale) {
            SHOW_AMOUNT = this.props.AMOUNT_ALTERNATIVE * PRODUCT.peramount;
        }

        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE < 1) {
                return PRODUCT.peramount;
            }
        } else {
            if (this.props.AMOUNT < 1) {
                return PRODUCT.peramount;
            }
        }

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage() {
        let PRODUCT = this.getCurrentProduct();
        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE == PRODUCT.availableones) {
                return (
                    <div>
                        <div className="cart-max">Это максимальное количество товара.</div>
                        <div className="cart-max-text">Вы можете указать желаемое количество в комментарии к заказу.
                        </div>
                    </div>
                );
            }
        } else {
            if (this.props.AMOUNT == PRODUCT.availableones) {
                return (
                    <div>
                        <div className="cart-max">Это максимальное количество товара.</div>
                        <div className="cart-max-text">Вы можете указать желаемое количество в комментарии к заказу.
                        </div>
                    </div>
                );
            }
        }
    }

    renderManipulator = () => {
        return (
            <div className="amounts-holder">
                { this.renderMinusButton() }
                <div className="the_amount">
                    <span
                        className="current_amount">{ this.renderCurShowAmount() }</span> { this.getCurrentProduct().ed_izm }
                </div>
                { this.renderPlusButton() }
            </div>
        );
    }

    getPercentClass = () => {
        if (parseInt(this.getCurrentProduct().of_day) == 1) {
            return ' greeny';
        }
    }

    renderProductImgSlider = () => {
        let SLIDES = [];

        if ((this.getCurrentProduct().images) && (typeof this.getCurrentProduct().images !== "undefined")) {
            if (this.getCurrentProduct().images.length) {
                for (let i = 0; i < this.getCurrentProduct().images.length; i++) {
                    SLIDES.push(<div key={ i }><img src={ CONFIG.API_C_DOMAIN + this.getCurrentProduct().images[i].full } />
                    </div>)
                }
            }
        }

        return (SLIDES);
    }

    next = () => {
        this.slider.slickNext();
    }
    previous = () => {
        this.slider.slickPrev();
    }
    beforeChange = (prev, next) => {
        if (window.innerWidth < 1200) {
            return false
        } else {
            this.setState({ SLIDE_INDEX: next });
        }

    };
    renderPrevButton = () => {
        if (window.innerWidth > 1200) {
            if (this.state.SLIDE_INDEX === 0) {
                return (<img className="round_button_img" src="/system_images/DisabledRoundPrev.png" />)
            } else {
                return (<img className="round_button_img" src="/system_images/RoundPrev.png" onClick={ this.previous } />)
            }
        } else {
            return false
        }

    }
    renderNextButton = () => {
        if (window.innerWidth > 1200) {
            if (this.state.SLIDE_INDEX === (this.getCurrentProduct().images.length - 1)) {
                return (<img className="round_button_img" src="/system_images/DisabledRoundNext.png" />)
            } else {
                return (<img className="round_button_img" src="/system_images/RoundNext.png" onClick={ this.next } />)
            }
        } else {
            return false
        }

    }
    dotsRender = () => {
        if (window.innerWidth < 1200) {
            return true
        } else {
            return false
        }
    }


    renderImagesSlider = () => {
        let settings = {
            dots: this.dotsRender(),
            fade: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: this.beforeChange,
            dotsClass: "button_bar",

        };

        return (
            <div className="gallery_carou_ab">
                <Slider ref={ c => (this.slider = c) } { ...settings } className="gallery_carou_ab">
                    { this.renderProductImgSlider() }
                </Slider>
                <div className='round_button_holder'>
                    { this.renderPrevButton() }
                    { this.renderNextButton() }
                </div>
            </div>
        );
    }

    renderPercents() {
        return;
        if ((parseInt(this.getCurrentProduct().sale) == 1) && (this.getCurrentProduct().old_price > 0)) {
            let PERCENT = (100 - (this.getCurrentProduct().price / (this.getCurrentProduct().old_price / 100))).toFixed()

            return (
                <div className="discount_percent_holder">
                    <div className={ 'discount_percent_text' + this.getPercentClass() }>
                        - { PERCENT }%
                    </div>
                </div>
            );
        }
    }

    renderRating = () => {
        if (this.getCurrentProduct().rating > 0) {
            return (

                <div className='rating_star_card'>
                    <div>{ this.getCurrentProduct().rating }</div>
                    <img className='star_image_card' src="/system_images/black_star.svg" />
                </div>

            );
        }
    }

    getCurrentProduct = () => {
        return this.state.PRODUCT
    }

    renderChangeButton = () => {
        return (
            <label className="p_image_changer">
                <div>
                    <div className="inline_edit"><i className="fa fa-pencil"></i></div>
                </div>
                <input style={ { 'display': 'none' } } type="file" onChange={ (e) => this.pictureChosen(e) } />
            </label>
        )
    }

    pictureChosen = async (e) => {
        let file = e.target.files[0];

        if (typeof file !== "undefined") {
            if (file !== null) {
                let SEND_DATA = new FormData();

                SEND_DATA.append('action', 'admin_uploadProductImage');
                SEND_DATA.append('apikey', this.props.USER.TOKEN);
                SEND_DATA.append('id', this.state.PRODUCT.id);
                SEND_DATA.append('PICTURE', file);

                fetch(
                    CONFIG.API_GATE,
                    {
                        credentials: 'include',
                        method: "POST",
                        body: SEND_DATA
                    }
                )
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status.result == 'SUCCESS') {

                            let PRODUCT = Object.assign({}, this.state.PRODUCT);
                            PRODUCT.big = responseJson.data.image;
                            // NEW_AVA_USER.IMAGE = responseJson.data.image;
                            this.setState({ PRODUCT: PRODUCT });
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        //alert(JSON.stringify(err))
                    })
            }
        }
    }

    renderImage() {
        let SRC = this.getCurrentProduct().big;
        let IMAGES = this.getCurrentProduct().images;

        // if ((typeof IMAGES !== "undefined") && (IMAGES.length > 1)) {
        //     return (
        //         <div className="the_gallery">
        //             {this.renderChangeButton()}
        //             {this.renderImportantBadge()}
        //             {this.renderImagesSlider()}
        //             {this.renderPercents()}
        //             {this.renderRating()}
        //         </div>
        //     );
        // } else {
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            SRC = CONFIG.API_C_DOMAIN + SRC;
            return (
                <div className="the_gallery">
                    { this.renderChangeButton() }
                    { this.renderImportantBadge() }
                    <div className="product-gallery" id="big_image">
                        <img title={ this.getCurrentProduct().title } src={ SRC } />
                        { this.renderPercents() }
                        { this.renderRating() }
                    </div>


                </div>
            );
        } else {
            SRC = CONFIG.API_C_DOMAIN + '/imgs/no-foto.png';
            return (
                <div className="the_gallery">
                    { this.renderChangeButton() }
                    { this.renderImportantBadge() }
                    <div className="product-gallery" id="big_image">
                        <img title={ this.getCurrentProduct().title } src={ SRC } />
                        { this.renderPercents() }
                        { this.renderRating() }
                    </div>
                </div>
            );
        }
        // }
    }

    toggleImportance = async () => {
        if (parseInt(this.props.USER.ID) == 344545 || parseInt(this.props.USER.ID) == 1) {
            try {
                let SEND_DATA = {
                    action: 'admin_updateImportantProduct',
                    id: this.state.PRODUCT.id,
                    apikey: this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.setState({ IMPORTANT: !this.state.IMPORTANT })
                }
            } catch (error) {
            }
        }
    }

    renderImportantBadge = () => {
        let src = '/system_images/favs_empty.png';
        if (this.state.IMPORTANT) {
            src = '/system_images/favs_full.png';
        }

        return (
            <>
                <a className="fav_adder" onClick={ () => this.toggleImportance() }>
                    <img src={ src } />
                </a>
                { this.renderBadges1() }
            </>
        )
    }



    recordField = (TITLE, input) => {
        this.setState({ [TITLE]: input.target.value });
    }

    renderWeightEdit = () => {
        let ed = parseFloat(this.state.WEIGHT) > 0.5 ? (this.state.PRODUCT.ed_izm === 'л' ? 'л' : 'кг') : (this.state.PRODUCT.ed_izm === 'л' ? 'мл' : 'г')

        if ((this.state.DO_EDIT_WEIGHT) && (typeof this.state.DO_EDIT_WEIGHT !== "undefined")) {
            return (
                <div className="spacer amount_spacer">
                    <span className="current_amount">
                        <input onChange={ (text) => this.recordField('WEIGHT', text) } className="inline_input" type="text" value={ this.state.WEIGHT } />

                    </span> { ed }
                    <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                    <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_WEIGHT: false, WEIGHT: this.state.PRODUCT.weight }) }><i className="fa fa-remove"></i></a>
                </div>
            )
        } else {

            return (
                <div className="spacer amount_spacer">
                    <span className="current_amount">
                        { parseFloat(this.state.WEIGHT) > 0.5 ? parseFloat(this.state.WEIGHT) : parseFloat(this.state.WEIGHT) * 1000 }
                    </span> { ed } <a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_WEIGHT: true }) }><i className="fa fa-pencil"></i></a>
                </div>
            )
        }
    }

    renderTitleInside = () => {
        if ((this.state.DO_EDIT_TITLE) && (typeof this.state.DO_EDIT_TITLE !== "undefined")) {
            return (
                <span>
                    <input onChange={ (text) => this.recordField('TITLE', text) } className="inline_input" type="text" value={ this.state.TITLE } />
                    <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                    <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_TITLE: false, TITLE: this.state.PRODUCT.title }) }><i className="fa fa-remove"></i></a>
                </span>
            )
        } else {
            return (
                <span>
                    { this.state.TITLE } <a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_TITLE: true }) }><i className="fa fa-pencil"></i></a>
                </span>
            )
        }
    }

    renderTitle() {
        let ed = parseFloat(this.props.PRODUCTS[this.getCurrentProduct().id].weight) > 0.5 ? (this.props.PRODUCTS[this.getCurrentProduct().id].ed_izm === 'л' ? 'л' : 'кг') : (this.props.PRODUCTS[this.getCurrentProduct().id].ed_izm === 'л' ? 'мл' : 'г')
        return (
            <div>
                <h1 itemProp="name">
                    { this.renderTitleInside() }
                </h1>
                { this.renderWeightEdit() }

            </div>
        );
    }

    switchView = (VIEW) => {
        if (this.state.VIEW !== VIEW) {
            this.setState({ VIEW: VIEW });
        }
    }

    renderBigButtons() {
        return (
            <></>
        );
    }

    renderTextView() {
        let SHORT_DESC = '';
        let SOSTAV = '';
        let STORAGE = '';

        SOSTAV = this.renderSostav();
        STORAGE = this.renderStorage();
        SHORT_DESC = this.renderShortDescription();
        return (
            <div className="flex-grow">
                { SHORT_DESC }
                { STORAGE }
                { SOSTAV }
            </div>
        );
    }

    renderSostav = () => {
        if ((this.state.DO_EDIT_SOSTAV) && (typeof this.state.DO_EDIT_SOSTAV !== "undefined")) {
            return (
                <div>
                    <label className="in-card-label">
                        Состав
                        <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_SOSTAV: false, SOSTAV: this.state.PRODUCT.sostav }) }><i className="fa fa-remove"></i></a>
                    </label>
                    <div className="short-desc">
                        <textarea onChange={ (text) => this.recordField('SOSTAV', text) } className="inline_input" type="text" >{ this.state.SOSTAV }</textarea>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <label className="in-card-label">Состав<a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_SOSTAV: true }) }><i className="fa fa-pencil"></i></a></label>
                    <div className="short-desc" dangerouslySetInnerHTML={ { __html: this.state.SOSTAV } }>
                    </div>
                </div>
            )
        }
    }

    renderStorage = () => {
        if ((this.state.DO_EDIT_STORAGE) && (typeof this.state.DO_EDIT_STORAGE !== "undefined")) {
            return (
                <div>
                    <label className="in-card-label">
                        Хранение
                        <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_STORAGE: false, STORAGE: this.state.PRODUCT.storage }) }><i className="fa fa-remove"></i></a>
                    </label>
                    <div className="short-desc">
                        <textarea onChange={ (text) => this.recordField('STORAGE', text) } className="inline_input" type="text" >{ this.state.STORAGE }</textarea>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <label className="in-card-label">Хранение<a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_STORAGE: true }) }><i className="fa fa-pencil"></i></a></label>
                    <div className="short-desc" dangerouslySetInnerHTML={ { __html: this.state.STORAGE } }>
                    </div>
                </div>
            )
        }
    }

    renderShortDescription = () => {
        if ((this.state.DO_EDIT_SHORT_DESCRIPTION) && (typeof this.state.DO_EDIT_SHORT_DESCRIPTION !== "undefined")) {
            return (
                <div>
                    <label className="in-card-label">
                        Описание
                        <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_SHORT_DESCRIPTION: false, SHORT_DESCRIPTION: this.state.PRODUCT.short_description }) }><i className="fa fa-remove"></i></a>
                    </label>
                    <div className="short-desc">
                        <textarea onChange={ (text) => this.recordField('SHORT_DESCRIPTION', text) } className="inline_input" type="text" >{ this.state.SHORT_DESCRIPTION }</textarea>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <label className="in-card-label">Описание<a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_SHORT_DESCRIPTION: true }) }><i className="fa fa-pencil"></i></a></label>
                    <div className="short-desc" dangerouslySetInnerHTML={ { __html: this.state.SHORT_DESCRIPTION } }>
                    </div>
                </div>
            )
        }
    }

    renderCommentForm() {
        return false
    }

    renderComments() {
        return false
    }

    showFoody = () => {
        return (this.getCurrentProduct().calories_amount !== '');
    }

    renderDesktopFoody() {
        if (WIDTH > 760) {
            if (this.showFoody()) {
                return (
                    <div className="desktop_foody">
                        { this.renderFoodyData() }
                    </div>
                );
            }
        }
    }

    showRelSelect = () => {
        if (this.state.SHOW_REL_SELECT) {


            return (
                <div className="or_modal">
                    <div className="or_overlay" onClick={ () => this.setState({ SHOW_REL_SELECT: false }) }></div>
                    <div className="or_body_ns">
                        <a className="close-modal" onClick={ () => this.setState({ SHOW_REL_SELECT: false }) }>
                            <img src="/system_images/close.png" />
                        </a>
                        <h6>Выберите рекомендованный товар:</h6>

                        <div className="select_search_holder">
                            <SelectSearch
                                filterOptions={ fuzzySearch }
                                search={ true }
                                id="supplierInModal"
                                name="supplierInModal"
                                options={ this.state.PRODUCT_OPTIONS }
                                placeholder="Выбрать товар"
                                onChange={ (val) => this.selectRelProduct(val) }
                                value={ '' }
                            />
                            <img className="bangled" src="/system_images/blue_angle_down.png" />
                        </div>
                    </div>
                </div>
            )
        }
    }

    selectRelProduct = (val) => {
        let NEW_RELATED = [val];

        for (let i = 0; i < this.state.related.length; i++) {
            let recId = this.state.related[i]
            NEW_RELATED.push(recId)
        }

        function onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }

        NEW_RELATED = NEW_RELATED.filter(onlyUnique);

        this.setState({ related: NEW_RELATED, SHOW_REL_SELECT: false }, () => this.saveRelated())
    }
    removeFromRel = (id) => {
        let NEW_RELATED = [];

        for (let i = 0; i < this.state.related.length; i++) {
            let recId = this.state.related[i]
            if (id !== parseInt(recId)) {
                NEW_RELATED.push(recId)
            }
        }

        this.setState({ related: NEW_RELATED }, () => this.saveRelated())
    }

    saveRelated = async () => {
        try {
            let SEND_DATA = {
                action: 'admin_updateRelated',
                id: this.state.PRODUCT.id,
                related: this.state.related,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
        } catch (error) {
            this.setState({ alternativeProd: {} });
        }
    }

    openRelAdd = () => {
        if (this.state.related.length >= 8) {
            alert('Больше 8 товаров нельзя использовать!')
            return;
        }
        this.setState({ SHOW_REL_SELECT: true })
    }

    renderRelated = () => {
        let PRODUCTS = [];

        for (let i = 0; i < this.state.related.length; i++) {
            let id = this.state.related[i]

            if (typeof this.props.PRODUCTS[parseInt(id)] !== "undefined") {
                let PRODUCT = this.props.PRODUCTS[parseInt(id)];

                if (PRODUCT) {
                    PRODUCTS.push(
                        <div className="rec_item" key={ i }>
                            <div className="rec_image">
                                <img
                                    src={ CONFIG.API_C_DOMAIN + PRODUCT.thumb }
                                />
                            </div>
                            <div className="rec_title">{ PRODUCT.title }</div>
                            <a className="rec_remove" onClick={ () => this.removeFromRel(parseInt(id)) }><i class="fa fa-remove"></i></a>
                        </div>
                    )
                }
            }
        }
        return (
            <div className="desktop_foody">
                <div className="foody_head">Рекомендуемые товары: <a onClick={ () => this.openRelAdd() } class="inline_edit"><i class="fa fa-plus"></i></a></div>
                <div className="foody_block">
                    <div className="rec_list">
                        { PRODUCTS }
                    </div>
                </div>
            </div>
        )
    }



    renderBigCircle = (percent) => {
        if (percent) {
            if (percent > 0) {
                return (
                    <div className="pie_holder">
                        <CircularProgressbar
                            value={ percent }
                        />
                        <div className="circle_data_info"><span className="big">{ percent }%</span><span
                            className="small">суточной<br />нормы</span></div>
                    </div>
                );
            }
        }
    }

    renderSmallCircle = (percent) => {
        if (percent) {
            if (percent > 0) {
                return (
                    <div className="pie_holder">
                        <CircularProgressbar
                            value={ percent }
                        />
                        <div className="circle_data_info"><span className="big_smaller">{ percent }%</span></div>
                    </div>
                );
            }
        }
    }

    renderCircleText = (amount, title, show_gramm) => {
        let gramm = 'г';
        if (typeof show_gramm !== "undefined") {
            gramm = '';
        }

        if (parseFloat(amount) >= 0) {
            return (
                <div className="data_title">
                    <div className="data_title_label">
                        { title }
                    </div>
                    <div className="data_title_value">
                        { amount } { gramm }
                    </div>
                </div>
            );
        }
    }

    renderShortDescription = () => {
        if ((this.state.DO_EDIT_SHORT_DESCRIPTION) && (typeof this.state.DO_EDIT_SHORT_DESCRIPTION !== "undefined")) {
            return (
                <div>
                    <label className="in-card-label">
                        Описание
                        <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_SHORT_DESCRIPTION: false, SHORT_DESCRIPTION: this.state.PRODUCT.short_description }) }><i className="fa fa-remove"></i></a>
                    </label>
                    <div className="short-desc">
                        <textarea onChange={ (text) => this.recordField('SHORT_DESCRIPTION', text) } className="inline_input" type="text" >{ this.state.SHORT_DESCRIPTION }</textarea>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <label className="in-card-label">Описание<a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_SHORT_DESCRIPTION: true }) }><i className="fa fa-pencil"></i></a></label>
                    <div className="short-desc" dangerouslySetInnerHTML={ { __html: this.state.SHORT_DESCRIPTION } }>
                    </div>
                </div>
            )
        }
    }

    renderFoodyData() {
        if ((this.state.DO_EDIT_FOOD) && (typeof this.state.DO_EDIT_FOOD !== "undefined")) {
            return (
                <div className="foody_data">
                    <div className="foody_head">
                        Энергетическая и пищевая ценность
                        <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({
                            DO_EDIT_FOOD: false,
                            calories_amount: this.state.PRODUCT.calories_amount,
                            calories_percent: this.state.PRODUCT.calories_percent,
                            fats_amount: this.state.PRODUCT.fats_amount,
                            fats_percent: this.state.PRODUCT.fats_percent,
                            ugl_amount: this.state.PRODUCT.ugl_amount,
                            ugl_percent: this.state.PRODUCT.ugl_percent,
                            bel_amount: this.state.PRODUCT.bel_amount,
                            bel_percent: this.state.PRODUCT.bel_percent,
                            klet_amount: this.state.PRODUCT.klet_amount,
                            klet_percent: this.state.PRODUCT.klet_percent
                        }) }><i className="fa fa-remove"></i></a>
                    </div>
                    <div>
                        <div>
                            <label>Калории</label>
                            <br />
                            <input onChange={ (text) => this.recordField('calories_amount', text) } className="inline_input" type="text" value={ this.state.calories_amount } />
                        </div>
                        <br />
                        <div>
                            <label>Калории, %</label>
                            <br />
                            <input onChange={ (text) => this.recordField('calories_percent', text) } className="inline_input" type="text" value={ this.state.calories_percent } />
                        </div>
                        <br />
                        <div>
                            <label>Жиры</label>
                            <br />
                            <input onChange={ (text) => this.recordField('fats_amount', text) } className="inline_input" type="text" value={ this.state.fats_amount } />
                        </div>
                        <br />
                        <div>
                            <label>Жиры, %</label>
                            <br />
                            <input onChange={ (text) => this.recordField('fats_percent', text) } className="inline_input" type="text" value={ this.state.fats_percent } />
                        </div>
                        <br />
                        <div>
                            <label>Углеводы</label>
                            <br />
                            <input onChange={ (text) => this.recordField('ugl_amount', text) } className="inline_input" type="text" value={ this.state.ugl_amount } />
                        </div>
                        <br />
                        <div>
                            <label>Углеводы, %</label>
                            <br />
                            <input onChange={ (text) => this.recordField('ugl_percent', text) } className="inline_input" type="text" value={ this.state.ugl_percent } />
                        </div>
                        <br />
                        <div>
                            <label>Белки</label>
                            <br />
                            <input onChange={ (text) => this.recordField('bel_amount', text) } className="inline_input" type="text" value={ this.state.bel_amount } />
                        </div>
                        <br />
                        <div>
                            <label>Белки, %</label>
                            <br />
                            <input onChange={ (text) => this.recordField('bel_percent', text) } className="inline_input" type="text" value={ this.state.bel_percent } />
                        </div>
                        <br />
                        <div>
                            <label>Клетчатка</label>
                            <br />
                            <input onChange={ (text) => this.recordField('klet_amount', text) } className="inline_input" type="text" value={ this.state.klet_amount } />
                        </div>
                        <br />
                        <div>
                            <label>Клетчатка, %</label>
                            <br />
                            <input onChange={ (text) => this.recordField('klet_percent', text) } className="inline_input" type="text" value={ this.state.klet_percent } />
                        </div>
                    </div>
                </div>
            );
        } else {
            if (
                (this.getCurrentProduct().calories_amount == '')
                &&
                (this.getCurrentProduct().calories_percent == '')
                &&
                (this.getCurrentProduct().fats_amount == '')
                &&
                (this.getCurrentProduct().ugl_percent == '')
                &&
                (this.getCurrentProduct().fats_percent == '')
                &&
                (this.getCurrentProduct().ugl_amount == '')
                &&
                (this.getCurrentProduct().klet_percent == '')
                &&
                (this.getCurrentProduct().klet_amount == '')
            ) {
                return false;
            }

            let ugl = parseFloat(this.getCurrentProduct().ugl_amount) / 80 * 100

            return (
                <div className="foody_data">
                    <div className="foody_head">
                        Энергетическая и пищевая ценность <a className="inline_edit" onClick={ () => this.setState({ DO_EDIT_FOOD: true }) }><i className="fa fa-pencil"></i></a>
                    </div>
                    <div className="foody_head_small">
                        на 100 г продукта
                    </div>
                    <div className="foody_block">

                        <div className="foody_one">

                            <div className="data_pie">
                                { this.renderBigCircle(Math.ceil(parseFloat(this.state.calories_amount) / 2200 * 100) || 1) }
                                { this.renderCircleText(this.state.calories_amount, 'Калорийность', true) }
                            </div>

                        </div>

                        <div className="foody_two">

                            <div className="data_pie">
                                { this.renderSmallCircle(Math.ceil(parseFloat(this.state.bel_amount) / 80 * 100) || 1) }
                                { this.renderCircleText(this.state.bel_amount, 'Белки') }
                            </div>

                            <div className="data_pie">
                                { this.renderSmallCircle(Math.ceil(parseFloat(this.state.fats_amount) / 60 * 100) || 1) }
                                { this.renderCircleText(this.state.fats_amount, 'Жиры') }
                            </div>

                        </div>

                        <div className="foody_three">
                            <div className="data_pie">
                                { this.renderSmallCircle(Math.ceil(parseFloat(this.state.ugl_amount) / 80 * 100) || 1) }
                                { this.renderCircleText(this.state.ugl_amount, 'Углеводы') }
                            </div>

                            <div className="data_pie">
                                { this.renderSmallCircle(Math.ceil(parseFloat(this.state.klet_amount) / 28 * 100)) }
                                { this.renderCircleText(this.state.klet_amount, 'Клетчатка') }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderMobileFoody() {
        return (
            <div className="mobile_foody">
                { this.renderFoodyData() }
            </div>
        );
    }

    renderView() {
        if (this.state.VIEW == 'COMMENTS') {
            return this.renderComments();
        } else if (this.state.VIEW == 'LEAVE') {
            return this.renderCommentForm();
        } else if (this.state.VIEW == 'FOODY') {
            return this.renderMobileFoody();
        } else if (this.state.VIEW == 'INFO') {
            return this.renderInfo();
        } else {
            return this.renderTextView();
        }
    }



    renderBreadcrumbs() {

        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Главная'
        });
        if (this.getCurrentProduct().CAT) {
            DATA.push({
                'to': '/' + this.getCurrentProduct().CAT.parent.url,
                'title': this.getCurrentProduct().CAT.parent.title
            });
            DATA.push({
                'to': '/' + this.getCurrentProduct().CAT.url,
                'title': this.getCurrentProduct().CAT.title
            });
        }
        DATA.push({
            'to': '/' + this.getCurrentProduct().url,
            'title': this.getCurrentProduct().title
        });

        return <BreadCrumbs DATA={ DATA } />

    }

    renderManagerBadges = () => {
        return (
            <div className="right_manager_badges">
                <div>
                    <span>Продажи за 10 дней</span>
                    { this.state.PRODUCT.sales_10 } { this.getCurrentProduct().ed_izm }
                </div>
                { this.renderActivity() }
                { this.renderMarge() }
                { this.renderWriteoffs() }
                { this.renderDaysLastBuy() }
                { this.renderRealAmount() }
                <a className="card_edit_btn" onClick={ () => this.props.editSeasoning({ id: this.state.PRODUCT.id, title: this.state.PRODUCT.title, product: this.state.PRODUCT }) }>
                    <i className="fa fa-pencil"></i>
                </a>
            </div>
        )
    }

    renderRealAmount = () => {
        let style = {};
        if (parseFloat(this.state.PRODUCT.real_amount) == 0) {
            style = { backgroundColor: '#ffc4c4' }
        }
        return (
            <div style={ style }>
                <span>Остаток</span>
                { this.state.PRODUCT.real_amount } { this.getCurrentProduct().ed_izm }
                { this.state.PRODUCT.incoming_date ? <span>{ this.state.PRODUCT.incoming_date }</span> : '' }
            </div>
        )
    }

    renderActivity = () => {
        let style = {};
        if (parseFloat(this.state.PRODUCT.activity) < 85) {
            style = { backgroundColor: '#ffc4c4' }
        }
        return (
            <div style={ style }>
                <span>Активность</span>
                { this.state.PRODUCT.activity }%
            </div>
        )
    }

    renderMarge = () => {
        let style = {};
        if (parseFloat(this.state.PRODUCT.marge) < 30) {
            style = { backgroundColor: '#ffc4c4' }
        }
        return (
            <div style={ style }>
                <span>Марж</span>
                { this.state.PRODUCT.marge }%
            </div>
        )
    }

    renderWriteoffs = () => {
        if (parseFloat(this.state.PRODUCT.writeoffs) !== 0) {
            return (
                <div>
                    <span>Списания за 30 дней</span>
                    { this.state.PRODUCT.writeoffs } р
                </div>
            )
        }
    }

    renderDaysLastBuy = () => {
        let style = {};
        if (parseFloat(this.state.PRODUCT.days_last_buy_number) > 5) {
            style = { backgroundColor: '#ffc4c4' }
        }
        if (this.state.PRODUCT.days_last_buy !== '1 д') {
            return (
                <div style={ style }>
                    <span>Дней с посл. продажи</span>
                    { this.state.PRODUCT.days_last_buy }
                </div>
            )
        }
    }

    renderManagerInfo = () => {
        return (
            <div className="manager_info">
                <span onClick={ () => this.props.setManager(this.state.PRODUCT.manager) }>{ this.state.PRODUCT.manager }</span>
                <br />
                <span className="suppl" onClick={ () => this.props.setSupplier(this.state.PRODUCT.supplier) }>{ this.state.PRODUCT.supplier }</span>
            </div>
        )
    }

    getShopTitle = (key) => {
        switch (key) {
            case 'eco': return 'Экомаркет';
            case 'oz': return 'Ozon';
            case 'av': return 'Азбука Ввкуса';
            case 'vk': return 'Вкусвилл';
            case 'pk': return 'Перекресток';
            case 'vp': return 'Впрок';
            case 'un': return 'Утконос';
            case 'gl': return 'Глобус';
            case 'mf': return 'MyFresh';
            case 'ed': return 'Ешь Деревенское';
            case 'ff': return '4Fresh';
            case 'sm': return 'SeasonMarket';
            case 'ln': return 'Lenta';
            case 'm2': return 'M2';
        }
    }

    shortifyTitle = (title) => {
        let title_parts = title.split(' ');
        if (title_parts.length > 1) {
            let new_title = [];
            for (let i = 0; i < title_parts.length; i++) {
                new_title.push(title_parts[i].substring(0, 1));
            }

            return new_title.join('').toUpperCase();
        }

        return title.substring(0, 2).toUpperCase();
    }

    renderParsingData = () => {
        let RESULTS = [];
        for (let i in this.state.PRODUCT.parsing_results) {
            RESULTS.push(
                <a target="_blank" href={ this.state.PRODUCT.parsing_results[i].url } className="parsing_result_row" key={ i } style={ { backgroundColor: this.state.PRODUCT.parsing_results[i].style } }>
                    <div>{ this.getShopTitle(i) }</div>
                    <div>{ this.state.PRODUCT.parsing_results[i].text }</div>
                    <div>{ this.state.PRODUCT.parsing_results[i].diff }</div>
                </a>
            )
        }
        if (this.state.PRODUCT.parsing_urls) {
            if (this.state.PRODUCT.parsing_urls.custom_urls) {
                for (let i = 0; i < this.state.PRODUCT.parsing_urls.custom_urls.length; i++) {
                    RESULTS.push(
                        <a target="_blank" href={ this.state.PRODUCT.parsing_urls.custom_urls[i].url } className="parsing_result_row" key={ i + '_custom' } style={ { backgroundColor: '#ff9a23' } }>
                            <div>{ this.shortifyTitle(this.state.PRODUCT.parsing_urls.custom_urls[i].title) }</div>
                            <div>{ this.state.PRODUCT.parsing_urls.custom_urls[i].price }</div>
                        </a>
                    )
                }
            }
        }
        let LINK = 'https://ecomarket.ru/adminer.php?view=price_parsing&manager=' + this.state.PRODUCT.manager;

        return (
            <div className="parsing_data_row">
                <div className="foody_head">Результаты <a target="_blank" href={ LINK }>проценки</a>: <a className="inline_edit confirm" onClick={ () => this.props.editParsing(this.state.PRODUCT) }><i className="fa fa-pencil"></i></a></div>
                <div className="parsing_data_results">
                    { RESULTS }
                </div>
            </div>
        )
    }

    renderProductGroups = () => {
        if ((this.state.DO_EDIT_GROUPS) && (typeof this.state.DO_EDIT_GROUPS !== "undefined")) {
            return (
                <div className="editable_product_groups">
                    <div className="foody_head">Категории
                        <a className="inline_edit confirm" onClick={ () => this.updateInlineValues() }><i className="fa fa-check"></i></a>
                        <a className="inline_edit end" onClick={ () => this.setState({ DO_EDIT_GROUPS: false, ALL_GROUPS: this.state.PRODUCT.all_groups }) }><i className="fa fa-remove"></i></a>
                    </div>
                    <Tree
                        treeData={ this.state.PRODUCT.tree }
                        onCheck={ (nodeIds) => {
                            this.setState({ ALL_GROUPS: nodeIds.checked })
                        } }
                        checkStrictly={ true }
                        checkable={ true }
                        multiple={ true }
                        checkedKeys={ this.state.ALL_GROUPS }
                    />
                </div>
            )
        } else {
            let GROUPS = [];

            for (let i = 0; i < this.state.PRODUCT.tree.length; i++) {
                let group_1 = this.state.PRODUCT.tree[i];

                if (this.state.ALL_GROUPS.includes(group_1.key)) {
                    GROUPS.push(
                        <li key={ i }>{ group_1.title }</li>
                    )
                }

                for (let j = 0; j < group_1.children.length; j++) {
                    let group_2 = group_1.children[j];

                    if (this.state.ALL_GROUPS.includes(group_2.key)) {
                        GROUPS.push(
                            <li key={ i + '_' + j }>- { group_2.title }</li>
                        )
                    }

                    for (let k = 0; k < group_2.children.length; k++) {
                        let group_3 = group_2.children[k];

                        if (this.state.ALL_GROUPS.includes(group_3.key)) {
                            GROUPS.push(
                                <li key={ i + '_' + j + '_' + k }>-- { group_3.title }</li>
                            )
                        }
                    }
                }
            }

            return (
                <div className="editable_product_groups">
                    <div className="foody_head">Категории
                        <a className="inline_edit confirm" onClick={ () => this.setState({ DO_EDIT_GROUPS: true }) }><i className="fa fa-pencil"></i></a>
                    </div>
                    <ul>
                        { GROUPS }
                    </ul>
                </div>
            )
        }
    }

    renderStats = () => {
        if (this.state.SHOW_STATS) {
            let STATS_TO_SHOW = '';

            if (this.state.STATS) {
                if (!this.state.STATS.length) {
                    STATS_TO_SHOW = <h5>Статистики закупок по товару нет!</h5>;
                } else {
                    STATS_TO_SHOW = (
                        <TheLine
                            data={ this.state.STATS }
                            product={ this.state.PRODUCT.title }
                            edizm={ this.getCurrentProduct().ed_izm }
                        />
                    )
                }
            }

            return (
                <div className="stats_holder">
                    <a className="close-modal" onClick={ () => this.setState({ SHOW_STATS: false }) }>
                        <img src="/system_images/close.png" />
                    </a>
                    { STATS_TO_SHOW }
                </div>
            )
        }
    }

    renderSales = () => {
        let content = '';
        if (!this.state.PRODUCT.sales.length) {
            content = 'Продаж в базе не обнаружено!'
        } else {
            content = [(
                <div key="zero" className="sales_column">
                    <div className="sales_column_header">
                        Дата
                    </div>
                    <div className="sales_column_header">
                        Продажи
                    </div>
                </div>
            )]
            for (let i = 0; i < this.state.PRODUCT.sales.length; i++) {
                let day = this.state.PRODUCT.sales[i];

                content.push(
                    <div key={ i } className='sales_column'>
                        <div>
                            { day.date }<br />{ day.weekday }
                        </div>
                        <div>
                            { day.sales } { this.state.PRODUCT.ed_izm }
                        </div>
                    </div>
                )
            }

            content = (
                <>
                    <h4>Статистика продаж</h4>
                    <div className="sales_row">
                        { content }
                    </div>
                </>
            )
        }

        return content
    }

    render() {

        if (!this.state.PRODUCT && this.getCurrentProduct() !== undefined) {
            return false
        }

        return (
            <section ref={ this.myRef }>
                { this.showRelSelect() }
                <div className="main-container inner container">
                    <div className="row the-product-row">
                        { this.renderStats() }
                        <div className="col-md-12">
                            <div className="row product-info">

                                <div className="col-sm-12 col-md-5 col-lg-6 images-block">
                                    <div className="only_images_block">
                                        { this.renderManagerBadges() }
                                        { this.renderLeftBadgesNew() }
                                        { this.renderImage() }
                                    </div>
                                    { this.renderManagerInfo() }
                                    { this.renderParsingData() }
                                    { this.renderDesktopFoody() }
                                    { this.renderRelated() }
                                </div>



                                <div className="col-sm-12 col-md-7 col-lg-6 product-details">
                                    <div className="the_detailed" itemScope itemType="http://schema.org/Product">
                                        { this.renderTitle() }
                                        { this.renderButtons() }
                                        <div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
                                            <label style={ { marginRight: '20px' } } htmlFor="agree">
                                                <span>Сахаросодержащий напиток</span>
                                            </label>
                                            <ToggleButton isOn={ this.state.sugar == 1 ? true : false } onToggle={ () => {
                                                this.setState({ sugar: this.state.sugar == 1 ? 0 : 1 }, () => this.updateInlineValues())
                                            } } />
                                        </div>
                                        <div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
                                            <label style={ { marginRight: '20px' } } htmlFor="agree">
                                                <span>Участвует в АвтоЗаказе</span>
                                            </label>
                                            <ToggleButton isOn={ this.state.autoorder == 1 ? true : false } onToggle={ () => {
                                                this.setState({ autoorder: this.state.autoorder == 1 ? 0 : 1 }, () => this.updateInlineValues())
                                            } } />
                                        </div>
                                        { this.renderBigButtons() }
                                        { this.renderProductGroups() }
                                        { this.renderView() }
                                    </div>
                                </div>

                            </div>
                            { this.renderSales() }
                        </div>

                    </div>
                </div>
            </section>
        );
    }

}

const Wrapper = (props) => {
    const { push } = useHistory()
    return <InnerProduct { ...props } push={ push } />
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Wrapper)