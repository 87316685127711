import React, {Component} from 'react';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {checkRegionEntry} from "../HELPERS/CoordinatesHelders/DefinitionOfTheRegion";
import regionsJson from "../../Jsons/Delivery.json";
import {openBadRatingModal} from "./../../REDUX/functions.js";


function mapStateToProps(state) {
    return {
        ADRESS: state.ADRESS,
        GPS: state.GPS,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class BadRatingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            COMMENT: false,
            COMMENT_OBJ: {
                '1': {comment: 'Был невежлив', status: false},
                '2': {comment: 'Был неопрятен', status: false},
                '3': {comment: 'Был не в фирменной одежде', status: false},
                '4': {comment: 'Опоздал', status: false},
                '5': {comment: 'Невнятно говорил', status: false},
                '6': {comment: 'По телефону', status: false},
                '7': {comment: 'При встрече', status: false},
                '8': {comment: 'Не помог занести пакеты', status: false},
                '9': {comment: 'от 1 до 30 минут', status: false},
                '10': {comment: 'от 30 минут до 1 часа', status: false},
                '11': {comment: 'на 1 час и более', status: false},
                '12': {comment: 'предупредил об опоздании', status: false},
                '13': {comment: 'не предупредил об опоздании', status: false},
            },
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.props.BAD_RATING_MODAL !== prevProps.BAD_RATING_MODAL) {
    //         if (window.innerWidth < 1200) {
    //             if (!this.props.BAD_RATING_MODAL) {
    //                 document.body.classList.remove("modal-open");
    //                 document.documentElement.scrollTop = this.OFFSET;
    //             } else {
    //                 this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
    //                 document.body.classList.add("modal-open");
    //             }
    //         }
    //     }
    // }

    handleChangeBox = (e, id) => {
        let ID_COMPONENT = document.getElementById(id)
        let NEW_COMMENT = Object.assign(this.state.COMMENT_OBJ);
        if (ID_COMPONENT.checked === true) {
            NEW_COMMENT[id].status = true;
            this.setState({
                    ...this.state.COMMENT,
                    COMMENT: e.target.value,
                    COMMENT_OBJ: NEW_COMMENT
                })
        } else {
            NEW_COMMENT[id].status = false;
            this.setState({COMMENT_OBJ: NEW_COMMENT})
        }
    }


    renderCheckBoxes = () => {
        return (
            <div>
                <label className="check_box_text_holder">
                    <input
                        type="checkbox"
                        id='1'
                        value="Был невежлив"
                        // checked=""
                        onChange={(e) => this.handleChangeBox(e, '1')}
                    />
                    <div className="check_box_text">Был невежлив</div>
                </label>
                {this.renderChildInputsOne()}
                <label className="check_box_text_holder">
                    <input
                        type="checkbox"
                        id='2'
                        value="Был неопрятен"
                        onChange={(e) => this.handleChangeBox(e, '2')}

                    />
                    <div className="check_box_text">Был неопрятен</div>
                </label>
                <label className="check_box_text_holder">
                    <input
                        type="checkbox"
                        id='3'
                        value="Был не в фирменной одежде"
                        onChange={(e) => this.handleChangeBox(e, '3')}

                    />
                    <div className="check_box_text">Был не в фирменной одежде</div>
                </label>
                <label className="check_box_text_holder">
                    <input
                        type="checkbox"
                        id='4'
                        value="Опоздал"
                        onChange={(e) => this.handleChangeBox(e, '4')}

                    />
                    <div className="check_box_text">Опоздал</div>
                </label>
                {this.renderChildInputsTwo()}
                <label className="check_box_text_holder">
                    <input
                        type="checkbox"
                        id='5'
                        value="Невнятно говорил"
                        onChange={(e) => this.handleChangeBox(e, '5')}

                    />
                    <div className="check_box_text">Невнятно говорил</div>
                </label>
                {this.renderSendCommentButton()}
            </div>
        )
    }

    sendCommentToServer = () => {
        let comment = ""
        let childComment = ""
        let childCommentTwo = ""

        for (let key in this.state.COMMENT_OBJ) {
            if (parseInt(key) < 6) {
                if (this.state.COMMENT_OBJ[key].status) {
                    if (comment === "") {
                        comment = this.state.COMMENT_OBJ[key].comment
                    } else {
                        comment = comment + ' , ' + this.state.COMMENT_OBJ[key].comment
                    }
                }
            } else {
                if (this.state.COMMENT_OBJ[key].status) {
                    if (parseInt(key) > 5 && parseInt(key) <= 8) {
                        if (childComment === "") {
                            childComment = this.state.COMMENT_OBJ[key].comment
                        } else {
                            childComment = childComment + ' , ' + this.state.COMMENT_OBJ[key].comment
                        }
                    }
                    if (parseInt(key) > 8) {
                        if (childCommentTwo === "") {
                            childCommentTwo = this.state.COMMENT_OBJ[key].comment
                        } else {
                            childCommentTwo = childCommentTwo + ' , ' + this.state.COMMENT_OBJ[key].comment
                        }
                    }
                }
            }
        }
        if (childComment !== "") {
            childComment = ('(' + this.state.COMMENT_OBJ['1'].comment + ' : ' + childComment + ')')
        }
        if (childCommentTwo !== "") {
            childCommentTwo = ('(' + this.state.COMMENT_OBJ['4'].comment + ' : ' + childCommentTwo + ')')
        }

        this.props.setBoxComment(comment + '(' + childComment + childCommentTwo + ')')
        this.props.closeBadRatingModal()
    }


    renderChildInputsOne = () => {
        if (this.state.COMMENT_OBJ['1'].status === true) {
            return (
                <div className="child_inputs">
                    <label className="check_box_text_holder">
                        <input
                            type="checkbox"
                            id='6'
                            value="По телефону"
                            onChange={(e) => this.handleChangeBox(e, '6')}

                        />
                        <div className="check_box_text">По телефону</div>
                    </label>
                    <label className="check_box_text_holder">
                        <input
                            type="checkbox"
                            id='7'
                            value="При встрече"
                            onChange={(e) => this.handleChangeBox(e, '7')}

                        />
                        <div className="check_box_text">При встрече</div>
                    </label>
                    <label className="check_box_text_holder">
                        <input
                            type="checkbox"
                            id='8'
                            value="Не помог занести пакеты"
                            onChange={(e) => this.handleChangeBox(e, '8')}
                        />
                        <div className="check_box_text">Не помог занести пакеты</div>
                    </label>
                </div>
            )
        }
    }

    renderChildInputsTwo = () => {

        if (this.state.COMMENT_OBJ['4'].status === true) {
            return (
                <div className="child_inputs">
                    <label className="check_box_text_holder">
                        <input
                            type="checkbox"
                            id='9'
                            value="от 1 до 30 минут"
                            onChange={(e) => this.handleChangeBox(e, '9')}

                        />
                        <div className="check_box_text">от 1 до 30 минут</div>
                    </label>
                    <label className="check_box_text_holder">
                        <input
                            type="checkbox"
                            id='10'
                            value="от 30 минут до 1 часа"
                            onChange={(e) => this.handleChangeBox(e, '10')}

                        />
                        <div className="check_box_text">от 30 минут до 1 часа</div>
                    </label>
                    <label className="check_box_text_holder">
                        <input
                            type="checkbox"
                            id='11'
                            value="на 1 час и более"
                            onChange={(e) => this.handleChangeBox(e, '11')}

                        />
                        <div className="check_box_text">на 1 час и более</div>
                    </label>
                    <label className="check_box_text_holder">
                        <input
                            type="checkbox"
                            id='12'
                            value="предупредил об опоздании"
                            onChange={(e) => this.handleChangeBox(e, '12')}

                        />
                        <div className="check_box_text">предупредил об опоздании</div>
                    </label>
                    <label className="check_box_text_holder">
                        <input
                            type="checkbox"
                            id='13'
                            value="не предупредил об опоздании"
                            onChange={(e) => this.handleChangeBox(e, '13')}

                        />
                        <div className="check_box_text">не предупредил об опоздании</div>
                    </label>
                </div>
            )
        }
    }

    renderSendCommentButton = () => {
        if (this.state.COMMENT) {
            return (
                <div style={{backgroundColor: "#43B02A"}} className="send_bad_comment_button"
                     onClick={() => {
                         this.sendCommentToServer();
                     }}>
                    Отправить ответы
                </div>
            )
        } else {
            return (
                <div style={{backgroundColor: "#979797"}} className="send_bad_comment_button">
                    Отправить ответы
                </div>
            )
        }

    }


    render() {
        return (
            <div>
                <div className='modal-overlay rating_overlay'>
                </div>
                <div className="modal-box bad_rating" id="mainModal">
                    <img className='close_cross_green' src="/system_images/green_cross_modal.png"
                         onClick={() => this.props.closeBadRatingModal()}/>
                    <div className='bad_rating_modal_holder'>
                        <div className='region_confirm'>
                            <div className='bad_rating_modal_header'>Вы поставили низкую оценку
                                курьеру.<br/> Скажите, пожалуйста,<br/> на что нам обратить внимание.
                            </div>
                            {this.renderCheckBoxes()}

                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

export default connect(
    mapStateToProps, mapDispatchToProps
)(BadRatingModal);