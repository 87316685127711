import React, {Component} from 'react';
import { YMaps, Map, Polygon, Rectangle, SearchControl, Placemark } from 'react-yandex-maps';


export default class PostalOffices extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            CENTER: props.CENTER,
            ZOOM: 13,
            OFFICES: props.OFFICES
        }

        this.MAP = false;
        this.MAP_REF = false;
    }

    componentDidMount = () =>
    {
        let COMPONENT = this;
		this.listener = setInterval(function(){
            if (window.pickpoint_selected) {
                window.pickpoint_selected = false;
                COMPONENT.props.closeOfficesSelect();
                clearInterval(COMPONENT.listener)
            }
        }, 200);
    }

    componentWillUnmount = () =>
    {
        if (this.listener) {
            clearInterval(this.listener)
        }
    }

    explode = ( delimiter, string ) =>
    {
        var emptyArray = { 0: '' };

        if ( delimiter === ''
            || delimiter === false
            || delimiter === null )
        {
            return false;
        }

        if ( typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object' )
        {
            return emptyArray;
        }

        if ( delimiter === true ) {
            delimiter = '1';
        }

        return string.toString().split ( delimiter.toString() );
    }

    setMap = (MAP) =>
    {
        this.MAP = MAP;
    }

    setMapRef = (MAP) =>
    {
        this.MAP_REF = MAP;
    }

    getYaKey()
    {
        let items = [
            '24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
            '486bc32f-60f4-4320-9d61-c8d14935e017',
            'c0e4e3d2-913b-4873-a81a-59ea899446f8',
            '64f61949-4219-4c76-9c63-a76af46cb444',
            'a45fc414-5133-407b-a440-91d028095f30',
            'ddb478df-6120-46a8-8331-ea67cefec68c'
        ];

        var item = items[Math.floor(Math.random()*items.length)];

        return item;
    }

    mapLoaded = (ymaps) =>
    {
        this.setMap(ymaps);
    }

    zoomIn = () =>
    {
        let ZOOM = this.MAP_REF.getZoom();
        this.MAP_REF.setZoom(ZOOM + 1);
    }

    zoomOut = () =>
    {
        let ZOOM = this.MAP_REF.getZoom();
        this.MAP_REF.setZoom(ZOOM - 1);
    }

    locateMe = () =>
    {
        let COMPONENT = this;
        navigator.geolocation.getCurrentPosition(
            function (position) {
                COMPONENT.MAP_REF.setCenter([ position.coords.latitude, position.coords.longitude]);
            }, function (error) {
                console.log(error);
            }, 
            {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 1000
            }
        );
    }

    renderMapButtons = () =>
    {
        if (typeof this.state.showButtons !== "undefined") {
            if (this.state.showButtons == "false") {
                return false;
            }
        }
        let BUTTON = '';
        if (navigator.geolocation) {
            BUTTON = (
                <a className="map_locate" onClick={() => this.locateMe()}><img src="/system_images/airplane.png" /></a>
            )
        }

        return (
            <div className="map_buttons">
                <div className="map_pm_btns">
                    <a className="map_plus" onClick={() => this.zoomIn()}>+</a>
                    <a className="map_minus" onClick={() => this.zoomOut()}>-</a>
                </div>
                {BUTTON}
            </div>
        );
    }

    placemarkClicked = (placemark) =>
    {
        this.props.pickPointSelected(placemark);
    }

    buildBalloontemplate = () =>
    {
    }

    renderPoints = () =>
    {
        let placemarks = [];
        for (let i = 0; i < this.props.OFFICES.length;i++) {
            let placemark = this.props.OFFICES[i];


            let balloon_data = '<div class="placemark_balloon_header"><img src="/system_images/' + this.props.ICON + '"/><span>' + this.props.TITLE + ' (' + placemark.id  + ')</span></div>';
            balloon_data += '<div class="placemark_balloon_adress">Адрес: ' + placemark.adress + '</div>';
            balloon_data += '<div class="placemark_balloon_text">Способ оплаты: банковской картой</div>';
            balloon_data += '<div class="placemark_balloon_text">График работы: ' + placemark.schedule + '</div>';
            balloon_data += '<div class="placemark_balloon_footer"><a class="placemark_balloon_button" onclick="window.pickpoint_selected = true;">Выбрать пункт выдачи</a></div>';

            placemarks.push(
                <Placemark 
                    key={i}
                    geometry={[placemark.lat, placemark.lon]} 
                    properties={{
                        closeButton: false,
                        balloonContent: balloon_data
                    }}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: '/system_images/' + this.props.PLACEMARK_ICON,
                        iconImageSize: [30, 40],
                        iconImageOffset: [-15, -40],

                        hideIconOnBalloonOpen: false,
                        closeButton: false
                    }}
                    onClick={() => this.placemarkClicked(placemark)}
                />
            )
        }

        return placemarks;
    }

    renderMessage = () =>
    {
        return (
            <>
            <div className='map-back-button'  onClick={() => this.props.closeOfficesSelect()}>
                <img className='map-back-button-size' src='/system_images/MapBackButton.png'></img>
            </div>
            <div className="delivery_office_message">
                Выберите пункт выдачи:
            </div>
            </>
        )
    }

    render() {
        return (
            <YMaps 
                query={{
                    apikey: this.getYaKey(),
                    load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,control.SearchControl'
                }}
            >
                <Map 
                    className="delivery_points"
                    instanceRef={map => this.setMapRef(map)}
                    defaultState={{
                        center: this.state.CENTER,
                        zoom: this.state.ZOOM,
                        controls: []
                    }}
                    onLoad={(ymaps) => this.mapLoaded(ymaps)}
                >
                    <div className="white_map_top_fader"></div>
                    {this.renderMessage()}
                    {this.renderPoints()}
                </Map>
            </YMaps>
        );
    }

}