import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import PayForm from './../../APP/COMPONENTS/ContentParts/PayForm';

function mapStateToProps(state) {
    return {
        NOT_FOUND_MODAL: state.NOT_FOUND_MODAL,
        TOKEN: state.TOKEN,
        USER: state.USER,
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class NotFound extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        let APIKEY = 'UNREGISTERED';
        if (this.props.USER) {
            APIKEY = this.props.USER.TOKEN;
        }
        this.state = {
            APIKEY: APIKEY,
            TOKEN: props.TOKEN,
            ID: props.NOT_FOUND_MODAL,
            TEXT: '',
            SUGGESTIONS: false,
            RESULTS: false
        };

        this.OFFSET = 0;
        this.setHistoryRef = this.setHistoryRef.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setHistoryRef(node) {
        this.history = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.NOT_FOUND_MODAL !== this.state.ID) {
            if (window.innerWidth < 1200) {
                if (!this.props.NOT_FOUND_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
            if (this.props.NOT_FOUND_MODAL !== false) {
                this.setState({ID: this.props.NOT_FOUND_MODAL, TEXT: '', SUGGESTIONS: false, RESULTS: false});
            } else {
                this.setState({ID: false, TEXT: ''});
            }
        }

        if (this.props.NOT_FOUND_MODAL !== prevProps.NOT_FOUND_MODAL) {
            this.setState({TOKEN: this.props.TOKEN});
        }

        if (this.props.USER !== prevProps.USER) {
            let APIKEY = 'UNREGISTERED';
            if (this.props.USER) {
                APIKEY = this.props.USER.TOKEN;
            }
            this.setState({APIKEY: APIKEY});
        }
    }

    filterProducts = async (text) => 
    {
        

        if (this.props.PRODUCTS) {
            try {
                let SEND_DATA = {
                    action:     'searchString',
                    string:     text
                };

                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                if (!this.STOP_SEARCHING) {
                    if (data !== false) {
                        if (data.results.length) {
                            this.setSearchResults(data.results, text);
                        } else {
                            this.setState({RESULTS: [], SUGGESTIONS: false});
                        }
                    } else {
                        this.setState({RESULTS: [], SUGGESTIONS: false});
                    }
                } else {
                    this.STOP_SEARCHING = false;
                }
            } catch (error) {
                this.setState({RESULTS: [], SUGGESTIONS: false});
            }
            
        }
    }

    saveData = async () =>
    {
        if (this.state.TEXT.length > 3) {
            let SEND_DATA = {
                action:         'recordNotFound',
                text:           this.state.TEXT,
                id:             this.state.ID,
                token:          this.state.TOKEN,
                apikey:         this.state.APIKEY
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({TEXT: ''});
                this.props.openNotFounModal(false)
            }
        }
    }


    recordText = (text) => 
    {
        let STRING = text.target.value;

        if (STRING.length > 1) {
            this.setState({TEXT: STRING});
            this.filterProducts(STRING);
        } else {
            this.setState({SUGGESTIONS: false, RESULTS: false, TEXT: STRING})
        }    
    }

    setSearchResults(IDS, text)
    {
        let PRODUCTS = this.props.PRODUCTS;
        let FOUND = [];
        let COMPONENT = this;

        FOUND.push(
            <div className="head" key="main_one">Возможно Вы искали:</div>
        );

        let MAX = 5;
        for (let i =0; i < IDS.length; i++) {
            let ID = IDS[i];
            if (typeof PRODUCTS[ID] !== "undefined") {
                let PRODUCT = PRODUCTS[ID];
                if (FOUND.length <= MAX) {
                    FOUND.push(
                        <Link to={"/" + PRODUCT.url} key={PRODUCT.id} className="search-result-row">
                            {PRODUCT.title}
                        </Link>
                    );
                }
            }
        }


        this.setState({RESULTS: FOUND, SUGGESTIONS: true});
    }

    onFocus = () =>
    {
        this.setState({SUGGESTIONS: true});
    }

    onFocusOut = () =>
    {
        this.setState({SUGGESTIONS: false});
    }

    renderResults()
    {
        if (this.state.SUGGESTIONS) {
            if (this.state.RESULTS) {
                return (
                    <div className="search" ref={this.setHistoryRef}>
                        {this.state.RESULTS}
                    </div>
                );
            }
        }
    }

    renderButton = () =>
    {
        if (this.state.TEXT.length > 3) {
            return (
                <a className="green_done" onClick={() => this.saveData()}>
                    Отправить
                </a>
            );
        } else {
            return (
                <a className="orange-button inactive-btn">
                    Отправить
                </a>
            );
        }
    }

	render()
    {
        if (this.state.ID !== false) {
            return (
                <div>
                    <div className="modal-overlay" onClick={() => this.props.openNotFounModal(false)}>
                    </div>
                    <div className="modal-box not-found-modal">
                        <a className="close-modal" onClick={() => this.props.openNotFounModal(false)}>
                            <img src="/system_images/close.png" />
                        </a>
                        <div className="modal_content">
                            <h3>Какой товар вы искали?</h3>
                            <textarea className="not_found_textarea" placeholder="Наименование товара" onChange={(text) => this.recordText(text)}></textarea>
                            <div className="not_found_searching">
                                {this.renderResults()}
                            </div>
                            <div className="text-center">
                                {this.renderButton()}
                            </div>                            
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(NotFound);