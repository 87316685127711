import React, {Component} from 'react';
import CanvasJSReact from './../../../canvasjs.react';
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class TheLine extends Component {
	constructor(props)
    {
        super(props);
        this.state = {
            data: props.data,
            ed_izm: props.ed_izm
        }

        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }

	
    prepareData = () =>
    {
    	let DATA = [];

    	for (let i = 0; i < this.state.data.length; i++) {
    		let ROW = this.state.data[i];
    		DATA.push({
                x: new Date(ROW.datetime), 
                y: parseFloat(ROW.price),
                doc_number: ROW.doc_number,
                supplier: ROW.supplier,
                amount: ROW.amount,
                edizm: this.props.edizm
            });
    	}

    	return DATA;
    }

    prepareData2 = () =>
    {
    	let DATA = [];

    	for (let i = 0; i < this.state.data.length; i++) {
    		let ROW = this.state.data[i];
    		DATA.push({
                x: new Date(ROW.datetime), 
                y: parseFloat(ROW.sale_price),
                doc_number: ROW.doc_number,
                supplier: ROW.supplier,
                amount: ROW.amount,
                edizm: this.props.edizm
            });
    	}

    	return DATA;
    }

    toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
		const options = {
			theme: "light2",
            animationEnabled: true,
            exportEnabled: true,
			title: {
				text: 'Статистика закупок: ' + this.props.product
			},
            axisX: {
				title: "Приходы",
                valueFormatString: 'DD MM YYYY'
			},
            toolTip: {
				shared: true,
                contentFormatter: function(e){
                    let doc_number = e.entries[0].dataPoint.doc_number
                    let supplier = e.entries[0].dataPoint.supplier
                    let datetime = e.entries[0].dataPoint.x
                    let amount = e.entries[0].dataPoint.amount
                    let edizm = e.entries[0].dataPoint.edizm

                    var str = '<strong>' + doc_number + '</strong><br/>';
                    var sup = supplier + '<br/>';
                    str = str.concat(sup);
                    var date = '<small>' + datetime.toLocaleDateString('ru-RU') + '</small><br/>';
                    str = str.concat(date);
                    var amount_row = '<small>' + amount + ' ' + edizm + '</small><br/>';
                    str = str.concat(amount_row);
                    for (var i = 0; i < e.entries.length; i++){
                      var  temp = "<span style='color: " + e.entries[i].dataSeries.markerColor + "'>" + e.entries[i].dataSeries.name + " <strong>"+  e.entries[i].dataPoint.y + "</strong></span><br/>" ; 
                      str = str.concat(temp);
                    }
                    return (str);
                }
			},
			legend: {
				cursor: "pointer",
				itemclick: this.toggleDataSeries
			},
			data: [
                {
                    type: "spline",
                    showInLegend: "true",
                    name: "Закупочная цена",
                    xValueFormatString: "DD MM YYYY HH:mm",
                    dataPoints: this.prepareData(),
                    lineThickness: 2,
                    markerColor: 'green',
                    lineColor: 'green'
                },
                {
                    type: "spline",
                    showInLegend: "true",
                    name: "Продажная цена",
                    xValueFormatString: "DD MM YYYY HH:mm",
                    dataPoints: this.prepareData2(),
                    lineThickness: 2,
                    markerColor: 'red',
                    lineColor: 'red'
                }
            ]
		}

		return (
			<div>
				<CanvasJSChart options={options} onRef={ref => this.chart = ref} />
			</div>
		)
	}
}