import React, {Component} from 'react';
import InputMask from "react-input-mask";

export default class StarsInput extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            FIELD: props.field,
            STARS: 0,
            label: props.label,
        }
    }


    updateStars = (e) => {
        if(e.target.checked) {
           this.setState({STARS: e.target.value});
           this.props.setField(this.state.FIELD,e.target.value);
        }
    }

    renderStar(integer)
    {
        if (parseInt(this.state.STARS) >= parseInt(integer)) {
            return <i className="fa fa-star"></i>;
        } else {
            return <i className="fa fa-star-o"></i>;
        }
    }

    render()
    {
        return (
            <div className="anketa_stars">
                <span>{this.state.label}</span>
                <div className="stars">
                    <input className="star star-5" id={this.state.FIELD + "star-5"} type="radio" name="star" value="5" onChange={this.updateStars}/ >
                    <label className="star star-5" htmlFor={this.state.FIELD + "star-5"}>
                        {this.renderStar(5)}
                    </label>

                    <input className="star star-4" id={this.state.FIELD + "star-4"} type="radio" name="star" value="4" onChange={this.updateStars}/ >
                    <label className="star star-4" htmlFor={this.state.FIELD + "star-4"}>
                        {this.renderStar(4)}
                    </label>

                    <input className="star star-3" id={this.state.FIELD + "star-3"} type="radio" name="star" value="3" onChange={this.updateStars}/ >
                    <label className="star star-3" htmlFor={this.state.FIELD + "star-3"}>
                        {this.renderStar(3)}
                    </label>

                    <input className="star star-2" id={this.state.FIELD + "star-2"} type="radio" name="star" value="2" onChange={this.updateStars}/ >
                    <label className="star star-2" htmlFor={this.state.FIELD + "star-2"}>
                        {this.renderStar(2)}
                    </label>

                    <input className="star star-1" id={this.state.FIELD + "star-1"} type="radio" name="star" value="1" onChange={this.updateStars}/ >
                    <label className="star star-1" htmlFor={this.state.FIELD + "star-1"}>
                        {this.renderStar(1)}
                    </label>
                </div>
            </div>
        );
    }
}
