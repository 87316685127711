import React, {Component} from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';


function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class RefEntrance extends Component {

	constructor(props)
    {
        super(props);
        this.props.setRefCode(props.match.params.reflink);
    }

	render() {
		return (
			<CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <Redirect to='/' />
			</CSSTransitionGroup>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(RefEntrance)
