import React, {Component} from 'react';
import {Switch, Route, Link, withRouter, NavLink} from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import MobileCart from './../../../APP/COMPONENTS/HeaderParts/MobileCart';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Tops extends Component {

    constructor(props) {
        super(props);
        this.state = {
            MARGIN_TOP: 0,
            HIDE: false
        }

        this.SCROLLING = false;
        this.PREV = 0;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
    }

    getClass() {
        if (this.state.HIDE) {
            return 'tops hide_top';
        } else {
            return 'tops';
        }
    }

    renderBlogerBadge = () => {
        if (this.props.USER) {
            if ((parseInt(this.props.USER.STATUS) == 4) || (parseInt(this.props.USER.STATUS) == 6)) {
                return <img src="/system_images/blogger/bloger.png" className="mobile_bloger_badge"/>
            }
        }
    }

    renderUser() {
        let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/tabs/white-user.png';

        if (this.props.USER) {
            return (
                <div className="user_mobile" onClick={() => this.props.openLK(true)}>
                    <img src={IMAGE_SRC}/>
                    <div className="i-flocktory" data-fl-user-name={this.props.USER.NAME}
                         data-fl-user-email={this.props.USER.PHONE.replace(/\D/g, "") + '@unknown.email'}></div>
                    {this.renderBlogerBadge()}
                </div>
            );
        } else {
            return (
                <div className="user_mobile" onClick={() => this.props.openLK(true)}>
                    <img src={IMAGE_SRC}/>
                    {this.renderBlogerBadge()}
                </div>
            );
        }

    }

    render() {
        let CLASSES = this.getClass()
        return (
            <div className={CLASSES}>
                <div className="tops_top">
                    {this.renderUser()}

                    <MobileCart/>
                </div>

                <div className="tops_logo">
                    <Link to={"/"}>ECOMARKET.RU</Link>
                </div>


            </div>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Tops))
