import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';


import ProductCard from './../../../APP/COMPONENTS/ContentParts/ProductCard';

import { ListManager } from "react-beautiful-dnd-grid";


export default class TheDragBlock extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	PRODUCTS: props.PRODUCTS
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {

    }


    dragEnd = (old_index,new_index) =>
    {

        const items = Array.from(this.state.PRODUCTS);
        const [reorderedItem] = items.splice(old_index, 1);
        items.splice(new_index, 0, reorderedItem);

        this.setState({PRODUCTS: items})
        this.props.setNewOrder(items)

    }

                        


	render() {
        return (
            <div className="draggable_holder">
            <ListManager
                items={this.state.PRODUCTS}
                direction="horizontal"
                maxItems={5}
                render={id => <ProductCard ID={id} />}
                onDragEnd={this.dragEnd}
            />
            </div>
        );
	}
}