import React, { Component } from 'react';
import { Link, withRouter, Location } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

import Cookies from 'universal-cookie';



import SelectSearch, { fuzzySearch } from 'react-select-search';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import OrderingProducts from './../../APP/COMPONENTS/ContentParts/OrderingProducts.js';
import OrderingConfirmation from './../../APP/COMPONENTS/ContentParts/OrderingConfirmation.js';
import { id } from 'date-fns/locale';

const cookies = new Cookies();

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        TOKEN: state.TOKEN,
        SUPPLIERS: state.SUPPLIERS,
        MANAGERS: state.MANAGERS,
        CATS: state.CATS,
        USER: state.USER,
        MANAGER_CHANGED: state.MANAGER_CHANGED
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Ordering extends Component {

    constructor(props) {
        super(props);

        let ALL_VIEWS = {
            amount: { title: 'остаток', show: false, sortable: true },
            sales_10: { title: 'продажи 10 дней', show: false, sortable: true },
            on_site: { title: 'отсутствует на сайте', show: false, sortable: true },
            planned: { title: 'ожидаемая поставка', show: false, sortable: true },
            minimum_quantity: { title: 'Мин кол-во на складе', show: false },
            maximum_quantity: { title: 'Макс кол-во для дозаказа', show: false },
            order_days: { title: 'заказ на дней', show: false },
            order_quant: { title: 'квант в заказе', show: false },
            to_order: { title: 'заказать', show: false },
            order_from_days: { title: 'заказ от дней', show: false },
            order_sum: { title: 'сумма заказа', show: false },
            order_confirm: { title: 'подтверждение заказа', show: false },
            supplier: { title: 'поставщик', show: false, sortable: true },
            till_when: { title: 'до скольки оформить и дни недели', show: false },
            sup_incomings: { title: 'поставки от поставщика', show: false },
            barcode: { title: 'штрихкод', show: false },
            sup_price: { title: 'послед закуп цена', show: false, sortable: true },
            writeoffs_30: { title: 'списания за 10 дней', show: false, sortable: true },
            group: { title: 'категория товара', show: false },
            /// ??? autoorder: {title: 'автозаказ', show: false},
            coef: { title: 'квант', show: false },
            /// ??? art: {title: 'артикул', show: false},
            expiration: { title: 'срок годности', show: false },
            manager: { title: 'менеджер', show: false },
            left_sum: { title: 'сумма остатков', show: false },
            marge: { title: 'маржа', show: false, sortable: true },
            sup_nom: { title: 'номеклатура поставщика', show: false },

            change_data_percent: { title: 'процент изменения цены', show: false },
            change_data_start_price: { title: 'мин цена', show: false },
            change_data_end_price: { title: 'макс цена', show: false },
            change_data_change_sum: { title: 'разница за 30 дней', show: false },
            change_data_supplier: { title: 'поставщик', show: false },
        }
        let VIEWS = {};
        for (let i in ALL_VIEWS) {
            VIEWS[i] = true//ALL_VIEWS[i].show
        }

        let LOCAL_VIEWS = this.getLocalViews()


        this.state = {
            SKU: [],
            FILTER: {
                TITLE: '',
                MANAGER: this.getManagerFromQuery(),
                SUPPLIER: '',
                GROUP: 0,
                UNCONFIRMED: false,
                TIMEDOUT: false,
                TOORDER: false,
                ORGANIC: false,
                IMPORTANT: false,
                TOPACK: false,
                SEASON: false,
                USE_INCOMING_DATES: true,
                ORDER_DAY: false,
                HIDDEN: false
            },
            VIEW: LOCAL_VIEWS ? LOCAL_VIEWS : VIEWS,
            ALL_VIEWS: ALL_VIEWS,
            SELECTED: {},
            SUPPLIERS: false,
            SHOW_ORDER_CONFIRMATION: false,
            SUPPLIERS_OPTIONS: [],
            DAY_NUMBER: this.getDayNumber(),
            SPECIAL_ITEMS: {}
        }

        this.prepareSelectedObject()

    }

    getManagerFromQuery = () => {
        let manager = this.get('manager')
        if (manager) {
            return manager;
        }

        return '';
    }

    get(name) {
        if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(window.location.search))
            return decodeURIComponent(name[1]);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.PRODUCTS == false && this.props.PRODUCTS !== false) {
            this.prepareSelectedObject()
        }

        if (prevProps.MANAGER_CHANGED !== this.props.MANAGER_CHANGED) {
            let manager = this.getManagerFromQuery();
            this.selectManager(manager)
        }
    }

    componentDidMount() {
        this.getSuppliers()
        this.prepareSelectedObject()
        this.props.changeOrderingView(true)
    }

    componentWillUnmount() {
        this.props.changeOrderingView(false)
    }

    getDayNumber = () => {
        let now = new Date();
        let day_number = now.getDay();

        if (day_number == 0) {
            return 7;
        }

        return day_number;
    }

    getSupplierOptions = (SUPPLIERS) => {
        let options = [{ value: 0, name: '1', key: 'def' }];
        let added = []

        if (typeof SUPPLIERS !== "undefined" && SUPPLIERS) {
            for (let i = 0; i < SUPPLIERS.length; i++) {
                let option = {
                    key: SUPPLIERS[i].id,
                    name: SUPPLIERS[i].title + ' |' + SUPPLIERS[i].id,
                    value: SUPPLIERS[i].title + ' |' + SUPPLIERS[i].id,
                };
                if (!added.includes(SUPPLIERS[i].id)) {
                    options.push(option)
                    added.push(SUPPLIERS[i].id);
                }
            }
        }
        this.setState({ SUPPLIERS_OPTIONS: options });
    }

    readConfirmedIds = () => {
        let CONFIRMED_IDS = cookies.get('CONFIRMED_IDS');
        if (CONFIRMED_IDS !== null && typeof CONFIRMED_IDS !== "undefined") {
            return CONFIRMED_IDS
        }

        return []
    }

    prepareSelectedObject = () => {
        let CONFIRMED_IDS = this.readConfirmedIds()

        let SELECTED = Object.assign({}, {})
        for (let pid in this.props.PRODUCTS) {
            if (this.props.PRODUCTS[pid].days_toorder > 0) {
                let use_amount = this.countNeededAmount(
                    pid,
                    this.props.PRODUCTS[pid].days_toorder,
                    this.props.PRODUCTS[pid].order_quant,
                    this.props.PRODUCTS[pid].order_from_days
                )
                SELECTED[pid] = {
                    days: this.props.PRODUCTS[pid].days_toorder,
                    amount: use_amount,
                    confirmed: CONFIRMED_IDS.includes(pid),
                    order_from_days: this.props.PRODUCTS[pid].order_from_days,
                    order_quant: this.props.PRODUCTS[pid].order_quant,
                }
            }
        }

        this.setState({ SELECTED: SELECTED })
    }

    getLocalViews = () => {
        let VIEW = localStorage.getItem('VIEW');
        if (VIEW !== null) {
            return JSON.parse(VIEW);
        }

        return false
    }

    selectGroup = (value) => {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER.GROUP = value;

        this.setState({ FILTER: FILTER })
    }

    renderGroupSelect = () => {
        let options = [];
        let added = []
        if (typeof this.props.CATS !== "undefined") {
            for (let i = 0; i < this.props.CATS.length; i++) {
                for (let j = 0; j < this.props.CATS[i].children.length; j++) {
                    let option = { name: this.props.CATS[i].children[j].title, value: this.props.CATS[i].children[j].id };
                    if (!added.includes(this.props.CATS[i].children[j].id)) {
                        options.push(option)
                        added.push(this.props.CATS[i].children[j].id);
                    }
                }
            }
        }

        return (
            <div className="select_search_holder">
                <SelectSearch
                    filterOptions={ fuzzySearch }
                    search={ true }
                    id="group"
                    value={ this.state.FILTER.GROUP }
                    name="group"
                    options={ options }
                    placeholder="Категория"
                    onChange={ (val) => this.selectGroup(val) }
                />
                <img className="bangled" src="/system_images/blue_angle_down.png" />
                { this.state.FILTER.GROUP.length > 0 ? (
                    <a className="reset_search_select" onClick={ () => this.selectGroup('') }>
                        <img src="/system_images/green_cross_modal.png" />
                    </a>
                ) : false }
            </div>
        )
    }

    searchTitle = (value) => {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER.TITLE = value;

        this.setState({ FILTER: FILTER })
    }

    selectManager = (value) => {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER.MANAGER = value;

        this.setState({ FILTER: FILTER })
    }

    renderManagerSelect = () => {
        let options = [];
        let added = []
        if (typeof this.props.MANAGERS !== "undefined") {
            for (let i in this.props.MANAGERS) {
                if (this.props.MANAGERS[i].products > 30) {
                    let option = { name: this.props.MANAGERS[i].name, value: this.props.MANAGERS[i].name };
                    if (!added.includes(this.props.MANAGERS[i].name)) {
                        options.push(option)
                        added.push(this.props.MANAGERS[i].name);
                    }
                }
            }
        }

        return (
            <div className="select_search_holder">
                <SelectSearch
                    filterOptions={ fuzzySearch }
                    search={ true }
                    id="manager"
                    value={ this.state.FILTER.MANAGER }
                    name="manager"
                    options={ options }
                    placeholder="Менеджер"
                    onChange={ (val) => this.selectManager(val) }
                />
                <img className="bangled" src="/system_images/blue_angle_down.png" />
                { this.state.FILTER.MANAGER.length > 0 ? (
                    <a className="reset_search_select" onClick={ () => this.selectManager('') }>
                        <img src="/system_images/green_cross_modal.png" />
                    </a>
                ) : false }
            </div>
        )
    }

    selectSupplier = (value) => {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER.SUPPLIER = value;

        this.setState({ FILTER: FILTER })
    }

    renderSupplierSelect = () => {
        let options = [];
        let added = []

        if (typeof this.state.SUPPLIERS !== "undefined" && this.state.SUPPLIERS.length) {
            for (let i = 0; i < this.state.SUPPLIERS.length; i++) {
                let option = { name: this.state.SUPPLIERS[i].title, value: this.state.SUPPLIERS[i].title };
                if (!added.includes(this.state.SUPPLIERS[i].title)) {

                    let toAdd = true;

                    if (this.state.FILTER.MANAGER.length) {
                        if (this.state.FILTER.MANAGER !== this.state.SUPPLIERS[i].manager) {
                            toAdd = false;
                        }
                    }

                    if (toAdd) {
                        options.push(option)
                        added.push(this.state.SUPPLIERS[i].title);
                    }
                }
            }
        }

        return (
            <div className="select_search_holder">
                <SelectSearch
                    filterOptions={ fuzzySearch }
                    search={ true }
                    id="supplier"
                    value={ this.state.FILTER.SUPPLIER }
                    name="supplier"
                    options={ options }
                    placeholder="Поставщик"
                    onChange={ (val) => this.selectSupplier(val) }
                />
                <img className="bangled" src="/system_images/blue_angle_down.png" />
                { this.state.FILTER.SUPPLIER.length > 0 ? (
                    <a className="reset_search_select" onClick={ () => this.selectSupplier('') }>
                        <img src="/system_images/green_cross_modal.png" />
                    </a>
                ) : false }
            </div>
        )
    }

    selectOrderDay = (value) => {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER.ORDER_DAY = value;

        this.setState({ FILTER: FILTER }, () => this.prepareSelectedObject())
    }

    renderOrderDaySelect = () => {
        let options = [
            { value: 1, name: 'Пн' },
            { value: 2, name: 'Вт' },
            { value: 3, name: 'Ср' },
            { value: 4, name: 'Чт' },
            { value: 5, name: 'Пт' },
            { value: 6, name: 'Сб' },
            { value: 7, name: 'Вс' }
        ]

        return (
            <div className="select_search_holder">
                <SelectSearch
                    id="order_day"
                    value={ this.state.FILTER.ORDER_DAY }
                    name="order_day"
                    options={ options }
                    placeholder="День заказа"
                    onChange={ (val) => this.selectOrderDay(val) }
                />
                <img className="bangled" src="/system_images/blue_angle_down.png" />
                { this.state.FILTER.ORDER_DAY > 0 ? (
                    <a className="reset_search_select" onClick={ () => this.selectOrderDay(false) }>
                        <img src="/system_images/green_cross_modal.png" />
                    </a>
                ) : false }
            </div>
        )
    }

    setSpecialCounting = (pid, sold, days) => {
        let SPECIAL_ITEMS = Object.assign({}, { ...this.state.SPECIAL_ITEMS })

        SPECIAL_ITEMS[pid] = {
            sold: sold,
            days: days
        }

        this.setState({ SPECIAL_ITEMS: SPECIAL_ITEMS }, () => this.prepareSelectedObject())
    }

    countNeededAmount = (pid, days_amount, order_quant, order_from_days) => {
        if (typeof this.props.PRODUCTS[pid] !== "undefined") {
            let PRODUCT = this.props.PRODUCTS[pid]

            let countNeeded = true;
            if (this.state.FILTER.USE_INCOMING_DATES) {
                if (PRODUCT.incoming_date && PRODUCT.incoming_date >= this.getTodayDate()) {
                    countNeeded = false
                }
            }

            if (countNeeded) {

                /// Needed for days (4days + daysTill)
                let REAL_DAYS = parseInt(days_amount) + parseInt(order_from_days);

                /// Modify if one order day and one delivery day
                let order_days = PRODUCT.order_days.split(",");
                let delivery_days = PRODUCT.delivery_days.split(",");

                /// If today is not order day
                if (order_days.length) {
                    if (this.state.FILTER.ORDER_DAY > 0) {
                        if (!order_days.includes(this.state.FILTER.ORDER_DAY.toString())) {
                            return 0
                        }
                    } else {
                        if (!order_days.includes(this.state.DAY_NUMBER.toString())) {
                            return 0
                        }
                    }
                }

                /// if deliver and order once a week
                if (order_days.length == 1 && delivery_days.length == 1) {
                    REAL_DAYS = 9
                }

                /// Sold per day
                let PER_DAY = PRODUCT.sales_10 / 10;

                if (typeof this.state.SPECIAL_ITEMS[pid] !== "undefined") {
                    PER_DAY = this.state.SPECIAL_ITEMS[pid].sold / this.state.SPECIAL_ITEMS[pid].days;
                }

                /// WriteOffs per day
                let WO_PER_DAY = PRODUCT.writeoffs / PRODUCT.pure_sup_price / 10;
                if (WO_PER_DAY > 0) {
                    WO_PER_DAY = 0;
                }

                /// Real amount per day
                let RA_PER_DAY = PER_DAY + WO_PER_DAY;

                /// Real neeeded amount
                let CUR_AMOUNT = Math.round(REAL_DAYS * RA_PER_DAY - PRODUCT.real_amount);

                if (pid == 9311) {
                    // console.log('REAL DAYS: ' + REAL_DAYS)
                    // console.log('RA_PER_DAY: ' + RA_PER_DAY)
                    // console.log('CUR_AMOUNT: ' + CUR_AMOUNT)
                }

                if (CUR_AMOUNT > 0) {
                    CUR_AMOUNT += parseFloat(PRODUCT.minimum_quantity)
                } else if (parseFloat(PRODUCT.real_amount) < parseFloat(PRODUCT.minimum_quantity)) {
                    CUR_AMOUNT = parseFloat(PRODUCT.minimum_quantity) - parseFloat(PRODUCT.real_amount);
                }

                if (order_quant > 0) {
                    let full_count = CUR_AMOUNT / order_quant;
                    let packed = Math.floor(full_count)

                    if (full_count - packed > 0) {
                        CUR_AMOUNT = (packed + 1) * order_quant;
                    }
                }


                if (CUR_AMOUNT > 0) {
                    return CUR_AMOUNT
                }
            }
        }
        return 0;
    }

    toggleConfirmation = (id) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)

        if (typeof SELECTED[id] !== "undefined") {
            SELECTED[id].confirmed = !SELECTED[id].confirmed;
        }

        this.setState({ SELECTED: SELECTED }, () => this.recordConfirmed())
    }

    unConfirmCurrentSelection = (ids) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)

        for (let i = 0; i < ids.length; i++) {
            let id = ids[i]

            if (typeof this.state.SELECTED[id] !== "undefined") {
                let amount = this.countNeededAmount(
                    id,
                    this.props.PRODUCTS[id].days_toorder,
                    this.state.SELECTED[id].order_quant,
                    this.state.SELECTED[id].order_from_days
                )
                if (amount > 0) {
                    SELECTED[id].confirmed = false
                }
            }
        }

        this.setState({ SELECTED: SELECTED }, () => this.recordConfirmed())
    }

    getTodayDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;

        return today
    }

    confirmCurrentSelection = (ids) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)

        for (let i = 0; i < ids.length; i++) {
            let id = ids[i]

            if (typeof SELECTED[id] !== "undefined") {
                let amount = this.countNeededAmount(
                    id,
                    this.props.PRODUCTS[id].days_toorder,
                    SELECTED[id].order_quant,
                    SELECTED[id].order_from_days
                )
                if (amount > 0) {
                    SELECTED[id].confirmed = true
                }
            }
        }

        this.setState({ SELECTED: SELECTED }, () => this.recordConfirmed())
    }

    setSelectedAmount = (id, amount) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)

        if (amount == 0 && typeof SELECTED[id] !== "undefined") {
            this.recordDaysAndAmountToBack(id, 0);

            delete SELECTED[id]
        } else if (amount > 0) {

            this.recordDaysAndAmountToBack(id, amount);

            if (typeof SELECTED[id] !== "undefined") {
                let use_amount = this.countNeededAmount(
                    id,
                    amount,
                    this.state.SELECTED[id].order_quant,
                    this.state.SELECTED[id].order_from_days
                )

                SELECTED[id].days = amount;
                SELECTED[id].amount = use_amount;
            } else {
                let PRODUCT = this.props.PRODUCTS[id]

                let use_amount = this.countNeededAmount(
                    id,
                    amount,
                    PRODUCT.order_quant,
                    PRODUCT.order_from_days
                )

                SELECTED[id] = {
                    days: amount,
                    amount: use_amount,
                    confirmed: false,
                    supplier_title: PRODUCT.supplier,
                    order_quant: PRODUCT.order_quant,
                    order_from_days: PRODUCT.order_from_days
                }
            }
        }

        this.setState({ SELECTED: SELECTED })
    }

    setSelectedAmountValue = (id, amount) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)

        if (amount == 0 && typeof SELECTED[id] !== "undefined") {
            this.recordDaysAndAmountToBack(id, 0);

            delete SELECTED[id]
        } else if (amount > 0) {
            if (typeof SELECTED[id] !== "undefined") {
                SELECTED[id].amount = amount;
                SELECTED[id].confirmed = true;
            } else {
                this.recordDaysAndAmountToBack(id, 1);
                let PRODUCT = this.props.PRODUCTS[id]

                SELECTED[id] = {
                    days: 1,
                    amount: amount,
                    confirmed: true,
                    supplier_title: PRODUCT.supplier,
                    order_quant: PRODUCT.order_quant,
                    order_from_days: PRODUCT.order_from_days
                }
            }
        }

        this.setState({ SELECTED: SELECTED })
    }

    setQuantToOrdering = (id, order_quant) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)

        if (typeof SELECTED[id] !== "undefined") {
            this.recordQuantAmount(id, order_quant);

            let use_amount = this.countNeededAmount(
                id,
                SELECTED[id].days,
                order_quant,
                SELECTED[id].order_from_days
            )

            SELECTED[id].order_quant = order_quant;
            SELECTED[id].amount = use_amount;

            this.setState({ SELECTED: SELECTED })
        }
    }

    setOrderFromDaysToOrdering = (id, order_from_days) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)

        if (typeof SELECTED[id] !== "undefined") {
            this.recordOrderFromDaysAmount(id, order_from_days);

            let use_amount = this.countNeededAmount(
                id,
                SELECTED[id].days,
                SELECTED[id].order_quant,
                order_from_days
            )

            SELECTED[id].order_from_days = order_from_days;
            SELECTED[id].amount = use_amount;

            this.setState({ SELECTED: SELECTED })
        }
    }

    setAnotherSupplierToOrdering = (ids, supplier) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)
        let supplier_parts = supplier.split(' |');
        supplier = supplier_parts[0]

        for (let i = 0; i < ids.length; i++) {
            let pid = ids[i]
            if (typeof SELECTED[pid] !== "undefined") {
                let ANOTHER_SELECTED = Object.assign({}, SELECTED[pid])
                ANOTHER_SELECTED.supplier_title = supplier;

                SELECTED[pid] = ANOTHER_SELECTED
            }
        }

        this.setState({ SELECTED: SELECTED })
    }

    setSupplierToOrdering = (id, supplier) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)

        let supplier_parts = supplier.split(' |');
        supplier = supplier_parts[0]

        if (typeof SELECTED[id] !== "undefined") {
            SELECTED[id].supplier_title = supplier
        } else {
            let PRODUCT = this.props.PRODUCTS[id]
            SELECTED[id] = {
                days: 1,
                amount: 1,
                confirmed: false,
                supplier_title: supplier,
                order_quant: PRODUCT.order_quant,
                order_from_days: PRODUCT.order_from_days
            }
        }

        this.setState({ SELECTED: SELECTED })
    }

    recordOrderFromDaysAmount = async (id, order_from_days) => {
        try {
            let SEND_DATA = {
                action: 'admin_recordOrderFromDaysToOrder',
                id: id,
                order_from_days: order_from_days,
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
            }
            return true;
        } catch (error) {
        }
    }

    recordQuantAmount = async (id, order_quant) => {
        try {
            let SEND_DATA = {
                action: 'admin_recordOrderQuantToOrder',
                id: id,
                order_quant: order_quant,
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
            }
            return true;
        } catch (error) {
        }
    }

    setMaximumQuantity = async (id, amount) => {
        try {
            let SEND_DATA = {
                action: 'admin_updateMaximumQuantity',
                id: id,
                maximum_quantity: amount,
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
            }
            return true;
        } catch (error) {
        }
    }

    setMinimumQuantity = async (id, amount) => {
        try {
            let SEND_DATA = {
                action: 'admin_updateMinimumQuantity',
                id: id,
                minimum_quantity: amount,
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
            }
            return true;
        } catch (error) {
        }
    }

    recordDaysAndAmountToBack = async (id, days) => {
        try {
            let SEND_DATA = {
                action: 'admin_recordDaysAndAmountToOrder',
                id: id,
                days: days,
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
            }
            return true;
        } catch (error) {
        }
    }

    getSuppliers = async () => {
        try {
            let SEND_DATA = {
                action: 'admin_getSuppliers',
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ SUPPLIERS: data }, () => this.getSupplierOptions(data))
            }
            return true;
        } catch (error) {
        }
    }

    renderProducts = () => {
        if ((this.state.FILTER.TITLE.length > 2 || this.state.FILTER.MANAGER.length || this.state.FILTER.SUPPLIER.length || this.state.FILTER.GROUP > 0) && this.state.SUPPLIERS) {
            return (
                <div className="products_ordering_holder">
                    <OrderingProducts
                        ALL_VIEWS={ this.state.ALL_VIEWS }
                        FILTER={ this.state.FILTER }
                        VIEW={ this.state.VIEW }
                        SELECTED={ this.state.SELECTED }
                        SUPPLIERS={ this.state.SUPPLIERS }
                        SUPPLIERS_OPTIONS={ this.state.SUPPLIERS_OPTIONS }
                        setSelectedAmount={ this.setSelectedAmount }
                        setSelectedAmountValue={ this.setSelectedAmountValue }
                        toggleConfirmation={ this.toggleConfirmation }
                        setSupplierToOrdering={ this.setSupplierToOrdering }
                        confirmCurrentSelection={ this.confirmCurrentSelection }
                        unConfirmCurrentSelection={ this.unConfirmCurrentSelection }
                        setQuantToOrdering={ this.setQuantToOrdering }
                        setOrderFromDaysToOrdering={ this.setOrderFromDaysToOrdering }
                        updateSuppliers={ this.getSuppliers }
                        setSpecialCounting={ this.setSpecialCounting }
                        setMaximumQuantity={ this.setMaximumQuantity }
                        setMinimumQuantity={ this.setMinimumQuantity }
                    />
                </div>
            )
        } else {
            return (
                <div className="products_ordering_holder">
                    <h5>Выберите Менеджера, Поставщика или Категорию! Или начните вводить в поиск - от двух символов</h5>
                </div>
            )
        }
    }

    getReadyToGoCount = () => {
        let count = 0;

        for (let i in this.state.SELECTED) {
            if (this.state.SELECTED[i].confirmed) {
                count++
            }
        }

        return count
    }

    recordConfirmed = () => {
        let confirmed = []

        for (let i in this.state.SELECTED) {
            if (this.state.SELECTED[i].confirmed) {
                confirmed.push(i)
            }
        }

        /////localStorage.setItem('CONFIRMED_IDS', JSON.stringify(confirmed));
        cookies.set('CONFIRMED_IDS', JSON.stringify(confirmed), { path: '/' });
    }

    countSum = () => {
        let sum = 0;

        for (let i in this.state.SELECTED) {
            if (this.state.SELECTED[i].confirmed) {
                if (typeof this.props.PRODUCTS[i] !== "undefined") {
                    let row_sum = this.state.SELECTED[i].amount * parseFloat(this.props.PRODUCTS[i].min_sup_price);
                    sum += row_sum
                }
            }
        }

        return Math.round(sum)
    }

    renderButton = () => {
        let COUNT = this.getReadyToGoCount()
        let SUM = this.countSum()
        return (
            <a className={ COUNT > 0 ? "submit_order_to_suppliers" : "submit_order_to_suppliers inactive" } onClick={ () => COUNT > 0 ? this.setState({ SHOW_ORDER_CONFIRMATION: true }) : alert('Вы не выбрали товары к заказу!') }>
                Сформировать заказ ({ COUNT } SKU на { SUM } руб)
            </a>
        )
    }

    checkboxChecked = (KEY) => {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER[KEY] = !FILTER[KEY];

        this.setState({ FILTER: FILTER })
    }

    renderCheckbox = (KEY, LABEL) => {
        return (
            <label htmlFor={ KEY } className="check_label">
                <div className="check_holder">
                    <input type="checkbox" id={ KEY } onChange={ () => this.checkboxChecked(KEY) } />
                </div>
                <span>{ LABEL }</span>
            </label>
        )
    }

    renderFilter = () => {
        return (
            <div className="ordering_filter">
                <div>
                    { this.renderCheckbox('ALL', 'Все') }
                    { this.renderCheckbox('USE_INCOMING_DATES', 'Учитывать ближайшие поставки') }
                    { this.renderCheckbox('UNCONFIRMED', 'Не потвержденные позиции') }
                    { this.renderCheckbox('TIMEDOUT', 'Просроченные поставки') }
                    { this.renderCheckbox('ORGANIC', 'Органические товары ') }
                    { this.renderCheckbox('IMPORTANT', 'Важные товары') }
                    { this.renderCheckbox('TOPACK', 'Фасовочные') }
                    { this.renderCheckbox('SEASON', 'Сезонные') }
                    { this.renderCheckbox('ARCHIVE', 'Закрытые') }
                    { this.renderCheckbox('HIDDEN', 'Скрытые') }
                </div>
                <div>
                    <label className="select_label">Поиск</label>
                    <div className="select_search_holder only_input">
                        <div className="select-search">
                            <input
                                id="title"
                                value={ this.state.FILTER.TITLE }
                                className="select_search_quick_input"
                                name="title"
                                placeholder="Поиск"
                                onChange={ (input) => this.searchTitle(input.target.value) }
                            />
                            { this.state.FILTER.TITLE.length > 0 ? (
                                <a className="reset_search_select" onClick={ () => this.searchTitle('') }>
                                    <img src="/system_images/green_cross_modal.png" />
                                </a>
                            ) : false }
                        </div>
                    </div>
                    <label className="select_label">Выбрать менеджера</label>
                    { this.renderManagerSelect() }
                    <label className="select_label">Выбрать категорию</label>
                    { this.renderGroupSelect() }

                </div>
                <div>
                    <Link to="/orders" className="blue_button">Открыть график поставок</Link>
                    <label className="select_label">Выбрать поставщика</label>
                    { this.renderSupplierSelect() }
                    <label className="select_label">День заказа</label>
                    { this.renderOrderDaySelect() }
                </div>
            </div>
        )
    }

    viewCheckboxChecked = (KEY) => {
        let VIEW = Object.assign({}, this.state.VIEW)
        VIEW[KEY] = !VIEW[KEY];

        localStorage.setItem('VIEW', JSON.stringify(VIEW));

        this.setState({ VIEW: VIEW })
    }

    renderShowFields = () => {
        let ROWS = [];
        for (let i in this.state.ALL_VIEWS) {
            ROWS.push(
                <div className={ this.state.VIEW[i] ? 'column_selecter_row active' : 'column_selecter_row' } key={ i } onClick={ () => this.viewCheckboxChecked(i) }>
                    <span>{ this.state.ALL_VIEWS[i].title }</span><div className="column_selecter_row_check">{ this.state.VIEW[i] ? <img src="/system_images/green_check_sel.png" /> : null }</div>
                </div>
            )
        }

        return (
            <div className="column_selecter">
                <div className="column_selecter_header">
                    <span>Фильтр столбцов</span>
                    <img src="/system_images/o_a.png" />
                </div>
                <div className="column_selecter_drop">
                    { ROWS }
                </div>
            </div>
        )
    }

    closeConfirmation = () => {
        this.setState({ SHOW_ORDER_CONFIRMATION: false })
    }

    renderOrderConfirmation = () => {
        if (this.state.SHOW_ORDER_CONFIRMATION) {
            return (
                <OrderingConfirmation
                    SELECTED={ this.state.SELECTED }
                    SUPPLIERS={ this.state.SUPPLIERS }
                    SUPPLIERS_OPTIONS={ this.state.SUPPLIERS_OPTIONS }
                    closeConfirmation={ this.closeConfirmation }
                    resetSupplierProducts={ this.resetSupplierProducts }
                    setSupplierToOrdering={ this.setSupplierToOrdering }
                    setAnotherSupplierToOrdering={ this.setAnotherSupplierToOrdering }
                />
            )
        }
    }

    resetSupplierProducts = (supplier_id, supplier_title, products) => {
        let SELECTED = Object.assign({}, this.state.SELECTED)
        for (let i = 0; i < products.length; i++) {
            if (typeof SELECTED[products[i]] !== "undefined") {
                delete SELECTED[products[i]]
            }
        }

        this.setState({ SELECTED: SELECTED }, () => this.recordConfirmed())
    }

    render() {
        return (
            <CSSTransitionGroup
                transitionAppear={ true }
                transitionAppearTimeout={ 600 }
                transitionEnterTimeout={ 600 }
                transitionLeaveTimeout={ 200 }
                transitionName={ 'SlideIn' }
            >
                <div className="right-part ordering">
                    <Helmet>
                        <title>Заказы</title>
                        <meta name="description" content="Заказы" />
                        <meta name="keywords" content="Заказы" />
                    </Helmet>
                    <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid">

                            <div className="row flex_down">
                                <div className="col-md-6">
                                    { this.renderFilter() }
                                </div>
                                <div className="col-md-3">
                                    { this.renderButton() }
                                </div>
                                <div className="col-md-3">
                                    { this.renderShowFields() }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    { this.renderProducts() }
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalsContainer />
                    { this.renderOrderConfirmation() }
                </div>

            </CSSTransitionGroup>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Ordering))