import React, {Component} from 'react';
import {checkRegionEntry, checkIfLocationIsInRussia} from "../../HELPERS/CoordinatesHelders/DefinitionOfTheRegion";
import regionsJson from "../../../Jsons/Delivery.json";

import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION,
        ADRESS: state.ADRESS,
        SHOWADRESS: state.SHOWADRESS,
        USEABLE_PERIODS: state.USEABLE_PERIODS,
        GPS: state.GPS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class RegionSelector extends Component {

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.ADRESS !== prevProps.ADRESS) {
            this.forceUpdate();
        }
    }

    regionDetector = () => {
        for (let region_key in regionsJson) {
            if (regionsJson[region_key].REGION_KEY == parseInt(this.props.REGION)) {
                return regionsJson[region_key].TITLE
                break;
            }
        }

        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY) {
            if (this.props.REGION == 'OTHER') {
                let REGION_DATA = checkIfLocationIsInRussia(this.props.ADRESS);
                return REGION_DATA.TITLE;
            }
        }
        // if(gps){
        //     let REGION_DATA = checkRegionEntry(gps)
        //     //console.log(REGION_DATA)
        //     return REGION_DATA.TITLE
        // }

    }

    render() {
        return (
            <div className='region_transparent_block'>
                <img className='cursor_transparent_image' src="/system_images/RegionIcon.png"/>
                <div className='region_transparent_text'>Ваш регион:</div>
                <div className='region_transparent_text region_text'
                     onClick={() => this.props.openRegionSelectorCities(true)}>{this.regionDetector(this.props.GPS)}</div>
            </div>
        )
    }

}

export default connect(
    mapStateToProps, mapDispatchToProps
)(RegionSelector);