import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        SHOW_SEARCH: state.SHOW_SEARCH
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class MobileSearchBlock extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            SUGGESTIONS: false,
            RESULTS: false,
            STRING: ''
        }

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.setInputRef = this.setInputRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.SHOW_SEARCH !== prevProps.SHOW_SEARCH) {
            if (!this.props.SHOW_SEARCH) {
                this.stopSearch();
            } else {
                this.input.focus();
            }
        }
    }

    setHistoryRef(node) {
        this.history = node;
    }

    setInputRef(node) {
        this.input = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
    }

    somethingInputted = (string) =>
    {
        let STRING = string.target.value;
        this.setState({STRING: STRING});

        if (STRING.length > 2) {
            this.filterProducts(STRING);
        } else {
            this.setState({SUGGESTIONS: false, RESULTS: false})
        }
    }

    filterProducts = async (text) => 
    {
        if (this.props.PRODUCTS) {
            let url = 'https://api.multisearch.io/?id=11608&query=' + text + '&uid=cc441f080&categories=0&limit=18&location=77';
            let response = await fetch(url);

            if (response.ok) {
                let json = await response.json();
                if (typeof json.results !== "undefined") {
                    if (!this.STOP_SEARCHING) {
                        this.setSearchResults(json.results.ids, text);
                    } else {
                        this.STOP_SEARCHING = false;
                    }
                } else {
                    this.setState({RESULTS: [], SUGGESTIONS: false});
                }
            }
        }
        /*
        if (this.props.PRODUCTS) {
            try {
                let SEND_DATA = {
                    action:     'searchString',
                    string:     text
                };

                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                if (data !== false) {
                    if (data.results.length) {
                        this.setSearchResults(data.results, text);
                    } else {
                        this.setState({RESULTS: [], SUGGESTIONS: false});
                    }
                } else {
                    this.setState({RESULTS: [], SUGGESTIONS: false});
                }
            } catch (error) {
                this.setState({RESULTS: [], SUGGESTIONS: false});
            }
        }
        */
    }

    setSearchResults(IDS, text)
    {
        let PRODUCTS = this.props.PRODUCTS;
        let FOUND = [];
        let COMPONENT = this;

        let MAX = 8;
        for (let i =0; i < IDS.length; i++) {
            let ID = IDS[i];
            if (typeof PRODUCTS[ID] !== "undefined") {
                let PRODUCT = PRODUCTS[ID];
                if (FOUND.length <= MAX) {
                    FOUND.push(
                        <Link to={'/' + PRODUCT.url} key={PRODUCT.id} className="search-result-row" onClick={() => this.props.toggleSearch()}>
                            {PRODUCT.title}
                        </Link>
                    );
                }
            }
        }


        this.setState({RESULTS: FOUND, SUGGESTIONS: true});
    }

    onFocus = () =>
    {
        this.setState({SUGGESTIONS: true});
    }

    onFocusOut = () =>
    {
        this.setState({SUGGESTIONS: false});
    }

    renderResults()
    {
        if (this.state.SUGGESTIONS) {
            if (this.state.RESULTS) {
                return (
                    <div className="search" ref={this.setHistoryRef}>
                        {this.state.RESULTS}
                    </div>
                );
            }
        }
    }

    renderReset()
    {
        if (this.state.STRING.length > 0) {
            return (
                <a className="search-controls-btn" onClick={() =>this.stopSearch()} ><i className="fa fa-times"></i></a>
            );
        }
    }

    stopSearch()
    {
        this.setState({RESULTS: false, SUGGESTIONS: false, STRING: ''});
        if (this.props.SHOW_SEARCH) {
            this.input.focus();
        }
    }

    handleSearch = (e) => {
        if (e.key === 'Enter') {
            this.startSearch()
        }
    }

    startSearch()
    {
        if (typeof window.rrApi !== "undefined") {
            try{ 
                window.rrApi.search(this.state.STRING);
            } catch(e) {}
        }
        
        this.setState({SUGGESTIONS: false, RESULTS: false})
        this.props.doSearch(this.state.STRING);
        ///document.location.href = '/search/' + this.state.STRING;
    }

	render() {
        if (!this.props.SHOW_SEARCH) {
            return false;
        }
		return (
			<div className="searching">
                <input 
                    onKeyDown={this.handleSearch}
                    className="searchinput"
                    type="text"
                    placeholder="Поиск"
                    onChange={(text) => this.somethingInputted(text)}
                    value={this.state.STRING}
                    ref={this.setInputRef}
                />
                <a className="search-btn" onClick={() => this.startSearch()}><i className="fa fa-search"></i></a>
                {this.renderReset()}
                {this.renderResults()}
            </div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileSearchBlock)
