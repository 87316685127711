import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import PayForm from './../../APP/COMPONENTS/ContentParts/PayForm';

function mapStateToProps(state) {
    return {
        EDIT_PARSING: state.EDIT_PARSING,
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class ParsingModal extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            url: ''
        };

        this.OFFSET = 0;
        this.setIframeRef = this.setIframeRef.bind(this);
    }

    setIframeRef(node) {
        this.iframe = node;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.EDIT_PARSING !== prevProps.EDIT_PARSING) {
            if (window.innerWidth < 1200) {
                if (!this.props.EDIT_PARSING) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
            if (this.props.EDIT_PARSING) {

                this.setState({
                    oz: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.oz : '',
                    oz_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.oz_coef : '',
                    ed: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.ed : '',
                    ed_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.ed_coef : '',
                    vk: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.vk : '',
                    vk_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.vk_coef : '',
                    vp: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.vp : '',
                    vp_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.vp_coef : '',
                    un: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.un : '',
                    un_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.un_coef : '',
                    mf: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.mf : '',
                    mf_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.mf_coef : '',
                    ff: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.ff : '',
                    ff_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.ff_coef : '',
                    gl: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.gl : '',
                    gl_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.gl_coef : '',
                    pk: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.pk : '',
                    pk_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.pk_coef : '',
                    av: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.av : '',
                    av_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.av_coef : '',
                    sm: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.sm : '',
                    sm_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.sm_coef : '',
                    m2: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.m2 : '',
                    m2_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.m2_coef : '',
                    ln: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.ln : '',
                    ln_coef: this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.ln_coef : '',
                    custom_urls:  this.props.EDIT_PARSING.parsing_urls ? this.props.EDIT_PARSING.parsing_urls.custom_urls.length ? this.props.EDIT_PARSING.parsing_urls.custom_urls : [{url: '',title: '', coef: '', price: ''}] : [{url: '',title: '', coef: '', price: ''}]
                });
            }
        }
    }

    setValue = (field, input) => {
        this.setState({[field]: input.target.value});
    }

    setCustomValue = (field, input, key) =>
    {
        let customs = this.state.custom_urls;
        customs[key][field] = input.target.value;
        this.setState({custom_urls: customs})
    }

    addBlockToCustom = () =>
    {
        let custom = this.state.custom_urls;
        custom.push(
            {
                url: '',
                title: '',
                coef: '',
                price: ''
            }
        )

        this.setState({custom_urls: custom})
    }

    renderCustoms = () =>
    {
        if (!this.state.custom_urls || !this.state.custom_urls.length) {
            return false
        }

        let Customs = [];
        for (let i = 0; i < this.state.custom_urls.length; i++) {
            Customs.push(
                <div className="parsing_block" key={i}>
                    <div>
                        <label>
                            <span>Название</span>
                            <input type="text" onChange={(input) => this.setCustomValue('title',input,i)}  value={this.state.custom_urls[i].title} />
                        </label>  
                        <label>
                            <span>Коэффициент</span>
                            <input type="text" onChange={(input) => this.setCustomValue('coef',input,i)}  value={this.state.custom_urls[i].coef} />
                        </label>
                        <label>
                            <span>Ссылка</span>
                            <input type="text" onChange={(input) => this.setCustomValue('url',input,i)}  value={this.state.custom_urls[i].url} />
                        </label>
                        <label>
                            <span>Цена</span>
                            <input type="text" onChange={(input) => this.setCustomValue('price',input,i)}  value={this.state.custom_urls[i].price} />
                        </label>                                            
                    </div>
                </div>
            )
        }

        return Customs
    }

    save = async () =>
    {
        try {
            let SEND_DATA = {
                action: 'admin_saveParsing',
                id: this.props.EDIT_PARSING.id,
                apikey: this.props.USER.TOKEN,
                oz: this.state.oz,
                oz_coef: this.state.oz_coef,
                ed: this.state.ed,
                ed_coef: this.state.ed_coef,
                vk: this.state.vk,
                vk_coef: this.state.vk_coef,
                vp: this.state.vp,
                vp_coef: this.state.vp_coef,
                un: this.state.un,
                un_coef: this.state.un_coef,
                mf: this.state.mf,
                mf_coef: this.state.mf_coef,
                ff: this.state.ff,
                ff_coef: this.state.ff_coef,
                gl: this.state.gl,
                gl_coef: this.state.gl_coef,
                pk: this.state.pk,
                pk_coef: this.state.pk_coef,
                av: this.state.av,
                av_coef: this.state.av_coef,
                sm: this.state.sm,
                sm_coef: this.state.sm_coef,
                ln: this.state.ln,
                ln_coef: this.state.ln_coef,
                m2: this.state.m2,
                m2_coef: this.state.m2_coef,
                custom_urls: this.state.custom_urls
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.props.editParsing(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    renderIframe = () =>
    {
        return (
            <div className="iframe_block">
                <iframe
                    src={this.state.url}
                    height="100%"
                    width="100%"
                    frameBorder="0"
                    scrolling="no"
                    allowFullScreen={false}
                    ref={this.setIframeRef}
                />
            </div>
        )
    }

    setIframeUrl = (url) =>
    {
        this.setState({url: url})
    }

	render()
    {
        if (this.props.EDIT_PARSING) {
            return (
                <div>
                    <div className="modal-overlay2" onClick={() => this.props.editParsing(false)}>
                    </div>
                    <div className="modal-box product-card">
                        <div className="parsing_div">
                            <h5>Проценка {this.props.EDIT_PARSING.title}:</h5>
                            <div className="parsing_holder">
                                <div className="parsing_block">
                                    <a target="_blank" href="https://www.ozon.ru/category/supermarket-25000/">
                                        <img src="/system_images/concurs/ozon.png"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('oz_coef',input)} value={this.state.oz_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('oz',input)} value={this.state.oz} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://av.ru">
                                        <img src="/system_images/concurs/az.svg"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('av_coef',input)}  value={this.state.av_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('av',input)}  value={this.state.av} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://vkusvill.ru">
                                        <img src="/system_images/concurs/vk.png"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('vk_coef',input)}  value={this.state.vk_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('vk',input)}  value={this.state.vk} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://perekrestok.ru">
                                        <img src="/system_images/concurs/perek.png"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('pk_coef',input)}  value={this.state.pk_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('pk',input)}  value={this.state.pk} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://vprok.ru">
                                        <img src="/system_images/concurs/vp.png"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('vp_coef',input)}  value={this.state.vp_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('vp',input)}  value={this.state.vp} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://utkonos.ru">
                                        <img src="/system_images/concurs/utkonos-logo-picodi.png"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('un_coef',input)}  value={this.state.un_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('un',input)}  value={this.state.un} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://online.globus.ru">
                                        <img src="/system_images/concurs/logo.svg"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('gl_coef',input)}  value={this.state.gl_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('gl',input)}  value={this.state.gl} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://myfresh.ru">
                                        <img src="/system_images/concurs/fresh.png"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('mf_coef',input)}  value={this.state.mf_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('mf',input)}  value={this.state.mf} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://esh-derevenskoe.ru">
                                        <img src="/system_images/concurs/ed.svg"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('ed_coef',input)}  value={this.state.ed_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('ed',input)}  value={this.state.ed} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://lenta.com">
                                        <img src="/system_images/concurs/lenta.svg"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('ln_coef',input)}  value={this.state.ln_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('ln',input)}  value={this.state.ln} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://www.m2-shop.ru/">
                                        <img src="/system_images/concurs/m2.png"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('m2_coef',input)}  value={this.state.m2_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('m2',input)}  value={this.state.m2} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="parsing_block">
                                    <a target="_blank" href="https://4fresh.ru">
                                        <img src="/system_images/concurs/logo.png"/> 
                                    </a>
                                    <div>
                                        <label>
                                            <span>Коэффициент</span>
                                            <input type="text" onChange={(input) => this.setValue('ff_coef',input)}  value={this.state.ff_coef} />
                                        </label>
                                        <label>
                                            <span>Ссылка</span>
                                            <input type="text" onChange={(input) => this.setValue('ff',input)}  value={this.state.ff} />
                                        </label>                                            
                                    </div>
                                </div>

                                <div className="customs_block">
                                <a className="orange-button" onClick={() => this.addBlockToCustom()}>Добавить сайт</a>
                                </div>
                                {this.renderCustoms()}

                            </div>
                            <div className="text-center">
                                <a className="orange-button" onClick={() => this.save()}>Сохранить</a>
                            </div>
                            <br/><br/><br/>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ParsingModal);