import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import LazyLoad from 'react-lazyload';
import {sendPostRequest} from "./../../APP/HELPERS/requests"
import {CSSTransition, TransitionGroup, CSSTransitionGroup} from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import MobileLocationData from "../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        DO_SEARCH: state.DO_SEARCH,
        PRODUCTS: state.PRODUCTS,
        FAVS: state.FAVS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MyProducts extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        /*
         this.props.closeAllModals();

        let COMPONENT = this;
        this.interval = setInterval(function(){
            COMPONENT.getIds();
        }, 50);
        */
    }

    getIds = async () => {
        if (this.props.USER) {
            try {
                let SEND_DATA = {
                    action: 'getMyPopular',
                    apikey: this.props.USER.TOKEN
                };
                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    clearInterval(this.interval);
                    this.setState({IDS: data.ids})
                }
            } catch (error) {
                console.log('We cannot get it!');
            }
        } else {
            let INFO = (
                <div className="row">
                    <div className="col-12">
                        <div className="success-text errored">
                            Для того, чтобы увидеть список своих товаров, Вам необходимо <a
                            onClick={() => this.props.openLK()}>авторизоваться</a>!
                        </div>
                    </div>
                </div>
            );
            this.setState({INFO: INFO});
        }
    }

    getProducts() {
        let KEYS = 0;
        if (this.props.FAVS) {
            KEYS = Object.keys(this.props.FAVS);
        }

        if (KEYS.length) {
            let CLASS = 'product_card ' + this.getItemsClass();

            let USE_PRODUCTS = [];
            for (let ID in this.props.FAVS) {
                if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                    USE_PRODUCTS.push(ID);
                }
            }

            if (USE_PRODUCTS.length) {
                return (
                    <div className="row products-row">
                        {USE_PRODUCTS.map(id => <div className={CLASS} key={id}><LazyLoad height={300}><ProductCard
                            ID={id}/></LazyLoad></div>)}
                    </div>
                );
            } else {
                return (
                    <div className="row">
                        <div className="col-12">
                            <div className="already_polled">
                                На данный момент, у Вас нет товаров в избранном!
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="already_polled">
                            На данный момент, у Вас нет товаров в избранном!
                        </div>
                    </div>
                </div>
            );
        }
    }

    getItemsClass() {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 'col-12';
        } else if (WIDTH < 769) {
            return 'col-6';
        } else if (WIDTH < 1050) {
            return 'col-4';
        } else if (WIDTH < 1200) {
            return 'col-3';
        } else if (WIDTH < 9900) {
            return 'el-5';
        }
    }

    renderH1() {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        Мои товары
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Главная'
        });
        DATA.push({
            'to': '/myproducts',
            'title': 'Мои товары'
        });

        return <BreadCrumbs DATA={DATA}/>
    }

    renderRetailOne = () => {
        const WIDTH = window.innerWidth;
        let KEYS = 0;
        let IDS = [];
        if (this.props.FAVS) {
            KEYS = Object.keys(this.props.FAVS);
        }

        if (KEYS.length) {
            for (let ID in this.props.FAVS) {
                IDS.push(ID);
            }
        }

        if (IDS.length) {
            IDS = IDS.join(',');

            if (WIDTH > 980) {
                return <div data-retailrocket-markup-block="5f7d81ac97a5251aec3fcc58" data-favorite={IDS}></div>
            } else {
                return <div data-retailrocket-markup-block="5f7da43597a5282bb875d3e3" data-favorite={IDS}></div>
            }
        }
    }

    render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            >
                <div className="right-part">
                    <Helmet>
                        <title>Мои товары</title>
                        <meta name="description" content="Мои товары"/>
                        <meta name="keywords" content="Мои товары"/>
                    </Helmet>

                    {this.renderBreadCrumbs()}
                    <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid">
                            {this.renderH1()}
                            {this.getProducts()}
                            {this.renderRetailOne()}
                        </div>
                    </div>

                    <ModalsContainer/>
                </div>
                <FooterBlock/>
                <MobileMessage/>
            </CSSTransitionGroup>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProducts))