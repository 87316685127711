import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import { isActive } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class OrderProductSearch extends Component {
    
	constructor(props)
    {
        super(props);
        this.state = {
            SUGGESTIONS: false,
            RESULTS: false,
            STRING: ''
        }

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.STOP_SEARCHING = false;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setHistoryRef(node) {
        this.history = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
    }

    somethingInputted = (string) =>
    {
        let STRING = string.target.value;
        this.setState({STRING: STRING});

        if (STRING.length > 1) {
            this.filterProducts(STRING);
        } else {
            this.setState({SUGGESTIONS: false, RESULTS: false})
        }
    }

    filterProducts = async (text) => 
    {
        if (this.props.PRODUCTS) {
            let PRODUCTS = this.props.PRODUCTS;
            let FOUND = [];
            let COMPONENT = this;

            Object.keys(PRODUCTS).forEach(key => {
                let PRODUCT = PRODUCTS[key];

                const itemData = PRODUCT.title.toUpperCase();
                const textData = text.toUpperCase();
                if (itemData.indexOf(textData) > -1) {
                    FOUND.push(PRODUCT.id);
                }
            });

            this.setSearchResults(FOUND, text);
        }
    }

    setSearchResults(IDS, text)
    {
        let PRODUCTS = this.props.PRODUCTS;
        let FOUND = [];
        let COMPONENT = this;

        let MAX = 10;
        for (let i =0; i < IDS.length; i++) {
            let ID = IDS[i];
            if (typeof PRODUCTS[ID] !== "undefined") {
                let PRODUCT = PRODUCTS[ID];
                if (FOUND.length <= MAX) {
                    FOUND.push(
                        <span key={PRODUCT.id} className="search-result-row" onClick={() => this.setSelected(PRODUCT)}>
                            {PRODUCT.title}
                        </span>
                    );
                }
            }
        }


        this.setState({RESULTS: FOUND, SUGGESTIONS: true});
    }

    setSelected = (PRODUCT) =>
    {
        this.props.addProduct(PRODUCT)
    }

    onFocus = () =>
    {
        this.setState({SUGGESTIONS: true});
    }

    onFocusOut = () =>
    {
        this.setState({SUGGESTIONS: false});
    }

    renderResults()
    {
        if (this.state.SUGGESTIONS) {
            if (this.state.RESULTS) {
                return (
                    <div className="search" ref={this.setHistoryRef}>
                        {this.state.RESULTS}
                    </div>
                );
            }
        }
    }

    renderReset()
    {
        if (this.state.STRING.length > 0) {
            return (
                <a className="search-controls-btn" onClick={() =>this.stopSearch()} ><i className="fa fa-times"></i></a>
            );
        }
    }

    stopSearch()
    {
        this.setState({RESULTS: false, SUGGESTIONS: false, STRING: ''});
    }

    handleSearch = (e) => {
        if (e.key === 'Enter') {
            this.startSearch()
        }
    }

    startSearch()
    {
        if (typeof window.rrApi !== "undefined") {
            try{ 
                window.rrApi.search(this.state.STRING);
            } catch(e) {}
        }

        this.props.doSearch(this.state.STRING);
        this.setState({SUGGESTIONS: false})
        this.STOP_SEARCHING = true;
        ///document.location.href = '/search/' + this.state.STRING;
    }

	render() {
		return (
			<div className="searching_in_order ">
                <input 
                    onKeyDown={this.handleSearch}
                    className="searchinput"
                    type="text"
                    placeholder="Поиск"
                    onChange={(text) => this.somethingInputted(text)}
                    value={this.state.STRING}
                />
                {this.renderReset()}
                {this.renderResults()}
            </div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OrderProductSearch)
