import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CONFIG from './../../../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

import {sendPostRequest} from "./../../../../APP/HELPERS/requests"
import {CustomNode} from './CustomNode'

import {
    Tree,
    getBackendOptions,
    MultiBackend,
  } from "@minoru/react-dnd-treeview";
  import { DndProvider } from "react-dnd";

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        CATS: state.CATS,
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CatNavs extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            ACTIVE: false,
            EDIT_CATS: false,
            TREE: []
        }
    }

    componentDidMount = () =>
    {
        this.prepareTree()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.CATS !== prevProps.CATS) {
            this.prepareTree()
        }
    }

    prepareTree = () =>
    {
        let CATS = this.props.CATS;
        let ITEMS = [];

        for (let i = 0; i < CATS.length; i++) {
            if (CATS[i].children.length) {
                for (let j = 0; j < CATS[i].children.length; j++) {
                    ITEMS.push({
                        parent: CATS[i].id,
                        id: CATS[i].children[j].id,
                        text: CATS[i].children[j].title
                    })
                }

            }
            ITEMS.push({
                parent: 0,
                id: CATS[i].id,
                text: CATS[i].title,
                droppable: true
            })
        }

        this.setState({TREE: ITEMS})
    }

    checkActiveArrow = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return 'nav-load-more selected';
        } else {
            return 'nav-load-more';
        }
    }

    renderSubCatsArrow = (CAT) =>
    {
        let CLASSES = this.checkActiveArrow(CAT.id);

        return (
            <div className={CLASSES}>
                <i className="fa fa-angle-right"></i>
            </div>
        );
    }

    renderNavIcon = (CAT) =>
    {
        let IMAGE = <div className="nav-image"></div>;

        if (CAT.ico !== "") {
            let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/groups/icos/' + CAT.ico;
            IMAGE = (
                <div className="nav-image">
                    <img src={IMAGE_SRC} />
                </div>
            );
        }

        return IMAGE;
    }

    checkActive = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return 'foldable selected';
        } else {
            return 'foldable';
        }
    }

    checkSubActive = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return true;
        } else {
            return false;
        }
    }

    renderSubCats = (CAT) =>
    {
        let SUB_ITEMS = false;

        if (this.checkSubActive(CAT.id)) {
            SUB_ITEMS = [];
            for (let i = 0; i < CAT.children.length; i++) {
                let SUB_CAT = CAT.children[i];
                SUB_ITEMS.push(this.renderSubLi(SUB_CAT,i));
            }
            SUB_ITEMS = (
                <ul className="in-sub-menu" key="1">
                    {SUB_ITEMS}
                </ul>
            );
        }

        return (
            <CSSTransitionGroup 
                transitionName="slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {SUB_ITEMS}
            </CSSTransitionGroup>
        );
    }

    renderSubLi = (CAT,i) => 
    {
        return (
            <li
                key={i}
            >
                <Link to={'/' + CAT.url}
                    href={CAT.url}
                    title={CAT.title}
                >
                    {CAT.title}
                </Link>
            </li>
        );
    }

    toggleSubs = (id) =>
    {
        let SET = id;
        if (this.state.ACTIVE == id) {
            SET = false;
        }

        this.setState({ACTIVE: SET});
    }

    renderLi = (CAT,i) => 
    {
        let IMAGE = this.renderNavIcon(CAT);

        if (CAT.children.length) {
            let CLASSES = this.checkActive(CAT.id);
            let SUB_CATS_ARROW = this.renderSubCatsArrow(CAT);
            let SUB_CATS = this.renderSubCats(CAT);

            return (
                <li
                    key={i}
                >
                    <a 
                        className={CLASSES} 
                        title={CAT.title}
                        onClick={() => this.toggleSubs(CAT.id)}
                    >
                        {IMAGE}
                        {CAT.title}
                        {SUB_CATS_ARROW}
                    </a>
                    {SUB_CATS}
                </li>
            );
        } else {
            return (
                <li
                    key={i}
                >
                    <Link to={'/' + CAT.url}
                        title={CAT.title}
                    >
                        {IMAGE}
                        {CAT.title}
                    </Link>
                </li>
            );
        }
    }

    updateCatsOrdering = async () =>
    {
        try {
            let SEND_DATA = {
                action: 'admin_updateCatsOrdering',
                apikey: this.props.USER.TOKEN,
                tree: this.state.TREE
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
            } else {

            }
        } catch (error) {

        }
    }

    handleTextChange =  (id, value) => {
        const newTree = this.state.TREE.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              text: value
            };
          }
    
          return node;
        });
    
        this.setState({TREE: newTree})
    }

	render() {
    	let CATS = this.props.CATS;

        if (CATS) {

            if (this.state.EDIT_CATS) {

                return (
                    <div>
                        <div className="cats_header">Категории <a className="inline_edit confirm" onClick={() => this.setState({EDIT_CATS: false},() => this.updateCatsOrdering())}><i className="fa fa-check"></i></a>
                            <a className="inline_edit end" onClick={() => this.setState({EDIT_CATS: false}, () => this.prepareTree())}><i className="fa fa-remove"></i></a></div>
                            <div className="the_sort_tree">
                            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                            <Tree
                                className="the_sort_tree"
                                tree={this.state.TREE} 
                                rootId={0}
                                sort={false}
                                render={(node, { depth, isOpen, onToggle }) => (
                                    <CustomNode
                                      node={node}
                                      depth={depth}
                                      isOpen={isOpen}
                                      onToggle={onToggle}
                                      onTextChange={this.handleTextChange}
                                    />
                                  )}
                                onDrop={(tree) => this.setState({TREE: tree})}
                                canDrop={(tree, { dragSource, dropTargetId }) => {
                                    if (dragSource?.parent === dropTargetId) {
                                      return true;
                                    }
                                  }}
                                  dropTargetOffset={5}
                            />
                        </DndProvider>
                        </div>
                    </div>
                );
            } else {
                let ITEMS = [];

                for (let i = 0; i < CATS.length; i++) {
                    let CAT = CATS[i];
                    ITEMS.push(this.renderLi(CAT,i));
                }

                return (
                    <div>
                        <div className="cats_header">Категории <a className="inline_edit" onClick={() => this.setState({EDIT_CATS: true})}><i className="fa fa-pencil"></i></a></div>
                        <ul className="main-nav">
                            {ITEMS}
                            <li
                                key="no_cat"
                            >
                                <Link to="/nocats"
                                    title="Без категории"
                                >
                                    <div className="nav-image"><img src="/system_images/close.png"/></div>
                                    Без категории
                                </Link>
                            </li>
                        </ul>
                    </div>
                );
            }
        } else {
            return false;
        }  
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CatNavs)
