import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';


/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


function mapStateToProps(state) {
    return {
        REGION: state.REGION,
        TOKEN: state.TOKEN
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Bars extends Component {

    constructor(props) {
        super(props);
        this.state = {}

    }

    renderNav = () => {
        return (
            <div className="bars_nav">
                <a target="_blank" className="bars_logo" href="https://ecomarket.ru/">
                    <img src="/system_images/Bars/logo.png"/>
                    <h2 className="bars_link">На сайт ECOMARKET.RU</h2>
                </a>
                <a href="#about" className="bars_link">Об акции</a>
                <a href="#" className="bars_link">
                    <span>Войти</span>
                    <img src="/system_images/Bars/login.png"/>
                </a>
            </div>
        )
    }

    renderHeader = () => {
        return (
            <div className="bars_header">
                <div className="container">
                    {this.renderNav()}
                    <div className="bars_intro">
                        <h1 className="bars_title">Сохраним природу вместе!</h1>
                        <h2 className="bars_subtitle">Поможем снежному барсу, спасём ёжика, посадим деревья, а взамен
                            получаем скидки и подарки!</h2>
                        <div className="bars_images">
                            <div>
                                <img className="bars_one" src="/system_images/Bars/bars.png"/>
                            </div>
                            <div>
                                <img className="bars_two" src="/system_images/Bars/bars.png"/>
                            </div>
                            <div>
                                <img className="bars_tree" src="/system_images/Bars/bars.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderAbout = () => {
        return (

            <div id="about" className="bars_about">
                <div className="bars_container">
                    <div className="">
                        <div className="bars_text_about">
                            Снежный барс - пятнистое сокровище Алтайского края, который на протяжении 20 лет находится
                            под угрозой полного исчезновения. Изучение и сохранение популяции снежного барса является
                            основной задачей «Сайлюгемского» парка, первого национального парка на Алтае.
                            Экомаркет вместе с вами принял решение помочь алтайским специалистам, а также внести свой
                            вклад в переработку пластика.
                        </div>
                        <h2 className="bars_title_about">Как можно помочь Снежному барсу?</h2>
                        <div className="bars_text_block">
                            <div className="bars_text_about">
                                Все очень просто!
                                Сдавайте использованные пластиковые крышки, шариковые ручки, фломастеры и пакеты!
                                Все вырученные средства от переработки идут в помощь популяризации Снежного Барса!
                            </div>
                            <div>
                                <img className="bars_about_img" src="/system_images/Bars/bars.png"/>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="bars_collected">
                    <div className="bars_collected_info">
                        <p className=".bars_collected_title">Уже собрано:</p>
                        <p className="bars_collected_price">56 542 </p>
                        <p className="bars_collected_valute">рублей</p>
                    </div>
                    <div className="">
                        <img src="/system_images/Bars/bars-trim.png"/>
                    </div>
                </div>
            </div>
        )
    }

    renderInfo = () => {

        const progress = [
            {
                icon: '/system_images/Bars/Info/battery.svg',
                title: 'свыше 2 тонн батареек'
            },
            {
                icon: '/system_images/Bars/Info/cap.svg',
                title: 'более тонны пластиковых крышек и фломастеров'
            },
            {
                icon: '/system_images/Bars/Info/paper.svg',
                title: 'свыше тонны крафт-бумаги'
            },
        ]
        return (
            <div className="bars_info">
                <div className="bars_info_conteiner">
                    <div className="">

                        <div className="bars_index_text" style={{color: "#4F6674", marginBottom: "30px"}}>
                            Среди всех видов бытового мусора пластик представляет собой наибольшую угрозу экологии
                            планеты. Ежегодно около 100 млн тонн пластмассы выбрасываются в окружающую среду, нанося
                            природе непоправимый вред. Чтобы избежать экологической катастрофы, необходимо
                            организовывать сбор и утилизацию пластиковых отходов.
                        </div>
                        <div className="bars_index_text" style={{color: "#4F6674", marginBottom: "30px"}}>
                            За три года реализации проекта нами собрано и утилизировано:
                        </div>
                        <div className="bars_progress">
                            {
                                progress.map((item, index) => (
                                    <div key={index} className="bars_progress_item">
                                        <div className="bars_progress_img">
                                            <img style={{width: "100%", height: "auto"}} src={item.icon}/>
                                        </div>
                                        <h4 className="bars_progress_title">
                                            {item.title}
                                        </h4>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    renderHedgehog = () => {
        return (
            <div className="bars_hedgehog">
                <div className="bars_container">
                    <div className="">
                        <div className="bars_hedgehogImg">
                            <img src="/system_images/Bars/hedgehog-aling.png"/>
                        </div>
                        <h3 className="bars_title_about" style={{color: "#1C455D", marginBottom: "30px"}}>Спасайте
                            ёжиков!</h3>
                        <div className="hedgehog_info">
                            <div className="hedgehog_info_img">
                                <img src="/system_images/Bars/hedgehog.png"/>
                            </div>
                            <div className="">
                                <p className="bars_text" style={{color: "#4F6674"}}>
                                    Знали ли вы, что одна неправильно утилизированная батарейка убивает двух ёжиков?
                                    Заботясь о природе и сдавая батарейки, пластиковые крышки,
                                    фломастеры и шариковые ручки нашему курьеру, вы поможете сохранить ёжиков. Будьте
                                    внимательны, курьер сможет принять не более 50 штук каждого
                                    из перечисленных выше.
                                    <br/> Каждый год мы высаживаем деревья для сохранения экологии.
                                    <br/>
                                    <br/>
                                    Подписывайтесь на наши обновления в Инстаграме и будьте в курсе всех новостей
                                </p>
                                <div className="bars_buttonGroup">

                                    <a href="#" className="bars_button">
                                        <div className="">
                                            <img src="/system_images/Bars/instagram.svg"/>
                                        </div>
                                        <a href='https://www.instagram.com/Ecomarket.ru/' className="bars_button_link">Перейти
                                            в Instagram</a>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderFooter = () => {

        return (
            <div className="">
                <div className="bars_container">
                    <div className="bars_inner">
                        <div className="bars_promoMobile">
                            <div className=" ">300 рублей в подарок <br/> на первый заказ через приложение</div>
                            <a href="#" className="bars_loadApp">
                                <div>
                                    <img src="/system_images/Bars/app-store-big.svg" alt="appStore"/>
                                </div>
                                <p className="bars_buttonText">Загрузите в App Store</p>
                            </a>
                            <div className="bars_contacts">
                                <div className="bars_contacts_title">Горячая линия</div>
                                <div className="bars_contacts_number">+7 (495) 445-05-05</div>
                                <a href="mailto:info@ecomarket.ru" className="bars_contacts_email">
                                    <img className="bars_contacts_img" src="/system_images/Bars/email-icon.svg"
                                         alt="email"/>
                                    <div className="bars_contacts_img_two" style={{marginBottom: "0"}}>info@ecomarket.ru</div>
                                </a>
                            </div>
                        </div>
                        <div className="bars_contacts_nav">
                            <div className="bars_contacts_logo">
                                <img style={{height: "100%", width: "auto"}} src="/system_images/Bars/logo.png"/>
                            </div>
                            <div className="bars_contacts_links">
                                <li>
                                    <Link className="bars_contacts_links" to="/company/about">
                                        О нас
                                    </Link>
                                </li>
                                <li>
                                    <Link className="bars_contacts_links" to="/company/dostavka">
                                        Доставка
                                    </Link>
                                </li>
                                <li>
                                    <Link className="bars_contacts_links" to="/company/oplata">
                                        Оплата
                                    </Link>
                                </li>
                                <li>
                                    <Link className="bars_contacts_links" to="/company/suppliers">
                                        Наши поставщики
                                    </Link>
                                </li>
                                <li>
                                    <Link className="bars_contacts_links" to="/company/contacts">
                                        Контакты
                                    </Link>
                                </li>
                                <li>
                                    <Link className="bars_contacts_links" to="/company/polzovatelskoe-soglashenie">
                                        Оферта
                                    </Link>
                                </li>
                                <li>
                                    <a className="bars_contacts_links" target="_blank" href="https://opt.ecomarket.ru">
                                        Ресторанам и опт
                                    </a>
                                </li>
                                <li>
                                    <a className="bars_contacts_links" href="https://зелёнаяработа.рф" target="_blank">
                                        Вакансии
                                    </a>
                                </li>
                            </div>
                        </div>
                        <div className="bars_contacts_promo">
                            <div className="bars_contacts_info">
                                <div className="bars_contacts_info_div">300 рублей в подарок на первый заказ через
                                    приложение
                                </div>
                                <div className="bars_contacts_icons">
                                    <a href="#" className="bars_contacts_icons_item">
                                        <img src="/system_images/Bars/play-market.svg" alt="play-market"/>
                                    </a>
                                    <a href="#" className="bars_contacts_icons_item">
                                        <img src="/system_images/Bars/app-store.svg" alt="play-market"/>
                                    </a>
                                </div>
                            </div>
                            <div className="bars_contacts_qrcode">
                                <img src="/system_images/Bars/qrcode.png" alt="qrcode"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    render() {
        return (
            <div className="homeBars">
                {this.renderHeader()}
                {this.renderAbout()}
                {this.renderInfo()}
                {this.renderHedgehog()}
                {this.renderFooter()}
            </div>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Bars))
