import React, {Component} from 'react';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {checkRegionEntry} from "../HELPERS/CoordinatesHelders/DefinitionOfTheRegion";
import regionsJson from "../../Jsons/Delivery.json";


function mapStateToProps(state) {
    return {
        OPEN_REGION_SELECTOR: state.OPEN_REGION_SELECTOR,
        ADRESS: state.ADRESS,
        GPS: state.GPS,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class RegionSelectorModal extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.OPEN_REGION_SELECTOR !== prevProps.OPEN_REGION_SELECTOR) {
            if (window.innerWidth < 1200) {
                if (!this.props.OPEN_REGION_SELECTOR) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    regionDetector = () => {
        for (let region_key in regionsJson) {
            if (regionsJson[region_key].REGION_KEY == parseInt(this.props.REGION)) {
                return regionsJson[region_key].TITLE + '?'
            }
        }
    }


    render() {
        if (this.props.OPEN_REGION_SELECTOR) {
            return (
                <div>
                    <div className='modal-overlay' onClick={() => this.props.openRegionSelector(false)}>
                    </div>
                    <div className="modal-box region_selector">
                                <img className='close_cross' src="/system_images/close_inactive.png" onClick={() => this.props.openRegionSelector(false)}/>
                        <div className='region_selector_holder'>
                            <div className='region_confirm'>
                                <div className='region_confirm_small_text'>Ваш регион</div>
                                <div className='region_confirm_big_text'>{this.regionDetector()}</div>
                                <div className='region_confirm_buttons'>
                                    <a className='region_yes_button' onClick={() => this.props.openRegionSelector(false)}>Да</a>
                                    <a className='region_change_button' onClick={() => {this.props.openRegionSelectorCities(true);this.props.openRegionSelector(false)}}>Выбрать <br/> другой регион</a>
                                </div>


                            </div>
                        </div>
                    </div>
                    </div>
            );
        }
        else {
            return ""
        }
    }


}
export default connect(
    mapStateToProps, mapDispatchToProps
)(RegionSelectorModal);