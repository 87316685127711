import {useRef, useState} from "react";
import * as React from 'react';
import {useEffect} from "react";
import PropTypes from "prop-types";


export function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        console.log(windowDimensions)
    }, [windowDimensions])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        handleResize()
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {windowDimensions, isMobile: windowDimensions.width <= 375};
}

function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
               setTimeout(()=>callback(),100)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export function OutsideAlerter({callback=()=>{}, ...props}) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, callback);

    return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired
};

export function useCoolDown (callback = (...args) => {}, timeout=600) {
    const [timer, setTimer] = useState(0)
    return (...args) => {
        if (timer) clearTimeout(timer)
        const newTimer = setTimeout(()=>{
            return callback(...args)
        },timeout)

        setTimer(newTimer)
    }
}

