import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import { useSwipeable, Swipeable } from 'react-swipeable'

import MapHelperOrder from './../../../APP/HELPERS/MapHelperOrder'

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION,
		GPS: state.GPS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class RegionMap extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);

        let ADRESS = false;

        this.state = {
        	ADRESS: ADRESS,
        	GPS: false,
        	KM: 0,
        	SUGGESTIONS: [],
        	SHOW_SUGGESTIONS: false,
        	TEMP_ADRESS: '',
        	POSITION: 0,
        	MAP_LOADED: false
        }

        this.setMapHelper = this.setMapHelper.bind(this);
    }

    setMapHelper = (node) =>
    {
        this.mapHelper = node;
    }

    componentDidMount()
    {
        if (this.props.USER) {
        	if (this.props.USER.ADRESS !== '') {
        		let COMPONENT = this;
        		this.interval = setInterval(function(){
	        		if (COMPONENT.state.MAP_LOADED) {
	        			clearInterval(COMPONENT.interval);
	        			COMPONENT.mapHelper.setSuggestedAdress(COMPONENT.props.USER.ADRESS);
        			}
        		}, 10);
        	}
        }
    }

    mapLoaded = () =>
    {
    	this.setState({MAP_LOADED: true});
    }

    setAdressAndGps = (adress,gps,km) =>
    {
    	this.setState({ADRESS: adress, GPS: gps, KM: km});
    }

    setSuggestions = (data) =>
    {
    	if (data.length) {
            this.setState({SUGGESTIONS: data});
        } else {
            this.setState({SUGGESTIONS: []});
        }
    }

    renderAdress = () =>
    {
    	return (
    		<div className="your_full_adress">
    			<div className="your_full_adress_small">Ваш адрес:</div>
    			<div className="your_full_adress_big" onClick={() => this.setState({SHOW_SUGGESTIONS: true, TEMP_ADRESS: this.state.ADRESS})}>{this.state.ADRESS}<span><img src="/system_images/green_pencil.png" alt="редактировать" /></span></div>
    		</div>

		);
    }

    renderButton = () =>
    {
    	if ((this.state.ADRESS) && (this.state.GPS)) {
    		return (
    			<a className="adress_confirm_button" onClick={() => this.props.adressConfirmed(this.state.ADRESS, this.state.GPS, this.state.KM)}>Подтвердить адрес</a>
			);
    	} else {
    		return (
    			<a className="adress_confirm_button disabled">Подтвердить адрес</a>
			);
    	}
    }

    resetGps = () =>
    {
    	this.setState({gps: false});
    }

    recordTempAdress = (text) => 
    {
    	this.mapHelper.getSuggestions(text.target.value);
        this.setState({TEMP_ADRESS: text.target.value});
    }

    setSuggestedAdress = (adress) =>
    {
    	this.setState({SHOW_SUGGESTIONS: false, SUGGESTIONS: [], TEMP_ADRESS: ''});
    	this.mapHelper.setSuggestedAdress(adress);
    }

    closeSuggestions = () =>
    {
    	this.setState({SUGGESTIONS: [], SHOW_SUGGESTIONS: false, POSITION: 0});
    }

    changePosition = (Y) =>
    {
    	if (Y + 5 >= this.state.POSITION) {
	    	this.setState({POSITION: Y + 5});
	    }
	    if (Y > 100) {
	    	this.closeSuggestions();
	    }
    }

    swipeEnded = (Y) =>
    {
    	if (Y < 100) {
    		this.setState({POSITION: 0});
    	} else {
    		this.closeSuggestions();
    	}
    }

    renderSuggester = () =>
    {
    	let CLASS = 'suggestions_swipe';
    	if (this.state.SHOW_SUGGESTIONS) {
    		CLASS = 'suggestions_swipe visible';
    	}

    	let SUGGESTIONS = [];

    	if (this.state.SUGGESTIONS.length) {
    		for (let i = 0; i < this.state.SUGGESTIONS.length; i++) {
    			let SEG = this.state.SUGGESTIONS[i];
    			SUGGESTIONS.push(
	                <a 
	                    key={i}
	                    onClick={() => this.setSuggestedAdress(SEG.TITLE)}
	                >
	                    {SEG.TITLE}
	                </a>
	            );
    		}
    	}

    	const config = {
			preventDefaultTouchmoveEvent: true,
			trackMouse: true,
			trackTouch: true,
			delta: 1
		};

    	return (
    		<div className={CLASS} style={{marginTop: this.state.POSITION}}>
    			<div className="suggestions_swipe_holder">
    				<Swipeable onSwiped={(eventData) => this.swipeEnded(eventData.absY)} onSwiping={(eventData) => this.changePosition(eventData.absY)} onSwipedDown={(eventData) => this.closeSuggestions} {...config}>
    					<div className="grab_block">
    						<div className="close_grab"></div>
						</div>
    				</Swipeable>
	    			<label>
	    				<span>
	    					<img src="/system_images/search_suggestions.png" alt="search_suggestions" />
	    				</span>
	    				<input className="suggestions_search" value={this.state.TEMP_ADRESS}  onChange={(text) => this.recordTempAdress(text)} />
	    				<span onClick={() => this.setState({SUGGESTIONS: [], TEMP_ADRESS: ''})}>
	    					<img src="/system_images/suggestions_close.png" alt="stop_suggestions" />
	    				</span>
	    			</label>
	    			<div className="all_suggestions">
	    				{SUGGESTIONS}
	    			</div>
    			</div>
    		</div>
    	);
    }

    renderSuggesterBack = () =>
    {
    	let CLASS = 'suggestions_swipe_back';
    	if (this.state.SHOW_SUGGESTIONS) {
    		CLASS = 'suggestions_swipe_back visible';
    	}

    	return (
    		<div className={CLASS} onClick={() => this.closeSuggestions()}>
    		</div>
    	);
    }

    render()
    {
    	return (
    		<div className="modal_content mapped">
    			<div className="white_map_top_fader"></div>
    			{this.renderAdress()}
    			<MapHelperOrder 
    				CLASS="full_map"
	    			REGION={this.props.REGION} 
	    			CENTER=""
	    			setAdressAndGps={this.setAdressAndGps}
	    			resetGps={this.resetGps}
	    			ref={this.setMapHelper} 
	    			setSuggestions={this.setSuggestions}
	    			mapLoaded={this.mapLoaded}
					GPS = {this.props.GPS}
                />
                {this.renderButton()}
                {this.renderSuggester()}
                {this.renderSuggesterBack()}
    		</div>
		);
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(RegionMap);