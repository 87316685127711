import React from "react";

export default ({ onBlur, children, hasError=false }) => {
  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;

    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        // You can invoke a callback or add custom logic here
        onBlur();
      }
    }, 0);
  };

  return (
    <div tabIndex="1" onBlur={handleBlur} style={{position: 'relative', maxWidth: '283px'}}>
      {children}
      {hasError && <div className="onBlur">Ой, чего-то не хватает</div>}
    </div>
  );
}