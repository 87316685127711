import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import MobileMessage from './../../../APP/COMPONENTS/ContentParts/MobileMessage.js';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        DO_SEARCH: state.DO_SEARCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class PlanetaZdorovya extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            TOKEN: props.TOKEN
        }
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });

        DATA.push({
            'to' : '/company/apteka-planeta-zdorovya',
            'title' : 'ЗДОРОВЬЕ НА МАКСИМУМ!'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    renderContent()
    {
        return (
            <div>
                <div class="zdor_header">
                    <h1 class="zdor_header_title">ЗДОРОВЬЕ НА МАКСИМУМ!</h1>
                 
                    <img class="zdor_header_banner" src="/system_images/health_banner.png" alt="" />
                    <div class="zdor_header_subtitle">
                        «Планета Здоровья» сегодня — это более 1950 аптек в 50 регионах страны. <br/>
                        «Планета Здоровья» — больше, чем просто аптеки! <br/>
                        Поддержите свое здоровье вместе с нами!
                    </div>
                    <a target="_blank" href="https://planetazdorovo.ru/?utm_source=partner&utm_medium=ecomarket&utm_campaign=moscow" class="zdor_link">
                        Оформить заказ
                    </a>
                    <div class="zdor_header_info">
                        В период с 1 июня 2021 г. по 30 июня 2021 г - скидка 10% по промокоду «eco10» на интернет-заказ на  <br/>    сайте planetazdorovo.ru или в мобильном приложении «Планета Здоровья»* <br/>
                        Оформите заказ прямо сейчас! <br/>
                        Онлайн-аптека 24/7** <br/>
                        Возможна доставка!***
                    </div>

                </div>
                <div class="zdor_content">
                    <p>
                        * Скидка 10% действует при оформлении заказа на сайте или в мобильном приложении «Планета Здоровья» и вводе промокода «eco10» в Москве и Московской области, Санкт-Петербурге и Ленинградской области, Казани и Республике Татарстан.
                    </p>
                    <p>
                        Акция действует в период с 1 июня 2021 г. по 30 июня 2021г. Скидки не суммируются. Промокод действует только на товары из ассортимента аптеки (из наличия). Промокод не действует, если выбран товар «Под заказ» в аптеку.

                    </p>

                    <p>
                        Информацию о сроках, организаторе мероприятия, месте и правилах его проведения уточняйте у сотрудников аптек. Промокод может быть применен только один раз.
                    </p>

                    <p>
                        На сайте и в мобильном приложении «Планета Здоровья» цена указана без учёта скидки, цена с учётом скидки рассчитывается при введении промокода при оформлении «интернет-заказа».
                    </p>
                    <p>
                        
                        ** Онлайн-аптека — сервис для покупателей, позволяющий быстро и удобно приобрести нужные товары в аптеке. Оплатить и забрать заказ можно по режиму работы выбранной аптеки после получения оповещения «Заказ готов»

                    </p>
                    <p>
                        
                        ***Доставка на дом осуществляется в  Москве и Московской области: Вы можете оформить заказ с доставкой на дом безрецептурных препаратов и сопутствующих товаров. На дом НЕ доставляют лекарственные препараты, отпускаемые по рецепту; наркотические и психотропные лекарственные средства; спиртосодержащие препараты с объемной долей этилового спирта свыше 25%; препараты, которые при хранении и транспортировке, требуют соблюдения температурного режима. Товары, которые предлагаются для доставки на дом, отмечены иконкой «грузовичка»

                    </p>
                    <p>
                        Сведения о юридических лицах, имеющие разрешение на осуществление розничной торговли лекарственными препаратами для медицинского применения дистанционным способом, осуществление такой торговли и доставки, указанных лекарственных препаратов гражданам:

                    </p>
                    <p>
                        Сведения о юридических лицах, имеющие разрешение на осуществление розничной торговли лекарственными препаратами для медицинского применения дистанционным способом, осуществление такой торговли и доставки, указанных лекарственных препаратов гражданам:

                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Аджента"  Юридический адрес: 115582, Москва г, Каширское ш, дом № 108, корпус 1, пом.1 ОГРН  1127747169549

                    </p>
                    <p>
                        
                        Общество с ограниченной ответственностью  "Азон"  Юридический адрес:  614097, г. Пермь, проспект Парковый, д. 15А, этаж ОГРН  1085903006221

                    </p>
                    <p>
                        
                        Общество с ограниченной ответственностью "Альфа"  Юридический адрес:  614990, г. Пермь, ул. Екатерининская, д. 75, оф. 301 ОГРН  1065903037947

                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Арктик"    Юридический адрес:  614990, г. Пермь, ул. Екатерининская, д. 75, офис 301
                        Общество с ограниченной ответственностью "Арктик"    Юридический адрес:  614990, г. Пермь, ул. Екатерининская, д. 75, офис 301 ОГРН  10659020555581

                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Вага"    Юридический адрес:  614081, г. Пермь, ул. Плеханова, д. 70, корпус А, кв. 33 ОГРН  1155958031140

                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Вербена"    Юридический адрес: 614068, г. Пермь, ул. Петропавловская, д. 62, кв. 39 ОГРН 1185958010919

                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Восток"    Юридический адрес:  614095, г. Пермь, ул. Мира, д. 31, кв. 48 ОГРН  1125904019647
                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Грант" Юридический адрес:  614039, г. Пермь, проспект Комсомольский, д. 58, пом. 16 ОГРН  1145958056573
                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Дион" Юридический адрес:  614033, г. Пермь, ул. Куйбышева, 149 ОГРН  1085904018771
                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Здоровье"    Юридический адрес:  614104, г. Пермь, ул. Генерала Черняховского, д. 78, цокольный этаж, помещение 5. ОГРН  1145958056628
                    </p>

                    <p>
                        ООО "Здравник"    Юридический адрес:  614012, г. Пермь, проспект Декабристов, д. 29, помещение 3 ОГРН  1095906000145
                    </p>

                    <p>
                        Общество с ограниченной ответственностью "Истра" Юридический адрес:  614010, г. Пермь,  ул. Героев Хасана, д. 11а, этаж 1, отдельный вход ОГРН  1085904017363
                    </p>

                    <p>
                        
                        Общество с ограниченной ответственностью "Кант"    Юридический адрес:  614077, г. Пермь,  ул. Крупской, д. 89, этаж 1 ОГРН  1185958011238

                    </p>

                    <p>
                        
                        Общество с ограниченной ответственностью "Лето"    Юридический адрес:  614056, г. Пермь, Гашкова, 19 ОГРН  1085906007879

                    </p>
                    <p>
                        
                        Общество с ограниченной ответственностью "Меридиан" Юридический адрес:  614051, г. Пермь, ул. Юрша, д. 52, кв. 16 ОГРН  1155958030722

                    </p>
                    
                    <p>
                        Общество с ограниченной ответственностью "Парус"    Юридический адрес: 614095, г. Пермь, ул. Мира, д. 31, кв. 48 ОГРН 1125904019196
                    </p>

                    <p>
                        Общество с ограниченной ответственностью "Олимп" Юридический адрес:  614060, г. Пермь, ул. Фрезеровщиков, д. 86, кв. 201 ОГРН  11759580112779
                    </p>

                    
                    <p>
                        Общество с ограниченной ответственностью "Радуга-16"    Юридический адрес: 614060, г. Пермь, ул. Фрезеровщиков, д. 86, кв. 201 ОГРН 1165074059655
                    </p>
                    <p>
                        Общество с ограниченной ответственностью  "Реон"    Юридический адрес:  614046, г. Пермь, ул. Василия Каменского, д. 4, этаж 1, пом. 3 ОГРН  1065902056550
                    </p>

                    <p>
                        Общество с ограниченной ответственностью "Парацельс" Юридический адрес: 123592, Москва г, Строгинский б-р, дом № 14, корпус 4   ОГРН 5147746106448   
                    </p>

                    <p>
                        Общество с ограниченной ответственностью "Парк"    Юридический адрес:  614111, г. Пермь, ул. Муромская, 16А, этаж 1, помещение 3 ОГРН  1085903006320
                    </p>

                    
                    <p>
                        Общество с ограниченной ответственностью "Тога"    Юридический адрес:  614051, г. Пермь, ул. Юрша, д. 5, этаж 1 ОГРН  1085906007494
                    </p>
                    <p>
                        Общество с ограниченной ответственностью  "Группа Торро"    Юридический адрес:  115582, г. Москва, Каширское ш., д. 108, к. 1 ОГРН 1057747597478 
                    </p>

                    <p>
                        Общество с ограниченной ответственностью "Спот"    Юридический адрес:  614097, г. Пермь, ул.Комиссара Пожарского, д. 10, этаж 1, пом. 4 ОГРН  1085903006331
                    </p>

                    <p>
                        Общество с ограниченной ответственностью "Чип"    Юридический адрес:  614095, г. Пермь, проспект Декабристов, д. 12, этаж 1 ОГРН  1075905008519
                    </p>
                    
                    <p>
                        Общество с ограниченной ответственностью "Фаэлит" 614097, г. Пермь, пр. Парковый, дом 18 ОГРН  1085903006243
                    </p>
                    <p>
                        Общество с ограниченной ответственностью "Юнити"    Юридический адрес:  614025, г. Пермь, ул. Коломенская, д. 24а, этаж 1 ОГРН  10859040118815
                    </p>

                    <p>
                        Аптеки «Планета Здоровья» — это аптеки, заключившие договор с ООО «АСП» и работающие под товарными знаками по свидетельствам №268923 и №323856.
                    </p>

                    <p>
                        ИМЕЮТСЯ ПРОТИВОПОКАЗАНИЯ, НЕОБХОДИМО ОЗНАКОМИТЬСЯ С ИНСТРУКЦИЕЙ ИЛИ ПРОКОНСУЛЬТИРОВАТЬСЯ СО СПЕЦИАЛИСТОМ.
                    </p>
                    <p>
                        16+
                    </p>
                </div>
        </div>
        );
    }

	render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>ЗДОРОВЬЕ НА МАКСИМУМ!</title>
                        <meta name="description" content="ЗДОРОВЬЕ НА МАКСИМУМ!" />
                        <meta name="keywords" content="ЗДОРОВЬЕ НА МАКСИМУМ!" />
                    </Helmet>

                    {this.renderBreadCrumbs()}
                    <div id="products" className="page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    {this.renderContent()}
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
                <FooterBlock />
                <MobileMessage />
            </CSSTransitionGroup>
        );
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(PlanetaZdorovya))
