import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import MobileMessage from './../../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import MobileLocationData from "../../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        DO_SEARCH: state.DO_SEARCH,
        POPULAR: state.POPULAR,
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class IdsSelecter extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	IDS: props.match.params.ids
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
    }

    componentDidMount()
    {
        this.props.closeAllModals();
    }

    getProducts()
    {
        let USE_PRODUCTS = [];

        let CLASS = 'product_card ' + this.getItemsClass();

        let IDS = this.state.IDS.split(",");
        for (let i = 0; i < IDS.length; i++) {
        	let ID = IDS[i];
        	if (!isNaN(parseFloat(ID)) && isFinite(ID)) {
        		if (typeof this.props.PRODUCTS[ID] !== "undefined") {
	        		USE_PRODUCTS.push(parseInt(ID));
	        	}
       		}
        }

        if (USE_PRODUCTS.length) {
            return (
                <div className="row products-row">
                    {USE_PRODUCTS.map(id => <div className={CLASS}  key={id}><LazyLoad height={300}><ProductCard ID={id} /></LazyLoad></div>)}
                </div>
            );
        } else {
        	return (
        		<h5 className="margined_h5">
		    		К сожалению, необходимые продукты недоступны!
		    	</h5>
        	);
        }
    }

    getItemsClass()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 'col-12';
        } else if (WIDTH < 769) {
            return 'col-6';
        } else if (WIDTH < 1050) {
            return 'col-4';
        } else if (WIDTH < 1200) {
            return 'col-3';
        } else if (WIDTH < 9900) {
            return 'el-5';
        }
    }

    renderH1()
    {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        Лучшие продукты, выбранные для вас
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });
        DATA.push({
            'to' : '/r/' + this.state.IDS,
            'title' : 'Подборка продуктов'
        });

        return <BreadCrumbs DATA={DATA} />
    }

	render() {
    	return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
        		<div className="right-part">
                    <Helmet>
                        <title>Лучшие продукты, выбранные для вас</title>
                        <meta name="description" content="Лучшие продукты, выбранные для вас" />
                        <meta name="keywords" content="Лучшие продукты, выбранные для вас" />
                    </Helmet>
                    <MobileLocationData />
                        <DesktopDeliveryInformation />
                    {this.renderBreadCrumbs()}
                    <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid">
                            {this.renderH1()}
                            {this.getProducts()}
                        </div>
                    </div>

                    <ModalsContainer />
    			</div>
                <FooterBlock />
                <MobileMessage />
            </CSSTransitionGroup>
		);
    }
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(IdsSelecter))