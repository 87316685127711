import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderCardProduct extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: props.PRODUCT
        }
        this.PRODUCT = props.PRODUCT;
    }

    renderImage()
    {
        let SRC = this.state.PRODUCT.thumb;
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC + ')'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        } else {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        }
    }

    rmFixed(numb) {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderProductWeightDiff = () =>
    {
        if (this.PRODUCT.START_AMOUNT.length) {
            if (parseFloat(this.PRODUCT.START_AMOUNT) !== parseFloat(this.PRODUCT.AMOUNT)) {
                if ((this.PRODUCT.ed_izm == 'кг') || (this.PRODUCT.ed_izm == 'г')) {
                    let DIFF_CLASS = '';
                    let DIFF_WEIGHT = 0;
                    let DIFF_PRICE = 0;
                    let DIFF_TEXT = 'перевес';
                    let DIFF_PERCENT = 0;

                    let ED = 1;
                    if (this.PRODUCT.ed_izm == 'кг') {
                        ED = 1000;
                    }

                    let ONE_PERCENT = parseFloat(this.PRODUCT.START_AMOUNT) / 100;
                    let START_PERCENT = parseFloat(this.PRODUCT.START_AMOUNT) / ONE_PERCENT;
                    let ACTIVE_PERCENT = parseFloat(this.PRODUCT.AMOUNT) / ONE_PERCENT;

                    let PRICE = this.rmFixed(parseFloat(this.PRODUCT.SUM) / parseFloat(this.PRODUCT.AMOUNT));
                    let START_PRICE = this.rmFixed(PRICE * parseFloat(this.PRODUCT.START_AMOUNT));

                    if (parseFloat(this.PRODUCT.START_AMOUNT) > parseFloat(this.PRODUCT.AMOUNT)) {
                        DIFF_CLASS = ' goes_less_amount';
                        DIFF_TEXT = 'недовес';
                        DIFF_WEIGHT = this.rmFixed((parseFloat(this.PRODUCT.START_AMOUNT) - parseFloat(this.PRODUCT.AMOUNT)) * ED);
                        DIFF_PERCENT = '-' +  this.rmFixed(START_PERCENT - ACTIVE_PERCENT);
                        DIFF_WEIGHT = '-' + DIFF_WEIGHT;

                        DIFF_PRICE = '-' +  (START_PRICE - parseFloat(this.PRODUCT.SUM)).toFixed(2);
                    } else {
                        DIFF_WEIGHT = this.rmFixed((parseFloat(this.PRODUCT.AMOUNT) - parseFloat(this.PRODUCT.START_AMOUNT)) * ED);
                        DIFF_PERCENT = '+' + this.rmFixed(ACTIVE_PERCENT - START_PERCENT);
                        DIFF_WEIGHT = '+' + DIFF_WEIGHT;

                        DIFF_PRICE = '+' + this.rmFixed((parseFloat(this.PRODUCT.SUM) - START_PRICE)).toString();
                    }

                    return (
                        <div className = "weight_product_holder">
                            <div className="small_text_weight"> {DIFF_TEXT} {DIFF_WEIGHT} г</div>
                            {/*<div className={"green_text_weight" + DIFF_CLASS}>{DIFF_PERCENT}%</div>*/}
                            <div className="small_text_weight">{START_PRICE} р</div>
                            <div className={"green_text_weight" + DIFF_CLASS}>{DIFF_PRICE} р</div>
                        </div>
                    )
                }
            }            
        }
    }

    render()
    {
        return (
            <div className="button-holder row-card">
                {this.renderImage()}
                <div className="product_card_full_col">
                    <div className="product_card_basic_row">
                        <div className="caption">
                            <Link to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}>
                                {this.PRODUCT.title}
                            </Link>
                        </div>
                        <div className="product-amount">
                            {this.PRODUCT.AMOUNT} {this.PRODUCT.ed_izm}
                        </div>
                    </div>
                    {this.renderProductWeightDiff()}
                </div>
                <div className="product-price">
                    <span className="price">{this.PRODUCT.SUM} р</span>
                </div>                
            </div>
        );
    }
}

export default connect(
    false, mapDispatchToProps
)(OrderCardProduct);