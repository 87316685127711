import React, {Component} from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import MobileMessage from './../../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import InnerLoader from './../../../APP/COMPONENTS/ContentParts/InnerLoader';

import PhoneInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/PhoneInput.js';
import NameInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/NameInput.js';
import ProductInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/ProductInput.js';
import EmailInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/EmailInput.js';
import CityInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/CityInput.js';
import TextInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/TextInput.js';
import BirthdayInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/BirthdayInput.js';
import WhatsappInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/WhatsappInput.js';
import ImageRadio from './../../../APP/COMPONENTS/ContentParts/AnketaParts/ImageRadio.js';
import ImageRadioColumnMultiple from './../../../APP/COMPONENTS/ContentParts/AnketaParts/ImageRadioColumnMultiple.js';
import TextedRadio from './../../../APP/COMPONENTS/ContentParts/AnketaParts/TextedRadio.js';
import TextedRadioMultiple from './../../../APP/COMPONENTS/ContentParts/AnketaParts/TextedRadioMultiple.js';
import CountryInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/CountryInput.js';
import StarsInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/StarsInput.js';
import GroupSearchInput from './../../../APP/COMPONENTS/ContentParts/AnketaParts/GroupSearchInput.js';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CATS: state.CATS,
        TOKEN: state.TOKEN
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Poll extends Component {

  constructor(props)
    {
        super(props);
        this.state = {
            CODE: props.CODE,
            POLL: props.POLL,
            STEP: 0,
            ANSWERS: {},
            SENT: false,
            PRODUCT: ''
        }

        this.elements = [];
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.CODE !== this.state.CODE) {
            this.setState({CODE: this.props.CODE, STEP: 0, SENT: false});
        }

        if (this.props.POLL !== this.state.POLL) {
            this.setState({POLL: this.props.POLL, STEP: 0, SENT: false});
        }
    }   

    setField = (TITLE, VALUE) =>
    {
        this.setState({[TITLE] : VALUE});
    }

    resetField = (TITLE) =>
    {
        this.setState({[TITLE] : ''});
    }

    scrollToPoll = (i) =>
    {
        window.scrollTo({top: this.elements[i].current.offsetTop - 150, behavior: "smooth"});
    }

    renderSubmit = () =>
    {
        return <button className="anketa-button" onClick={() => this.submit()}>Отправить ответы</button>
    }

    submit = async () =>
    {
        if (!this.SENDING) {
            this.SENDING = true;
            let SEND_DATA = {
                action:         'savePollData',
                token:          this.props.TOKEN,
                code:           this.state.CODE,
                answers:        this.state.ANSWERS,
                product:        this.state.PRODUCT
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SENT: true});
                this.SENDING = false;
                window.postMessage('ANKETA_FILLED');
                if (typeof window.ReactNativeWebView !== "undefined") {
                    window.ReactNativeWebView.postMessage('ANKETA_FILLED');
                }
            } else {
                this.SENDING = false;
            }
        }
    }

    renderStars = (ITEM) =>
    {
        return (
            <div>
                <div className="rating poll_rating">
                    <input name={ITEM.id} id={ITEM.id + '_star_5'} type="radio" value="5" onChange={() => this.select(ITEM.id, 5)} /><label className="full" htmlFor={ITEM.id + '_star_5'} title="5"><span className="star"></span><span className="number">5</span></label>
                    <input name={ITEM.id} id={ITEM.id + '_star_4'} type="radio" value="4" onChange={() => this.select(ITEM.id, 4)} /><label className="full" htmlFor={ITEM.id + '_star_4'} title="4"><span className="star"></span><span className="number">4</span></label>
                    <input name={ITEM.id} id={ITEM.id + '_star_3'} type="radio" value="3" onChange={() => this.select(ITEM.id, 3)} /><label className="full" htmlFor={ITEM.id + '_star_3'} title="3"><span className="star"></span><span className="number">3</span></label>
                    <input name={ITEM.id} id={ITEM.id + '_star_2'} type="radio" value="2" onChange={() => this.select(ITEM.id, 2)} /><label className="full" htmlFor={ITEM.id + '_star_2'} title="2"><span className="star"></span><span className="number">2</span></label>
                    <input name={ITEM.id} id={ITEM.id + '_star_1'} type="radio" value="1" onChange={() => this.select(ITEM.id, 1)} /><label className="full" htmlFor={ITEM.id + '_star_1'} title="1"><span className="star"></span><span className="number">1</span></label>
                </div>
            </div>
        );
    }

    select = (ID, ANSWER) =>
    {
        let ANSWERS = Object.assign({}, this.state.ANSWERS);
        ANSWERS[ID] = ANSWER;

        if ((parseInt(ID) == 1247) && (parseInt(ANSWER) < 4)) {
            this.setState({ANSWERS: ANSWERS});
        } else {
            let NEXT_STEP = parseInt(this.state.STEP) + 1;

            if ((this.state.STEP == Object.keys(ANSWERS).length - 1) &&(NEXT_STEP <= this.state.POLL.length - 1)) {
                this.setState({STEP: NEXT_STEP, ANSWERS: ANSWERS});
                this.scrollToPoll(NEXT_STEP);
            } else {
                this.setState({ANSWERS: ANSWERS});
            }
        }
    }

    recordText = (text, ID) => 
    {
        let VALUE = text.target.value;
        let ANSWERS = Object.assign({}, this.state.ANSWERS);
        ANSWERS[ID] = VALUE;
        this.setState({ANSWERS: ANSWERS});
    }

    renderOptions = (ITEM) =>
    {
        let OPTIONS = [];
        for (let i = 0; i < ITEM.answers.length; i++) {
            let ID = ITEM.id + '_' + i;
            let ANSWER = ITEM.answers[i];
            OPTIONS.push(
                <div className="poll_radio" key={ID}>
                    <input name={ITEM.id} id={ID} type="radio" value={ANSWER} required onChange={() => this.select(ITEM.id, ANSWER)} />
                    <label htmlFor={ID}>{ANSWER}</label>
                </div>
            );
        }

        return (
            <div>
                {OPTIONS}
            </div>
        );
    }

    getClass = (i) =>
    {
        if (this.state.STEP >= i) {
            return 'poller';
        } else {
            return 'poller inactive';
        }
    }

    nextStep = () =>
    {
        let NEXT_STEP = parseInt(this.state.STEP) + 1;
        if (this.state.STEP == Object.keys(this.state.ANSWERS).length - 1) {
            if (NEXT_STEP <= this.state.POLL.length - 1) {
                this.setState({STEP: NEXT_STEP});
                this.scrollToPoll(NEXT_STEP);
            }
        }
    }

    renderNextButton = (i) =>
    {
        if (i !== this.state.POLL.length - 1) {
            return (
                <div className="text-center">
                    <a className="poll_next_question" onClick={() => this.nextStep()}>
                        <span className="poll_next_question_circle">
                            <img src="/system_images/white_angle_down.png"/>
                            <img src="/system_images/white_angle_down.png"/>
                        </span>
                        <span>Далее</span>
                    </a>
                </div>
            );
        }
    }

    renderContent = () =>
    {
        let QUESTIONS = [];

        for (let i = 0; i < this.state.POLL.length; i++) {
            let ITEM = this.state.POLL[i];
            let INSIDE = '';

            switch (parseInt(ITEM.type)) {
                case 1:
                    INSIDE = (
                        <div>
                            <textarea className="required" type="textarea" placeholder="Ваш ответ" required onChange={(text) => this.recordText(text, ITEM.id)}></textarea>
                            {this.renderNextButton(i)}
                        </div>
                    );
                    break;
                case 3:
                    INSIDE = this.renderStars(ITEM);
                    break;
                default:
                    INSIDE = this.renderOptions(ITEM);
                    break;
            }

            this.elements[i] = React.createRef();

            QUESTIONS.push(
                <div ref={this.elements[i]} key={i} className={this.getClass(i)}>
                    <div className="question">
                        {ITEM.question}
                    </div>
                    <div className="answertextarea">
                        {INSIDE}
                    </div>
                </div>
            );

            if ((ITEM.id == 1247) && (typeof this.state.ANSWERS[1247] !== "undefined") && (this.state.ANSWERS[1247] < 4)) {
                QUESTIONS.push(
                    <div key={i + 'addon'} className="poller">
                        <div className="question">
                            <i className="fa fa-question-circle"></i> Качество какого продукта Вас не устроило?
                        </div>
                        <div className="answertextarea">
                            <ProductInput field="PRODUCT" PRODUCT={this.state.PRODUCT} setField={this.setField} resetField={this.resetField} />
                        </div>
                    </div>
                );
            }
        }

        return (
            <div>
                {this.renderStatus()}
                {QUESTIONS}
                {this.renderSubmit()}
            </div>
        );
    }

    renderStatus = () =>
    {
        let ITEMS = [];
        for (let i = 0; i < this.state.POLL.length; i++) {
            let ITEM = this.state.POLL[i];
            ITEMS.push(<span key={i} className={this.getNavClass(i)}></span>)
        }

        return (
            <div className="poll_float_nav">
                {ITEMS}
            </div>
        );
    }

    getNavClass = (i) =>
    {
        if ((this.state.STEP > i) || (Object.keys(this.state.ANSWERS).length == this.state.POLL.length)) {
            return 'poll_circle active';
        } else {
            return 'poll_circle';
        }
    }

    renderSubmit = () =>
    {
        if (this.state.SENT) {
            return (
                <div className="answer-the-form success">
                    <i class="fa fa-check"></i>Спасибо! Ваше мнение очень важно для нас!
                </div>
            );
        } else {
            let SHOW = true;
            if ((typeof this.state.ANSWERS[1247] !== "undefined") && (this.state.ANSWERS[1247] < 4)) {
                if (this.state.PRODUCT.length < 2) {
                    SHOW = false;
                }
            }
            if (Object.keys(this.state.ANSWERS).length == this.state.POLL.length) {
                if (SHOW) {
                    return (
                        <div className="text-center">
                            <button type="button" className="poll_submit" onClick={() => this.submit()}>Отправить</button>
                        </div>
                    );
                }
            }
        }
    }

  render() {
    return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div id="products" className="page-content"  ref={this.myRef}>
                    <div className="anketa">
                        <h1 className="main-heading poll-heading">Помогите нам стать лучше</h1>
                        {this.renderContent()}
                    </div>
                </div>
            </CSSTransitionGroup>
        );
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Poll)
