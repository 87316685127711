import React, {Component} from 'react';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CONFIG from './../../../SYSTEM/Config.js'

import OrderingProductRow from './../../../APP/COMPONENTS/ContentParts/OrderingProductRow';

function mapStateToProps(state) {
    return {
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        PRODUCTS: state.PRODUCTS,
        HIDDEN: state.HIDDEN
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderingProducts extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            SORT: false,
            SELECTED_ALL: false,
        }
    }

    filterProducts = () =>
    {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;


        let GROUPED = [];
        if (this.props.FILTER.GROUP > 0) {
            if (typeof this.props.GROUPED_PRODUCTS[this.props.FILTER.GROUP] !== "undefined") {
                GROUPED = this.props.GROUPED_PRODUCTS[this.props.FILTER.GROUP];
            }
        }

        let PRODUCTS = []
        for (let i in this.props.PRODUCTS) {
            let PRODUCT = this.props.PRODUCTS[i];

            let USE = true;

            if (USE == true && this.props.FILTER.ORDER_DAY > 0) {
                let supplier = this.getSupplier(PRODUCT.id, PRODUCT.supplier, this.props.SUPPLIERS)
            
                if (supplier) {
                    if (!supplier.order_days.includes(this.props.FILTER.ORDER_DAY.toString())) {
                        USE = false;
                    }
                } else {
                    USE = false;
                }
            }

            if (USE == true && !this.props.FILTER.HIDDEN) {
                if (this.props.HIDDEN.includes(PRODUCT.id.toString())) {
                    USE = false;
                }
            }
            
            if (USE == true && !this.props.FILTER.ALL) {
                if (typeof this.props.SELECTED[PRODUCT.id] == "undefined" || this.props.SELECTED[PRODUCT.id].amount <= 1) {
                    USE = false;
                }
            }

            if (USE == true && !this.props.FILTER.TOPACK) {
                if (parseInt(PRODUCT.topack) == 1) {
                    USE = false;
                }
            }

            if (USE == true && !this.props.FILTER.SEASON) {
                if (PRODUCT.seasonstart !== PRODUCT.seasonend) {
                    if (parseInt(PRODUCT.today_season) == 0) {
                        USE = false;
                    }
                }
            }

            if (USE == true && !this.props.FILTER.ARCHIVE) {
                if (parseInt(PRODUCT.archive) == 1) {
                    USE = false;
                }
            }

            if (USE == true && this.props.FILTER.UNCONFIRMED) {
                if (typeof this.props.SELECTED[PRODUCT.id] == "undefined") {
                    USE = false;
                } else {
                    if (this.props.SELECTED[PRODUCT.id].confirmed == true) {
                        USE = false
                    }
                }
            }

            if (USE == true && this.props.FILTER.IMPORTANT) {
                if (parseInt(PRODUCT.important) == 0) {
                    USE = false;
                }
            }

            if (USE == true && this.props.FILTER.TIMEDOUT) {
                if (!PRODUCT.incoming_date || PRODUCT.incoming_date.length == 0 || today <= PRODUCT.incoming_date) {
                    USE = false;
                }
            }

            if (USE == true && this.props.FILTER.ORGANIC) {
                if (parseInt(PRODUCT.ro) == 0 && parseInt(PRODUCT.eco) == 0) {
                    USE = false;
                }
            }

            if (USE == true && this.props.FILTER.SUPPLIER.length) {
                if (PRODUCT.supplier !== this.props.FILTER.SUPPLIER) {
                    USE = false;
                }
            }

            if (USE == true && this.props.FILTER.MANAGER.length) {
                if (PRODUCT.manager !== this.props.FILTER.MANAGER) {
                    USE = false;
                }
            }

            if (USE == true && this.props.FILTER.GROUP > 0) {
                if (!GROUPED.includes(PRODUCT.id.toString())) {
                    USE = false;
                }
            }

            if (USE == true && this.props.FILTER.TITLE.length) {
                let STRING = PRODUCT.title.toUpperCase();
                let COMPARE = this.props.FILTER.TITLE.toUpperCase();

                if (STRING.indexOf(COMPARE) == -1) {
                    USE = false;
                }
            }

            if (USE) {
                PRODUCTS.push(PRODUCT)
            }
        }

        return PRODUCTS;
    }
    
    sortProducts = (PRODUCTS) =>
    {
        if (this.state.SORT) {
            if (this.state.SORT.key == 'sup_price') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => parseFloat((prev.pure_sup_price/prev.prev_sup_price-1)*100) - parseFloat((next.pure_sup_price/next.prev_sup_price-1)*100));
                } else {
                    PRODUCTS.sort((prev, next) => parseFloat((next.pure_sup_price/next.prev_sup_price-1)*100) - parseFloat((prev.pure_sup_price/prev.prev_sup_price-1)*100));
                }
            }

            if (this.state.SORT.key == 'amount') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => parseFloat(prev.real_amount) - parseFloat(next.real_amount));
                } else {
                    PRODUCTS.sort((prev, next) => parseFloat(next.real_amount) - parseFloat(prev.real_amount));
                }
            }

            if (this.state.SORT.key == 'sales_10') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => parseFloat(prev.sales_10) - parseFloat(next.sales_10));
                } else {
                    PRODUCTS.sort((prev, next) => parseFloat(next.sales_10) - parseFloat(prev.sales_10));
                }
            }

            if (this.state.SORT.key == 'on_site') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => parseFloat(prev.days_last_buy) - parseFloat(next.days_last_buy));
                } else {
                    PRODUCTS.sort((prev, next) => parseFloat(next.days_last_buy) - parseFloat(prev.days_last_buy));
                }
            }

            if (this.state.SORT.key == 'writeoffs_30') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => parseFloat(prev.writeoffs) - parseFloat(next.writeoffs));
                } else {
                    PRODUCTS.sort((prev, next) => parseFloat(next.writeoffs) - parseFloat(prev.writeoffs));
                }
            }

            if (this.state.SORT.key == 'marge') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => parseFloat(prev.marge) - parseFloat(next.marge));
                } else {
                    PRODUCTS.sort((prev, next) => parseFloat(next.marge) - parseFloat(prev.marge));
                }
            }

            if (this.state.SORT.key == 'left_sum') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => parseFloat(Math.round(prev.real_amount * prev.normal_price)) - parseFloat(Math.round(next.real_amount * next.normal_price)));
                } else {
                    PRODUCTS.sort((prev, next) => parseFloat(Math.round(next.real_amount * next.normal_price)) - parseFloat(Math.round(prev.real_amount * prev.normal_price)));
                }
            }

            if (this.state.SORT.key == 'title') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => this.stringSortAsc(prev.title, next.title));
                } else {
                    PRODUCTS.sort((prev, next) => this.stringSortDesc(prev.title, next.title));
                }
            }

            if (this.state.SORT.key == 'supplier') {
                if (this.state.SORT.dir == 'ASC') {
                    PRODUCTS.sort((prev, next) => this.stringSortAsc(prev.supplier, next.supplier));
                } else {
                    PRODUCTS.sort((prev, next) => this.stringSortDesc(prev.supplier, next.supplier));
                }
            }
        }

        return PRODUCTS;
    }

    stringSortAsc = (a, b) => {

        let nameA = a.toLowerCase();
        let nameB = b.toLowerCase();

        if (nameA < nameB)
            return -1
        if (nameA > nameB)
            return 1
        return 0
    }

    stringSortDesc = (a, b) => {

        let nameA = a.toLowerCase();
        let nameB = b.toLowerCase();

        if (nameA > nameB)
            return -1
        if (nameA < nameB)
            return 1
        return 0
    }

    getSupplier = (id, supplier, suppliers) =>
    {
        if (typeof this.props.SELECTED[id] !== "undefined") {
            if (typeof this.props.SELECTED[id].supplier_title !== "undefined") {
                supplier = this.props.SELECTED[id].supplier_title
            }
        }

        let useSupplier = suppliers.filter(sup => {
            if (sup.title == supplier) {
                return true
            }

            return false
        })

        if (useSupplier.length) {
            return useSupplier[0]
        }

        return false
    }

    renderProducts = () =>
    {
        let ROWS = []
        let PRODUCTS = this.filterProducts()
        PRODUCTS = this.sortProducts(PRODUCTS)
        let CONFIRMED = 0;
        let ALL = 0;
        for (let i = 0; i < PRODUCTS.length; i++) {
            let PRODUCT = PRODUCTS[i]

            let SUPPLIER = this.getSupplier(PRODUCT.id, PRODUCT.supplier, this.props.SUPPLIERS)

            if (typeof this.props.SELECTED[PRODUCT.id] !== "undefined") {
                ALL++;
                if (this.props.SELECTED[PRODUCT.id].confirmed) {
                    CONFIRMED++;
                }
            }

            let className='';
            if (PRODUCT.activity > 0 && PRODUCT.activity < 70) {
                className = ' low_activity'
            }

            if (PRODUCT.writeoffs < 0) {
                let write = Math.abs(PRODUCT.writeoffs)
                let sales_percents = Math.round(PRODUCT.sales_30 / 100 * 5)
                if (write >= sales_percents) {
                    className = ' much_writeoffs'
                }
            }

            ROWS.push(
                <div key={i} className={ "ordering_product" + className }>
                    <OrderingProductRow
                        ALL_SUPPLIERS={this.props.SUPPLIERS}
                        PRODUCT={PRODUCT}
                        SELECTED={this.props.SELECTED}
                        VIEW={this.props.VIEW}
                        SUPPLIER={SUPPLIER}
                        SUPPLIERS={this.props.SUPPLIERS}
                        SUPPLIERS_OPTIONS={this.props.SUPPLIERS_OPTIONS}
                        setMaximumQuantity={this.props.setMaximumQuantity}
                        setMinimumQuantity={this.props.setMinimumQuantity}
                        setSelectedAmount={this.props.setSelectedAmount}
                        setSelectedAmountValue={this.props.setSelectedAmountValue}
                        toggleConfirmation={this.props.toggleConfirmation}
                        setSupplierToOrdering={this.props.setSupplierToOrdering}
                        setQuantToOrdering={this.props.setQuantToOrdering}
                        setOrderFromDaysToOrdering={this.props.setOrderFromDaysToOrdering}
                        updateSuppliers={this.props.updateSuppliers}
                        setSpecialCounting={this.props.setSpecialCounting}
                    />
                </div>
            )
        }

        if (ALL == CONFIRMED) {
            this.selectAll(true);
        } else {
            this.selectAll(false);
        }

        return ROWS
    }


    selectAll = (bool) =>
    {
        if (this.state.SELECTED_ALL !== bool) {
            this.setState({SELECTED_ALL: bool})
        }
    }

    displaySorter = (key, obj) =>
    {
        if (obj.sortable) {
            return (
                <div className="sorter_holder">
                    <a className="sorter_button" onClick={() => this.setState({SORT: {key: key, dir: 'ASC'}})}><img src="/system_images/tr_up.png"/></a>
                    <a className="sorter_button" onClick={() => this.setState({SORT: {key: key, dir: 'DESC'}})}><img src="/system_images/tr_down.png"/></a>
                </div>
            )
        }
    }

    confirmCurrentSelection = () =>
    {
        let products = this.filterProducts()

        let ids = [];
        for (let i = 0; i < products.length; i++) {
            ids.push(products[i].id)
        }

        if (!this.state.SELECTED_ALL) {
            this.props.confirmCurrentSelection(ids)
        } else {
            this.props.unConfirmCurrentSelection(ids)
        }
    }

    getSelectAllClass = () =>
    {
        if (this.state.SELECTED_ALL) {
            return ' selected_all';
        }

        return '';
    }

    renderHeader = () =>
    {
        return (
            <div className="ordering_header">
                <div className="op_id"></div>
                <div className="op_id"></div>
                <div className="op_image"></div>
                <div className="op_title">наименование{this.displaySorter('title',{sortable: true})}</div>
                <div className="op_fav"></div>
                
                {
                    this.props.VIEW.amount && (<div className="op_amount">{this.props.ALL_VIEWS.amount.title}{this.displaySorter('amount',this.props.ALL_VIEWS.amount)}</div>)
                }
                {
                    this.props.VIEW.sales_10 && (<div className="op_sales_10">{this.props.ALL_VIEWS.sales_10.title}{this.displaySorter('sales_10',this.props.ALL_VIEWS.sales_10)}</div>)
                }
                {
                    this.props.VIEW.on_site && (<div className="op_on_site">{this.props.ALL_VIEWS.on_site.title}{this.displaySorter('on_site',this.props.ALL_VIEWS.on_site)}</div>)
                }
                {
                    this.props.VIEW.planned && (<div className="op_planned">{this.props.ALL_VIEWS.planned.title}</div>)
                }
                {
                    this.props.VIEW.minimum_quantity && (<div className="op_order_days">{this.props.ALL_VIEWS.minimum_quantity.title}</div>)
                }
                {
                    this.props.VIEW.maximum_quantity && (<div className="op_order_days">{this.props.ALL_VIEWS.maximum_quantity.title}</div>)
                }
                {
                    this.props.VIEW.order_days && (<div className="op_order_days">{this.props.ALL_VIEWS.order_days.title}</div>)
                }
                {
                    this.props.VIEW.order_quant && (<div className="op_quant">{this.props.ALL_VIEWS.order_quant.title}</div>)
                }
                {
                    this.props.VIEW.to_order && (<div className="op_to_order">{this.props.ALL_VIEWS.to_order.title}</div>)
                }
                {
                    this.props.VIEW.order_from_days && (<div className="op_quant">{this.props.ALL_VIEWS.order_from_days.title}</div>)
                }
                {
                    this.props.VIEW.order_sum && (<div className="op_order_sum">{this.props.ALL_VIEWS.order_sum.title}</div>)
                }
                {
                    this.props.VIEW.order_confirm && (<div className="op_order_confirm">{this.props.ALL_VIEWS.order_confirm.title}<br/><a className={"confirm_all" + this.getSelectAllClass()} onClick={() => this.confirmCurrentSelection()}></a></div>)
                }
                {
                    this.props.VIEW.supplier && (<div className="op_supplier">{this.props.ALL_VIEWS.supplier.title}{this.displaySorter('supplier',this.props.ALL_VIEWS.supplier)}</div>)
                }
                {
                    this.props.VIEW.till_when && (<div className="op_till_when">{this.props.ALL_VIEWS.till_when.title}</div>)
                }
                {
                    this.props.VIEW.sup_incomings && (<div className="op_sup_incomings">{this.props.ALL_VIEWS.sup_incomings.title}</div>)
                }
                {
                    this.props.VIEW.barcode && (<div className="op_barcode">{this.props.ALL_VIEWS.barcode.title}</div>)
                }
                {
                    this.props.VIEW.sup_price && (<div className="op_sup_price">{this.props.ALL_VIEWS.sup_price.title}{this.displaySorter('sup_price',this.props.ALL_VIEWS.marge)}</div>)
                }
                {
                    this.props.VIEW.writeoffs_30 && (<div className="op_writeoffs_30">{this.props.ALL_VIEWS.writeoffs_30.title}{this.displaySorter('writeoffs_30',this.props.ALL_VIEWS.writeoffs_30)}</div>)
                }
                {
                    this.props.VIEW.group && (<div className="op_group">{this.props.ALL_VIEWS.group.title}</div>)
                }
                {
                    this.props.VIEW.coef && (<div className="op_coef">{this.props.ALL_VIEWS.coef.title}</div>)
                }
                {
                    this.props.VIEW.expiration && (<div className="op_expiration">{this.props.ALL_VIEWS.expiration.title}</div>)
                }
                {
                    this.props.VIEW.manager && (<div className="op_manager">{this.props.ALL_VIEWS.manager.title}</div>)
                }
                {
                    this.props.VIEW.left_sum && (<div className="op_left_sum">{this.props.ALL_VIEWS.left_sum.title}{this.displaySorter('left_sum',this.props.ALL_VIEWS.left_sum)}</div>)
                }
                {
                    this.props.VIEW.marge && (<div className="op_marge">{this.props.ALL_VIEWS.marge.title}{this.displaySorter('marge',this.props.ALL_VIEWS.marge)}</div>)
                }
                {
                    this.props.VIEW.sup_nom && (<div className="op_sup_nom">{this.props.ALL_VIEWS.sup_nom.title}</div>)
                }

                {
                    this.props.VIEW.change_data_percent && (<div className="op_sup_nom">{this.props.ALL_VIEWS.change_data_percent.title}</div>)
                }
                {
                    this.props.VIEW.change_data_start_price && (<div className="op_sup_nom">{this.props.ALL_VIEWS.change_data_start_price.title}</div>)
                }
                {
                    this.props.VIEW.change_data_end_price && (<div className="op_sup_nom">{this.props.ALL_VIEWS.change_data_end_price.title}</div>)
                }
                {
                    this.props.VIEW.change_data_change_sum && (<div className="op_sup_nom">{this.props.ALL_VIEWS.change_data_change_sum.title}</div>)
                }
                {
                    this.props.VIEW.change_data_supplier && (<div className="op_manager">{this.props.ALL_VIEWS.change_data_supplier.title}</div>)
                }
            </div>
        )
    }

    render()
    {
        return (
            <div>
                <div className="table_header">
                    {this.renderHeader()}
                </div>
                <div className="table_body">
                    {this.renderProducts()}
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderingProducts);