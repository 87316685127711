import React, {Component} from 'react';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import HeaderWithAddress from "./YandexMaps/MapHeaderWithAddress";
import MapBackButton from "./YandexMaps/MapBackButton";
import MapIframe from "./YandexMaps/MapIframe.js";
import DeliveryInformation from "./DeliveryInformation.js";
import DeliveryMixedModalContent from "./../../APP/COMPONENTS/ContentParts/DeliveryMixedModalContent.js";

function mapStateToProps(state) {
    return {
        SHOW_MIXED_MODAL: state.SHOW_MIXED_MODAL,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DeliveryMixedModal extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.SHOW_MIXED_MODAL !== prevProps.SHOW_MIXED_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.SHOW_MIXED_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }

    }

    render() {

        if (window.innerWidth < 1200) {
            return false;
        }
        if (this.props.SHOW_MIXED_MODAL) {
            return (
                <div>
                    <div className='modal-overlay' onClick={() => this.props.openDeliveryAddressMixedModal(false)}>
                    </div>
                    <div className="modal-box mixed-modal">
                        <a className="close-modal floating" onClick={() => this.props.openDeliveryAddressMixedModal(false)}>
                            <img src={'/system_images/close.png'} />
                        </a>
                        <DeliveryMixedModalContent VIEW={this.props.SHOW_MIXED_MODAL} />
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DeliveryMixedModal);