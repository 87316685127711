import React, {Component} from 'react';
import CONFIG from './../../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state,myProps) {
    let CART = state.CART;
    let PRODUCTS = state.PRODUCTS;
    let AMOUNT = 0;
    let TOTAL_CART = 0;

    let KEYS = Object.keys(CART);
    
    if (KEYS.length) {
        for (let ID of KEYS) {
            if (typeof PRODUCTS[ID] !== "undefined") {
                if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                    AMOUNT++;
                    TOTAL_CART += CART[ID].amount;
                }
            }
        }
    }

    return {
        AMOUNT: AMOUNT,
        TOTAL_CART: TOTAL_CART,
        ADDING_TO_EXISTING: state.ADDING_TO_EXISTING
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class MobileCart extends Component {

	constructor(props)
    {
        super(props);
        let SHOW_COUNTER = false;
        if (props.ADDING_TO_EXISTING) {
            SHOW_COUNTER = true;
        }

        this.state = {
            SHOW_MESSAGE: false,
            SHOW_COUNTER: SHOW_COUNTER,
            ADDING_TO_EXISTING: props.ADDING_TO_EXISTING,
            SECONDS: 1200
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.AMOUNT > prevProps.AMOUNT) {
            if (this.props.AMOUNT == 1) {
                if (typeof this.TIMER !== "undefined") {
                    clearTimeout(this.TIMER);
                }

                this.setState({SHOW_MESSAGE: true});

                let COMPONENT = this;
                this.TIMER = setTimeout(function() { 
                    COMPONENT.setState({SHOW_MESSAGE: false});
                }
                , 3000);
            }
        }

        if (this.props.TOTAL_CART !== prevProps.TOTAL_CART) {
            if (typeof this.refs.cartCircle !== "undefined") {
                if (this.props.AMOUNT == prevProps.AMOUNT) {
                    if (this.state.SHOW_MESSAGE) {
                        this.setState({SHOW_MESSAGE: false});
                    }
                }
                
                this.refs.cartCircle.classList.add('cart-pulse');
                let COMPONENT = this;
                setTimeout(function () {
                    COMPONENT.refs.cartCircle.classList.remove('cart-pulse')
                }, 1000);
            }
        }

        if (this.props.ADDING_TO_EXISTING !== this.state.ADDING_TO_EXISTING) {
            if (this.props.ADDING_TO_EXISTING) {
                this.setState({ADDING_TO_EXISTING: this.props.ADDING_TO_EXISTING, SHOW_COUNTER: true})
                this.launchCounter();
            } else {
                if (typeof this.interval !== "undefined") {
                    this.setState({SHOW_COUNTER: false, ADDING_TO_EXISTING: false})
                    clearInterval(this.interval);
                }
            }
        }
    }

    componentDidMount = () =>
    {
        if (this.state.ADDING_TO_EXISTING) {
            this.launchCounter();
        }
    }

    launchCounter = () =>
    {
        let COMPONENT = this;
        this.interval = setInterval(function(){
            let SECONDS = COMPONENT.state.ADDING_TO_EXISTING.TIME_START - Math.floor(Date.now() / 1000);

            if (SECONDS == 0) {
                clearInterval(COMPONENT.interval);
                COMPONENT.props.startAddingProductsToExistingOrder(false);
            } else {
                COMPONENT.setState({SECONDS: SECONDS});
            }
        },1000);
    }

    renderAddingCounter = () =>
    {
        if (this.state.SHOW_COUNTER) {
            if (this.state.ADDING_TO_EXISTING) {
                let MINUTES = Math.floor(this.state.SECONDS / 60);
                let SECONDS = this.state.SECONDS - (MINUTES * 60);

                if (SECONDS == 0) {
                    SECONDS = '00';
                } else if (SECONDS < 10) {
                    SECONDS = '0' + SECONDS;
                }

                return (
                    <div className="adding_counter">
                        {MINUTES}:{SECONDS}
                    </div>
                );
            }
        }
    }

    showAddedMessage = () =>
    {
        let MESSAGE = false;
        if (this.state.SHOW_MESSAGE) {
            MESSAGE = <div id="first-time-add">Выбранный товар лежит в корзине</div>;
        }
        return (
            <CSSTransitionGroup 
                transitionName="fade"
                transitionEnterTimeout={900}
                transitionLeaveTimeout={900}
            >
             {MESSAGE}
            </CSSTransitionGroup>
        );
    }

    showAddedMessage = () =>
    {
        let MESSAGE = false;
    	if (this.state.SHOW_MESSAGE) {
            MESSAGE = <div id="first-time-add">Выбранный товар лежит в корзине</div>;
        }
		return (
            <CSSTransitionGroup 
                transitionName="fade"
                transitionEnterTimeout={900}
                transitionLeaveTimeout={900}
            >
			 {MESSAGE}
            </CSSTransitionGroup>
		);
    }

    showAmountCircle()
    {
        if (this.props.AMOUNT > 0) {
            return (
                <div className="cart-amount-label">{this.props.AMOUNT}</div>
            );
        }
    }

	render() {
        let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/tabs/white-cart.png';

		return (
			<div className="top_cart" onClick={() => this.props.openCart(true)}>
                {this.showAddedMessage()}
				<a ref="cartCircle" className="top-bar-cart white">
		        	<img src={IMAGE_SRC} />
                    {this.showAmountCircle()}
                    {this.renderAddingCounter()}
		        </a>
                
			</div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileCart)
