import React, {Component} from 'react';
import {Switch, Route,withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CatPreview from './../../APP/COMPONENTS/ContentParts/CatPreview'
import Adverts from './../../APP/COMPONENTS/ContentParts/Adverts'

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import MobileLocationData from "../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

import TagsSlider from "../COMPONENTS/ContentParts/TagsSlider";

function mapStateToProps(state, myProps) {
    return {
        DO_SEARCH: state.DO_SEARCH,
        PRODUCTS: state.PRODUCTS,
        REGION: state.REGION
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class HomePage extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            REGION: props.REGION,
            PRODUCTS: props.PRODUCTS
        }
    }

    componentDidMount()
    {
        this.props.changeOrderingView(false)
        this.props.closeAllModals();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
        if (this.props.PRODUCTS !== prevProps.PRODUCTS) {
            this.setState({PRODUCTS: true});
        }
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    checkUpdates()
    {
        if (this.state.PRODUCTS !== this.props.PRODUCTS) {
            if (!this.state.PRODUCTS) {
                this.setState({UPDATED: true});
            }
        }
    }

    renderAdvert()
    {
        return <Adverts />;
    }

    getH1 = () =>
    {
        if (this.state.REGION == 78) {
            return 'Доставка продуктов на дом';
        } else {
            return 'Доставка продуктов на дом за 90 минут';
        }
    }

    getTitle = () =>
    {
        switch (this.state.REGION) {
            case 78:
                return 'Интернет-магазин Экомаркет — доставка продуктов на дом в Санкт-Петербурге и Ленинградской области, купить продукты питания с доставкой в онлайн-магазине';
                break;
            default:
                return 'Интернет-магазин Экомаркет — доставка продуктов на дом в Москве и Московской области, купить продукты питания с доставкой в онлайн-магазине';
                break;
        }
    }

    getKeywords = () =>
    {
        switch (this.state.REGION) {
            case 78:
                return 'Доставка продуктов Санкт-Петербург';
                break;
            default:
                return 'Доставка продуктов Москва';
                break;
        }
    }

    getDescription = () =>
    {
        switch (this.state.REGION) {
            case 78:
                return 'Доставка био, экопродуктов, всего за 90 минут по Санкт-Петербургу и области. Огромный ассортимент, более 1000 наименований.В наличии Халяльные и Кошерные продукты. Правильное и здоровое питание с Экомаркетом!';
                break;
            default:
                return 'Доставка био, экопродуктов, всего за 90 минут по Москве и области. Огромный ассортимент, более 1000 наименований.В наличии Халяльные и Кошерные продукты. Правильное и здоровое питание с Экомаркетом!';
                break;
        }
    }

    renderRetailOne = () =>
    {
        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return <div data-retailrocket-markup-block="5f7d811c97a5282bb875ce1b"></div>
        } else {
            return <div data-retailrocket-markup-block="5f7da2a597a5282bb875d393"></div>
        }
    }

    renderRetailTwo = () =>
    {
        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return <div data-retailrocket-markup-block="5f7d812697a5251aec3fcc49"></div>
        } else {
            return <div data-retailrocket-markup-block="5f7da2b597a5251aec3fd144"></div>
        }
    }

    renderRetailThree = () =>
    {
        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return <div data-retailrocket-markup-block="5f7d813897a5282bb875ce1f"></div>
        } else {
            return <div data-retailrocket-markup-block="5f7da2c897a5251aec3fd146"></div>
        }
    }

	render() {
        if (!this.state.PRODUCTS) {
            return false;
        }
        
    	return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <Helmet>
                    <title>{this.getTitle()}</title>
                    <meta name="description" content={this.getDescription()} />
                    <meta name="keywords" content={this.getKeywords()} />
                </Helmet>
        		<div className="right-part transparent">
                    <CatPreview />
                    {this.renderRetailThree()}
                    <h1 className="main_h1">{this.getH1()}</h1>

                    <ModalsContainer />
    			</div>
                <MobileMessage />
            </CSSTransitionGroup> 
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(HomePage))
