import React, {Component} from 'react';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {checkRegionEntry} from "../HELPERS/CoordinatesHelders/DefinitionOfTheRegion";
import regionsJson from "../../Jsons/Delivery.json";
import {checkIdPointInsideCity} from "../HELPERS/CoordinatesHelders/CheckIdPointInsideCity";
import regionJson from "../../Jsons/Delivery.json"

function mapStateToProps(state) {
    return {
        CITIES_SELECTOR_MODAL: state.CITIES_SELECTOR_MODAL,
        ADRESS: state.ADRESS,
        GPS: state.GPS,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CitiesSelectorModal extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.CITIES_SELECTOR_MODAL !== prevProps.CITIES_SELECTOR_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.CITIES_SELECTOR_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    regionChecker=(city)=>{
        if (parseInt(this.props.REGION) !== parseInt(city)) {
            this.props.setUseablePeriods({PERIODS: false, KM: false, MINUTES: false, INSIDE_MKAD: false});
            this.props.setAdressAndGpsAndRegion({KM: false, ADRESS: false, GPS: false, SHOWADRESS: false});
            this.props.openRegionSelectorCities(false)
        }
        this.props.changeRegion(parseInt(city));
        this.props.openRegionSelectorCities(false)
    }

    styleChanger = (city) =>{
        // if(this.props.REGION == (parseInt(city))){
        //     return "city_text_green"
        // }
        // else return "city_text"
        return "city_text"
    }

    closeRegionModalOpenMap = () =>
    {
        this.props.openRegionSelectorCities(false)
        if (window.innerWidth < 1200) {
            this.props.openDeliveryAddressModal(true);
        } else {
            this.props.openDeliveryAddressMixedModal('adress');
        }
    }



    render() {
        if (this.props.CITIES_SELECTOR_MODAL) {
            return (
                <div>
                    <div className='modal-overlay' onClick={() => this.props.openRegionSelectorCities(false)}>
                    </div>
                    <div className="modal-box region_city_selector">
                            <img className='close_cross' src="/system_images/close_inactive.png" onClick={() => this.props.openRegionSelectorCities(false)}/>
                        <div className="city_selector_holder">
                            <div className="city_selector_big_text">Выберите ваш <br/>регион</div>
                            <div className='cities_title'>
                                <div className={this.styleChanger(77)} onClick={() =>this.regionChecker(77)}>Москва и Московская область</div>
                                <div className={this.styleChanger(78)} onClick={() =>this.regionChecker(78)}>Санкт-Петербург и Лениградская область</div>
                                <div className={this.styleChanger(16)} onClick={() =>this.regionChecker(16)}>Казань и Республика Татарстан</div>
                                <div className={this.styleChanger(52)} onClick={() =>this.regionChecker(52)}>Нижний Новгород и Нижегородская область</div>
                                <div className={this.styleChanger(61)} onClick={() =>this.regionChecker(61)}>Ростов-на-Дону и Ростовская область</div>
                                {/*<div className={this.styleChanger(62)} onClick={() =>this.regionChecker(62)}>Рязань и Рязанская область</div>*/}
                                <div className={this.styleChanger(36)} onClick={() =>this.regionChecker(36)}>Воронеж и Воронежская область</div>
                                <div className={this.styleChanger(23)} onClick={() =>this.regionChecker(23)}>Краснодар и Краснодарский край</div>
                                <div className="city_text own_region" onClick={() =>this.closeRegionModalOpenMap()}>Указать другой регион</div>
                            </div>
                        </div>


                    </div>
                </div>
            );
        }
        else {
            return ""
        }
    }


}
export default connect(
    mapStateToProps, mapDispatchToProps
)(CitiesSelectorModal);