import React, { Component } from 'react';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SelectSearch, { fuzzySearch } from 'react-select-search';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { isActive } from "./../../APP/HELPERS/requests"

/// PARTS ///
import Logo from './../../APP/COMPONENTS/HeaderParts/Logo';
import SearchBlock from './../../APP/COMPONENTS/HeaderParts/SearchBlock';
import MobileNavToggle from './../../APP/COMPONENTS/HeaderParts/MobileNavToggle';
import HeadCart from './../../APP/COMPONENTS/HeaderParts/HeadCart';
import Tabs from './../../APP/COMPONENTS/HeaderParts/Tabs';
import Tops from './../../APP/COMPONENTS/HeaderParts/Tops';
import TopNavs from './../../APP/COMPONENTS/HeaderParts/TopNavs';

import NavBar from './../../APP/COMPONENTS/HeaderParts/NavBar';
import MobileSearchBlock from './../../APP/COMPONENTS/HeaderParts/MobileSearchBlock';

import UserBlock from './../../APP/COMPONENTS/HeaderParts/NavBar/UserBlock';
import FavsBlock from './../../APP/COMPONENTS/HeaderParts/NavBar/FavsBlock';

import ToggleButton from "./../../APP/COMPONENTS/ContentParts/ToggleButton";

import Slider from "./../../APP/COMPONENTS/ContentParts/Slider";

const createXMLTable = (table, fileName) => {
	const xmlTable = `
		  <html xmlns:o="urn:schemas-microsoft-com:office:office xmlns:x="urn:schemas-microsoft-com:office:excel"
		 xmlns="http://www.w3.org/TR/REC-html40"
		  >
			 <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
			 <head>
				<xml>
				  <x:ExcelWorkbook>
					  <x:ExcelWorksheets>
						  <x:ExcelWorksheet>
							  <x:Name>${fileName}</x:Name>
							  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
						  </x:ExcelWorksheet>
					  </x:ExcelWorksheets>
				  </x:ExcelWorkbook>
				</xml>
			 </head>
			 <body>
			   ${table}
			 </body>
		  </html> `
	return xmlTable;
}

const createFileUrl = (xmlTable) => {
	const tableBlob = new Blob([xmlTable], { type: 'application/vnd.ms-excel;base64,' });
	const downloadURL = URL.createObjectURL(tableBlob);
	return downloadURL;
}

const downloadFile = (downloadURL, fileName) => {
	const downloadLink = document.createElement('a');
	document.body.appendChild(downloadLink);
	downloadLink.download = fileName;
	downloadLink.href = downloadURL;
	downloadLink.click();
}


function mapStateToProps(state) {
	return {
		ORDERING_VIEW: state.ORDERING_VIEW,
		SHOW_REGION_HELPER: state.SHOW_REGION_HELPER,
		REGION: state.REGION,
		ADDING_TO_EXISTING: state.ADDING_TO_EXISTING,
		SHOW_INACTIVE: state.SHOW_INACTIVE,
		MANAGERS: state.MANAGERS,
		MANAGER: state.MANAGER,
		SUPPLIERS: state.SUPPLIERS,
		SUPPLIER: state.SUPPLIER,
		SPEC_FILTER: state.SPEC_FILTER,
		SHOP: state.SHOP,
		PRODUCTS: state.PRODUCTS,
		MARGE_FILTER: state.MARGE_FILTER,
		WRITE_FILTER: state.WRITE_FILTER,
		REPACK: state.REPACK

	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;


class header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SHOW_REGION_HELPER: props.SHOW_REGION_HELPER,
			REGION: props.REGION,
			ADDING_TO_EXISTING: props.ADDING_TO_EXISTING,
			MIN_MARGE: 0,
			MAX_MARGE: 100,
			MIN: 0,
			MAX: 100,
			MARGE_COUNTED: false,
			WRITEOFF_COUNTED: false,
			MIN_WRITEOFF: 0,
			MAX_WRITEOFF: 100,
			MIN_W: 0,
			MAX_W: 100,
			REPACK: 0
		}
	}

	componentDidMount() {
		window.addEventListener("scroll", this.toggleBodyClass);
		this.getMarge();
		this.getWriteoffs();
	}

	getWriteoffs = () => {
		if (this.props.PRODUCTS && !this.state.WRITEOFF_COUNTED) {
			let MIN = 99999999999999;
			let MAX = -9999999999999;

			let PRODUCTS = [];
			for (let i in this.props.PRODUCTS) {
				let PRODUCT = this.props.PRODUCTS[i];
				if (isActive(PRODUCT, this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER, this.props.WRITE_FILTER)) {
					if (this.props.PRODUCTS[i].writeoffs < MIN) {
						MIN = this.props.PRODUCTS[i].writeoffs
					}

					if (this.props.PRODUCTS[i].writeoffs > MAX) {
						MAX = this.props.PRODUCTS[i].writeoffs
					}
				}
			}

			if (MIN !== 99999999999999) {
				let MIN_W = parseInt(MIN)
				let MAX_W = parseInt(MAX)

				this.setState({ MIN_WRITEOFF: MIN_W, MIN_W: MIN_W, MAX_W: MAX_W, MAX_WRITEOFF: MAX_W, WRITEOFF_COUNTED: true })
			}
		}

	}

	getMarge = () => {
		if (this.props.PRODUCTS && !this.state.MARGE_COUNTED) {
			let MIN = 99999999999999;
			let MAX = -9999999999999;

			let PRODUCTS = [];
			for (let i in this.props.PRODUCTS) {
				let PRODUCT = this.props.PRODUCTS[i];
				if (isActive(PRODUCT, this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER, this.props.WRITE_FILTER)) {
					if (this.props.PRODUCTS[i].marge < MIN) {
						MIN = this.props.PRODUCTS[i].marge
					}

					if (this.props.PRODUCTS[i].marge > MAX) {
						MAX = this.props.PRODUCTS[i].marge
					}

					if (this.props.PRODUCTS[i].marge < -200) {
						PRODUCTS.push(this.props.PRODUCTS[i].title + ' - ' + this.props.PRODUCTS[i].marge)
					}
				}
			}

			if (MIN !== 99999999999999) {
				MIN = parseInt(MIN)
				MAX = parseInt(MAX)

				this.setState({ MIN_MARGE: MIN, MIN: MIN, MAX: MAX, MAX_MARGE: MAX, MARGE_COUNTED: true })
			}
		}
	}

	renderAddingMessage = () => {
		if (this.state.SHOW_ADDING_MESSAGE) {
			return (
				<div className="main_adding_message">
					У вас есть 20 минут.<br />Успейте добавить товар, пока заказ<br />находится на первой стадии сборки!
				</div>
			);
		} else if (this.state.SHOW_ENDING_MESSAGE) {
			return (
				<div className="main_adding_message">
					К сожалению время на добавление<br />товаров к заказу подошло к концу.<br />Добавленные товары сохранены<br />в корзине.
				</div>
			);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.toggleBodyClass);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.PRODUCTS !== prevProps.PRODUCTS || this.props.SPEC_FILTER !== prevProps.SPEC_FILTER) {
			this.getMarge();
			this.getWriteoffs();
		}

		if (this.props.REGION !== prevProps.REGION) {
			this.setState({ REGION: this.props.REGION });
		}
		if (this.props.ADDING_TO_EXISTING !== prevProps.ADDING_TO_EXISTING) {
			if (this.props.ADDING_TO_EXISTING) {
				if (!this.state.ADDING_TO_EXISTING) {
					this.setState({ ADDING_TO_EXISTING: true, SHOW_ADDING_MESSAGE: true });

					let COMPONENT = this;
					setTimeout(function () {
						COMPONENT.setState({ SHOW_ADDING_MESSAGE: false })
					}, 5000);
				}
			} else {
				this.setState({ ADDING_TO_EXISTING: false, SHOW_ADDING_MESSAGE: false, SHOW_ENDING_MESSAGE: true });
				let COMPONENT = this;
				setTimeout(function () {
					COMPONENT.setState({ SHOW_ENDING_MESSAGE: false })
				}, 5000);
			}
		}
	}

	toggleBodyClass = () => {
		if (window.scrollY > 100) {
			document.body.classList.add("floating");
		} else {
			document.body.classList.remove("floating");
		}
	};

	renderPhone = () => {
		let PHONE = '8(495)4450505';

		switch (parseInt(this.state.REGION)) {
			case 77:
				PHONE = '8(495)4450505';
				break;
			case 78:
				PHONE = '8(812)5661006';
				break;
			case 16:
				PHONE = '8(843)5005103';
				break;
			case 52:
				PHONE = '8(920)0661652';
				break;

		}

		return (
			<a href={ 'tel: ' + PHONE }>
				{ PHONE }
			</a>
		);
	}

	get(name) {
		if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(window.location.search))
			return decodeURIComponent(name[1]);
	}

	getStyle = () => {
		if (this.props.history.location.pathname == "/ordering") {
			return ' no_stick';
		}

		if (this.get('hide_all_navs')) {
			return ' hidden_true';
		}

		if ((this.props.history.location.pathname == "/company/bars") || (this.props.history.location.pathname == "/company/zoo") || (this.props.history.location.pathname == "/company/spbzoo") || (this.props.history.location.pathname == "/company/save-nature")) {
			return ' hidden_true';
		}

		return '';
	}

	renderInactiveToggle = () => {
		return (
			<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
				<label style={ { marginRight: '20px' } } htmlFor="agree">
					<span>Добавить неактивные</span>
				</label>
				<ToggleButton isOn={ this.props.SHOW_INACTIVE } onToggle={ () => {
					this.props.setShowInactive(!this.props.SHOW_INACTIVE)
				} } />
			</div>
		)
	}

	setSpec = (type) => {
		let SPEC_FILTER = Object.assign({}, this.props.SPEC_FILTER)
		SPEC_FILTER[type] = SPEC_FILTER[type] == true ? 0 : SPEC_FILTER[type] == false ? 1 : SPEC_FILTER[type] == 1 ? 0 : 1;

		this.props.setSpecFilter(SPEC_FILTER)
	}

	renderSpecToggles = () => {
		return (
			<>
				<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
					<label style={ { marginRight: '20px' } } htmlFor="agree">
						<span>Важные</span>
					</label>
					<ToggleButton isOn={ this.props.SPEC_FILTER.important } onToggle={ () => {
						this.setSpec('important')
					} } />
				</div>
				<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
					<label style={ { marginRight: '20px' } } htmlFor="agree">
						<span>Cезонные</span>
					</label>
					<ToggleButton isOn={ this.props.SPEC_FILTER.season } onToggle={ () => {
						this.setSpec('season')
					} } />
				</div>
				<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
					<label style={ { marginRight: '20px' } } htmlFor="agree">
						<span>Фасовочные</span>
					</label>
					<ToggleButton isOn={ this.props.SPEC_FILTER.topack } onToggle={ () => {
						this.setSpec('topack')
					} } />
				</div>

				<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
					<label style={ { marginRight: '20px' } } htmlFor="agree">
						<span>Закрытые с остатками</span>
					</label>
					<ToggleButton isOn={ this.props.SPEC_FILTER.archive } onToggle={ () => {
						this.setSpec('archive')
					} } />
				</div>
				<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
					<label style={ { marginRight: '20px' } } htmlFor="agree">
						<span>Закрытые</span>
					</label>
					<ToggleButton isOn={ this.props.SPEC_FILTER.archive_all } onToggle={ () => {
						this.setSpec('archive_all')
					} } />
				</div>
				<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
					<label style={ { marginRight: '20px' } } htmlFor="agree">
						<span>Только с нулевыми остатками</span>
					</label>
					<ToggleButton isOn={ this.props.SPEC_FILTER.zeroes } onToggle={ () => {
						this.setSpec('zeroes')
					} } />
				</div>
				<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
					<label style={ { marginRight: '20px' } } htmlFor="agree">
						<span>Активные + Неактивные</span>
					</label>
					<ToggleButton isOn={ this.props.SPEC_FILTER.alls } onToggle={ () => {
						this.setSpec('alls')
					} } />
				</div>
				{ this.state.MARGE_COUNTED &&
					<><div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
						<label style={ { marginRight: '20px' } } htmlFor="agree">
							<span>Маржинальность</span>
						</label>
					</div>
						<Slider
							MIN={ this.state.MIN_MARGE }
							MAX={ this.state.MAX_MARGE }
							MIN_VAL={ this.state.MIN }
							MAX_VAL={ this.state.MAX }
							recordValues={ this.recordValues }
							noSlider
						/></>
				}
				{ this.state.WRITEOFF_COUNTED &&
					<><div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
						<label style={ { marginRight: '20px' } } htmlFor="agree">
							<span>Списания</span>
						</label>
					</div>
						<Slider
							MIN={ this.state.MIN_WRITEOFF }
							MAX={ this.state.MAX_WRITEOFF }
							MIN_VAL={ this.state.MIN_W }
							MAX_VAL={ this.state.MAX_W }
							recordValues={ this.recordValues2 }
							noSlider
						/></>
				}
				<div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
					<label style={ { marginRight: '20px' } } htmlFor="agree">
						<span>Только выключенные</span>
					</label>
					<ToggleButton isOn={ this.props.SPEC_FILTER.archive_only } onToggle={ () => {
						this.setSpec('archive_only')
					} } />
				</div>
			</>
		)
	}

	recordValues = (values) => {
		this.props.setMargeFilter(values)
	}

	recordValues2 = (values) => {
		this.props.setWriteoffFilter(values)
	}

	setmanager = (val) => {
		let value = val;
		if (value == 'Менеджер') {
			value = '';
		}
		this.props.setManager(value);
	}

	renderManagersSelect = () => {
		let options = [];
		let added = [];

		if (typeof this.props.MANAGERS !== "undefined") {
			for (let i in this.props.MANAGERS) {
				let option = { name: i, value: i };
				if (!added.includes(i)) {
					options.push(option)
					added.push(i);
				}
			}
		}

		return (
			<div style={ { display: 'flex', alignItems: 'center', marginLeft: '10px' } } className="p_check agree">
				{ this.props.MANAGER.length > 0 ? (
					<a className="reset_search_select" onClick={ () => this.props.setManager('') }>
						<img src="/system_images/green_cross_modal.png" />
					</a>
				) : false }
				<SelectSearch filterOptions={ fuzzySearch } search={ true } id="manager" value={ this.props.MANAGER } name="manager" options={ options } placeholder="Менеджер" onChange={ (val) => this.setmanager(val) } />
			</div>
		);
	}

	renderShopSelect = () => {
		let options = [];
		let added = []
		let shops = [
			{ title: 'мама (Все)', value: '' },
			{ title: 'Ecomarket', value: 'ecomarket' },
			{ title: 'Season', value: 'seasonmarket' },
			{ title: 'Halal', value: 'halal' }
		]

		for (let i = 0; i < shops.length; i++) {
			let option = { name: shops[i].title, value: shops[i].value };
			options.push(option)
		}


		return (
			<div style={ { display: 'flex', alignItems: 'center', marginLeft: '10px' } } className="p_check agree">
				{ this.props.SHOP.length > 0 ? (
					<a className="reset_search_select" onClick={ () => this.props.setShop('') }>
						<img src="/system_images/green_cross_modal.png" />
					</a>
				) : false }
				<SelectSearch filterOptions={ fuzzySearch } search={ true } id="shop" value={ this.props.SHOP } name="shop" options={ options } placeholder="Магазин" onChange={ (val) => this.props.setShop(val) } />
			</div>
		);
	}

	supplier = (val) => {
		let value = val;
		if (value == 'Поставщик') {
			value = '';
		}
		this.props.setSupplier(value);
	}

	renderSupplierSelect = () => {
		let options = [];
		let added = [];

		if (typeof this.props.SUPPLIERS !== "undefined") {
			for (let i = 0; i < this.props.SUPPLIERS.length; i++) {
				let option = { name: this.props.SUPPLIERS[i], value: this.props.SUPPLIERS[i] };
				if (!added.includes(this.props.SUPPLIERS[i])) {
					options.push(option)
					added.push(this.props.SUPPLIERS[i]);
				}
			}
		}

		return (
			<div style={ { display: 'flex', alignItems: 'center', marginLeft: '10px' } } className="p_check agree">
				{ this.props.SUPPLIER.length > 0 ? (
					<a className="reset_search_select" onClick={ () => this.props.setSupplier('') }>
						<img src="/system_images/green_cross_modal.png" />
					</a>
				) : false }
				<SelectSearch filterOptions={ fuzzySearch } search={ true } id="supplier" value={ this.props.SUPPLIER } name="supplier" options={ options } placeholder="Поставщик" onChange={ (val) => this.supplier(val) } />
			</div>
		);
	}

	save2xls = () => {

		let data = [
			[
				'id', 'название', 'закупочная', 'eco', 'sm', 'halal', 'менеджер', 'поставщик'
			]
		]

		for (let i in this.props.PRODUCTS) {
			let product = this.props.PRODUCTS[i]
			if (isActive(product, this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER)) {
				data.push(
					[
						product.id,
						product.title,
						parseInt(product.sup_price),
						parseInt(product.price),
						parseInt(product.sm_price),
						parseInt(product.halal_price),
						product.manager,
						product.supplier
					]
				)
			}
		}

		let filename = 'Экспорт с зеркала';
		this.convertArrayToTable(data, filename)
	}

	convertArrayToTable = async (apiArray, fileName) => {
		//use keys from the first array object to form table column headers
		const tableHeaders = `<tr>${Object.keys(apiArray[0]).map(key => `<td>${key}</td>`).join('')}</tr>`;
		//now loop through all array objects to form table rows
		const tableRows = apiArray.map(obj =>
			[`<tr>
			  ${Object.keys(obj).map(key => `<td>${obj[key] === null || obj[key] === '' ? '' : obj[key]}</td>`).join('')}
		   </tr>`]).join('');
		const table = `<table>${tableHeaders}${tableRows}</table>`.trim();


		const xmlTable = createXMLTable(table, fileName);
		const downloadURL = createFileUrl(xmlTable);
		downloadFile(downloadURL, fileName);
	}

	renderManagersPercents = () => {
		let managers = {};

		if (typeof this.props.PRODUCTS !== "undefined") {
			for (let i in this.props.PRODUCTS) {
				let product = this.props.PRODUCTS[i]
				if (product.price_change_data && product.price_change_data.percent > 1) {
					if (typeof managers[product.manager] == "undefined") {
						managers[product.manager] = [];
					}

					managers[product.manager].push(product.id)
				}
			}
		}

		if (Object.keys(managers).length) {
			let blocks = [];
			for (let i in managers) {
				blocks.push(
					<Link to={ "/ordering?manager=" + i } key={ i }>
						<div onClick={ () => this.props.managerChanged() }>
							{ i } <span>({ managers[i].length })</span>
						</div>
					</Link>
				)
			}

			return <div className="managers_price_change_holder">
				{ blocks }
			</div>
		}

		return null;
	}

	render() {

		return (
			<div className={ "left-part" + this.getStyle() }>
				<header className="header-wrap inner">
					<section id="topping">

						<SearchBlock />

						<div className="he_filter">
							<span>Показать фильтр</span>
							<div className="he_filter_body">
								{ this.renderShopSelect() }

								{ this.renderSupplierSelect() }

								{ this.renderManagersSelect() }

								{ this.renderSpecToggles() }
								<div className="repack_block">
									<span>Перетарка</span>
									<input type="number" min="0" value={ this.props.REPACK } onChange={ (e) => this.props.setRepack(e.target.value) } />
								</div>
							</div>
						</div>

						<a className="xls_btn" onClick={ () => this.save2xls() }>XLS</a>

						{ !this.props.ORDERING_VIEW ? <Link to="/ordering" className="xls_btn">Заказы</Link> : <Link to="/" className="xls_btn">Главная</Link> }

						{/* {this.renderManagersPercents()} */ }


						<UserBlock />

					</section>

					{ !this.props.ORDERING_VIEW && <NavBar /> }
				</header>
				{ this.renderAddingMessage() }
			</div>
		);
	}

}



export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(header))
