import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import OrderProductSearch from './../../APP/COMPONENTS/ContentParts/OrderProductSearch.js';

import { CSSTransitionGroup } from 'react-transition-group';
import SelectSearch, { fuzzySearch } from 'react-select-search';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        SUPPLIERS: state.SUPPLIERS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            USER: this.props.USER,
            LOADED: false,
            ORDER: false,
            STATUS: 0,
            PRODUCTS: []
        }
    }


    componentDidMount = () => {
        this.props.changeOrderingView(true)
        this.fetchOrderData()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.USER !== prevProps.USER) {
            this.setState({ USER: this.props.USER });
        }
    }

    fetchOrderData = async () => {
        let id = this.props.match.params.id;

        try {
            let SEND_DATA = {
                action: 'admin_supplierOrderData',
                id: id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ ORDER: data, LOADED: true, STATUS: data.status, PRODUCTS: this.prepareProducts(data.products) });
            } else {
                this.setState({ LOADED: true });
            }
        } catch (error) {
        }
    }

    prepareProducts = (basic_products) => {
        let PRODUCTS = [];
        for (let i = 0; i < basic_products.length; i++) {
            let PRODUCT = basic_products[i]
            PRODUCT.startup = true;

            PRODUCTS.push(PRODUCT)
        }
        return PRODUCTS;
    }

    saveOrderData = async () => {
        if (!this.state.SAVING) {
            this.setState({ SAVING: true })

            let id = this.props.match.params.id;

            let products = [];
            for (let i = 0; i < this.state.PRODUCTS.length; i++) {
                if (this.state.PRODUCTS[i].confirmed) {
                    products.push(this.state.PRODUCTS[i])
                }
            }

            try {
                let SEND_DATA = {
                    action: 'admin_supplierConfirmOrder',
                    id: id,
                    delivery_date: this.state.ORDER.delivery_date,
                    delivery_time: this.state.ORDER.delivery_time,
                    products: products,
                    apikey: this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.fetchOrderData();
                    this.setState({ SAVING: false })
                } else {
                    this.setState({ SAVING: false })
                }
            } catch (error) {
                this.setState({ SAVING: false })
            }
        }
    }

    cancelOrder = async () => {
        if (!this.state.CANCELLING) {
            this.setState({ CANCELLING: true })

            let id = this.props.match.params.id;

            try {
                let SEND_DATA = {
                    action: 'admin_supplierCancelOrder',
                    id: id,
                    apikey: this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.fetchOrderData();
                    this.setState({ CANCELLING: false })
                } else {
                    this.setState({ CANCELLING: false })
                }
            } catch (error) {
                this.setState({ CANCELLING: false })
            }
        }
    }

    setNewSupplier = async (supplier) => {
        this.setState({ CHANGING_SUP: true })

        let id = this.props.match.params.id;

        try {
            let SEND_DATA = {
                action: 'admin_changeOrderSupplier',
                id: id,
                supplier: supplier,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.fetchOrderData();
                this.setState({ CHANGING_SUP: false })
            } else {
                this.setState({ CHANGING_SUP: false })
            }
        } catch (error) {
            this.setState({ CHANGING_SUP: false })
        }
    }

    changeDeliveryDateNow = async (delivery_date) => {
        this.setState({ CHANGING_STATUS: true })

        let id = this.props.match.params.id;

        try {
            let SEND_DATA = {
                action: 'admin_changeDeliveryDate',
                id: id,
                delivery_date: delivery_date,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.fetchOrderData();
                this.setState({ CHANGING_STATUS: false })
            } else {
                this.setState({ CHANGING_STATUS: false })
            }
        } catch (error) {
            this.setState({ CHANGING_STATUS: false })
        }
    }

    changeOrderStatus = async (status) => {
        this.setState({ CHANGING_STATUS: true })

        let id = this.props.match.params.id;

        try {
            let SEND_DATA = {
                action: 'admin_changeOrderStatus',
                id: id,
                status: status,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.fetchOrderData();
                this.setState({ CHANGING_STATUS: false })
            } else {
                this.setState({ CHANGING_STATUS: false })
            }
        } catch (error) {
            this.setState({ CHANGING_STATUS: false })
        }
    }

    renderStatusBadge = (status) => {
        let title = 'Новый';
        let className = 'new';

        switch (parseInt(status)) {
            case 1:
                title = 'На согласовании';
                className = 'talk';
                break;
            case 2:
                title = 'Ожидание поставки';
                className = 'waiting';
                break;
            case 3:
                title = 'Отменён';
                className = 'cancelled';
                break;
            case 4:
                title = 'Принят';
                className = 'done';
                break;
        }

        return <span className={ 'order_status ' + className }>{ title }</span>
    }

    renderStatusSelect = (status) => {
        if (parseInt(status) < 4) {
            let options = []
            options.push(
                <option key={ 0 } value={ 0 }>Новый</option>
            )
            options.push(
                <option key={ 1 } value={ 1 }>На согласовании</option>
            )
            options.push(
                <option key={ 2 } value={ 2 }>Ожидание поставки</option>
            )
            options.push(
                <option key={ 3 } value={ 3 }>Отменён</option>
            )
            options.push(
                <option key={ 4 } value={ 4 }>Принят</option>
            )
            return (
                <div className="order_status_change">
                    <h6>Изменить статус:</h6>
                    <select
                        onChange={ (select) => { this.changeOrderStatus(select.target.value) } }
                        defaultValue={ this.state.STATUS }
                    >
                        { options }
                    </select>
                </div>
            )
        }
    }

    renderSupplierChangeSelect = () => {
        if (this.state.ORDER.status > 1) {
            return null
        }

        let options = [];
        let added = [];

        if (typeof this.props.SUPPLIERS !== "undefined") {
            for (let i = 0; i < this.props.SUPPLIERS.length; i++) {
                let option = { name: this.props.SUPPLIERS[i], value: this.props.SUPPLIERS[i] };
                if (!added.includes(this.props.SUPPLIERS[i])) {
                    options.push(option)
                    added.push(this.props.SUPPLIERS[i]);
                }
            }
        }

        return (
            <div className="order_status_change">
                <h6>Изменить поставщика:</h6>
                <SelectSearch filterOptions={ fuzzySearch } search={ true } id="supplier" value={ this.props.SUPPLIER } name="supplier" options={ options } placeholder="Поставщик" onChange={ (val) => this.changeSupplier(val) } />
            </div>
        );
    }

    changeSupplier = (selected) => {
        let confirmed = window.confirm("Вы уверены, что хотите поменять поставщика?");
        if (confirmed) {
            this.setNewSupplier(selected);
        }
    }

    renderDeliverydateSelect = () => {
        if (this.state.ORDER.status > 3) {
            return (
                <div className="order_static_row">
                    Дата поставки: { this.state.ORDER.delivery_date }
                </div>
            )
        }

        return (
            <div className="order_status_change">
                <h6>Изменить дату поставки:</h6>
                <input
                    type="date"
                    name="delivery_date"
                    onChange={ (input) => this.changeDeliveryDateNow(input.target.value) }
                    value={ this.state.ORDER.delivery_date }
                />
            </div>
        )
    }

    renderIntroBlock = () => {
        return (
            <div className="order_head">
                { this.renderStatusBadge(this.state.ORDER.status) }

                <div className="order_static_row">
                    Дата заказа: { this.state.ORDER.order_date }
                </div>
                { this.renderDeliverydateSelect() }

                <div className="order_static_row">
                    Время поставки: { this.state.ORDER.delivery_time }
                </div>
                <div className="order_static_row">
                    Поставщик: { this.state.ORDER.supplier_title }
                </div>
                <div className="order_static_row">
                    Менеджер: { this.state.ORDER.manager_name }
                </div>
                {
                    this.state.ORDER.comment.length ?
                        <div className="order_static_row">
                            Комментарий: { this.state.ORDER.comment }
                        </div>
                        : null
                }
                { this.renderStatusSelect(this.state.ORDER.status) }
                { this.renderSupplierChangeSelect() }
                { this.renderXlsSaveButton() }
            </div>
        );
    }

    renderXlsSaveButton = () => {
        if (this.state.LOADED && this.state.ORDER.status != 4) {
            return (
                <ReactHTMLTableToExcel
                    id="products_table_btn"
                    className="filter_btn"
                    table="products_table_saveable"
                    filename={ 'Заказ ' + this.state.ORDER.id }
                    sheet="ecomarket_products"
                    buttonText="Сохранить в XLS"
                />
            );
        }
    }

    renderImage = (PRODUCT) => {
        let SRC = PRODUCT.images.thumb;
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            return (
                <img
                    className="product_image"
                    src={ CONFIG.API_C_DOMAIN + SRC }
                />
            );
        }

        return false;
    }

    changePrice = (price, id) => {
        let PRODUCTS = []

        for (let i = 0; i < this.state.PRODUCTS.length; i++) {
            if (this.state.PRODUCTS[i].id == id) {
                let PRODUCT = this.state.PRODUCTS[i]
                PRODUCT.supplier_price = price
                PRODUCTS.push(PRODUCT)
            } else {
                PRODUCTS.push(this.state.PRODUCTS[i])
            }
        }

        this.setState({ PRODUCTS: PRODUCTS })
    }

    changeAmount = (amount, id) => {
        let PRODUCTS = []

        for (let i = 0; i < this.state.PRODUCTS.length; i++) {
            if (this.state.PRODUCTS[i].id == id) {
                let PRODUCT = this.state.PRODUCTS[i]
                PRODUCT.supplier_amount = amount
                PRODUCTS.push(PRODUCT)
            } else {
                PRODUCTS.push(this.state.PRODUCTS[i])
            }
        }

        this.setState({ PRODUCTS: PRODUCTS })
    }

    toggleConfirmation = (id) => {
        let PRODUCTS = []

        for (let i = 0; i < this.state.PRODUCTS.length; i++) {
            if (this.state.PRODUCTS[i].id == id) {
                let PRODUCT = this.state.PRODUCTS[i]
                PRODUCT.confirmed = !PRODUCT.confirmed
                PRODUCTS.push(PRODUCT)
            } else {
                PRODUCTS.push(this.state.PRODUCTS[i])
            }
        }

        this.setState({ PRODUCTS: PRODUCTS })
    }

    renderCheckbox = (PRODUCT) => {
        return (
            <input
                key={ PRODUCT.id + '_checkbox' }
                id={ PRODUCT.id + '_checkbox' }
                type="checkbox"
                onChange={ () => this.toggleConfirmation(PRODUCT.id) }
                checked={ PRODUCT.confirmed }
            />
        )
    }

    renderProductsRows = (SAVEABLE) => {
        let ROWS = [];
        for (let i = 0; i < this.state.PRODUCTS.length; i++) {
            let PRODUCT = this.state.PRODUCTS[i];
            let numb = i + 1;

            let className = null;
            if (!PRODUCT.supplier_confirmed) {
                className = 'faded';
            } else {
                if (this.state.ORDER.status < 3) {
                    if (parseFloat(PRODUCT.sup_price) < parseFloat(PRODUCT.supplier_price)) {
                        className = 'price_high';
                    }
                }
            }

            ROWS.push(
                <tr key={ i } className={ className }>
                    <td className="cell-center">{ numb }</td>

                    { (typeof SAVEABLE !== "undefined") ? null : <td className="cell-center"><a onClick={ () => this.props.openProductModal(PRODUCT.id, PRODUCT) }>{ this.renderImage(PRODUCT) }</a></td> }
                    <td><span className="product_title">{ PRODUCT.title }</span></td>

                    { (typeof SAVEABLE !== "undefined") ? null : <td className="cell-center">
                        { PRODUCT.amount + ' ' + PRODUCT.ed_izm }
                    </td> }
                    { (typeof SAVEABLE !== "undefined") ? null : <td className="cell-center">
                        { PRODUCT.supplier_confirmed ?

                            this.state.ORDER.status < 2 ?
                                <input
                                    key={ PRODUCT.id + '_amount' }
                                    type="number"
                                    min="0"
                                    onChange={ input => this.changeAmount(input.target.value, PRODUCT.id) }
                                    defaultValue={ PRODUCT.supplier_amount }
                                /> : PRODUCT.supplier_amount + ' ' + PRODUCT.ed_izm
                            : PRODUCT.supplier_amount + ' ' + PRODUCT.ed_izm }
                    </td> }
                    { (typeof SAVEABLE !== "undefined") ? null : <td className="cell-center">
                        { PRODUCT.price } руб
                    </td> }
                    { (typeof SAVEABLE !== "undefined") ? null : <td className="cell-center">
                        { PRODUCT.supplier_confirmed ?
                            this.state.ORDER.status < 2 ?
                                <>
                                    <input
                                        key={ PRODUCT.id + '_price' }
                                        type="number"
                                        min="0"
                                        onChange={ input => this.changePrice(input.target.value, PRODUCT.id) }
                                        defaultValue={ PRODUCT.supplier_price }
                                    /> { this.renderSupPriceChange(PRODUCT.sup_price, PRODUCT.supplier_price) }
                                </>
                                : PRODUCT.supplier_price + ' руб'
                            : PRODUCT.supplier_price + ' руб' }
                    </td> }
                    { (typeof SAVEABLE !== "undefined") ? <td className="cell-center">{ PRODUCT.supplier_amount }</td> : null }
                    { (typeof SAVEABLE !== "undefined") ? <td className="cell-center">{ PRODUCT.ed_izm }</td> : null }
                    { (typeof SAVEABLE !== "undefined") ? <td className="cell-center">{ PRODUCT.supplier_price }</td> : null }
                    <td className="cell-center">
                        { (PRODUCT.supplier_price * PRODUCT.supplier_amount).toFixed(2) }
                    </td>
                    { (typeof SAVEABLE !== "undefined") ? null :
                        this.state.ORDER.status < 2 ?
                            <td className="cell-center">
                                { PRODUCT.supplier_confirmed ? this.renderCheckbox(PRODUCT) : null }
                            </td>
                            : null
                    }
                    { (typeof SAVEABLE !== "undefined") ? <td className="cell-center">
                        { PRODUCT.vendor_code }
                    </td> : null }
                    { (typeof SAVEABLE !== "undefined") ? <td className="cell-center">
                        { PRODUCT.barcode }
                    </td> : null }
                    { (typeof SAVEABLE !== "undefined") ? <td className="cell-center">
                        { PRODUCT.vendor_code }
                    </td> : null }
                </tr>
            );
        }

        return (
            <tbody>
                { ROWS }
            </tbody>
        );
    }

    renderSupPriceChange = (current, prev) => {
        if (prev == 0) {
            return null
        }

        let diff = ((prev / current - 1) * 100).toFixed(2)

        if (diff < 0) {
            return (
                <span className="price_diff_change">
                    <img src="/system_images/tr_down.png" /> { diff }%
                </span>
            )
        } else if (diff > 0) {
            return (
                <span className="price_diff_change">
                    <img src="/system_images/tr_up.png" /> { diff }%
                </span>
            )
        }

        return null
    }

    renderProductsTableHeader = (SAVEABLE) => {
        return (
            <thead>
                <tr>
                    <th>№</th>
                    { (typeof SAVEABLE !== "undefined") ? null : <th>фото</th> }
                    <th>наименование</th>
                    { (typeof SAVEABLE !== "undefined") ? null : <th>желаемое количество</th> }
                    { (typeof SAVEABLE !== "undefined") ? null : <th>кол-во пост-ка</th> }
                    { (typeof SAVEABLE !== "undefined") ? null : <th>последняя цена</th> }
                    { (typeof SAVEABLE !== "undefined") ? null : <th>цена пост-ка</th> }
                    { (typeof SAVEABLE !== "undefined") ? <th>количество</th> : null }
                    { (typeof SAVEABLE !== "undefined") ? <th>ед изм</th> : null }
                    { (typeof SAVEABLE !== "undefined") ? <th>цена</th> : null }
                    <th>сумма</th>
                    { (typeof SAVEABLE !== "undefined") ? null : this.state.ORDER < 2 ? <th>под-ние</th> : null }
                    { (typeof SAVEABLE !== "undefined") ? <th>артикул</th> : null }
                    { (typeof SAVEABLE !== "undefined") ? <th>штрихкод</th> : null }
                    { (typeof SAVEABLE !== "undefined") ? <th>артикул/пометки</th> : null }
                </tr>
            </thead>
        );
    }

    renderProducts = () => {
        return (
            <div className="table_holder">
                <table id="products_table" className="products_table" cellPadding="0" cellSpacing="0">
                    { this.renderProductsTableHeader() }
                    { this.renderProductsRows() }
                </table>
                <table id="products_table_saveable" className="products_table_saveable" cellPadding="0" cellSpacing="0">
                    <tr>
                        <td>
                            Дата заказа:
                        </td>
                        <td>
                            { this.state.ORDER.order_date }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Дата поставки:
                        </td>
                        <td>
                            { this.state.ORDER.delivery_date }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Время поставки:
                        </td>
                        <td>
                            { this.state.ORDER.delivery_time }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Поставщик:
                        </td>
                        <td>
                            { this.state.ORDER.supplier_title }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Менеджер:
                        </td>
                        <td>
                            { this.state.ORDER.manager_name }
                        </td>
                    </tr>
                    {
                        this.state.ORDER.comment.length ?
                            <tr>
                                <td>
                                    Комментарий:
                                </td>
                                <td>
                                    { this.state.ORDER.comment }
                                </td>
                            </tr>
                            : null
                    }
                    <tr>
                        <td></td><td></td>
                    </tr>
                    { this.renderProductsTableHeader(true) }
                    { this.renderProductsRows(true) }
                </table>
            </div>
        );
    }

    countOrder = () => {
        let sum = 0;
        let amount = 0;
        let confirmed = 0;

        for (let i = 0; i < this.state.PRODUCTS.length; i++) {
            if (this.state.PRODUCTS[i].confirmed || this.state.ORDER.status == 2 || this.state.ORDER.status == 4) {
                amount++;
                sum += this.state.PRODUCTS[i].supplier_amount * this.state.PRODUCTS[i].supplier_price;
            }
            if (this.state.PRODUCTS[i].supplier_confirmed) {
                confirmed++;
            }
        }

        return {
            amount: amount,
            sum: sum,
            confirmed: confirmed
        }
    }

    changeDeliveryTime = (date) => {
        let ORDER = Object.assign({}, this.state.ORDER)

        ORDER.delivery_time = date;

        this.setState({ ORDER: ORDER })
    }

    changeDeliveryDate = (date) => {
        let ORDER = Object.assign({}, this.state.ORDER)

        ORDER.delivery_date = date;

        this.setState({ ORDER: ORDER })
    }

    getClass = (amount) => {
        if (amount == 0) {
            return 'inactive'
        }

        return null
    }

    selectOrDeselectAll = () => {
        let totals = this.countOrder();
        let PRODUCTS = []

        for (let i = 0; i < this.state.PRODUCTS.length; i++) {
            let PRODUCT = this.state.PRODUCTS[i]
            if (PRODUCT.supplier_confirmed) {
                if (totals.amount == totals.confirmed) {
                    PRODUCT.confirmed = false
                } else {
                    PRODUCT.confirmed = true
                }
            }
            PRODUCTS.push(PRODUCT)
        }

        this.setState({ PRODUCTS: PRODUCTS })
    }

    renderCancelButton = () => {
        if (this.state.ORDER.status == 0 || this.state.ORDER.status == 1 || this.state.ORDER.status == 2) {
            return (
                <a className="cancel_supplier_order" onClick={ () => this.cancelOrder() }>Отменить заказ</a>
            )
        }

        return null
    }

    renderDeliveryTimeOptions = () => {
        let options = []
        for (let i = 4; i < 22; i++) {
            let local_start = i;
            let local_end = i + 1;

            if (local_start < 10) {
                local_start = '0' + local_start
            }
            if (local_end < 10) {
                local_end = '0' + local_end
            }

            let string = local_start + ':00-' + local_end + ':00';
            options.push(<option key={ i } value={ string }>{ string }</option>)
        }

        return options;
    }

    renderTotalsBlock = () => {
        let totals = this.countOrder();

        return (
            <div className="ordering_confirm_total">
                <div>
                    <span>Заказ:</span>
                    <span>{ totals.amount } поз</span>
                </div>
                <div>
                    <span>Сумма:</span>
                    <span>{ totals.sum.toFixed(2) } руб</span>
                </div>
                { this.state.ORDER.status < 2 ?
                    <>
                        <div>
                            <span>Дата доставки:</span>
                            <span>
                                <input
                                    type="date"
                                    name="delivery_date"
                                    onChange={ (input) => this.changeDeliveryDate(input.target.value) }
                                    value={ this.state.ORDER.delivery_date }
                                />
                            </span>
                        </div>
                        <div>
                            <span>Время доставки:</span>
                            <span>
                                <select
                                    name="delivery_time"
                                    onChange={ (input) => this.changeDeliveryTime(input.target.value) }
                                    value={ this.state.ORDER.delivery_time }
                                >
                                    { this.renderDeliveryTimeOptions() }
                                </select>
                            </span>
                        </div>
                        { parseInt(this.state.ORDER.status) > 0 ? <a className="ordering_confirm_btn" onClick={ () => this.selectOrDeselectAll() }>
                            { totals.amount == totals.confirmed ? 'Отменить выбор' : 'Выбрать все' }
                        </a> : null }
                        <a className={ 'submit_order_to_suppliers ' + this.getClass(totals.amount) } onClick={ () => totals.amount > 0 ? this.saveOrderData() : alert('Необходимо подтвердить товары в заказе') }>
                            { this.state.SAVING ? 'Записываем...' : 'Согласовать' }
                        </a>
                        { this.renderCancelButton() }
                    </> : null }
            </div>
        )
    }

    addProduct = (PRODUCT) => {
        let PRODUCTS = [...this.state.PRODUCTS]

        let ADD = true;
        for (let i = 0; i < PRODUCTS.length; i++) {
            if (PRODUCTS[i].id == PRODUCT.id) {
                ADD = false
            }
        }

        if (ADD) {
            PRODUCTS.push({
                amount: 1,
                barcode: PRODUCT.barcode,
                confirmed: false,
                ed_izm: PRODUCT.ed_izm,
                id: PRODUCT.id,
                images: {
                    thumb: PRODUCT.thumb,
                    big: PRODUCT.big
                },
                price: PRODUCT.price,
                startup: false,
                sup_price: PRODUCT.pure_sup_price,
                supplier_amount: 1,
                supplier_confirmed: 1,
                supplier_price: PRODUCT.pure_sup_price,
                title: PRODUCT.title,
                url: PRODUCT.url
            })
        }

        this.setState({ PRODUCTS: PRODUCTS })
    }

    renderSearchBlock = () => {
        if (this.state.ORDER.status == 1) {
            return (
                <OrderProductSearch
                    addProduct={ this.addProduct }
                />
            )
        }
    }

    render() {
        if (!this.state.USER) {
            return <Redirect to="/" />
        }

        if (!this.state.LOADED) {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part ordering">
                        <Helmet>
                            <title>Заказ</title>
                        </Helmet>
                        <div id="page-content" className="page-content">
                            <div className="main-container inner container-fluid">
                                <h1>Заказ</h1>
                                <Link to="/orders" className="back_btn">К заказам</Link>
                                <div className="table_holder"><div className="loader_content">Загружаем данные по заказу ...</div></div>
                            </div></div>
                    </div>
                </CSSTransitionGroup>
            );
        }

        if (!this.state.ORDER) {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part ordering">
                        <Helmet>
                            <title>Заказ</title>
                        </Helmet>
                        <div id="page-content" className="page-content">
                            <div className="main-container inner container-fluid">
                                <h1>Заказ</h1>
                                <Link to="/orders" className="back_btn">К заказам</Link>
                                <div className="table_holder">К сожалению, данных по заказу не найдено...</div>
                            </div></div>
                    </div>
                    <ModalsContainer />
                </CSSTransitionGroup>
            );
        } else {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part ordering">
                        <Helmet>
                            <title>Заказ №{ this.state.ORDER.id } от { this.state.ORDER.order_date }</title>
                        </Helmet>
                        <div id="page-content" className="page-content">
                            <div className="main-container inner container-fluid">
                                <h1>Заказ №{ this.state.ORDER.id } от { this.state.ORDER.order_date }</h1>
                                <Link to="/orders" className="back_btn">К заказам</Link>
                                { this.renderIntroBlock() }
                                { this.renderSearchBlock() }
                                <div className="order_data_block">
                                    { this.renderProducts() }
                                    { this.renderTotalsBlock() }
                                </div>
                            </div></div>
                    </div>
                    <ModalsContainer />
                </CSSTransitionGroup>
            );
        }
    }
}

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(Order))
