import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        POPULAR_PRODUCTS: state.POPULAR,
        PRODUCTS: state.PRODUCTS,
        CART: state.CART,
        KM: state.KM,
        PolygonsFreeDeliveryWhenSum: state.PolygonsFreeDeliveryWhenSum,
        USER: state.USER,
        REGION: state.REGION,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class ProducRowRec extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            CLICKED: false,
            productsIds: [],
        }
        this.PRODUCT = props.PRODUCT;
    }

    componentDidMount = async () => {
        await this.getProducts()

        const slider = document.querySelector('.row_rec_carousel');
        setTimeout(function () {
            if ((typeof slider !== "undefined") && (slider !== null)) {
                let startX;
                let scrollLeft;
                let isDown = false;

                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });

                slider.addEventListener('mouseup', () => {
                    isDown = false;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                });

                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) {
                        return false;
                    }
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX);
                    slider.scrollLeft = scrollLeft - walk;
                });
            }
        }, 200);
    }

    getProducts = async () => {
        try {
            let SEND_DATA = {
                action: 'getPopularUserProducts',
                apikey: this.props.USER.TOKEN,
                region: this.props.REGION,
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({productsIds: data.products})
            } else {
            }
        } catch (error) {
        }
    }

    renderImage(PRODUCT) {
        let SRC = PRODUCT.thumb;
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC + ')'};
            return (
                <div
                    className="image"
                    title={PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        } else {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
            return (
                <div
                    className="image"
                    title={PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        }
    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount() {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderCartedPrice(PRODUCT) {
        return PRODUCT.price;
    }

    renderPlusButton() {
        return (
            <a className="minus" onClick={() => this.plusProduct()} tabIndex="0">
                +
            </a>
        );
    }

    minusProduct() {
        if (this.props.AMOUNT - 1 < 1) {
            this.setState({MANIPULATE: false});
        } else {
            this.launchManipulation();
        }
        this.props.minusCart(this.PRODUCT);
    }

    plusProduct() {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCart(this.PRODUCT);
        }
    }

    renderMinusButton() {
        return (
            <a className="minus" onClick={() => this.minusProduct()} tabIndex="0">
                {(this.props.AMOUNT == 1) ? <img src="/system_images/green_bin.png"/> : '-'}
            </a>
        );
    }

    checkMaximum() {
        let PRODUCT = this.PRODUCT;
        return this.props.AMOUNT + 1 <= PRODUCT.availableones;

    }

    renderCurShowAmount() {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (this.props.AMOUNT < 1) {
            return PRODUCT.peramount;
        }

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage() {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT == PRODUCT.availableones) {
            return (
                <div>
                    <div className="cart-max">Это максимальное количество товара.</div>
                    <div className="cart-max-text">Вы можете указать желаемое количество в комментарии к заказу.</div>
                </div>
            );
        }
    }

    launchManipulation() {
        if (!this.state.MANIPULATE) {
            this.setState({MANIPULATE: true});
        }
    }

    renderManipulator = () => {
        if (!this.state.MANIPULATE) {
            return false;
        }

        return (
            <div className="amount_controller">

                {this.renderMaximumMessage()}

                <div className="the_controls">
                    {this.renderMinusButton()}

                    <div className="the_amount">
                        <span className="current_amount">{this.renderShowAmount()}</span>
                    </div>

                    {this.renderPlusButton()}
                </div>
            </div>
        );
    }

    addThis(PRODUCT) {
        this.props.addToCart(PRODUCT);
        this.setState({CLICKED: true});
    }

    renderProductRow(PRODUCT) {
        return (
            <div className="rec_card" onClick={() => this.addThis(PRODUCT)}>
                {this.renderImage(PRODUCT)}
                <div className="caption">
                    {PRODUCT.title}
                </div>
                <div className="product-price">
                    <span className="price">{this.renderCartedPrice(PRODUCT)} р</span>
                </div>
            </div>
        )
    }

    rmFixed2(numb) {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(parseFloat(numb).toFixed(2)).toFixed(2);
        return numb;
    }

    renderFreeDevelopConditions = () => {

        let SUM = parseFloat(this.props.SUM);

        if (this.props.PolygonsFreeDeliveryWhenSum !== '-1') {
            let CITY_SUM = (this.rmFixed2(Number(this.props.PolygonsFreeDeliveryWhenSum) - SUM))
            if (CITY_SUM <= 0) {
                return false
            } else {
                return "До бесплатной доставки осталось " + CITY_SUM + ' рублей!'
            }
        }

        if (parseInt(this.props.KM) == 0) {
            let CITY_SUM = (this.rmFixed2(3500 - SUM))
            if (CITY_SUM <= 0) {
                return false
            } else {
                return "До бесплатной доставки осталось " + CITY_SUM + ' рублей!'
            }
        } else {
            let VILLAGE_SUM = (this.rmFixed2(8500 - SUM))
            if (VILLAGE_SUM <= 0) {
                return false
            } else {
                return "До бесплатной доставки осталось " + VILLAGE_SUM + '!'
            }
        }
    }

    renderFreeDeliveryMessage = () =>
    {
        if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER') {
            return false;
        }

        return (
            <div className="to_free_develop">
                <span className="add_rec_intro">{this.renderFreeDevelopConditions()} </span>
            </div>
        )
    }

    render() {
        if (typeof this.props.PRODUCTS !== "undefined") {
            let CARDS = [];
            if (this.state.productsIds)
                for (let i of this.state.productsIds) {
                    if (typeof this.props.CART[i] == "undefined") {
                        if (this.props.PRODUCTS[i]) {
                            let PRODUCT = this.props.PRODUCTS[i];
                            CARDS.push(<div className="row_rec_holder"
                                            key={i}>{this.renderProductRow(PRODUCT)}</div>);
                        }
                    }
                }

            if (CARDS.length) {
                return (
                    <div>
                        <span className="add_rec_intro">{this.renderFreeDevelopConditions()} </span>
                        <span className="add_rec_intro">Рекомендуем добавить товары: </span>
                        <div className="row_rec_carousel" ref={this.setWrapperRef}>
                            {CARDS}
                        </div>
                    </div>
                );
            } else {
                return this.renderFreeDeliveryMessage();
            }
        }


        return false;
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ProducRowRec);