import React, {Component} from 'react';
import InputMask from "react-input-mask";

export default class ImageRadioColumnMultiple extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            DATA: props.data,
            FIELD: props.field,
            SELECTED: props.selected
        }
    }

    recordValue = (value) =>
    {
        let VALUES = this.state.SELECTED;
        let SELECTED = this.checkSelected(value);
        if (SELECTED) {
            let NEW_VALUES = [];
            for (let i = 0; i < VALUES.length; i++) {
                if (VALUES[i] !== value) {
                    NEW_VALUES.push(VALUES[i]);
                }
            }

            this.setState({SELECTED: NEW_VALUES});
            this.props.setField(this.state.FIELD,NEW_VALUES);

        } else {
            VALUES.push(value);

            this.setState({SELECTED: VALUES});
            this.props.setField(this.state.FIELD,VALUES);
        }
    }

    checkSelected = (VALUE) =>
    {
        let SELECTED = false;

        for (let i = 0; i < this.state.SELECTED.length; i++) {
            if (this.state.SELECTED[i] == VALUE) {
                SELECTED = true;
            }
        }

        if (SELECTED) {
            return true;
        } else {
            return false;
        }
    }

    render()
    {
        let OPTIONS = [];
        for (let i = 0; i < this.state.DATA.length; i++) {
            let CLASS = 'anketa_images_input';
            let SELECTED = this.checkSelected(this.state.DATA[i]['VALUE']);
            if (SELECTED) {
                CLASS = 'anketa_images_input selected';
            }
            OPTIONS.push(
                <div className="anketa_images_checkbox" key={i} onClick={() => this.recordValue(this.state.DATA[i]['VALUE'])}>
                    <img style={{height: '19px', alignSelf: 'flex-end', marginBottom: '7px', marginRight: '11px'}} src={SELECTED?"/system_images/checkboxFull.svg":"/system_images/checkbox.svg"}/>
                    <span style={this.props.className==="ANIMALS"?{fontSize: '18px', fontWeight: '900', color: '#000'}:{fontSize: '15px', marginRight: '5px', fontWeight: '500', color: '#000'}}>{this.state.DATA[i]['TEXT']}</span>
                    {this.state.DATA[i].IMAGES && <div style={{display: 'flex', marginLeft: '2px'}}>{this.state.DATA[i].IMAGES.map(img=><img style={{marginLeft:'5px'}} src={img}/>)}</div>}
                    {this.state.DATA[i]['IMAGE'] && <img style={{marginBottom: '0px', marginLeft: '0px'}} src={this.state.DATA[i]['IMAGE']}/>}
                </div>
/*                <a key={i} className={CLASS} onClick={() => this.recordValue(this.state.DATA[i]['VALUE'])}>
                    <span style={this.props.className==="ANIMALS"?{fontSize: '18px', fontWeight: '900', color: '#000'}:{fontSize: '15px', fontWeight: '500', color: '#000'}}>{this.state.DATA[i]['TEXT']}</span>
                    {this.state.DATA[i].IMAGES && <div style={{display: 'flex', marginLeft: '2px'}}>{this.state.DATA[i].IMAGES.map(img=><img style={{marginRight:'6px'}} src={img}/>)}</div>}
                    {this.state.DATA[i]['IMAGE'] && <img style={{marginBottom: '10px', marginLeft: '12px'}} src={this.state.DATA[i]['IMAGE']}/>}
                </a>*/
            );
        }

        return (
            <div className={this.props.className}>
                {OPTIONS}
            </div>
        );
    }
}
