import React, {Component} from 'react';
import InputMask from "react-input-mask";

export default class CityInput extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            WHATSAPP: props.WHATSAPP
        }
    }

    handleChange = (text) => {
	    console.log(text.target.value)
        this.validate(text.target.value);
    };

    validate = (string) =>
    {
        if (string > 0) {
            this.props.setField('REGION',string)
        } else {
            this.props.resetField('REGION');
        }
    }

    render()
    {
    	return (
            <select  onChange={(text) => this.handleChange(text)}>
                <option value="0">Выберите свой регион</option>
                <option value="77">Москва и Московская область</option>
                <option value="78">Санкт-Петербург и Ленинградская область</option>
                <option value="16">Казань и Республика Татарстан</option>
                <option value="52">Нижний Новгород и Нижегородская область</option>
                <option value="61">Ростов-на-Дону и Ростовская область</option>
                <option value="36">Воронеж и Воронежская область</option>
                <option value="34">Волгоград и Волгоградская область</option>
                <option value="1">Другой</option>
            </select>
        );
    }
}
