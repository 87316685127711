import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../SYSTEM/Config.js'
import { sendPostRequest } from "./../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

var firebaseConfig = {
	apiKey: "AIzaSyAxfzAMkflHIddxwze2Mcqj7JTiOK5skn0",
	authDomain: "ecomarket-6bbe3.firebaseapp.com",
	databaseURL: "https://ecomarket-6bbe3.firebaseio.com",
	projectId: "ecomarket-6bbe3",
	storageBucket: "ecomarket-6bbe3.appspot.com",
	messagingSenderId: "902477886844",
	appId: "1:902477886844:web:9358fdf3ba24cd4baf60f9",
	measurementId: "G-WTLJ50HBXZ"
};

var messaging = false;
window.firebase.initializeApp(firebaseConfig);
if (window.firebase.messaging.isSupported()) {
	messaging = window.firebase.messaging();

	messaging.onMessage(function(payload) {
	    const noteTitle = payload.notification.title;
	    const noteOptions = {
	        body: payload.notification.body,
	        icon: 'https://ecomarket.ru/system_images/logo192.png',
	    };
	    new Notification(noteTitle, noteOptions);
	});
}

class WebPush extends Component {

	constructor(props)
    {
    	super(props);
    	this.state = {
    		USER: props.USER
    	}
	}

	componentDidMount = () =>
	{
		if (window.firebase.messaging.isSupported()) {
			this.getStartToken();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps.USER !== this.props.USER) {
            this.setState({USER: this.props.USER});
            this.updateToken();
        }
    }

    updateToken = async () =>
    {
        if (this.state.USER) {
        	let COMPONENT = this;
        	if (messaging) {
	        	messaging.getToken().then((currentToken) => {
		            try {
		                let SEND_DATA = {
		                    action:    'updateFCM',
		                    apikey:     COMPONENT.state.USER.TOKEN,
		                    fcm:        currentToken
		                };

		                sendPostRequest(SEND_DATA,CONFIG.API_GATE);
		            } catch (error) {
		                console.log('BAD PRODUCTS TIMER');
		            }
		        });
	        }
        }
    }

	getStartToken = () =>
	{
		let COMPONENT = this;
		if (messaging) {
			messaging.getToken().then((currentToken) => {
				if (currentToken) {
					COMPONENT.sendTokenToServer(currentToken);
				} else {
					COMPONENT.RequestPermission();
				}
			}).catch((err) => {
			});
		}
	}

	RequestPermission = () =>
	{
		let COMPONENT = this;
		if (messaging) {
			messaging.requestPermission()
			.then(function(permission){
				if (permission === 'granted') {
					COMPONENT.getStartToken();
				}
			}).catch(function(err) {
			});
		}
	}

	sendTokenToServer = async (token) =>
	{
		let SEND_DATA = {
            action:    'createFCM',
            apikey:     this.state.USER.TOKEN,
            fcm:        token,
            device:     'WEB'
        };
        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

        if (this.state.USER) {
        	this.updateToken();
        }
	}

	render() {
		return false
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(WebPush))
