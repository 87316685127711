import React, {Component} from 'react';
import InputMask from "react-input-mask";

/*export const phoneNumber = phone.toString().replace(/\D+/g, '');*/

export default class PhoneInput extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            PHONE: props.PHONE
        }
    }

    recordPhone = (text) => 
    {
        this.validatePhone(text.target.value);
    }

    validatePhone = (phone) =>
    {
        let phoneNumber = phone.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
            this.props.setField('PHONE',phone)
        } else {
            this.props.resetField('PHONE');
        }
    }

    render()
    {
    	return <InputMask placeholder={this.props.placeholder?this.props.placeholder:null} type="tel" className="phone" id="share_phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} defaultValue={this.state.PHONE} />
    }
}
