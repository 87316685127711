import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state,myProps) {
    return {
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class ProductCardAddon extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            ID:     props.ID,
            PRODUCT: this.props.PRODUCTS[parseInt(props.ID)],
            MANIPULATE: false,
            AMOUNT: props.AMOUNT
        }

        this.PRODUCT = this.props.PRODUCTS[parseInt(props.ID)];
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.AMOUNT !== this.state.AMOUNT) {
            this.setState({AMOUNT: this.props.AMOUNT});
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.MANIPULATE) {
                this.setState({MANIPULATE: false});
            }
        }
    }

    renderMinusButton()
    {
        return (
            <a className="minus" onClick={() => this.minusProduct()} tabIndex="0">
                {(this.state.AMOUNT == 1) ? <img src="/system_images/green_bin.png" /> : '-'}
            </a>
        );
    }

    minusProduct()
    {
        if (this.state.AMOUNT - 1 < 1) {
            this.setState({MANIPULATE: false});
        } else{
            this.launchManipulation();
        }
        this.props.minusAddon(this.PRODUCT);
    }

    checkMaximum()
    {
        let PRODUCT = this.PRODUCT;
        if (this.state.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    plusProduct()
    {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            this.props.plusAddon(this.PRODUCT);
        }
    }

    renderCartedPrice()
    {
        if (this.state.AMOUNT > 0) {
            return this.PRODUCT.price * this.state.AMOUNT;
        } else {
            return this.PRODUCT.price;
        }
    }

    addToCart()
    {
        this.launchManipulation();
        this.props.addToAddon(this.PRODUCT);
    }

    doPress(id)
    {
        this.setState({PRESSED: id});
    }

    doUnpress()
    {
        this.setState({PRESSED: false});
    }

    morePressed()
    {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            this.props.plusAddon(this.PRODUCT);
        }
    }

    checkMaximum()
    {
        let PRODUCT = this.PRODUCT;
        if (this.state.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    renderCartButton = () =>
    {
        if (this.state.AMOUNT > 0) {
            return (
                <a className="to-cart-btn added-btn" onClick={() => this.morePressed()} >
                    Ещё
                </a>
            );
        } else {
            return (
                <a className="to-cart-btn" onClick={() => this.addToCart()} >
                    Добавить
                </a>
            );            
        }
    }

    rmFixed2(numb)
    {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(numb).toFixed(2);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderProductOldPrice()
    {
        if ((parseInt(this.PRODUCT.sale) == 1) && (this.PRODUCT.old_price > 0)) {
            let PRICE = this.rmFixed2(parseFloat(this.PRODUCT.old_price));
            let PRICE_PARTS = PRICE.toString().split('.');
            if (typeof PRICE_PARTS[1] !== "undefined") {
                if (PRICE_PARTS[1].length == 1) {
                    PRICE_PARTS[1] = PRICE_PARTS[1] + '0';
                }
            } else {
                PRICE_PARTS[1] = '00';
            }
            return (
                <div className="old_price_row">
                    <div className="old_price_line"></div>
                    <span className="old_price_text">
                        {PRICE_PARTS[0]}
                    </span>
                    <span className="old_price_text2">
                        {PRICE_PARTS[1]}
                    </span>
                </div>
            );
        }
    }

    getPriceClass = () =>
    {
        if ((parseInt(this.PRODUCT.sale) == 1) && (this.PRODUCT.old_price > 0)) {
            return ' selecter_padded';
        }

        return '';
    }

    renderButtons = () =>
    {
        if (this.state.PRODUCT.availableones > 0) {
            let PRICE = this.renderCartedPrice();
            let PRICE_PARTS = PRICE.toString().split('.');

            if (typeof PRICE_PARTS[1] !== "undefined") {
                if (PRICE_PARTS[1].length == 1) {
                    PRICE_PARTS[1] = PRICE_PARTS[1] + '0';
                }
            } else {
                PRICE_PARTS[1] = '00';
            }

            return (
                <div 
                    className="button-holder"
                >
                    
                    <div className={'selecter' + this.getPriceClass()}>
                        {this.renderProductOldPrice()}
                        <span className="price">{PRICE_PARTS[0]}</span>
                        <span className="price_kop">{PRICE_PARTS[1]}</span>
                    </div>
                </div>
            );
        } else {
            return (
                <div 
                    className="button-holder"
                >
                </div>
            );
        }
    }

    renderPlusButton()
    {
        return (
            <a className="minus" onClick={() => this.plusProduct()} tabIndex="0">
                +
            </a>
        );
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount()
    {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.state.AMOUNT * PRODUCT.peramount;

        if(PRODUCT.peramount % 1 !== 0){
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderCurShowAmount()
    {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.state.AMOUNT * PRODUCT.peramount;

        if (this.state.AMOUNT < 1) {
            return PRODUCT.peramount;
        }

        if(PRODUCT.peramount % 1 !== 0){
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    launchManipulation()
    {
        if (!this.state.MANIPULATE) {
            this.setState({MANIPULATE: true});
        }
    }

    renderManipulator = () =>
    {
        if (!this.state.MANIPULATE) {
            return false;
        }

        return (
            <div className="amount_controller">

                <div className="the_controls">
                    {this.renderMinusButton()}

                    <div className="the_amount">
                        <span className="current_amount">{this.renderShowAmount()}</span>
                    </div>

                    {this.renderPlusButton()}
                </div>
            </div>
        );
    }

    renderImage()
    {
        if (WIDTH > 760) {
            let SRC = this.state.PRODUCT.thumb;
            if ((typeof SRC !== "undefined") && (SRC !== "")) {
                return (
                    <div className="relative_image_holder">
                        <div 
                            className="image" 
                            title={this.PRODUCT.title}
                        >
                            <img
                                alt={this.PRODUCT.title}
                                src={CONFIG.API_C_DOMAIN + SRC}
                            />
                        </div>
                        {this.renderManipulator()}
                    </div>
                );
            } else {
                let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
                return (
                    <div className="relative_image_holder">
                        <div 
                            className="image" 
                            title={this.PRODUCT.title}
                            style={STYLE}
                        >
                        </div>
                        {this.renderManipulator()}
                    </div>
                );
            }
        } else {
            let SRC = this.state.PRODUCT.thumb;
            if ((typeof SRC !== "undefined") && (SRC !== "")) {
                return (
                    <div className="relative_image_holder">
                        <div 
                            className="image" 
                            title={this.PRODUCT.title}
                        >
                            <img
                                alt={this.PRODUCT.title}
                                src={CONFIG.API_C_DOMAIN + SRC}
                            />
                        </div>
                        {this.renderManipulator()}
                    </div>
                );
            } else {
                let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
                return (
                    <div className="relative_image_holder">
                        <div
                            className="image" 
                            title={this.PRODUCT.title}
                            style={STYLE}
                        >
                        </div>
                        {this.renderManipulator()}
                    </div>
                );
            }
        }
    }


    renderTitle()
    {
        return (
            <span className="product-card-title" title={this.PRODUCT.title}>
                {this.PRODUCT.title}
            </span>
        );
    }

	render() {
		return (
            <div className="product-col product-col-addon"  ref={this.setWrapperRef}>

                {this.renderImage()}

                {this.renderTitle()}

                {this.renderButtons()}

                {this.renderCartButton()}

                <div className="spacer"></div>
            </div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductCardAddon)
