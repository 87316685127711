import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';
import InputMask from "react-input-mask";
import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import MobileLocationData from "../../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

function mapStateToProps(state, myProps) {
    return {
        REGION: state.REGION,
        TOKEN: state.TOKEN
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Contacts extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            REGION: props.REGION,
            PHONE: '',
            NAME: '',
            COMPANY: '',
            EMAIL: '',
            SUCCESS: false
        }

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.items = this.getItemsPerScreen();

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount()
    {
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    handleScroll(event) {

    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    getItemsPerScreen()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 1;
        } else if (WIDTH < 769) {
            return 1;
        } else if (WIDTH < 1050) {
            return 2;
        } else if (WIDTH < 1200) {
            return 3;
        } else if (WIDTH < 9900) {
            return 3;
        }
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });

        DATA.push({
            'to' : '/company/about',
            'title' : 'О нас'
        });

        return <BreadCrumbs DATA={DATA} />
    }


    record = (title, input) => 
    {
        this.setState({[title]: input.target.value});
    }

    validateEmail = (email) =>
    {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    renderButton = () =>
    {
        let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');
        if ((this.state.NAME.length > 1) && (phoneNumber.length == 11) && (this.validateEmail(this.state.EMAIL)) && (this.state.COMPANY.length > 3)) {
            return (
                <button className="orange-button" onClick={() => this.sendRequest()}>
                    Отправить
                </button>
            );
        } else {
            return (
                <button className="orange-button inactive-btn">
                    Отправить
                </button>
            );
        }
    }

    sendRequest = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'recordB2Brequest',
                token:      this.state.TOKEN,
                phone:      this.state.PHONE,
                email:      this.state.EMAIL,
                company:    this.state.COMPANY,
                name:       this.state.NAME
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                try {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'generate_lead',
                        'form_name': 'B2B',
                    });
                } catch {}

                this.setState({SUCCESS: true, NAME: '', PHONE: '', EMAIL: '', COMPANY: ''})
            }
        } catch (error) {
        }
    }

    validate = (FIELD) =>
    {
        let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');
        if ((this.state.NAME.length > 0) || (phoneNumber.length > 0) || (this.state.EMAIL.length > 0) || (this.state.COMPANY.length > 0)) {
            switch (FIELD) {
                case 'NAME':
                    if (this.state.NAME.length < 2) {
                        return 'invalid';
                    }
                    break;
                case 'PHONE':
                    if (phoneNumber.length !== 11) {
                        return 'invalid';
                    }
                    break;
                case 'EMAIL':
                    if (!this.validateEmail(this.state.EMAIL)) {
                        return 'invalid';
                    }
                    break;
                case 'COMPANY':
                    if (this.state.COMPANY.length < 4) {
                        return 'invalid';
                    }
                    break;
            }
        }
    }

    renderSuccess = () =>
    {
        if (this.state.SUCCESS) {
            return (
                <div className="contacts_corporate_form_success">
                    Спасибо!<br/>Мы скоро с Вами свяжемся!
                </div>
            );
        }
    }

	render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>Контакты</title>
                        <meta name="description" content="Контакты" />
                        <meta name="keywords" content="Контакты" />
                    </Helmet>
                    <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid unsubscribe_container">
                            <MobileLocationData />
                        <DesktopDeliveryInformation />
                            {this.renderBreadCrumbs()}
                            <h1 className="contacts_h1">Контакты</h1>
                            <h2 className="contacts_h2">Служба поддержки</h2>

                            <div style={{justifyContent: 'space-between'}} className="contacts_city_phones">
                                <div className="contacts_city_title">Москва и регионы:</div>
{/*                                <a className="contacts_city_phone" href="tel: +74954450505">+7 495 445 05 05</a>*/}
                                <a target="_blank" className="contacts_city_wa" href="http://api.whatsapp.com/send?phone=+79855555598"><img src="/system_images/wa.png" />+7 (985) 555-55-98</a>
                            </div>

                            {/*<div className="contacts_city_phones">
                                <div className="contacts_city_title">Санкт-Петербург:</div>
                                <a className="contacts_city_phone" href="tel: +7 812 566 10 06">+7 812 566 10 06</a>
                                <a target="_blank" className="contacts_city_wa" href="http://api.whatsapp.com/send?phone=+79855555598"><img src="/system_images/wa.png" />+7 985 555 55 98</a>
                            </div>

                            <div className="contacts_city_phones">
                                <div className="contacts_city_title">Казань:</div>
                                <a className="contacts_city_phone" href="tel: +74954450505">+7 495 445 05 05</a>
                                <a target="_blank" className="contacts_city_wa" href="http://api.whatsapp.com/send?phone=+79855555598"><img src="/system_images/wa.png" />+7 985 555 55 98</a>
                            </div>

                            <div className="contacts_city_phones">
                                <div className="contacts_city_title">Нижний Новгород:</div>
                                <a className="contacts_city_phone" href="tel: +74954450505">+7 495 445 05 05</a>
                                <a target="_blank" className="contacts_city_wa" href="http://api.whatsapp.com/send?phone=+79855555598"><img src="/system_images/wa.png" />+7 985 555 55 98</a>
                            </div>

                            <div className="contacts_city_phones">
                                <div className="contacts_city_title">Ростов-на-Дону:</div>
                                <a className="contacts_city_phone" href="tel: +74954450505">+7 495 445 05 05</a>
                                <a target="_blank" className="contacts_city_wa" href="http://api.whatsapp.com/send?phone=+79855555598"><img src="/system_images/wa.png" />+7 985 555 55 98</a>
                            </div>*/}

                            <div className="contacts_emails">
                                <div>
                                    <span>Почта: </span>
                                    <a href="mailto: info@ecomarket.ru">info@ecomarket.ru</a>
                                </div>
                                <div>
                                    <span>Отдел закупок: </span>
                                    <a href="mailto: vendors@ecomarket.ru">vendors@ecomarket.ru</a>
                                </div>
                                <div>
                                    <span>Отдел маркетинга: </span>
                                    <a href="mailto: hasueva@ecomarket.ru">hasueva@ecomarket.ru</a>
                                </div>
                            </div>

                            <div style={{justifyContent: 'space-between'}} className="contacts_city_phones">
                                <div className="contacts_city_title">Отдел&nbsp;корпоративных&nbsp;продаж&nbsp;B2B&nbsp;:</div>
                                {/*                                <a className="contacts_city_phone" href="tel: +74954450505">+7 495 445 05 05</a>*/}
                                <a target="_blank" className="contacts_city_wa" href="http://api.whatsapp.com/send?phone=+79159754362"><img src="/system_images/wa.png" />+7 (915) 975-43-62</a>
                            </div>

                            <div className="contacts_emails">
                                <div>
                                    <span>Почта: </span>
                                    <a href="mailto: b2b@ecomarket.ru">b2b@ecomarket.ru</a>
                                </div>
                            </div>

{/*                            <div className="contacts_corporate_sales">
                                <span>Отдел корпоративных продаж B2B:</span> <a target="_blank" className="contacts_city_wa" href="http://api.whatsapp.com/send?phone=+79855555598"><img style={{height: '16px', marginRight: '10px'}} src="/system_images/wa.png" />+7 (915) 975-43-62</a>
                            </div>*/}

                            <div className="contacts_corporate_form">
                                <div className="intro_line">
                                    Оставьте заявку, и менеджер отдела В2В<br/>свяжется с вами в течение 1 часа
                                </div>
                                <div className="contacts_form_row">
                                    <input value={this.state.NAME} className={this.validate('NAME')} type="text" placeholder="Имя" onChange={(text) => this.record('NAME',text)} />
                                    <input value={this.state.COMPANY} className={this.validate('COMPANY')} type="text" placeholder="Название компании" onChange={(text) => this.record('COMPANY',text)} />
                                </div>
                                <div className="contacts_form_row">
                                    <InputMask value={this.state.PHONE} className={this.validate('PHONE')} placeholder="Номер телефона" type="tel" mask="+7-999-999-99-99" onChange={(text) => this.record('PHONE',text)} />
                                    <input value={this.state.EMAIL} className={this.validate('EMAIL')} type="email" placeholder="E-mail" onChange={(text) => this.record('EMAIL',text)} />
                                </div>

                                <div className="unform flexed">
                                    <div>
                                        {this.renderButton()}
                                    </div>
                                    <div>
                                        {this.renderSuccess()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <ModalsContainer />
                </div>
                <FooterBlock />
            </CSSTransitionGroup>
        );
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Contacts))
