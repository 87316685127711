import React, {Component} from 'react';

export default class BonusesSlider extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            value: 4000
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event){
        this.setState({value: event.target.value});
    }

    getHowMuchBonusesOnSum = (sum) => 
    {
        let CALC_PERCENT = 0;
        if (sum <= 2500) {
            CALC_PERCENT = 0.5;
        } else if(sum <= 3500) {
            CALC_PERCENT = 1;
        } else if(sum <= 4500) {
            CALC_PERCENT = 1.5;
        } else if(sum <= 5500) {
            CALC_PERCENT = 2;
        } else if(sum <= 7000) {
            CALC_PERCENT = 2.5;
        } else if(sum <= 8500) {
            CALC_PERCENT = 3;
        } else if(sum <= 10000) {
            CALC_PERCENT = 4;
        } else if(sum <= 12000) {
            CALC_PERCENT = 5;
        } else if(sum <= 14000) {
            CALC_PERCENT = 6;
        } else if(sum <= 16000) {
            CALC_PERCENT = 7;
        } else if(sum <= 18000) {
            CALC_PERCENT = 8;
        } else if(sum <= 20000) {
            CALC_PERCENT = 9;
        } else {
            CALC_PERCENT = 10;
        }

        return Math.round(sum / 100 * CALC_PERCENT);
    }

    getHowMuch = () =>
    {
        let START = this.getHowMuchBonusesOnSum(parseInt(this.state.value) - 500);
        let END = this.getHowMuchBonusesOnSum(parseInt(this.state.value) + 500);
        return START + ' - ' + END + ' экобаллов';
    }

    getHowMuchAmount = () =>
    {
        let START = parseInt(this.state.value) - 500;
        let END = parseInt(this.state.value) + 500;

        return 'от ' + START + ' до ' + END + ' руб';
    }

	render()
    {
    	return (
            <div>
                <div className="how_much_earn_if">
                    {this.getHowMuch()}
                </div>
                <div className="how_much_earn_if_what">
                    если сумма вашей покупки
                </div>
                <div className="how_much_earn_if_what_amount">
                    {this.getHowMuchAmount()}
                </div>
                <div className="bonuses_range">
                    <div className="range_prices_holder top">
                        <span>2500</span>
                        <span></span>
                        <span>6500</span>
                        <span></span>
                        <span>10500</span>
                        <span></span>
                        <span>14500</span>
                        <span></span>
                        <span>18500</span>
                        <span></span>
                        <span>22500</span>
                        <span></span>
                    </div>
                    <input type="range" value={this.state.value} min="2000" max="25000" step="500"  onChange={this.handleChange.bind(this)}/>
                    <div className="range_dots_holder">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="range_prices_holder bottom">
                        <span></span>
                        <span>4500</span>
                        <span></span>
                        <span>8500</span>
                        <span></span>
                        <span>12500</span>
                        <span></span>
                        <span>16500</span>
                        <span></span>
                        <span>20500</span>
                        <span></span>
                        <span>24500</span>
                    </div>
                </div>
            </div>
        );
    }
}
