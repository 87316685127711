import React, {Component} from 'react';
import {checkRegionEntry, checkIfLocationIsInRussia} from '../../HELPERS/CoordinatesHelders/DefinitionOfTheRegion.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import { useSwipeable, Swipeable } from 'react-swipeable'

import MapHelperOrder from './../../../APP/HELPERS/MapHelperOrder'

import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION,
        SHOWADRESS: state.SHOWADRESS,
        ADRESS: state.ADRESS,
        GPS: state.GPS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MapIframe extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);

        let ADRESS = false;

        this.state = {
            ADRESS: ADRESS,
            GPS: false,
            KM: 0,
            SHOWADRESS: '',
            REGION_COORDINATES: '',
            SUGGESTIONS: [],
            SHOW_SUGGESTIONS: false,
            TEMP_ADRESS: '',
            POSITION: 0,
            MAP_LOADED: false,
            ERROR: true
        }

        this.setMapHelper = this.setMapHelper.bind(this);
    }

    setMapHelper = (node) =>
    {
        this.mapHelper = node;
    }

    componentDidMount()
    {
        if (this.props.USER) {
            if (this.props.USER.ADRESS !== '') {
                let ADRESS = this.props.USER.ADRESS;
                if (this.props.ADRESS) {
                    ADRESS = this.props.ADRESS;
                }

                let COMPONENT = this;
                this.interval = setInterval(function(){
                    if (COMPONENT.state.MAP_LOADED) {
                        clearInterval(COMPONENT.interval);
                        COMPONENT.mapHelper.setSuggestedAdress(ADRESS);
                    }
                }, 10);
            }
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     let ADRESS = this.props.USER.ADRESS;
    //     if (this.props.ADRESS) {
    //         ADRESS = this.props.ADRESS;
    //     }
    //     this.mapHelper.setSuggestedAdress(ADRESS)
    // }


    adressConfirmed = (ADRESS, GPS, SHOWADRESS, KM) =>
    {

        this.props.setAdressAndGpsAndRegion({
            KM: KM,
            ADRESS: ADRESS,
            GPS: GPS,
            SHOWADRESS: SHOWADRESS,
        });

        ///this.saveUserData(ADRESS, GPS, parseInt(REGION_DATA.REGION_KEY));

        this.props.openDeliveryAddressModal(false);
    }

    saveUserData = async (ADRESS, GPS, REGION) =>
    {
        if (this.props.USER) {
            let SEND_DATA = {
                action:         'doSaveUserData',
                phone:          this.props.USER.PHONE,
                name:           this.props.USER.NAME,
                adress:         ADRESS,
                floor:          this.props.USER.FLOOR,
                entrance:       this.props.USER.ENTRANCE,
                del_comment:    this.props.USER.DEL_COMMENT,
                appartment:     this.props.USER.APPARTMENT,
                email:          this.props.USER.EMAIL,
                apikey:         this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.props.doAuth(data.user);
            }
        }
    }

    mapLoaded = () =>
    {
        let COMPONENT = this;
        setTimeout(function(){
            COMPONENT.setState({MAP_LOADED: true});
        }, 500);
    }

    setAdressAndGps = (adress,gps,showadress) =>
    {
        let REGION_DATA = checkRegionEntry(gps);

        if (!REGION_DATA) {
            if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY) {
                REGION_DATA = checkIfLocationIsInRussia(adress);
            }
        }
        
        let ERROR = true;
        if (REGION_DATA) {
            ERROR = false;
            this.props.changeRegion(REGION_DATA.REGION_KEY);
        }
        this.setState({ADRESS: adress, GPS: gps, SHOWADRESS: showadress, ERROR: ERROR},() => {});
    }

    setSuggestions = (data) =>
    {
        if (data.length) {
            this.setState({SUGGESTIONS: data});
        } else {
            this.setState({SUGGESTIONS: []});
        }
    }

    renderAdress = () =>
    {
        return (

            <div className="your_full_adress">
                <div className="your_full_adress_small">Ваш адрес:</div>
                <div className='adress_and_pensil_holder' onClick={() => this.setState({SHOW_SUGGESTIONS: true, TEMP_ADRESS: this.state.ADRESS})}>
                    <div className="your_full_adress_big" >{this.state.SHOWADRESS}
                        <img className="black_pensil_img" src={'/system_images/pensil_black.png'}/>
                    </div>
                </div>
            </div>


        );
    }

    // renderButtonHandler(){
    //     this.adressConfirmed(this.state.ADRESS, this.state.GPS, this.state.KM)
    //     this.props.openDeliveryAddressModal(false)
    //     this.props.openDeliveryPaymentModal(true)
    //
    // }

    renderButton = () =>
    {
        if ((this.state.ADRESS) && (this.state.GPS) && (!this.state.ERROR)) {
            return (
                <a className="adress_confirm_button" onClick={() => {
                    this.adressConfirmed(this.state.ADRESS, this.state.GPS, this.state.SHOWADRESS, this.state.KM)
                }}>Сохранить</a>
            );
        } else {
            return (
                <a className="adress_confirm_button disabled">Сохранить</a>
            );
        }
    }

    resetGps = () =>
    {
        this.setState({gps: false});
    }

    recordTempAdress = (text) =>
    {
        this.mapHelper.getSuggestions(text.target.value);
        this.setState({TEMP_ADRESS: text.target.value});
    }

    setSuggestedAdress = (adress) =>
    {
        this.setState({SHOW_SUGGESTIONS: false, SUGGESTIONS: [], TEMP_ADRESS: ''});
        this.mapHelper.setSuggestedAdress(adress);
    }

    closeSuggestions = () =>
    {
        this.setState({SUGGESTIONS: [], SHOW_SUGGESTIONS: false, POSITION: 0});
    }

    changePosition = (Y) =>
    {
        if (Y + 5 >= this.state.POSITION) {
            this.setState({POSITION: Y + 5});
        }
        if (Y > 100) {
            this.closeSuggestions();
        }
    }

    swipeEnded = (Y) =>
    {
        if (Y < 100) {
            this.setState({POSITION: 0});
        } else {
            this.closeSuggestions();
        }
    }

    renderSuggester = () =>
    {
        let CLASS = 'suggestions_swipe';
        if (this.state.SHOW_SUGGESTIONS) {
            CLASS = 'suggestions_swipe visible';
        }

        let SUGGESTIONS = [];

        if (this.state.SUGGESTIONS.length) {
            for (let i = 0; i < this.state.SUGGESTIONS.length; i++) {
                let SEG = this.state.SUGGESTIONS[i];
                SUGGESTIONS.push(
                    <a
                        key={i}
                        onClick={() => this.setSuggestedAdress(SEG.TITLE)}
                    >
                        {SEG.TITLE}
                    </a>
                );
            }
        }

        const config = {
            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
            trackTouch: true,
            delta: 1
        };

        return (
            <div className={CLASS} style={{marginTop: this.state.POSITION}}>
                <div className="suggestions_swipe_holder">
                    <Swipeable onSwiped={(eventData) => this.swipeEnded(eventData.absY)} onSwiping={(eventData) => this.changePosition(eventData.absY)} onSwipedDown={(eventData) => this.closeSuggestions} {...config}>
                        <div className="grab_block">
                            <div className="close_grab"></div>
                        </div>
                    </Swipeable>
                    <label>
	    				<span>
	    					<img src="/system_images/search_suggestions.png" alt="search_suggestions" />
	    				</span>
                        <input className="suggestions_search" value={this.state.TEMP_ADRESS}  onChange={(text) => this.recordTempAdress(text)} />
                        <span onClick={() => this.setState({SUGGESTIONS: [], TEMP_ADRESS: ''})}>
	    					<img src="/system_images/suggestions_close.png" alt="stop_suggestions" />
	    				</span>
                    </label>
                    <div className="all_suggestions">
                        {SUGGESTIONS}
                    </div>
                </div>
            </div>
        );
    }

    renderSuggesterBack = () =>
    {
        let CLASS = 'suggestions_swipe_back';
        if (this.state.SHOW_SUGGESTIONS) {
            CLASS = 'suggestions_swipe_back visible';
        }

        return (
            <div className={CLASS} onClick={() => this.closeSuggestions()}>
            </div>
        );
    }

    renderError = () =>
    {
        if ((this.state.ERROR) && (this.state.MAP_LOADED)) {
            return (
                <div className="no_delivery_here">
                    <span className= "no_delivery_here_text">В ваш регион мы пока что<br/> не доставляем продукты</span> <img src="/system_images/cry_cat.png" />
                </div>
            );
        }
    }


    render()
    {
        return (
            <div className="modal_content mapped">
                <div className="white_map_top_fader"></div>
                {this.renderAdress()}
                {this.renderError()}
                <MapHelperOrder
                    CLASS="full_map"
                    REGION={this.props.REGION}
                    CENTER=""
                    REGION_COORDINATES={this.props.REGION_COORDINATES}
                    setAdressAndGps={this.setAdressAndGps}
                    resetGps={this.resetGps}
                    ref={this.setMapHelper}
                    setSuggestions={this.setSuggestions}
                    mapLoaded={this.mapLoaded}
                    GPS = {this.props.GPS}
                />
                {this.renderButton()}
                {this.renderSuggester()}
                {this.renderSuggesterBack()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(MapIframe);