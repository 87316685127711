import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"
import BadRatingModal from "./../../MODALS/BadRatingModal.js"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {openBadRatingModal} from "./../../../REDUX/functions.js";


function mapStateToProps(state) {
    return {
        USER: state.USER,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class CourrierBlock extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            ID: this.props.ID,
            COURRIER: this.props.COURRIER,
            STARS: false,
            TIPS: false,
            SHOW_THANKS_TEXT: false,
            SHOW_TIPS_INPUT: false,
            SHOW_FINISHED_RAITING: false,
            SHOW_TEXT_AFTER_COMMENT: false,
            COMMENT: '',
            IMAGE_URL: false,
            LOADED_IMAGE: false,
            SHOW_BAD_RATING_MODAL: false,
            CHECKBOX_COMMENT: false,
            SHOW_INPUT_AFTER_COMMENT: false,
            SHOW_ALL_INPUTS_AFTER_COMMENT: false,


        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.STARS !== prevState.STARS) {
            clearTimeout(this.TIMER)
            let COMPONENT = this;
            this.TIMER = setTimeout(() => {
                COMPONENT.setState({SHOW_FINISHED_RAITING: true})
                if (this.state.STARS < 4) {
                    this.TIMER = setTimeout(() => {
                        COMPONENT.setState({SHOW_BAD_RATING_MODAL: true})
                    }, 500);
                }
            }, 1000);
        }
    }


    leaveRate = async (STAR) => {
        try {
            let SEND_DATA = {
                action: 'leaveCourrierRate',
                id: this.props.ID,
                stars: STAR,
                cour: this.state.COURRIER.id,
                apikey: this.props.USER.TOKEN,

            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
        } catch (error) {
            console.log('BAD_ORDER_DATA');
        }
    }


    renderStars(stars) {
        if (stars > 0) {
            let STARS = [];
            let STAR_IMAGE = false

            if (stars >= 1) {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_yellow.png"/>
                );
            } else {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_grey.png"/>
                );
            }

            let STAR1 = (
                <div key={'star_1'}>
                    {STAR_IMAGE}
                </div>
            );
            STARS.push(STAR1);

            if (stars >= 2) {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_yellow.png"/>
                );
            } else {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_grey.png"/>
                );
            }

            let STAR2 = (
                <div key={'star_2'}>
                    {STAR_IMAGE}
                </div>
            );
            STARS.push(STAR2);

            if (stars >= 3) {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_yellow.png"/>
                );
            } else {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_grey.png"/>
                );
            }

            let STAR3 = (
                <div key={'star_3'}>
                    {STAR_IMAGE}
                </div>
            );
            STARS.push(STAR3);

            if (stars >= 4) {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_yellow.png"/>
                );
            } else {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_grey.png"/>
                );
            }
            let STAR4 = (
                <div key={'star_4'}>
                    {STAR_IMAGE}
                </div>
            );
            STARS.push(STAR4);

            if (stars >= 5) {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_yellow.png"/>
                );
            } else {
                STAR_IMAGE = (
                    <img className="currier_star_img" src="/system_images/star_grey.png"/>
                );
            }
            let STAR5 = (
                <div key={'star_5'}>
                    {STAR_IMAGE}
                </div>
            );
            STARS.push(STAR5);

            return (
                <div className="mobile_stars">
                    <div className="currier_stars_holder">
                        {STARS}
                    </div>
                    {this.renderThankText(window.innerWidth < 1000)}
                </div>
            );
        }
    }

    setStars(STAR) {
        this.setState({STARS: STAR});
        this.leaveRate(STAR);
        // if (STAR < 4) {
        //     this.setState({POLL_MODAL: true});
        // }
    }

    renderRateStars() {
        if (this.state.STARS > 0) {

        }
        // if (this.state.STARS > 0) {
        //     if (this.state.POLLED) {
        //         return (
        //             <View>
        //                 <Text
        //                     style={{
        //                         textAlign: 'center',
        //                         fontFamily: 'DinPro-Medium',
        //                         fontWeight: 'bold',
        //                         fontSize: 14,
        //                         lineHeight: 15,
        //                         color: '#43B02A',
        //                     }}
        //                 >
        //                     Мы обязательно учтём ваш отзыв.
        //                 </Text>
        //                 <Text
        //                     style={{
        //                         textAlign: 'center',
        //                         fontFamily: 'DinPro-Medium',
        //                         fontSize: 14,
        //                         lineHeight: 15,
        //                         color: '#5A5A5A',
        //                     }}
        //                 >
        //                     Спасибо, что помогаете нам становиться лучше!
        //                 </Text>
        //             </View>
        //
        //         );
        //     } else {
        //         return (
        //             <View>
        //                 <Text
        //                     style={{
        //                         textAlign: 'center',
        //                         fontFamily: 'DinPro-Medium',
        //                         fontWeight: 'bold',
        //                         fontSize: 14,
        //                         lineHeight: 15,
        //                         color: '#43B02A',
        //                     }}
        //                 >
        //                     Спасибо за вашу оценку!
        //                 </Text>
        //                 <Text
        //                     style={{
        //                         textAlign: 'center',
        //                         fontFamily: 'DinPro-Medium',
        //                         fontSize: 14,
        //                         lineHeight: 15,
        //                         color: '#5A5A5A',
        //                     }}
        //                 >
        //                     После получения заказа вам придёт пуш-сообщение для оценки качества товаров. Пожалуйста, помогайте нам становиться лучше!
        //                 </Text>
        //             </View>
        //
        //         );
        //     }
        // }
        // "Спасибо, ваша оценка будет учтена"
        let RATING_TEXT = ""
        let stars = this.state.STARS;
        let STAR_IMAGE = false;
        let STARS = [];


        if (stars >= 1) {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_yellow.png"/>
            );
        } else {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_grey.png"/>
            );
        }
        let STAR1 = (
            <div
                onClick={() => this.setStars(1)}
                key={'star_1'}
            >
                {STAR_IMAGE}
            </div>
        );
        STARS.push(STAR1);

        if (stars >= 2) {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_yellow.png"/>
            );
        } else {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_grey.png"/>
            );
        }
        let STAR2 = (
            <div
                onClick={() => this.setStars(2)}
                key={'star_2'}
            >
                {STAR_IMAGE}
            </div>
        );
        STARS.push(STAR2);

        if (stars >= 3) {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_yellow.png"/>
            );
        } else {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_grey.png"/>
            );
        }
        let STAR3 = (
            <div
                onClick={() => this.setStars(3)}
                key={'star_3'}
            >
                {STAR_IMAGE}
            </div>
        );
        STARS.push(STAR3);

        if (stars >= 4) {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_yellow.png"/>
            );
        } else {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_grey.png"/>
            );
        }
        let STAR4 = (
            <div
                onClick={() => this.setStars(4)}
                key={'star_4'}
            >
                {STAR_IMAGE}
            </div>
        );
        STARS.push(STAR4);

        if (stars >= 5) {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_yellow.png"/>
            );
        } else {
            STAR_IMAGE = (
                <img className="currier_star_img" src="/system_images/star_grey.png"/>
            );
        }
        let STAR5 = (
            <div
                onClick={() => this.setStars(5)}
                key={'star_5'}
            >
                {STAR_IMAGE}
            </div>
        );
        STARS.push(STAR5);

        return (
            <div className="mobile_stars">
            <div className="currier_stars_holder">
                {STARS}
            </div>
            </div>
        );
    }

    renderRating = () => {

        if (parseInt(this.props.COURRIER.stars) === 0) {
            if (this.state.SHOW_FINISHED_RAITING) {
                return this.renderStars(parseInt(this.state.STARS))
            } else {
                return this.renderRateStars()
            }

        } else {
            return this.renderStars(this.props.COURRIER.stars)
        }
    }

    changeStyle = (Id) => {
        if (Id !== '5') {
            this.setState({SHOW_TIPS_INPUT: false})
        }
        if (Id) {
            document.getElementById('1').style.background = "#FFFFFF";
            document.getElementById('2').style.background = "#FFFFFF";
            document.getElementById('3').style.background = "#FFFFFF";
            document.getElementById('4').style.background = "#FFFFFF";
            document.getElementById('5').style.background = "#FFFFFF";
            document.getElementById(Id).style.background = "#E0DDD8";
        }
    }

    renderTipsBlock = () => {
        if (!this.state.SHOW_THANKS_TEXT) {
            if (this.state.STARS >= 5) {
                if(window.innerWidth > 1200){
                return (
                    <div className="tips_holder">
                        <div>Вы можете оставить чаевые курьеру</div>
                        <div className="tips_sum_holder">
                            <div className="tips_sum" id='1' onClick={() => {
                                this.setState({TIPS: 10});
                                this.changeStyle('1')
                            }}>10 р
                            </div>
                            <div className="tips_sum" id='2' onClick={() => {
                                this.setState({TIPS: 20});
                                this.changeStyle('2')
                            }}>20 р
                            </div>
                            <div className="tips_sum" id='3' onClick={() => {
                                this.setState({TIPS: 50});
                                this.changeStyle('3')
                            }}>50 р
                            </div>
                            <div className="tips_sum" id='4' onClick={() => {
                                this.setState({TIPS: 100});
                                this.changeStyle('4')
                            }}>100 р
                            </div>
                            <div className="tips_sum" id='5' onClick={() => {
                                this.setState({SHOW_TIPS_INPUT: true, TIPS: 0});
                                this.changeStyle('5')
                            }}>другая сумма
                            </div>
                        </div>
                    </div>
                )
                }
                else{
                    return (
                        <div className="tips_holder">
                            <div style={{textAlign: 'center', fontSize: '12px'}}>Чаевые курьеру</div>
                            <div className="tips_sum_holder">
                                <div style={{display: 'flex'}}>
                                <div className="tips_sum" id='1' onClick={() => {
                                    this.setState({TIPS: 10});
                                    this.changeStyle('1')
                                }}>10 р
                                </div>
                                <div className="tips_sum" id='2' onClick={() => {
                                    this.setState({TIPS: 20});
                                    this.changeStyle('2')
                                }}>20 р
                                </div>
                                <div className="tips_sum" id='3' onClick={() => {
                                    this.setState({TIPS: 50});
                                    this.changeStyle('3')
                                }}>50 р
                                </div>
                                </div>
                                <div style={{display: 'flex', marginTop: "10px"}}>
                                <div className="tips_sum" id='4' onClick={() => {
                                    this.setState({TIPS: 100});
                                    this.changeStyle('4')
                                }}>100 р
                                </div>
                                <div className="tips_sum" id='5' onClick={() => {
                                    this.setState({SHOW_TIPS_INPUT: true, TIPS: 0});
                                    this.changeStyle('5')
                                }}>другая сумма
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            } else return false
        }

    }

    renderSaveTipsButton = () => {
        //
        if (this.state.TIPS || this.state.TIPS === 0) {
            return (
                <div style={{
                    background: (parseInt(this.state.TIPS) !== 0)
                        ? "#43B02A"
                        : "#C4C4C4"
                }} className="tips_save_green_button" onClick={() => this.sendTipsToServer()}>
                    Оставить чаевые
                </div>
            )
        } else return false;
    }


    sendTipsToServer = async () => {

        if ((parseInt(this.state.TIPS) !== 0)) {

            try {
                let SEND_DATA = {
                    action: 'recordTips',
                    id: this.props.ID,
                    tips: this.state.TIPS,
                    cour: this.props.COURRIER.id,
                    apikey: this.props.USER.TOKEN,

                };
                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.setState({SHOW_THANKS_TEXT: true, SHOW_TIPS_INPUT: false})
                }

            } catch (error) {
                console.log('BAD_ORDER_DATA');
            }
        }

    }

    showThanksText = () => {
        let TEXT = 'Большое спасибо за чаевые!'
        return (
            <div style={{display: 'flex', fontSize: "font-size: 14px", color: "#5A5A5A"}}>
                😊 <div style={{paddingLeft: '10px'}}>{TEXT}</div>
            </div>
        )
    }

    updateTips = (tips) => {
        this.setState({TIPS: tips.target.value});
    }

    renderTipsInput = () => {

        if (this.state.SHOW_TIPS_INPUT) {
            return (
                <input
                    className="tips_input"
                    type="number"
                    min="0"
                    onChange={(tips) => this.updateTips(tips)} placeholder="Сумма чаевых"
                />
            )
        }
    }

    updateComment = (comment) => {
        this.setState({COMMENT: comment.target.value})
    }

    renderCommentInputAfterCheckBoxes = () => {
        if (this.state.SHOW_ALL_INPUTS_AFTER_COMMENT) {
            if (this.state.SHOW_INPUT_AFTER_COMMENT) {
                if (this.state.CHECKBOX_COMMENT !== false) {
                    return (
                        <div className="button_and_comment_holder">
                            <input
                                className="currier_comment_input"
                                type="text"
                                min="0"
                                onChange={(comment) => this.updateComment(comment)}
                                placeholder="Комментарий по доставке"
                            />
                            {this.renderSaveCommentButton()}
                        </div>
                    )
                } else {
                    return (
                        <div>
                            <div className="text_after_comment_holder">
                                <div className="green_text_comment">
                                    Мы обязательно учтём ваш отзыв.
                                </div>
                                <div>
                                    Спасибо, что помогаете нам становиться лучше!
                                </div>
                                <div className="corrier_block_transparent_text">
                                    Если вам есть, что еще добавить к отзыву, то <br/> вы можете оставить комментарий
                                    ниже
                                </div>
                            </div>

                            <div className="button_and_comment_holder">
                                <input
                                    className="currier_comment_input"
                                    type="text"
                                    min="0"
                                    onChange={(comment) => this.updateComment(comment)}
                                    placeholder="Комментарий по доставке"
                                />
                                {this.renderSaveCommentButton()}
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    renderCommentInput = () => {
        if (!this.state.SHOW_TEXT_AFTER_COMMENT) {
            if (this.state.STARS) {
                if (parseInt(this.state.STARS) === 4)
                    return (
                        <div className="button_and_comment_holder">
                            <input
                                className="currier_comment_input"
                                type="text"
                                min="0"
                                onChange={(comment) => this.updateComment(comment)}
                                placeholder="Комментарий по доставке"
                            />
                            {this.renderSaveCommentButton()}
                        </div>
                    )
            }
        } else return false
    }


    renderSaveCommentButton = () => {

        let STYLE = {'backgroundImage': 'url(' + this.state.IMAGE_URL + ')', position: 'relative'};

        let iMageLoaded = (
            <label className="relative">
                <div style={STYLE} className="courrier_bad_picture"></div>
                <input
                    id="file"
                    style={{display: "none"}}
                    className="photo_input"
                    type="file"
                    placeholder={this.state.label}
                    onChange={(e) => this.pictureChosen(e)} accept=".jpg,.jpeg,.png"
                />
                <img className="red_circle_with_cross" src="/system_images/red_circle_with_cross.png" onClick={() => this.setState({IMAGE_URL: false})}/>
            </label>
        )
        if (!this.state.SHOW_TEXT_AFTER_COMMENT) {

            if (this.state.COMMENT.length > 0) {
                return (
                    <div className="comment_and_photo_holder">
                        {this.state.IMAGE_URL
                            ? iMageLoaded
                            : this.imageDownload()}
                        <div style={{background: "#43B02A"}} className="send_comment_button"
                             onClick={() => 
                                this.leaveCourrierComment()
                                }>
                            Отправить
                        </div>
                    </div>

                )
            } else {
                return (
                    <div className="comment_and_photo_holder">
                        {this.state.IMAGE_URL
                            ? iMageLoaded
                            : this.imageDownload()}
                        <div style={{background: "#C4C4C4"}} className="send_comment_button">
                            Отправить
                        </div>
                    </div>
                )
            }
        } else return false
    }

    leaveCourrierComment = async () =>
    {
        let SEND_DATA = new FormData();

        SEND_DATA.append('action', 'recordDeliveryComment');
        SEND_DATA.append('apikey', this.props.USER.TOKEN);
        SEND_DATA.append('comment', this.state.COMMENT);
        SEND_DATA.append('PICTURE', this.state.LOADED_IMAGE);
        SEND_DATA.append('cour', this.props.COURRIER.id);
        SEND_DATA.append('id', this.props.ID);

        fetch( 
            CONFIG.API_GATE, 
            {
                credentials: 'include',
                method: "POST",
                body: SEND_DATA
            }
        )
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status.result == 'SUCCESS') {
                this.setState({
                    SHOW_TEXT_AFTER_COMMENT: true,
                    SHOW_ALL_INPUTS_AFTER_COMMENT: false,
                    SHOW_INPUT_AFTER_COMMENT:false
                });
            }
        })
        .catch(err => {
        })
    }

    setCheckBoxComment = (data) => {
        this.setState({CHECKBOX_COMMENT: data});
        this.sendPollToServer(data);
    }

    sendPollToServer = async (poll_results) =>
    {
        try {
            let SEND_DATA = {
                action:     'recordCourrierPoll',
                id:         this.state.ID,
                results:    poll_results,
                cour:       this.state.COURRIER.id,
                apikey:     this.props.USER.TOKEN,

            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
        } catch (error) {
            console.log('BAD_ORDER_DATA');
        }
    }

    renderTextAfterComment = () => {
        if (this.state.SHOW_TEXT_AFTER_COMMENT && this.state.SHOW_INPUT_AFTER_COMMENT === false) {
            return (
                <div className="text_after_comment_holder">
                    <div className="green_text_comment">
                        Мы обязательно учтём ваш отзыв.
                    </div>
                    <div>
                        Спасибо, что помогаете нам становиться лучше!
                    </div>
                </div>
            )
        }
    }


    pictureChosen(e) {
        let file = e.target.files[0];

        if ((typeof file !== "undefined") && (file)) {
            let url = URL.createObjectURL(file)
            this.setState({IMAGE_URL: url, LOADED_IMAGE: file})
        }
    }

    imageDownload = () => {
        // <input type="button" id="loadFileXml" value="loadXml" onClick="document.getElementById('file').click();"/>
        // <input type="file" style="display:none;" id="file" name="file"/>
        return (
            <label>
                <div style={{backgroundImage: 'url(/system_images/camera.png)'}} className="courrier_bad_picture"></div>
                <input
                    id="file"
                    style={{display: "none"}}
                    className="photo_input"
                    type="file"
                    placeholder={this.state.label}
                    onChange={(e) => this.pictureChosen(e)} accept=".jpg,.jpeg,.png"
                />
            </label>
        )
    }

    closeMainBlock = () => {
        document.getElementById("mainBlock").style.display = 'none'
    }

    showBadRatingModal = () => {
        if (this.state.SHOW_BAD_RATING_MODAL) {
            return (
                <BadRatingModal setBoxComment={this.setCheckBoxComment} closeBadRatingModal={this.closeBadRatingModal}/>
            )
        } else {
            return <div></div>
        }
    }


    closeBadRatingModal = () => {
        this.setState({
            SHOW_BAD_RATING_MODAL: false,
            SHOW_INPUT_AFTER_COMMENT: true,
            SHOW_ALL_INPUTS_AFTER_COMMENT: true
        })
    }

    renderCurrierName = (screen) => {
        if (screen) {
            return (
                <div className="courrier_name_and_rating_text">
                    {this.props.COURRIER.name}<br/>
                    {this.props.COURRIER.rate}
                </div>
            )
        }
        else return false
    }

    renderThankText = (screen) => {

        if (screen) {
            return (
                this.state.SHOW_FINISHED_RAITING
                        ? <div className="courier_thanks_text">Спасибо, ваша оценка будет учтена</div>
                        : false

            )
        }
        else return false
    }

    renderButtonAndTextInput = () =>
    {
        if (this.state.SHOW_THANKS_TEXT) {
            return (
                <div className="button_and_text_input">
                    {this.state.SHOW_THANKS_TEXT
                        ? this.showThanksText()
                        : this.renderSaveTipsButton()
                    }
                    {this.renderTipsInput()}
                </div>
            )
        }
    }

    render() {
        let STYLE = {}

        if (this.state.COURRIER.image_path == "") {
            STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'}
        } else {
            STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + this.props.COURRIER.image_path + ')'};
        }

        return (
            <div className="courrier_full_holder" id="mainBlock">
                <div className="courrier_block">
                    {/*<div>Заказ доставлен в {this.props.COURRIER.arrival_time}</div>*/}
                    <div className="courrier_imgclose_and_text">
                        <div className="courrier_block_green_text">{this.props.COURRIER.arrival_time}</div>
                        <img src="/system_images/courrier_close.png"
                            onClick={() => this.closeMainBlock()}/>
                    </div>
                    <div className="courrier_check_text">Оцените курьера</div>
                    <div className="currier_stars_and_face">
                        <div className="currier_stars_and_face_sub" style={{display: 'flex'}}>
                            <div>
                                <div className="courrier_face_image" style={STYLE}>
                                </div>
                                {this.renderCurrierName(window.innerWidth < 1000)}
                            </div>
                            {/*{this.renderStars(3)}*/}
                            {this.renderRating()}
                        </div>
                        {this.showBadRatingModal()}
                        {this.renderThankText(window.innerWidth > 1000)}
                        {/*{this.state.SHOW_FINISHED_RAITING*/}
                        {/*    ? <div className="courier_thanks_text">Спасибо, ваша оценка будет учтена</div>*/}
                        {/*    : false*/}
                        {/*}*/}
                    </div>
                    {this.renderTextAfterComment()}
                    {this.renderCommentInputAfterCheckBoxes()}
                    {this.renderCommentInput()}
                    {this.renderTipsBlock()}
                    {this.renderButtonAndTextInput()}
                    

                    {this.renderCurrierName(window.innerWidth > 1000)}

                </div>
            </div>
        )
    }


}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CourrierBlock);