import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import CartContent from './../../APP/COMPONENTS/ContentParts/CartContent';
import CartButton from './../../APP/COMPONENTS/ContentParts/CartButton';
import AddonContent from './../../APP/COMPONENTS/ContentParts/AddonContent';
import AddonButton from './../../APP/COMPONENTS/ContentParts/AddonButton';

function mapStateToProps(state) {
    return {
        CART_MODAL: state.CART_MODAL,
        ADDING_TO_EXISTING: state.ADDING_TO_EXISTING
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Cart extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {};

        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.CART_MODAL !== prevProps.CART_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.CART_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    mobileScreenChecker = () => {
        if (window.innerWidth <= 1200) {
            return (
                <div>
                    <a className="cart_back_arrow" onClick={() => this.props.openCart(false)}>
                        <img src="/system_images/cart_back.png"/>
                    </a>
                    <h5 className= "cart_text_mobile">
                        Моя корзина
                    </h5>
                </div>
            )
        } else {
            return (
                <div>
                    <a className="close-modal" onClick={() => this.props.openCart(false)}>
                        <img src="/system_images/close.png"/>
                    </a>
                    <h4>
                        Моя корзина
                    </h4>
                </div>
            )
        }
    }

    render() {
        if (this.props.CART_MODAL) {
            if (this.props.ADDING_TO_EXISTING) {
                return (
                    <div>
                        <div className="modal-overlay" onClick={() => this.props.openCart(false)}>
                        </div>
                        <div className="modal-box static-modal cart-modal" id="cart">
                            <div className="modal_top">
                                <a className="close-modal" onClick={() => this.props.openCart(false)}>
                                    <img src="/system_images/close.png"/>
                                </a>
                                <h4>
                                    Моя корзина
                                </h4>
                            </div>

                            <AddonContent/>
                            <AddonButton/>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="modal-overlay" onClick={() => this.props.openCart(false)}>
                        </div>
                        <div className="modal-box static-modal cart-modal" id="cart">
                            <div className="modal_top">
                                {this.mobileScreenChecker()}
                            </div>

                            <CartContent/>
                            <CartButton/>
                        </div>
                    </div>
                );
            }
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Cart);