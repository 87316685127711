import React, {Component, useState} from 'react';
import {Switch, Route, withRouter, Redirect, NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../APP/HELPERS/requests"
import {CSSTransition, TransitionGroup, CSSTransitionGroup} from 'react-transition-group';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';

import PhoneInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/PhoneInput.js';
import NameInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/NameInput.js';
import ProductInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/ProductInput.js';
import EmailInput, {emailRegex} from './../../APP/COMPONENTS/ContentParts/AnketaParts/EmailInput.js';
import CityInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/CityInput.js';
import TextInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/TextInput.js';
import BirthdayInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/BirthdayInput.js';
import WhatsappInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/WhatsappInput.js';
import ImageRadio from './../../APP/COMPONENTS/ContentParts/AnketaParts/ImageRadio.js';
import DoubleTextedRadio from './../../APP/COMPONENTS/ContentParts/AnketaParts/DoubleTextedRadio.js'
import ImageRadioRow from './../../APP/COMPONENTS/ContentParts/AnketaParts/ImageRadioRow.js';
import TextedRadio from './../../APP/COMPONENTS/ContentParts/AnketaParts/TextedRadio.js';
import TextedRadioMultiple from './../../APP/COMPONENTS/ContentParts/AnketaParts/TextedRadioMultiple.js';
import CountryInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/CountryInput.js';
import StarsInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/StarsInput.js';
import GroupSearchInput from './../../APP/COMPONENTS/ContentParts/AnketaParts/GroupSearchInput.js';
import WithOnBlur from "../HELPERS/WithOnBlur";
import {getWindowDimensions, useWindowDimensions} from "../HELPERS/hooks/hooks";
import SubmitBonuses from "../COMPONENTS/ContentParts/SubmitBonuses";

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CATS: state.CATS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const STORES = [
    {title: 'Перекресток', fieldName: 'perekrestok', value: [], disabledIndex: false},
    {title: 'ВкусВилл', fieldName: 'vkusvill', value: [], disabledIndex: false},
    {title: 'Азбука Вкуса', fieldName: 'azbukavkusa', value: [], disabledIndex: false},
    {title: 'Пятёрочка', fieldName: 'pyaterochka', value: [], disabledIndex: false},
    {title: 'Магнит', fieldName: 'marnit', value: [], disabledIndex: false},
    {title: 'Утконос', fieldName: 'utkonos', value: [], disabledIndex: 1},
    {title: 'OZON Express', fieldName: 'ozonexpress', value: [], disabledIndex: 1},
    {title: 'Самокат', fieldName: 'samokat', value: [], disabledIndex: 1},
    {title: 'Ашан', fieldName: 'ashan', value: [], disabledIndex: false},
    {title: 'Яндекс Лавка', fieldName: 'yandexlavka', value: [], disabledIndex: 1},
    {title: 'Сбермаркет', fieldName: 'sbermarket', value: [], disabledIndex: 1},
    {title: 'Лента', fieldName: 'lenta', value: [], disabledIndex: false},
    {title: 'Гипер Глобус', fieldName: 'giperglobus', value: [], disabledIndex: false},
    {title: 'Euro Spar', fieldName: 'eurospar', value: [], disabledIndex: false},
    {title: 'Только Экомаркет', fieldName: 'ecomarket', value: [], disabledIndex: 1},
    {title: 'другое', fieldName: 'else', value: [], disabledIndex: false},
]
const WIDTH = window.innerWidth;

class Closer extends Component {

    constructor(props) {
        super(props);

        let MAX_STEPS = 7;
        if (WIDTH > 980) {
            MAX_STEPS = 4;
        }

        this.state = {
            EARNED: 0,
            questions: 0,
            errors: [],
            STEP: 0,
            MAX_STEPS: MAX_STEPS,
            CATS: props.CATS,
            SUCCESS: false,
            suggestions: [],
            PHONE: '',
            NAME: '',
            EMAIL: '',
            WHATSAPP: '',
            REGION: 0,
            REGION_ELSE: '',
            BIRTHDAY: '',
            SEX: '',
            ORGANIC: '',
            POINT: '',
            FAMILY_STATUS: '',
            CHILDREN: '',
            ANIMALS: '',
            ANIMAL_TYPES: [],
            OTHER_STORES: STORES,
            OTHER_STORES_ELSE: '',
            POPULAR_STORES: STORES.map((item) => ({
                ...item,
                value: false,
                disabledIndex: false,
            })),
            POPULAR_STORES_ELSE: '',
            PRODUCT_1: '',
            PRODUCT_2: '',
            PRODUCT_3: '',
            WHERE: '',
            HOW: [],
            COUNTRY_1: '',
            COUNTRY_2: '',
            COUNTRY_3: '',
            DAYS: '',
            FOOD_T: [],
            WEEKENDS: [],
            MONTHS: [],
            DAYS_IN_YEAR: [],
            FOOD_С: [],
            GROUP: [],
            REST: [],
            SERVICE: [],
            BIG_GROUP: '',
            SOCIALS: [],
            INSTAGRAM: '',
            countries: [''],
            floatingCounterVisible: false,
            acceptPersonalData: false,
        }

        this.myRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.CATS !== prevProps.CATS) {
            this.setState({CATS: this.props.CATS});
        }

        if (this.props.USER !== prevProps.USER) {
            if (this.props.USER) {
                this.setState({
                    USER: this.props.USER,
                    NAME: this.props.USER.NAME,
                    PHONE: this.props.USER.PHONE,
                    EMAIL: this.props.USER.EMAIL
                })
                this.countUsersBonuses();
            } else {
                this.setState({
                    USER: false,
                    NAME: '',
                    PHONE: '',
                    EMAIL: ''
                });
                this.countUsersBonuses();
            }
        }
    }

    componentDidMount() {
        this.countUsersBonuses();
        const {width} = getWindowDimensions()
        window.addEventListener('scroll', (e) => {
            this.setState({floatingCounterVisible: width >= 980 ? window.scrollY > 185 : window.scrollY > 90})
        })

    }

    createOnBlurHandler = fieldName => {
        let error = false
        const value = this.state[fieldName]


        if (fieldName === 'EMAIL' && !emailRegex.test(value))
            error = true

        else if (fieldName === 'PHONE' && this.state.PHONE.length !== 16)
            error = true

        else if (value === '')
            error = true


        return () => {
            if (error) this.setState({errors: [...this.state.errors, fieldName]})
        }
    }

    getHasError = (fieldName) => {
        return this.state.errors?.includes(fieldName)
    }

    blurProps = (fieldName) => ({
        onBlur: this.createOnBlurHandler(fieldName),
        hasError: this.getHasError(fieldName)
    })

    countUsersBonuses = () => {
        let COMPONENT = this;

        setTimeout(function () {
            let EARNED = 0;
            let questions = 0
            if (COMPONENT.state.NAME !== '') {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.PHONE !== '') {
                EARNED += 100;
                questions++
            }
            if (COMPONENT.state.EMAIL !== '') {
                EARNED += 100;
                questions++
            }
            if (COMPONENT.state.REGION > 0) {
                EARNED += 40;
                questions++
            }
            if (COMPONENT.state.BIRTHDAY !== '') {
                EARNED += 100;
                questions++
            }
            if (COMPONENT.state.SEX !== '') {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.ORGANIC !== '') {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.POINT !== '') {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.FAMILY_STATUS !== '') {
                EARNED += 50;
                questions++
            }
            if (COMPONENT.state.CHILDREN !== '') {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.DAYS !== '') {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.FOOD_T.length >= 1) {
                EARNED += 50;
                questions++
            }
            if (COMPONENT.state.OTHER_STORES.length >= 1) {
                const answered = !!COMPONENT.state.OTHER_STORES.find(({value}) => value.length >= 1)
                if (answered) {
                    EARNED += 200;
                    questions++
                }
            }
            if (COMPONENT.state.POPULAR_STORES.length >= 1) {
                const answered = !!COMPONENT.state.POPULAR_STORES.find(({value}) => value !== false)
                if (answered) {
                    EARNED += 100;
                    questions++
                }
            }
            if (COMPONENT.state.WEEKENDS.length >= 1) {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.DAYS_IN_YEAR.length >= 1) {
                EARNED += 50;
                questions++
            }
            if (COMPONENT.state.MONTHS.length >= 1) {
                EARNED += 60;
                questions++
            }
            if (COMPONENT.state.FOOD_С.length >= 1) {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.REST.length >= 1) {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.GROUP.length >= 1) {
                EARNED += 100;
                questions++
            }
            if (COMPONENT.checkService()) {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.WHERE !== '') {
                EARNED += 30;
                questions++
            }
            if ((COMPONENT.state.PRODUCT_1 !== "") || (COMPONENT.state.PRODUCT_2 !== "") || (COMPONENT.state.PRODUCT_3 !== "")) {
                EARNED += 100;
                questions++
            }
            if ((COMPONENT.state.COUNTRY_1 !== "") || (COMPONENT.state.COUNTRY_2 !== "") || (COMPONENT.state.COUNTRY_3 !== "")) {
                EARNED += 20;
                questions++
            }
            if (COMPONENT.state.SOCIALS.length >= 1) {
                EARNED += 30;
                questions++
            }
            if (COMPONENT.state.INSTAGRAM !== '') {
                EARNED += 100;
                questions++
            }
            if (COMPONENT.state.BIG_GROUP !== '') {
                EARNED += 20;
                questions++
            }


            if (COMPONENT.state.ANIMAL_TYPES.length >= 1) {
                EARNED += 20;
                questions++
            }


            if (COMPONENT.state.HOW.length >= 1) {
                EARNED += 60;
                questions++
            }


            COMPONENT.setState({questions, EARNED});
        }, 50);
    }

    checkService = () => {
        let GOOD = [];
        for (let i = 0; i < this.state.SERVICE.length; i++) {
            if (this.state.SERVICE[i] > 0) {
                GOOD++;
            }
        }

        if (GOOD == 6) {
            return true;
        } else {
            return false;
        }
    }

    setField = (TITLE, VALUE) => {
        this.setState({[TITLE]: VALUE, errors: this.state.errors.filter(err => err !== TITLE)});
        this.countUsersBonuses();
    }

    resetField = (TITLE) => {
        this.setState({[TITLE]: ''});
        this.countUsersBonuses();
    }

    setDoubleRadioField = (title, value, index) => {
        let arr = [...this.state[title]]
        arr[index].value = value
        this.setState({[title]: arr, errors: this.state.errors.filter(err => err !== title)})
        this.countUsersBonuses();
    }

    renderName = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.NAME !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (

            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        1.&nbsp;&nbsp;
                    </div>
                    <div>
                        Ваше имя
                    </div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <WithOnBlur {...this.blurProps('NAME')}>
                            <NameInput placeholder={'Как вас зовут?'} NAME={this.state.NAME} setField={this.setField}
                                       resetField={this.resetField}/>
                        </WithOnBlur>
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>

        );
    }

    renderOtherStores = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.OTHER_STORES !== '') {
            for (let i = 0; i < this.state.OTHER_STORES.length; i++) {
                if (this.state.OTHER_STORES[i].value.length >= 1) {
                    CLASSES = 'anketa_bonuses validated';
                }
            }
        }

        return (

            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>11.&nbsp;&nbsp;</div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div>Где вы еще покупаете продукты <br/> (уточните, онлайн или офлайн) <br/> <br/></div>
                        <div style={{display: 'flex', width: '320px', justifyContent: 'space-between'}}>
                            <div>Зная ваши предпочтения, мы станем <br/> лучше и удобнее для вас
                            </div>
                            <div style={{marginLeft: '20px'}} className={CLASSES}>
                                +200
                            </div>
                        </div>
                    </div>
                </div>
                <div className="anketa_ui">
                    <div className="otherStores">
                        <div className="onlineOrOffline"><span style={{fontSize: '20px'}}>покупаете</span>
                            <br/> онлайн <span>или</span> офлайн
                        </div>
                        <DoubleTextedRadio setField={this.setDoubleRadioField}
                                           field="OTHER_STORES"
                                           data={this.state.OTHER_STORES}/>
                    </div>
                    {this.state.OTHER_STORES[15].value.length !== 0 &&
                    <div className="stores_else">
                        <TextInput placeholder={'Напишите название, если другое'} FIELD="OTHER_STORES_ELSE"
                                   setField={this.setField} resetField={this.resetField}/>
                    </div>
                    }
                </div>
            </div>

        );
    }

    renderPopularStores = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.POPULAR_STORES !== '') {
            for (let i = 0; i < this.state.POPULAR_STORES.length; i++) {
                if (this.state.POPULAR_STORES[i].value !== false) {
                    CLASSES = 'anketa_bonuses validated';
                }
            }
        }

        const hiddenNames = this.state.OTHER_STORES.reduce((acc, cur) => {
            if (cur.value?.length < 1)
                return [...acc, cur.fieldName]
            else return acc
        }, [])

        let visible = this.state.OTHER_STORES.length !== hiddenNames.length

        return (

            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>20.&nbsp;&nbsp;</div>
                    <div>
                        <div style={{display: 'flex', width: '320px', justifyContent: 'space-between'}}>
                            <div>В каком магазине вы чаще <br/> закупаетесь?</div>
                            <div style={{marginLeft: '20px'}} className={CLASSES}>
                                +100
                            </div>
                        </div>
                    </div>
                </div>
                <div className="anketa_ui">
                    {
                        visible
                            ? <div>
                                <div className="otherStores">
                                    <div className="oftenOrRarely">
                                        <span>часто</span>
                                        <span>редко</span>
                                    </div>
                                    <DoubleTextedRadio setField={this.setDoubleRadioField}
                                                       field="POPULAR_STORES"
                                                       hiddenNames={hiddenNames}
                                                       unique={true}
                                                       data={this.state.POPULAR_STORES}/>
                                </div>

                            </div>
                            : <div className='questionEleven'>Пожалуйста, ответьте сначала на вопрос 11</div>
                    }


                </div>
            </div>

        );
    }

    getGeoSuggestions = (string) => {
        if (this.timer)
            clearTimeout(this.timer)

        this.timer = setTimeout(async () => {
            const url = (apikey) => `https://geocode-maps.yandex.ru/1.x?geocode=${string}&apikey=${apikey}&kind=house&rspn=1&bbox=20.742187,26.431228~188.789062,78.903929&format=json`
            const response = await fetch(
                url(CONFIG.GEOCODER_KEY),
                {method: "GET"}
            );

            const responseJson = await response?.json();
            const suggestions = responseJson?.response?.GeoObjectCollection.featureMember.map(el => {
                const isRussia = el.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted.includes('Россия')
                if (!isRussia)
                    return null
                const formatted = el.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted
                let full = formatted.replace('Россия, ', '')
                return full
            }).filter(v => !!v)

            this.setState({suggestions})

            console.log({suggestions})
        }, 400)

    }

    renderRegion = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.REGION > 0) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        6.&nbsp;&nbsp;  </div>
                    <div>Ваш регион</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <CityInput setField={this.setField} resetField={this.resetField}/>
                        {this.state.REGION === '1' && <div className="region_else">
                            <TextInput value={this.state.REGION_ELSE} suggestions={this.state.suggestions}
                                       onChange={(value) => this.getGeoSuggestions(value)}
                                       FIELD="REGION_ELSE" placeholder={'Название населённого пункта'}
                                       setField={this.setField} resetField={this.resetField}/>
                        </div>}
                        {
                            <div>

                            </div>
                        }
                    </div>
                    <div className={CLASSES}>
                        +40
                    </div>
                </div>
            </div>
        );
    }

    renderBirthday = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.BIRTHDAY !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        4.&nbsp;&nbsp;  </div>
                    <div> День рождения</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui birthday">
                        <BirthdayInput setField={this.setField} resetField={this.resetField}/>
                    </div>
                    <div className={CLASSES}>
                        +100
                    </div>
                </div>
            </div>
        );
    }

    renderSex = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.SEX !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        5.&nbsp;&nbsp;  </div>
                    <div>Ваш пол</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <div className="sex">
                            <ImageRadio
                                setField={this.setField}
                                resetField={this.resetField}
                                field="SEX"
                                data={[
                                    {VALUE: 'MALE', IMAGE: '/system_images/anketa_male.png'},
                                    {VALUE: 'FEMALE', IMAGE: '/system_images/anketa_female.png'}
                                ]}
                            />
                        </div>
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>
        );
    }

    renderOrganic = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.ORGANIC !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>17.&nbsp;&nbsp;</div>
                    <div>Имеет ли для вас значение <br/>слово «органика»</div>
                    <div><img style={{margin: '5px 0 0 22px'}} src="/system_images/organic.svg"/></div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <div className="organic">
                            <TextedRadio
                                setField={this.setField}
                                resetField={this.resetField}
                                field="ORGANIC"
                                data={[
                                    {VALUE: 1, TEXT: 'Да'},
                                    {VALUE: 2, TEXT: 'Нет'},
                                ]}
                            />
                        </div>
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>
        );
    }

    renderPoint = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.POINT !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        9.&nbsp;&nbsp;  </div>
                    <div>Куда чаще всего заказываете?</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <TextedRadio
                            setField={this.setField}
                            resetField={this.resetField}
                            field="POINT"
                            data={[
                                {VALUE: 1, TEXT: 'Частный дом'},
                                {VALUE: 2, TEXT: 'Квартира'},
                                {VALUE: 3, TEXT: 'Офис'}
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>
        );
    }

    renderFamilyStatus = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.FAMILY_STATUS !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    Семейное положение
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <TextedRadio
                            setField={this.setField}
                            resetField={this.resetField}
                            field="FAMILY_STATUS"
                            data={[
                                {VALUE: 1, TEXT: 'женат/замужем'},
                                {VALUE: 2, TEXT: 'холост'}
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        50
                    </div>
                </div>
            </div>
        );
    }

    renderChildren = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.CHILDREN !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        8.&nbsp;&nbsp;  </div>
                    <div>Сколько детей в семье?</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <div className="children">
                            <TextedRadio
                                setField={this.setField}
                                resetField={this.resetField}
                                field="CHILDREN"
                                data={[
                                    {VALUE: 'N', TEXT: '0'},
                                    {VALUE: 1, TEXT: '1'},
                                    {VALUE: 2, TEXT: '2'},
                                    {VALUE: 3, TEXT: '3'},
                                    {VALUE: 4, TEXT: '4+'}
                                ]}
                            />
                        </div>
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>
        );
    }

    renderAnimals = () => {
        let CLASSES = 'anketa_bonuses';

        if (this.state.ANIMAL_TYPES.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }


        let ANIMALS = (
            <div className="anketa_top_spacer">
                <ImageRadioRow
                    setField={this.setField}
                    resetField={this.resetField}
                    field="ANIMAL_TYPES"
                    className={'animals'}
                    selected={this.state.ANIMAL_TYPES}
                    data={[
                        {VALUE: '1', IMAGE: '/system_images/anketa_animal_1.png', TEXT: 'птичка'},
                        {VALUE: '2', IMAGE: '/system_images/anketa_animal_2.png', TEXT: 'кошка'},
                        {VALUE: '3', IMAGE: '/system_images/anketa_animal_3.png', TEXT: 'собачка'},
                        {VALUE: '4', IMAGE: '/system_images/anketa_animal_4.png', TEXT: 'рыбки'},
                        {VALUE: '5', IMAGE: '/system_images/anketa_animal_5.png', TEXT: 'другое'},
                        {VALUE: '6', IMAGE: '', TEXT: 'нет'},
                    ]}
                />
            </div>
        );

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        7.&nbsp;&nbsp;  </div>
                    <div>Домашние животные</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        {ANIMALS}
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>
        );
    }

    renderPhone = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.PHONE !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        2.&nbsp;&nbsp;  </div>
                    <div>Ваш номер телефона</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <WithOnBlur {...this.blurProps('PHONE')}>
                            <PhoneInput placeholder={'+7 ('} PHONE={this.state.PHONE} setField={this.setField}
                                        resetField={this.resetField}/>
                        </WithOnBlur>
                    </div>
                    <div className={CLASSES}>
                        +100
                    </div>
                </div>
            </div>
        );
    }

    renderEmail = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.EMAIL !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        3.&nbsp;&nbsp;  </div>
                    <div>Адрес вашей электронной почты</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <WithOnBlur {...this.blurProps('EMAIL')}><EmailInput placeholder={'Электронная почта'}
                                                                             NAME={this.state.EMAIL}
                                                                             setField={this.setField}
                                                                             resetField={this.resetField}/></WithOnBlur>
                    </div>
                    <div className={CLASSES}>
                        +100
                    </div>
                </div>
            </div>
        );
    }

    renderThreeProducts = () => {
        let CLASSES = 'anketa_bonuses';
        if ((this.state.PRODUCT_1 !== "") || (this.state.PRODUCT_2 !== "") || (this.state.PRODUCT_3 !== "")) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>12.&nbsp;&nbsp; </div>
                    <div>Выберите 3 любимых товара.
                        <div style={{fontSize: '12px'}}>(Со временем на эти 3 товара будет скидка)</div>
                    </div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <ProductInput field="PRODUCT_1" PRODUCT={this.state.PRODUCT_1} setField={this.setField}
                                      resetField={this.resetField}/>
                        <ProductInput field="PRODUCT_2" PRODUCT={this.state.PRODUCT_2} setField={this.setField}
                                      resetField={this.resetField}/>
                        <ProductInput field="PRODUCT_3" PRODUCT={this.state.PRODUCT_3} setField={this.setField}
                                      resetField={this.resetField}/>
                    </div>
                    <div className={CLASSES}>
                        +100
                    </div>
                </div>
            </div>
        );
    }

    renderWhere = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.WHERE !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    Где вы докупаете продукты или товары, которые не нашли в нашем магазине
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <TextInput FIELD="WHERE" setField={this.setField} resetField={this.resetField}/>
                    </div>
                    <div className={CLASSES}>
                        +30
                    </div>
                </div>
            </div>
        );
    }

    renderHow = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.HOW.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>
                        10.&nbsp;&nbsp;  </div>
                    <div>Как вы узнали о нас?</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <ImageRadioRow
                            setField={this.setField}
                            resetField={this.resetField}
                            field="HOW"
                            selected={this.state.HOW}
                            className={"how"}
                            data={[
                                {VALUE: '1', TEXT: 'Блогеры Инстаграм', IMAGES: ['/system_images/blondFemale.svg']},
                                {VALUE: '2', TEXT: 'Прочие блогеры', IMAGES: ['/system_images/female.png']},
                                {VALUE: '3', TEXT: 'Наш Инстраграм', IMAGES: ['/system_images/thunderphone.svg']},
                                {VALUE: '4', TEXT: 'В яндекс, гугл поисковике', IMAGES: ['/system_images/search.svg']},
                                {VALUE: '5', TEXT: 'Баннерная в Интернете', IMAGES: ['/system_images/bannerPhone.svg']},
                                {
                                    VALUE: '6',
                                    TEXT: 'Посоветовал знакомый',
                                    IMAGES: ['/system_images/blondFriend.svg', '/system_images/friendShake.svg']
                                },
                                {
                                    VALUE: '7',
                                    TEXT: 'Увидели на улице',
                                    IMAGES: ['/system_images/car.svg', '/system_images/road.svg', '/system_images/bike.svg']
                                },
                                {
                                    VALUE: '8',
                                    TEXT: 'ТВ, радио',
                                    IMAGES: ['/system_images/tv.svg', '/system_images/radio.svg']
                                },
                                {VALUE: '9', TEXT: 'Другое', IMAGES: ['/system_images/eye.svg']},
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        +60
                    </div>
                </div>
            </div>
        );
    }


    renderThreeCountries = () => {
        let CLASSES = 'anketa_bonuses';
        if ((this.state.COUNTRY_1 !== "") || (this.state.COUNTRY_2 !== "") || (this.state.COUNTRY_3 !== "")) {
            CLASSES = 'anketa_bonuses validated';
        }
        let {countries} = this.state

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>14.&nbsp;&nbsp;</div>
                    <div>Какие страны вы обычно посещаете?</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        {countries.map((value, index) => <CountryInput placeholder={'Название страны'}
                                                                       field={"COUNTRY_" + (index + 1)}
                                                                       setField={this.setField}
                                                                       resetField={this.resetField}/>)}
                        {countries.length < 3 && <div className="countries"
                                                      onClick={() => this.setState({countries: [...this.state.countries, '']})}>добавить
                            еще
                            страну
                        </div>}
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>
        );
    }

    renderDaysOut = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.DAYS !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>14.&nbsp;&nbsp;</div>
                    <div>Сколько дней в году вы отсутствуете в <br/> городе</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <TextedRadio
                            setField={this.setField}
                            resetField={this.resetField}
                            field="DAYS"
                            data={[
                                {VALUE: 1, TEXT: '10+'},
                                {VALUE: 2, TEXT: '30+'},
                                {VALUE: 3, TEXT: '60+'},
                                {VALUE: 4, TEXT: '100+'},
                                {VALUE: 5, TEXT: '150+'}
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        20
                    </div>
                </div>
            </div>
        );
    }

    renderFoodType = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.FOOD_T.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>15.&nbsp;&nbsp;</div>
                    <div>Предпочтения в питании</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui ">
                        <TextedRadioMultiple
                            setField={this.setField}
                            resetField={this.resetField}
                            field="FOOD_T"
                            data={[
                                {VALUE: 1, TEXT: 'Вегетарианец'},
                                {VALUE: 2, TEXT: 'Веган'},
                                {VALUE: 3, TEXT: 'Кошер'},
                                {VALUE: 4, TEXT: 'Халяль'},
                                {VALUE: 5, TEXT: 'Без глютена'},
                                {VALUE: 6, TEXT: 'Без сахара'},
                                {VALUE: 7, TEXT: 'Без лактозы'},
                                {VALUE: 8, TEXT: 'Нет предпочтений'},
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        +50
                    </div>
                </div>
            </div>
        );
    }


    renderMonths = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.MONTHS.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        // import Erect from 'erect'

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>13.&nbsp;&nbsp;</div>
                    <div>В какие месяцы вы уезжаете на <br/> отдых и отсутствуете в городе ?</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <TextedRadioMultiple
                            setField={this.setField}
                            resetField={this.resetField}
                            field="MONTHS"
                            data={[
                                {VALUE: 1, TEXT: 'Январь'},
                                {VALUE: 2, TEXT: 'Февраль'},
                                {VALUE: 3, TEXT: 'Март'},
                                {VALUE: 4, TEXT: 'Апрель'},
                                {VALUE: 5, TEXT: 'Май'},
                                {VALUE: 6, TEXT: 'Июнь'},
                                {VALUE: 7, TEXT: 'Июль'},
                                {VALUE: 8, TEXT: 'Август'},
                                {VALUE: 9, TEXT: 'Сентябрь'},
                                {VALUE: 10, TEXT: 'Октябрь'},
                                {VALUE: 11, TEXT: 'Ноябрь'},
                                {VALUE: 12, TEXT: 'Декабрь'},
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        +60
                    </div>
                </div>
            </div>
        );
    }

    renderDaysInYear = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.DAYS_IN_YEAR.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    14. Сколько дней в году вы отсутствуете в <br/> городе
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <TextedRadioMultiple
                            setField={this.setField}
                            resetField={this.resetField}
                            field="DAYS_IN_YEAR"
                            data={[
                                {VALUE: 1, TEXT: '10+'},
                                {VALUE: 2, TEXT: '30+'},
                                {VALUE: 3, TEXT: '60+'},
                                {VALUE: 4, TEXT: '100+'},
                                {VALUE: 5, TEXT: '150+'},
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        +50
                    </div>
                </div>
            </div>
        );
    }

    renderWeekends = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.WEEKENDS.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>16.&nbsp;&nbsp;</div>
                    <div>Куда бы вы пошли в свой выходной</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <TextedRadioMultiple
                            setField={this.setField}
                            resetField={this.resetField}
                            field="WEEKENDS"
                            data={[
                                {VALUE: 1, TEXT: 'Парк'},
                                {VALUE: 2, TEXT: 'Кино'},
                                {VALUE: 3, TEXT: 'Спортзал'},
                                {VALUE: 4, TEXT: 'Шопинг'},
                                {VALUE: 5, TEXT: 'Лежать на диване'},
                                {VALUE: 6, TEXT: 'Всегда выходной'},
                                {VALUE: 7, TEXT: 'Другое'},
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>
        );
    }

    renderFoodCountry = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.FOOD_С.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    Предпочтение в питании
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui equals">
                        <TextedRadioMultiple
                            setField={this.setField}
                            resetField={this.resetField}
                            field="FOOD_С"
                            data={[
                                {VALUE: 1, TEXT: 'русская'},
                                {VALUE: 2, TEXT: 'французская'},
                                {VALUE: 3, TEXT: 'японская'},
                                {VALUE: 4, TEXT: 'кавказская'},
                                {VALUE: 5, TEXT: 'другая'}
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        20
                    </div>
                </div>
            </div>
        );
    }

    renderRest = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.REST.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div>17.&nbsp;&nbsp;</div>
                    <div>Куда бы вы пошли в свой выходной</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui equals">
                        <TextedRadioMultiple
                            setField={this.setField}
                            resetField={this.resetField}
                            field="REST"
                            data={[
                                {VALUE: 1, TEXT: 'Парк'},
                                {VALUE: 2, TEXT: 'Спортзал'},
                                {VALUE: 3, TEXT: 'Кино'},
                                {VALUE: 4, TEXT: 'Шопинг'},
                                {VALUE: 5, TEXT: 'Лежать на диване'},
                                {VALUE: 6, TEXT: 'Другое'}
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        +20
                    </div>
                </div>
            </div>
        );
    }

    renderFavGroup = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.GROUP.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        let DATA = [];
        for (let i = 0; i < this.state.CATS.length; i++) {
            let CAT = this.state.CATS[i];
            DATA.push({VALUE: CAT.id, TEXT: CAT.title});
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    Любимый раздел на ECOMARKET.RU
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui equals">
                        <TextedRadioMultiple
                            setField={this.setField}
                            resetField={this.resetField}
                            field="GROUP"
                            data={DATA}
                        />
                    </div>
                    <div className={CLASSES}>
                        20
                    </div>
                </div>
            </div>
        );
    }

    setStarField = (field, value) => {
        let SERVICE = this.state.SERVICE;
        SERVICE[field] = value;
        this.setState({SERVICE: SERVICE});
    }

    renderService = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.checkService()) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    Оцените по 5-балльной шкале наш вервис:
                </div>
                <div className="anketa_label_small">
                    5 звёзд - отлично
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <StarsInput
                            field="0"
                            label="Цена"
                            setField={this.setStarField}
                            resetField={this.resetField}
                        />
                        <StarsInput
                            field="1"
                            label="Качество"
                            setField={this.setStarField}
                            resetField={this.resetField}
                        />
                        <StarsInput
                            field="2"
                            label="Ассортимент"
                            setField={this.setStarField}
                            resetField={this.resetField}
                        />
                        <StarsInput
                            field="3"
                            label="Стоимость доставки"
                            setField={this.setStarField}
                            resetField={this.resetField}
                        />
                        <StarsInput
                            field="4"
                            label="Сервис"
                            setField={this.setStarField}
                            resetField={this.resetField}
                        />
                        <StarsInput
                            field="5"
                            label="Курьеры"
                            setField={this.setStarField}
                            resetField={this.resetField}
                        />
                    </div>
                    <div className={CLASSES}>
                        20
                    </div>
                </div>
            </div>
        );
    }

    renderBigGroup = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.BIG_GROUP !== "") {
            CLASSES = 'anketa_bonuses validated';
        }

        let DATA = [];
        for (let i = 0; i < this.state.CATS.length; i++) {
            let CAT = this.state.CATS[i];
            DATA.push({VALUE: CAT.id, TEXT: CAT.title});
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    Какой раздел вы бы хотели расширить?
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <GroupSearchInput
                            setField={this.setField}
                            resetField={this.resetField}
                            field="BIG_GROUP"
                            data={DATA}
                        />
                    </div>
                    <div className={CLASSES}>
                        20
                    </div>
                </div>
            </div>
        );
    }

    renderSocials = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.SOCIALS.length >= 1) {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>18.&nbsp;&nbsp;</div>
                    <div>Какими соц сетями вы больше всего <br/> пользуетесь?</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui ">
                        <TextedRadioMultiple
                            setField={this.setField}
                            resetField={this.resetField}
                            field="SOCIALS"
                            data={[
                                {VALUE: 1, TEXT: 'Instagram'},
                                {VALUE: 2, TEXT: 'Youtube'},
                                {VALUE: 3, TEXT: 'VK'},
                                {VALUE: 4, TEXT: 'Tik-Tok'},
                                {VALUE: 5, TEXT: 'Яндекс Дзен'}
                            ]}
                        />
                    </div>
                    <div className={CLASSES}>
                        +30
                    </div>
                </div>
            </div>
        );
    }

    renderInstagram = () => {
        let CLASSES = 'anketa_bonuses';
        if (this.state.INSTAGRAM !== '') {
            CLASSES = 'anketa_bonuses validated';
        }

        return (
            <div className="anketa_block">
                <div className="anketa_label">
                    <div className='anketa_label_number'>19.&nbsp;&nbsp;</div>
                    <div>Ваш ник в Инстаграм</div>
                </div>
                <div className="anketa_fields">
                    <div className="anketa_ui">
                        <TextInput FIELD="INSTAGRAM" placeholder={'Ваш ник'} setField={this.setField}
                                   resetField={this.resetField}/>
                    </div>
                    <div className={CLASSES}>
                        +100
                    </div>
                </div>
            </div>
        );
    }

    renderSubmit = () => {
        return <button className="anketa-button"
                       onClick={
                           () => {
                               window.scrollTo(0, 0);
                               this.submit()
                           }
                       }>Отправить ответы</button>
    }

    submit = async () => {
        let TOKEN = "UNDEFINED";
        if (this.props.USER) {
            TOKEN = this.props.USER.TOKEN;
        }

        if (this.state.PHONE.length > 11) {
            if (!this.SENDING) {
                this.SENDING = true;
                let SEND_DATA = {
                    action: 'saveAnketaData',
                    phone: this.state.PHONE,
                    name: this.state.NAME,
                    email: this.state.EMAIL,
                    whatsapp: this.state.WHATSAPP,
                    product_1: this.state.PRODUCT_1,
                    product_2: this.state.PRODUCT_2,
                    product_3: this.state.PRODUCT_3,
                    region: this.state.REGION,
                    region_else: this.state.REGION_ELSE,
                    birthday: this.state.BIRTHDAY,
                    sex: this.state.SEX,
                    organic: this.state.ORGANIC,
                    point: this.state.POINT,
                    family_status: this.state.FAMILY_STATUS,
                    children: this.state.CHILDREN,
                    animals: this.state.ANIMALS,
                    animal_types: this.state.ANIMAL_TYPES,
                    where: this.state.WHERE,
                    how: this.state.HOW,
                    country_1: this.state.COUNTRY_1,
                    country_2: this.state.COUNTRY_2,
                    country_3: this.state.COUNTRY_3,
                    days: this.state.DAYS,
                    food_t: this.state.FOOD_T,
                    other_stores: this.state.OTHER_STORES,
                    other_stores_else: this.state.OTHER_STORES_ELSE,
                    popular_stores: this.state.POPULAR_STORES,
                    popular_stores_else: this.state.POPULAR_STORES_ELSE,
                    daysInYear: this.state.DAYS_IN_YEAR,
                    months: this.state.MONTHS,
                    food_c: this.state.FOOD_С,
                    group: this.state.GROUP,
                    weekends: this.state.WEEKENDS,
                    rest: this.state.REST,
                    service: this.state.SERVICE,
                    big_group: this.state.BIG_GROUP,
                    socials: this.state.SOCIALS,
                    instagram: this.state.INSTAGRAM,
                    earned: this.state.EARNED,
                    questions: this.state.questions,
                    apikey: TOKEN
                };


                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

                if (data !== false) {
                    if (typeof data.user !== "undefined") {
                        this.props.doAuth(data.user);
                    }
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'submit_questionnaire',
                    });
                    this.setState({SUCCESS: true});
                    this.SENDING = false;
                } else {
                    this.SENDING = false;
                }
            }
        }
    }

    renderAcceptPersonalData = () => {
        return <div style={{height: '25px', cursor: 'pointer'}} className="anketa_images_checkbox" onClick={()=>this.setState({acceptPersonalData:!this.state.acceptPersonalData})}>
            <img style={{height: '19px', alignSelf: 'flex-end', marginBottom: '2px', marginRight: '11px'}}
                 src={this.state.acceptPersonalData?"/system_images/checkboxFull.svg":"/system_images/checkbox.svg"}/>
            <span style={{fontSize: '14px', fontWeight: '900', color: '#000'}}>Согласен на обработку персональных данных</span>
        </div>
    }

    renderForm = () => {
        let STEP_0 = ' hidden';
        let STEP_1 = ' hidden';
        let STEP_2 = ' hidden';
        let STEP_3 = ' hidden';
        let STEP_4 = ' hidden';
        let STEP_5 = ' hidden';
        let STEP_6 = ' hidden';

        switch (this.state.STEP) {
            case 0:
                STEP_0 = ' visible';
                break;
            case 1:
                STEP_1 = ' visible';
                break;
            case 2:
                STEP_2 = ' visible';
                break;
            case 3:
                STEP_3 = ' visible';
                break;
            case 4:
                STEP_4 = ' visible';
                break;
            case 5:
                STEP_5 = ' visible';
                break;
            case 6:
                STEP_6 = ' visible';
                break;
        }

        if (WIDTH > 980 || true) {

            return (
                <div>
                    <div className="row">
                        <div style={{display: 'flex', flexDirection: 'column', width: '345px'}}>
                            {this.renderName()}

                            {this.renderPhone()}

                            {this.renderEmail()}

                            {this.renderBirthday()}

                            {this.renderSex()}

                            {this.renderRegion()}

                            {this.renderAnimals()}

                            {this.renderChildren()}

                            {this.renderPoint()}

                            {this.renderHow()}

                            {this.renderOtherStores()}

                            {this.renderThreeProducts()}

                            {this.renderMonths()}

                            {this.renderThreeCountries()}

                            {this.renderFoodType()}

                            {this.renderWeekends()}

                            {this.renderOrganic()}

                            {this.renderSocials()}

                            {this.renderInstagram()}

                            {this.renderPopularStores()}

                            {this.renderAcceptPersonalData()}

                            {this.renderSubmit()}
                        </div>
                    </div>
                </div>
            );


        } else {
            return (
                <div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '345px', padding: '0 15px'}}>
                        {this.renderName()}

                        {this.renderPhone()}

                        {this.renderEmail()}

                        {this.renderBirthday()}

                        {this.renderSex()}

                        {this.renderRegion()}

                        {this.renderAnimals()}

                        {this.renderChildren()}

                        {this.renderPoint()}

                        {this.renderHow()}

                        {this.renderOtherStores()}

                        {this.renderThreeProducts()}

                        {this.renderMonths()}

                        {this.renderThreeCountries()}

                        {this.renderFoodType()}

                        {this.renderWeekends()}

                        {this.renderOrganic()}

                        {this.renderSocials()}

                        {this.renderInstagram()}

                        {this.renderPopularStores()}

                        {this.renderSubmit()}
                    </div>
                </div>
            );
        }
    }

    renderFormholder = () => {
        return (
            <div className="anketa_main">
                {this.renderForm()}
            </div>
        );
    }

    changeStep = (STEP) => {
        this.setState({STEP: STEP});
        let COMPONENT = this;
        setTimeout(function () {
            COMPONENT.myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 20);
    }

    renderNavs = () => {
        let PREV = false;
        let NEXT = false;

        if (this.state.STEP > 0) {
            let MOVE_PREV = this.state.STEP - 1;
            PREV = (<a className="anketa_prev" onClick={() => this.changeStep(MOVE_PREV)}>{'<< Назад'}</a>)
        }

        if (this.state.STEP < this.state.MAX_STEPS - 1) {
            let MOVE_NEXT = this.state.STEP + 1;
            NEXT = (<a className="anketa_next" onClick={() => this.changeStep(MOVE_NEXT)}>{'Далее >>'}</a>)
        }

        return (
            <div className="anketa_navs">
                {PREV}
                {NEXT}
            </div>
        );
    }

    renderSteps = () => {
        let STEPS = [];
        for (let i = 0; i < this.state.MAX_STEPS; i++) {
            let ACTIVE_CLASS = 'anketa_step';
            if (i <= this.state.STEP) {
                ACTIVE_CLASS = 'anketa_step active';
            }

            STEPS.push(<span key={i} className={ACTIVE_CLASS}></span>)
        }

        return (
            <div className="anketa_steps">
                {STEPS}
            </div>
        );
    }

    renderFormContent = () => {
        if ((this.state.USER) && (parseInt(this.state.USER.polled) == 1)) {
            return (
                <div className="already_polled">
                    Вы уже заполняли анкету ранее!
                </div>
            );
        } else {
            if (this.state.SUCCESS) {
                return <SubmitBonuses/>
                /*return this.renderSuccessholder();*/
            } else {
                return this.renderFormholder();
            }
        }
    }

    renderSuccessholder() {
        window.postMessage('ANKETA_FILLED');
        return (
            <div className="step">
                <div className="row">
                    <div className="col-6">
                        <div className="anketa_done">
                            Подробнее о начислениях и использовании экобаллов вы можете узнать в своем <span
                            onClick={() => this.props.openLK(true)}>личном кабинете</span> в разделе “Экобаллы”
                        </div>
                        <div className="anketa_done_head">
                            Подписывайтесь на наш Instagram
                        </div>
                        <div className="anketa_done_2">
                            Следите за новостями, участвуйте в конкурсах и выигрывайте!
                        </div>
                        <img src="/system_images/insta_img.jpg" className="anketa_insta"/>
                        <a target="_blank" className="anketa-button_2" href="https://instagram.com/Ecomarket.ru">Перейти
                            в Instagram >></a>
                        <div className="anketa_done_head_2">
                            Скачивайте наше приложение
                        </div>
                        <div className="mobile_message_buttons left">
                            <a target="_blank"
                               href="https://play.google.com/store/apps/details?id=com.ecomarket_ooo"><img
                                src="/system_images/google_play.png"/></a>
                            <a target="_blank" href="itms-apps://itunes.apple.com/app/apple-store/id1486148531"><img
                                src="/system_images/app_store.png"/></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    renderContent = () => {
        return (
            <div>
                <div className="anketa_first">
                    <div className="anketa_first_head">
                        Заполните анкету и получите 1200 экобаллов!
                    </div>
                    <div className="anketa_first_statistics">
                <span style={{
                    fontSize: '18px',
                    fontWeight: '700',
                    color: '#818181'
                }}>{this.state.questions + ' из 20 '}</span>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '8px', fontSize: '12px', fontWeight: '700'}}>
                Набрано баллов
                </span>
                            <span style={{
                                width: '61px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#fff',
                                borderRadius: '4px'
                            }}>
            {this.state.EARNED}
                </span>
                        </div>
                    </div>
                </div>
                {this.renderFormContent()}
            </div>
        );
    }

    render() {
        if (!this.state.CATS) {
            return <InnerLoader/>
        }

        const {width} = getWindowDimensions()

        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            >
                <div className="right-part">
                    <Helmet>
                        <title style={{fontSize: '22px', fontWeight: '900'}}>Анкета-опросник</title>
                        <meta name="description" content="Анкета-опросник"/>
                        <meta name="keywords" content="Анкета-опросник"/>
                    </Helmet>

                    {this.state.floatingCounterVisible && <div className="anketa_first_statistics" style={{
                        marginLeft: width >= 980 ? '40px' : '0',
                        zIndex: '10',
                        marginTop: width >= 980 ? '-54px' : '0',
                        padding: width >= 980 ? '12px 0 10px' : '12px 15px 10px',
                        height: width >= 980 ? '62px' : '62px',
                        position: 'fixed'
                    }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: '700',
                    color: '#818181'
                }}>{this.state.questions + ' из 20 '}</span>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '8px', fontSize: '12px', fontWeight: '700'}}>
                Набрано баллов
                </span>
                            <span style={{
                                width: '61px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#fff',
                                borderRadius: '4px'
                            }}>
            {this.state.EARNED}
                </span>
                        </div>
                    </div>}

                    <div id="products" className="page-content" ref={this.myRef}>
                        <div className="anketa">
                            <h1 className="main-heading ">Анкета-опросник</h1>
                            {this.renderContent()}
                        </div>
                    </div>

                    <ModalsContainer/>
                </div>
                <FooterBlock/>
                <MobileMessage/>
            </CSSTransitionGroup>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Closer)
