import React, {Component, useState} from 'react';
import CONFIG from './../SYSTEM/Config.js'
import { sendPostRequest } from "./../APP/HELPERS/requests"
import Cookies from 'universal-cookie';

import { isActive } from "./../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'

import moment from "moment";

const cookies = new Cookies();

function mapStateToProps(state)
{
    return {
        CART: state.CART,
        REGION: state.REGION,
        GPS: state.GPS,
        IS_TESTER: state.IS_TESTER,
        ADRESS: state.ADRESS,
        USER: state.USER,
        SPEC_FILTER: state.SPEC_FILTER,
        SHOP: state.SHOP,
        MARGE_FILTER: state.MARGE_FILTER,
        WRITE_FILTER: state.WRITE_FILTER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DataFetch extends Component {

	constructor(props)
    {
        super(props);

        this.state = {
        	TOKEN: false,
            REGION: props.REGION,
            USER: false,
            PERIODS: false,
            FETCHING: false
        }

        this.localStorageUpdated = this.localStorageUpdated.bind(this);
        this.MAP = false;
        this.refMap = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REGION) {
            if (this.props.REGION !== prevProps.REGION) {
                this.noChangeRegion = true;
                this.setState({REGION: this.props.REGION, PERIODS: false});
                this.getAuthToken();
            }
        }

        if (this.props.USER) {
            if (this.props.USER !== prevProps.USER) {
                this.appStarter(this.state.TOKEN)
            }
        }
      
    }

    componentDidMount = async () =>
    {
        this.getRegion();
        this.checkDevice();
        this.getAuth();

        let eco = cookies.get('ecoedit');
        if (eco !== null) {
            this.getAuthByCookie(eco);
        }

        
        this.localStorageUpdated()
        window.addEventListener('storage', this.localStorageUpdated);
    }

    componentWillUnmount()
    {
        if (typeof this.listener !== "undefined") {
            clearInterval(this.listener);
        }
        if (typeof this.periodListener !== "undefined") {
            clearInterval(this.periodListener);
        }
        if (typeof this.listenerDistance !== "undefined") {
            clearInterval(this.listenerDistance);
        }
        if (typeof this.gpsChangeTimeGet !== "undefined") {
            clearInterval(this.gpsChangeTimeGet);
        }
        
        window.removeEventListener('storage', this.localStorageUpdated)
    }

    localStorageUpdated()
    {
        let COMPONENT = this;
        setTimeout( function(){
            let CART = localStorage.getItem('CART');

            if (CART !== COMPONENT.props.CART) {
                if (CART !== null) {
                    let PARSED_CART = JSON.parse(CART);
                    if (PARSED_CART !== COMPONENT.props.CART) {
                        COMPONENT.props.setCart(PARSED_CART);
                    }
                } else {
                    COMPONENT.props.setCart({});
                }
            }

            let SPEC_FILTER = localStorage.getItem('SPEC_FILTER');

            if (SPEC_FILTER !== COMPONENT.props.SPEC_FILTER) {
                if (SPEC_FILTER !== null) {
                    let PARSED_SPEC_FILTER = JSON.parse(SPEC_FILTER);
                    if (PARSED_SPEC_FILTER !== COMPONENT.props.SPEC_FILTER) {
                        COMPONENT.props.setSpecFilter(PARSED_SPEC_FILTER);
                    }
                }
            }
        }, 500
        );
    }

    getRegion = () =>
    {
        let region = this.getSubdomain();
        this.props.changeRegion(region);
        return region;
    }

    getSubdomain = () =>
    {
        let cookie_region = this.checkRegionCookie();
        if (cookie_region) {
            return cookie_region;
        }

        let parts = window.location.hostname.split('.');
        if (typeof parts[1] !== "undefined") {
            switch (parts[0]) {
                case 'spb':
                    return 78;
                case 'kaz':
                    return 16;
                case 'nnov':
                    return 52;
                case 'rnd':
                    return 61;
            }
        }

        this.props.showLocationAlert(true);

        return 77;
    }

    checkRegionCookie = () =>
    {
        let region = cookies.get('eco_region');

        if (region !== null) {
            return region;
        }

        return false;
    }

    checkDevice()
    {
        let IOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (!IOS) {
            /*
            let root = document.documentElement;
            root.classList.add("hide-overflow");
            document.body.classList.add("hide-overflow");
            */
        }
    }

    getAuth = async () =>
    {
        let USER = await localStorage.getItem('USER');
        if (USER !== null) {
            this.props.doAuth(JSON.parse(USER));
            this.setState({USER: USER});

            return true;
        } else {
            return false;
        }
    }

    getAuthByCookie = async(eco) =>
    {
        try {
            let SEND_DATA = {
                action:     'getAuthByCookie',
                eco:        eco,
                token:      this.state.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {

                cookies.set('ecoedit', data.user.eco, { path: '/', time: data.user.time });
                this.props.doAuth(data.user);
                this.setState({USER: data.user});
            }
            return true;
        } catch (error) {
        }
    }

    get(key){
       var p = window.location.search;
        p = p.match(new RegExp(key + '=([^&=]+)'));
        return p ? p[1] : false;
    }

    getAuthToken = async(result) =>
    {
    	let SEND_DATA = {
            action:   'getAuthToken',
        };

        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
        if (data !== false) {
            if (!this.state.TOKEN) {
                this.props.setToken(data.token);
                this.setState({TOKEN: data.token});
            }
            this.appStarter(data.token);
            
        }
    }

    appStarter = async (TOKEN) =>
    {
        if (this.props.USER) {
            let SLIDES = await this.getSlides(TOKEN);
            let DATA = await this.fetchAppData(TOKEN);

            if (typeof DATA !== "undefined") {

                this.props.setStartUpData({
                    TOKEN: TOKEN,
                    CATS: DATA.groups,
                    PRODUCTS: DATA.products,
                    SLIDES: SLIDES,
                    GROUPED_PRODUCTS: DATA.grouped_products,
                    POPULAR: DATA.popular_products,
                    TAGS: DATA.tags,
                    MANAGERS: DATA.managers2,
                    SUPPLIERS: DATA.suppliers,
                    HIDDEN: DATA.hidden
                });

                this.recordSmallMarge(DATA.products)

                let COMPONENT = this;
                if (typeof this.timeoutToLaunch == "undefined") {
                    this.timeoutToLaunch = setTimeout( function(){
                            COMPONENT.launchPeriodic(TOKEN);
                        }
                        ,50000
                    );
                }
            }
        }
    }

    fetchAppData = async (TOKEN) =>
    {
        if (!this.FETCHING) {
            this.FETCHING = true;
            try {
                let SEND_DATA = {
                    action:    'admin_appStartUp',
                    REGION:     this.state.REGION,
                    TEST_CASE:  this.props.IS_TESTER ? 'B' : 'A',
                    apikey:     this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

                this.FETCHING = false;

                if (data !== false) {
                    this.setState({PERIODS: data.periods});
                    return data;
                } else {
                    this.props.logOut();
                }
            } catch (error) {
                this.FETCHING = false;
                console.log('BAD PRODUCTS TIMER');
            }
        }
    }

    launchPeriodic = async (TOKEN) =>
    {
        let SLIDES = await this.getSlides(TOKEN);
        let DATA = await this.fetchAppData(TOKEN);

        if (typeof DATA !== "undefined") {
            this.props.setStartUpData({
                TOKEN: TOKEN,
                CATS: DATA.groups,
                PRODUCTS: DATA.products,
                SLIDES: SLIDES,
                GROUPED_PRODUCTS: DATA.grouped_products,
                POPULAR: DATA.popular_products,
                TAGS: DATA.tags,
                MANAGERS: DATA.managers2,
                SUPPLIERS: DATA.suppliers,
                HIDDEN: DATA.hidden
            });

            this.recordSmallMarge(DATA.products)
        
            let COMPONENT = this;
            if (typeof this.timeoutToLaunch == "undefined") {
                this.timeoutToLaunch = setTimeout( function(){
                        COMPONENT.launchPeriodic(TOKEN);
                    }
                    ,50000
                );
            }
        }
    }

    recordSmallMarge = (PRODUCTS) =>
    {
        for (let i in PRODUCTS) {
            let PRODUCT = PRODUCTS[i]

            if (isActive(PRODUCT , this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER, this.props.WRITE_FILTER)) {
                if (PRODUCT.marge < 0) {
                    ///console.log(PRODUCT.id + ' | ' + PRODUCT.title + ' - ' + PRODUCT.marge)
                }
            }
        }
    }

    getSlides = async(TOKEN) =>
    {
        try {
            let SEND_DATA = {
                action:     'getSlider_v2',
                region:     this.state.REGION,
                token:      TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                return data;
            } else {
                return false;
            }
        } catch (error) {
            console.warn('PROBLEM WHILE GETTING SLIDES');
        }
    }

	render() {
		return (
            <div style={{'display':'none'}}>
            </div>
        );
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataFetch)
