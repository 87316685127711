import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"
import CourrierBlock from "./CourrierBlock.js"
import styles from "./OrderCard.module.css"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import OrderCardProduct from './../../../APP/COMPONENTS/ContentParts/OrderCardProduct';
import OrderCardProductClean from './../../../APP/COMPONENTS/ContentParts/OrderCardProductClean';
import SText from "../../HELPERS/SText";
import cn from "classnames";

function mapStateToProps(state) {
    return {
        USER: state.USER,
        PRODUCTS: state.PRODUCTS,
        CARD_MODAL: state.CARD_MODAL,
        PAY_MODAL: state.PAY_MODAL,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class OrderCard extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            ORDER: props.order,
            COURRIER: false,
            FETCH_DATA: props.fetchData
        };
    }

    componentDidMount = () => {
        if ((typeof this.state.FETCH_DATA !== "undefined") && (this.state.FETCH_DATA)) {
            this.getOrderData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.PAY_MODAL !== prevProps.PAY_MODAL) {
            ///this.getOrderData();
        }
    }


    getOrderData = async () => {
        try {
            let SEND_DATA = {
                action: 'getOrderData',
                id: this.state.ORDER.ID,
                apikey: this.props.USER.TOKEN,

            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            console.log(data)
            if (data !== false) {
                this.setState({ORDER_DATA: data.order, COURRIER: data.order.COURRIER});
            }
        } catch (error) {
            return false;
        }
    }

    toggleOrderData() {
        if (window.innerWidth < 1200) {
            if (typeof this.props.changeView !== "undefined") {
                this.props.changeView('ORDER', this.state.ORDER);
            }
        } else {
            if (!this.state.ORDER_DATA) {
                this.getOrderData();
            } else {
                this.setState({ORDER_DATA: false});
            }
        }
    }


    getPaidTitle(STATUS) {
        switch (parseInt(STATUS)) {
            case 0:
                return 'Не оплачен';
                break;
            case 1:
                return 'Оплачен';
                break;
            case 2:
                return 'Средства зарезервированы';
                break;
            case 3:
                return 'Карта подтверждена';
                break;
            case 4:
                return 'Средства зарезервированы';
                break;
        }
    }


    getStatusTitle(status) {
        let status_title = '';

        switch (status) {
            case 'created':
                status_title = 'Принят';
                break;
            case 'handed_over_for_picking':
                status_title = 'Собран';
                break;
            case 'on_the_way':
                status_title = 'В пути';
                break;
            case 'delivered':
                status_title = 'Доставлен';
                break
            default:
                status_title = 'Отменён';
                break;
        }

        return status_title;
    }

    renderPayBtn() {
        let ORDER = this.state.ORDER_DATA;

        if ((ORDER.PAID == 0) && (ORDER.PAYMENT == 3) && (ORDER.STATUS !== 4)) {
            return <a className="orange-button" onClick={() => this.props.doPay(this.state.ORDER.ID)}>Оплатить</a>
        }
    }

    renderBonus() {
        if (this.state.ORDER_DATA.BONUS_SUM < 1) {
            return false;
        } else {
            return (
                <div className="or_data_row">
                    <div className="or_data_row_left">
                        Вы использовали экобаллы:
                    </div>
                    <div className="or_data_row_right">
                        - {this.state.ORDER_DATA.BONUS_SUM} р
                    </div>
                </div>
            );
        }
    }

    renderCoupon() {
        if (this.state.ORDER_DATA.COUP_SUM < 1) {
            return false;
        } else {
            return (
                <div className="or_data_row">
                    <div className="or_data_row_left">
                        Вы применили промокод:
                    </div>
                    <div className="or_data_row_right">
                        - {this.state.ORDER_DATA.COUP_SUM} р
                    </div>
                </div>
            );
        }
    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    prepareToCount(NUMB) {
        let RETURN = 0;

        if (NUMB > 0) {
            if (NUMB % 1 !== 0) {
                RETURN = parseFloat(this.rmFixed(Number(NUMB)));
            } else {
                RETURN = parseInt(Number(NUMB).toFixed());
            }
        }

        return RETURN;
    }


    renderProductsSum() {
        let SUM = this.prepareToCount(this.state.ORDER_DATA.SUM);
        let COUP_SUM = this.prepareToCount(this.state.ORDER_DATA.COUP_SUM);
        let BONUS_SUM = this.prepareToCount(this.state.ORDER_DATA.BONUS_SUM);
        let DEL_SUM = this.prepareToCount(this.state.ORDER_DATA.DEL_SUM);
        let SHOW_SUM = SUM + COUP_SUM + BONUS_SUM;

        return (
            <div className="or_data_row">
                <div className="or_data_row_left">
                    Стоимость товаров:
                </div>
                <div className="or_data_row_right">
                    {Math.round(SHOW_SUM)} р
                </div>
            </div>
        );
    }

    renderTotalSum() {
        let SUM = this.prepareToCount(this.state.ORDER_DATA.SUM);
        let COUP_SUM = this.prepareToCount(this.state.ORDER_DATA.COUP_SUM);
        let BONUS_SUM = this.prepareToCount(this.state.ORDER_DATA.BONUS_SUM);
        let DEL_SUM = this.prepareToCount(this.state.ORDER_DATA.DEL_SUM);
        let SHOW_SUM = SUM + DEL_SUM;

        return (
            <div className="or_data_row">
                <div className="or_data_row_left_bold">
                    Итог:
                </div>
                <div className="or_data_row_right_bold">
                    {SHOW_SUM} р
                </div>
            </div>
        );
    }

    renderDeliverySum() {
        let SHOW = 'бесплатно';

        if (this.state.ORDER_DATA.DEL_SUM > 0) {
            SHOW = this.state.ORDER_DATA.DEL_SUM + ' р';
        }

        return (
            <div className="or_data_row">
                <div className="or_data_row_left">
                    Сумма доставки:
                </div>
                <div className="or_data_row_right">
                    {SHOW}
                </div>
            </div>
        );
    }

    renderPaidStatus() {
        if (this.state.ORDER_DATA.PAID < 1) {
            let ORDER = this.state.ORDER_DATA;

            if ((ORDER.PAID == 0) && (parseInt(ORDER.STATUS) !== 4)) {
                return (
                    <div className="or_data_row">
                        <div></div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <span className="unpaid_this_order">Заказ неоплачен</span>
                            {window.innerWidth > 700 ?
                                <div className={styles.supportNumber}>Если у вас есть вопросы по оплате, то обратитесь в
                                    нашу службу поддержки <a
                                        href="tel:+74951599009">8 (495) 159-90-09</a> или info@ecomarket.ru
                                </div> : false}
                            <div className="fff_buttons">
                                {this.renderRepeat2()}
                                <a className="or_data_row_right bad_payment do_pay_button" onClick={() => {
                                    this.props.closeAllModals();
                                    this.props.doPay(this.state.ORDER.ID)
                                }}>
                                    Оплатить
                                </a>
                            </div>
                        </div>
                    </div>
                );
            }

        } else if (this.state.ORDER_DATA.PAID == 1) {
            return (
                <div className="or_data_row">
                    <div></div>
                    <div>
                        <div className="flexed_paid">
                            <img className="paid_mobile_image" src="/system_images/green_check_paid.png"/><span
                            className="paid_this_order">Оплачен</span>
                        </div>
                        {this.renderRepeat2()}
                    </div>
                </div>
            );
        }

        return (
            <div className="or_data_row">
                <div></div>
                <div>
                    {this.renderRepeat2()}
                </div>
            </div>
        );
    }

    dublicateOrder = () => {
        let NEW_CART = Object.assign({});

        for (let i in this.state.ORDER_DATA.PRODUCTS) {
            let PRODUCT = this.state.ORDER_DATA.PRODUCTS[i];
            if (typeof this.props.PRODUCTS[i] !== "undefined") {
                NEW_CART[PRODUCT.ID] = {};
                NEW_CART[PRODUCT.ID].amount = Math.floor(PRODUCT.AMOUNT / this.props.PRODUCTS[i].peramount);
                NEW_CART[PRODUCT.ID].time = Math.floor(Date.now() / 1000);
            }
        }

        this.props.setCart(NEW_CART);

        this.props.openLK(false);
        this.props.openCart(true);
    }

    renderChips() {

    }

    renderFinals() {
        return (
            <div className="or_finals">
                {this.renderChips()}
                {this.renderProductsSum()}
                {this.renderDeliverySum()}
                {this.renderCoupon()}
                {this.renderBonus()}
                {this.renderTotalSum()}
                {this.renderPaidStatus()}
            </div>
        );
    }

    renderProducts() {
        if (this.state.ORDER_DATA) {
            let ORDER = this.state.ORDER_DATA;

            let RENDER_PRODUCTS = [];
            let COMPONENT = this;

            for (let i in ORDER.PRODUCTS) {
                let PRODUCT = ORDER.PRODUCTS[i];
                if (typeof this.props.PRODUCTS[i] !== "undefined") {
                    let USE_PRODUCT = this.props.PRODUCTS[i];

                    if (typeof USE_PRODUCT !== "undefined") {
                        USE_PRODUCT.SUM = PRODUCT.SUM;
                        USE_PRODUCT.AMOUNT = PRODUCT.AMOUNT;
                        USE_PRODUCT.START_AMOUNT = PRODUCT.START_AMOUNT;
                        let READY_PRODUCT = (
                            <OrderCardProduct PRODUCT={USE_PRODUCT} key={'order_product_card_' + i}/>
                        );

                        RENDER_PRODUCTS.push(READY_PRODUCT);
                    }
                } else {
                    let READY_PRODUCT = (
                        <OrderCardProductClean key={'order_product_card_' + i} PRODUCT={PRODUCT}/>
                    );

                    RENDER_PRODUCTS.push(READY_PRODUCT);
                }
            }

            if (RENDER_PRODUCTS) {
                return (
                    <div className="hidden_products">
                        <span className="order_products_modal_head">Список товаров</span>

                        {RENDER_PRODUCTS}
                        {this.renderFinals()}
                    </div>
                );
            }
        }
    }

    renderCourrierButton2 = () => {
        let imgCourier = this.state.ORDER_DATA?.COURRIER?.image_path ? (CONFIG.API_C_DOMAIN + this.state.ORDER_DATA?.COURRIER?.image_path) : "/system_images/courierZaglushka.png"
        return <div className="contact_courrier">
            {this.renderStatusBlock({order: {status: this.state.ORDER.newStatus}})}
            {(this.state.ORDER.newStatus === 'on_the_way' && this.state.ORDER_DATA?.COURRIER?.name && this.state.ORDER_DATA?.COURRIER?.phone) ?
                <div className={styles.courierCard}>
                    <div className={styles.courier}>
                        <div className={styles.image}><img src={imgCourier}/></div>
                        <div>{this.state.ORDER_DATA?.COURRIER?.name}</div>
                    </div>
                    <div className={styles.courierBtns}>
                        <a className={styles.contact} href={"tel: " + this.state.ORDER_DATA?.COURRIER?.phone}>
                            Связаться
                        </a>
                        <div style={{opacity: this.state.TRACKING ? 0.5 : 1}} className={styles.courierOnMap}
                             onClick={() => this.toggleCMap()}>
                            Курьер на карте
                        </div>
                    </div>
                </div> : null}
        </div>

    }

    getCButtonClass = () => {
        if (this.state.TRACKING) {
            return ' activated';
        }
        return '';
    }

    toggleCMap = () => {
        let SET = false;
        if (!this.state.TRACKING) {
            SET = true;
        }
        this.setState({TRACKING: SET});
    }

    renderCourrierButton = () => {
        if (this.state.ORDER_DATA.newStatus === 'on_the_way') {
            if (this.state.TRACKING) {
                let SRC = CONFIG.API_DOMAIN + 'helpers/app_map.php?apikey=' + this.props.USER.TOKEN + '&order=' + this.state.ORDER.ID;
                if (WIDTH <= 760) {
                    return (
                        <iframe src={SRC} style={{height: '500px !important'}} width="100%" height="500px"
                                className="tracking_frame"></iframe>
                    );
                } else {
                    return (
                        <iframe src={SRC} style={{height: '312px !important'}} width="100%" height="312px"
                                className="tracking_frame"></iframe>
                    );
                }
            }
        }
    }

    renderArrow() {
        if (!this.state.ORDER_DATA) {
            return (
                <span className="load_prods">
                    <img src="/system_images/green_angle.png"/>
                </span>
            );
        } else {
            return (
                <span className="load_prods rotated">
                    <img src="/system_images/green_angle.png"/>
                </span>
            );
        }
    }

    getRowClass() {
        if (this.state.ORDER_DATA) {
            return 'order-row opened';
        } else {
            return 'order-row';
        }
    }

    renderStatusClass(status) {
        if (status < 4) {
            return "order-status active";
        } else {
            return "order-status";
        }
    }

    renderStatusMobileClass(status) {
        if (status < 4) {
            return "order-mobile-status active";
        } else {
            return "order-mobile-status";
        }
    }

    renderStatusCircle(status) {
        if (status < 4) {
            return <div className="status-circle"></div>;
        }
    }

    renderDeliveryTime(ORDER) {
        if (WIDTH <= 760) {
            if (ORDER.STATUS < 4) {
                return (
                    <div className="order-time">Дата доставки: {this.state.ORDER.PRETTY_DATE}<br/>Время
                        доставки: {this.state.ORDER.TIME}</div>
                );
            }
        }
    }

    renderUnpaidOrder = (ORDER) => {
        if ((parseInt(ORDER.STATUS) !== 4) && (parseInt(ORDER.PAID) == 0)) {
            return <img className="unpaid_image" src="/system_images/unpaid.png"/>;
        }
    }

    renderRightText = () => {
        if (this.state.ORDER_DATA) {
            return (
                <div className="mobile_order_card_first_more">скрыть</div>
            );
        } else {
            return (
                <div className="mobile_order_card_first_more">подробнее >></div>
            );
        }
    }

    renderUnpaidMobileOrder = () => {
        if ((parseInt(this.state.ORDER.STATUS) !== 4) && (parseInt(this.state.ORDER.PAID) == 0)) {
            return <div className="order_mobile_unpaid"><img className="unpaid_mobile_image"
                                                             src="/system_images/unpaid.png"/><span>Не оплачен</span>
            </div>;
        }

        return <div className="order_mobile_unpaid paid"></div>
    }
    renderCourrierBlock = () => {
        if ((this.state.ORDER_DATA) && (this.state.COURRIER)) {
            return (
                <CourrierBlock COURRIER={this.state.COURRIER} ID={this.state.ORDER.ID}/>
            )
        }
    }

    getOurPhone = () => {
        let PHONE = '+7-495-445-05-05';
        if (parseInt(this.props.REGION) == 78) {
            PHONE = '+7-812-566-10-06';
        }

        return PHONE;
    }

    renderCall = () => {
        if (this.state.ORDER_DATA) {
            return (
                <a className="phoneImg_and_text" href={"tel: " + this.getOurPhone()}>
                    <img className="help_phone_img" src="/system_images/help_phone.png"/>
                    <div className="call_us_text">Связаться <br/>с нами</div>
                </a>
            );
        }
    }

    renderTimeAndAddress = () => {
        if (window.innerWidth < 1200) {
            return (
                <div className="address_time_phoneimg">
                    <div className="mobile_order_card_second">
                        <div>Время доставки:</div>
                        <div>{this.state.ORDER.PRETTY_DATE}, {this.state.ORDER.TIME}</div>
                        <div style={{marginTop: '10px'}}>Адрес доставки:</div>
                        <div>{this.state.ORDER.ADRESS}</div>
                        {window.innerWidth < 700 ? this.renderUnpaidMobileOrder() : false}
                    </div>
                    {/*{this.renderCall()}*/}

                </div>
            )
        } else {
            return (
                <div className="mobile_order_card_second">
                    <div style={{marginBottom: 10}}>{this.state.ORDER.PRETTY_DATE}, {this.state.ORDER.TIME}</div>
                    <div>{this.state.ORDER.ADRESS}</div>
                </div>
            )
        }
    }

    renderRepeat = () => {
        if (WIDTH <= 760) {
            if (this.state.ORDER_DATA) {
                return (
                    <div className="text-center">
                        <a className="order_additions" onClick={() => this.dublicateOrder()}><img
                            src="/system_images/white_repeat.png"/>Повторить заказ</a>
                    </div>
                )
            }
        }
    }

    renderRepeat2 = () => {
        if (WIDTH > 760) {
            if (this.state.ORDER_DATA) {
                return (
                    <div className="text-center">
                        <a className="order_additions" onClick={() => this.dublicateOrder()}><img
                            src="/system_images/white_repeat.png"/>Повторить заказ</a>
                    </div>
                )
            }
        }
    }

    renderTrackingBlock = () => {
        if (this.state.ORDER_DATA) {
            let SHOW = true;
            if ((parseInt(this.state.ORDER_DATA.STATUS) !== 3) && (WIDTH < 1200)) {
                SHOW = false;
            }

            if (/*SHOW &&*/ this.state.ORDER.newStatus !== 'cancelled') {
                return (
                    <div className="courrier_tracking_block_holder">
                        <div className="courrier_tracking_block">
                            {this.renderCourrierButton2()}
                            {this.renderCourrierButton()}
                        </div>
                    </div>
                );
            }
        }
    }

    addCardHeadClass = () => {
        if (this.state.ORDER_DATA) {
            return ' card_opened';
        }
        return '';
    }

    renderCommentAndSupportBlock = () => {
        if (this.state.ORDER_DATA) {
            return <div>
                {this.state.ORDER.comment ? <div style={{
                    marginTop: 10,
                    color: '#A0A0A0',
                    maxWidth: '65%',
                    wordWrap: 'break-word'
                }}>{'Комментарий: ' + this.state.ORDER.comment}</div> : null}
                <div className={styles.supportBtnWrapper}>
                    <a className={styles.supportBtn} href={"tel: " + this.getOurPhone()}>
                        <div><img style={{width: 24, height: 24, marginRight: 8}} src="/system_images/support.png"
                                  alt=""/></div>
                        <div>Служба поддержки</div>
                    </a>
                </div>
            </div>
        }
    }

    renderStatusBlock = ({order}) => {

        function getOrderStatus() {
            switch (order?.status) {
                case 'created':
                    return 0
                case 'handed_over_for_picking':
                    return 1
                case 'on_the_way':
                    return 2
                case 'delivered':
                    return 3
                default:
                    return -1
            }
        }

        const currentStatus = getOrderStatus()

        if (currentStatus == -1) return null

        return <div className={styles.orderStatus}>
            <div className={styles.statusWrapper}>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                    <div className={cn(styles.statusCircle, styles.statusActive)}><img
                        src={'/system_images/StatusBlock/accepted.svg'}/></div>
                    <SText fontSize={12} fontWeight={550} color={'#43B02A'}>{'принят'}</SText>
                </div>
                <div style={{
                    margin: '0 4px',
                    width: '33px',
                    height: '3px',
                    background: currentStatus >= 1 ? '#43B02A' : '#EDEDED'
                }}/>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                    <div className={cn(styles.statusCircle, currentStatus >= 1 && styles.statusActive)}><img
                        src={currentStatus >= 1 ? '/system_images/StatusBlock/packageWhite.svg' : '/system_images/StatusBlock/packageBlack.svg'}/>
                    </div>
                    <SText fontSize={12} fontWeight={550}
                           color={currentStatus >= 1 ? '#43B02A' : '#EDEDED'}>{'собран'}</SText>
                </div>
                <div style={{
                    margin: '0 -1px 0 4px',
                    width: '33px',
                    height: '3px',
                    background: currentStatus >= 2 ? '#43B02A' : '#EDEDED'
                }}/>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '15px'
                }}>
                    <div style={{marginBottom: '0'}}
                         className={cn(styles.statusCircle, currentStatus >= 2 && styles.statusActive)}><img
                        src={currentStatus >= 2 ? '/system_images/StatusBlock/rocketWhite.svg' : '/system_images/StatusBlock/rocketBlack.svg'}/>
                    </div>
                    <div style={{textAlign: 'center', width: '73px', height: '25px'}}>
                        <SText style={{lineHeight: '0,6'}}
                               fontSize={12} fontWeight={550}
                               color={currentStatus >= 2 ? '#43B02A' : '#EDEDED'}>
                            {'в пути'}
                        </SText>
                    </div>
                </div>
                <div style={{
                    margin: '0 4px 0 0',
                    width: '33px',
                    height: '3px',
                    background: currentStatus >= 3 ? '#43B02A' : '#EDEDED'
                }}/>
                <div style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div className={cn(styles.statusCircle, currentStatus === 3 && styles.statusActive)}><img
                        src={currentStatus >= 3 ? '/system_images/StatusBlock/thumbsUpWhite.svg' : '/system_images/StatusBlock/thumbsUpBlack.svg'}/>
                    </div>
                    <SText fontSize={12} fontWeight={550}
                           color={currentStatus >= 3 ? '#43B02A' : '#EDEDED'}>{'доставлен'}</SText>
                </div>
            </div>
        </div>
    }

    render() {
        return (
            <div>
                <div className={"mobile_order_card" + this.addCardHeadClass()}>
                    <div className="mobile_order_card_head" onClick={() => this.toggleOrderData()}>
                        <div className="mobile_order_card_first">
                            <div className="mobile_order_card_first_id">Заказ {this.state.ORDER.NUMBER}</div>
                            {(this.state.ORDER_DATA && window.innerWidth <= 700)?null:<div
                                className={this.renderStatusMobileClass(this.state.ORDER.STATUS)}>{this.getStatusTitle(this.state.ORDER.newStatus)}</div>}
                            {window.innerWidth > 700 ? this.renderUnpaidMobileOrder() : false}
                            <div className="total_sum_in_order_cart">{Math.round(this.state.ORDER.SUM)} р</div>
                        </div>
                        {this.renderTimeAndAddress()}
                        {this.state.ORDER_DATA ? this.renderCommentAndSupportBlock() : null}
                    </div>
                    {this.renderCourrierBlock()}
                    {this.renderTrackingBlock()}
                    {this.renderProducts()}
                </div>
                {this.renderRepeat()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderCard);