import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class MobileMessage extends Component {

	constructor(props)
    {
        super(props);
    }

    goToGoogle()
    {

    }

	render() {
        return false;
        
		return (
            <div className="mobile_message_back">
            	<div className="mobile_message_block">
            		<div className="mobile_message_left_block">
            			<div className="mobile_message_intro">
            				НАШЕ ПРИЛОЖЕНИЕ РАБОТАЕТ!
        				</div>
        				<div className="mobile_message_hand">
            				<img src="/system_images/mobile_hand.png" />
        				</div>
        				<div className="mobile_message_outro">
            				Скачивайте приложение Ecomarket
        				</div>
        				<div className="mobile_message_buttons">
        					<a onClick={() => window.yaCounter49140634.reachGoal('google_play')} href="https://play.google.com/store/apps/details?id=com.ecomarket_ooo"><img src="/system_images/google_play.png" /></a>
        					<a onClick={() => window.yaCounter49140634.reachGoal('apple_store')} href="itms-apps://itunes.apple.com/app/apple-store/id1486148531"><img src="/system_images/app_store.png" /></a>
        				</div>
        				<div className="mobile_message_text">
            				К сожалению, наш сайт временно не работает из-за большой перегрузки. Но вы можете оформить заказ через приложение. Приносим извинения за неудобства, наши программисты работают над решением этого вопроса.
        				</div>
            		</div>
            		<div className="mobile_message_right_block">
            			<img src="/system_images/app_qr.png" />
            		</div>
            	</div>
            </div>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(MobileMessage)
