import React, {Component} from 'react';

import ProductModal from './../../APP/MODALS/Product.js';
import Cart from './../../APP/MODALS/Cart.js';
import Lk from './../../APP/MODALS/Lk.js';
import Card from './../../APP/MODALS/Card.js';
import Pay from './../../APP/MODALS/Pay.js';
import Order from './../../APP/MODALS/Order.js';
import EmptyCart from './../../APP/MODALS/EmptyCart.js';
import NotFound from './../../APP/MODALS/NotFound.js';
import DeliveryAddress from './MapModal.js';
import DeliveryPayment from "./DeliveryInformation";
import DeliveryMixedModal from "./DeliveryMixedModal";
import AddressSelectModal from "./AddressSelectModal";
import RegionSelectorModal from "./RegionSelectorModal";
import CitiesSelectorModal from "./CitiesSelectorModal";
import SeasonsModal from "./SeasonsModal";
import ParsingModal from "./ParsingModal";

export default class ModalsContainer extends Component {
	render()
    {
        return (
            <div>
                <ProductModal />
                <Cart />
                <Lk />
                <Pay />
                <Card />
                <Order />
                <NotFound />
                <EmptyCart />
                <DeliveryAddress />
                <DeliveryPayment />
                <DeliveryMixedModal />
                <AddressSelectModal />
                <RegionSelectorModal />
                <CitiesSelectorModal />
                <SeasonsModal />
                <ParsingModal />
            </div>
        );
    }
}