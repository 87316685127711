import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"
import {checkRegionEntry, checkIfLocationIsInRussia} from '../../HELPERS/CoordinatesHelders/DefinitionOfTheRegion.js'

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ProductRowRec from "./../../../APP/COMPONENTS/ContentParts/ProductRowRec"
import MapDesktop from "./../../../APP/MODALS/YandexMaps/MapDesktop.js";

import AddressBlock from './../../../APP/MODALS/DeliveryInformation/AddressBlock.js';
import CostWithImage from "./../../../APP/MODALS/DeliveryInformation/CostWithImage.js";
import CostGrid from "./../../../APP/MODALS/DeliveryInformation/CostGrid.js"
import DeliveryRules from "./../../../APP/MODALS/DeliveryInformation/DeliveryRules.js"

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        REC: true,
        USEABLE_PERIODS: state.USEABLE_PERIODS,
        ADRESS: state.ADRESS,
        SHOWADRESS: state.SHOWADRESS,
        GPS: state.GPS,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DeliveryMixedModalContent extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            ADRESS: '',
            VIEW: props.VIEW,
            TEMP_ADRESS: '',
            SUGGESTIONS: [],
            SHOW_SUGGESTIONS: false,
            ERROR: false,
            SUCCESS: false,
            KM: 0
        };

        this.setChild = this.setChild.bind(this);

        this.setSuggestionRef = this.setSuggestionRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setSuggestionRef(node) {
        this.suggestion = node;
    }

    handleClickOutside(event) {
        if (this.suggestion && !this.suggestion.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
    }

    setSuggestions = (data) => {
        if (data.length) {
            this.setState({SUGGESTIONS: data, SHOW_SUGGESTIONS: false});
        } else {
            this.setState({SUGGESTIONS: [], SHOW_SUGGESTIONS: false});
        }
    }

    setChild(node) {
        this.child = node;
    }

    getHeadClass = (type) => {
        if (this.state.VIEW == type) {
            return ' active';
        }

        return '';
    }

    renderHeader = () => {
        return (
            <div className='address-time-buttons-area'>
                <div className={'address-time-buttons' + this.getHeadClass('adress')} onClick={() => {
                    this.setState({VIEW: 'adress'})
                }}>Адрес
                </div>
                <div className={'address-time-buttons' + this.getHeadClass('payment')} onClick={() => {
                    this.setState({VIEW: 'payment'})
                }}>Время и стоимость
                </div>
            </div>
        );
    }

    setSuggestedInChild = (adress) => {
        this.setState({SUGGESTIONS: false});
        this.callable2(adress)
    }

    renderSuggestions = () => {
        if ((this.state.SUGGESTIONS) && (this.state.SUGGESTIONS.length)) {
            let SUGGESTIONS = [];

            if (this.state.SUGGESTIONS.length) {
                for (let i = 0; i < this.state.SUGGESTIONS.length; i++) {
                    let SEG = this.state.SUGGESTIONS[i];

                    SUGGESTIONS.push(
                        <a
                            key={i}
                            onClick={() => this.setSuggestedInChild(SEG.TITLE)}
                        >
                            {SEG.TITLE}
                        </a>
                    );
                }
            }

            if (SUGGESTIONS.length) {
                return (
                    <div className="modal_suggestions_list" ref={this.setSuggestionRef}>
                        {SUGGESTIONS}
                    </div>
                );
            }

            return false;
        }

        return false;
    }

    getInputClass = () => {
        if (this.state.ERROR) {
            return ' errored';
        }

        if (this.state.SUCCESS) {
            return ' successed';
        }
        return '';
    }

    renderResetButton = () => {
        if (this.state.ERROR) {
            return (
                <a className="input_cleaner" onClick={() => this.resetAdress()}>
                    <img src="/system_images/input_cleaner.png"/>
                </a>
            );
        }

        if (this.state.SUCCESS) {
            return (
                <a className="input_cleaner" onClick={() => this.resetAdress()}>
                    <img src="/system_images/input_success.png"/>
                </a>
            );
        }

        return (
            <a className="input_cleaner" onClick={() => this.resetAdress()}>
                <img src="/system_images/input_cleaner.png"/>
            </a>
        );
    }

    resetAdress = () => {
        this.setState({ADRESS: '', TEMP_ADRESS: '', ERROR: false, SUCCESS: false});

    }

    setAdress = async () => {
        let DATA = {
            KM: this.state.KM,
            ADRESS: this.state.ADRESS,
            GPS: this.state.GPS,
            SHOWADRESS: this.state.TEMP_ADRESS,
        };

        try {
            let SEND_DATA = {
                action: 'checkPolygonsContain',
                lat: this.state.GPS[0],
                lng: this.state.GPS[1]
            };
            sendPostRequest(SEND_DATA).then(res=> {
                if (res) {
                    this.props.setPolygonsData(res)
                    /*CONFIG['MINIMAL'] = res.MINIMUM_ORDER
                    console.log(CONFIG)*/
                } else {
                    this.props.setPolygonsData({DELIVERY_PRICE:'-1', FREE_DELIVERY_WHEN_SUM: '-1', MINIMUM_ORDER: '2000', MINUTES: '-1', POLY_ID: '7'})
                }
            })
        } catch (error) {
            console.log(error);
        }

        if (this.props.REGION !== this.state.REGION) {
            this.props.changeRegion(this.state.REGION);
        }

        this.props.setAdressAndGpsAndRegion(DATA);
        this.props.openDeliveryAddressMixedModal(false);
    }

    renderButton = () => {
        if ((this.state.ADRESS) && (this.state.GPS) && (!this.state.ERROR) && (this.state.SUCCESS)) {
            return <a className="adress_confirm_button_bigmap" onClick={() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'add_shipping_info',
                });

                this.setAdress()
            }}>Сохранить</a>
        } else {
            return <a className="adress_confirm_button_bigmap disabled">Сохранить</a>
        }
    }
    renderError = () => {
        if ((this.state.ERROR)) {
            return (
                <div className="no_delivery_here">
                    В ваш регион мы пока что не доставляем продукты <img src="/system_images/cry_cat.png"/>
                </div>
            );
        }
    }

    renderAdressBlock = () => {
        return (
            <div className="no_adress_in_mixed">
                <div className="adress_modal_heading">Ваш адрес:</div>
                <input type="text" placeholder="Ваш адрес" className={"adress_modal_input" + this.getInputClass()}
                       value={this.state.ADRESS} onChange={(text) => this.recordTempAdress(text)}/>
                <div className="modal_suggestions_holder">{this.renderSuggestions()}{this.renderResetButton()}</div>
                <div className="adress_modal_text">
                    Если вы хотите изменить адрес доставки, то вы можете выбрать другую точку на карте или ввести новый
                    адрес в поле
                </div>
                <div className="modal_adress_button_holder">
                    {this.renderButton()}
                </div>
            </div>
        );
    }

    renderPaymentBlock = () => {
        if (!this.props.ADRESS) {
            return (
                <div className="no_adress_in_mixed">
                    <span>Чтобы сказать время ближайшей доставки, нам надо знать ваш адрес</span>
                    <a onClick={() => this.setState({VIEW: 'adress'})} className="no_adress_in_mixed_link">Указать адрес
                        >></a>
                </div>
            );
        } else {
            return (
                <div className="modal_payment_data desktopped">
                    <div className='payment-information'>
                        <AddressBlock/>
                    </div>
                    <div className="del_modal_line"></div>
                    <div className='delivery-cost'>
                        <CostWithImage/>
                        <CostGrid/>
                    </div>
                    <div className="del_modal_line"></div>
                    <div className='delivery-information'>
                        <DeliveryRules/>
                    </div>
                </div>
            );
        }
    }

    getLeftClass = () => {
        if (this.state.VIEW == 'payment') {
            return ' filledup';
        }

        return '';
    }

    renderLeftPart = () => {
        let CONTENT = '';
        if (this.state.VIEW == 'adress') {
            CONTENT = this.renderAdressBlock();
        } else if (this.state.VIEW == 'payment') {
            CONTENT = this.renderPaymentBlock();
        }
        return (
            <div className={"modal_mixed_left" + this.getLeftClass()}>
                {this.renderHeader()}
                {CONTENT}
            </div>
        );
    }


    mapChangeClass() {
        if (this.state.CHANGE_ADRESS) {
            return 'map_shown';
        } else {
            return 'map_hidden';
        }
    }

    updateData = (value) => {
    }

    checkDeliveryToLocationPermission = (gps, adress) =>
    {
        let REGION_DATA = checkRegionEntry(gps);

        if (!REGION_DATA) {
            if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY) {
                REGION_DATA = checkIfLocationIsInRussia(adress);
            }
        }

        return REGION_DATA;
    }


    changeAdress = (adress, showadress, gps, km) => {
        let REGION_DATA = this.checkDeliveryToLocationPermission(gps, adress);
        
        let SUCCESS = false;
        let ERROR = true;
        if (REGION_DATA) {
            ERROR = false;
            SUCCESS = true;
        }
        this.setState({
            TEMP_ADRESS: showadress,
            ADRESS: adress,
            GPS: gps,
            SHOWADRESS: showadress,
            KM: km,
            ERROR: ERROR,
            SUCCESS: SUCCESS,
            REGION: REGION_DATA.REGION_KEY
        })
    }

    recordTempAdress = (text) => {
        this.callable(text);
        this.setState({TEMP_ADRESS: text.target.value, SUCCESS: false, ADRESS: text.target.value});
    }

    renderMap = () => {
        return (
            <MapDesktop
                className='map-display'
                updateData={this.updateData}
                confirmThisAdress={this.confirmThisAdress}
                CONFIRM={true}
                CLASS={this.mapChangeClass()}
                TYPE="GET_CENTER"
                setAdressAndGps={this.setAdressAndGps}
                REGION='78'
                changeAdress={this.changeAdress}
                GPS={this.props.GPS}
                setCallable={callable => this.callable = callable}
                setCallable2={callable2 => this.callable2 = callable2}
                setSuggestions={this.setSuggestions}
            />
        );
    }

    renderCover = () => {
        if (this.state.VIEW == 'payment') {
            return (
                <div className="map_cover_inactive"></div>
            )
        }

        return false;
    }

    render() {
        return (
            <div className="modal_parent">
                {this.renderError()}
                {this.renderLeftPart()}
                {this.renderCover()}
                {this.renderMap()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DeliveryMixedModalContent);