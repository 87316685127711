import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import Catalogue from './../../APP/SCENES/Catalogue';
import Product from './../../APP/SCENES/Product';
import Tag from './../../APP/SCENES/Tag';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class ShopEnter extends Component {

	constructor(props)
    {
        super(props);
    }

	render() {
		let URL = this.props.match.params.url;

		if (URL.indexOf('tag_') !== -1) {
			let url_parts = URL.split('tag_');
			return <Tag url={url_parts[1]} />;
		} else if (URL.indexOf('.html') !== -1) {
			return <Product url={URL} />;
		} else {
			return <Catalogue url={URL}  />;
		}
	}

}

export default connect(
	false,
	mapDispatchToProps
)(ShopEnter)
