import React, {Component} from 'react';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import HeaderWithAddress from "./YandexMaps/MapHeaderWithAddress";
import MapBackButton from "./YandexMaps/MapBackButton";
import MapIframe from "./YandexMaps/MapIframe.js";
import DeliveryInformation from "./DeliveryInformation.js";

function mapStateToProps(state) {
    return {
        DELIVERY_ADDRESS_MODAL: state.DELIVERY_ADDRESS_MODAL,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MapModal extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            PRODUCT: false,
            ADDRESS:'',
            name:''
        };
    }




    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.DELIVERY_ADDRESS_MODAL !== prevProps.DELIVERY_ADDRESS_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.DELIVERY_ADDRESS_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }

    }

    mapChangeClass() {
        if (this.state.CHANGE_ADRESS) {
            return 'map_shown';
        } else {
            return 'map_hidden';
        }
    }

    updateData = (value) => {
        this.setState({ name: value })
    }


    render() {

        if (window.innerWidth >= 1200) {
            return false;
        }
        if (this.props.DELIVERY_ADDRESS_MODAL) {
            return (
                <div>
                    <div className='modal-overlay' onClick={() => this.props.openDeliveryAddressModal(false)}>
                    </div>
                    <div className="modal-box yamap-card">

                        <MapIframe
                            className='map-display'
                            updateData={this.updateData}
                            confirmThisAdress={this.confirmThisAdress}
                            CONFIRM={true}
                            CLASS={this.mapChangeClass()}
                            TYPE="GET_CENTER"
                            setAdressAndGps={this.setAdressAndGps}
                            REGION='78'
                            GPS=''
                        />

                        {/*<div className='header-with-address'>*/}
                        {/*    <HeaderWithAddress/>*/}
                        {/*    <h5>{this.state.ADDRESS}</h5>*/}
                        {/*</div>*/}

                        <div className='map-back-button'  onClick={() => this.props.openDeliveryAddressModal(false)}>
                            <MapBackButton/>
                        </div>
                    </div>
                    <div className="modal-ya">
                        <a className="close-modal" onClick={() => this.props.openDeliveryAddressModal(true)}>
                            <img src="/system_images/close.png"/>
                        </a>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(MapModal);