import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CardForm  from "./../../../APP/COMPONENTS/ContentParts/CardForm"

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL,
        PAY_MODAL: state.PAY_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class PayForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            CARD: false,
            CARDS: false,
            SAVING: false,
            SHOW_SELECT: false,
            ID: props.PAY_MODAL,
            ADD_CARD: false
        };

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.SHOW_SELECT) {
                this.setState({SHOW_SELECT: false});
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.CARD_MODAL !== prevProps.CARD_MODAL) {
            this.getPaymentCards();
        }
    }

    componentDidMount()
    {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.getPaymentCards();
    }

    setHistoryRef(node) {
        this.history = node;
    }

    getPaymentCards = async () =>
    {
        try {
            let SEND_DATA = {
                action:    'paymentCards',
                apikey:    this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                if (data.cards.length == 0) {
                    this.addCard();
                } else {
                    this.setState({CARDS: data.cards, CARD: data.cards[0].ID});
                }
            } else {
                this.addCard();
            }
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }

    doPay = async() =>
    {
        let SEND_DATA = {
            action:         'addCardToOrder',
            order:          this.state.ID,
            card:           this.state.CARD,
            apikey:         this.props.USER.TOKEN
        };

        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

        if (data !== false) {
            this.props.doPay(false);
        }
    }

    getCardsSource(type)
    {
        switch (type) {
            case 'Visa':
                return CONFIG.API_C_DOMAIN + '/imgs/banks/visa.png';
                break;
            case 'Maestro':
                return CONFIG.API_C_DOMAIN + '/imgs/banks/maestro.png';
                break;
            case 'Mir':
                return CONFIG.API_C_DOMAIN + 'imgs/banks/mir.png';
                break;
            case 'Mastercard':
                return CONFIG.API_C_DOMAIN + 'imgs/banks/mastercard.png';
                break;
        }
    }

    getSelectedTitle = () =>
    {
        if (this.state.CARD) {
            for (let i = 0; i < this.state.CARDS.length; i++) {
                let CARD = this.state.CARDS[i];
                let TITLE = CARD.FIRST + '*****' + CARD.LAST;
                if (CARD.ID == this.state.CARD) {
                    return TITLE;
                }
            }
        }

        return 'У Вас нет карт в данный момент!';
    }

    renderCards()
    {
        if (this.state.CARDS) {
            let CARDS = [];
            for (let i = 0; i < this.state.CARDS.length; i++) {
                let CARD = this.state.CARDS[i];
                let TITLE = CARD.FIRST + '*****' + CARD.LAST;
                let IMAGE = this.getCardsSource(CARD.TYPE);

                CARDS.push(
                    <label key={i} className="col-12" onClick={() => this.setState({CARD: CARD.ID, SHOW_SELECT: false})}>
                        {TITLE}
                    </label>
                );
            }

            let TITLE = this.state.CARDS[0].FIRST + '*****' + this.state.CARDS[0].LAST;
            let IMAGE = this.getCardsSource(this.state.CARDS[0].TYPE);

            if (!this.state.SHOW_SELECT) {
                CARDS = false;
            } else {
                CARDS = (
                    <div className="variants-check-select">
                        {CARDS}
                    </div>
                );
            }

            return (
                <div className="select-parent cards-select" ref={this.setHistoryRef}>
                    <div className="checked-select">
                        <i className="fa fa-caret-down"></i>
                        <div className="selected-check-select" onClick={() => this.setState({SHOW_SELECT: true})}>
                            <label className="col-12">
                                {this.getSelectedTitle()}
                            </label>
                        </div>
                        {CARDS}
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }

    addCard()
    {
        /*this.props.addCardModal('PAY');*/
    }

    renderPayNowHead()
    {
        return (
            <div className="order_white_block no_margin_top">
                <div className="after_order_text">
                    Введите данные карты для оплаты. Мы снимем с неё 1 рубль для проверки карты и вернём в течение суток. Сумма за заказ будет снята как только заказ будет полностью собран
                </div>
            </div>
        );
    }

    cardAdded = (ID) =>
    {
        this.setState({ADD_CARD: false});
        this.getPaymentCards();
    }

    renderCardContent()
    {
        return (
            <div className="modal_content">
                {this.renderPayNowHead()}

                <CardForm cardAdded={this.cardAdded} SECURITY={this.state.SECURITY} />

            </div>
        )
    }

    renderMainContent()
    {
        return (
            <div className="order_form_holder">
                <div className="modal_content">

                    <div id="enter-own-data">
                        <div className="row form-fields">
                            <div className="col-12">
                                {this.renderCards()}
                            </div>
                            <a id="enter-card-data-link" className="simple-link" onClick={() => this.setState({ADD_CARD: true})}>Добавить новую карту</a>
                        </div>
                    </div>

                    <div className="form-stats">
                        <div className="form-fields">
                            <div className="col-12">
                                <div className="p_check agree">
                                    <input name="agree" type="checkbox" id="agree" value="1" checked="checked" readOnly />
                                    <label htmlFor="agree">
                                        <span>
                                        Принимаю условия договора-оферты
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="buttoneer">
                        <div className="row form-fields">
                            <div className="col-12 text-center" id="submit_holder">
                                <a className="orange-button" onClick={() => this.doPay()}>
                                    Оплатить
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

	render()
    {
        if (this.state.ADD_CARD) {
            return this.renderCardContent();
        } else {
            return this.renderMainContent();
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(PayForm);