import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    if (typeof state.CART[myProps.PRODUCT.id] !== "undefined") {
        return {
            AMOUNT: state.CART[myProps.PRODUCT.id].amount
        }
    } else {
        return {
            AMOUNT: 0
        }
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class AddonAddedRow extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: props.PRODUCT,
            KEY: props.key
        }
        this.PRODUCT = props.PRODUCT;
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.MANIPULATE) {
                this.setState({MANIPULATE: false});
            }
        }
    }

    renderImage()
    {
        let SRC = this.state.PRODUCT.thumb;
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC + ')'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        } else {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        }
    }

    render()
    {
        return (
            <div className="button-holder row-card addon-card">
                {this.renderImage()}
                <div className="caption">
                    <span className="product_title">
                        {this.state.PRODUCT.title}
                    </span>
                    <span className="addon_status">
                        Сборка
                    </span>
                </div>
                <div className="middle-controls">
                    <span className="active_amount">
                        {this.state.PRODUCT.amount}
                    </span>
                </div>
                <div className="product-price">
                    <span className="price">{this.state.PRODUCT.sum} р</span>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AddonAddedRow);