import React, {Component} from 'react';
/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {sendPostRequest} from "../../HELPERS/requests";
import CONFIG from "../../../SYSTEM/Config";

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION,
        ADRESS: state.ADRESS,
        SHOWADRESS: state.SHOWADRESS,
        PRODUCTS: state.PRODUCTS,
        CART: state.CART,
        KM: state.KM,
        INSIDE_MKAD: state.INSIDE_MKAD,
        PolygonsDeliveryPrice: state.PolygonsDeliveryPrice,
        PolygonsFreeDeliveryWhenSum: state.PolygonsFreeDeliveryWhenSum,
        PolygonsMinimumOrder: state.PolygonsMinimumOrder,

        POSTAL_DAYS: state.POSTAL_DAYS,
        POSTAL_PRICE: state.POSTAL_PRICE
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class AddressBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ORDERS_COUNT: 9999,
            MKAD: props.KM,
        }
    }

    componentDidMount() {
        if (this.props.USER) {
            this.fetchOrdersCount(this.props.USER.PHONE)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.KM !== this.state.MKAD) {
            this.setState({MKAD: this.props.KM});
        }
    }

    fetchOrdersCount = async (phone) => {
        try {
            let SEND_DATA = {
                action: 'getOrdersCountByPhone',
                phone: phone

            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ORDERS_COUNT: data.orders_count});
            }
        } catch (error) {
            return false;
        }
    }

    num_title = (n, titles) => {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    countDeliverySum = (little_rub) => {
        let SUM_DATA = this.countCart();
        let SUM = SUM_DATA.SUM;

        if (this.props.INSIDE_MKAD) {


            if (this.props.PolygonsDeliveryPrice !== '-1') {
                if (Number(this.props.PolygonsFreeDeliveryWhenSum) <= SUM) {
                    this.state.DELIVERY_PRICE = 0
                    return (
                        <span className="delivery_price_green">
                            Бесплатно
                        </span>
                    )
                } else {
                    this.state.DELIVERY_PRICE = Number(this.props.PolygonsDeliveryPrice)
                    return this.props.PolygonsDeliveryPrice + ' р'

                }
            }


            if (SUM > 3500) {
                if (typeof little_rub !== "undefined") {
                    return (
                        <span className="delivery_price_green">
                            Бесплатно
                        </span>
                    );
                } else {
                    return 'Бесплатно';
                }
            } else {
                if (typeof little_rub !== "undefined") {
                    return '299 р';
                } else {
                    return '299 рублей';
                }

            }
        } else {
            let KM = this.props.KM;
            let SET_SUM = 0;
            let LEFT_KM = 0;

            if (SUM < 8500) {
                if (KM < 30) {
                    SET_SUM = KM * 30;
                } else {
                    LEFT_KM = KM - 30;
                    SET_SUM = 30 * 30 + (LEFT_KM * 50);
                }
            } else {
                if (KM > 30) {
                    var use_km = KM - 30;
                    SET_SUM = use_km * 50;
                }
            }

            let CITY_ADDON = 299;
            if (SUM > 3500) {
                CITY_ADDON = 0;
            }

            SET_SUM += CITY_ADDON;

            if (SET_SUM == 0) {
                if (typeof little_rub !== "undefined") {
                    return (
                        <span className="delivery_price_green">
                            Бесплатно
                        </span>
                    );
                } else {
                    return 'Бесплатно';
                }
            }

            if (typeof little_rub !== "undefined") {
                return SET_SUM + 'р';
            } else {
                return SET_SUM + ' ' + this.num_title(SET_SUM, ['рубль', 'рубля', 'рублей']);
            }

        }
    }

    renderAdress = () => {
        if (this.props.ADRESS) {
            return (
                <span className="order_form_adress_changer" onClick={() => {
                    this.props.openDeliveryPaymentModal(false);
                    this.props.openOrder(false);
                    this.props.openDeliveryAddressModal(true);
                }}>
                    {this.props.ADRESS}
                </span>
            );
        } else {
            return (
                <span className="order_form_adress_changer" onClick={() => {
                    this.props.openDeliveryPaymentModal(false);
                    this.props.openOrder(false);
                    this.props.openDeliveryAddressModal(true);
                }}>
                    Укажите адрес
                </span>
            );
        }
    }

    renderFreeLeft = () => {
        let SUM_DATA = this.countCart();
        let SUM = SUM_DATA.SUM;
        if (this.props.INSIDE_MKAD) {
            if (SUM < 3500) {
                let LEFT = Math.round(3500 - SUM);
                return (
                    <div className="free-delivery-exactly">
                        {LEFT} {this.num_title(LEFT, ['рубль', 'рубля', 'рублей'])}
                    </div>
                );
            }
        } else {
            if (SUM < 8500) {
                let LEFT = Math.round(8500 - SUM);
                return (
                    <div className="free-delivery-exactly">
                        {LEFT} {this.num_title(LEFT, ['рубль', 'рубля', 'рублей'])}
                    </div>
                );
            }
        }
    }

    renderTillFree = () => {
        let LEFT = this.renderFreeLeft();
        if (typeof LEFT !== "undefined") {
            if (LEFT.length) {
                return (
                    <div>
                        <div className='free-delivery-description'>До бесплатной доставки осталось купить товаров
                            на
                        </div>
                        {LEFT}
                    </div>
                )
            }
        }
    }

    ShowAlarmPanel(switcher) {
        if (switcher) {
            return (
                <div className='action-description'>
                    <img className='vertical-line-action' src="/system_images/VerticalLine.png"/>
                    <div className='action-text-main'>
                        <div className='big-action-text'>По вашему адресу действует акция</div>
                        <div className='small-action-text'>Сумма доставки расчитывается в зависимости от суммы
                            покупки. Чем больше сумма покупки, тем меньше сумма доставки
                        </div>
                    </div>
                </div>
            )
        }
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    rmFixed2(numb) {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(parseFloat(numb).toFixed(2)).toFixed(2);
        return numb;
    }

    countCartButton() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        return SUM;
    }

    renderFreeDevelopConditions = () => {

        let SUM = this.countCartButton();

        if (this.props.PolygonsFreeDeliveryWhenSum !== '-1') {
            let CITY_SUM = (this.rmFixed2(Number(this.props.PolygonsFreeDeliveryWhenSum) - SUM))
            if(CITY_SUM <= 0){
                return false
            } else{

                return "До бесплатной доставки осталось " + CITY_SUM + ' рублей!'

            }
        }

        if(parseInt(this.props.KM) == 0){
            let CITY_SUM = (this.rmFixed2(3500 - SUM))
            if(CITY_SUM <= 0){
                return false
            } else{

                return "До бесплатной доставки осталось " + CITY_SUM + ' рублей!'

            }
        }
        else{
            let VILLAGE_SUM = (this.rmFixed2(8500 - SUM))
            if(VILLAGE_SUM <= 0){
                return false
            } else{

                return  "До бесплатной доставки осталось " + VILLAGE_SUM + '!'

            }
        }
    }

    FreeDevelop() {



        let SUM = this.countCartButton();
        let LEFT = this.rmFixed2(this.props.PolygonsMinimumOrder - SUM)
        if ((parseInt(this.state.ORDERS_COUNT) < 4) && (parseInt(this.state.MKAD) <= 10))
            return (
                <div>
                    <div className='cost-block-main'>
                        <div className='cost-description'>Стоимость доставки составит:</div>
                        <div className='cost-exactly'>Бесплатно</div>
                    </div>
                    <div className='free-delivery-block-main'>
                        <div className='free-delivery-some-information'>Минимальная сумма
                            заказа {this.props.PolygonsMinimumOrder ? this.props.PolygonsMinimumOrder : '2000.00'} руб
                        </div>
                    </div>
                </div>
            )
        else {
            if ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.POSTAL_DAYS) {
                return (
                    <div>
                        <div className='cost-block-main'>
                            <div className='cost-description'>Стоимость доставки составит:</div>
                            <div className='cost-exactly'>от {this.props.POSTAL_PRICE} р</div>
                        </div>

                    </div>
                );
            } else {
                return (
                    <div>
                        <div className='cost-block-main'>
                            <div className='cost-description'>Стоимость доставки составит:</div>
                            <div className='cost-exactly'>{this.countDeliverySum()} </div>
                        </div>

                        <div className='free-delivery-block-main'>
                            {this.renderTillFree()}
                            {
                                <div className='free-delivery-some-information'>Минимальная сумма
                                    заказа {this.props.PolygonsMinimumOrder ? this.props.PolygonsMinimumOrder : '2000.00'} руб
                                </div>}
                        </div>

                        {LEFT > 0 &&
                        <div className='free-delivery-block-main'>

                            <div className='free-delivery-some-information' style={{color: '#FF7834'}}>{this.renderFreeDevelopConditions()}

                            </div>
                        </div>
                        }
                    </div>
                )
            }
        }
    }

    countCart() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        let FREE_DEL = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;

                        if (PRODUCTS[ID].fd > 0) {
                            FREE_DEL = 1;
                        }
                    }
                }
            }
        }

        this.SUM = SUM;

        return {
            FREE_DEL: FREE_DEL,
            SUM: SUM
        };
    }

    render() {
        return (
            <div>
                <div className='address-block-main'>
                    <div className='address-description'>Адрес доставки:</div>
                    <div className='address-exactly'>{this.renderAdress()}</div>
                </div>
                {this.FreeDevelop()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AddressBlock);