import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";
import styles from "./Bonuses.module.css"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import BonusesSlider from "./../../../APP/COMPONENTS/ContentParts/BonusesSlider"
import SText from "../../HELPERS/SText";
import cn from "classnames";

function mapStateToProps(state) {
    return {
        USER: state.USER,
        PRODUCTS: state.PRODUCTS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Bonuses extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            CODE: this.props.USER.CODE,
            BONUSES: this.props.USER.BONUSES,
            SHARE_BONUSES: 0,
            SHARE: false,
            HOW: false,
            HISTORY: false,
            BONUSES_STORY: false,
            PHONE: false,
            NEW_CODE: false,
            SHARE_CODE: false,
            SHARE_LINK: false,
            value: 6000,
            BLOGGER_EMAIL: this.props.USER.EMAIL,
            BLOGGER_NAME: this.props.USER.NAME,
            BLOGGER_VK: '',
            BLOGGER_INSTAGRAM: '',
            BLOGGER_YOUTUBE: '',
            BLOGGER_TIKTOK: '',
            BLOGGER_ANOTHER: '',
            PARTNER_PLATFORM: '',

            EARN: false,
            EXPIRES: false,
        };

        this.setHowEarn = this.setHowEarn.bind(this);
        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.setHowRef = this.setHowRef.bind(this);
        this.setShareRef = this.setShareRef.bind(this);
        this.setShareRef = this.setShareRef.bind(this);
        this.setUtmRef = this.setUtmRef.bind(this);
        this.setUtmPRef = this.setUtmPRef.bind(this);
        this.setCodeRef = this.setCodeRef.bind(this);
        this.setShareCodeRef = this.setShareCodeRef.bind(this);
        this.setShareLinkRef = this.setShareLinkRef.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.myRef = React.createRef();
        this.myRef2 = React.createRef();
    }

    componentDidMount() {
        this.getHistory();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.USER.BONUSES !== prevProps.USER.BONUSES) || (this.props.USER.CODE !== prevProps.USER.CODE)) {
            this.setState({BONUSES: this.props.USER.BONUSES, CODE: this.props.USER.CODE});
        }
    }

    getHistory = async () => {
        try {
            let SEND_DATA = {
                action: 'bonusesStory',
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({BONUSES_STORY: data.bonuses_story, EXPIRES: data.expirings})
            }
        } catch (error) {
            console.log('BAD_BONUSES_STORY');
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setHistoryRef(node) {
        this.history = node;
    }

    setHowEarn(node) {
        this.earn = node;
    }

    setHowRef(node) {
        this.how = node;
    }

    setShareRef(node) {
        this.share = node;
    }

    setCodeRef(node) {
        this.code = node;
    }

    setUtmRef(node) {
        this.utm = node;
    }

    setUtmPRef(node) {
        this.utmp = node;
    }

    setShareCodeRef(node) {
        this.share_code = node;
    }

    setShareLinkRef(node) {
        this.share_link = node;
    }

    handleClickOutside(event) {
        if (this.earn && !this.earn.contains(event.target)) {
            if (this.state.EARN) {
                this.setState({EARN: false});
            }
        }

        if (this.history && !this.history.contains(event.target)) {
            if (this.state.HISTORY) {
                this.setState({HISTORY: false});
            }
        }

        if (this.how && !this.how.contains(event.target)) {
            if (this.state.HOW) {
                this.setState({HOW: false});
            }
        }

        if (this.share && !this.share.contains(event.target)) {
            if (this.state.SHARE) {
                this.setState({SHARE: false});
            }
        }

        if (this.code && !this.code.contains(event.target)) {
            if (this.state.EDIT) {
                this.setState({EDIT: false});
            }
        }

        if (this.share_code && !this.share_code.contains(event.target)) {
            if (this.state.SHARE_CODE) {
                this.setState({SHARE_CODE: false});
            }
        }

        if (this.utm && !this.utm.contains(event.target)) {
            if (this.state.SHARE_UTM) {
                this.setState({SHARE_UTM: false});
            }
        }

        if (this.utmp && !this.utmp.contains(event.target)) {
            if (this.state.SHARE_PUTM) {
                this.setState({SHARE_PUTM: false});
            }
        }

        if (this.share_link && !this.share_link.contains(event.target)) {
            if (this.state.SHARE_LINK) {
                this.setState({SHARE_LINK: false});
            }
        }
    }

    num_title = (n, titles) => {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderHowHead() {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({HOW: false})}>
                        <img src={'/system_images/angle_left.png'}/>
                    </a>
                    <h4>Как зачисляются экобаллы</h4>
                </div>
            );
        } else {
            return (
                <div>
                    <a className="close-modal" onClick={() => this.setState({HOW: false})}>
                        <img src={'/system_images/close.png'}/>
                    </a>

                    <div className="how_bonuses_head">Как зачисляются экобаллы</div>
                </div>
            );
        }
    }

    renderHowAppend() {
        if (this.state.HOW) {
            let CLASSES = 'form-pop-append';
            if (WIDTH <= 760) {
                CLASSES = 'form-pop-append fixed-full-screen';
            }

            return (
                <div className={CLASSES} id="bonuses-pop" ref={this.setHowRef}>
                    {this.renderHowHead()}

                    <div className="how_bonuses_text">
                        За каждую вашу покупку в ECOMARKET.RU мы начисляем вам экобаллы на ваш <a
                        onClick={() => this.setState({HOW: false})}>счет в личном кабинете.</a> Количество начисленных
                        экобаллов зависит от суммы, на которую была произведена ваша покупка, а именно:
                    </div>

                    <div className="bonuses-info-table">
                        <div>
                            2000-2500 руб - 0,5%
                            <br/>
                            2501-3500 руб - 1%
                            <br/>
                            3501-4500 руб - 1,5%
                            <br/>
                            4501-5500 руб - 2%
                            <br/>
                            5501-7000 руб - 2,5%
                            <br/>
                            7001-8500 руб - 3%
                            <br/>
                            8501-10000 руб - 4%
                        </div>
                        <div>
                            10001-12000 руб - 5%
                            <br/>
                            12001-14000 руб - 6%
                            <br/>
                            14001-16000 руб - 7%
                            <br/>
                            17001-18000 руб - 8%
                            <br/>
                            18001-20000 руб - 9%
                            <br/>
                            20001 руб и выше - 10%
                        </div>
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        <strong>
                            Вы делаете пост или сторис в Instagram
                        </strong>
                        <br/>
                        с обзором наших продутов и отмечаете нашу страницу @ecomarket.ru.
                        <br/>
                        Мы начисляем вам 250 экобаллов за первый пост или сторис и 150 экобаллов за последующие
                        публикации при дальнейших заказах.
                        <br/>
                        1 заказ - 1 начисление, 1 экобалл = 1 рубль.
                        <br/>
                        Если ваша страница закрыта, напишите нам и наш менеджер по инстаграму добавится к вам.
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        Если <strong>курьер забыл у вас термопакет с холодками</strong>, позвоните нам 8(495)4450505
                        (доб 106) или напишите в WhatsApp +7 916 1150909 и мы начислим вам 200 экобаллов, а пакет
                        заберём при следующей доставке.
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        Отдайте курьеру использованные батарейки, и мы начислим вам экобаллы:
                        <br/>
                        1 пальчиковая батарейка - 10 экобаллов.
                    </div>

                    <div className="insta_block">
                        <div className="insta_image_holder">
                            <img src="/system_images/green_instagram.png"/>
                        </div>
                        <div>
                            <div className="green_imsta">
                                Подписывайтесь на наш Instagram,
                            </div>
                            <div className="black_insta">
                                участвуйте в конкурсах и выигрывайте.
                            </div>
                        </div>
                    </div>

                    <div className="bonus_warning">Внимание! Экобаллы сгорают по истечении 30 дней</div>

                </div>
            );
        }
    }

    renderEarnHead() {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({EARN: false})}>
                        <img src={'/system_images/angle_left.png'}/>
                    </a>
                    <h4>Дополнительные экобаллы</h4>
                </div>
            );
        }
    }

    renderShareHead() {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({SHARE: false})}>
                        <img src={'/system_images/angle_left.png'}/>
                    </a>
                    <h4>Передать экобаллы другу</h4>
                </div>
            );
        }
    }

    renderHistoryHead() {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({HISTORY: false})}>
                        <img src={'/system_images/angle_left.png'}/>
                    </a>
                    <h4>История начисления экобаллов</h4>
                </div>
            );
        }
    }

    renderHistory() {
        if (this.state.HISTORY) {
            let STORY_ITEMS = [];

            if (this.state.BONUSES_STORY) {
                for (let i = 0; i < this.state.BONUSES_STORY.length; i++) {
                    let ITEM = this.state.BONUSES_STORY[i];
                    STORY_ITEMS.push(
                        <div className="story-row" key={i} ref={this.setHistoryRef}>
                            <div
                                className="story-amount">{ITEM.AMOUNT} {this.num_title(ITEM.AMOUNT, ['экобалл', 'экобалла', 'экобаллов'])}</div>
                            <div className="story-how">{ITEM.HUMAN_DATE} {ITEM.WAY}</div>
                            <div className="story-how">Активны до {ITEM.DATE_END}</div>
                        </div>
                    );
                }
            }

            let CLASSES = 'bform-pop-append';
            if (WIDTH <= 760) {
                CLASSES = 'bform-pop-append fixed-full-screen';
            }

            return (
                <div className={CLASSES} id="story-pop" ref={this.setHistoryRef}>
                    <a className="close-modal floating" onClick={() => this.setState({HISTORY: false})}>
                        <img src={'/system_images/close.png'}/>
                    </a>
                    <div className="bonuses_modal_title thelined">
                        История начисления экобаллов
                    </div>
                    {this.renderHistoryHead()}
                    {STORY_ITEMS}
                </div>
            );
        }
    }

    doShare = async () => {
        try {
            let SEND_DATA = {
                action: 'shareBonuses',
                apikey: this.props.USER.TOKEN,
                phone: this.state.PHONE,
                bonuses: this.state.SHARE_BONUSES
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SHARE: false, SHARE_BONUSES: 0, PHONE: false, BONUSES: data.BONUSES});
                this.props.setBonusesAmount(data.BONUSES);
            } else {
                return false;
            }
        } catch (error) {
            console.warn('PROBLEM WHILE SHARING');
        }
    }

    recordPhone = (phone) => {
        this.setState({PHONE: phone.target.value});
    }

    recordBonuses = (bonuses) => {
        this.setState({SHARE_BONUSES: bonuses.target.value});
    }

    changeCode = (code) => {
        this.setState({NEW_CODE: code.target.value});
    }

    changeCodeNow = async () => {
        if (this.state.NEW_CODE.length > 5) {
            try {
                let SEND_DATA = {
                    action: 'changeCode',
                    code: this.state.NEW_CODE,
                    apikey: this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.props.doAuth(data.user);
                }
            } catch (error) {
                console.log('BAD PRODUCTS TIMER');
            }
        }
    }

    renderShare() {
        if (this.state.SHARE) {
            let STORY_ITEMS = [];

            let CLASSES = 'bform-pop-append';
            if (WIDTH <= 760) {
                CLASSES = 'bform-pop-append fixed-full-screen';
            }

            return (
                <div className={CLASSES} id="share-pop" ref={this.setShareRef}>
                    <a className="close-modal floating" onClick={() => this.setState({SHARE: false})}>
                        <img src={'/system_images/close.png'}/>
                    </a>
                    <div className="bonuses_modal_title">
                        Передать экобаллы другу
                    </div>
                    <div className="form-fields nm">
                        <div className="col-12">
                            <InputMask placeholder="Телефон друга" type="tel" className="phone full" id="share_phone"
                                       mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)}/>
                        </div>
                        <div className="col-8 npr">
                            <input name="bonuses" placeholder="Количество баллов" id="share_bonuses" type="number"
                                   className="bonuses full" min="0" max={this.props.USER.BONUSES}
                                   onChange={(text) => this.recordBonuses(text)}/>
                        </div>
                        <div className="col-4 npl">
                            <a className="orange-button shared-btn" onClick={() => this.doShare()}>Поделиться</a>
                        </div>
                        <div className="col-12 share_message"></div>
                        {this.renderShareHead()}
                    </div>
                </div>
            );
        }
    }

    renderEdit(POSITION) {
        if (this.state.EDIT == POSITION) {
            return (
                <div className="form-pop-append" id="code-pop" ref={this.setCodeRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <input name="code" id="share_bonuses" type="text" className="bonuses"
                                   onChange={(text) => this.changeCode(text)}/>
                        </div>
                        <div className="col-4 npl">
                            <a className="orange-button" onClick={() => this.changeCodeNow()}>Сохранить</a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderShares() {
        return (
            <div>

                <div className="bonus_share_text">
                    <div>
                        Подарите другу <strong>{this.props.USER.PROMO_DISCOUNT}</strong> экобаллов на первую покупку и
                        Вы также получите {this.props.USER.PROMO_DISCOUNT} экобаллов на свой счёт!
                    </div>
                    <div className="bonus-small-text">
                        Для этого, поделитесь промокодом со своим другом на первую покупку и получайте бонусы.
                    </div>
                </div>

                <div className="bonus-share-block">
                    <div className="bonus-title">
                        Ваш промокод
                    </div>
                    <div className="bonus-holder">
                        <div className="bonus-actions">
                                <span>
                                    {this.props.USER.CODE}
                                </span>
                            <a onClick={() => this.setState({EDIT: 'CODE'})}>
                                <img src="/system_images/green_pencil.png"/>
                            </a>
                        </div>
                    </div>

                    <div className="share_buttons">
                        <a className="share_button" onClick={() => this.setState({SHARE_CODE: true})}>
                            <img src="/system_images/green_chain.png"/>
                        </a>
                        <a className="share_button" onClick={() => this.setState({SHARE_CODE: true})}>
                            <img src="/system_images/green_share.png"/>
                        </a>
                        <a className="share_button" target="_blank"
                           href={'http://api.whatsapp.com/send?text=' + this.props.USER.CODE_MESSAGE}>
                            <img src="/system_images/green_whatsapp.png"/>
                        </a>
                    </div>

                    <div className="share-holder">
                        {this.renderEdit('CODE')}
                        {this.renderShareCode()}
                    </div>
                </div>
                <div className="mobile_separator"></div>
                <div className="bonus-share-block">
                    <div className="bonus-title">
                        Ваша реферальная ссылка
                    </div>
                    <div className="bonus-holder">
                        <div className="bonus-actions">
                                <span>
                                    https://ecomarket.ru/!{this.props.USER.CODE}/
                                </span>
                            <a onClick={() => this.setState({EDIT: 'LINK'})}>
                                <img src="/system_images/green_pencil.png"/>
                            </a>
                        </div>
                    </div>

                    <div className="share_buttons">
                        <a className="share_button" onClick={() => this.setState({SHARE_LINK: true})}>
                            <img src="/system_images/green_chain.png"/>
                        </a>
                        <a className="share_button" onClick={() => this.setState({SHARE_LINK: true})}>
                            <img src="/system_images/green_share.png"/>
                        </a>
                        <a className="share_button" target="_blank"
                           href={'http://api.whatsapp.com/send?text=' + this.props.USER.LINK_MESSAGE}>
                            <img src="/system_images/green_whatsapp.png"/>
                        </a>
                    </div>

                    <div className="share-holder">
                        {this.renderEdit('LINK')}
                        {this.renderShareLink()}
                    </div>
                </div>

            </div>
        );
    }

    renderShareCode() {
        if (this.state.SHARE_CODE) {
            return (
                <div className="form-pop-append" ref={this.setShareCodeRef}>
                    <div className="share-actions">
                        <div className="sharer">
                            <a target="_blank" className="shareicon"
                               href={'http://api.whatsapp.com/send?text=' + this.state.CODE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://twitter.com/intent/tweet?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + this.props.USER.CODE_MESSAGE + '&description=' + this.props.USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + this.props.USER.CODE_MESSAGE + '&description=' + this.props.USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'viber://forward?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'}/>
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderShareLink() {
        if (this.state.SHARE_LINK) {
            return (
                <div className="form-pop-append" ref={this.setShareLinkRef}>
                    <div className="share-actions">
                        <div className="sharer">
                            <a className="cloner"
                               onClick={() => navigator.clipboard.writeText('https://ecomarket.ru/!' + this.props.USER.CODE + '/')}>
                                <i className="fas fa-clone"></i>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'http://api.whatsapp.com/send?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://twitter.com/intent/tweet?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + this.props.USER.LINK_MESSAGE + '&description=' + this.props.USER.LINK_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + this.props.USER.LINK_MESSAGE + '&description=' + this.props.USER.LINK_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'viber://forward?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'}/>
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderAnketaBlock = () => {
        if (parseInt(this.props.USER.polled) !== 1) {
            return (
                <div className={styles.ecoPerMinute_block}>
                    <div className="bonuses_anketa_way_intro">
                        1200 экобаллов за 1 минуту!
                    </div>
                    <div className="bonuses_anketa_way_flex">
                        <div className="bonuses_anketa_way_flex_left">
                            Это не шутки! Переходите по ссылке, заполните анкету о себе, а мы начислим вам 1200
                            экобаллов! Это займет 1 минуту
                        </div>
                        <div className="bonuses_anketa_way_flex_right">
                            <Link style={{color: '#fff'}} to="/closer" onClick={() => this.props.openLK(false)}>
                                Скорее к анкете
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderEcoPerMinute = () => {
        return (
            <div className={styles.ecoPerMinute_block}>
                <div style={{marginBottom: 20}}>
                    <SText fontSize={16} fontWeight={700} lineHeight={20}
                           color={'#404040'}>{'1200 экобаллов за 1 минуту!'}</SText>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', flex: 1}}>
                    <SText style={{maxWidth: 290, marginRight: 0, marginBottom: 15}} fontSize={16} fontWeight={400}
                           lineHeight={20}
                           color={'#404040'}>{'Это не шутки! Переходите по ссылке, заполните анкету о себе, а мы начислим вам 1200 экобаллов! Это займет 1 минуту'}</SText>
                    <SText fontSize={14} fontWeight={700} lineHeight={18} color={'#fff'}
                           className={styles.anketaBtn}>{'Скорее к анкете'}</SText>
                </div>
            </div>
        );
    }
    recordNewCodeNow = (code) => {
        this.changeCodeNowActive(code.target.value);
    }

    changeCodeNowActive = async (CODE) => {
        if (CODE.length > 5) {
            try {
                let SEND_DATA = {
                    action: 'changeCode',
                    code: CODE,
                    apikey: this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

                if (data !== false) {
                    this.props.doAuth(data.user);
                    this.setState({CODE_ERROR: false, CODE: CODE});
                } else {
                    this.setState({CODE_ERROR: true});
                }
            } catch (error) {
                console.log('BAD PRODUCTS TIMER');
            }
        } else {
            this.setState({CODE_ERROR: true});
        }
    }

    getClassInput = () => {
        if (this.state.CODE_ERROR) {
            return 'with_error';
        }

        return '';
    }

    renderPromocodeBlock = () => {
        let CODE = this.state.CODE;
        return (
            <div className="promocode_block">
                <div className="promocode_inner_block">
                    <input className={this.getClassInput()} defaultValue={CODE}
                           onKeyUp={(text) => this.recordNewCodeNow(text)}/>
                    <div className="promocode_inner_block_flex">
                        <a className="copy_btn" onClick={() => navigator.clipboard.writeText(CODE)}>
                            Копировать
                        </a>
                        <a className="promocode_block_share_button" onClick={() => this.setState({SHARE_CODE: true})}>
                            <img src="/system_images/share_icon.png"/>
                        </a>
                        {this.renderShareCode()}
                    </div>
                </div>
                <div className="promocode_inner_block">
                    <div className="promocode_inner_block_header">
                        А это ваш промокод!
                    </div>
                    <div className="promocode_inner_block_text">
                        Подарите другу {this.props.USER.PROMO_DISCOUNT} экобаллов на первый заказ и
                        получите {this.props.USER.PROMO_DISCOUNT} экобаллов на свой счёт. Для этого поделитесь
                        промокодом со своим другом и получайте бонусы!
                    </div>
                </div>
            </div>
        );
    }


    renderUTMBlock = () => {
        return false;

        if ((this.props.USER.STATUS == 4) || (this.props.USER.STATUS == 6)) {
            let utm = this.props.USER.UTM;

            return (
                <div className="utm_block">
                    <div className="promocode_inner_block">
                        <div className="utm_text_block">?utm_source={this.props.USER.UTM}</div>
                        <div className="promocode_inner_block_flex">
                            <a className="copy_btn"
                               onClick={() => navigator.clipboard.writeText('https://ecomarket.ru?utm_source=' + this.props.USER.UTM)}>
                                Копировать
                            </a>
                            <a className="promocode_block_share_button"
                               onClick={() => this.setState({SHARE_UTM: true})}>
                                <img src="/system_images/share_icon.png"/>
                            </a>
                            {this.renderShareUtm()}
                        </div>
                    </div>
                    <div className="promocode_inner_block">
                        <div className="utm_inner_block_header">
                            Ваша utm-ссылка на сайт Ecomarket.ru
                        </div>
                        <div className="utm_inner_block_text">
                            Не забывайте ставить ссылку в свайп на ваших сторис!
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderShareUtm = () => {
        if (this.state.SHARE_UTM) {
            let utm = this.props.USER.UTM;
            let share = 'https://ecomarket.ru?utm_source=' + this.props.USER.UTM;
            return (
                <div className="form-pop-append" ref={this.setUtmRef}>
                    <div className="share-actions">
                        <div className="sharer">
                            <a target="_blank" className="shareicon"
                               href={'http://api.whatsapp.com/send?text=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://twitter.com/intent/tweet?text=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + share + '&description=' + share + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + share + '&description=' + share + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'}/>
                            </a>
                            <a target="_blank" className="shareicon" href={'viber://forward?text=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'}/>
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderSharePUtm = (share) => {
        if (this.state.SHARE_PUTM) {
            let utm = this.props.USER.UTM;
            return (
                <div className="form-pop-append" ref={this.setUtmPRef}>
                    <div className="share-actions">
                        <div className="sharer">
                            <a target="_blank" className="shareicon"
                               href={'http://api.whatsapp.com/send?text=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://twitter.com/intent/tweet?text=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + share + '&description=' + share + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + share + '&description=' + share + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'}/>
                            </a>
                            <a target="_blank" className="shareicon"
                               href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'}/>
                            </a>
                            <a target="_blank" className="shareicon" href={'viber://forward?text=' + share}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'}/>
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    unneeded = () => {
        return (
            <div>
                <div className="bonus_separator"></div>

                {this.renderShares()}
            </div>
        );
    }

    renderFinalsPretty = () => {
        if (this.state.EARN) {

            let CLASSES = 'bform-pop-append';
            if (WIDTH <= 760) {
                CLASSES = 'bform-pop-append fixed-full-screen';
            }

            return (
                <div className={CLASSES} ref={this.setHowEarn}>
                    <a className="close-modal floating" onClick={() => this.setState({EARN: false})}>
                        <img src={'/system_images/close.png'}/>
                    </a>
                    <div className="bonuses_modal_title">
                        Дополнительные экобаллы
                    </div>
                    <div className="first_gradient_block">
                        <div className="white_gradient_header">
                            Сделайте пост или сториз в Инстаграм с отметкой
                        </div>
                        <div>
                            <span className="gradient_insta_link">
                                @ecomarket.ru
                            </span>
                        </div>
                        <div className="gradient_small_text">
                            Наш менедер свяжется с вами в директе и начислит
                        </div>
                        <div className="gradient_bold_text">
                            250 экобаллов за первый отзыв и по 150 – за последующие.
                        </div>
                        <img src="/system_images/instahand.png"/>
                    </div>

                    <div className="second_gradient_block">
                        <div className="white_gradient_header">
                            Сохранение природы
                        </div>
                        <div className="gradient_small_text">
                            Cдавайте курьеру батарейки, пластиковые крышки<br/>
                            от бутылок, фломастеры, шариковые ручки.
                        </div>
                        <div className="gradient_bold_text">
                            1 батарейка = 10 экобаллов<br/>
                            1 крышка/ручка/фломастер = 5 экобаллов
                        </div>
                        <img src="/system_images/hedgehogs.png"/>
                    </div>

                    <div className="third_gradient_block">
                        <div className="white_gradient_header black">
                            Если курьер оставил у вас термопакет,<br/>
                            напишите нам!
                        </div>
                        <div style={{maxWidth: 249}} className="gradient_small_text black">
                            И мы заберем его при следующей доставке, а вам начислим 200 экобаллов.
                        </div>
                        <div>
                            <a className="gradient_wa"
                               href="http://api.whatsapp.com/send?text=Здравствуйте, курьер оставил у нас термопакет. Прошу забрать его при следующей доставке и начислить мне 200 экобаллов&phone=+79161150909">Написать
                                на WhatsApp</a>
                        </div>
                        <img src="/system_images/ecobag.png"/>
                    </div>
                    {this.renderEarnHead()}
                </div>
            );
        }
    }

    renderBecomeABlogger = () => {
        if ((parseInt(this.props.USER.STATUS) !== 4) && ((parseInt(this.props.USER.STATUS) !== 6))) {
            return (
                <div className="blogger_form">
                    {this.renderBecomeBloggerIntro()}
                    {this.renderBloggerForm()}
                    {this.renderPartnerForm()}
                </div>
            );
        }
    }

    renderBecomeBloggerIntro = () => {
        console.log(this.props.USER)
        let y = this.props.USER
        if (Number(this.props?.USER?.BLOGGER_STATUS) < 2 /*&& JSON.parse(this.props.USER.PARTNER_STATUS) < 2*/) {
            if ((this.state.BLOGGER_SENT) || (this.props.USER.BLOGGER_STATUS == 1) || (this.state.PARTNER_SENT) || (this.props.USER.PARTNER_STATUS == 0)) {
                return (
                    <div className="blogger_form_intro">
                        <a className="inactive">
                            Заявка отправлена
                        </a>
                        <div className="blogger_form_intro_text">
                            Спасибо, что оставили заявку!<br/>В течение суток вам придет уведомление на почту.
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="blogger_form_intro_text">
                            Станьте блогером или партнёром Ecomarket.ru и получите возможность 100% оплаты вашего заказа
                            бонусам, а так же возможность вывода заработанных средств на ваш расчетный счет.
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{marginRight: 15, cursor: 'pointer'}}
                                 className={cn(this.getBloggerFormAClass(), styles.standBtn)} onClick={() => {
                                this.setState({PARTNER_FORM_OPENED: false})
                                this.fillBloggerForm()
                            }}>
                                <SText fontSize={14} fontWeight={700} lineHeight={18} color={'#fff'}>Стать
                                    блогером</SText>
                            </div>
                            <div style={{background: '#FF7834', cursor: 'pointer'}}
                                 className={cn(this.getPartnerFormAClass(), styles.standBtn)} onClick={() => {
                                this.setState({BLOGGER_FORM_OPENED: false})
                                this.fillPartnerForm()
                            }}>
                                <SText fontSize={14} fontWeight={700} lineHeight={18} color={'#fff'}>Стать
                                    партнёром</SText>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            if (parseInt(this.props.USER.BLOGGER_STATUS) == 2) {
                return (
                    <div className="blogger_form_intro">
                        <div className="blogger_circles_holder">
                            <a className="inactive">
                                Стать блогером
                            </a>
                            {this.renderBloggerCircles()}
                        </div>
                        <div className="blogger_form_intro_text">
                            К сожалению, пока что мы не смогли подтвердить ваш статус блогера, но вы находитесь в
                            системе лояльности «Друг».<br/>Как только 10 человек сделают заказ по вашему промокоду, вы
                            получите статус блогера!
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="blogger_form_intro">
                        <a className="extended" onClick={() => this.startBlogging()}>
                            Перейти к статусу блогера
                        </a>
                        <div className="blogger_form_intro_text">
                            Поздравляем, теперь вы наш блогер!<br/>Мы подтверждаем ваш статус блогера.
                        </div>
                    </div>
                );
            }
        }
    }

    renderBloggerCircles = () => {
        let CIRCLES = [];

        for (let i = 1; i < 11; i++) {
            let CLASS = '';
            if (parseInt(this.props.USER.BLOGGER_REFS) >= i) {
                CLASS = ' active';
            }

            CIRCLES.push(<span key={i} className={'blogger_circle' + CLASS}></span>);
        }

        return (
            <div className="bloggers_circles">{CIRCLES}</div>
        );
    }

    recordValue = (input, variable) => {
        this.setState({[variable]: input.target.value});
    }

    renderBloggerForm = () => {
        if (this.state.BLOGGER_FORM_OPENED) {
            return (
                <div className="blogger_form_data">
                    <div className="blogger_form_data_header">
                        Пожалуйста, оставьте заявку.<br/>В течение суток вам придет уведомление на почту.
                    </div>
                    <div className="blogger_form_data_sub_header">
                        Укажите ссылки только на те профили в соцсетях, в которых вы будете делать рекламу
                    </div>
                    <div className="blogger_form_row">
                        <div className="blogger_form_input">
                            <span><img src="/system_images/blogger/name.png"/></span>
                            <input type="text" placeholder="Ваше имя" defaultValue={this.props.USER.NAME}
                                   onChange={(text) => this.recordValue(text, 'BLOGGER_NAME')}/>
                        </div>
                    </div>
                    <div className="blogger_form_row">
                        <div className="blogger_form_input">
                            <span><img src="/system_images/blogger/instagram.png"/></span>
                            <textarea placeholder="ссылка на профиль в Instagram"
                                      onChange={(text) => this.recordValue(text, 'BLOGGER_INSTAGRAM')}/>
                        </div>
                        <div className="blogger_form_input">
                            <span><img src="/system_images/blogger/vk.png"/></span>
                            <textarea placeholder="ссылка на профиль в Вконтакте"
                                      onChange={(text) => this.recordValue(text, 'BLOGGER_VK')}/>
                        </div>
                    </div>
                    <div className="blogger_form_row">
                        <div className="blogger_form_input">
                            <span><img src="/system_images/blogger/youtube.png"/></span>
                            <textarea placeholder="ссылка на профиль в YouTube"
                                      onChange={(text) => this.recordValue(text, 'BLOGGER_YOUTUBE')}/>
                        </div>
                        <div className="blogger_form_input">
                            <span><img src="/system_images/blogger/tiktok.png"/></span>
                            <textarea placeholder="ссылка на профиль в TikTok"
                                      onChange={(text) => this.recordValue(text, 'BLOGGER_TIKTOK')}/>
                        </div>
                    </div>
                    <div className="blogger_form_row">
                        {this.renderAnotherBloggerField()}
                    </div>
                    <div className="blogger_form_row">
                        <div className="blogger_form_input blogger_email">
                            <span><img src="/system_images/blogger/email.png"/></span>
                            <input type="text" placeholder="Ваша почта" defaultValue={this.props.USER.EMAIL}
                                   onChange={(text) => this.recordValue(text, 'BLOGGER_EMAIL')}/>
                        </div>
                    </div>
                    {this.renderBloggersSubmit()}
                </div>
            );
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    renderPartnerForm = () => {
        if (this.state.PARTNER_FORM_OPENED) {
            return (
                <div className="blogger_form_data">
                    <div className="blogger_form_data_header">
                        Пожалуйста, оставьте заявку.<br/>В течение суток вам придет уведомление на почту.
                    </div>
                    <div className="blogger_form_row">
                        <div className="blogger_form_input">
                            <span><img src="/system_images/blogger/name.png"/></span>
                            <input type="text" placeholder="Ваше имя" defaultValue={this.props.USER.NAME}
                                   onChange={(text) => this.recordValue(text, 'BLOGGER_NAME')}/>
                        </div>
                    </div>
                    <div className="blogger_form_row">
                        <div className="partner_form_input">
                            <span>Ссылка на площадку, на которой планируете продвижение</span>
                            <input placeholder="Ссылка на площадку"
                                   onChange={(text) => this.recordValue(text, 'PARTNER_PLATFORM')}/>
                        </div>
                    </div>
                    <div className="blogger_form_row">
                        <div className="blogger_form_input blogger_email">
                            <span><img src="/system_images/blogger/email.png"/></span>
                            <input type="text" placeholder="Ваша почта" defaultValue={this.props.USER.EMAIL}
                                   onChange={(text) => this.recordValue(text, 'BLOGGER_EMAIL')}/>
                        </div>
                    </div>
                    {this.renderPartnerSubmit()}
                </div>
            );
        }
    }

    startBlogging = async () => {
        try {
            let SEND_DATA = {
                action: 'startBlogging',
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {

                this.props.doAuth(data.user);
            }
        } catch (error) {
            console.log('BAD_BONUSES_STORY');
        }
    }

    sendBloggerRequest = async () => {
        try {
            let SEND_DATA = {
                action: 'recordBloggerRequest',
                tiktok: this.state.BLOGGER_TIKTOK,
                youtube: this.state.BLOGGER_YOUTUBE,
                vk: this.state.BLOGGER_VK,
                instagram: this.state.BLOGGER_INSTAGRAM,
                name: this.state.BLOGGER_NAME,
                email: this.state.BLOGGER_EMAIL,
                another: this.state.BLOGGER_ANOTHER,
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                try {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'generate_lead',
                        'form_name': 'Стать блогером',
                    });
                } catch {
                }
                this.setState({BLOGGER_FORM_OPENED: false, BLOGGER_SENT: true});
                this.props.doAuth(data.user);
                this.myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        } catch (error) {
            console.log('BAD_BONUSES_STORY');
        }
    }

    sendPartnerRequest = async () => {
        try {
            let SEND_DATA = {
                action: 'recordPartnerRequest',
                name: this.state.BLOGGER_NAME,
                email: this.state.BLOGGER_EMAIL,
                site: this.state.PARTNER_PLATFORM,
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                try {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'generate_lead',
                        'form_name': 'Стать партнёром',
                    });
                } catch {
                }
                this.setState({PARTNER_FORM_OPENED: false, PARTNER_SENT: true});
                this.props.doAuth(data.user);
                this.myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        } catch (error) {
            console.log('BAD_BONUSES_STORY');
        }
    }

    renderBloggersSubmit = () => {
        if (((this.state.BLOGGER_TIKTOK.length) || (this.state.BLOGGER_YOUTUBE.length) || (this.state.BLOGGER_VK.length) || (this.state.BLOGGER_INSTAGRAM.length) || (this.state.BLOGGER_ANOTHER.length)) && this.state.BLOGGER_NAME && this.validateEmail(this.state.BLOGGER_EMAIL)) {
            return (
                <a className="blogger_submit" onClick={() => this.sendBloggerRequest()}>Отправить заявку</a>
            );
        } else {
            return (
                <a className="blogger_submit inactive">Отправить заявку</a>
            );
        }
    }

    renderPartnerSubmit = () => {
        if (this.state.PARTNER_PLATFORM && this.state.BLOGGER_NAME && this.validateEmail(this.state.BLOGGER_EMAIL)) {
            return (
                <a className="blogger_submit" onClick={() => this.sendPartnerRequest()}>Отправить заявку</a>
            );
        } else {
            return (
                <a className="blogger_submit inactive">Отправить заявку</a>
            );
        }
    }

    renderAnotherBloggerField = () => {
        if (this.state.ADD_ANOTHER_FIELD) {
            return (
                <div className="blogger_form_input">
                    <span className="add_blogger_placeholder"></span>
                    <textarea placeholder="ссылка на профиль в соцсети"
                              onChange={(text) => this.recordValue(text, 'BLOGGER_ANOTHER')}/>
                </div>
            );
        } else {
            return (
                <a className="add_blogger" onClick={() => this.setState({ADD_ANOTHER_FIELD: true})}>
                    <div className="blogger_form_input">
                        <span className="add_blogger_placeholder"></span>
                        <span className="add_blogger_plus_image_holder">
                            <img src="/system_images/blogger/plus.png"/>
                        </span>
                        <span className="add_blogger_text">
                            Добавить аккаунт
                        </span>
                    </div>
                </a>
            );
        }
    }

    getBloggerFormAClass = () => {
        if (this.state.BLOGGER_FORM_OPENED) {
            return 'inactive';
        }
    }

    getPartnerFormAClass = () => {
        if (this.state.PARTNER_FORM_OPENED) {
            return 'inactive';
        }
    }

    fillBloggerForm = () => {
        if (this.state.BLOGGER_FORM_OPENED) {
            this.setState({BLOGGER_FORM_OPENED: false});
        } else {
            this.setState({BLOGGER_FORM_OPENED: true});
        }
    }

    fillPartnerForm = () => {
        if (this.state.PARTNER_FORM_OPENED) {
            this.setState({PARTNER_FORM_OPENED: false});
        } else {
            this.setState({PARTNER_FORM_OPENED: true});
        }
    }

    renderNonBloggerFinals = () => {
        if ((parseInt(this.props.USER.STATUS) !== 4) && ((parseInt(this.props.USER.STATUS) !== 6))) {
            return (
                <div>


                    <div className="bonuses_more_range">
                        <div className="how_else_earn">
                            Получайте экобаллы за каждый заказ!<br/>
                            Чем больше сумма заказа - тем больше экобаллов вы получите! Например,
                        </div>

                        <BonusesSlider/>
                    </div>

                    {this.renderFinalsPretty()}

{/*                    <div className="how_else" ref={this.myRef}>
                        Как ещё заработать экобаллы?
                    </div>
                    {this.renderBecomeABlogger()}*/}

                    <div className="bonus_navs">
                        {this.renderHistory()}
                        {this.renderShare()}
                        <a className="bonus_tabled_link" onClick={() => this.setState({EARN: true})}>
                            Дополнительные экобаллы
                        </a>

                        <a className="bonus_tabled_link" onClick={() => this.setState({HISTORY: true})}>
                            История зачисления баллов
                        </a>
                        <a className="bonus_tabled_link" onClick={() => this.setState({SHARE: true})}>
                            Передать свои экобаллы другу
                        </a>
                    </div>
                </div>
            );
        }
    }

    renderProductsUtm = () => {
        return false;

        let utm = this.props.USER.UTM;
        let share = 'https://ecomarket.ru?utm_source=' + this.props.USER.UTM;
        let small_share = '?utm_source=' + this.props.USER.UTM;

        if (this.state.PRODUCT_USED) {
            small_share = '/' + this.state.PRODUCT_USED + '?utm_source=' + this.props.USER.UTM;
            share = 'https://ecomarket.ru/' + this.state.PRODUCT_USED + '?utm_source=' + this.props.USER.UTM;
        }

        return (
            <div className="utmp_block">

                <div className="promocode_inner_block">
                    <div className="utmp_inner_block_header">
                        Ваша utm-ссылка на товар
                    </div>
                    <div className="utm_inner_block_text">
                        Выберите товар из списка, и мы сгенерируем ссылку на этот товар с вашей utm меткой
                    </div>
                    <div className="utm_product_searcher">
                        <input className="blogger_product_searcher" placeholder="Начните писать название товара"
                               type="text" onKeyDown={(text) => this.searchProduct(text)}/>
                        {this.renderSuggestions()}
                    </div>

                </div>

                <div className="promocode_inner_block margined_utm">
                    <div className="utm_text_block">{small_share}</div>
                    <div className="promocode_inner_block_flex">
                        <a className="copy_btn" onClick={() => navigator.clipboard.writeText(share)}>
                            Копировать
                        </a>
                        <a className="promocode_block_share_button" onClick={() => this.setState({SHARE_PUTM: true})}>
                            <img src="/system_images/share_icon.png"/>
                        </a>
                        {this.renderSharePUtm(share)}
                    </div>
                </div>
            </div>
        );
    }

    renderSuggestions = () => {
        if (this.state.SEARCH) {
            if (this.state.SEARCH.length > 2) {
                let PRODUCTS = this.props.PRODUCTS;
                let FOUND = [];
                let COMPONENT = this;

                let MAX = 10;
                for (let ID in PRODUCTS) {
                    let PRODUCT = PRODUCTS[ID];

                    let STRING = PRODUCT.title.toUpperCase();
                    let COMPARE = this.state.SEARCH.toUpperCase();

                    if (STRING.indexOf(COMPARE) !== -1) {
                        if (FOUND.length <= MAX) {
                            FOUND.push(
                                <span key={PRODUCT.id} className="search-result-row"
                                      onClick={() => this.setState({SEARCH: false, PRODUCT_USED: PRODUCT.url})}>
                                    {PRODUCT.title}
                                </span>
                            );
                        } else {
                            break;
                        }
                    }
                }

                if (FOUND.length) {
                    return (
                        <div className="quick_search_results">
                            {FOUND}
                        </div>
                    );
                }
            }
        }
    }

    searchProduct = (input) => {
        this.setState({SEARCH: input.target.value})
    }

    renderBloggersFinals = () => {
        if ((parseInt(this.props.USER.STATUS) == 4) || ((parseInt(this.props.USER.STATUS) == 6))) {
            return (
                <div>

                    {this.renderShare()}
                    <div className="relative_bloggers_bonuses">
                        <a className="bonus_tabled_link" onClick={() => this.setState({HISTORY: true})}>
                            История зачисления баллов
                        </a>
                        {this.renderHistory()}
                    </div>
                    {this.renderProductsUtm()}
                    {this.renderVisitFilter()}
                    {this.renderStatistics()}
                </div>
            );
        }
    }

    renderStatistics = () => {
        if (this.state.STATISTICS) {
            return (
                <div className="bs_row" ref={this.myRef2}>
                    <div className="bs_row_item">
                        <div className="bs_row_item_row">
                            Покупки по промо:<br/><span className="bs_block">{this.state.STATISTICS.promos}</span>
                        </div>
                    </div>
                </div>
            );
        }
        /*
        if (this.state.STATISTICS) {
            return (
                <div className="bs_row" ref={this.myRef2}>
                    <div className="bs_row_item">
                        <div className="bs_row_header">
                            Количество переходов:
                        </div>
                        <div className="bs_row_item_row">
                            Москва: <span>{this.state.STATISTICS.visits[77]}</span>
                        </div>
                        <div className="bs_row_item_row">
                            Санкт-Петербург: <span>{this.state.STATISTICS.visits[78]}</span>
                        </div>
                        <div className="bs_row_item_row">
                            Казань: <span>{this.state.STATISTICS.visits[16]}</span>
                        </div>
                        <div className="bs_row_item_row">
                            Нижний Новгород: <span>{this.state.STATISTICS.visits[52]}</span>
                        </div>
                    </div>

                    <div className="bs_row_item">
                        <div className="bs_row_header">
                            Количество заказов:
                        </div>
                        <div className="bs_row_item_row">
                            Оформленные заказы:<br/><span className="bs_block">{this.state.STATISTICS.orders}</span>
                        </div>
                        <div className="bs_row_item_row">
                            Покупки по промо:<br/><span className="bs_block">{this.state.STATISTICS.promos}</span>
                        </div>
                    </div>
                </div>
            );
        }
        */
    }

    setStartDate = (input) => {
        this.setState({START_DATE: input.target.value});
        if (this.state.END_DATE) {
            this.getStatistics(input.target.value, this.state.END_DATE);
        }
    }

    setEndDate = (input) => {
        this.setState({END_DATE: input.target.value});
        if (this.state.START_DATE) {
            this.getStatistics(this.state.START_DATE, input.target.value);
        }
    }

    getStatistics = async (START, END) => {
        try {
            let SEND_DATA = {
                action: 'getBloggerStatistics',
                start: START,
                end: END,
                apikey: this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({STATISTICS: data.statistics});

                this.myRef2.current.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        } catch (error) {
            console.log('BAD_BONUSES_STORY');
        }
    }

    renderVisitFilter = () => {
        return (
            <div>
                <div className="bf_header_main">
                    Статистика переходов и заказов
                </div>
                <div className="bf_holder">
                    <div className="bh_header">
                        Выберите период:
                    </div>
                    <div className="bf_row">
                        <div className="bf_row_item">
                            <span>С</span>
                            <input
                                onChange={date => this.setStartDate(date)}
                                type="date"
                                className="date_input_placeholder"
                            />
                        </div>
                        <div className="bf_row_item">
                            <span>по</span>
                            <input
                                onChange={date => this.setEndDate(date)}
                                type="date"
                                className="date_input_placeholder"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderFirstText = () => {
        if ((parseInt(this.props.USER.STATUS) !== 4) && ((parseInt(this.props.USER.STATUS) !== 6))) {
            return (
                <div className="bonuses_second_line">
                    1 экобалл = 1 рубль.<br/>
                    Вы можете использовать экобаллы для оплаты 30% заказа.<br/>
                    Обратите внимание, экобаллы сгорают по истечении 30 дней.
                </div>
            );
        } else {
            return (
                <div className="bonuses_second_line">
                    1 экобалл = 1 рубль.<br/>
                    Экобаллами вы можете оплатить 100% стоимости покупки.<br/>*Обращаем ваше внимание, что только
                    блогерам доступна возможность оплачивать 100% оплаты, покупатели могут оплачивать Экобаллами 50%
                    стоимости.
                </div>
            );
        }
    }

    renderShaders = () => {
        if ((this.state.EARN) || (this.state.HISTORY) || (this.state.SHARE)) {
            return <div className="bonuses_shader"></div>
        }
    }

    renderFirings() {
        if (this.state.EXPIRES) {
            return (
                <div
                    className="bonuses_on_fire">{this.state.EXPIRES.bonuses} {this.num_title(this.state.EXPIRES.bonuses, ['балл', 'балла', 'баллов'])} {this.num_title(this.state.EXPIRES.bonuses, ['сгорит', 'сгорят', 'сгорят'])} через {this.state.EXPIRES.days} {this.num_title(this.state.EXPIRES.days, ['день', 'дня', 'дней'])}</div>
            );
        }
    }

    render() {
        return (

            <div id="bonuses" className="inside active">
                {this.renderShaders()}
                <div className="bonusrow">
                    <div className="lk-intro-row bonus-intro-row">

                        <div className="bonuses_first_line">
                            У
                            вас <span>{this.state.BONUSES}</span> {this.num_title(this.state.BONUSES, ['экобалл', 'экобалла', 'экобаллов'])}
                        </div>
                        {this.renderFirings()}
                        {this.renderFirstText()}
                        {this.renderAnketaBlock()}
                        {this.renderPromocodeBlock()}

                        {this.renderUTMBlock()}

                        {this.renderNonBloggerFinals()}

                        {this.renderBloggersFinals()}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Bonuses);