import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import OrderingGroupConfirmation from './../../../APP/COMPONENTS/ContentParts/OrderingGroupConfirmation.js';


function mapStateToProps(state) {
    return {
        USER: state.USER,
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderingConfirmation extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            GROUPED_PRODUCTS: false
        };
    }

    componentDidMount = () =>
    {
        this.groupProductsBySupplier()
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.SELECTED !== prevProps.SELECTED) {
            this.groupProductsBySupplier()
        }
    }

    getSupplier = (id, supplier, suppliers) =>
    {
        if (typeof this.props.SELECTED[id] !== "undefined") {
            if (typeof this.props.SELECTED[id].supplier_title !== "undefined") {
                supplier = this.props.SELECTED[id].supplier_title
            }
        }

        let useSupplier = suppliers.filter(sup => {
            if (sup.title == supplier) {
                return true
            }

            return false
        })

        if (useSupplier.length) {
            return useSupplier[0]
        }

        return false
    }

    groupProductsBySupplier = () =>
    {
        let GROUPED_PRODUCTS = []
        let j = 1;
        for (let id in this.props.SELECTED) {
            let SELECTED = this.props.SELECTED[id]
            if (SELECTED.confirmed) {
                let PRODUCT = this.props.PRODUCTS[id]
                let SUPPLIER = this.getSupplier(id, PRODUCT.supplier, this.props.SUPPLIERS)
                
                if (typeof GROUPED_PRODUCTS[SUPPLIER.title] == "undefined") {
                    GROUPED_PRODUCTS[SUPPLIER.title] = {
                        numb: j,
                        id: SUPPLIER.id,
                        title: SUPPLIER.title,
                        supplier: SUPPLIER,
                        products: [
                            {
                                id: PRODUCT.id,
                                days: SELECTED.days,
                                amount: SELECTED.amount,
                                title: PRODUCT.title,
                                sup_price: PRODUCT.pure_sup_price,
                                confirmed: false,
                                product: PRODUCT
                            }
                        ]
                    }

                    j++
                } else {
                    GROUPED_PRODUCTS[SUPPLIER.title].products.push({
                        id: PRODUCT.id,
                        days: SELECTED.days,
                        amount: SELECTED.amount,
                        title: PRODUCT.title,
                        sup_price: PRODUCT.pure_sup_price,
                        confirmed: false,
                        product: PRODUCT
                    })
                }
            }
        }

        if (Object.keys(GROUPED_PRODUCTS).length > 0) {
            this.setState({GROUPED_PRODUCTS: GROUPED_PRODUCTS})
        } else {
            this.props.closeConfirmation()
        }
    }

    renderGroups = () =>
    {
        let GROUPS = [];

        for (let i in this.state.GROUPED_PRODUCTS) {
            GROUPS.push(
                <OrderingGroupConfirmation
                    key={i}
                    DATA={this.state.GROUPED_PRODUCTS[i]}
                    SUPPLIERS_OPTIONS={this.props.SUPPLIERS_OPTIONS}
                    ALL_SUPPLIERS={this.props.SUPPLIERS}
                    resetSupplierProducts={this.props.resetSupplierProducts}
                    setSupplierToOrdering={this.props.setSupplierToOrdering}
                    setAnotherSupplierToOrdering={this.props.setAnotherSupplierToOrdering}
                />
            )
        }

        return GROUPS
    }

	render()
    {
        return (
            <div className="or_modal">
                <div className="or_overlay" onClick={() => this.props.closeConfirmation()}></div>
                <div className="or_body greyed">
                    <a className="close-modal" onClick={() => this.props.closeConfirmation()}>
                        <img src="/system_images/close.png" />
                    </a>
                    <h4>Формирование заказа</h4>
                    {this.state.GROUPED_PRODUCTS ?
                        this.renderGroups()
                        : null
                    }
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderingConfirmation);