import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import ConfirmCardProduct from './../../APP/COMPONENTS/ContentParts/ConfirmCardProduct';
import ConfirmCardProductClean from './../../APP/COMPONENTS/ContentParts/ConfirmCardProductClean';

import MapHelperShown from "./../../APP/HELPERS/MapHelperShown"
import MapHelper from "./../../APP/HELPERS/MapHelper"

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        TOKEN: state.TOKEN
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderConfirm extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            ORDER_TOKEN: props.match.params.order_token,
        	ORDER: false,
            ERROR: false,
            SET_ADRESS: '',
            SET_GPS: '',
            DEL_SUM: 0,
            SUM: 0,
            TOKEN: props.TOKEN,
            DEL_ADDON: 0,
            MKAD: 0,
            CHANGE_ADRESS: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.match.params.order_token !== prevProps.match.params.order_token) {
            this.props.closeAllModals();
            this.setState({ORDER: false, ORDER_TOKEN: this.props.match.params.order_token, ERROR: false});
            this.getData();
        }
    }

    componentDidMount()
    {
        this.getData();
    }

    getData = async () =>
    {
        try {
            let SEND_DATA = {
                action:         'getOrderToConfirmByToken',
                order_token:     this.state.ORDER_TOKEN,
                token:           this.state.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.checkOrderStatus(data);
            } else {
                this.setState({ERROR: true, ORDER: []});
            }
        } catch (error) {
            console.log(error)
        }
    }

    checkOrderStatus(order)
    {
        if (order.STATUS > 0) {
            this.setState({ERROR: true, ORDER: []});
        } else {
            this.setState({ORDER: order, ERROR: false, SET_ADRESS: order.ADRESS, SET_GPS: order.GPS, SUM: order.SUM, DEL_SUM: (parseInt(order.DEL_SUM) - parseInt(order.DEL_ADDON)), DEL_ADDON: order.DEL_ADDON});
        }
    }

    renderH1()
    {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="confirm_head">
                        Подтверждение заказа
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });
        DATA.push({
            'to' : '/oc/' + this.state.ORDER_TOKEN,
            'title' : 'Подтверждение заказа'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    renderProducts()
    {
        if (this.state.ORDER) {
            let ORDER = this.state.ORDER;

            let RENDER_PRODUCTS = [];
            let COMPONENT = this;

            for (let i in ORDER.PRODUCTS) {
                let PRODUCT = ORDER.PRODUCTS[i];
                if (typeof this.props.PRODUCTS[i]) {
                    let USE_PRODUCT = this.props.PRODUCTS[i];

                    if (typeof USE_PRODUCT !== "undefined") {
                        USE_PRODUCT.SUM = PRODUCT.SUM;
                        USE_PRODUCT.AMOUNT = PRODUCT.AMOUNT;
                        let READY_PRODUCT = (
                            <ConfirmCardProduct PRODUCT={USE_PRODUCT} key={'order_product_card_' + i} />
                        );

                        RENDER_PRODUCTS.push(READY_PRODUCT);
                    }
                } else {
                    let READY_PRODUCT = (
                        <ConfirmCardProductClean key={'order_product_card_' + i} product={PRODUCT} />
                    );

                    RENDER_PRODUCTS.push(READY_PRODUCT);
                }
            }

            if (RENDER_PRODUCTS) {
                return (
                    <div className="confirm_products">
                        <h5>Товары</h5>
                        {RENDER_PRODUCTS}
                    </div>
                );
            }
        }
    }

    getPaidTitle(STATUS)
    {
        switch (parseInt(STATUS)) {
            case 0:
                return 'Не оплачен';
                break;
            case 1:
                return 'Оплачен';
                break;
            case 2:
                return 'Средства зарезервированы';
                break;
            case 3:
                return 'Карта подтверждена';
                break;
        }
    }

    getStatusTitle(status)
    {
        let status_title = '';

        switch (status)
        {
            case 'created':
                status_title = 'принят';
                break;
            case 'handed_over_for_picking':
                status_title = 'собран';
                break;
            case 'on_the_way':
                status_title = 'в пути';
                break;
            case 'delivered':
                status_title = 'доставлен';
                break
            default:
                status_title = 'отменён';
                break;
        }

        return status_title;
    }

    renderPayBtn()
    {
        let ORDER = this.state.ORDER;

        if ((ORDER.PAID == 0) && (ORDER.PAYMENT == 3) && (ORDER.STATUS !== 4)) {
            return <a className="orange-button" onClick={() => this.props.doPay(this.state.ORDER.ID)}>Оплатить</a>
        }
    }

    renderStatusLine()
    {
         
    }

    renderBonus()
    {
        if (this.state.ORDER.BONUS_SUM < 1) {
            return false;
        } else {
            return (
                <div className="or_data_row">
                    <div className="or_data_row_left">
                        Вы использовали экобаллы:
                    </div>
                    <div className="or_data_row_right">
                        - {this.state.ORDER.BONUS_SUM} р
                    </div>
                </div>
            );
        }
    }

    renderCoupon()
    {
        if (this.state.ORDER.COUP_SUM < 1) {
            return false;
        } else {
            return (
                <div className="or_data_row">
                    <div className="or_data_row_left">
                        Вы применили промокод:
                    </div>
                    <div className="or_data_row_right">
                        - {this.state.ORDER.COUP_SUM} р
                    </div>
                </div>
            );
        }
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    prepareToCount(NUMB)
    {
        let RETURN = 0;

        if (NUMB > 0) {
            if(NUMB % 1 !== 0){
                RETURN = parseFloat(this.rmFixed(Number(NUMB)));
            } else {
                RETURN = parseInt(Number(NUMB).toFixed());
            }
        }

        return RETURN;
    }

    renderProductsSum()
    {
        let SUM = this.prepareToCount(this.state.ORDER.SUM);
        let COUP_SUM = this.prepareToCount(this.state.ORDER.COUP_SUM);
        let BONUS_SUM = this.prepareToCount(this.state.ORDER.BONUS_SUM);
        let SHOW_SUM = SUM + COUP_SUM + BONUS_SUM;

        return (
            <div className="or_data_row">
                <div className="or_data_row_left">
                    Стоимость товаров:
                </div>
                <div className="or_data_row_right">
                    {SHOW_SUM} р
                </div>
            </div>
        );
    }

    renderTotalSum()
    {
        let SUM = this.prepareToCount(this.state.ORDER.SUM);
        let COUP_SUM = this.prepareToCount(this.state.ORDER.COUP_SUM);
        let BONUS_SUM = this.prepareToCount(this.state.ORDER.BONUS_SUM);
        let DEL_SUM = this.prepareToCount(this.state.DEL_SUM) + this.prepareToCount(this.state.DEL_ADDON);
        let SHOW_SUM = SUM  + DEL_SUM;
        
        return (
            <div className="or_data_row">
                <div className="or_data_row_left_bold">
                    Итого:
                </div>
                <div className="or_data_row_right_bold">
                    {SHOW_SUM} р
                </div>
            </div>
        );
    }

    renderDeliverySum()
    {
        let SHOW = 'бесплатно';

        if ((this.state.DEL_SUM > 0) || (this.state.DEL_ADDON > 0)) {
            let DEL_SUM = this.prepareToCount(this.state.DEL_SUM) + this.prepareToCount(this.state.DEL_ADDON);
            SHOW = DEL_SUM + ' р';
        }

        return (
            <div className="or_data_row">
                <div className="or_data_row_left">
                    Сумма доставки:
                </div>
                <div className="or_data_row_right">
                    {SHOW}
                </div>
            </div>
        );
    }


    renderChips()
    {

    }

    renderFinals()
    {
        return (
            <div>
                <div className="or_data_row_number">
                    <div>
                        Номер заказа:
                    </div>
                    <div className="or_data_row_number_bold">
                        {this.state.ORDER.NUMBER}
                    </div>
                </div>

                <div className="confirm_finals">
                    {this.renderChips()}
                    {this.renderProductsSum()}
                    {this.renderDeliverySum()}
                    {this.renderCoupon()}
                    {this.renderBonus()}
                    {this.renderTotalSum()}
                </div>
                <div className="confirm_separator">
                </div>
            </div>
        );
    }

    setAdressAndGps = (adress, gps) =>
    {
        this.mapHelper.checkAdress(adress);
        ///this.setState({SET_ADRESS: adress, SET_GPS: gps});
    }

    setCheckedAdress = (data) =>
    {

    }

    mapLoaded = (data) =>
    {

    }

    setMapHelper = (node) =>
    {
        this.mapHelper = node;
    }

    countAppendDelivery()
    {
        if (this.state.MKAD > 0) {
            let km = this.state.MKAD;
            let sum_default = this.state.SUM;
            let default_delivery_price = this.state.DEL_SUM;

            let SET_SUM = 0;
        
            if (sum_default < 8500) {
                if (km < 30) {
                    SET_SUM = km * 30;
                } else {
                    SET_SUM = km * 50;
                }
            } else {
                if (km > 30) {
                    var use_km = km - 30;
                    SET_SUM = use_km * 50;
                }
            }
        
            let mkad = km;
            let delivery_add = 0;

            if (SET_SUM > 0) {
                delivery_add = SET_SUM;
            }
            
            this.setState({DEL_ADDON: delivery_add});
        } else {
            this.setState({DEL_ADDON: 0});
        }
    }

    doConfirm = async () => 
    {
        try {
            let SEND_DATA = {
                action:         'doOrderConfirmByToken',
                order_token:     this.state.ORDER_TOKEN,
                token:           this.state.TOKEN,
                gps:             this.state.SET_GPS,
                adress:          this.state.SET_ADRESS,
                del_addon:       this.state.DEL_ADDON,
                del_sum:         this.state.DEL_SUM,
                mkad:            this.state.MKAD
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.getData();
            }
        } catch (error) {
            console.log(error)
        }
    }

    setCheckedAdress = (result, adress, gps, mkad) =>
    {
        if (result) {
            this.setState({SET_ADRESS: adress, SET_GPS: gps, MKAD: mkad});
            this.countAppendDelivery()
        } else {

            this.setState({SET_ADRESS: 'Адрес определить не получилось!', SET_GPS: gps, MKAD: mkad});
        }
    }

    renderButton()
    {
        if (
            (this.state.SET_GPS !== '') 
            && 
            (this.state.SET_ADRESS !== 'Адрес определить не получилось!') 
            && 
            (
                (this.state.SET_GPS[0] !== 59.93309218303942) 
                &&  
                (this.state.SET_GPS[1] !== 30.310671271484367)
            ) 
            && 
            (
                (this.state.SET_GPS[0] !== 55.754638) 
                &&  
                (this.state.SET_GPS[1] !== 37.621633)
            )
        ) {
            return (
                <div className="confirm_page_button">
                    <button className="orange-button" onClick={() => this.doConfirm()}>
                        Подтвердить
                    </button>
                </div>
            );
        } else {
            return (
                <div className="confirm_page_button">
                    <button className="orange-button inactive-btn">
                        Подтвердить
                    </button>
                </div>
            );
        }
    }

    renderComment = () =>
    {
        if (this.state.ORDER.COMMENT !== "") {
            return (
                <div className="confirm_comment">
                    Комменатрий к заказу:<br/>
                    {this.state.ORDER.COMMENT}
                </div>
            );
        }
    }

    mapChangeClass()
    {
        if (this.state.CHANGE_ADRESS) {
            return 'map_shown';
        } else {
            return 'map_hidden';
        }
    }

    confirmThisAdress = () =>
    {
        this.setState({CHANGE_ADRESS: false});
    }

    renderAdressData()
    {
        return (
            <div className="confirm_adress">
                <h5>Адрес</h5>
                <div className="confirm_adress_holder">
                    <div className="confirm_adress_holder_left">
                        <div className="confirm_adress_bold">
                            Адрес доставки: {this.state.SET_ADRESS}
                        </div>
                        {this.renderComment()}
                        <a className="confirm_change" onClick={() => this.setState({CHANGE_ADRESS: true})}>Изменить</a>
                    </div>
                    <div className="confirm_adress_holder_right">
                        <a onClick={() => this.setState({CHANGE_ADRESS: true})}>
                            <img src="/system_images/map_layer.png" />
                        </a>
                    </div>
                </div>

                <MapHelperShown 
                    confirmThisAdress={this.confirmThisAdress}
                    CONFIRM={true}
                    CLASS={this.mapChangeClass()} 
                    CENTER={this.state.ORDER.GPS} 
                    TYPE="GET_CENTER" 
                    setAdressAndGps={this.setAdressAndGps} 
                    REGION={this.state.ORDER.REGION}
                />
                <MapHelper 
                    CENTER={[55.70849105231936, 37.59300249013515]}  
                    ref={this.setMapHelper} 
                    GPS={true} 
                    REGION={this.state.ORDER.REGION}
                    setSuggestions={this.setSuggestions} 
                    mapLoaded={this.mapLoaded} 
                    setCheckedAdress={this.setCheckedAdress} 
                />
            </div>
        );
    }

	render() {
        if (!this.state.ORDER) {
            return <InnerLoader />
        }

        if (this.state.ERROR) {
            return (
                <CSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                > 
                    <div className="right-part transparent">
                        <Helmet>
                            <title>Подтверждение заказа</title>
                            <meta name="description" content="Подтверждение заказа" />
                            <meta name="keywords" content="Подтверждение заказа" />
                        </Helmet>
                        {this.renderBreadCrumbs()}
                        <div id="page-content" className="page-content">
                            <div className="main-container inner container-fluid">
                                {this.renderH1()}

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="content-box">
                                            <h5>Заказ успешно подтверждён!</h5>
                                            <Link to={"/"} className="btn btn-main">На главную</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalsContainer />
                    </div>
                    <FooterBlock />
                </CSSTransitionGroup>
            );
        } else {
        	return (
                <CSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                > 
            		<div className="right-part">
                        <Helmet>
                            <title>Подтверждение заказа</title>
                            <meta name="description" content="Подтверждение заказа" />
                            <meta name="keywords" content="Подтверждение заказа" />
                        </Helmet>
                        <div id="page-content" className="page-content">
                            <div className="main-container inner container-fluid">
                                {this.renderH1()}
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.renderFinals()}
                                        {this.renderAdressData()}
                                        <div className="confirm_separator"></div>
                                        {this.renderProducts()}
                                        {this.renderButton()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalsContainer />
        			</div>
                    <FooterBlock />
                </CSSTransitionGroup>
    		);
        }
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(OrderConfirm))