import React from "react";
import { text } from "./mixins";

export default ({
                    fontSize,
                    fontWeight,
                    color = '#000',
                    fontType,
                    gap,
                    style={},
                    className='',
                    largerGap,
                    lineHeight,
                    div=false,
                    ...props
                }) => {
    return div ?
        <div className={className}
             style={{
                 ...text(parseInt(fontSize), fontWeight, fontType, gap, largerGap, lineHeight),
                 ...style,
                 color: color,
             }}
        >{props.children}</div>
        : <span className={className}
                style={{
                    ...text(parseInt(fontSize), fontWeight, fontType, gap, largerGap, lineHeight),
                    ...style,
                    color: color,
                }}
        >{props.children}</span>;
}