import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import MobileLocationData from "../../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

function mapStateToProps(state, myProps) {
    return {
        REGION: state.REGION
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Suppliers extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            REGION: props.REGION
        }
    }

    componentDidMount()
    {
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    handleScroll(event) {

    }


    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });

        DATA.push({
            'to' : '/company/suppliers',
            'title' : 'Наши поставщики'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    getTitle = () =>
    {
    	switch (this.state.REGION) {
			default:
    			return 'Наши поставщики';
    			break;
    	}
    }

    getKeywords = () =>
    {
    	switch (this.state.REGION) {
    		default:
    			return 'Наши поставщики';
    			break;
    	}
    }

    getDescription = () =>
    {
    	switch (this.state.REGION) {
    		default:
    			return 'Наши поставщики';
    			break;
    	}
    }


    renderContent()
    {
    	return (
    		<div className="static_page_body">
    			      <section className="providers">
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/eco-farm.png"
              alt="Эко-ферма Рябинки"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">Эко-ферма Рябинки</h3>
          <p className="providers__about">
            Эко-ферма Рябинки - органическое фермерское хозяйство. На сегодня
            Эко-ферма Рябинки один из немногих в России производитель Био
            продуктов, получившая европейский Биосертификат на широкий
            ассортимент продукции. Евролисток - единый знак ЕС, используемый для
            маркировки органических экологически чистых продуктов. В хозяйстве
            более 1250,78 не сельхозземель. Поля расположены в 145 км от Москвы,
            в благоприятном для экологии районе. <br/ >Недавно Артур, вместе с
            Экомаркетом посетили замечательную Эко-ферму Рябинки, о чем мы
            расскажем и покажем в данном ролике)
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/bogimovo.png"
              alt="История в Богимово"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">История в Богимово</h3>
          <p className="providers__about">
            Ферма Богимово - ещё один фермерский производитель органической
            продукции. Ферма расположена в 40 км от Калуги. Вся молочная
            продукция получается из молока породы Джерси (родом из Дании) и
            самое главное продукция Фермы Богимово имеет Европейский
            экосертификат выданный немецким институтом. В ассортименте продукции
            Фермы Богимово большое количество различной молочной продукции,
            отличающиеся нежным вкусом и натуральностью. Экомаркет посетил с
            экскурсией ферму Богимово, где мы узнали много нового и интересного,
            о чем Артур расскажет в сюжете )
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/field.png"
              alt="Горчичная Поляна"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">Горчичная Поляна</h3>
          <p className="providers__about">
            Горчичная Поляна - это абсолютно натуральные сезонные овощи и свежее
            мясо, сертифицированное в соотвествии с требованиями экологического
            производства Европейского союза. Горчичная Поляна - это 1200
            гектаров, исключительно чистые воздух, земля и вода, не знающие
            промышленных и транспортных выборосов. Вся продукция, начиная от
            мясных деликатесов, заканчивая овощами - сертифицирована российской
            компанией Эко-Контроль, в соотвествии с высокими европейскими
            экологическими стандартами. На этот раз, Экомаркет посетил Ферму
            Горчичная Поляна, вас ожидает занимательная история, которую
            поведает вам Артур.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/ufeelgood.png"
              alt="UFEELGOOD"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">UFEELGOOD</h3>
          <p className="providers__about">
            UFEELGOOD - это один из крупнейших поставщиков, производителей и
            ритейлеров органических продуктов, натуральной косметики и
            суперфудов со всего мира. Компания сотрудничает с крупными
            федеральными сетями, крупными оптовыми клиентами и активно развивает
            собственные розничные продажи через интернет-магазин и отдельные
            эко-бутики полезного питания. Каждая упаковка продукции UFEELGOOD
            сделана в уникальном дизайне с использованием передовых технологий и
            с большой любовью. Компания занимается поиском и разработкой новых
            полезных продуктов, чтобы иметь возможность помогать людям, которые
            хотят сохранить физическое и духовное здоровье. Компания UFEELGOOD
            собрала множество различных товаров для вашего здоровья, которые
            объединяет натуральность, органичность и польза. Это экопродукты,
            натуральная косметика и органические суперфуды со всего мира.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/ecotopia.png"
              alt="Экотопия"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">Экотопия</h3>
          <p className="providers__about">
            Экотопия – это команда единомышленников, компания-производитель и
            поставщик экотоваров и качественных, проверенных продуктов. Команда
            Экотопии производит и распространяет продукты, способствующие
            всеобщему исцелению, сохранению жизни, счастью, восстановлению и
            сохранению окружающей среды, оздоровлению духа, тела, разума и
            окружающего пространства. Для компании важно объединить всех, кто
            разделяет ценности всеобщего благополучия людей, планеты и мира, а
            также донести идею, что положительные изменения происходят изнутри.
            Поэтому Экотопия делает акцент на развитии и здоровье каждой
            личности ради общей благотворной трансформации. Магазины полезных
            продуктов Экотопии на данный момент существуют в Москве и
            Санкт-Петербурге. Они поставляют вегетарианскую еду, в которой
            сохранены все жизненные силы продуктов и полностью проявлены их
            естественные вкусовые качества, а также сохранены все минералы и
            микроэлементы, витамины и энзимы, энергия солнца и воды. Среди их
            продукции максимально натуральные товары, произведенные без
            применения химических консервантов или с минимальным их
            использованием, выращенные по возможности в экологически чистых
            районах.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/sasha-bread.png"
              alt="Sasha Bread"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">Эко-ферма Рябинки</h3>
          <p className="providers__about">
            Sasha Bread - это пекарня, хозяйкой и учредительницей которой
            является Дарья Мясищева. В какой-то момент девушка начала печь хлеб
            дома и параллельно работала в кафе, где занималась в том числе и
            выпечкой. Как-то раз одна из фотографий буханок была опубликована в
            Facebook. Эта фотография привлекла внимание директора международного
            гастрономического фестиваля "Оmnivore". Затем было знакомство с
            владельцами пекарни "Главхлеб", которые разрешили воспользоваться их
            оборудованием, кулинарный фестиваль "Новая жизнь", покупка
            собственного оборудования и создание пекарни. Сейчас пекарня
            производит более 500 буханок в день, а продажи в декабре, в конце
            года, увеличиваются на 20-25%. Для самой Дарьи всегда главную роль
            играло качество исходных продуктов, а конкретные рецепты, как
            говорит девушка, она берет из интернета, Инстаграмма и прочих
            ресурсов, просто рассматривая разные фото хлеба и решая, какой
            больше всего нравится. Сейчас хлеб печется на ржаной закваске, на
            пшеничной закваске, на закваске с добавлением конопляной муки, при
            этом 2/3 выпекаемого нами хлеба – на закваске, и 1/3 ассортимента -
            на дрожжах. Поставляется хлеб Sasha Bread в приблизительно 2 десятка
            магазинов Москвы и Московской области.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/juravlev.png"
              alt="Хлебобулочная мануфактура Журавлевых"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">
            Хлебобулочная мануфактура Журавлевых
          </h3>
          <p className="providers__about">
            Хлебобулочная мануфактура Журавлевых - это молодое, активное
            семейное предприятие с производственным цехом в центре Москвы.
            Мануфактура обладает самым новым и самым лучшим оборудованием для
            производства хлебобулочной продукции. На данный момент, у компании
            существует собственная сеть точек по Москве, а также она работает с
            HoReCa (рестораны и кафе города Москвы). Кредо мануфактуры
            Журавлевых - профессиональное качество с личной ответственностью.
            Они делают и хотят делать качественный и вкусный хлеб из
            качественного сырья за доступные цены. При этом в производстве
            хлебобулочных изделий не используются смеси и заготовки, а
            производители сами разрабатывают и изготавливают вкусную продукцию.
            Хлеб от мануфактуры Журавлевых - это это натуральный продукт ручной
            работы, более 10 лет опыта пекарей, современное европейское
            оборудование и команда людей, влюбленных в свое дело. Сейчас
            мануфактура сотрудничает с семейными кафе, фермерскими магазинами,
            розничными сетями, винными магазинами, а также крупнейшими
            кейтеринговыми компаниями, входящими в топ-5 Москвы.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/volkomolko.png"
              alt="VolkoMolko"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">VolkoMolko</h3>
          <p className="providers__about">
            VolkoMolko – это приготовленные с любовью вкусные и натуральные
            продукты, без использования компонентов животного происхождения,
            консервантов и вредных растительных жиров. Эта компания стремится
            получить максимум вкуса и пользы из растений, без причинения вреда
            как человеческому организму, так и другим обитателям нашей планеты.
            Продукция VolkoMolko – это растительные аналоги молочных продуктов,
            продукты без лактозы и холестерина, полностью на растительной основе
            и для здорового питания. Компания производит веганские сыры и
            колбасы, ореховое молоко, соевую сгущенку, хумусы и паштеты,
            ореховое печенье и джемы без сахара. VolkoMolko - первая российская
            компания, которая запустила такой продукт, как веганские сыры, в
            России. Данный продукт - настоящая гордость производителя. Сейчас в
            линейке 7 разных видов, но компания не собирается на этом
            останавливаться и по праву считает, что эта линейка - самый широкий
            ассортимент сыров для вегетарианцев не только в России, но и в мире.
            Производство и главный штаб VolkoMolko находятся в Москве. Также они
            поставляют свою продукцию практически во все регионы страны, а также
            в Белоруссию, Украину и Казахстан.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/altagamma.png"
              alt="Altagamma"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">Altagamma</h3>
          <p className="providers__about">
            «Жизнь слишком коротка, чтобы употреблять некачественные сыры и
            использовать плохое оливковое масло», – считает основатель и
            владелец компании Altagamma Food Донато Паризи. Altagamma - это сеть
            сыроварен высокого качества в России и зарубежом. В данный момент
            действует сеть сыроварен итальянских сыров в таких городах, как
            Москва, Санкт-Петербург, Екатеринбург, Баку (Азербайджан), Перт
            (Австралия), прорабатываются проекты в Тбилиси (Грузия) и Киеве
            (Украина). Также сыроварня уже более 7 лет занимается производством
            и поставками продуктов из Италии. Ежедневно в московской сыроварне
            производится около двухсот килограммов высококачественных сыров. В
            Россию Донато Паризи приехал пять лет назад вместе со своей женой
            Татой Чинчаладзе. Сначала сыродел поставлял в Россию нишевую
            региональную итальянскую продукцию. Семья мечтала привезти в нашу
            страну все самое ценное, что производится в Италии, и хотела, чтобы
            на столах у москвичей была такая же продукция, что и у итальянцев.
            Из различных регионов Италии Паризи выбирал нишевые продукты самого
            высокого качества. В этот период он познакомился со своей женой
            Татой, коммерческим директором компании, которая завозила на
            российский рынок высококачественные гастрономические товары лучших
            производителей Европы. В 2014 году, после введенных Россией санкций
            и ограничения импорта ряда товаров, Паризи с женой открыли
            сыроварню, что позволило местным потребителям есть нежнейшую
            рикотту, моцареллу, буррату, скаморцу, произведенные уже в России.
            Altagamma Food производит мягкие, невыдержанные сыры, которые
            хранятся от 10 до 14 дней: рикотта, моцарелла, буррата, скаморца,
            тречча, нодини, сфолья и другие. Всего у сыроварни в ассортименте
            десять видов сыров, но производство новых сортов не стоит на месте.
            Интересно, что весь процесс приготовления сыра происходит вручную,
            машины только греют молоко. Ферма, с которой оно поставляется,
            располагается в Калужской области, где занимаются изготовлением
            детского питания. Молоко для сыра Altagamma дают коровы джерсейской
            породы (английское происхождение). Технология на ферме также из-за
            рубежа. Все остальные ингредиенты для производства сыров, включая
            закваску известной марки «Клеричи» и итальянскую соль,
            дистрибьюторские компании привозят сыроварне из Италии. Так, в
            течение дня изготавливается около 150–180 килограммов различных
            сыров. Помимо производства сыров Altagamma занимается поставками
            оливкового масла, бальзамического уксуса, пасты, неаполитанского
            шоколадного драже и других продуктов. Из каждого региона Италии они
            выбирают самое лучшее, позволяя россиянам попробовать настоящую и
            самую лучшую итальянскую продукцию.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/vkusnotelo.png"
              alt="Вкуснолето"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">Вкуснолето</h3>
          <p className="providers__about">
            Вкуснолето - это производство качественных и полезных продуктов из
            натуральных компонентов для здорового образа жизни. Специализируется
            производитель на меде и медовых десертах, но также изготавливает
            шоколад и шоколадно-ореховые пасты. Для своих десертов Вкуснолето
            собирают мед в Ленинградской области, а еще закупают его с частных
            пасек по всей стране, постоянно экспериментируя с множеством сортов
            меда, которых существует аж более 50-ти! В результате получается
            вкуснейший крем-мед, в который производитель добавляет различные
            ингредиенты: ягоды, фрукты, орехи и даже такие компоненты, как мята
            или красный жгучий перец! Своей продукцией Вкуснолето помогает людям
            заботиться о здоровье, укрепляя ослабленный от экологии больших
            городов и частых эпидемий иммунитет. Ведь мед - это натуральное
            природное лекарство, которое приносит пользу всем - взрослым, детям
            и пожилым людям. И он не только полезный, но еще и вкусный.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/milzu.png"
              alt="MILZU!"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">MILZU!</h3>
          <p className="providers__about">
            MILZU! является производителем самых здоровых зерновых продуктов,
            которые превращают рожь в совершенно особенное блюдо. Создатели
            компании вдохновлены тенденцией нашего времени – питаться правильно,
            просто и легко. MILZU! - это семейная компания, которая начала
            производство здоровых цельнозерновых хлопьев для завтрака в сентябре
            2014 года в Латвии. Сухие завтраки изготавливаются из натуральных
            или органических ингредиентов, богатых клетчаткой и белком. MILZU!
            ценят за то, что в их продукции рожь является основным ингредиентом,
            все товары имеют высокую пищевую ценность (особенно клетчатка и
            белок) при низком содержании сахара или же вовсе без добавления
            сахара, как и жиров и E-добавок. Продукция производителя содержит
            витамины натурального происхождения, сохраненные из исходных
            компонентов, что позволяет MILZU! повышать уровень приверженности к
            здоровому питанию, творческому мышлению и целенаправленной жизни у
            большего и большего количества людей.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img src="" alt="" className="providers-info__logo" />
          </div>
          <h3 className="providers__title">Фермер Михаил Понарский</h3>
          <p className="providers__about">
            Фермер Михаил Понарский вот уже несколько лет работает почти без
            выходных. Он производит сыры и молочные продукты в Истринском
            районе. В молочном производстве его основной задачей было полностью
            разработать технологический процесс. По словам Михаила, сварить
            вкусный сыр дома – не проблема, а вот выйти на промышленную
            рецептуру не так просто: «Самое трудное – это повторить вкус того
            сыра, который получился у тебя в первый раз. Плюс-минус полградуса –
            и у сыра совсем другой вкус». Простые сыры, вроде адыгейского или
            сулугуни, фермера не интересуют. Он пошел по другому пути, и сделал
            ставку на сложные рецептуры. Принципиальная позиция производства
            Михаила Понарского такая: все только натуральное! В этом самое
            большое преимущество фермерской продукции. Сейчас ферма Михаила
            Понарского производит не только сыр, но и сметану, творог, йогурт,
            масло, кефир и другую молочную продукцию. Все продукты
            перерабатываются в специальных ёмкостях из пищевой нержавейки и
            хранятся в специальных промышленных холодильниках с постоянным
            заданным температурным режимом.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/organic_around.png"
              alt="Органик Эраунд"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">Органик Эраунд</h3>
          <p className="providers__about">
            ORGANIC AROUND — это производитель органической продукции с собственными сельскохозяйственными угодьями в Ставропольском крае.
            Компания производит по-настоящему полезные и безопасные продукты, самостоятельно контролируя все этапы производства: от выбора семян до упаковки. Такой метод гарантирует высокое качество продукции и обеспечивает оптимальную себестоимость продукта и его доступную цену в нашем магазине.
            Ценности данного производителя являются основой выпускаемой им продукции и отражают идеологию ORGANIC - используется вода горной реки для орошения земель, собирается урожай ручным способом или же с помощью биотоплива для сельхозтехники, а производственные отходы утилизируются. Это причины, по которым продукция ORGANIC AROUND сертифицирована по Европейскому стандарту органики.
            Для клиентов «Экомаркет.ру» компания производит следующие органические продукты:
            - Консервированный молодой органический зелёный горошек высшего сорта. Этот нежный и сладкий продукт - низкокалорийный, полезный и питательный. Он богат витаминами и минералами. Произведён без добавления сахара!
            - Томатный сок. Изготовлен из отборных и спелых органических томатов. Отличается густой консистенцией и насыщенным вкусом с приятной кислинкой. Рекомендуется употреблять самостоятельно или в составе коктейлей. Томатный сок богат витамином С, солями калия и магния. Не содержит соли и сахара!
            - Тыквенный сок однородной нежной консистенции. Обладает тонким ароматом и насыщенным сладким вкусом. Тыква - прекрасный источник каротина, витаминов группы А, Е, С и В. Но главное преимущество продукта состоит в большой концентрации редкого витамина К, который можно получить в полном объеме только из этого овоща. Изготовлен сок без добавления сахара.
            - Томатная паста. Для производства продукта используются только самые спелые и сладкие органические помидоры. Их пропускают через специальный сетчатый просеиватель, чтобы получить концентрированную томатную пасту 25% с ярким вкусом. Продукт без добавления соли, сахара и специй – чистые томаты в каждой ложке!
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/white_h.png"
              alt="Белая Дача"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">АО «Белая Дача Трейдинг»</h3>
          <p className="providers__about">
            Белая Дача начала свою историю больше 100 лет назад. Компания - одна из первых в России, которая начала выращивать салатные культуры по современным технологиям и представила на рынке новый продукт – мытые салаты.<br/>
            Белая Дача с вниманием и заботой относится к каждому салатному листику и делает всё, чтобы полезная и вкусная еда как можно чаще была на каждом столе.<br/>
            1 раз достаточно попробовать салаты Белая Дача, чтобы оценить их вкус и пользу. Если бы эти продукты могли сказать пару слов о себе, это звучало бы так: «Вкусные и полезные!» Поддерживайте культуру здорового питания с Белой Дачей.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/vegalize.jpeg"
              alt="VEGALIZE"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">VEGALIZE</h3>
          <p className="providers__about">
            VEGALIZE - это поставщик альтернативных продуктов питания для тех, кто избирателен в своём рационе по физиологическим, этическим, экологическим и иным соображениям. Имеет ассортимент товаров, ориентированных на веганскую аудиторию. Эксклюзивный дистрибьютор растительного молока итальянской торговой марки Riso Scotti.
          </p>
        </div>
        <div className="providers-info">
          <div className="info-img">
            <img
              src="/system_images/suppliers/semushka.jpg"
              alt="Семушка"
              className="providers-info__logo"
            />
          </div>
          <h3 className="providers__title">Семушка</h3>
          <p className="providers__about">
            Семушка – крупнейший производитель натуральных продуктов для здорового питания.
            <br/>
            В фирменной линейке представлен широкий ассортимент орехов, сухофруктов, различных сортов семян и сладостей.
            <br/>
            Компания с 2002 года руководствуется самыми высокими стандартами качества.
            <br/>
            В производстве используется исключительно отборное сырье, импортируемое крупнейшими производителями и поставщиками из стран Латинской и Северной Америки, Европы, Азии, Африки и Австралии.
            <br/>
            Производимый ассортимент отличается большим выбором и включает в себя эко-линейки без консервантов, добавок и дополнительной обработки: орехи без обжарки, сухофрукты и сладости без добавления сахара.
          </p>
        </div>
      </section>

    		</div>
		);
    }

	render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>{this.getTitle()}</title>
                        <meta name="description" content={this.getDescription()} />
                        <meta name="keywords" content={this.getKeywords()} />
                    </Helmet>
                    <MobileLocationData />
                        <DesktopDeliveryInformation />
                    {this.renderBreadCrumbs()}
                    <div id="products" className="page-content">
                        <div className="static_page_content">
                            <h1>{this.getTitle()}</h1>
                            {this.renderContent()}
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
                <FooterBlock />
            </CSSTransitionGroup>
        );
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Suppliers))
