import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import Auth from './../../APP/COMPONENTS/LKParts/Auth';
import Login from './../../APP/COMPONENTS/LKParts/Login';

function mapStateToProps(state) {
    return {
        TOKEN: state.TOKEN,
        LK_MODAL: state.LK_MODAL,
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Lk extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
        };

        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.LK_MODAL !== prevProps.LK_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.LK_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

	render()
    {
        let CONTENT = <Login />;
        if (this.props.USER) {
            CONTENT = <Auth VIEW={this.props.LK_MODAL} />;
        }

        if (this.props.LK_MODAL) {
            return (
                <div>
                    <div className="modal-overlay" onClick={() => this.props.openLK(false)}>
                    </div>
                    {CONTENT}
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Lk);