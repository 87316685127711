import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {sendPostRequest} from "./requests";
import * as ReduxActions from "../../REDUX/functions";

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const QuestData = (props) => {

    async function loadQuestProducts () {
        let USER = JSON.parse(await localStorage.getItem('USER'))
        const request = {
            action: 'productListInStorage',
            apikey: USER?.TOKEN
        }
        let response = await sendPostRequest(request, undefined)
        if (response)
            props.setQuestProducts(response)
    }

    useEffect(()=>{
        loadQuestProducts()
    }, [])

    return null
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestData)