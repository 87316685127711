import React, {Component, useEffect, useState} from 'react';

const Row = ({title, value, disabledIndex, setField, field, index, unique = false}) => {

    const [selected, setSelected] = useState(value)

    useEffect(() => {
        setField(field, selected, index)
    }, [selected])

    useEffect(() => {
        setSelected(value)
    }, [])

    function onClick(index) {
        if (unique) {
            selected === index ? setSelected(false) : setSelected(index)
            return
        }
        if (disabledIndex === index) return
        setSelected(prev => prev.includes(index) ?
            prev.filter(v => v !== index) :
            [...prev, index]
        )
    }

    return <div className="rowRadio">
        <div style={{fontSize: '16px', fontWeight: '900'}}>{title}</div>
        <div className="rowRadioButtons">
            {[...Array(2)].map(
                (_, index) => {
                    return <div onClick={() => onClick(index)}>
                        {
                            <img style={disabledIndex === index ? {marginRight: '2px'} : {}}
                                 src={disabledIndex === index ? '/system_images/disabled.svg' : ((unique ? selected === index : selected.includes(index)) ?
                                     "/system_images/checkboxFull.svg" :
                                     "/system_images/checkbox.svg")
                                 }/>
                        }
                    </div>
                }
            )}

        </div>
    </div>
}

export default ({setField, field, data, unique, hiddenNames = []}) => {


    return <div>
        {
            data.filter(item => !hiddenNames.includes(item.fieldName)).map((store, index) => <Row setField={setField}
                                                                                                  unique={unique}
                                                                                                  field={field}
                                                                                                  index={index} {...store}/>)
        }
    </div>
}