import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

import { isActive } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';
import TheDragBlock from './../../APP/COMPONENTS/ContentParts/TheDragBlock';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

import MobileLocationData from "../COMPONENTS/ContentParts/MobileLocationData";
import DesktopDeliveryInformation from '../COMPONENTS/ContentParts/DesktopDeliveryInformation.js';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import MultipleDragList from '../COMPONENTS/ContentParts/MultipleDragList';


function mapStateToProps(state) {
    return {
        USER: state.USER,
        CATS: state.CATS,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        DO_SEARCH: state.DO_SEARCH,
        POPULAR: state.POPULAR,
        SHOW_INACTIVE: state.SHOW_INACTIVE,
        PRODUCTS: state.PRODUCTS,
        MANAGER: state.MANAGER,
        SUPPLIER: state.SUPPLIER,
        SPEC_FILTER: state.SPEC_FILTER,
        SHOP: state.SHOP,
        MARGE_FILTER: state.MARGE_FILTER,
        WRITE_FILTER: state.WRITE_FILTER,
        REPACK: state.REPACK
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Catalogue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            URL: props.url,
            CAT: false,
            ERROR: false,
            PRODUCTS: false,
            draggable: false,
            new_order: [],
            TAGGED_PRODUCTS: {},
            SAVE_MESSAGE: false,
            NEW_TAG: '',
            SHOW_SMALL: false
        }

        this.interval = false;
        this.WITH_CHILDREN = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.url !== prevProps.url) {
            this.props.closeAllModals();

            if (this.props.CATS) {
                this.setState({ CAT: false, PRODUCTS: false, URL: this.props.url, ERROR: false, SAVE_MESSAGE: false });
                let COMPONENT = this;
                this.interval = setInterval(function () {
                    COMPONENT.prepareData()
                }, 50);
            }
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
        if ((prevProps.SHOW_INACTIVE !== this.props.SHOW_INACTIVE) || (prevProps.MANAGER !== this.props.MANAGER) || (prevProps.SUPPLIER !== this.props.MANAGER)) {
            //let PRODUCTS = this.getProducts(this.state.CAT);
            //console.log(PRODUCTS)
            ///this.setState({PRODUCTS: PRODUCTS});
        }
    }

    componentDidMount() {
        this.props.closeAllModals();




        let COMPONENT = this;
        this.interval = setInterval(function () {
            COMPONENT.prepareData()
        }, 50);
    }

    saveNewMultiOrder = async () => {
        if (typeof this.state.TAGGED_PRODUCTS['all'] !== "undefined") {

            let SEND_DATA_PRODUCTS = {}

            for (let i in this.state.TAGGED_PRODUCTS) {
                SEND_DATA_PRODUCTS[i] = []

                for (let j = 0; j < this.state.TAGGED_PRODUCTS[i].length; j++) {
                    SEND_DATA_PRODUCTS[i].push(this.state.TAGGED_PRODUCTS[i][j].id)
                }
            }

            let cat_id = this.state.CAT.id
            let SEND_DATA = {
                action: 'admin_updateGroupTagsOrdering',
                cat: cat_id,
                tagsProducts: SEND_DATA_PRODUCTS,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.setState({ SAVE_MESSAGE: 'После отчистки кеша, порядок обновится!', TAGGED_PRODUCTS: {}, NEW_TAG: '' })
            }
        }
    }

    saveNewOrder = async () => {
        if (this.state.new_order.length) {
            let cat_id = this.state.CAT.id
            let SEND_DATA = {
                action: 'admin_updateGroupOrdering',
                cat: cat_id,
                products: this.state.new_order,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.setState({ SAVE_MESSAGE: 'После отчистки кеша, порядок обновится!' })
            }
        }
    }

    saveNewTag = async () => {
        if (this.state.NEW_TAG.length) {
            let cat_id = this.state.CAT.id
            let SEND_DATA = {
                action: 'admin_createGroupTag',
                id: cat_id,
                name: this.state.NEW_TAG,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.setState({ NEW_TAG: '' })
            }
        }
    }

    getCat = () => {
        let CAT = false;
        let NEXT = false;
        let SET_J = false;
        let SET_I = false;

        if (this.props.url !== "popular" && this.props.url !== "nocats") {
            if (this.props.CATS) {
                for (let i = 0; i < this.props.CATS.length; i++) {
                    if ((!CAT) || (SET_I + 1 === i)) {

                        let PARENT_CAT = this.props.CATS[i];
                        if (PARENT_CAT.url === this.state.URL) {
                            CAT = PARENT_CAT;
                        }

                        if ((!CAT) || (SET_I + 1 === i)) {

                            if (PARENT_CAT.children.length) {
                                for (let j = 0; j < PARENT_CAT.children.length; j++) {
                                    let CHILD_CAT = PARENT_CAT.children[j];

                                    if (!NEXT) {

                                        if (SET_I !== false) {
                                            if (SET_I + 1 === i) {
                                                if (j === 0) {
                                                    NEXT = CHILD_CAT;
                                                }
                                            }
                                            if (SET_I === i) {
                                                if (SET_J + 1 === j) {
                                                    NEXT = CHILD_CAT;
                                                }
                                            }
                                        }

                                    }

                                    if (CHILD_CAT.url === this.state.URL) {
                                        CAT = CHILD_CAT;

                                        SET_J = j;
                                        SET_I = i;
                                    }
                                }
                            }


                        }

                        if (!NEXT) {
                            if (CAT) {
                                if (typeof this.props.CATS[i + 1] !== "undefined") {
                                    if (this.props.CATS[i + 1].children.length) {
                                        NEXT = this.props.CATS[i + 1].children[0];
                                    } else {
                                        NEXT = this.props.CATS[i + 1];
                                    }
                                } else {
                                    if (this.props.CATS[0].children.length) {
                                        NEXT = this.props.CATS[0].children[0];
                                    } else {
                                        NEXT = this.props.CATS[0];
                                    }
                                }
                            }
                        }

                    }
                }
            }
            if (CAT) {
                CAT.NEXT = NEXT;
            }

            if (typeof window.rrApiOnReady !== "undefined") {
                if (typeof window.rrApi !== "undefined") {
                    try {
                        window.rrApi.categoryView(CAT.id);
                    } catch (e) { }
                }
            }
        } else if (this.props.url == "nocats") {
            CAT = {
                title: 'Нет категории',
                children: false,
                id: -1,
                url: 'nocats',
                parent: {},
                meta_title: 'Нет категории',
                meta_keywords: 'Нет категории',
                meta_description: 'Нет категории'
            };
        } else {
            CAT = {
                title: 'Популярно',
                children: false,
                id: 0,
                url: 'popular',
                parent: {},
                meta_title: 'Популярно',
                meta_keywords: 'Популярно',
                meta_description: 'Популярно'
            };
        }

        if (CAT) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'page_category',
                'category_id': CAT.id,
                'category_name': CAT.title,
            });
            this.setState({ CAT: CAT, ERROR: false });
        } else {
            clearInterval(this.interval);
            this.setState({ CAT: 'NONE', PRODUCTS: true, ERROR: true });
        }
    }

    prepareData = async () => {
        if (!this.state.CAT) {
            if (this.props.CATS) {
                this.getCat();
            }
        }

        if (this.state.CAT) {
            if (!this.state.PRODUCTS) {
                let PRODUCTS = this.getProducts(this.state.CAT);
                this.setState({ PRODUCTS: PRODUCTS });
                clearInterval(this.interval);
            }
        }
    }

    dragEnd = (res) => {
        console.log(res)
    }

    setNewOrder = (items) => {
        this.setState({ new_order: items })
    }

    getTaggedProducts = (TAGS, PRODUCTS) => {
        let TAGGED_PRODUCTS = {}
        for (let i = 0; i < TAGS.length; i++) {
            TAGGED_PRODUCTS[TAGS[i].id] = []
        }

        for (let i = 0; i < PRODUCTS.length; i++) {
            let PRODUCT = this.props.PRODUCTS[PRODUCTS[i]];

            if (parseInt(PRODUCT.archive) == 1 && parseInt(PRODUCT.availableones) == 0) {

            } else {
                let TAG_SET = false;

                for (let j = 0; j < PRODUCT.all_groups.length; j++) {
                    let group = PRODUCT.all_groups[j]

                    if (typeof TAGGED_PRODUCTS[group] !== "undefined") {
                        TAGGED_PRODUCTS[group].push(PRODUCT)
                        TAG_SET = true
                    }
                }

                if (!TAG_SET) {
                    TAGGED_PRODUCTS['all'].push(PRODUCT)
                }
            }
        }

        TAGGED_PRODUCTS = this.sort(TAGGED_PRODUCTS)

        return TAGGED_PRODUCTS
    }

    sort = (TAGGED_PRODUCTS) => {
        let NEW_TAGGED_PRODUCTS = {};

        for (let key in TAGGED_PRODUCTS) {
            NEW_TAGGED_PRODUCTS[key] = TAGGED_PRODUCTS[key].sort(function (a, b) {
                return a.numb - b.numb;
            });
        }

        return NEW_TAGGED_PRODUCTS
    }

    setTagsOrder = (items) => {
        this.setState({ TAGGED_PRODUCTS: items })
    }

    resortTags = (TAGS) => {
        return [...TAGS].sort((a, b) => {

            return a.order - b.order
        })
    }

    getProducts(CAT) {
        let GROUPED_PRODUCTS = this.props.GROUPED_PRODUCTS;

        if ((CAT.children) && (Object.keys(CAT.children).length)) {

            if (!this.state.WITH_CHILDREN) {
                this.setState({ WITH_CHILDREN: true });
            }

            let GROUPS = [];

            for (let i in CAT.children) {
                let ID = CAT.children[i].id;

                if (typeof GROUPED_PRODUCTS[ID] !== "undefined") {
                    if (GROUPED_PRODUCTS[ID].length > 0) {
                        GROUPS.push(
                            <LazyLoad key={ i } height={ 300 }>
                                <ProductsScrollPreview CAT={ CAT.children[i] } IDS={ GROUPED_PRODUCTS[ID] } />
                            </LazyLoad>
                        );
                    }
                }
            }

            return (
                <div>
                    { GROUPS }
                </div>
            );

        } else {
            let USE_PRODUCTS = false;
            if (this.state.WITH_CHILDREN) {
                this.setState({ WITH_CHILDREN: false });
            }

            if (CAT.id > 0) {
                if (typeof GROUPED_PRODUCTS[CAT.id] !== "undefined") {
                    USE_PRODUCTS = GROUPED_PRODUCTS[CAT.id];
                }
            } else {
                if (CAT.id == -1) {
                    USE_PRODUCTS = []
                    for (let i in this.props.PRODUCTS) {

                        if (this.props.PRODUCTS[i].all_groups.length == 0) {
                            USE_PRODUCTS.push(this.props.PRODUCTS[i].id)
                        } else {
                            let found = this.props.PRODUCTS[i].all_groups.filter(title => {
                                return !title.includes('tag')
                            })

                            if (found.length == 0) {
                                USE_PRODUCTS.push(this.props.PRODUCTS[i].id)
                            }
                        }
                    }
                } else {
                    if (typeof this.props.POPULAR !== "undefined") {
                        USE_PRODUCTS = this.props.POPULAR;
                    }
                }
            }

            let DO_USE_PRODUCTS = [];

            for (let i = 0; i < USE_PRODUCTS.length; i++) {
                if (this.state.multidraggable || this.state.draggable) {
                    if (parseInt(this.props.PRODUCTS[USE_PRODUCTS[i]].archive) == 1 && parseInt(this.props.PRODUCTS[USE_PRODUCTS[i]].availableones) == 0) {

                    } else {
                        DO_USE_PRODUCTS.push(USE_PRODUCTS[i]);
                    }
                } else {
                    if (isActive(this.props.PRODUCTS[USE_PRODUCTS[i]], this.props.SHOW_INACTIVE, this.props.MANAGER, this.props.SUPPLIER, this.props.SPEC_FILTER, this.props.SHOP, this.props.MARGE_FILTER, this.props.WRITE_FILTER, this.props.REPACK)) {
                        DO_USE_PRODUCTS.push(USE_PRODUCTS[i]);
                    }
                }
            }

            let CLASS = 'product_card ' + this.getItemsClass();

            const objectComparisonCallback = (arrayItemA, arrayItemB) => {
                if (arrayItemA.order < arrayItemB.order) {
                    return -1
                }

                if (arrayItemA.order > arrayItemB.order) {
                    return 1
                }

                return 0
            }

            if (DO_USE_PRODUCTS) {
                if (this.state.multidraggable) {
                    const CAT_TAGS = Object.assign({}, CAT._tags)

                    let USE_TAGS = [];
                    for (let i in CAT_TAGS) {
                        USE_TAGS.push(CAT_TAGS[i])
                    }

                    USE_TAGS.sort(objectComparisonCallback)

                    let TAGS = [{ id: 'all', name: 'Все товары категории', order: 0 }]

                    for (let i = 0; i < USE_TAGS.length; i++) {
                        const TAG_ID = USE_TAGS[i].id
                        let TAG = Object.assign({}, USE_TAGS[i]);
                        TAG.id = 'tag_' + TAG_ID
                        let USE_ID = 'tag_' + TAG_ID

                        TAGS.push(TAG)
                    }
                    let TAGGED_PRODUCTS = this.getTaggedProducts(TAGS, USE_PRODUCTS)

                    TAGS = this.resortTags(TAGS);

                    return (
                        <MultipleDragList tags={ TAGS } products={ TAGGED_PRODUCTS } setTagsOrder={ this.setTagsOrder } SHOW_SMALL={ this.state.SHOW_SMALL } />
                    )
                }
                if (this.state.draggable) {
                    return (
                        <div className="row">
                            <TheDragBlock
                                PRODUCTS={ DO_USE_PRODUCTS }
                                setNewOrder={ this.setNewOrder }
                            />
                        </div>
                    )
                }

                return (
                    <div className="row products-row">
                        { DO_USE_PRODUCTS.map(id => <div className={ CLASS } key={ id }><ProductCard ID={ id } /></div>) }


                        <div className={ CLASS }><LazyLoad height={ 300 }>
                            <div className="product-col not_found_col">
                                <div className="relative_image_holder">
                                    <div
                                        className="image image_not_found"
                                        onClick={ () => this.props.openNotFounModal(CAT.id) }
                                    >
                                        <div className="not_found_absolute">
                                            <img src="/system_images/green_search.png" />
                                            <div className="not_found_text">
                                                Не нашли товар, который искали?
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="button-holder"
                                >
                                    <a onClick={ () => this.props.openNotFounModal(CAT.id) } className="button_not_found">
                                        Предложить
                                    </a>
                                </div>
                                <div className="spacer"></div>
                            </div>
                        </LazyLoad></div>
                    </div>
                );
            }
        }

        return false;
    }

    getItemsClass() {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 'col-12';
        } else if (WIDTH < 769) {
            return 'col-6';
        } else if (WIDTH < 1050) {
            return 'col-4';
        } else if (WIDTH < 1200) {
            return 'col-3';
        } else if (WIDTH < 9900) {
            return 'el-5';
        }
    }

    renderH1() {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        { this.state.CAT.title }
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Главная'
        });
        if (typeof this.state.CAT.parent.title !== "undefined") {
            DATA.push({
                'to': '/' + this.state.CAT.parent.url,
                'title': this.state.CAT.parent.title
            });
        }
        DATA.push({
            'to': '/' + this.state.CAT.url,
            'title': this.state.CAT.title
        });

        return <BreadCrumbs DATA={ DATA } />
    }

    renderNextCat() {
        if (this.state.CAT.NEXT) {
            return (
                <div className="view-more-cat">
                    <div className="main-container inner container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to={ '/' + this.state.CAT.NEXT.url }>
                                    <div className="more-intro">
                                        Перейти в следующий раздел &gt;&gt;&gt;
                                    </div>
                                    <div className="group-title">
                                        { this.state.CAT.NEXT.title }
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderHiddenText() {
        if (this.state.CAT.after !== '') {
            return (
                <div style={ { display: 'none' } }>
                    { this.state.CAT.after }
                </div>
            );
        }
    }

    renderRetailOne = () => {
        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return <div data-retailrocket-markup-block="5f7d814697a5251aec3fcc4d" data-category-id={ this.state.CAT.id }></div>
        } else {
            return <div data-retailrocket-markup-block="5f7da2da97a5251aec3fd148" data-category-id={ this.state.CAT.id }></div>
        }
    }

    renderRetailTwo = () => {
        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return <div data-retailrocket-markup-block="5f7d814e97a5251aec3fcc4e" data-category-id={ this.state.CAT.id }></div>
        } else {
            return <div data-retailrocket-markup-block="5f7da2ed97a5282bb875d3a4" data-category-id={ this.state.CAT.id }></div>
        }
    }

    renderDraggButton = () => {
        if (this.state.CAT.id !== 0 && this.state.CAT.id !== -1) {
            if (this.state.draggable) {
                return (
                    <>
                        <a className="orange-button" onClick={ () => { this.setState({ draggable: false }, () => this.saveNewOrder()); } }>Сохранить изменения</a>
                        <a className="red-button" onClick={ () => { this.setState({ draggable: false, new_order: [] }); } }>Отменить</a>
                    </>
                )
            } else if (this.state.multidraggable) {
                return (
                    <>
                        <a className="orange-button" onClick={ () => { this.setState({ multidraggable: false }, () => { document.querySelector('html').classList.add("hide-overflow"); this.saveNewMultiOrder() }); } }>Сохранить изменения</a>
                        <a className="red-button" onClick={ () => { this.setState({ NEW_TAG: '', multidraggable: false, TAGGED_PRODUCTS: [] }); document.querySelector('html').classList.add("hide-overflow"); } }>Отменить</a>

                        <label>
                            <input style={ { appearance: 'auto', width: '20px', height: '20px' } } type="checkbox" checked={ this.state.SHOW_SMALL } onChange={ () => this.setState({ SHOW_SMALL: !this.state.SHOW_SMALL }) } /> Показать сокращенные карточки
                        </label>

                        <h6>Создать тег</h6>
                        <div className="tag-creater">

                            <input onChange={ (text) => this.setState({ NEW_TAG: text.target.value }) } className="inline_input" type="text" value={ this.state.NEW_TAG } />
                            <a className="inline_edit confirm" onClick={ () => this.saveNewTag() }><i className="fa fa-check"></i></a>
                        </div>

                    </>
                )
            } else {
                return (
                    <>
                        <a className="orange-button" onClick={ () => { this.setState({ draggable: true }); } }>Изменить порядок</a>
                        <a className="orange-button" onClick={ () => { this.setState({ multidraggable: true }); document.querySelector('html').classList.remove("hide-overflow"); } }>Изменить порядок по тэгам</a>
                    </>
                )
            }
        }
    }

    render() {
        if ((!this.state.CAT) && (!this.state.PRODUCTS)) {
            return <InnerLoader />
        }

        if (this.state.ERROR) {
            return <E404 />;
        }

        if (this.state.WITH_CHILDREN) {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part transparent">
                        <Helmet>
                            <title>{ this.state.CAT.meta_title }</title>
                            <meta name="description" content={ this.state.CAT.meta_description } />
                            <meta name="keywords" content={ this.state.CAT.meta_keywords } />
                        </Helmet>

                        { this.renderBreadCrumbs() }
                        <div id="page-content" className="page-content">
                            <div className="main-container inner container-fluid">
                                { this.renderH1() }
                            </div>
                            { this.renderRetailOne() }
                            { this.getProducts(this.state.CAT) }
                        </div>
                        { this.renderRetailTwo() }

                        { this.renderHiddenText() }

                        <ModalsContainer />
                        <div className="i-flocktory" data-fl-action="track-category-view" data-fl-category-id={ this.state.CAT.id }></div>
                    </div>
                    <FooterBlock />
                    <MobileMessage />
                </CSSTransitionGroup>
            );
        } else {

            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part">
                        <Helmet>
                            <title>{ this.state.CAT.meta_title }</title>
                            <meta name="description" content={ this.state.CAT.meta_description } />
                            <meta name="keywords" content={ this.state.CAT.meta_keywords } />
                        </Helmet>

                        { this.renderBreadCrumbs() }
                        <div id="page-content" className="page-content">
                            <div className="main-container inner container-fluid">
                                { this.renderH1() }
                                { this.renderRetailOne() }
                                <div className="dbh">
                                    { this.renderDraggButton() }
                                </div>
                                { this.state.SAVE_MESSAGE ? (
                                    <div className="success_small_text">{ this.state.SAVE_MESSAGE }</div>
                                ) : false }
                                { this.getProducts(this.state.CAT) }
                            </div>
                            { this.renderRetailTwo() }
                            { this.renderNextCat() }
                            { this.renderHiddenText() }
                        </div>

                        <ModalsContainer />
                        <div className="i-flocktory" data-fl-action="track-category-view" data-fl-category-id={ this.state.CAT.id }></div>
                    </div>
                    <FooterBlock />
                    <MobileMessage />
                </CSSTransitionGroup>
            );
        }
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Catalogue))