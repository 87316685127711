import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProductCard from './../../../APP/COMPONENTS/ContentParts/ProductCard';
import SmallProductCard from './../../../APP/COMPONENTS/ContentParts/SmallProductCard'; 
import TagEditor from './../../../APP/COMPONENTS/ContentParts/TagEditor';
import TheDragBlock from './../../../APP/COMPONENTS/ContentParts/TheDragBlock';
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset}`
    }));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

// Move item from one list to other
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    let destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    if (destination.length) {
        destClone.splice(droppableDestination.index, 0, removed);
    } else {
        destClone.push(removed)
    }

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: isDragging ? 'lightgreen' : 'grey',

    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250
});

class MultipleDragList extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            tags: props.tags,
            items: props.products,
            editing: {},
            SHOW_SMALL: props.SHOW_SMALL,
            SELECTED: false
        };
    }

    componentDidUpdate = (prevProps) =>
    {
        if (this.props.SHOW_SMALL !== this.state.SHOW_SMALL) {
            this.setState({SHOW_SMALL: this.props.SHOW_SMALL})
        }
    }

    getList = (id) =>
    {
        return this.state.items[id]
    }

    onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        // Sorting in same list
        if (source.droppableId === destination.droppableId) {
            const sortedItems= reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let items = Object.assign({},this.state.items)

            items[source.droppableId] = sortedItems

            this.setState({items: items}, () => this.props.setTagsOrder(items))
        }
        // Interlist movement
        else {

            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            let items = Object.assign({},this.state.items)

            for (let i in result) {
                items[i] = result[i]
            }

            this.setState({items: items}, () => this.props.setTagsOrder(items))
        }
    };

    removeFromTags = (item, index, tag) =>
    {
        let source = {
            droppableId: tag,
            index: index
        }
        let destination = {
            droppableId: 'all',
            index: 0
        }
        const result = move(
            this.getList(tag),
            this.getList('all'),
            source,
            destination
        );

        let items = Object.assign({},this.state.items)

        for (let i in result) {
            items[i] = result[i]
        }

        this.setState({items: items}, () => this.props.setTagsOrder(items))
    }

    setSelected = (key) =>
    {
        if (!this.state.SELECTED) {
            this.setState({SELECTED: key})
        } else {
            this.setState({SELECTED: false})
        }
    }

    getClass = (key) =>
    {
        if (this.state.SELECTED) {
            if (key !== this.state.SELECTED) {
                return ' hidden_column';
            } else {
                return ' gridded_column'
            }
        }
        return '';
    }

    getDirection = (key) => {
        
        if (this.state.SELECTED) {
            if (key !== this.state.SELECTED) {
                return 'vertical';
            } else {
                return 'horizontal'
            }
        }
        return 'vertical';
    }

    setNewOrder = (items) =>
    {
        let new_items = Object.assign({},this.state.items)
        let unSorted = new_items[this.state.SELECTED]
        let newSort = [];

        for (let i = 0; i < items.length; i++) {
            newSort.push(
                unSorted.filter(product => product.id == items[i])[0]
            )
        }
        new_items[this.state.SELECTED] = newSort

        this.setState({items: new_items}, () => this.props.setTagsOrder(new_items))
    }

    isFocused = (key) =>
    {
        if (this.state.SELECTED) {
            if (key !== this.state.SELECTED) {
                return false;
            } else {
                return true
            }
        }
        return false
    }

    renderGrid = () =>
    {
        if (this.state.SELECTED) {


            let ITEMS = [];
            for (let i = 0; i < this.state.items[this.state.SELECTED].length; i++) {
                ITEMS.push(this.state.items[this.state.SELECTED][i].id)
            }
            return (
                <div className="grid_over">
                    <a className="grid_finish" onClick={() => this.setSelected(false)}>Закончить редактирование тега</a>
                    <TheDragBlock
                        PRODUCTS={ITEMS}
                        setNewOrder={this.setNewOrder}
                    />
                </div>
            )
        }
    }

    render() {

        
        return (
            <div className="multidrag_holder">
                {this.renderGrid()}
                <DragDropContext onDragEnd={this.onDragEnd}>
                    {this.state.tags.map((TAG, index) => {

                        return (<Droppable droppableId={TAG.id} >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}>
                                    <TagEditor
                                        tag={TAG}
                                        setSelected={this.setSelected}
                                    />
                                    {this.state.items[TAG.id] ? this.state.items[TAG.id].map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}>
                                                        <a className="remover_from_this" onClick={() => this.removeFromTags(item.id, index, TAG.id)}><img src="/system_images/close.png"/></a>
                                                        { this.state.SHOW_SMALL ? <SmallProductCard ID={item.id} /> : <ProductCard ID={item.id} /> }
                                                </div>
                                            )}
                                        </Draggable>
                                    )) : false}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>)
                    })}
                </DragDropContext>
            </div>
        );
    }
}

export default MultipleDragList;