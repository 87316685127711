import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as ReduxActions from './../../../REDUX/functions.js';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MapBackButton extends Component{
    render() {
        return <img className='map-back-button-size' src='/system_images/MapBackButton.png'></img>
    }
}
export default connect(
    mapStateToProps, mapDispatchToProps
)(MapBackButton);