import React, {Component} from 'react';
/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        REGION: state.REGION
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CostWithImage extends Component{
    render() {
        return (
            <div className='costWithImage-block'>
                <img className ='costWithImage-image' src='/system_images/time_icon.png' />
                <div className='costWithImage-description'>Стоимость доставки</div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps, mapDispatchToProps
)(CostWithImage);