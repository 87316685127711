import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import ProductCardAddon from './../../../APP/COMPONENTS/ContentParts/ProductCardAddon';
import ProductRowAddon from './../../../APP/COMPONENTS/ContentParts/ProductRowAddon';
import Slider from "react-slick";

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL,
        PRODUCTS: state.PRODUCTS,
        REGION: state.REGION
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class FiveMinutes extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            ADDED: {},
            ORDER_ID: props.ORDER,
            NUMBER: props.NUMBER,
            EMAIL: '',
            NO_FORM: false,
            SECURITY: props.SECURITY,
            JOURNAL: 1,
            JOURNAL_SENT: false,
            PHONE: props.PHONE,
            MISSED: props.MISSED,
            PAYMENT: props.PAYMENT,
            REGION: props.REGION,
            PRODUCTS: props.PRODUCTS,
            MOVING_CART: props.MOVING_CART,
            AVAILABLE: true,
            SECONDS: 300,
            ADD_PRODUCTS: false,
            STRING: '',
            SUGGESTIONS: false,
            ROUTE: props.ROUTE,
            ROUTE_HEADING: props.ROUTE_HEADING
        }

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.items = this.getItemsPerScreen();

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.STOP_SEARCHING = false;
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    getItemsPerScreen()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 769) {
            return 2;
        } else {
            return 3;
        }
    }

    getItemsPerScreenScroll()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 769) {
            return 2;
        } else {
            return 3;
        }
    }

    addToAddon = (product) =>
    {
        let id = product.id;
        let ADDED = Object.assign({}, this.state.ADDED);
        if (typeof ADDED[id] == "undefined") {
            ADDED[id] = 1;
        }

        this.setState({ADDED: ADDED, SUGGESTIONS: false, RESULTS: false, SUGGESTIONS: false, STRING: ''});
    }

    plusAddon = (product) =>
    {
        let id = product.id;
        let ADDED = Object.assign({}, this.state.ADDED);
        if (typeof ADDED[id] !== "undefined") {
            ADDED[id] ++;
        } else {
            ADDED[id] = 1;
        }

        this.setState({ADDED: ADDED});
    }

    minusAddon = (product) =>
    {
        let id = product.id;
        let ADDED = Object.assign({}, this.state.ADDED);
        if (typeof ADDED[id] !== "undefined") {
            ADDED[id] --;

            if (ADDED[id] <= 0) {
                delete(ADDED[id]);
            }
        }

        this.setState({ADDED: ADDED});
    }

    removeAddon = (product) =>
    {
        let id = product.id;
        let ADDED = Object.assign({}, this.state.ADDED);
        if (typeof ADDED[id] !== "undefined") {
            delete(ADDED[id]);
        }

        this.setState({ADDED: ADDED});
    }

    componentDidMount()
    {
        this.getCatData();
        let COMPONENT = this;
        this.interval = setInterval(function(){
            let SECONDS = COMPONENT.state.SECONDS - 1;
            if (SECONDS == 0) {
                clearInterval(COMPONENT.interval);
                COMPONENT.props.goNextStep();
            } else {
                COMPONENT.setState({SECONDS: SECONDS});
            }
        },1000);

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount = () =>
    {
        clearInterval(this.interval);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    somethingInputted = (string) =>
    {
        let STRING = string.target.value;
        this.setState({STRING: STRING});

        if (STRING.length > 1) {
            this.filterProducts(STRING);
        } else {
            this.setState({SUGGESTIONS: false, RESULTS: false})
        }
    }

    setHistoryRef(node) {
        this.history = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
    }

    filterProducts = async (text) => 
    {
        

        if (this.props.PRODUCTS) {
            try {
                let SEND_DATA = {
                    action:     'searchString',
                    string:     text
                };

                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                if (!this.STOP_SEARCHING) {
                    if (data !== false) {
                        if (data.results.length) {
                            this.setSearchResults(data.results, text);
                        } else {
                            this.setState({RESULTS: [], SUGGESTIONS: false});
                        }
                    } else {
                        this.setState({RESULTS: [], SUGGESTIONS: false});
                    }
                } else {
                    this.STOP_SEARCHING = false;
                }
            } catch (error) {
                this.setState({RESULTS: [], SUGGESTIONS: false});
            }
            
        }
    }

    setSearchResults(IDS, text)
    {
        let PRODUCTS = this.props.PRODUCTS;
        let FOUND = [];
        let COMPONENT = this;

        let MAX = 10;
        for (let i =0; i < IDS.length; i++) {
            let ID = IDS[i];
            if (typeof PRODUCTS[ID] !== "undefined") {
                let PRODUCT = PRODUCTS[ID];
                if (FOUND.length <= MAX) {

                    let SRC = CONFIG.API_C_DOMAIN + PRODUCT.thumb;
                    FOUND.push(
                        <a className="replacement_item" key={i} onClick={() => this.addToAddon(PRODUCT)}>
                            <div className="relative_image_holder">
                                <img
                                    src={SRC}
                                />
                            </div>
                            <div className="replacement_item_text">
                                <div className="replacement_item_title">
                                    {PRODUCT.title}
                                </div>
                                <div className="replacement_item_coef_amount">
                                    {PRODUCT.peramount} {PRODUCT.ed_izm}
                                </div>
                            </div>

                            <div className="replacement_item_sum">
                                {PRODUCT.price}
                            </div>                    
                        </a>
                    );
                }
            }
        }


        this.setState({RESULTS: FOUND, SUGGESTIONS: true});
    }

    onFocus = () =>
    {
        this.setState({SUGGESTIONS: true});
    }

    onFocusOut = () =>
    {
        this.setState({SUGGESTIONS: false});
    }

    renderResults()
    {
        if (this.state.SUGGESTIONS) {
            if (this.state.RESULTS) {
                return (
                    <div className="search" ref={this.setHistoryRef}>
                        {this.state.RESULTS}
                    </div>
                );
            }
        }
    }

    renderReset()
    {
        if (this.state.STRING.length > 0) {
            return (
                <a className="search-controls-btn" onClick={() =>this.stopSearch()} ><i className="fa fa-times"></i></a>
            );
        }
    }

    stopSearch()
    {
        this.setState({RESULTS: false, SUGGESTIONS: false, STRING: ''});
    }

    handleSearch = (e) => {
        if (e.key === 'Enter') {
            this.startSearch()
        }
    }

    startSearch()
    {
        this.props.doSearch(this.state.STRING);
        this.setState({SUGGESTIONS: false})
        this.STOP_SEARCHING = true;
        ///document.location.href = '/search/' + this.state.STRING;
    }

    openLK()
    {
        this.props.openOrder(false);
        this.props.openLK(true);
    }

    getFlocktory =() =>
    {
        return <div className="i-flocktory" data-fl-action="exchange" data-fl-spot="some_spot" data-fl-user-name={this.props.USER.NAME} data-fl-user-email={this.state.PHONE.replace(/\D/g, "") + '@unknown.email'}></div>;
    }

    renderLowerBlock()
    {
        return (
            <div className="done_anketa_block">
                {JSON.stringify(this.state)}
            </div>
        );
    }

    renderText = () =>
    {
        if (this.state.PAYMENT == 1) {
            return (
                <div className="done_message">
                    Итоговая сумма вашего заказа может варьироваться в пределах 10%, если в заказе есть весовой товар.
                </div>
            ); 
        } else {
            return (
                <div className="done_message">
                    Итоговая сумма вашего заказа может варьироваться в пределах 10%, если в заказе есть весовой товар. В таком случае будет осуществлен воврат части денежных средств или дополнительное списание, как только заказ будет собран.
                </div>
            );
        }
    }

    countCart()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.state.ADDED;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        return this.rmFixed2(SUM);
    }

    rmFixed2(numb)
    {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(parseFloat(numb).toFixed(2)).toFixed(2);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderButton = () =>
    {
        return (
            <a className="orange-button" onClick={() => this.props.goNextStep(false)}>
                Завершить без замены   {this.renderCounter()}
            </a>
        );
    }

    addProductsToOrder = async () =>
    {
        if (!this.state.SAVING) {
            this.setState({SAVING: true});
            try {
                let TOKEN = 'UNREGISTERED';
                if (this.props.USER) {
                    TOKEN = this.props.USER.TOKEN;
                }

                let SEND_DATA = {
                    action:     'addProductsToOrder',
                    id:         this.state.ORDER_ID,
                    apikey:     TOKEN,
                    security:   this.state.SECURITY,
                    products:   this.state.ADDED

                };
                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                if (data !== false) {
                    this.props.payDone();
                }
                this.setState({SAVING: false});
            } catch (error) {
                this.setState({SAVING: false});
            }
        }
    }

    renderReplacement = () =>
    {
        let ITEMS = [];

        for (let i = 0; i < this.state.MISSED.length; i++) {
            let MISSED_PRODUCT = this.state.MISSED[i];
            let SRC = CONFIG.API_C_DOMAIN + MISSED_PRODUCT.image;

            ITEMS.push(
                <div className="replacement_item" key={i}>
                    <div className="relative_image_holder">
                        <img
                            src={SRC}
                        />
                    </div>
                    <div className="replacement_item_text">
                        <div className="replacement_item_title">
                            {MISSED_PRODUCT.title}
                        </div>
                    </div>

                    <div className="replacement_item_amount">
                        {MISSED_PRODUCT.amount} {MISSED_PRODUCT.ed_izm}
                    </div>

                    <div className="replacement_item_sum">
                        {MISSED_PRODUCT.sum}
                    </div>                    
                </div>
            );
        }

        return (
            <div className="replace_items">
                <div className="replace_items_list">
                    {ITEMS}
                </div>
                <div className="replace_spacer"></div>
                
            </div>
        );
    }

    renderCounter = () =>
    {
        let MINUTES = Math.floor(this.state.SECONDS / 60);
        let SECONDS = this.state.SECONDS - (MINUTES * 60);

        if (SECONDS == 0) {
            SECONDS = '00';
        } else if (SECONDS < 10) {
            SECONDS = '0' + SECONDS;
        }

        return MINUTES + ':' + SECONDS;
    }

    renderReplaceButton = () =>
    {
        if (this.state.AVAILABLE) {
            return (
                <div className="text-center">
                    <a className="replacement_button" onClick={() => this.startAdding()}>
                        Перейти в каталог
                    </a>
                </div>
            );
        }
    }

    startAdding = () =>
    {
        this.props.startAddingProductsToExistingOrder({
            ID: this.state.ORDER_ID, 
            SECURITY: this.state.SECURITY, 
            NUMBER: this.state.NUMBER,
            ROUTE: this.state.ROUTE,
            ROUTE_HEADING: this.state.ROUTE_HEADING,
            PAYMENT: this.state.PAYMENT,
            MISSED: this.state.MISSED,
            PHONE: this.state.PHONE
        });
        this.props.openOrder(false);
    }

    renderAddonsContent = () =>
    {
        return (
            <div className="addon_holder">
                {this.renderReplacement()}
            </div>
        );

    }

    getCatData = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'getCarouselForCat',
                id:         0,
                REGION:     this.state.REGION,
                token:      this.props.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                this.setState({IDS: data})
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    inUsedCart = (id) =>
    {
        for (let ID in this.state.MOVING_CART) {
            if (ID == id) {
                return true;
            }
        }

        return false;
    }

    renderCarousel = () =>
    {
        if (this.state.IDS) {
            let PRODUCT_CARDS = [];
            let TOTAL_NORMALS = 0;
            let LIMIT = 10;

            for (let i = 0; i <= LIMIT; i++) {
                if (typeof this.state.IDS[i] !== "undefined") {
                    if (this.inUsedCart(this.state.IDS[i])) {
                        LIMIT++;
                    } else {
                        if (typeof this.props.PRODUCTS[this.state.IDS[i]] !== "undefined") {
                            let PRODUCT = this.props.PRODUCTS[this.state.IDS[i]];
                            let AMOUNT = 0;
                            if (this.state.ADDED[this.state.IDS[i]]) {
                                AMOUNT = this.state.ADDED[this.state.IDS[i]];
                            }
                            PRODUCT_CARDS.push(
                                <div className="item" key={i}>
                                    <ProductCardAddon ID={this.state.IDS[i]} AMOUNT={AMOUNT} plusAddon={this.plusAddon} minusAddon={this.minusAddon} addToAddon={this.addToAddon} />
                                </div>
                            );
                        }
                    }
                }
            }

            let arrows = false;
            if (this.items > 2) {
                arrows = true;
            }

            let SETTINGS = {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: this.items,
                slidesToScroll: 1,
                className: 'owl-product',
                arrows: arrows,
                swipeToSlide: true
            };

            let CAROUSEL = false;
            if (this.items > 2) {
                CAROUSEL = (
                    <Slider ref={c => (this.slider = c)} {...SETTINGS}>
                        {PRODUCT_CARDS}
                    </Slider>
                );
            } else {
                CAROUSEL = (
                    <div onScroll={this.handleScroll} className="rm_carousel" ref={c => (this.slider = c)}>
                        {PRODUCT_CARDS}
                    </div>
                );
            }

            return (
                <div>
                    {CAROUSEL}
                    {this.renderCardText()}
                </div>
            );
        }
    }

    renderCardText = () =>
    {
        if (this.state.PAYMENT == 1) {
            return (
                <div className="five_minutes_card_text">
                </div>
            );
        } else {
            return (
                <div className="five_minutes_card_text">
                    Сумма за добавленный товар будет списана с вашего счета, как только заказ будет готов к отправке
                </div>
            );
        }
    }

    renderSearchInput = () =>
    {
        return (
            <div className="five_minutes_search">
                <input 
                    onKeyDown={this.handleSearch}
                    className="searchinput"
                    type="text"
                    placeholder="Поиск"
                    onChange={(text) => this.somethingInputted(text)}
                    value={this.state.STRING}
                />
                {this.renderReset()}
                {this.renderResults()} 
            </div>
        );
    }

    renderAddedList = () =>
    {
        let CART = this.state.ADDED;
        let KEYS = Object.keys(CART);

        if (KEYS.length == 0) {
            return false;
        } else {
            let PRODUCTS = this.props.PRODUCTS;
            let PRODUCT_CARDS = [];
            if (KEYS.length) {
                for (let ID of KEYS) {
                    if (typeof PRODUCTS[ID] !== "undefined") {
                        if (PRODUCTS[ID].availableones >= CART[ID]) {
                            let AMOUNT = 0;
                            if (this.state.ADDED[ID]) {
                                AMOUNT = this.state.ADDED[ID];
                            }
                            PRODUCT_CARDS.push(
                                <div className="product-col" key={ID}>
                                    <ProductRowAddon ID={ID} AMOUNT={AMOUNT} plusAddon={this.plusAddon} minusAddon={this.minusAddon} addToAddon={this.addToAddon} removeAddon={this.removeAddon} />
                                </div>
                            );
                        }
                    }
                }
            }

            return (
                <div className="added_product_rows">
                    {PRODUCT_CARDS}
                </div>
            );
        }
    }

    renderContent = () => {
        if (this.state.ADD_PRODUCTS) {
            return (
                <div className="order_done_first_block">
                    <div className="after_order_head">
                        Добавление товаров в заказ
                    </div>
                    <div className="five_minutes_search_block">
                        {this.renderSearchInput()}
                        {this.renderAddedList()}
                    </div>
                    {this.renderCounter()}
                </div>
            );
        } else {
            return (
                <div className="order_done_replace_block">
                    <div className="five_minutes_head">
                        Спасибо, мы приняли ваш заказ, но, к сожалению, пока вы его оформляли, эти товары закончились:
                    </div>
                    {this.renderAddonsContent()}
                </div>
            );
        }
    }

	render()
    {
        return (
            <div className="order_form_holder totally_hidden">
                <div className="modal_content">
                    <div className="order_finals_block flex_block order_finals_block_grey replacement_block">
                        {this.renderContent()}
                    </div>
                </div>
                <div className="modal_bottom">
                    <div className="replacement_items_control">
                        <div className="five_minutes_text">
                            Вы можете перейти в каталог и заменить нехватающие товары
                        </div>
                        {this.renderReplaceButton()}
                        <a className="replace_contact" href="tel: +7-921-557-22-72">
                            <img src="/system_images/connect_us.png" /> <span>Связаться с нами</span>
                        </a>
                        
                    </div>
                    {this.renderButton()}
                </div>
            </div>
        )         
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(FiveMinutes);