import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import LazyLoad from 'react-lazyload';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        REGION: state.REGION,
        TOKEN: state.TOKEN,
        PREPARED_CATS: state.PREPARED_CATS,
        POPULAR: state.POPULAR,
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CatPreview extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            REGION: props.REGION,
            CATS: props.CATS,
            PREPARED_CATS: []
        };

        this.LOADING = false;
        this.LOADED = {};
    }

    componentDidMount()
    {
        this.getCatsAndIds();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ((this.props.REGION !== prevProps.REGION)) {
            let COMPONENT = this;
            setTimeout(function(){
                COMPONENT.LOADING = false;
                COMPONENT.LOADED = {};
                COMPONENT.setState({CATS: COMPONENT.props.CATS, REGION: COMPONENT.props.REGION, PREPARED_CATS: []});
                COMPONENT.getCatsAndIds();
            },300);
        }

    }

    getCatsAndIds = async() =>
    {
        if (this.state.PREPARED_CATS.length == 0) {
            if (!this.LOADING) {
                this.LOADING = true;
                if (this.state.CATS) {

                    if (!this.LOADED[0]) {
                        let CAT = {id: 0, title: 'Популярное', url: 'popular'};
                        let products = await this.getCatData(CAT);
                        if (products) {
                            this.setState({PREPARED_CATS: this.state.PREPARED_CATS.concat({CAT: CAT, IDS: products})});
                            this.LOADED[0] = true;
                        }
                    }

                    for (let i = 0; i < this.props.CATS.length; i++) {

                        let CAT = this.state.CATS[i];
                        if (typeof CAT !== "undefined") {
                            if (!this.LOADED[CAT.id]) {
                                let products = await this.getCatData(this.props.CATS[i]);
                                if (products) {
                                    if (products.length) {
                                        this.setState({PREPARED_CATS: this.state.PREPARED_CATS.concat({CAT: this.props.CATS[i], IDS: products})})
                                        this.LOADED[CAT.id] = true;
                                    }
                                }
                            }
                        }
                    }

                    this.props.setPreparedCats(this.state.PREPARED_CATS);
                }
            }
        }
    }


    getCatData = async (CAT) =>
    {
        try {
            let SEND_DATA = {
                action:     'admin_getCarouselForCat',
                id:         CAT.id,
                REGION:     this.state.REGION,
                AB_CASE:    window.AB_CASE,
                apikey:     this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                return data;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    renderRows = () =>
    {
        this.getCatsAndIds();

        if (this.state.PREPARED_CATS) {
            let RENDERED_CATS = [];

            for (let i = 0; i < this.state.PREPARED_CATS.length; i++) {
                let OBJ = this.state.PREPARED_CATS[i];
                RENDERED_CATS.push(<LazyLoad key={i} height={300}><ProductsScrollPreview CAT={OBJ.CAT} IDS={OBJ.IDS} /></LazyLoad>)
            }
            
            return (
                <div>
                    {RENDERED_CATS.map(carousel => carousel)}
                </div>
            );
        }
    }

    render()
    {
        return (
            <div>
                {this.renderRows()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CatPreview);