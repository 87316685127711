import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Logo extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	LOGOS: [
        		CONFIG.API_C_DOMAIN + '/imgs/logo_top_1.png',
        		CONFIG.API_C_DOMAIN + '/imgs/logo_top_2.png',
        		CONFIG.API_C_DOMAIN + '/imgs/logo_top_3.png',
        		CONFIG.API_C_DOMAIN + '/imgs/logo_top_4.png'
        	],
        	ACTIVE: 0
        }
    }

    onHover = () =>
    {
    	let TOTAL = this.state.LOGOS.length - 1;
    	let SET_ACTIVE = this.state.ACTIVE + 1;
    	if (SET_ACTIVE > TOTAL) {
    		SET_ACTIVE = 0;
    	}

    	this.setState({ACTIVE: SET_ACTIVE});
    }

    getCurrentSource = () =>
    {
    	return this.state.LOGOS[this.state.ACTIVE];
    }

	render() {
		return (
            <div className="head-logo-spacer">
    			<Link to="/">
    				<img src={this.getCurrentSource()} id="logo" onMouseOver={this.onHover} />
    			</Link>
            </div>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(Logo)
