import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import QuestData from './../APP/HELPERS/QuestData'

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ScrollToTop from './../APP/HELPERS/scrolltotop.js';

/// PARTS ///
import RegionHelper from './../APP/COMPONENTS/HeaderParts/RegionHelper.js';
import HeaderBlock from './../APP/COMPONENTS/Header.js';
import ContentBlock from './../APP/COMPONENTS/Content.js';
import Preloader from './../APP/COMPONENTS/Preloader.js';

import ScrollTop from './../APP/COMPONENTS/ContentParts/ScrollTop.js';
import moment from "moment";
import 'moment/locale/ru'
import Login from './../APP/COMPONENTS/LKParts/Login'

moment.locale('ru')

function mapStateToProps(state) {
    return {
        LIMIT_NAVS: state.LIMIT_NAVS,
        PRODUCTS: state.PRODUCTS,
		ADRESS: state.ADRESS,
		USER: state.USER
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class App extends Component {

	constructor(props)
    {
    	super(props);
		this.myRef = React.createRef();
	}

	componentDidMount()
	{
	}

    getStyle = () =>
    {


        if (this.props.LIMIT_NAVS) {
        	return ' limit_navs';
        }

        if (this.props.history.location.pathname == "/company/zoo") {
        	return ' no_padding_true';
        }

        return '';
    }


	render() {
		if (!this.props.USER) {
			return (
				<div className="static_login">
					<Login />
				</div>
			)
		}

		return (
			<div className={"the-holder" + this.getStyle()} ref={this.myRef}>
				<HeaderBlock />
				<ContentBlock />
				<Preloader />
			</div>
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(App))
