import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CONFIG from './../../../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        FAVS: state.FAVS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class FavsBlock extends Component {

	constructor(props)
    {
        super(props);
    }

	render() {
    	let IMAGE_SRC = '/system_images/favs_empty.png';
        let KEYS = Object.keys(this.props.FAVS);
    
        if (KEYS.length) {
            IMAGE_SRC = '/system_images/favs_full.png';
        }

        return (
            <Link to="/myproducts" className="header_favs_block">
                <img src={IMAGE_SRC} alt="мои товары" />
            </Link>
        );
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FavsBlock)
