import React, {Component} from 'react';
import InputMask from "react-input-mask";

export default class ImageRadioColumnMultiple extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            DATA: props.data,
            FIELD: props.field,
            SELECTED: props.selected
        }
    }

    recordValue = (value) => 
    {
        let VALUES = this.state.SELECTED;
        let SELECTED = this.checkSelected(value);
        if (SELECTED) {
            let NEW_VALUES = [];
            for (let i = 0; i < VALUES.length; i++) {
                if (VALUES[i] !== value) {
                    NEW_VALUES.push(VALUES[i]);
                }
            }

            this.setState({SELECTED: NEW_VALUES});
            this.props.setField(this.state.FIELD,NEW_VALUES);

        } else {
            VALUES.push(value);

            this.setState({SELECTED: VALUES});
            this.props.setField(this.state.FIELD,VALUES);
        }        
    }

    checkSelected = (VALUE) =>
    {
        let SELECTED = false;

        for (let i = 0; i < this.state.SELECTED.length; i++) {
            if (this.state.SELECTED[i] == VALUE) {
                SELECTED = true;
            }
        }

        if (SELECTED) {
            return true;
        } else {
            return false;
        }
    }

    render()
    {
        let OPTIONS = [];
        for (let i = 0; i < this.state.DATA.length; i++) {
            let CLASS = 'anketa_images_input columned';
            let SELECTED = this.checkSelected(this.state.DATA[i]['VALUE']);
            if (SELECTED) {
                CLASS = 'anketa_images_input columned selected';
            }
            OPTIONS.push(
                <a key={i} className={CLASS} onClick={() => this.recordValue(this.state.DATA[i]['VALUE'])}>
                    <img src={this.state.DATA[i]['IMAGE']} />
                    <span>{this.state.DATA[i]['TEXT']}</span>
                </a>
            );
        }

    	return (
            <div className={this.props.className}>
                {OPTIONS}
            </div>
        );
    }
}
