import React, {Component} from 'react';
import InputMask from "react-input-mask";

export default class TextInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            FIELD: props.FIELD,
            SUGGESTIONS: false,
            VISIBLE: false,
        }

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    select = (id, title) => {
        this.props.setField(this.state.FIELD, id);
        this.setState({STRING: title, RESULTS: [], SUGGESTIONS: false})
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setHistoryRef(node) {
        this.history = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
    }

    recordValue = (text) => {
        this.validate(text.target.value);
    }

    validate = (string) => {
        if (string.length > 1) {
            this.props.setField(this.state.FIELD, string)
        } else {
            this.props.resetField(this.state.FIELD);
        }
    }

    renderResults() {
        if (this.state.VISIBLE) {
            if (this.props.suggestions) {
                return (
                    <div className="search" ref={this.setHistoryRef}>
                        {this.props.suggestions.map(suggestion => <div className="search-result-row" onClick={() => {
                            this.props.setField(this.state.FIELD, suggestion)
                            this.props.onChange(suggestion)
                        }}>{suggestion}</div>)}
                    </div>
                );
            }
        }
    }

    render() {
        return <div className="product_searcher">
            <input value={this.props?.value || undefined} type="text" placeholder={this.props.placeholder}
                   onChange={(text) => {
                       this.recordValue(text)
                       if (this.state.FIELD === 'REGION_ELSE') {
                           this.props.onChange(text.target.value)
                       }
                   }} defaultValue={this.state.NAME}
                   onBlur={() => setTimeout(() => this.setState({VISIBLE: false}), 100)}
                   onFocus={() => this.setState({VISIBLE: true})}
            />
            {this.state.FIELD === 'REGION_ELSE' ? this.renderResults() : null}
        </div>
    }
}
