import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import InnerModalProduct from './../../APP/COMPONENTS/ContentParts/InnerModalProduct';

function mapStateToProps(state) {
    return {
        EDIT_SEASONS: state.EDIT_SEASONS,
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class SeasonModal extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            CURRENT_CLASS: "modal-box product-card",
            VIEW: 'SEASON'
        };

        this.OFFSET = 0;
    }

    componentDidMount() {
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.EDIT_SEASONS !== prevProps.EDIT_SEASONS) {
            if (!this.props.EDIT_SEASONS) {
                document.body.classList.remove("modal-open");
                document.documentElement.scrollTop = this.OFFSET;
            } else {
                this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                document.body.classList.add("modal-open");

                let PRODUCT = this.props.EDIT_SEASONS.product;
                this.getLatestSaleDate(PRODUCT.id);
                let ssd = PRODUCT.seasonstart_day;
                var today =  new Date ();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');

                if (PRODUCT.seasonstart_day == '00') {

                    ssd = dd;
                }
                let ssm = PRODUCT.seasonstart_month;
                if (PRODUCT.seasonstart_month == '00') {
                    ssm = mm;
                }
                let sed = PRODUCT.seasonend_day;
                if (PRODUCT.seasonend_day == '00') {
                    sed = '01';
                }
                let sem = PRODUCT.seasonend_month;
                if (PRODUCT.seasonend_month == '00') {
                    sem = '01';
                }
                this.setState({
                    SEASONSTART_DAY: ssd,
                    SEASONSTART_MONTH: ssm,
                    SEASONEND_DAY: sed,
                    SEASONEND_MONTH: sem,
                    TITLE: PRODUCT.title,
                    QUANT: 0,
                    PERCENT: 0,
                    ID: PRODUCT.id,
                    TOPACK: PRODUCT.topack,
                    CLOSE_COMMENT: '',
                    IMAGES: []
                });
            }
        }
    }

    closeModal = () => {
        if (window.innerWidth < 1200){
            document.getElementById("movebleDiv").style.animationName = "closeModal";
            document.getElementById("modalOverlay").style.animationName = "closeModalOverlay";
        }
        let COMPONENT = this;
        setTimeout(function () {
            COMPONENT.setState({VIEW: 'SEASON'})
            COMPONENT.props.editSeasoning(false)
        }, 500);
    }

    mobileScreenChaker = (IdName) => {
        if (window.innerWidth <= 1200){
            return IdName
        }
        else return ''
    }

    mobileScreenChakerOverlay = () => {
        if (window.innerWidth <= 1200){
            return "modal-overlay-white"
        }
        else return "modal-overlay"
    }

    renderClass = (TYPE) =>
    {
        if (TYPE == this.state.VIEW) {
            return 'tabby active';
        }

        return 'tabby';
    }

    setView = (VIEW) =>
    {
        this.setState({VIEW: VIEW})
    }

    renderTabber = () =>
    {
        return (
            <div className="tabber_holder">
                <a className={this.renderClass('SEASON')} onClick={() => this.setView('SEASON')}>
                    Сезонность
                </a>
                <a className={this.renderClass('TOPACK')} onClick={() => this.setView('TOPACK')}>
                    Фасовочный
                </a>
                <a className={this.renderClass('WHOLESALE')} onClick={() => this.setView('WHOLESALE')}>
                    Опт
                </a>
                <a className={this.renderClass('CLOSE_TO_ORDER')} onClick={() => this.setView('CLOSE_TO_ORDER')}>
                    Закрыть к заказу
                </a>
            </div>
        )
    }

    renderDayOptions = () =>
    {
        let options = [];
        for (let i = 1; i <= 31; i++) {
            let j = i;
            if (j < 10) {
                j = '0' + j;
            }
            options.push(
                <option key={i} value={j}>{j}</option>
            );
        }

        return options;
    }

    renderMonthOptions = () =>
    {
        let options = [
            <option value="01" key={1}>Январь</option>,
            <option value="02" key={2}>Февраль</option>,
            <option value="03" key={3}>Март</option>,
            <option value="04" key={4}>Апрель</option>,
            <option value="05" key={5}>Май</option>,
            <option value="06" key={6}>Июнь</option>,
            <option value="07" key={7}>Июль</option>,
            <option value="08" key={8}>Август</option>,
            <option value="09" key={9}>Сентябрь</option>,
            <option value="10" key={10}>Октябрь</option>,
            <option value="11" key={11}>Ноябрь</option>,
            <option value="12" key={12}>Декабрь</option>,
        ];

        return options;
    }

    recordValue = (name, input) =>
    {
        this.setState({[name]: input.target.value});
    }

    renderToClose = () =>
    {
        return (
            <>
                <textarea onChange={(input) => this.recordValue('CLOSE_COMMENT',input)} placeholder="Комментарий к закрытию"></textarea>
                <a className="orange-button" onClick={() => this.closeToOrder()}>Закрыть к заказу</a>
            </>
        );
    }

    renderTopack = () =>
    {
        if (parseInt(this.state.TOPACK) == 1) {
            return <a className="orange-button" onClick={() => this.updateToPack(0)}>Убрать из фасовочного</a>
        } else {
            return <a className="orange-button" onClick={() => this.updateToPack(1)}>Сделать фасовочным</a>
        }
    }

    renderSeason = () =>
    {
        return (
            <>
                <div>
                    <strong>Дата поледней продажи: {this.state.LAST_SALE}</strong>
                </div>

                <div>
                    <span>Вне сезона с</span>
                    <div className="flex">
                        <select onChange={(input) => this.recordValue('SEASONSTART_DAY',input)} value={this.state.SEASONSTART_DAY} >
                            {this.renderDayOptions()}
                        </select>
                        <select onChange={(input) => this.recordValue('SEASONSTART_MONTH',input)} value={this.state.SEASONSTART_MONTH} >
                            {this.renderMonthOptions()}
                        </select>
                    </div>
                </div>
                <div>
                    <span>Вне сезона до</span>
                    <div className="flex">
                        <select onChange={(input) => this.recordValue('SEASONEND_DAY',input)} value={this.state.SEASONEND_DAY} >
                            {this.renderDayOptions()}
                        </select>
                        <select onChange={(input) => this.recordValue('SEASONEND_MONTH',input)} value={this.state.SEASONEND_MONTH} >
                            {this.renderMonthOptions()}
                        </select>
                    </div>
                </div>
                <a className="orange-button" onClick={() => this.saveSeason()}>Сохранить</a>
            </>
        )
    }

    renderWholesale = () =>
    {
        return (
            <>
                <label>
                    <span>Название карточки:</span>
                    <textarea onChange={(input) => this.recordValue('TITLE',input)} value={this.state.TITLE} ></textarea>
                </label>
                <label>
                    <span>Квант:</span>
                    <input type="number" onChange={(input) => this.recordValue('QUANT',input)} value={this.state.QUANT} />
                </label>
                <label>
                    <span>Скидка за штуку при опте (%):</span>
                    <input type="number" onChange={(input) => this.recordValue('PERCENT',input)} value={this.state.PERCENT} />
                </label>
                <label>
                    <span>Фотография:</span>
                    <input type="file" multiple="multiple" onChange={(input) => this.recordImage(input)} />
                </label>
                <a className="orange-button" onClick={() => this.createWholesale()}>Создать</a>
                
            </>
        )
    }

    createWholesale = async () =>
    {
        let SEND_DATA = new FormData();

        SEND_DATA.append('action', 'admin_createWholesaleCard');
        SEND_DATA.append('apikey', this.props.USER.TOKEN);
        SEND_DATA.append('id', this.state.ID);
        SEND_DATA.append('title', this.state.TITLE);
        SEND_DATA.append('quant', this.state.QUANT);
        SEND_DATA.append('percent', this.state.PERCENT);

        for(var i = 0; i < this.state.IMAGES.length; i++) {
            SEND_DATA.append('image[]', this.state.IMAGES[i]);
        }

        fetch( 
            CONFIG.API_GATE, 
            {
                credentials: 'include',
                method: "POST",
                body: SEND_DATA
            }
        )
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status.result == 'SUCCESS') {
                this.closeModal()
            }
        })
        .catch(err => {
            console.log(err)
        });
    }

    recordImage = (event) =>
    {
        this.setState({
            IMAGES: event.target.files,
        })
    }

    getLatestSaleDate = async (id) =>
    {
        try {
            let SEND_DATA = {
                action: 'admin_getLatestSaleDate',
                id: id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.setState({LAST_SALE: data})
            }
        } catch (error) {
            console.log(error)
        }
    }

    closeToOrder = async () =>
    {
        if (this.state.CLOSE_COMMENT.length > 1) {
            try {
                let SEND_DATA = {
                    action: 'admin_closeToOrder',
                    id: this.state.ID,
                    comment: this.state.CLOSE_COMMENT,
                    apikey: this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

                if (data !== false) {
                    this.closeModal();
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    updateToPack = async (topack) =>
    {
        try {
            let SEND_DATA = {
                action: 'admin_changeTopack',
                id: this.state.ID,
                topack: topack,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.closeModal();
            }
        } catch (error) {
            console.log(error)
        }
    }

    saveSeason = async () =>
    {
        try {
            let SEND_DATA = {
                action: 'admin_changeSeason',
                id: this.state.ID,
                seasonstart_day: this.state.SEASONSTART_DAY,
                seasonstart_month: this.state.SEASONSTART_MONTH,
                seasonend_day: this.state.SEASONEND_DAY,
                seasonend_month: this.state.SEASONEND_MONTH,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.closeModal();
            }
        } catch (error) {
            console.log(error)
        }
    }

    renderContent = () =>
    {
        let CONTENT = '';
        if (this.state.VIEW == 'SEASON') {
            CONTENT = this.renderSeason();
        } else if (this.state.VIEW == 'TOPACK') {
            CONTENT = this.renderTopack();
        } else if (this.state.VIEW == 'WHOLESALE') {
            CONTENT = this.renderWholesale();
        } else if (this.state.VIEW == 'CLOSE_TO_ORDER') {
            CONTENT = this.renderToClose();
        }

        return (
            <div className="tabber_body">
                {CONTENT}
            </div>
        )
    }


    // onClick={() => this.props.openProductModal(false)}
	render()
    {
        if (this.props.EDIT_SEASONS) {
            return (
                <div>
                    <div className={this.mobileScreenChakerOverlay()} id={this.mobileScreenChaker("modalOverlay")} onClick={() => this.closeModal()}>
                    </div>
                    <div className = {this.state.CURRENT_CLASS}
                         id = {this.mobileScreenChaker("movebleDiv")}
                    >  
                        <div className="mobile_fixed_closer">
                            <a className="close-modal-holder" onClick={() => this.closeModal()}>
                                {
                                    window.innerWidth <= 1200
                                    ? <div className="collapse_text">Свернуть</div>
                                    : false
                                }
                                <img  style={{height: '19px', width: '19px' }} src="/system_images/green_cross_modal.png" />
                            </a>
                        </div>

                        <section>
                            <div className="main-container inner container">
                                <div className="row the-product-row">
                                    <div className="col-md-12">
                                        <h1>{this.props.EDIT_SEASONS.title}</h1>
                                        {this.renderTabber()}
                                        {this.renderContent()}
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(SeasonModal);