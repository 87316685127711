import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductRowRec from "./../../../APP/COMPONENTS/ContentParts/ProductRowRec"

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        REC: true,
        USEABLE_PERIODS: state.USEABLE_PERIODS,
        ADRESS: state.ADRESS,
        PolygonsMinimumOrder: state.PolygonsMinimumOrder,
        REGION: state.REGION
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CartButton extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            SUM: 0,
            CART: props.CART,
            PRODUCTS: props.PRODUCTS
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.CART !== prevProps.CART) {
            this.setState({SUM: 0, CART: this.props.CART, PRODUCTS: this.props.PRODUCTS});
        }
    }

    countCart()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        return SUM;
    }

    rmFixed2(numb)
    {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(parseFloat(numb).toFixed(2)).toFixed(2);
        return numb;
    }

    goToOrder= () =>
    {
        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('makeOrder');
        }
/*        if (typeof window.roistat !== "undefined") {
            window.roistat.event.send('GO_TO_PURCHASE');
        }*/
        this.props.openCart(false);
        this.props.openOrder(true);
        ///window.location.href = CONFIG.API_C_DOMAIN + '/cart?content=' + JSON.stringify(this.props.CART);
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }
    
    checkMaximum()
    {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    checkCartContents()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let RESULT = 0;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                        SUM += ROW_SUM;
                        RESULT = 1;
                    }
                }
            }
        }

        this.SUM = SUM;

        return RESULT;
    }

    renderCart()
    {
        let CART_OK = 0;

        let count = 0;
        for (let k in this.props.CART) {
            ++count;
        }

        if (count > 0) {
            CART_OK = this.checkCartContents();
        }

        if (CART_OK == 1) {
            return this.renderCartContent();
        } else {
            return this.renderCartEmpty();
        }
    }

    renderCartEmpty()
    {

    }

    renderCartContent()
    {
        return (
            <div>
                {this.renderMessage()}
                {this.renderProducts()}
                
            </div>
        );
    }

    emptyAddressChecker = () => {
        if(!this.props.ADRESS){
            if(window.innerWidth < 1200){
                this.props.openCart(false);
                this.props.openDeliveryAddressModal(true)
                this.props.openOrder(true);
            }
            else{
                this.props.openCart(false);
                this.props.openDeliveryAddressMixedModal('adress')
                this.props.openOrder(true);
            }
        }
        else{
            this.goToOrder();
        }
    }

	render()
    {
        let SUM = this.countCart();

        if ((SUM >= this.props.PolygonsMinimumOrder) && (SUM > 0)) {
            if ((this.props.USEABLE_PERIODS) || ((typeof CONFIG.USE_REGION_DELIVERY !== "undefined") && CONFIG.USE_REGION_DELIVERY && this.props.REGION == 'OTHER')) {
                return (
                    <div className="modal_bottom" id="cart-submit" data-id="cart-submit">
                        <ProductRowRec SUM = {SUM}/>
                        <a className="orange-button hoverable" onClick={this.emptyAddressChecker}>
                            Оформить заказ
                        </a>
                    </div>
                );
            } else {
                return (
                    <div className="modal_bottom" id="cart-submit" data-id="cart-submit">
                        <ProductRowRec SUM = {SUM}/>
                        <a className="orange-button hoverable" onClick={() => {this.props.openCart(false);this.props.openDeliveryAddressModal(true)}}>
                            Оформить заказ
                        </a>
                    </div>
                );
            }
        } else {
/*            let LEFT = this.rmFixed2(CONFIG.MINIMAL - SUM);*/
            let LEFT

            if (this.props.ADRESS.length > 0) {
                LEFT = this.rmFixed2(this.props.PolygonsMinimumOrder - SUM)
            } else {
                LEFT = this.rmFixed2(CONFIG.MINIMAL - SUM)
            }

            return (
                <div className="modal_bottom" id="cart-submit" data-id="cart-submit">
                    <ProductRowRec SUM = {SUM}/>
                    <a className="close-modal-clean orange-button green-button" onClick={() => this.props.openCart(false)}>
                        <img style={{paddingTop: "5px"}} src="/system_images/white_plus.png" />
                        <div style={{paddingLeft: '10px'}}>Нужно ещё добавить</div>
                        <span className="addmore">{LEFT} р</span>
                    </a>
                </div>
            );
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CartButton);