import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ProductCard from "./ProductCard";

function mapStateToProps(state, myProps) {
    return {
        TAGS: state.TAGS
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class TagsSlider extends Component {

    constructor(props)
    {
        super(props);
    }

    renterTagsToSlides = () => {
        let SLIDES = [];
        if ((this.props.TAGS) && (typeof this.props.TAGS !== "undefined")) {
            if (this.props.TAGS.length) {
                for (let i = 0; i < this.props.TAGS.length; i++) {
                    SLIDES.push(
                        <Link className='tags_slider_button' key={i} to={'/tag_' + this.props.TAGS[i].url}>{this.props.TAGS[i].title}</Link>
                    );
                }
            }
        }

        return SLIDES

    }

    renderSlider = () => {
        let settings = {
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 3,
            initialSlide: 0,
            variableWidth: true,
            arrows: false
        };

        return (
            <div>
                <Slider {...settings}>
                    {this.renterTagsToSlides()}
                </Slider>
            </div>
        );
    }

    render() {
        return (
            <div className='slider_holder'>
                {this.renderSlider()}
            </div>

        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TagsSlider))
