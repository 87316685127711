import regionsJson from "../../../Jsons/Delivery.json";

export function checkIdPointInsideCity(gps, region_object)
{
    if (typeof region_object !== "undefined"){
        let x = gps[0];
        let y = gps[1];
        let xp = new Array();
        let yp = new Array();
        for (let i = 0; i < region_object.MKAD.length; i++) {
            xp.push(region_object.MKAD[i][0])
            yp.push(region_object.MKAD[i][1])
        }

        let NEW_REGION = Object.assign(region_object);
        NEW_REGION.INSIDEMKAD = false;

        if (inPoly(x, y, xp, yp)) {
            NEW_REGION.INSIDEMKAD = true;
        }

        return NEW_REGION;
    }
    else return "ДОСТАВКА В ВАШ РЕГИОН НЕВОЗМОЖНА"
}

function inPoly(x, y, xp, yp) {
    let npol = xp.length;
    let j = npol - 1;
    let check = false;
    for (let i = 0; i < npol; i++) {
        if ((((yp[i] <= y) && (y < yp[j])) || ((yp[j] <= y) && (y < yp[i]))) &&
            (x > (xp[j] - xp[i]) * (y - yp[i]) / (yp[j] - yp[i]) + xp[i])) 
        {
            check = !check;
        }

        j = i;
    }
    return check;
}