import React, {Component} from 'react';
import InputMask from "react-input-mask";

export default class NameInput extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            NAME: props.NAME
        }
    }

    recordPhone = (text) => 
    {
        this.validate(text.target.value);
    }

    validate = (string) =>
    {
        if (string.length > 1) {
            this.props.setField('NAME',string)
        } else {
            this.props.resetField('NAME');
        }
    }

    render()
    {
    	return <input placeholder={this.props.placeholder?this.props.placeholder:null} type="text" onChange={(text) => this.recordPhone(text)} defaultValue={this.state.NAME} />
    }
}
