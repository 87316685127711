import React, {Component} from 'react';
import InputMask from "react-input-mask";
import cn from "classnames"

const babies = [
    '/system_images/firstChild.svg',
    '/system_images/secondChild.svg',
    '/system_images/thirdChild.svg',
    '/system_images/firstChild.svg',
]

const popular = [
    '/system_images/home.svg',
    '/system_images/apartment.svg',
    '/system_images/office.svg'
]

export default class ImageRadio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            DATA: props.data,
            FIELD: props.field,
            SELECTED: false
        }
    }

    recordValue = (value) => {
        this.setState({SELECTED: value});
        this.props.setField(this.state.FIELD, value);
    }

    checkSelected = (VALUE) => {
        if (this.state.SELECTED == VALUE) {
            return 'anketa_texted_input selected';
        }

        return 'anketa_texted_input';
    }

    render() {
        let OPTIONS = [];
        for (let i = 0; i < this.state.DATA.length; i++) {
            let CLASS = this.checkSelected(this.state.DATA[i]['VALUE']);
            OPTIONS.push(
                <a key={i} className={CLASS} style={this.props.field === 'CHILDREN' ? {marginBottom: '15px'} : {}}
                   onClick={() => this.recordValue(this.state.DATA[i]['VALUE'])}>
                    <span
                        style={{fontSize: '16px', fontWeight: '900', color: '#000'}}>{this.state.DATA[i]['TEXT']}</span>
                    {this.props.field === 'CHILDREN' &&
                    babies.slice(0, i).map(img => <img style={{margin: '0 0 0 9px'}} src={img}/>)
                    }
                    {this.props.field === 'POINT' && <img style={{margin: '0 0 0 9px'}} src={popular[i]}/>}
                </a>
            );
        }

        return (
            <div
                className={cn(this.props.field === 'ORGANIC' ? null : "anketa_texted_radio", this.props.field === 'CHILDREN' && "children_inner", this.props.field === 'POINT' && 'point')}>
                {OPTIONS}
            </div>
        );
    }
}
