import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import InnerProduct from './../../../APP/COMPONENTS/ContentParts/InnerProduct';
import InnerLoader from './../../../APP/COMPONENTS/ContentParts/InnerLoader';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        CATS: state.CATS,
        PRODUCT_MODAL: state.PRODUCT_MODAL,
        REGION: state.REGION,
        USER: state.USER
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class InnerModalProduct extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            PRODUCT: false,
            ID: this.props.PRODUCT_MODAL,
            dontChangeUrl: false,
        };
    }

    componentDidMount() {
        this.getWebProduct(this.state.ID);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.PRODUCT_MODAL !== prevProps.PRODUCT_MODAL) {
            if (this.props.PRODUCT_MODAL !== false) {
                this.getWebProduct(this.props.PRODUCT_MODAL);
            }
        }
    }

    getProduct = () => {
        /// REPLACED THIS FUNCTION WITH CLEAR CALL OF getWebProduct ///
        this.getWebProduct();
        if (!this.state.PRODUCT) {
            if (this.props.PRODUCTS) {
                let PRODUCT = false;

                if (!this.state.dontChangeUrl) {
                    for (let ID in this.props.PRODUCTS) {
                        if (this.props.PRODUCTS[ID].url === this.state.URL) {
                            PRODUCT = this.props.PRODUCTS[ID];
                            PRODUCT.CAT = this.getParentCat(PRODUCT.group);
                        }
                    }
                }

                if (PRODUCT) {
                    this.setState({PRODUCT: PRODUCT, ERROR: false});
                } else {
                    this.getWebProduct();
                }
            }
        }
    }

    dontChangeUrl = data => {
        this.setState({
            dontChangeUrl: data
        })
    }

    getWebProduct = async (ID) => {
        try {
            let SEND_DATA = {
                action: 'admin_getProductById',
                id: ID,
                region: this.props.REGION,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                let PRODUCT = data;
                PRODUCT.CAT = this.getParentCat(PRODUCT.group);
                this.setState({PRODUCT: PRODUCT, ERROR: false, ID: ID});
            } else {
                this.setState({PRODUCT: 'NONE', ERROR: true});
            }
        } catch (error) {
            this.setState({PRODUCT: 'NONE', ERROR: true});
        }
    }

    getParentCat(id) {
        id = parseInt(id);
        let CAT = false;
        for (let i = 0; i < this.props.CATS.length; i++) {
            if (!CAT) {

                let PARENT_CAT = this.props.CATS[i];
                if (parseInt(PARENT_CAT.id) === id) {
                    CAT = PARENT_CAT;
                }

                if (!CAT) {
                    if (PARENT_CAT.children.length) {
                        for (let j = 0; j < PARENT_CAT.children.length; j++) {
                            let CHILD_CAT = PARENT_CAT.children[j];
                            if (parseInt(CHILD_CAT.id) === id) {
                                CAT = CHILD_CAT;
                            }
                        }
                    }
                }

            }
        }

        return CAT;
    }

    render() {
        if (this.state.PRODUCT) {
            return <InnerProduct product={this.state.PRODUCT} MODAL={true} dontChangeURL={this.dontChangeUrl}/>
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(InnerModalProduct);