import React, {Component} from 'react';
import {Switch, Route, withRouter, Redirect, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { CSSTransitionGroup } from 'react-transition-group';

import SelectSearch, {fuzzySearch} from 'react-select-search';

import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        MANAGERS: state.MANAGERS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Orders extends Component {
	constructor(props)
    {
        super(props);
        this.state = {
            USER: this.props.USER,
            LOADED: false,
            SUPPLIERS: false,
            MANAGERS: false,
            FILTER: this.getFilter(),
            ORDERS: []
        }
    }

    getFilter = () =>
    {
        let FILTER = localStorage.getItem('FILTER');
        if (FILTER && FILTER !== null) {
            return JSON.parse(FILTER);
        }
        return {
            MANAGER: '',
            SUPPLIER: '',
            STATUS: ''
        }
    }

    componentDidMount = () =>
    {
        this.props.changeOrderingView(true)
        this.fetchOrders()
        this.getSuppliers()
    }

    getSuppliers = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'admin_getSuppliers',
                apikey:     this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SUPPLIERS: data})
            }
            return true;
        } catch (error) {
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {

        if (this.props.USER !== prevProps.USER) {
            this.setState({USER: this.props.USER});
        }
    }

    fetchOrders = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'admin_supplierOrders',
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.getManagers(data)
            } else {
                this.setState({LOADED: true});
            }
        } catch (error) {
        }
    }

    getManagers = (ORDERS) =>
    {
        let MANAGERS = [];
        for (let i = 0; i < ORDERS.length; i++) {
            if (!MANAGERS.includes(ORDERS[i].manager_name)) {
                MANAGERS.push(ORDERS[i].manager_name)
            }
        }

        this.setState({ORDERS: ORDERS, LOADED: true, MANAGERS: MANAGERS});
    }

    selectManager = (value) =>
    {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER.MANAGER = value;

        localStorage.setItem('FILTER', JSON.stringify(FILTER))

        this.setState({FILTER: FILTER})
    }

    renderManagerSelect = () =>
	{
		let options = [];
		let added = []
		if (typeof this.state.MANAGERS !== "undefined") {
			for (let i = 0; i < this.state.MANAGERS.length; i++) {
                let option = {name: this.state.MANAGERS[i], value: this.state.MANAGERS[i]};
                if (!added.includes(this.state.MANAGERS[i])) {
                    options.push(option)
                    added.push(this.state.MANAGERS[i]);
                }
			}
		}

		return (
            <div className="select_search_holder">
                <SelectSearch 
                    filterOptions={fuzzySearch} 
                    search={true} 
                    id="manager" 
                    value={this.state.FILTER.MANAGER} 
                    name="manager" 
                    options={options} 
                    placeholder="Менеджер" 
                    onChange={(val) => this.selectManager(val)} 
                />
                <img className="bangled" src="/system_images/blue_angle_down.png"/>
                {this.state.FILTER.MANAGER.length > 0 ? (
					<a className="reset_search_select" onClick={() => this.selectManager('')}>
						<img src="/system_images/green_cross_modal.png" />
					</a>
				) : false}
            </div>
        )
	}

    selectSupplier = (value) =>
    {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER.SUPPLIER = value;

        localStorage.setItem('FILTER', JSON.stringify(FILTER))

        this.setState({FILTER: FILTER})
    }

    renderSupplierSelect = () =>
	{
        if (!this.state.SUPPLIERS) {
            return null
        }

		let options = [{value: 0, name: '', key: 'def'}];
        let added = []

        if (typeof this.state.SUPPLIERS !== "undefined" && this.state.SUPPLIERS) {
            for (let i = 0; i < this.state.SUPPLIERS.length; i++) {
                let option = {
                    key: this.state.SUPPLIERS[i].id,
                    name: this.state.SUPPLIERS[i].title + ' |' + this.state.SUPPLIERS[i].id, 
                    value: this.state.SUPPLIERS[i].title + ' |' + this.state.SUPPLIERS[i].id, 
                };
                if (!added.includes(this.state.SUPPLIERS[i].id)) {
                    options.push(option)
                    added.push(this.state.SUPPLIERS[i].id);
                }
            }
        }


		return (
            <div className="select_search_holder">
                <SelectSearch 
                    filterOptions={fuzzySearch} 
                    search={true} 
                    id="supplier" 
                    value={this.state.FILTER.SUPPLIER} 
                    name="supplier" 
                    options={options} 
                    placeholder="Поставщик" 
                    onChange={(val) => this.selectSupplier(val)} 
                />
                <img className="bangled" src="/system_images/blue_angle_down.png"/>
                {this.state.FILTER.SUPPLIER.length > 0 ? (
					<a className="reset_search_select" onClick={() => this.selectSupplier('')}>
						<img src="/system_images/green_cross_modal.png" />
					</a>
				) : false}
            </div>
        )
	}

    selectStatus = (value) =>
    {
        let FILTER = Object.assign({}, this.state.FILTER)
        FILTER.STATUS = value;

        localStorage.setItem('FILTER', JSON.stringify(FILTER))

        this.setState({FILTER: FILTER})
    }

    renderStatusSelect = () =>
    {
        let options = [
            {value: 0, name: '', key: 'def'},
            {
                key: 0,
                name: 'Новый', 
                value: 0, 
            },
            {
                key: 1,
                name: 'На согласовании', 
                value: 1, 
            },
            {
                key: 2,
                name: 'Ожидание поставки', 
                value: 2, 
            },
            {
                key: 3,
                name: 'Отменён', 
                value: 3, 
            },
            {
                key: 4,
                name: 'Принят', 
                value: 4, 
            },
        ]
        
        return (
            <div className="select_search_holder">
                <SelectSearch 
                    filterOptions={fuzzySearch} 
                    search={true} 
                    id="supplier" 
                    value={this.state.FILTER.STATUS} 
                    name="supplier" 
                    options={options} 
                    placeholder="Статус" 
                    onChange={(val) => this.selectStatus(val)} 
                />
                <img className="bangled" src="/system_images/blue_angle_down.png"/>
                {this.state.FILTER.STATUS !== '' ? (
					<a className="reset_search_select" onClick={() => this.selectStatus('')}>
						<img src="/system_images/green_cross_modal.png" />
					</a>
				) : false}
            </div>
        )
    }

    renderFilter = () =>
    {
        return (
            <div className="ordering_filter">
                <div>
                <label className="select_label">Статус</label>
                    {this.renderStatusSelect()}
                    <label className="select_label">Выбрать менеджера</label>
                    {this.renderManagerSelect()}
                    <label className="select_label">Выбрать поставщика</label>
                    {this.renderSupplierSelect()}
                    
                </div>
            </div>
        )
    }

    filterOrders = () =>
    {
        let FILTERED_ORDERS = []
        let FILTER_SUP_PART = ''
        if (this.state.FILTER.SUPPLIER.length) {
            let PARTS = this.state.FILTER.SUPPLIER.split('|');
            FILTER_SUP_PART = PARTS[1]
        }

        for (let i = 0; i < this.state.ORDERS.length; i++) {
            let ORDER = this.state.ORDERS[i];

            let USE = true;

            if (USE == true && this.state.FILTER.STATUS !== '') {
                if (parseInt(this.state.FILTER.STATUS) !== parseInt(ORDER.status)) {
                    USE = false
                }
            }

            if (USE == true && this.state.FILTER.MANAGER.length) {
                if (this.state.FILTER.MANAGER !== ORDER.manager_name) {
                    USE = false
                }
            }

            if (USE == true && this.state.FILTER.SUPPLIER.length) {
                if (parseInt(FILTER_SUP_PART) !== parseInt(ORDER.supplier)) {
                    USE = false
                }
            }

            if (USE) {
                FILTERED_ORDERS.push(ORDER)
            }
        }

        return FILTERED_ORDERS
    }

    renderStatusBadge = (status) =>
    {
        let title = 'Новый';
        let className = 'new';

        switch (parseInt(status)) {
            case 1:
                title = 'На согласовании';
                className = 'talk';
                break;
            case 2:
                title = 'Ожидание поставки';
                className = 'waiting';
                break;
            case 3:
                title = 'Отменён';
                className = 'cancelled';
                break;
            case 4:
                title = 'Принят';
                className = 'done';
                break;
        }

        return <span className={'order_status ' + className}>{title}</span>
    }

    getSupplier = (supplier) =>
    {
        if (!this.state.SUPPLIERS) {
            return '-'
        }

        let useSupplier = this.state.SUPPLIERS.filter(sup => {
            if (sup.id == supplier) {
                return true
            }

            return false
        })

        if (useSupplier.length) {
            return useSupplier[0].title
        }

        return '-';
    }

    renderOrdersRows = (ORDERS, SAVEABLE) =>
    {
        let ROWS = [];
        for (let i = 0; i < ORDERS.length; i++) {
            let ORDER = ORDERS[i];

            ROWS.push(
                <tr key={i}>
                    <td>{this.renderStatusBadge(ORDER.status)}</td>
                    <td className="cell-center">{ORDER.id}</td>
                    <td className="cell-center text-left"><strong>{this.getSupplier(ORDER.supplier)}</strong></td>
                    <td className="cell-center">{ORDER.manager_name}</td>
                    <td className="cell-center">{ORDER.order_date}</td>
                    <td className="cell-center">{ORDER.delivery_date}</td>
                    <td className="cell-center">{ORDER.delivery_time}</td>
                    <td className="cell-center">{ORDER.sum} руб</td>
                    <td><Link to={"/orders/" + ORDER.id} className="my_own_price">перейти</Link></td>
                </tr>
            );
        }

        return (
            <tbody>
                {ROWS}
            </tbody>
        );
    }

    renderOrdersTableHeader = () =>
    {
        return (
            <thead>
                <tr>
                    <th>статус</th>
                    <th>номер</th>
                    <th>поставщик</th>
                    <th>менеджер</th>
                    <th>дата заказа</th>
                    <th>дата поставки</th>
                    <th>время поставки</th>
                    <th>сумма</th>
                    <th></th>
                </tr>
            </thead>
        );
    }

    renderOrders = () =>
    {
        if (this.state.LOADED) {
            if (this.state.ORDERS.length) {
                let ORDERS = this.filterOrders();
                let TBODY = this.renderOrdersRows(ORDERS);
                let HEADER = this.renderOrdersTableHeader();

                return (
                    <div className="table_holder">
                        <table id="products_table" className="products_table" cellPadding="0" cellSpacing="0">
                            {HEADER}
                            {TBODY}
                        </table>                      
                    </div>
                );

            } else {
                return <div className="table_holder"><h3>Мы не нашли заказов в базе</h3></div>;
            }
        } else {
            return <div className="table_holder"><div className="loader_content">Собираем статистику по заказам ...</div></div>
        }
    }

    render() {
        if (!this.state.USER) {
            return <Redirect to="/"/>
        }

        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
            <div className="right-part ordering">
                <Helmet>
                    <title>Заказы</title>
                </Helmet>
                <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid">
                            <h1>Заказы</h1>
                            <Link to="/ordering" className="back_btn">Назад</Link>
                            {
                                this.state.SUPPLIERS && this.state.MANAGERS && !this.state.ORDERS.length ?<></> :
                                <>
                                    {this.renderFilter()}
                                    {this.renderOrders()}
                                </>
                            }
                            
                        </div>
                    </div>
            </div>
            </CSSTransitionGroup>
        );
	}
}

export default withRouter(connect(
	mapStateToProps, mapDispatchToProps
)(Orders))
