import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import HomePage from './../../APP/SCENES/HomePage';
import ShopEnter from './../../APP/SCENES/ShopEnter';
import Ordering from './../../APP/SCENES/Ordering';
import Orders from './../../APP/SCENES/Orders';
import Order from './../../APP/SCENES/Order';
import Company from './../../APP/SCENES/Company';
import Search from './../../APP/SCENES/Search';
import MyProducts from './../../APP/SCENES/MyProducts';
import UserPoll from './../../APP/SCENES/UserPoll';
import RefEntrance from './../../APP/SCENES/RefEntrance';
import OrderConfirm from './../../APP/SCENES/OrderConfirm';
import BlogCatalogue from './../../APP/SCENES/BlogCatalogue';
import BlogSingle from './../../APP/SCENES/BlogSingle';
import Unsubscribe from './../../APP/SCENES/Unsubscribe';

// STATIC PAGES ///
import Delivery from './../../APP/SCENES/STATIC_PAGES/Delivery';
import About from './../../APP/SCENES/STATIC_PAGES/About';
import Closer from './../../APP/SCENES/Closer';
import Suppliers from './../../APP/SCENES/STATIC_PAGES/Suppliers';
import Contacts from './../../APP/SCENES/STATIC_PAGES/Contacts';
import Sapsan from './../../APP/SCENES/STATIC_PAGES/Sapsan'
import Zoo from './../../APP/SCENES/STATIC_PAGES/Zoo'
import SpbZoo from './../../APP/SCENES/STATIC_PAGES/SpbZoo'
import IdsSelecter from './../../APP/SCENES/STATIC_PAGES/IdsSelecter'
import PlanetaZdorovya from './../../APP/SCENES/STATIC_PAGES/PlanetaZdorovya'
import Bars from './../../APP/SCENES/STATIC_PAGES/Bars'

import XFit from "../SCENES/STATIC_PAGES/XFit/XFit.js";

import {Redirect} from "react-router";
import SaveNature from "../SCENES/STATIC_PAGES/SaveNature/SaveNature";


function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class content extends Component {
	render() {
		return (
			<Switch>
				<Route exact path='/' component={HomePage}/>
				<Route exact path='/ordering' component={Ordering}/>
				<Route exact path='/closer' component={Closer}/>
				<Route exact path='/orders/:id' component={Order}/>
				<Route exact path='/orders' component={Orders}/>
				<Route path='/search/:string' component={Search}/>				
				<Route path='/:url' component={ShopEnter}/>
			</Switch>
		);
	}
}

export default withRouter(connect(
	false,
	mapDispatchToProps
)(content))
