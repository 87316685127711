import React, { Component } from 'react';

import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ToggleButton from "./../../../APP/COMPONENTS/ContentParts/ToggleButton";
import InputMask from "react-input-mask";

function mapStateToProps(state, myProps) {

    return {
        USER: state.USER,
        CATS: state.CATS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class SupplierEditForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            phone: props.supplier.phone,
            phone2: props.supplier.phone2,
            phone3: props.supplier.phone3,
            email: props.supplier.email,
            minimal_sum: props.supplier.minimal_sum,
            category: props.supplier.category,
            pickup: props.supplier.pickup == 0 ? false : true,
            order_days: props.supplier.order_days,
            password: '',
            delivery_days: props.supplier.delivery_days,
            delivery_times: props.supplier.delivery_times,
            delivery_times2: props.supplier.delivery_times2,
            phones: props.supplier.phones,
            emails: props.supplier.emails,
            autoorder: props.supplier.autoorder,
            selforder: props.supplier.selforder,
            com_percent: props.supplier.com_percent
        };

        console.log(this.state)
    }

    addPhone = () => {
        let phones = [...this.state.phones]
        phones.push('');
        this.setState({ phones: phones })
    }

    addEmail = () => {
        let emails = [...this.state.emails]
        emails.push('');
        this.setState({ emails: emails })
    }

    sendLogin = async () => {
        this.saveData(true);
        try {
            let SEND_DATA = {
                action: 'admin_sendSupplierLogin',
                id: this.props.supplier.id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                alert('Данные для входа отправлены')
            } else {
            }
        } catch (error) {
        }
    }

    saveData = async (alerting) => {
        try {
            let SEND_DATA = {
                action: 'admin_editSupplierData',
                id: this.props.supplier.id,
                phone: this.state.phone,
                phone2: this.state.phone2,
                phone3: this.state.phone3,
                email: this.state.email,
                category: this.state.category,
                pickup: this.state.pickup == true ? 1 : 0,
                order_days: this.state.order_days,
                order_times: this.state.order_times,
                delivery_days: this.state.delivery_days,
                delivery_times: this.state.delivery_times,
                delivery_times2: this.state.delivery_times2,
                minimal_sum: this.state.minimal_sum,
                password: this.state.password,
                phones: this.state.phones,
                emails: this.state.emails,
                autoorder: this.state.autoorder,
                selforder: this.state.selforder,
                com_percent: this.state.com_percent,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.props.updateSuppliers()
                if (typeof alerting == "undefined") {
                    alert('Данные по поставщику сохранены')
                }
            } else {
            }
        } catch (error) {
        }
    }

    recordField = (value, field) => {
        this.setState({ [field]: value }, () => console.log(this.state))
    }

    recordTapSelect = (value, field) => {
        let currentValues = [...this.state[field]]
        let selectedValues = [];

        let FOUND = false;

        for (let i = 0; i < currentValues.length; i++) {
            if (currentValues[i] !== value) {
                if (currentValues[i].length > 0 && currentValues[i] !== ",") {
                    selectedValues.push(currentValues[i])
                }
            } else {
                FOUND = true
            }
        }
        if (!FOUND) {
            selectedValues.push(value)
        }

        this.recordField(selectedValues.join(','), field)
    }

    recordMultipleSelect = (values, field) => {
        let selectedValues = [];
        for (let i = 0; i < values.length; i++) {
            selectedValues.push(values[i].value);
        }

        this.recordField(selectedValues.join(','), field)
    }

    renderCatsSelect = () => {
        let current = this.state.category.split(',')
        let options = [];
        let defaultValues = []

        for (let i = 0; i < this.props.CATS.length; i++) {
            let id = this.props.CATS[i].id
            if (current.includes(id)) {
                defaultValues.push(id)
            }

            options.push(
                <option key={ id } value={ id }>{ this.props.CATS[i].title }</option>
            )

            if (this.props.CATS[i].children && this.props.CATS[i].children.length) {
                for (let j = 0; j < this.props.CATS[i].children.length; j++) {
                    let id2 = this.props.CATS[i].children[j].id
                    if (current.includes(id2)) {
                        defaultValues.push(id2)
                    }

                    options.push(
                        <option key={ id2 } value={ id2 }>-- { this.props.CATS[i].children[j].title }</option>
                    )
                }
            }
        }

        return (
            <select
                multiple={ true }
                onChange={ (select) => { this.recordMultipleSelect(select.target.selectedOptions, 'category') } }
                defaultValue={ defaultValues }
            >
                { options }
            </select>
        )
    }

    renderDaysSelect = (field, values) => {
        let options = {
            1: 'Пн',
            2: 'Вт',
            3: 'Ср',
            4: 'Чт',
            5: 'Пт',
            6: 'Сб',
            7: 'Вс'
        }

        let defaultValues = []
        if (typeof values !== 'array') {
            defaultValues = values.split(",")
        }

        let options_viewable = [];

        for (let i in options) {
            let className = '';
            if (defaultValues.includes(i.toString())) {
                className = ' selected';
            }

            options_viewable.push(
                <a className={ 'tap_option ' + className } key={ i } onClick={ () => this.recordTapSelect(i, field) }>
                    { options[i] }
                </a>
            )
        }

        return (
            <div className="tappable_options">
                { options_viewable }
            </div>
        )
    }

    renderTimeSelect = (from, till, field, value) => {
        let options = [<option key="0"></option>]

        for (let i = from; i <= till; i++) {
            let numb = i;
            if (i < 10) {
                numb = '0' + i;
            }
            numb += ':00';

            options.push(<option key={ i } value={ numb }>{ numb }</option>)
        }

        return (
            <select
                onChange={ (select) => { this.recordField(select.target.value, field) } }
                defaultValue={ value }
            >
                { options }
            </select>
        )
    }

    renderDeliveryDatesAndTimes = () => {
        if (!this.state.pickup) {
            return (
                <>
                    <h6>Дни поставки:</h6>
                    <div>{ this.renderDaysSelect('delivery_days', this.state.delivery_days) }</div>
                    <h6>Время поставки от:</h6>
                    <div>{ this.renderTimeSelect(8, 20, 'delivery_times', this.state.delivery_times) }</div>
                    <h6>Время поставки до:</h6>
                    <div>{ this.renderTimeSelect(8, 20, 'delivery_times2', this.state.delivery_times2) }</div>
                </>
            )
        }

        return null
    }

    renderSupplierStats = () => {
        return (
            <div className="supplier_stats">
                <div>
                    <span>Всего:</span><br />{ this.props.supplier.all }
                </div>
                <div>
                    <span>Акт:</span><br />{ this.props.supplier.active }
                </div>
                <div>
                    <span>Сезон:</span><br />{ this.props.supplier.season }
                </div>
            </div>
        )
    }

    deleteValueByIndex = (field, index) => {
        let values = this.state[field]
        let new_values = []

        for (let i = 0; i < values.length; i++) {
            if (i !== index) {
                new_values.push(values[i])
            }
        }

        this.setState({ [field]: new_values })
    }

    recordFieldByIndex = (value, field, index) => {
        let values = [...this.state[field]]
        values[index] = value

        this.setState({ [field]: values })
    }

    renderPhones = () => {
        let phones = this.state.phones;

        let returns = [];
        for (let i = 0; i < phones.length; i++) {
            returns.push(
                <div className="inliner" key={ i }>
                    <InputMask
                        defaultValue={ phones[i] }
                        placeholder="Телефон"
                        type="tel"
                        className="phone mmm"
                        mask="+7-999-999-99-99"
                        onChange={ (text) => this.recordFieldByIndex(text.target.value, 'phones', i) }
                    />
                    <a onClick={ () => this.deleteValueByIndex('phones', i) }><img src="/system_images/green_cross_modal.png" /></a>
                </div>
            )
        }

        return (
            <div>
                <a className="add_contact" onClick={ () => this.addPhone() }>+ Добавить телефон</a>
                { returns }
            </div>
        );
    }

    renderEmails = () => {
        let emails = this.state.emails;

        let returns = [];
        for (let i = 0; i < emails.length; i++) {
            returns.push(
                <div className="inliner" key={ i }>
                    <input
                        defaultValue={ emails[i] }
                        placeholder="Email"
                        type="email"
                        className="phone mmm"
                        onChange={ (text) => this.recordFieldByIndex(text.target.value, 'emails', i) }
                    />
                    <a onClick={ () => this.deleteValueByIndex('emails', i) }><img src="/system_images/green_cross_modal.png" /></a>
                </div>
            )
        }

        return (
            <div>
                <a className="add_contact" onClick={ () => this.addEmail() }>+ Добавить email</a>
                { returns }
            </div>
        );
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    renderDebt = () => {
        if (this.props.supplier.debt > 0) {
            return (
                <div className="supplier_debt_block">
                    Задолженность: { this.numberWithCommas(this.props.supplier.debt) } руб
                </div>
            )
        }
    }

    render() {

        return (
            <div className="sup_edit_form">
                { this.renderSupplierStats() }
                { this.renderDebt() }
                <h6>Телефон:</h6>
                <div>
                    <InputMask
                        defaultValue={ this.state.phone }
                        placeholder="Телефон"
                        type="tel"
                        className="phone"
                        mask="+7-999-999-99-99"
                        onChange={ (text) => this.recordField(text.target.value, 'phone') }
                    />
                </div>

                <h6>Email для входа:</h6>
                <div>
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={ (input) => this.recordField(input.target.value, 'email') }
                        required=""
                        defaultValue={ this.state.email }
                    />
                </div>
                <h6>Пароль для входа:</h6>
                <div>
                    <input
                        type="password"
                        placeholder="Пароль"
                        onChange={ (input) => this.recordField(input.target.value, 'password') }
                        required=""
                        defaultValue={ this.state.password }
                    />
                </div>

                <h6>Телефоны для уведомлений</h6>
                { this.renderPhones() }
                <h6>Почты для уведомлений</h6>
                { this.renderEmails() }

                <h6>Минимальная сумма заказа:</h6>
                <div>
                    <input
                        type="number"
                        placeholder="Минимальная сумма заказа"
                        onChange={ (input) => this.recordField(input.target.value, 'minimal_sum') }
                        required=""
                        defaultValue={ this.state.minimal_sum }
                    />
                </div>
                { /*
                    <h6>Категории:</h6>
                    <div>{this.renderCatsSelect()}</div>
                */ }
                <h6>Дни заказа:</h6>
                <div>{ this.renderDaysSelect('order_days', this.state.order_days) }</div>
                <h6>Заказать до:</h6>
                <div>{ this.renderTimeSelect(8, 20, 'order_times', this.state.order_times) }</div>
                <div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
                    <h6 style={ { marginRight: '20px' } } htmlFor="agree">
                        <span>Самовывоз:</span>
                    </h6>
                    <ToggleButton isOn={ this.state.pickup } onToggle={ () => {
                        this.recordField(!this.state.pickup, 'pickup')
                    } } />
                </div>
                { this.renderDeliveryDatesAndTimes() }

                <div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
                    <h6 style={ { marginRight: '20px' } } htmlFor="agree">
                        <span>Участвует в АвтоЗаказе:</span>
                    </h6>
                    <ToggleButton isOn={ this.state.autoorder == 1 ? true : false } onToggle={ () => {
                        this.setState({ autoorder: this.state.autoorder == 1 ? 0 : 1 })
                    } } />
                </div>

                <div style={ { display: 'flex', alignItems: 'center' } } className="p_check agree">
                    <h6 style={ { marginRight: '20px' } } htmlFor="agree">
                        <span>Может сам формировать заказы из кабинета поставщика:</span>
                    </h6>
                    <ToggleButton isOn={ this.state.selforder == 1 ? true : false } onToggle={ () => {
                        this.setState({ selforder: this.state.selforder == 1 ? 0 : 1 })
                    } } />
                </div>

                <h6>Процент комиссионера:</h6>
                <div>
                    <input
                        type="number"
                        placeholder="Процент комиссионера"
                        onChange={ (input) => this.recordField(input.target.value, 'com_percent') }
                        required=""
                        value={ this.state.com_percent }
                    />
                </div>

                <div className="sup_buttons">
                    <a className="sup_send" onClick={ () => this.sendLogin() }>
                        Отправить ссылку
                    </a>
                    <a className="sup_save" onClick={ () => this.saveData() }>
                        Сохранить
                    </a>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplierEditForm)