import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        EMPTY_CART_MODAL: state.EMPTY_CART_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class EmptyCart extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
        };
        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.EMPTY_CART_MODAL !== prevProps.EMPTY_CART_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.EMPTY_CART_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    emptyCart()
    {
        this.props.emptyCart();
        this.props.openCart(false);
        this.props.emptyCartModal(false);
    }

	render()
    {
        if (this.props.EMPTY_CART_MODAL) {
            return (
                <div>
                    <div className="modal-overlay confirm-overlay" onClick={() => this.props.emptyCartModal(false)}>
                    </div>
                    <div className="modal-box confirm-modal">
                        <div className="confirm_heading">
                        	Очистить корзину?
                        </div>
                        <div className="confirm_buttons">
                        	<a className="confirm_button_true" onClick={() => this.emptyCart()}>
                        		ОК
                        	</a>
                        	<a className="confirm_button_false" onClick={() => this.props.emptyCartModal(false)}>
                        		Отмена
                        	</a>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(EmptyCart);