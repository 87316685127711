import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import InputMask from "react-input-mask";

function mapStateToProps(state, myProps) {
    return {
        REGION: state.REGION,
        USER: state.USER,
        TOKEN: state.TOKEN
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Zoo extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            REGION: props.REGION,
            SHOW_RULES: false,
            CHOSEN: false,
            ENTER_CODE: false,
            PHONE: '+7-___-___-__-__',
            CODE: '____',
            TOKEN: this.props.TOKEN,
            BOX: 1,
            VOTES: {},
            LOGIN: false,
            SHOW_DROP: false,
            POLLED: false
        }

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.items = this.getItemsPerScreen();

        this.handleScroll = this.handleScroll.bind(this);
        this.setBoxesRef = this.setBoxesRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.TOKEN !== this.state.TOKEN) {
            this.setState({TOKEN: this.props.TOKEN});
        }

        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    recordPhone = (phone) => 
    {
        this.setState({PHONE: phone.target.value});
    }

    setBoxesRef(node) {
        this.drop = node;
    }

    handleClickOutside(event) {
        if (this.drop && !this.drop.contains(event.target)) {
            if (this.state.SHOW_DROP) {
                this.setState({SHOW_DROP: false});
            }
        }
    }

    recordCode = (code) => 
    {
        this.setState({CODE: code.target.value});
        code = code.target.value.toString().replace(/\D+/g, '');

        if (code.length > 3) {
            this.doConfirm(code);
        }
    }

    recordChosen = async() =>
    {
        try {
            let SEND_DATA = {
                action:     'zooPoll',
                apikey:      this.props.USER.TOKEN,
                chosen:      this.state.CHOSEN,
                box:         this.state.BOX,
                region:      this.state.REGION,
                source:     'web'
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data) {
                this.setState({POLLED: true})
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    fetchStatistics = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'zooPollStats',
                token:      this.state.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({VOTES: data.votes, BOXES: data.boxes});
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    doConfirm = async(code) =>
    {
        try {
            let SEND_DATA = {
                action:     'doConfirm',
                token:      this.state.TOKEN,
                phone:      this.state.PHONE,
                code:       code,
                source:     'web'
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ENTER_CODE: false, LOGIN: false});
                this.props.doAuth(data.user);
                this.getServerFavs(data.user);
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    componentDidMount()
    {
        this.fetchStatistics();
        document.addEventListener('mousedown', this.handleClickOutside);
    }



    handleScroll(event) {

    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    getItemsPerScreen()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 1;
        } else if (WIDTH < 769) {
            return 1;
        } else if (WIDTH < 1050) {
            return 2;
        } else if (WIDTH < 1200) {
            return 3;
        } else if (WIDTH < 9900) {
            return 3;
        }
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });

        DATA.push({
            'to' : '/company/zoo',
            'title' : 'Московский зоопарк'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    getTitle = () =>
    {
    	switch (this.state.REGION) {
			default:
    			return 'Экомаркет – верный друг Московского зоопарка, мы помогаем его обитателям стать счастливее';
    			break;
    	}
    }

    getKeywords = () =>
    {
    	switch (this.state.REGION) {
    		default:
    			return 'Экомаркет – верный друг Московского зоопарка, мы помогаем его обитателям стать счастливее';
    			break;
    	}
    }

    getDescription = () =>
    {
    	switch (this.state.REGION) {
    		default:
    			return 'Экомаркет – верный друг Московского зоопарка, мы помогаем его обитателям стать счастливее';
    			break;
    	}
    }

    renderRulesModal = () =>
    {
        if (!this.state.SHOW_RULES) {
            return false;
        } else {
            return (
                <div className="zoo_rules_holder">
                    <div className="zoo_closer"  onClick={() => this.setState({SHOW_RULES: false})}>
                    </div>
                    <div className="zoo_rules_modal">
                        <a className="zoo_close" onClick={() => this.setState({SHOW_RULES: false})}>
                            <img src="/system_images/zoo_close.png" />
                        </a>
                        <div className="zoo_rules_modal_text">
                        <p>
                        Получив заказ Экомаркет в коробке, пожалуйста не выкидывайте ее, а передайте при следующем заказе нашему курьеру, он заберет ее и вернет на склад.
                        </p>
                        <p>
                        Раз в месяц, все накопленные коробки мы будем передавать в пункт приема макулатуры для дальнейшей переработки и изготовления новых картонных изделий.
                        <br/>
                        Средства, полученные нами за сдачу коробок, мы передадим Московскому зоопарку для поддержки его обитателей: жирафа Самсона и слона Памира.
                        </p>
                        <p>
                        Самсон Гамлетович Ленинградов родился в Ленинградском зоопарке в 1993 году (отсюда и фамилия) и переехал в Москву в трёхлетнем возрасте. Добродушный, миролюбивый, он с удовольствием общается с людьми. Самая любимая еда Самсона — листики ивы, бананы, яблоки и морковь.
                        </p>
                        <p>
                        Памир – аристократичный самец, вежливый, раньше был хулиганом, но теперь остепенился. Но, помимо этого, он ещё и хитёр. Около трёх лет назад посетители подняли панику, они увидели лежащего без движения слона и решили, что он умер. Сотрудники пытались его позвать, но безрезультатно, Памир не шевелился. Тогда ему в вольер накидали много еды, через какое-то время он встал, всё съел и остался очень доволен. Позднее, когда Памир неоднократно повторял свой трюк, сотрудники зоопарка поняли, что он, пытаясь получить побольше лакомств, просто манипулирует ими.
                        </p>
                        </div>
                    </div>
                </div>
            );
        }
    }

    enterLK = () =>
    {

    }

    renderNameOrMessage = () =>
    {
        if (this.props.USER) {
            return (
                    <span>
                        {this.props.USER.NAME}
                    </span>
            );
        } else {
            return 'Войти';
        }
    }

    renderFirstLine()
    {
        return (
            <a 
                className="auth zoo-auth" 
                onClick={() => this.props.openLK(true)}
            >
                {this.renderNameOrMessage()}
                {this.renderSubLine()}
            </a>
        );
    }

    num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderSubLine = () =>
    {
        if (this.props.USER) {
            return (
                <div>
                    <div className="i-flocktory" data-fl-user-name={this.props.USER.NAME} data-fl-user-email={this.props.USER.PHONE.replace(/\D/g, "") + '@unknown.email'}></div>
                </div>
            );
             
        }
    }

    renderBlogerBadge = () =>
    {
        if (this.props.USER) {
            if (parseInt(this.props.USER.STATUS) == 4) {
                return <img src="/system_images/blogger/bloger.png" className="bloger_badge" />
            }
        }
    }

    renderUserBlock = () =>
    {
        return (
            <div className="head_user">
                <div className="head_user_one">
                    {this.renderFirstLine()}
                </div>
                <a className="head_user_two" onClick={() => this.props.openLK(true)} >
                    <img src="/system_images/white-user.png" />
                    {this.renderBlogerBadge()}
                </a>
            </div>
        );
    }

    submitPhone = async () =>
    {
        let SEND_DATA = {
            action:     'doRegisterOrRecover',
            phone:      this.state.PHONE,
            source:     'web',
            token:      this.state.TOKEN
        };

        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
        if (data !== false) {
            this.setState({ENTER_CODE: true});
        }
    }

    renderSubmitPhoneButton = () =>
    {
        let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
            return (
                <a className="zoo_submit" onClick={() => this.submitPhone()}>
                    Отправить
                </a>
            );
        } else {
            return (
                <a className="zoo_submit disabled">
                    Отправить
                </a>
            );
        }
    }

    renderLineFiller = (vote,use) =>
    {
        let PERCENT = 0;
        let TOTAL = 0;
        let CURRENT = 0;

        for (let i in this.state.VOTES) {
            if (parseInt(this.state.VOTES[i].chosen) == vote) {
                CURRENT = parseInt(this.state.VOTES[i].votes);
                TOTAL += parseInt(this.state.VOTES[i].votes);
            } else {
                if (use.indexOf(parseInt(this.state.VOTES[i].chosen)) != -1) {
                    TOTAL += parseInt(this.state.VOTES[i].votes);
                }
            }
        }

        PERCENT = Math.round(CURRENT / (TOTAL / 100));

        return (
            <div className="zoo_check_line_filled" style={{width: PERCENT + '%'}}></div>
        );
    }

    getPolledAmount = (vote) =>
    {
        let CURRENT = 0;
        for (let i in this.state.VOTES) {
            if (parseInt(this.state.VOTES[i].chosen) == vote) {
                CURRENT = parseInt(this.state.VOTES[i].votes);
            }
        }

        return CURRENT + ' ' + this.num_title(CURRENT, ['человек', 'человека', 'человек'])
    }

    showBoxSelect = () =>
    {
        let CURRENT = this.state.SHOW_DROP;
        if (CURRENT) {
            this.setState({SHOW_DROP: false})
        } else {
            this.setState({SHOW_DROP: true})
        }
    }

    renderBoxesSelect = () =>
    {
        let OPTIONS = [];
        for (let i = 1; i <= 5; i++) {
            let sel = '';
            if (i == this.state.BOX) {
                sel = ' activated';
            }

            OPTIONS.push(
                <a key={i} className={'zoo_drop_item' + sel} onClick={() => this.setState({BOX: i, SHOW_DROP: false})}>
                    {i}
                </a>
            );
        }

        let DROP_SELECT = '';
        if (this.state.SHOW_DROP) {
            DROP_SELECT = (
                <div className="zoo_boxes_drop" ref={this.setBoxesRef}>
                    {OPTIONS}
                </div>
            );
        }

        return (
            <div className="zoo_boxes_select">
                <span className="zoo_boxes_active" onClick={() => this.showBoxSelect()}>
                    {this.state.BOX} <img src="/system_images/zoo_angle.png" />
                </span>
                {DROP_SELECT}
            </div>

        );
    }

    doSubmitPoll = () =>
    {
        if (this.state.CHOSEN) {
            this.recordChosen();
        }
    }

    renderSubmitBtn = () =>
    {
        if (this.state.CHOSEN) {
            return (
                <a onClick={() => this.doSubmitPoll()} className="zoo_submit_btn">
                    Проголосовать
                </a>
            );
        } else {
            return (
                <a className="zoo_submit_btn dis">
                    Проголосовать
                </a>
            );
        }
    }

    renderActions = () =>
    {
        if (this.state.POLLED) {
            return (
                <div>
                    <div className="zoo_polled_1">
                        Спасибо за участие!
                    </div>
                    <div className="zoo_polled_2">
                        Мы учтём ваш голос
                    </div>
                    <div className="zoo_polled_3">
                        Не забудьте передать курьеру пустую<br/>коробку при следующем заказе
                    </div>
                </div>
            );
        }
        if (!this.state.LOGIN) {
            return (
                <div>
                    <div className="zoo_checks_text">
                        Поставьте галочку в выбранном поле
                    </div>
                    <div className="zoo_checks">
                        <div className="zoo_check_item">
                            {this.renderCheck1()}
                            <div className="zoo_check_line l">
                                {this.renderLineFiller(1,[1,2])}
                            </div>
                            <div className="zoo_already">
                                уже проголосовало
                            </div>
                            <div className="zoo_already_number">
                                {this.getPolledAmount(1)}
                            </div>
                        </div>
                        <div className="zoo_check_item">
                            {this.renderCheck2()}
                            <div className="zoo_check_line r">
                                {this.renderLineFiller(2,[1,2])}
                            </div>
                            <div className="zoo_already">
                                уже проголосовало
                            </div>
                            <div className="zoo_already_number">
                                {this.getPolledAmount(2)}
                            </div>
                        </div>
                    </div>
                    <div className="zoo_boxes_row">
                        <div className="zoo_boxes_intro">
                            Я сдам
                        </div>
                        {this.renderBoxesSelect()}
                        <div className="zoo_boxes_outro">
                            коробок курьеру со следующим заказом
                        </div>
                    </div>
                    <div>
                        {this.renderSubmitBtn()}
                    </div>
                </div>
            );
        } else {
            if (!this.state.ENTER_CODE) {
                return (
                    <div className="zoo_login_block">
                        <div className="zoo_login_text mobile_yellow">
                            Пожалуйста, авторизируйтесь,<br/>чтобы мы смогли учесть ваш голос
                        </div>
                        <div className="zoo_login_phoned">
                            <InputMask value={this.state.PHONE} id="enter_phone" placeholder="Введите номер телефона" type="tel" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} />
                            {this.renderSubmitPhoneButton()}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="zoo_login_block">
                        <div className="zoo_login_text">
                            На ваш номер отправлен 4-х значный код.<br/>Введите его в поле ниже
                        </div>
                        <div className="zoo_login_coded">
                            <InputMask value={this.state.CODE} id="enter_code" reset="true" maskPlaceholder="_ _ _ _" placeholder="_ _ _ _" type="tel" mask="9 9 9 9" onChange={(text) => this.recordCode(text)} defaultValue="" />
                        </div>
                        <a className="zoo_renter_phone" onClick={() => this.setState({ENTER_CODE: false})}>
                            Ввести номер заново
                        </a>
                    </div>
                );
            }
        }
    }

    renderCheck2 = () =>
    {
        if (this.state.CHOSEN) {
            if (this.state.CHOSEN == 2) {
                return (
                    <div className="zoo_checkbox">
                        <img src="/system_images/zoo_check.png" />
                    </div>
                );
            } else {
                return (
                    <div className="zoo_checkbox to_check" onClick={() => {
                    this.recordVote(2);
                }}>
                </div>
                );
            }
        } else {
            return (
                <div className="zoo_checkbox to_check" onClick={() => {
                    this.recordVote(2);
                }}>
                </div>
            );
        }
    }

    renderCheck1 = () =>
    {
        if (this.state.CHOSEN) {
            if (this.state.CHOSEN == 1) {
                return (
                    <div className="zoo_checkbox">
                        <img src="/system_images/zoo_check.png" />
                    </div>
                );
            } else {
                return (
                    <div className="zoo_checkbox to_check" onClick={() => {
                    this.recordVote(1);
                }}>
                </div>
                );
            }
        } else {
            return (
                <div className="zoo_checkbox to_check" onClick={() => {
                    this.recordVote(1);
                }}>
                </div>
            );
        }
    }

    recordVote = (VOTE) =>
    {
        if (this.props.USER) {
            this.setState({CHOSEN: VOTE});
        } else {
            this.setState({LOGIN: true});
        }
    }

    renderText = () =>
    {
        if (this.state.CHOSEN) {
            if (WIDTH > 600) {
                return <span>Спасибо!<br/>Не забудьте передать курьеру пустую<br/>коробку при следующем заказе</span>;
            }
        } else {
            if (WIDTH > 600) {
                return 'Выберите животное, которому вы хотите передать средства от переработки коробки, которую вы отдадите нашему курьеру';
            }
        }
    }

    checkFadedClass = () =>
    {
        if (this.state.SHOW_RULES) {
            return ' blur_and_fade';
        }

        return '';
    }

    renderFurtherButton = () =>
    {
        if (this.state.CHOSEN) {
            return (
                <Link className="zoo_further" to="/">
                    Оформить следующий заказ
                </Link>
            );
        }
    }

    renderMobileThank = () =>
    {
        if (WIDTH <= 600) {
            if ((this.props.USER) && (this.state.CHOSEN)) {
                return <div className="zoo_action_text"><span>Спасибо!<br/>Не забудьте передать курьеру пустую<br/>коробку при следующем заказе</span></div>;
            } else if (this.props.USER) {
                return <div className="zoo_action_text">Выберите животное, которому вы хотите передать средства от переработки коробки, которую вы отдадите нашему курьеру</div>;
            }
        }
    }

    num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

	render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="zoo">
                    <Helmet>
                        <title>{this.getTitle()}</title>
                        <meta name="description" content={this.getDescription()} />
                        <meta name="keywords" content={this.getKeywords()} />
                    </Helmet>


                    <div className="container">

                        <div className="zoo_desktop">
                            <div className="row zoo-flex">
                                <div className="col-5">
                                    <Link to="/">
                                        <img src="/system_images/white_logo.png" className="zoo_logo" />
                                        <span className="zoo_direction">
                                            На сайт ECOMARKET.RU
                                        </span>
                                    </Link>
                                </div>
                                <div className="col-2">
                                    <a onClick={() => this.setState({SHOW_RULES: true})} className="zoo_rules_nav_item">
                                        Об акции
                                    </a>
                                </div>
                                <div className="col-5">
                                    {this.renderUserBlock()}
                                </div>
                            </div>
                        </div>

                        <div className="zoo_mobile">
                            <div className="row zoo-flex">
                                <div className="col-3">
                                    {this.renderUserBlock()}
                                </div>

                                
                                <div className="col-6 text-center">
                                    <Link to="/">
                                        <span className="zoo_direction">
                                            ECOMARKET.RU
                                        </span>
                                    </Link>
                                </div>

                                <div className="col-3 text-right">
                                    <a onClick={() => this.setState({SHOW_RULES: true})} className="zoo_rules_nav_item">
                                        Об акции
                                    </a>
                                </div>
                                
                            </div>
                        </div>

                        <div className={'row zoo-flex-2' + this.checkFadedClass() }>
                            <div className="zoo-h1">
                                Экомаркет – верный друг Московского зоопарка, мы помогаем его обитателям стать счастливее
                            </div>
                            <img src="/system_images/zoo_logo.png" alt="Московский зоопарк" className="zoo_m_logo"/>
                        </div>



                        
                        
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="zoo_action_text">Выберите животное, которому вы хотите передать средства от переработки коробки, которую вы отдадите нашему курьеру</div>
                        </div>
                    </div>
                    <div className={'zoo_main' + this.checkFadedClass() }>
                        <div className="container">
                            <div className="row">
                                <img src="/system_images/zoo_circle_one.png" className="circle_one" />
                                <img src="/system_images/zoo_circle_two.png" className="circle_two" />
                                <img src="/system_images/zoo_circle_one.png" className="circle_four" />
                                <img src="/system_images/zoo_circle_two.png" className="circle_three" />

                                <div className="col-6 text-right">
                                    <div className="zoo_giraffe_name">
                                        Это жираф САМСОН
                                    </div>
                                    <img src="/system_images/zoo_giraffe.png" className="zoo_giraffe" />
                                    <img src="/system_images/zoo_leaves_one.png" className="zoo_leaves_one" />
                                    <img src="/system_images/zoo_leaves_two.png" className="zoo_leaves_two" />
                                </div>
                                <div className="col-6">
                                    <div className="zoo_elephant_name">
                                        Это слон ПАМИР
                                    </div>
                                    <img src="/system_images/zoo_elephant.png" className="zoo_elephant" />
                                    <img src="/system_images/zoo_leaves_three.png" className="zoo_leaves_three" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'zoo_actions' + this.checkFadedClass() }>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="zoo_actions_content">
                                        {this.renderActions()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'zoo_actions2' + this.checkFadedClass() }>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="zoo_last_text">
                                        За одну коробку мы перечисляем животному<br/><strong>5 рублей</strong>
                                    </div>
                                    <div className="zoo_last_text2">
                                        Нам уже вернулось<br/><strong>{this.state.BOXES} {this.num_title(this.state.BOXES, ['коробка', 'коробки', 'коробок'])}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                        

                    {this.renderRulesModal()}
                    <ModalsContainer />
                </div>
                <FooterBlock />
            </CSSTransitionGroup>
        );
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Zoo))
