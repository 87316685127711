import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

function mapStateToProps(state, myProps) {
    return {
        REGION: state.REGION
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Sapsan extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            REGION: props.REGION,
            ANSWER: false,
            ANSWERED: false
        }
    }

    componentDidMount()
    {
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    handleScroll(event) {

    }


    getTitle = () =>
    {
    	switch (this.state.REGION) {
			default:
    			return 'О нас';
    			break;
    	}
    }

    getKeywords = () =>
    {
    	switch (this.state.REGION) {
    		default:
    			return 'О нас';
    			break;
    	}
    }

    getDescription = () =>
    {
    	switch (this.state.REGION) {
    		default:
    			return 'О нас';
    			break;
    	}
    }

    getCode = () =>
    {
    	switch (parseInt(this.state.ANSWER)) {
    		case 1:
    			return 'ECOMANGO';
    			break;
			case 2:
				return 'PROMO1000';
				break;
			case 3:
				return 'PROMO10';
				break;
			case 4:
				return 'PROMO15';
				break;
			case 5:
				return 'PROMO20';
				break;
			default: 
				return 'VNIMANIE';
				break;
    	}
    }
    
    getDiscount = () =>
    {
    	switch (parseInt(this.state.ANSWER)) {
    		case 1:
    			return 'Ура, Вы выиграли сочный тайский манго в подарок на заказ по промокоду';
    			break;
			case 2:
				return 'Супер! Держите скидку 1000 рублей на заказ по промокоду';
				break;
			case 3:
				return 'Поздравляем, Ваша скидка 10% на заказ по промокоду';
				break;
			case 4:
				return 'Вы молодец, заработали скидку 15% на заказ по промокоду';
				break;
			case 5:
				return 'Вы самый внимательный в мире, получите мега-скидку 20% на заказ по промокоду';
				break;
			default: 
				return 'Упс, ответ неверный, получите утешительный приз – скидку 10% на Ваш заказ по промокоду';
				break;
    	}
    }

    recordAnswer = (answer) =>
    {
    	if (answer.target.value !== 0) {
	    	this.setState({ANSWER: answer.target.value});
	    }
    }

    doTheAnswer = () =>
    {
        this.setState({ANSWERED: true});
        window.scrollTo(0,0);
    }

    renderButton = () =>
    {
    	if (this.state.ANSWER) {
    		return (
    			<a className="sapsan_button" onClick={() => this.doTheAnswer()}>Ответить</a>
			);
    	} else {
    		return (
    			<a className="sapsan_button disabled">Ответить</a>
			);
    	}
    }

    renderContent()
    {
    	if (!this.state.ANSWERED) {
    		return (
	    		<div className="static_page_body">
	    			<h2 className="spansan_header">Проверим Вашу внимательность</h2>
	    			<div class="sapsan_intro">
	    				Введите количество фруктов и овощей,<br/>
						которые Вы нашли на картинке:
					</div>
					<input className="sapsan_input" type="number" placeholder="?" onChange={(text) => this.recordAnswer(text)} />
					{this.renderButton()}
	    		</div>
			);
    	} else {
	    	return (
	    		<div className="static_page_body">
	    			<h2 className="spansan_header">{this.getDiscount()}</h2>
	    			<div className="sapsan_code_holder">
	    				<div className="sapsan_code">{this.getCode()}</div>
    				</div>
	    			<div className="sapsan_screen">
	    				Сделайте снимок экрана,<br/>
						чтобы не потерять промокод
					</div>
					<div className="sapsan_correct">
						Правильный ответ: 5
					</div>
	    			<img className="sapsan_image" src="/system_images/sapsan_results.png" />
	    		</div>
			);
		}
    }

	render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>Сапасан игра</title>
                        <meta name="description" content="Сапасан игра" />
                        <meta name="keywords" content="Сапасан игра" />
                    </Helmet>

                    <div id="products" className="page-content">
                        <div className="static_page_content">
                            {this.renderContent()}
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
                <FooterBlock />
            </CSSTransitionGroup>
        );
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Sapsan))
