import Cookies from 'universal-cookie';

import CONFIG from './../SYSTEM/Config.js'
import { sendPostRequest } from "./../APP/HELPERS/requests"
import { store } from "../SYSTEM/Router";

const cookies = new Cookies();
window.WINDOW_CART = [];

const getRealAmount = (id, products, amount) => {
    if (typeof products[id] !== "undefined") {
        return products[id].peramount * amount;
    }

    return amount;
}

const getCookieTime = () => {
    let now = new Date();
    let time = now.getTime();
    let expireTime = time + 1000 * 36000;
    now.setTime(expireTime);
    let return_time = now.toGMTString();
    return return_time;
}

const recordFavs = (USER, FAVS) => {
    if (USER) {
        let SEND_DATA = {
            action: 'recordFavs',
            apikey: USER.TOKEN,
            favs: JSON.stringify(FAVS)
        };
        let data = sendPostRequest(SEND_DATA, CONFIG.API_GATE);
    }
}

var initState = {
    TOKEN: false,
    USER: false,
    CATS: false,
    PRODUCTS: false,
    SLIDES: false,
    GROUPED_PRODUCTS: false,
    CART: false,
    POPULAR: false,
    PREPARED_CATS: false,
    TAGS: false,
    SCROLL: false,
    HIDDEN: [],
    MANAGER_CHANGED: false,

    PRODUCT_MODAL: false,
    LK_MODAL: false,
    CART_MODAL: false,
    CARD_MODAL: false,
    PAY_MODAL: false,
    DO_SEARCH: false,
    ORDER_MODAL: false,
    EMPTY_CART_MODAL: false,
    NOT_FOUND_MODAL: false,

    TOP_NAVS: false,
    SHOW_SEARCH: false,
    LOADED_ORDER: false,

    REF_CODE: false,

    SHOW_REGION_HELPER: true,
    REGION: false,

    REF_SITE: false,
    REF_STRING: false,

    SHOW_LOCATION_ALERT: false,
    CLICK_ID: '',
    KH_ID: '',

    FAVS: false,
    LIMIT_NAVS: false,

    ADDING_TO_EXISTING: false,
    DELIVERY_ADDRESS_MODAL: false,
    DELIVERY_PAYMENT_MODAL: false,
    FORCED_ADDRESS_SELECT_MODAL: false,
    KM: false,
    ADRESS: false,
    SHOWADRESS: false,
    GPS: false,
    USEABLE_PERIODS: false,
    MINUTES: false,
    INSIDE_MKAD: true,
    POSTAL_DAYS: false,
    POSTAL_PRICE: false,
    POST_DATA: [],

    SHOW_MIXED_MODAL: false,
    OPEN_REGION_SELECTOR: false,
    CITIES_SELECTOR_MODAL: false,

    MODAL_WAS_SHOWN: false,
    ADDRESS_WITH_ADDITION: false,
    BAD_RATING_MODAL: false,

    PolygonsMinutes: '-1',
    PolygonsMinimumOrder: '2000',
    PolygonsDeliveryPrice: '-1',
    PolygonsFreeDeliveryWhenSum: '-1',

    QUEST_PRODUCTS: [],

    IS_TESTER: false,


    urlToProductModal: '',
    isFromBasketModal: false,

    THREE_THOUSAND: 299,
    FOUR_THOUSAND: 299,
    NINE_THOUSAND: 299,

    SHOW_INACTIVE: false,
    EDIT_SEASONS: false,
    MANAGERS: false,
    MANAGER: '',
    SUPPLIERS: false,
    SUPPLIER: '',
    EDIT_PARSING: false,
    SHOP: '',
    SPEC_FILTER: {
        topack: 0,
        season: 0,
        archive: 0,
        archive_all: 0,
        disabled: 0,
        zeroes: 0,
        alls: 0,
        all: 0,
        important: 0,
        archive_only: 0,
        no_groups: 0,
        repack: 0
    },
    MARGE_FILTER: [false, false],
    WRITE_FILTER: [false, false],
    REPACK: 0,

    ORDERING_VIEW: false
};

var reducer = function (state = initState, action) {
    let CART = {};
    let PRODUCT = false;
    let ID = false;
    let FAVS = [];


    const getCategory = (id) => {
        let CAT = null

        state.CATS.forEach(cat => {
            if (id) {
                if (cat.id == id) {
                    CAT = cat
                } else {
                    cat.children.forEach(subcat => {
                        if (subcat.id == id)
                            CAT = subcat
                    })
                }
            } else CAT = null
        })

        return CAT
    }

    switch (action.type) {

        case 'SET_POSTAL_DAYS_AND_PRICE': {
            return {
                ...state,
                POSTAL_DAYS: action.data.days,
                POSTAL_PRICE: action.data.price,
            }
        }

        case 'SET_SHOW_INACTIVE': {
            return {
                ...state,
                SHOW_INACTIVE: action.data
            }
        }

        case 'SET_MARGE': {
            return {
                ...state,
                MARGE_FILTER: action.data
            }
        }

        case 'REPACK': {
            return {
                ...state,
                REPACK: action.data
            }
        }

        case 'MANAGER_CHANGED': {
            return {
                ...state,
                MANAGER_CHANGED: + new Date()
            }
        }

        case 'CHANGE_ORDERING_VIEW': {
            return {
                ...state,
                ORDERING_VIEW: action.data
            }
        }

        case 'SET_WRITE': {
            return {
                ...state,
                WRITE_FILTER: action.data
            }
        }

        case 'EDIT_PARSING': {
            return {
                ...state,
                EDIT_PARSING: action.data
            }
        }

        case 'EDIT_SEASONS': {
            return {
                ...state,
                EDIT_SEASONS: action.data
            }
        }

        case 'SET_SPECFILTER': {
            localStorage.setItem('SPEC_FILTER', JSON.stringify(action.data));
            return {
                ...state,
                SPEC_FILTER: action.data
            }
        }

        case 'SET_MANAGER': {
            return {
                ...state,
                MANAGER: action.data
            }
        }

        case 'SET_SHOP': {
            return {
                ...state,
                SHOP: action.data
            }
        }

        case 'SET_SUPPLIER': {
            return {
                ...state,
                SUPPLIER: action.data
            }
        }

        case 'RECORD_POST_DATA': {
            return {
                ...state,
                POST_DATA: action.data
            }
        }

        case 'SET_TOKEN': {
            return {
                ...state,
                TOKEN: action.data
            }
        }

        case 'BAD_RATING_MODAL': {
            return {
                ...state,
                BAD_RATING_MODAL: action.data
            }
        }

        case 'ADDRESS_WITH_ADDITION':
            return {
                ...state,
                ADDRESS_WITH_ADDITION: action.data
            }

        case 'OPEN_CITIES_SELECTOR':
            let MODAL_SHOWN = state.MODAL_WAS_SHOWN;
            if (action.data == false) {
                MODAL_SHOWN = true;
                cookies.set('NO_REGION_MODAL', 'true', { path: '/', time: getCookieTime() });
            }

            return {
                ...state,
                CITIES_SELECTOR_MODAL: action.data,
                MODAL_WAS_SHOWN: MODAL_SHOWN
            }

        case 'OPEN_REGION_SELECTOR':
            let MODAL_SHOW = state.MODAL_WAS_SHOW;
            if (action.data == false) {
                MODAL_SHOW = true;
                cookies.set('NO_REGION_MODAL', 'true', { path: '/', time: getCookieTime() });
            }
            cookies.set('MODAL_WAS_SHOWN', MODAL_SHOW, { path: '/', time: getCookieTime() });
            return {
                ...state,
                OPEN_REGION_SELECTOR: action.data,
                MODAL_WAS_SHOWN: MODAL_SHOW
            }


        case 'OPEN_DEL_ADRESS_MIXED_MODAL':
            return {
                ...state,
                SHOW_MIXED_MODAL: action.data
            }

        case 'SET_ADDRESS_POINT':
            return {
                ...state,

            }

        case 'SET_USEABLE_PERIODS':
            let USEABLE_PERIODS = false;
            let KM = false;
            let MINUTES = false;
            let INSIDE_MKAD = false;

            if (action.data) {
                USEABLE_PERIODS = action.data.PERIODS;
                KM = action.data.KM;
                MINUTES = action.data.MINUTES;
                INSIDE_MKAD = action.data.INSIDE_MKAD;
            }

            return {
                ...state,
                USEABLE_PERIODS: USEABLE_PERIODS,
                KM: KM,
                MINUTES: MINUTES,
                INSIDE_MKAD: INSIDE_MKAD
            }

        case 'SET_POLYGONS_DATA':

            let PolygonsMinutes = action.data.MINUTES;
            let PolygonsMinimumOrder = action.data.MINIMUM_ORDER;
            let PolygonsDeliveryPrice = action.data.DELIVERY_PRICE;
            let PolygonsFreeDeliveryWhenSum = action.data.FREE_DELIVERY_WHEN_SUM;

            return {
                ...state,
                PolygonsMinutes,
                PolygonsMinimumOrder,
                PolygonsDeliveryPrice,
                PolygonsFreeDeliveryWhenSum,
            }

        case 'MODAL_WINDOW_SHOW': {
            let MODAL_WAS_SHOW = true;

            if (action.data) {
                MODAL_WAS_SHOW = action.data.MODAL_WAS_SHOW;
            }
            return {
                ...state,
                MODAL_WAS_SHOW: MODAL_WAS_SHOW,
            }
        }

        case 'SET_ADRESS_GPS_ADRESS_REGION':
            let ADRESSDATA = {
                KM: action.data.KM,
                ADRESS: action.data.ADRESS,
                GPS: action.data.GPS,
                SHOWADRESS: action.data.SHOWADRESS,
                MODAL_WAS_SHOWN: true
            };
            cookies.set('ADRESSDATA', JSON.stringify(ADRESSDATA), { path: '/', time: getCookieTime() });
            return {
                ...state,
                KM: action.data.KM,
                ADRESS: action.data.ADRESS,
                GPS: action.data.GPS,
                SHOWADRESS: action.data.SHOWADRESS,
                MODAL_WAS_SHOWN: true
            }

        case 'SHOW_LOCATION_ALERT':
            return {
                ...state,
                SHOW_LOCATION_ALERT: action.data
            }

        case 'SHOW_DELIVERY_ADDRESS_MODAL':
            return {
                ...state,
                DELIVERY_ADDRESS_MODAL: action.data
            }

        case 'SHOW_DELIVERY_PAYMENT_MODAL':
            return {
                ...state,
                DELIVERY_PAYMENT_MODAL: action.data
            }

        case 'SHOW_FORCED_ADDRESS_SELECT_MODAL':
            return {
                ...state,
                FORCED_ADDRESS_SELECT_MODAL: action.data
            }

        case 'START_EXISTING_ORDER':
            let use_data = false;
            if (action.data !== false) {
                use_data = action.data;
                use_data.TIME_START = Math.floor(Date.now() / 1000) + 1200;
            }
            return {
                ...state,
                ADDING_TO_EXISTING: use_data
            }

        case 'LIMIT_NAVS':
            return {
                ...state,
                LIMIT_NAVS: action.data
            }

        case 'SHOW_LOCATION_ALERT':
            return {
                ...state,
                SHOW_LOCATION_ALERT: action.data
            }

        case 'SET_KH_ID':
            return {
                ...state,
                KH_ID: action.data
            }

        case 'OPEN_NOT_FOUND_MODAL':
            return {
                ...state,
                NOT_FOUND_MODAL: action.data
            }

        case 'SET_REF_SITE':
            return {
                ...state,
                REF_SITE: action.data
            }

        case 'SET_CLICK_ID':
            return {
                ...state,
                CLICK_ID: action.data
            }

        case 'SET_REF_STRING':
            return {
                ...state,
                REF_STRING: action.data
            }

        case 'SET_REF_CODE':
            return {
                ...state,
                REF_CODE: action.data
            }

        case 'LOAD_ORDER':
            return {
                ...state,
                LOADED_ORDER: action.data
            }

        case 'RESET_PRODUCTS':
            return {
                ...state,
                PRODUCTS: false
            }


        case 'CHANGE_REGION':
            cookies.set('eco_region', action.data, { path: '/', time: getCookieTime() });
            return {
                ...state,
                REGION: action.data
            }

        case 'SET_START_UP_DATA':
            let DATA = action.data;
            return {
                ...state,
                TOKEN: DATA.TOKEN,
                CATS: DATA.CATS,
                PRODUCTS: DATA.PRODUCTS,
                SLIDES: DATA.SLIDES,
                GROUPED_PRODUCTS: DATA.GROUPED_PRODUCTS,
                POPULAR: DATA.POPULAR,
                TAGS: DATA.TAGS,
                MANAGERS: DATA.MANAGERS,
                SUPPLIERS: DATA.SUPPLIERS,
                HIDDEN: DATA.HIDDEN
            }

        case 'SET_GROUPED':
            return {
                ...state,
                GROUPED_PRODUCTS: action.data
            }

        case 'SET_PREPARED_CATS':
            return {
                ...state,
                PREPARED_CATS: action.data
            }

        case 'SET_CART':
            let NEW_CART = false;
            CART = action.data;
            if (CART === false) {
                NEW_CART = Object.assign({});
            } else {
                NEW_CART = Object.assign({}, CART);
            }
            localStorage.setItem('CART', JSON.stringify(NEW_CART));

            window.WINDOW_CART = []
            for (let cart_product_id in NEW_CART) {
                window.WINDOW_CART.push(
                    {
                        product_id: cart_product_id,
                        product_quantity: NEW_CART[cart_product_id]
                    }
                );
            }


            return {
                ...state,
                CART: NEW_CART
            }

        case 'SET_FAVS':
            let NEW_FAVS = false;
            FAVS = action.data;
            if (FAVS === false) {
                NEW_FAVS = Object.assign({});
            } else {
                NEW_FAVS = Object.assign({}, FAVS);
            }
            localStorage.setItem('FAVS', JSON.stringify(NEW_FAVS));
            return {
                ...state,
                FAVS: NEW_FAVS
            }

        case 'ADD_TO_FAVS':
            if (!state.FAVS) {
                FAVS = Object.assign({});
            } else {
                FAVS = Object.assign({}, state.FAVS);
            }
            FAVS[action.data.id] = 1;
            localStorage.setItem('FAVS', JSON.stringify(FAVS));
            recordFavs(state.USER, FAVS);

            if (state.CART === false) {
                CART = Object.assign({});
            } else {
                CART = Object.assign({}, state.CART);
            }
            PRODUCT = action.data;
            try {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    'ecommerce': {
                        'add': {
                            'products': [{
                                "id": PRODUCT.id,
                                "name": PRODUCT.title,
                                "price": PRODUCT.price,
                                'brand': state.PRODUCTS[PRODUCT.id].brand || null,
                                'category': getCategory(PRODUCT.all_groups[0])?.title || null,
                            }]
                        },
                        'items': [{
                            'item_name': PRODUCT.title,
                            'item_id': PRODUCT.id,
                            'item_brand': state.PRODUCTS[PRODUCT.id].brand || null,
                            'item_category': getCategory(PRODUCT.all_groups[0])?.title || null,
                            'item_category2': getCategory(PRODUCT.all_groups[1])?.title || null,
                            'item_category3': getCategory(PRODUCT.all_groups[2])?.title || null,
                            'item_category4': getCategory(PRODUCT.all_groups[3])?.title || null,
                            'item_category5': getCategory(PRODUCT.all_groups[4])?.title || null,
                            'price': PRODUCT.price,
                            'discount': PRODUCT.old_price !== 0 ? (PRODUCT.old_price - PRODUCT.price) : null,
                        }]
                    },
                    'event': 'gtm-ee-event',
                    'event-GA4': 'add_to_wishlist',
                    'gtm-ee-event-category': 'Enhanced Ecommerce',
                    'gtm-ee-event-action': 'Add to Wishlist',
                    'gtm-ee-event-non-interaction': 'False',
                });
            } catch { }
            return {
                ...state,
                FAVS: FAVS
            }

        case 'REMOVE_FROM_FAVS':
            FAVS = Object.assign({}, state.FAVS);
            ID = action.data;
            if (typeof FAVS[ID] !== "undefined") {
                delete FAVS[ID];
                localStorage.setItem('FAVS', JSON.stringify(FAVS));
                recordFavs(state.USER, FAVS);
            }

            return {
                ...state,
                FAVS: FAVS
            }


        /// EMPTY CART ///
        case 'EMPTY_CART':
            CART = {};
            localStorage.setItem('CART', JSON.stringify(CART));

            window.WINDOW_CART = []

            return {
                ...state,
                CART: CART
            }

        /// ADDING +1 TO PRODUCT IN CART ////
        case 'PLUS_CART':
            if (state.CART === false) {
                CART = Object.assign({});
            } else {
                CART = Object.assign({}, state.CART);
            }
            PRODUCT = action.data;
            if (typeof CART[PRODUCT.id] !== "undefined") {
                CART[PRODUCT.id].amount++;
                CART[PRODUCT.id].real_amount = getRealAmount(PRODUCT.id, state.PRODUCTS, CART[PRODUCT.id].amount);
                CART[PRODUCT.id].time = Math.floor(Date.now() / 1000);
                localStorage.setItem('CART', JSON.stringify(CART));

                window.WINDOW_CART = []
                for (let cart_product_id in CART) {
                    window.WINDOW_CART.push(
                        {
                            product_id: cart_product_id,
                            product_quantity: CART[cart_product_id]
                        }
                    );
                }

                let urlArray = action.from.split('/')
                let item_list_id
                if (action.from !== 'modalCard') {
                    if (action.from.indexOf('search') !== -1) {
                        action.from = 'search'
                        item_list_id = '7'
                    } else if (action.from.indexOf('popular') !== -1) {
                        action.from = 'popular'
                        item_list_id = '6'
                    } else if (action.from.indexOf('myproducts') !== -1) {
                        action.from = 'myproducts'
                        item_list_id = '5'
                    } else if (urlArray[urlArray.length - 1] === '') {
                        action.from = 'main'
                        item_list_id = '4'
                    } else if (urlArray[urlArray.length - 1] !== '') {
                        action.from = 'catalog'
                        item_list_id = '3'
                    }
                }

                if (state.CART_MODAL) {
                    action.from = 'cart'
                    item_list_id = '1'
                } else if (action.from === 'modalCard') {
                    action.from = 'modalCard'
                    item_list_id = '2'
                }

                try {
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        'ecommerce': {
                            'add': {
                                'products': [{
                                    "id": PRODUCT.id,
                                    "name": PRODUCT.title,
                                    "price": PRODUCT.price,
                                    "quantity": 1,
                                    'brand': state.PRODUCTS[parseInt(PRODUCT.id)].brand || null,
                                    'category': getCategory(PRODUCT.all_groups[0])?.title || null,
                                }]
                            },
                            'items': [{
                                'item_name': PRODUCT.title,
                                'item_id': PRODUCT.id,
                                'item_brand': state.PRODUCTS[parseInt(PRODUCT.id)].brand || null,
                                'item_category': getCategory(PRODUCT.all_groups[0])?.title || null,
                                'item_category2': getCategory(PRODUCT.all_groups[1])?.title || null,
                                'item_category3': getCategory(PRODUCT.all_groups[2])?.title || null,
                                'item_category4': getCategory(PRODUCT.all_groups[3])?.title || null,
                                'item_category5': getCategory(PRODUCT.all_groups[4])?.title || null,
                                'item_list_name': action.from,
                                'item_list_id': item_list_id,
                                'price': PRODUCT.price,
                                'discount': PRODUCT.old_price !== 0 ? (PRODUCT.old_price - PRODUCT.price) : null, // Опционально
                                'quantity': 1,
                            }]
                        },
                        'event': 'gtm-ee-event',
                        'event-GA4': 'add_to_cart',
                        'gtm-ee-event-category': 'Enhanced Ecommerce',
                        'gtm-ee-event-action': 'Add to Cart',
                        'gtm-ee-event-non-interaction': 'False',
                    });
                } catch {
                }
            }


            return {
                ...state,
                LAST_UPDATED: PRODUCT.id,
                CART: CART
            }

        /// ADDING TO CART ////
        case 'ADD_TO_CART':
            if (state.CART === false) {
                CART = Object.assign({});
            } else {
                CART = Object.assign({}, state.CART);
            }

            PRODUCT = action.data;
            CART[PRODUCT.id] = {};
            CART[PRODUCT.id].amount = 1;
            CART[PRODUCT.id].real_amount = getRealAmount(PRODUCT.id, state.PRODUCTS, 1);
            CART[PRODUCT.id].time = Math.floor(Date.now() / 1000);

            let urlArray = action.from.split('/')
            let item_list_id
            if (action.from !== 'modalCard') {
                if (action.from.indexOf('search') !== -1) {
                    action.from = 'search'
                    item_list_id = '7'
                } else if (action.from.indexOf('popular') !== -1) {
                    action.from = 'popular'
                    item_list_id = '6'
                } else if (action.from.indexOf('myproducts') !== -1) {
                    action.from = 'myproducts'
                    item_list_id = '5'
                } else if (urlArray[urlArray.length - 1] === '') {
                    action.from = 'main'
                    item_list_id = '4'
                } else if (urlArray[urlArray.length - 1] !== '') {
                    action.from = 'catalog'
                    item_list_id = '3'
                }
            }

            if (state.CART_MODAL) {
                action.from = 'cart'
                item_list_id = '1'
            } else if (action.from === 'modalCard') {
                action.from = 'modalCard'
                item_list_id = '2'
            }

            try {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    'ecommerce': {
                        'add': {
                            'products': [{
                                "id": PRODUCT.id,
                                "name": PRODUCT.title,
                                "price": PRODUCT.price,
                                "quantity": 1,
                                'brand': state.PRODUCTS[parseInt(PRODUCT.id)].brand || null,
                                'category': getCategory(PRODUCT.all_groups[0])?.title || null,
                            }]
                        },
                        'items': [{
                            'item_name': PRODUCT.title,
                            'item_id': PRODUCT.id,
                            'item_brand': state.PRODUCTS[parseInt(PRODUCT.id)].brand || null,
                            'item_category': getCategory(PRODUCT.all_groups[0])?.title || null,
                            'item_category2': getCategory(PRODUCT.all_groups[1])?.title || null,
                            'item_category3': getCategory(PRODUCT.all_groups[2])?.title || null,
                            'item_category4': getCategory(PRODUCT.all_groups[3])?.title || null,
                            'item_category5': getCategory(PRODUCT.all_groups[4])?.title || null,
                            'item_list_name': action.from,
                            'item_list_id': item_list_id,
                            'price': PRODUCT.price,
                            'discount': PRODUCT.old_price !== 0 ? (PRODUCT.old_price - PRODUCT.price) : null, // Опционально
                            'quantity': 1,
                        }]
                    },
                    'event': 'gtm-ee-event',
                    'event-GA4': 'add_to_cart',
                    'gtm-ee-event-category': 'Enhanced Ecommerce',
                    'gtm-ee-event-action': 'Add to Cart',
                    'gtm-ee-event-non-interaction': 'False',
                });
            } catch {
            }

            try {
                if (typeof window.yaCounter49140634 !== "undefined") {
                    window.yaCounter49140634.reachGoal('ADD_ORDER');
                }
            } catch {
            }

            localStorage.setItem('CART', JSON.stringify(CART));

            window.WINDOW_CART = []
            for (let cart_product_id in CART) {
                window.WINDOW_CART.push(
                    {
                        product_id: cart_product_id,
                        product_quantity: CART[cart_product_id]
                    }
                );
            }

            return {
                ...state,
                LAST_UPDATED: PRODUCT.id,
                CART: CART
            }

        /// ADDING TO CART ////
        case 'ADD_TO_CART_AMOUNT':
            if (state.CART === false) {
                CART = Object.assign({});
            } else {
                CART = Object.assign({}, state.CART);
            }
            PRODUCT = action.data;
            if (typeof CART[PRODUCT.id] !== "undefined") {
                CART[PRODUCT.id] = {};
                CART[PRODUCT.id].amount = action.amount;
                CART[PRODUCT.id].real_amount = getRealAmount(PRODUCT.id, state.PRODUCTS, action.amount);
                CART[PRODUCT.id].time = Math.floor(Date.now() / 1000);
                localStorage.setItem('CART', JSON.stringify(CART));

                window.WINDOW_CART = []
                for (let cart_product_id in CART) {
                    window.WINDOW_CART.push(
                        {
                            product_id: cart_product_id,
                            product_quantity: CART[cart_product_id]
                        }
                    );
                }
            }

            return {
                ...state,
                LAST_UPDATED: PRODUCT.id,
                CART: CART
            }

        /// REMOVING CART ////
        case 'MINUS_CART':
            CART = Object.assign({}, state.CART);
            PRODUCT = action.data;
            if (typeof CART[PRODUCT.id] !== "undefined") {

                let SET_REMOVE_CART = 0;

                if (CART[PRODUCT.id].amount === 1) {
                    let urlArray = action.from.split('/')
                    let item_list_id
                    if (action.from !== 'modalCard') {
                        if (action.from.indexOf('search') !== -1) {
                            action.from = 'search'
                            item_list_id = '7'
                        } else if (action.from.indexOf('popular') !== -1) {
                            action.from = 'popular'
                            item_list_id = '6'
                        } else if (action.from.indexOf('myproducts') !== -1) {
                            action.from = 'myproducts'
                            item_list_id = '5'
                        } else if (urlArray[urlArray.length - 1] === '') {
                            action.from = 'main'
                            item_list_id = '4'
                        } else if (urlArray[urlArray.length - 1] !== '') {
                            action.from = 'catalog'
                            item_list_id = '3'
                        }
                    }

                    if (state.CART_MODAL) {
                        action.from = 'cart'
                        item_list_id = '1'
                    } else if (action.from === 'modalCard') {
                        action.from = 'modalCard'
                        item_list_id = '2'
                    }
                    try {
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                            'ecommerce': {
                                'remove': {
                                    'products': [{
                                        "id": PRODUCT.id,
                                        "name": PRODUCT.title,
                                        "price": PRODUCT.price,
                                        "quantity": CART[PRODUCT.id]?.amount || 0,
                                        'brand': state.PRODUCTS[parseInt(PRODUCT.id)].brand || null,
                                        'category': getCategory(PRODUCT.all_groups[0])?.title || null,
                                    }]
                                },
                                'items': [{
                                    'item_name': PRODUCT.title,
                                    'item_id': PRODUCT.id,
                                    'item_brand': state.PRODUCTS[parseInt(PRODUCT.id)].brand || null,
                                    'item_category': getCategory(PRODUCT.all_groups[0])?.title || null,
                                    'item_category2': getCategory(PRODUCT.all_groups[1])?.title || null,
                                    'item_category3': getCategory(PRODUCT.all_groups[2])?.title || null,
                                    'item_category4': getCategory(PRODUCT.all_groups[3])?.title || null,
                                    'item_category5': getCategory(PRODUCT.all_groups[4])?.title || null,
                                    'item_list_name': action.from,
                                    'item_list_id': item_list_id,
                                    'price': PRODUCT.price,
                                    'discount': PRODUCT.old_price !== 0 ? (PRODUCT.old_price - PRODUCT.price) : null, // Опционально
                                    'quantity': CART[PRODUCT.id]?.amount || 0,
                                }]
                            },
                            'event': 'gtm-ee-event',
                            'event-GA4': 'remove_from_cart',
                            'gtm-ee-event-category': 'Enhanced Ecommerce',
                            'gtm-ee-event-action': 'Remove from Cart',
                            'gtm-ee-event-non-interaction': 'False',
                        });
                    } catch {
                    }
                    delete CART[PRODUCT.id];
                } else {
                    CART[PRODUCT.id].amount--;
                    CART[PRODUCT.id].real_amount = getRealAmount(PRODUCT.id, state.PRODUCTS, CART[PRODUCT.id].amount);
                    CART[PRODUCT.id].time = Math.floor(Date.now() / 1000);
                    SET_REMOVE_CART = CART[PRODUCT.id];
                }
                localStorage.setItem('CART', JSON.stringify(CART));


                window.WINDOW_CART = []
                for (let cart_product_id in CART) {
                    window.WINDOW_CART.push(
                        {
                            product_id: cart_product_id,
                            product_quantity: CART[cart_product_id]
                        }
                    );
                }
            }


            return {
                ...state,
                LAST_UPDATED: PRODUCT.id,
                CART: CART
            }

        /// REMOVING CART ////
        case 'REMOVE_FROM_CART':
            CART = Object.assign({}, state.CART);
            PRODUCT = action.data;
            if (typeof CART[PRODUCT.id] !== "undefined") {
                let urlArray = action.from.split('/')
                let item_list_id
                if (action.from !== 'modalCard') {
                    if (action.from.indexOf('search') !== -1) {
                        action.from = 'search'
                        item_list_id = '7'
                    } else if (action.from.indexOf('popular') !== -1) {
                        action.from = 'popular'
                        item_list_id = '6'
                    } else if (action.from.indexOf('myproducts') !== -1) {
                        action.from = 'myproducts'
                        item_list_id = '5'
                    } else if (urlArray[urlArray.length - 1] === '') {
                        action.from = 'main'
                        item_list_id = '4'
                    } else if (urlArray[urlArray.length - 1] !== '') {
                        action.from = 'catalog'
                        item_list_id = '3'
                    }
                }

                if (state.CART_MODAL) {
                    action.from = 'cart'
                    item_list_id = '1'
                } else if (action.from === 'modalCard') {
                    action.from = 'modalCard'
                    item_list_id = '2'
                }
                try {
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        'ecommerce': {
                            'remove': {
                                'products': [{
                                    "id": PRODUCT.id,
                                    "name": PRODUCT.title,
                                    "price": PRODUCT.price,
                                    "quantity": CART[PRODUCT.id]?.amount || 0,
                                    'brand': state.PRODUCTS[parseInt(PRODUCT.id)].brand || null,
                                    'category': getCategory(PRODUCT.all_groups[0])?.title || null,
                                }]
                            },
                            'items': [{
                                'item_name': PRODUCT.title,
                                'item_id': PRODUCT.id,
                                'item_brand': state.PRODUCTS[parseInt(PRODUCT.id)].brand || null,
                                'item_category': getCategory(PRODUCT.all_groups[0])?.title || null,
                                'item_category2': getCategory(PRODUCT.all_groups[1])?.title || null,
                                'item_category3': getCategory(PRODUCT.all_groups[2])?.title || null,
                                'item_category4': getCategory(PRODUCT.all_groups[3])?.title || null,
                                'item_category5': getCategory(PRODUCT.all_groups[4])?.title || null,
                                'item_list_name': action.from,
                                'item_list_id': item_list_id,
                                'price': PRODUCT.price,
                                'discount': PRODUCT.old_price !== 0 ? (PRODUCT.old_price - PRODUCT.price) : null, // Опционально
                                'quantity': CART[PRODUCT.id]?.amount || 0,
                            }]
                        },
                        'event': 'gtm-ee-event',
                        'event-GA4': 'remove_from_cart',
                        'gtm-ee-event-category': 'Enhanced Ecommerce',
                        'gtm-ee-event-action': 'Remove from Cart',
                        'gtm-ee-event-non-interaction': 'False',
                    });
                } catch {
                }
                delete CART[PRODUCT.id];
                localStorage.setItem('CART', JSON.stringify(CART));
            }

            window.WINDOW_CART = []
            for (let cart_product_id in CART) {
                window.WINDOW_CART.push(
                    {
                        product_id: cart_product_id,
                        product_quantity: CART[cart_product_id]
                    }
                );
            }

            return {
                ...state,
                LAST_UPDATED: PRODUCT.id,
                CART: CART
            }

        case 'OPEN_PRODUCT_MODAL':
            let PRODUCT_MODAL = action.data;
            return {
                ...state,
                PRODUCT_MODAL,
            }

        case 'METRIKA_PRODUCT_MODAL':
            let cart = action.cart
            let url = action.url
            return {
                ...state,
                urlToProductModal: url,
                isFromBasketModal: cart,
            }

        case 'ADD_CARD':
            return {
                ...state,
                CARD_MODAL: action.data
            }

        case 'OPEN_LK':
            let OPEN_LK = action.data;
            return {
                ...state,
                LK_MODAL: OPEN_LK
            }

        case 'CLOSE_ALL_MODALS':
            let CLOSE = false;
            return {
                ...state,
                CART_MODAL: CLOSE,
                LK_MODAL: CLOSE,
                PRODUCT_MODAL: CLOSE,
                PAY_MODAL: CLOSE,
                NOT_FOUND_MODAL: CLOSE
            }

        case 'DO_PAY':
            return {
                ...state,
                PAY_MODAL: action.data
            }

        case 'OPEN_CART':

            if (action.data) {
                CART = Object.assign({}, state.CART);

                const ids = Object.keys(CART)
                const products = ids.map(id => {
                    const prod = state.PRODUCTS[parseInt(id)]
                    if (prod) {
                        return {
                            'id': prod.id,
                            'name': prod.title,
                            'price': prod.price,
                            'brand': state.PRODUCTS[parseInt(prod.id)].brand || null,
                            'category': getCategory(prod.all_groups[0])?.title || null,
                            'quantity': CART[parseInt(id)].amount || 0,
                        }
                    } else return {}
                })
                const items = ids.map(id => {
                    const prod = state.PRODUCTS[parseInt(id)]
                    if (prod) {
                        return {
                            'item_id': prod.id,
                            'item_name': prod.title,
                            'price': prod.price,
                            'item_brand': state.PRODUCTS[parseInt(prod.id)].brand || null,
                            'item_category': getCategory(prod.all_groups[0])?.title || null,
                            'item_category2': getCategory(prod.all_groups[1])?.title || null,
                            'item_category3': getCategory(prod.all_groups[2])?.title || null,
                            'item_category4': getCategory(prod.all_groups[3])?.title || null,
                            'item_category5': getCategory(prod.all_groups[4])?.title || null,
                            'quantity': CART[parseInt(id)].amount || 0,
                            'discount': prod.old_price ? (prod.old_price - prod.price) : null
                        }
                    } else return {}
                })
                try {
                    if (items.length !== 0) {
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                            'ecommerce': {
                                'currencyCode': 'RUB',
                                'checkout': {
                                    'actionField': {
                                        'step': 1,
                                        'option': 'View Cart'
                                    },
                                    'products': products || null,
                                },
                                'items': items || null,
                            },
                            'event': 'gtm-ee-event',
                            'event-GA4': 'view_cart',
                            'gtm-ee-event-category': 'Enhanced Ecommerce',
                            'gtm-ee-event-action': 'View Cart',
                            'gtm-ee-event-non-interaction': 'False',
                        });
                    }
                } catch {
                }
            }

            return {
                ...state,
                CART_MODAL: action.data
            }

        case 'EMPTY_CART_MODAL':
            return {
                ...state,
                EMPTY_CART_MODAL: action.data
            }

        case 'OPEN_ORDER':
            if (action.data) {
                CART = Object.assign({}, state.CART);
                const idsf = Object.keys(CART)
                const productsf = idsf.map(id => {
                    const prod = state.PRODUCTS[parseInt(id)]
                    if (prod) {
                        return {
                            'id': prod.id,
                            'name': prod.title,
                            'price': prod.price,
                            'brand': state.PRODUCTS[parseInt(prod.id)].brand || null,
                            'category': getCategory(prod.all_groups[0])?.title || null,
                            'quantity': CART[parseInt(id)].amount || 0,
                        }
                    } else return {}
                })
                const itemsf = idsf.map(id => {
                    const prod = state.PRODUCTS[parseInt(id)]
                    if (prod) {
                        return {
                            'item_id': prod.id,
                            'item_name': prod.title,
                            'price': prod.price,
                            'item_brand': state.PRODUCTS[parseInt(prod.id)].brand || null,
                            'item_category': getCategory(prod.all_groups[0])?.title || null,
                            'item_category2': getCategory(prod.all_groups[1])?.title || null,
                            'item_category3': getCategory(prod.all_groups[2])?.title || null,
                            'item_category4': getCategory(prod.all_groups[3])?.title || null,
                            'item_category5': getCategory(prod.all_groups[4])?.title || null,
                            'quantity': CART[parseInt(id)].amount || 0,
                            'discount': prod.old_price ? (prod.old_price - prod.price) : null
                        }
                    } else return {}
                })
                try {
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        'ecommerce': {
                            'currencyCode': 'RUB',
                            'checkout': {
                                'actionField': {
                                    'step': 2, 'option': 'Checkout'
                                },
                                'products': productsf,
                            },
                            'items': itemsf
                        },
                        'event': 'gtm-ee-event',
                        'event-GA4': 'begin_checkout',
                        'gtm-ee-event-category': 'Enhanced Ecommerce',
                        'gtm-ee-event-action': 'Checkout',
                        'gtm-ee-event-non-interaction': 'False',
                    });
                } catch {
                }
            }

            return {
                ...state,
                ORDER_MODAL: action.data
            }

        case 'DO_AUTH':
            let USER = action.data;
            cookies.set('eco', USER.eco, { path: '/', time: USER.time });
            cookies.set('NO_REGION_MODAL', 'true', { path: '/', time: getCookieTime() });
            localStorage.setItem('USER', JSON.stringify(USER));
            return {
                ...state,
                USER: USER,
                MODAL_WAS_SHOWN: true
            }

        case 'UPDATE_AVATAR':
            let NEW_AVA_USER = Object.assign({}, state.USER);
            NEW_AVA_USER.IMAGE = action.data;
            cookies.set('eco', NEW_AVA_USER.eco, { path: '/', time: NEW_AVA_USER.time });
            localStorage.setItem('USER', JSON.stringify(NEW_AVA_USER));

            return {
                ...state,
                USER: NEW_AVA_USER
            }

        case 'SET_BONUSES_AMOUNT':
            let NEW_USER = Object.assign({}, state.USER);
            NEW_USER.BONUSES = action.data;
            cookies.set('eco', NEW_USER.eco, { path: '/', time: NEW_USER.time });
            localStorage.setItem('USER', JSON.stringify(NEW_USER));

            return {
                ...state,
                USER: NEW_USER
            }

        case 'DO_LOG_OUT':
            localStorage.removeItem('USER');
            cookies.set('eco', false, { path: '/', time: -1 });
            return {
                ...state,
                USER: false,
                FAVS: false
            }

        case 'DO_SEARCH':
            return {
                ...state,
                DO_SEARCH: action.data
            }

        case 'CLOSE_NAV':
            return {
                ...state,
                TOP_NAVS: false
            }

        case 'TOGGLE_NAV':
            let SET_NAVS = true;
            let SHOW_SHOW_SEARCH = false;
            if (state.TOP_NAVS === true) {
                SET_NAVS = false;
            }

            return {
                ...state,
                TOP_NAVS: SET_NAVS,
                SHOW_SEARCH: SHOW_SHOW_SEARCH
            }

        case 'TOGGLE_SEARCH':
            let SHOW_SEARCH = true;
            let SHOW_TOP_NAVS = false;
            if (state.SHOW_SEARCH === true) {
                SHOW_SEARCH = false;
            }

            return {
                ...state,
                SHOW_SEARCH: SHOW_SEARCH,
                TOP_NAVS: SHOW_TOP_NAVS
            }

        case 'SET_QUEST_PRODUCTS':
            return {
                ...state,
                QUEST_PRODUCTS: action.data,
            }

        case 'SET_IS_TESTER': {
            return {
                ...state,
                IS_TESTER: action.value
            }
        }

        case 'DONT_CHANGE_URL': {
            return {
                ...state,
                dontChangeURL: action.data,
            }
        }

        case 'SCROLL': {
            return {
                ...state,
                SCROLL: action.data
            }
        }

        case 'SET_HIDDEN': {
            return {
                ...state,
                HIDDEN: action.data
            }
        }

        case 'SET_PRICE_OF_DELIVERY_PER_PRICE': {
            return {
                ...state,
                THREE_THOUSAND: action.data.three,
                FOUR_THOUSAND: action.data.four,
                NINE_THOUSAND: action.data.nine,
            }
        }

        default:
            return state;
    }
}

export default reducer;