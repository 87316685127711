import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductRowRec from "./../../../APP/COMPONENTS/ContentParts/ProductRowRec"

function mapStateToProps(state) {
    return {
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        ADDING_TO_EXISTING: state.ADDING_TO_EXISTING,
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class AddonButton extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            SUM: 0,
            CART: props.CART,
            PRODUCTS: props.PRODUCTS,
            SECONDS: 1200
        };
    }

    componentDidMount = () =>
    {
        this.launchTimer()
    }

    launchTimer = () =>
    {
        let COMPONENT = this;
        let SECONDS = COMPONENT.props.ADDING_TO_EXISTING.TIME_START - Math.floor(Date.now() / 1000);
        COMPONENT.setState({SECONDS: SECONDS});
        this.interval = setInterval(function(){
            let SECONDS = COMPONENT.props.ADDING_TO_EXISTING.TIME_START - Math.floor(Date.now() / 1000);
            if (SECONDS == 0) {
                clearInterval(COMPONENT.interval);
                COMPONENT.setState({SECONDS: SECONDS});
            } else {
                COMPONENT.setState({SECONDS: SECONDS});
            }
        },1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.CART !== prevProps.CART) {
            this.setState({SUM: 0, CART: this.props.CART, PRODUCTS: this.props.PRODUCTS});
        }
    }

    renderTimer = () =>
    {
        if (this.props.ADDING_TO_EXISTING) {
            let SECONDS = this.state.SECONDS;
            let MINUTES = Math.floor(SECONDS / 60);
            SECONDS = SECONDS - (MINUTES * 60);

            if (SECONDS == 0) {
                SECONDS = '00';
            } else if (SECONDS < 10) {
                SECONDS = '0' + SECONDS;
            }

            return (
                <div className="addon_button_timer">
                    {MINUTES}:{SECONDS}
                </div>
            );
        }
    }

    countCart()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        return SUM;
    }

    rmFixed2(numb)
    {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(parseFloat(numb).toFixed(2)).toFixed(2);
        return numb;
    }

    goToOrder()
    {

        this.props.openCart(false);
        this.props.openOrder(true);
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }
   

    checkCartContents()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let RESULT = 0;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                        SUM += ROW_SUM;
                        RESULT = 1;
                    }
                }
            }
        }

        this.SUM = SUM;

        return RESULT;
    }

    addProductsToOrder = async () =>
    {
        let SUM = this.countCart();
        if (SUM > 0) {
            if (!this.state.SAVING) {
                this.setState({SAVING: true});
                try {
                    let TOKEN = 'UNREGISTERED';
                    if (this.props.USER) {
                        TOKEN = this.props.USER.TOKEN;
                    }

                    let SEND_DATA = {
                        action:     'addProductsToOrder',
                        id:         this.props.ADDING_TO_EXISTING.ID,
                        apikey:     TOKEN,
                        security:   this.props.ADDING_TO_EXISTING.SECURITY,
                        products:   this.props.CART

                    };

                    let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                    if (data !== false) {
                        this.finishOrder();
                    }
                    this.setState({SAVING: false});
                } catch (error) {
                    this.setState({SAVING: false});
                }
            }
        } else {
            this.finishOrder();
        }
    }

    finishOrder = () =>
    {
        this.props.openCart(false);
        this.props.openOrder(true);
        this.props.emptyCart();
    }

	render()
    {
        let SUM = this.countCart();

        if (SUM > 0) {
            return (
                <div className="modal_bottom" id="cart-submit" data-id="cart-submit">
                    <a className="orange-button hoverable" onClick={() => this.addProductsToOrder()}>
                        Добавить к заказу
                        {this.renderTimer()}
                    </a>
                </div>
            );
        } else {
            return (
                <div className="modal_bottom" id="cart-submit" data-id="cart-submit">
                    <a className="orange-button hoverable" onClick={() => this.addProductsToOrder()}>
                        Завершить без замены
                        {this.renderTimer()}
                    </a>
                </div>
            );
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AddonButton);