import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'
import styles from "./ProductCard.module.css"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {sendPostRequest} from "../../HELPERS/requests";

function mapStateToProps(state, myProps) {
    let AMOUNT = 0;
    let id = parseInt(myProps.ID);
    if (typeof state.CART[id] !== "undefined") {
        AMOUNT = state.CART[id].amount;
    }

    let AMOUNT_ALTERNATIVE = 0;
    let product = state.PRODUCTS[id]
    if (product) {
        let idAlternative = parseInt(product?.opt.id);
        if (typeof state.CART[idAlternative] !== "undefined") {
            AMOUNT_ALTERNATIVE = state.CART[idAlternative].amount;
        }
    }

    return {
        PRODUCTS: state.PRODUCTS,
        AMOUNT,
        AMOUNT_ALTERNATIVE,
        ADRESS: state.ADRESS,
        MODAL_WAS_SHOWN: state.MODAL_WAS_SHOWN,
        REGION: state.REGION,
        CART_MODAL: state.CART_MODAL,
        SCROLL: state.SCROLL,
        USER: state.USER
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class ProductCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ID: props.ID,
            PRODUCT: this.props.PRODUCTS[parseInt(props.ID)],
            MANIPULATE: false,
            ALTERNATIVE_MANIPULATE: false,
            ERROR: false,
            alternativeProd: {},
            wholesale: false,
            cardWidth: false,
        }
        let prodId = JSON.parse(props.ID)
        this.PRODUCT = this.props.PRODUCTS[prodId];
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.ID !== prevProps.ID)) {
            this.setState({ID: this.props.ID, PRODUCT: this.props.PRODUCTS[parseInt(this.props.ID)]});
            this.PRODUCT = this.props.PRODUCTS[parseInt(this.props.ID)];
        }
        if (this.props.SCROLL !== prevProps.SCROLL) {
            if (this.props.SCROLL)
                this.setState({MANIPULATE: false, ALTERNATIVE_MANIPULATE: false})
        }
    }

    getCurrentProduct = () => {
        if (this.state.wholesale) return this.state.alternativeProd.PRODUCT
        else return this.state.PRODUCT
    }

    getWebProduct = async (ID) => {
        try {
            let SEND_DATA = {
                action: 'getProductById_v2',
                id: ID,
                region: this.props.REGION,
                token: this.props.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                if (this.props.PRODUCTS[data.id])
                    this.setState({alternativeProd: {PRODUCT: data, ERROR: false, ID: ID}});
            } else {
                this.setState({alternativeProd: {}});
            }
        } catch (error) {
            this.setState({alternativeProd: {}});
        }
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const alternativeId = parseInt(this.state.PRODUCT?.opt.id)
        if (alternativeId !== 0)
            await this.getWebProduct(alternativeId)
        const width = this.wrapperRef?.clientWidth
        this.setState({cardWidth: width})
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.MANIPULATE) {
                this.setState({MANIPULATE: false});
            }
            if (this.state.ALTERNATIVE_MANIPULATE) {
                this.setState({ALTERNATIVE_MANIPULATE: false});
            }
        }
    }

    cancelClose = async () => {
        try {
            let SEND_DATA = {
                action: 'admin_cancelClose',
                id: this.state.PRODUCT.id,
                apikey: this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                alert('Товар убран из списка к зжакрытию')
            }
        } catch (error) {
        }
    }

    renderLeftBadgesNew() {
        let LEFT_ONES = [];

        if (parseInt(this.state.PRODUCT.archive) == 1) {
            let BADGE = (
                <div className="cardBadge archived" key="-1">
                    Закрыт
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.topack == 1) {
            let BADGE = (
                <div className="cardBadge topack_card" key="0">
                    Фасовочный
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.toclose == 1) {
            let BADGE = (
                <div className="cardBadge toclose_card" key="0">
                    К закрытию <a className="cancel_close" onClick={() => this.cancelClose()}><img src="/system_images/close.png"/></a>
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.seasonstart !== this.state.PRODUCT.seasonend) {
            if (parseInt(this.state.PRODUCT.today_season) == 0 && this.state.PRODUCT.availableones == 0) {
                let BADGE = (
                    <div className="cardBadge season_card" key="0">
                        Сезонный
                    </div>
                );
                LEFT_ONES.push(BADGE);
            }
        }

        if (LEFT_ONES.length) {
            return (
                <div className="badgesCard">
                    {LEFT_ONES.map(badge => {
                        return badge
                    })}
                </div>
            );
        } else {
            return false;
        }
    }

    renderMinusButton() {
        return (
            <div className={styles.minus} onClick={() => this.minusProduct()}>
                {this.state.wholesale
                    ? <img
                        src={this.props.AMOUNT_ALTERNATIVE == 1 ? '/system_images/bin_black.svg' : '/system_images/minusBlack.svg'}/>
                    : <img
                        src={this.props.AMOUNT == 1 ? '/system_images/bin_black.svg' : '/system_images/minusBlack.svg'}/>}
            </div>
        );
    }

    minusProduct() {
        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE - 1 < 1) {
                this.setState({ALTERNATIVE_MANIPULATE: false});
            } else {
                this.launchAlternativeManipulation();
            }
        } else {
            if (this.props.AMOUNT - 1 < 1) {
                this.setState({MANIPULATE: false});
            } else {
                this.launchManipulation();
            }
        }
        this.props.minusCart(this.getCurrentProduct());
    }

    checkMaximum() {

        let PRODUCT = this.getCurrentProduct();

        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE + 1 <= PRODUCT.availableones) {
                return true;
            }
        } else {
            if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
                return true;
            }
        }
        return false;
    }

    plusProduct() {
        if (this.state.wholesale) {
            this.launchAlternativeManipulation()
        } else {
            this.launchManipulation();
        }
        let check = this.checkMaximum();
        if (check) {
            this.props.plusCart(this.getCurrentProduct());
        }
    }

    renderCartedPrice() {
        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE > 0) {
                return this.getCurrentProduct().price //* this.props.AMOUNT_ALTERNATIVE;
            } else {
                return this.getCurrentProduct().price;
            }
        } else {
            if (this.props.AMOUNT > 0) {
                return this.getCurrentProduct().price //* this.props.AMOUNT;
            } else {
                return this.getCurrentProduct().price;
            }
        }
    }

    regionSelectorShow = () => {

        if (!this.props.MODAL_WAS_SHOWN) {
            this.props.openRegionSelector(true)
        }
    }

    addToCart() {
        if (this.state.wholesale) {
            this.launchAlternativeManipulation()
        } else {
            this.launchManipulation();
        }

        this.props.addToCart(this.getCurrentProduct());
    }


    doPress(id) {
        this.setState({PRESSED: id});
    }

    doUnpress() {
        this.setState({PRESSED: false});
    }

    morePressed() {
        if (this.state.wholesale) {
            this.launchAlternativeManipulation()
        } else {
            this.launchManipulation();
        }
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCart(this.getCurrentProduct());
        }
    }

    renderCartButton = () => {
        const width = this.wrapperRef?.clientWidth
        if (this.state.wholesale) {
            if (!this.state.ALTERNATIVE_MANIPULATE && this.props.AMOUNT_ALTERNATIVE > 0) {
                return <div onClick={() => {
                    this.launchAlternativeManipulation()
                    this.props.scroll(false)
                }} className={styles.controllBtnClickedOutside}>
                    <div className={styles.amount}>
                        {this.renderShowAmount()} {this.getCurrentProduct().ed_izm === 'л' ? 'шт' : this.getCurrentProduct().ed_izm === 'л'}
                    </div>
                </div>
            } else if (this.props.AMOUNT_ALTERNATIVE > 0) {
                return (
                    <div style={{
                        width: width > 175 ? '50%' : 'calc(100% - 6px)',
                        minWidth: 137,
                        margin: width > 175 ? '-5px 0 0' : '-5px auto 0'
                    }}
                         className={styles.controllBtn}>
                        {this.renderMinusButton()}

                        <div style={{color: '#333', fontWeight: 700, fontFamily: 'DINPro-Bold, sans-serif'}}
                             className={styles.amount}>
                            {this.renderShowAmount()} {this.getCurrentProduct().ed_izm === 'л' ? 'шт' : this.getCurrentProduct().ed_izm === 'л'}
                        </div>

                        {this.renderPlusButton()}
                    </div>

                );
            } else {

                return (
                    <a className="to-cart-btn" onClick={() => {
                        this.props.scroll(false)
                        this.addToCart()
                    }} title={"В корзину"}>
                        <img src="/system_images/ShopBag.png"/>
                    </a>
                );
            }
        } else {
            if (!this.state.MANIPULATE && this.props.AMOUNT > 0) {
                return <div onClick={() => {
                    if (this.state.wholesale) {
                        this.launchAlternativeManipulation()
                        this.props.scroll(false)
                    } else {
                        this.launchManipulation()
                        this.props.scroll(false)
                    }
                }} className={styles.controllBtnClickedOutside}>
                    <div className={styles.amount}>
                        {this.renderShowAmount()} {this.getCurrentProduct().ed_izm === 'л' ? 'шт' : this.getCurrentProduct().ed_izm}
                    </div>
                </div>
            } else if (this.props.AMOUNT > 0) {
                return (
                    <div style={{
                        width: width > 175 ? '50%' : 'calc(100% - 6px)',
                        minWidth: 137,
                        margin: width > 175 ? '-5px 0 0' : '-5px auto 0'
                    }}
                         className={styles.controllBtn}>
                        {this.renderMinusButton()}

                        <div style={{color: '#333', fontWeight: 700, fontFamily: 'DINPro-Bold, sans-serif'}}
                             className={styles.amount}>
                            {this.renderShowAmount()} {this.getCurrentProduct().ed_izm === 'л' ? 'шт' : this.getCurrentProduct().ed_izm}
                        </div>

                        {this.renderPlusButton()}
                    </div>

                );
            } else {
                return (
                    <a className="to-cart-btn" onClick={() => {
                        this.props.scroll(false)
                        this.addToCart()
                    }} title={"В корзину"}>
                        <img src="/system_images/ShopBag.png"/>
                    </a>
                );
            }
        }
    }

    rmFixed2(numb) {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(numb).toFixed(2);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderProductOldPrice() {
        const width = this.wrapperRef?.clientWidth
        if ((parseInt(this.getCurrentProduct().sale) == 1) && (this.getCurrentProduct().old_price > 0)) {
            let PRICE = this.rmFixed2(parseFloat(this.getCurrentProduct().old_price));
            let PRICE_PARTS = PRICE.toString().split('.');
            if (typeof PRICE_PARTS[1] !== "undefined") {
                if (PRICE_PARTS[1].length == 1) {
                    PRICE_PARTS[1] = PRICE_PARTS[1] + '0';
                }
            } else {
                PRICE_PARTS[1] = '00';
            }
            let PERCENT = (100 - (this.getCurrentProduct().price / (this.getCurrentProduct().old_price / 100))).toFixed()
            return (
                <div className={styles.oldPriceWrapper}>
                    <div style={{fontSize: width > 175 ? 15 : 12}} className={styles.oldPrice}>
                        <span>
                            {PRICE_PARTS[0]}
                        </span>
                        <span className={styles.oldPriceCent}>
                            {PRICE_PARTS[1]}
                        </span>
                    </div>
                    <div style={{fontSize: width > 175 ? 14 : 12}} className={styles.per}>
                        <span>
                            {'-' + PERCENT + '%'}
                        </span>
                    </div>
                </div>
                /*                <div className="old_price_row">
                                    <div className="old_price_line"></div>
                                    <span className="old_price_text">
                                        {PRICE_PARTS[0]}
                                    </span>
                                    <span className="old_price_text2">
                                        {PRICE_PARTS[1]}
                                    </span>
                                    <span className="old_price_percents">
                                        -{PERCENT}%
                                    </span>
                                </div>*/
            );
        } else return <div style={{minHeight: 15}}/>
    }

    getPriceClass = () => {
        if ((parseInt(this.getCurrentProduct().sale) == 1) && (this.getCurrentProduct().old_price > 0)) {
            return ' selecter_padded';
        }

        return '';
    }

    renderButtons = () => {
        const width = this.wrapperRef?.clientWidth
        if (this.getCurrentProduct().availableones > 0) {
            let PRICE = this.renderCartedPrice();
            PRICE = this.rmFixed(PRICE);
            let PRICE_PARTS = PRICE.toString().split('.');

            if (typeof PRICE_PARTS[1] !== "undefined") {
                if (PRICE_PARTS[1].length == 1) {
                    PRICE_PARTS[1] = PRICE_PARTS[1] + '0';
                }
            } else {
                PRICE_PARTS[1] = '00';
            }

            return (
                <div className={styles.productPriceAndToggle}>
                    {this.renderProductOldPrice()}
                    <div className={styles.priceAndBtn}>
                        {((!this.state.wholesale && !this.state.MANIPULATE) || (this.state.wholesale && !this.state.ALTERNATIVE_MANIPULATE) || width > 175) &&
                        <div className={styles.price}>
                            <span>{PRICE_PARTS[0]}</span>
                            <span className={styles.priceCent}>{PRICE_PARTS[1]}</span>
                        </div>}
                        <div style={{
                            position: (((!this.state.wholesale && this.state.MANIPULATE) || (this.state.wholesale && !this.state.ALTERNATIVE_MANIPULATE)) && width < 175) ? 'static' : 'absolute',
                            maxWidth: '100%',
                            width: 'calc(100% - 40px)',
                            flexGrow: 5,
                            bottom: 0,
                            right: 0,
                        }}>
                            {<div className={styles.wrapperControllBtn}>
                          
                            </div>}
                        </div>
                    </div>
                    {/*                    {((!this.state.wholesale && !this.state.MANIPULATE) || (this.state.wholesale && !this.state.ALTERNATIVE_MANIPULATE) || width >= 215) && <div className={'selecter' + this.getPriceClass()}>
                        {this.renderProductOldPrice()}
                        <span className="price">{PRICE_PARTS[0]}</span>
                        <span className="price_kop">{PRICE_PARTS[1]}</span>
                    </div>}

                    {<div style={width < 215 ? {
                        position: width < 215 ? 'absolute' : 'static',
                        width: width < 215 ? 'calc(100% - 3px)' : '50%',
                        top: 0,
                        right: 0
                    } : {}} className={styles.wrapperControllBtn}>
                        {this.renderCartButton()}
                    </div>}*/}
                </div>
            );
        } else {
            return (
                <div
                    className="button-holder"
                >
                </div>
            );
        }
    }

    renderPlusButton() {
        return (
            <div className={styles.plus} onClick={() =>
                this.plusProduct()
            }>
                <img src={'/system_images/plusBlack.svg'}/>
            </div>
        );
    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount() {
        let PRODUCT = this.getCurrentProduct();
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;
        if (this.state.wholesale) SHOW_AMOUNT = this.props.AMOUNT_ALTERNATIVE * PRODUCT.peramount

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderCurShowAmount() {
        let PRODUCT = this.getCurrentProduct();
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;
        if (this.state.wholesale) SHOW_AMOUNT = this.props.AMOUNT_ALTERNATIVE * PRODUCT.peramount

        if (this.state.wholesale) {
            if (this.props.AMOUNT_ALTERNATIVE < 1) {
                return PRODUCT.peramount;
            }
        } else {
            if (this.props.AMOUNT < 1) {
                return PRODUCT.peramount;
            }
        }


        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage() {
        let PRODUCT = this.getCurrentProduct();
        if ((this.state.wholesale && this.props.AMOUNT_ALTERNATIVE == PRODUCT.availableones) || (!this.state.wholesale && this.props.AMOUNT == PRODUCT.availableones)) {
            return (
                <div className={styles.maxBG}>
                    <div className="cart-max">Это максимальное количество товара.</div>
                    <div className="cart-max-text">Вы можете указать желаемое количество в комментарии к заказу.</div>
                </div>
            );
        }
    }

    launchManipulation() {
        if (!this.state.MANIPULATE) {
            this.setState({MANIPULATE: true});
        }
    }

    launchAlternativeManipulation() {
        if (!this.state.ALTERNATIVE_MANIPULATE) {
            this.setState({ALTERNATIVE_MANIPULATE: true});
        }
    }

    renderManipulator = () => {
        if (this.state.wholesale) {
            if (!this.state.ALTERNATIVE_MANIPULATE) {
                return false;
            }
        } else {
            if (!this.state.MANIPULATE) {
                return false;
            }
        }

        let PRODUCT = this.getCurrentProduct();
        if ((this.state.wholesale && this.props.AMOUNT_ALTERNATIVE == PRODUCT.availableones) || (!this.state.wholesale && this.props.AMOUNT == PRODUCT.availableones)) {
            return (
                <div className={styles.maxBG}>
                    <div className="cart-max">Это максимальное количество товара.</div>
                    <div className="cart-max-text">Вы можете указать желаемое количество в комментарии к заказу.</div>
                </div>
            );
        }
    }

    renderMoreBlock() {
        if (WIDTH > 760) {
            return (
                <div className="read-some-more">
                        <span>
                        Подробнее
                        </span>
                </div>
            );
        }
    }

    renderRating = () => {
        if (this.getCurrentProduct().rating > 0) {
            return (

                <div className='rating_star'>
                    <div>{this.getCurrentProduct().rating}</div>
                    <img className='star_image' src="/system_images/raitingStar.png"/>
                </div>

            );
        }
    }


    renderImage() {
        if (WIDTH > 760) {
            let SRC = this.getCurrentProduct().thumb;
            if ((typeof SRC !== "undefined") && (SRC !== "")) {
                return (
                    <div className="relative_image_holder">

                        <div
                            className="image"
                            title={this.getCurrentProduct().title}
                            onClick={() => {
                                let url = window.location.href
                                this.props.metrikaProductModal(url, this.props.CART_MODAL)
                                this.props.openProductModal(this.state.PRODUCT.id, this.PRODUCT)
                            }}
                        >
                            <img
                                alt={this.getCurrentProduct().title}
                                src={CONFIG.API_C_DOMAIN + SRC}
                            />
                            {/*{this.renderPercents()}*/}
                            {this.renderMoreBlock()}
                            {this.renderRating()}
                        </div>
                        {this.renderManipulator()}
                    </div>
                );
            } else {
                let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
                return (
                    <div className="relative_image_holder">
                        <div
                            className="image"
                            title={this.getCurrentProduct().title}
                            style={STYLE}
                            onClick={() => this.props.openProductModal(this.props.ID)}
                        >
                            {/*{this.renderPercents()}*/}
                            {this.renderMoreBlock()}
                            {this.renderRating()}

                        </div>
                        {this.renderManipulator()}
                    </div>
                );
            }
        } else {
            let SRC = this.getCurrentProduct().thumb;
            if ((typeof SRC !== "undefined") && (SRC !== "")) {
                let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};

                return (
                    <div className="relative_image_holder">
                        <div

                            className="image"
                            title={this.getCurrentProduct().title}
                            style={STYLE}
                            onClick={() => this.props.openProductModal(this.props.ID)}
                        >
                            <img
                                alt={this.getCurrentProduct().title}
                                src={CONFIG.API_C_DOMAIN + SRC}
                            />
                            {/*{this.renderPercents()}*/}
                            {this.renderMoreBlock()}
                            {this.renderRating()}

                        </div>
                        {this.renderManipulator()}
                    </div>
                );
            } else {
                let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
                return (
                    <div className="relative_image_holder">
                        <Link
                            className="image"
                            title={this.getCurrentProduct().title}
                            style={STYLE}
                            to={'/' + this.getCurrentProduct().url}
                        >
                            {/*{this.renderPercents()}*/}
                            {this.renderMoreBlock()}
                            {this.renderRating()}

                        </Link>
                        {this.renderManipulator()}
                    </div>
                );
            }
        }
    }

    getPercentClass = () => {
        if (parseInt(this.getCurrentProduct().of_day) == 1) {
            return ' greeny';
        }

        return '';
    }

    renderPercents() {
        if ((parseInt(this.getCurrentProduct().sale) == 1) && (this.getCurrentProduct().old_price > 0)) {
            let PERCENT = (100 - (this.getCurrentProduct().price / (this.getCurrentProduct().old_price / 100))).toFixed()

            return (
                <div className="discount_percent_holder">
                    <div className={'discount_percent_text' + this.getPercentClass()}>
                        - {PERCENT}%
                    </div>
                </div>
            );
        }
    }

    renderTitle() {
        let url = window.location.href
        return (
            <a onClick={() => {
                this.props.metrikaProductModal(url, this.props.CART_MODAL);
                this.props.openProductModal(this.state.PRODUCT.id, this.PRODUCT)
            }} className="product-card-title" 
                  title={this.getCurrentProduct().title}>
                {this.getCurrentProduct().title}
            </a>
        );
    }

    renderWeight() {
        return <div style={{
            marginBottom: 10,
            fontWeight: 'bold',
            fontSize: 12,
            color: '#BFBFBF',
            fontFamily: 'DINPro-Bold, sans-serif'
        }}>
            {parseFloat(this.getCurrentProduct().weight) > 0.5 ? parseFloat(this.getCurrentProduct().weight) : parseFloat(this.getCurrentProduct().weight) * 1000} {parseFloat(this.getCurrentProduct().weight) > 0.5 ? (this.getCurrentProduct().ed_izm === 'л' ? this.getCurrentProduct().ed_izm : 'кг') : (this.getCurrentProduct().ed_izm === 'мл' ? this.getCurrentProduct().ed_izm : 'г')}
        </div>
    }

    getPercents() {
        if ((parseInt(this.state.alternativeProd?.PRODUCT?.sale) == 1) && (this.state.alternativeProd?.PRODUCT?.old_price > 0)) {
            let PERCENT = (100 - (this.state.alternativeProd.PRODUCT.price / (this.state.alternativeProd.PRODUCT.old_price / 100))).toFixed()
            return <div className={styles.percents}>
                -{PERCENT}%
            </div>
        }
    }

    renderWholesaleToggler() {
        if (this.state.PRODUCT?.opt.id !== '0' && Object.keys(this.state.alternativeProd)?.length !== 0) {

            return (
                <div className={styles.toggler}>
                    <div onClick={() => {
                        this.setState({wholesale: false, ALTERNATIVE_MANIPULATE: false})
                    }} style={{
                        background: !this.state.wholesale ? '#fff' : '#ededed',
                        fontWeight: !this.state.wholesale ? '700' : '500',
                        fontFamily: !this.state.wholesale ? 'DINPro-Medium, sans-serif' : 'DINPro-Bold, sans-serif',
                        fontSize: !this.state.wholesale ? 12 : 11,
                    }} className={styles.firstToggle}>1
                        шт
                    </div>
                    <div onClick={() => {
                        this.setState({wholesale: true, MANIPULATE: false})
                    }} style={{
                        background: this.state.wholesale ? '#fff' : '#ededed',
                        fontWeight: this.state.wholesale ? '700' : '500',
                        fontFamily: this.state.wholesale ? 'DINPro-Medium, sans-serif' : 'DINPro-Bold, sans-serif',
                        fontSize: this.state.wholesale ? 12 : 11,
                    }} className={styles.secondToggle}>
                        <div>{this.state.PRODUCT?.opt.ed_izm === 'л' ? 'шт' : this.state.PRODUCT?.opt.ed_izm}</div>
                        {this.getPercents()}
                    </div>
                </div>
            )
        } else return false
    }

    renderManagerInfo = () =>
    {
        return (
            <div className="manager_info">
                <span onClick={() => this.props.setManager(this.state.PRODUCT.manager)}>{this.state.PRODUCT.manager}</span>
                <br/>
                <span className="suppl" onClick={() => this.props.setSupplier(this.state.PRODUCT.supplier)}>{this.state.PRODUCT.supplier}</span>
            </div>
        )
    }

    renderManagerBadges = () =>
    {
        return (
            <div className="right_manager_badges">
                <div>
                    <span>Продажи за 10 дней</span>
                    {this.state.PRODUCT.sales_10} {this.getCurrentProduct().ed_izm === 'л' ? 'шт' : this.getCurrentProduct().ed_izm === 'л'}
                </div>
                {this.renderActivity()}
                {this.renderMarge()}
                {this.renderWriteoffs()}
                {this.renderDaysLastBuy()}
                {this.renderRealAmount()}
                <a className="card_edit_btn" onClick={() => this.props.editSeasoning({id: this.state.PRODUCT.id, title: this.state.PRODUCT.title, product: this.state.PRODUCT})}>
                    <i className="fa fa-pencil"></i>
                </a>
            </div>
        )
    }

    renderRealAmount = () =>
    {
        let style = {};
        if (parseFloat(this.state.PRODUCT.real_amount) == 0) {
            style={backgroundColor: '#ffc4c4'}
        }
        return (
            <div style={style}>
                <span>Остаток</span>
                {this.state.PRODUCT.real_amount} {this.getCurrentProduct().ed_izm === 'л' ? 'шт' : this.getCurrentProduct().ed_izm === 'л'}
            </div>
        )
    }

    renderActivity = () =>
    {
        let style = {};
        if (parseFloat(this.state.PRODUCT.activity) < 85) {
            style={backgroundColor: '#ffc4c4'}
        }
        return (
            <div style={style}>
                <span>Активность</span>
                {this.state.PRODUCT.activity}%
            </div>
        )
    }

    renderMarge = () =>
    {
        let style = {};
        if (parseFloat(this.state.PRODUCT.marge) < 30) {
            style={backgroundColor: '#ffc4c4'}
        }
        return (
            <div style={style}>
                <span>Марж</span>
                {this.state.PRODUCT.marge}%
            </div>
        )
    }

    renderWriteoffs = () =>
    {
        if (parseFloat(this.state.PRODUCT.writeoffs) !== 0) {
            return (
                <div>
                    <span>Списания за 30 дней</span>
                    {this.state.PRODUCT.writeoffs} р
                </div>
            )
        }
    }

    renderDaysLastBuy = () =>
    {
        let style = {};
        if (parseFloat(this.state.PRODUCT.days_last_buy_number) > 5) {
            style={backgroundColor: '#ffc4c4'}
        }
        if (this.state.PRODUCT.days_last_buy !== '1 д') {
            return (
                <div style={style}>
                    <span>Дней с посл. продажи</span>
                    {this.state.PRODUCT.days_last_buy}
                </div>
            )
        }
    }

    getShopTitle = (key) =>
    {
        switch (key) {
            case 'eco': return 'EC';
            case 'oz': return 'OZ';
            case 'av': return 'AV';
            case 'vk': return 'VV';
            case 'pk': return 'PK';
            case 'vp': return 'VP';
            case 'un': return 'UT';
            case 'gl': return 'GL';
            case 'mf': return 'FR';
            case 'ed': return 'ED';
            case 'ff': return '4F';
            case 'sm': return 'SM';
            case 'ln': return 'LN';
            case 'm2': return 'M2';
        }
    }

    renderParsingData = () =>
    {
        let RESULTS = [];
        for (let i in this.state.PRODUCT.parsing_results) {
            RESULTS.push(
                <a target="_blank" href={this.state.PRODUCT.parsing_results[i].url} className="parsing_result_row small_one" key={i} style={{backgroundColor: this.state.PRODUCT.parsing_results[i].style}}>
                    <div>{this.getShopTitle(i)}</div>
                    <div>{this.state.PRODUCT.parsing_results[i].text}</div>
                </a>
            )
        }

        let LINK = 'https://ecomarket.ru/adminer.php?view=price_parsing&manager=' + this.state.PRODUCT.manager;

        if (!RESULTS.length) {
            return null;
        }
        return (
            <div className="parsing_data_row small_one">
                <div className="parsing_data_results">
                    {RESULTS}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="product-col small-product-card" ref={this.setWrapperRef}>
                {this.renderImage()}
                {this.renderTitle()}
            </div>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCard)