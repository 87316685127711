import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CartProducts from './../../../APP/COMPONENTS/ContentParts/CartProducts';
import AddonProducts from './../../../APP/COMPONENTS/ContentParts/AddonProducts';
import AddonAddedRow from './../../../APP/COMPONENTS/ContentParts/AddonAddedRow';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        REC: true,
        REGION: state.REGION,
        ADDING_TO_EXISTING: state.ADDING_TO_EXISTING,
        KM: state.KM
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class AddonContent extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            SUM: 0,
            CART: props.CART,
            PRODUCTS: props.PRODUCTS,
            REGION: props.REGION,
            KM: props.KM,
            CURRENT_PRODUCTS: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {

        let UPDATE = 0;
        if ((typeof this.props.CART !== "undefined") && (typeof this.props.PRODUCTS !== "undefined")) {
            let NEW_KEYS = Object.keys(prevProps.CART);
            let CURRENT_KEYS = Object.keys(this.props.CART);


            if (JSON.stringify(NEW_KEYS) !== JSON.stringify(CURRENT_KEYS)) {
                UPDATE = 1;
            }
        } else {
            UPDATE = 1;
        }

        if (UPDATE == 1) {
            this.setState({SUM: 0, CART: this.props.CART, PRODUCTS: this.props.PRODUCTS});
        }

        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }

        if (this.props.KM !== prevProps.KM) {
            this.setState({KM: this.props.KM});
        }
    }

    componentDidMount = () =>
    {
        this.fetchCurrentProducts();
    }

    fetchCurrentProducts = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'getOrderQuickData',
                id:         this.props.ADDING_TO_EXISTING.ID,
                security:   this.props.ADDING_TO_EXISTING.SECURITY

            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({CURRENT_PRODUCTS: data.products});
            }
        } catch (error) {
            return false;
        }
    }

    renderCurrentCurrentProducts = () =>
    {
        if (this.state.CURRENT_PRODUCTS) {
            let PRODUCTS = [];

            for (let i = 0; i < this.state.CURRENT_PRODUCTS.length; i++) {
                PRODUCTS.push(
                    <AddonAddedRow key={i} PRODUCT={this.state.CURRENT_PRODUCTS[i]} />
                );
            }

            return (
                <div className="addon_current_data">
                    <div className="addon_order_number">
                        Номер заказа: {this.props.ADDING_TO_EXISTING.NUMBER}
                    </div>
                    <div className="addon_order_status">
                        Цех уже собирает эти товары
                    </div>
                    <div className="addon_added_products">
                        {PRODUCTS}
                    </div>
                </div>
            );
        }
    }

    emptyCart()
    {
        this.props.emptyCartModal(true);
    }

    countCart()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        return this.rmFixed2(SUM);
    }

    rmFixed2(numb)
    {
        numb = numb.toFixed(2);
        numb = this.removeTrails(numb);
        numb = parseFloat(parseFloat(numb).toFixed(2)).toFixed(2);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    getCityName = () =>
    {
        if (this.state.REGION == 78) {
            return 'Санкт-Петербургу';
        } else {
            return 'Москве';
        }
    }

    getSumMessage(SUM)
    {
        return 'Бесплатная доставка по ' + this.getCityName() + ' - заказ на 3500 р';
        if (parseInt(SUM) < 2000) {
            return 'Минимальный заказ на ' + CONFIG.MINIMAL + ' р';
        } else {
            return 'Бесплатная доставка по ' + this.getCityName() + ' - заказ на 3500 р';
        }
    }

    renderMessage()
    {
        let SUM = this.countCart();
        let MESSAGE = this.getSumMessage(SUM);
        if (SUM > 0) {
            return (
                <div className="cart-message addon-message">
                    <div className="addon_text-cart-part">
                        Сумма за добавленные товары: <span className="addon_sum">{SUM}</span>
                    </div>
                    {this.renderProducts()}
                </div>
            );
        }
    }

    renderProducts()
    {
        return <AddonProducts />
    }

    goToOrder()
    {
        if (this.props.USER) {
            window.location.href = CONFIG.API_C_DOMAIN + '/cart';
        } else {
            this.props.openLK(true);
        }
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }
    
    checkMaximum()
    {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    checkCartContents()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let RESULT = 0;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID].amount;
                        SUM += ROW_SUM;
                        RESULT = 1;
                    }
                }
            }
        }

        this.SUM = SUM;

        return RESULT;
    }

    renderRetailOne = () => 
    {
        return false;

        const WIDTH = window.innerWidth;
        let IDS = [];
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID].amount) {
                        IDS.push(ID);
                    }
                }
            }
        }

        IDS = IDS.join(',');

        if (WIDTH > 980) {
            return (
                <div>
                    <div data-retailrocket-markup-block="5f7d818897a5251aec3fcc56" data-products={IDS}></div>
                </div>
            );
        } else {
            return (
                <div>
                    <div data-retailrocket-markup-block="5f7da41397a5282bb875d3cd" data-products={IDS}></div>
                </div>
            );
        }
    }

    renderRetailTwo = () => 
    {
        return false;

        const WIDTH = window.innerWidth;
        if (WIDTH > 980) {
            return (
                <div>
                    <div data-retailrocket-markup-block="5f7d819697a5251aec3fcc57"></div>
                </div>
            );
        } else {
            return (
                <div>
                    <div data-retailrocket-markup-block="5f7da42497a5282bb875d3d3"></div>
                    <script>retailrocket.markup.render();</script>
                </div>
            );
        }
    }

    renderCart()
    {
        let CART_OK = 0;

        let count = 0;
        for (let k in this.props.CART) {
            ++count;
        }

        if (count > 0) {
            CART_OK = this.checkCartContents();
        }


        return this.renderCartContent();

    }

    renderCartEmpty()
    {
        return (
            <div>
                <div className="form-fields text-center">
                    <div className="empty_cart_block">
                        <img src="/system_images/empty_basket.png" />
                    </div>
                </div>
            </div>
        );
    }

    renderCartContent()
    {
        return (
            <div className="true_cart_content">
                {this.renderMessage()}
                {this.renderCurrentCurrentProducts()}
            </div>
        );
    }

    render()
    {
        return (
            <div className="modal_content">
                {this.renderCart()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AddonContent);