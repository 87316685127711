import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

import Poll from './../../APP/COMPONENTS/ContentParts/Poll.js';
import SpecialPoll from './../../APP/COMPONENTS/ContentParts/SpecialPoll.js';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class UserPoll extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            CODE: this.props.match.params.pollcode,
            POLL: false,
            PRODUCTS: false,
            POLL_ID: 0
        }
    }

    componentDidMount()
    {
        this.getPollData(this.state.CODE);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.match.params.pollcode !== prevProps.match.params.pollcode) {
            this.setState({CODE: this.props.match.params.pollcode});
            this.getPollData(this.props.match.params.pollcode);
        }
    }

    getPollData = async (CODE) =>
    {
        if (!this.GETTING) {
            this.GETTING = true;
            let SEND_DATA = {
                action:         'getPollData',
                code:           this.state.CODE,
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                this.GETTING = false;
                if (typeof data.poll !== "undefined") {
                    this.setState({POLL: data.poll, POLL_ID: data.id, PRODUCTS: data.products, NUMBER: data.number});
                } else {
                    this.setState({POLL: 'none'});
                }
            } else {
                this.GETTING = false;
                this.setState({POLL: 'none'});
            }
        }
    }

    renderPoll = () =>
    {
        if ((parseInt(this.state.POLL_ID) == 24) || (parseInt(this.state.POLL_ID) == 26)) {
            return <SpecialPoll POLL={this.state.POLL} POLL_ID={this.state.POLL_ID} CODE={this.state.CODE}  PRODUCTS={this.state.PRODUCTS} number={this.state.NUMBER} />;
        }

        return <Poll POLL={this.state.POLL} CODE={this.state.CODE} />;
    }

	render() {
        if (!this.state.POLL) {
            return <InnerLoader />
        } else if (this.state.POLL == 'none') {
            return <E404 />;
        }

		return (
			<CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>Опрос</title>
                        <meta name="description" content="Опрос" />
                        <meta name="keywords" content="Опрос" />
                    </Helmet>
					{this.renderPoll()}
					<ModalsContainer />
                </div>
                <FooterBlock />
			</CSSTransitionGroup>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(UserPoll)
