import React, {Component} from 'react';
import { Map, Polygon, Rectangle } from 'react-yandex-maps';
import regionsJson from '../../../src/Jsons/Delivery.json'
import CONFIG from './../../SYSTEM/Config.js'

export default class MapHelper extends Component {

	constructor(props)
    {
    	super(props);

        let GPS = false;
        if (props.GPS) {
            GPS = true;
        }

    	this.state = {
            REGION: parseInt(props.REGION),
    		CENTER: props.CENTER,
            GPS: this.props.GPS
    	}

    	this.MAP = false;

        this.setDrRef = this.setDrRef.bind(this);
        this.setMkadRef = this.setMkadRef.bind(this);
	}

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    setDrRef(node) {
        this.DR = node;
    }

    setMkadRef(node) {
        this.MKAD = node;
    }

	setMap = (MAP) =>
	{
		this.MAP = MAP;
        this.props.mapLoaded();
	}

	getSuggestions = (STRING) =>
	{

	}

    getClosestPoint(point)
    {
      
    }


	render() {
		return (
			<div style={{'display':'none'}}>
				<Map defaultState={{center: this.state.CENTER, zoom: 12}} onLoad={(ymaps) => this.setMap(ymaps)}>
				</Map>
			</div>
		);
	}

}